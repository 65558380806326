import { Label, TextInput } from 'flowbite-react';
import React from 'react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { IPasswordInputProps } from './InputField.types';

interface State {
    showPassword: boolean;
}
const PasswordInput: React.FC<IPasswordInputProps> = ({
    helperText,
    color,
    shadow,
    sizing,
    classeName,
    colorLabel,
    label,
    ...rest
}) => {
    const [val, setVal] = React.useState<State>({
        showPassword: false,
    });
    return (
        <>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="username4" className="dark:text-white" color={colorLabel} value={label} />
                </div>
                <TextInput
                    {...rest}
                    sizing={sizing}
                    id="passwordLogistioAgent"
                    className={`w-full ${classeName}`}
                    type={val.showPassword ? 'text' : 'password'}
                    // name="password"
                    addon={
                        val.showPassword ? (
                            <MdVisibilityOff
                                className="cursor-pointer w-5 h-5"
                                onClick={() => {
                                    setVal({
                                        showPassword: false,
                                    });
                                }}
                            />
                        ) : (
                            <MdVisibility
                                className="cursor-pointer w-5 h-5"
                                onClick={() => {
                                    setVal({
                                        showPassword: true,
                                    });
                                }}
                            />
                        )
                    }
                    helperText={helperText}
                    shadow={shadow}
                    color={color}
                />
            </div>
        </>
    );
};

export default PasswordInput;
