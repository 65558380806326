/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-shadow */
// import { Box, FormControl, MenuItem, Select, Tooltip } from '@mui/material';
// import React, { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
// import CustomButton from 'components/customButton/CustomButton';
// import InputField from 'components/inputField/InputField';
// import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
// import CustomBtnIcon from 'components/customButton/CustomBtnIcon';
// import RepeatIcon from '@mui/icons-material/Repeat';
// import { getProduct, updateProduct } from 'services/admin/product';
// import ProductImageUpload from 'components/productImageUpload/ProductImageUpload';
// import ProductImageUploading from 'components/productImageUploading/ProductImageUploading';
// import axios from 'axios';
// import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';
// import { shippingLines } from 'utils/helpers/shippingLines';
// import { countries } from 'utils/countryList';
// import toast from 'react-hot-toast';
// import {
//     addQuotation,
//     productDesignImagesContainer,
//     quotationOptionsContainer,
//     quotationOptionsWrapper,
//     quotationsContainer,
//     quotationsWrapper,
//     quotationVariantsContainer,
//     quotationVariantsWrapper,
//     variantsContainer,
//     variantsWrapper,
// } from './Quotation.styles';
// import QuotationOption from './QuotationOption';

// const clone = require('rfdc/default');

// interface IQuotation {
//     options: {
//         country: string;
//         packaging: string;
//         quantity: string;
//         shippingLine: string;
//         shippingLineFee: string;
//         type: string;
//         unitPrice: string;
//         accepted?: boolean;
//     }[];
//     // the variants key is an array of length 1 (just to keep the existing code working and not loosing too much time refactoring)
//     variants: {
//         createAt: string;
//         customer: string;
//         description: string;
//         images: string[];
//         name: string;
//         options: string[];
//         product: string;
//         quantity: number;
//         updatedAt: string;
//         _id: string;
//     }[];
// }

// const deduplicateQuotationOptions = (
//     quotations: IQuotation[],
// ): { result: IQuotation[]; duplicatedOptions: IQuotation[] } => {
//     const duplicatedOptions: IQuotation[] = [];
//     const result = quotations.map((quotation) => {
//         const deduplicatedOptions: IQuotation['options'] = [];
//         for (let i = 0; i < quotation.options.length; i++) {
//             const searchOption = quotation.options[i];
//             const exist = deduplicatedOptions.some((opt) => JSON.stringify(opt) === JSON.stringify(searchOption));
//             if (!exist) {
//                 deduplicatedOptions.push(searchOption);
//             } else {
//                 duplicatedOptions.push({ variants: [...quotation.variants], options: [searchOption] });
//             }
//         }
//         return { ...quotation, options: deduplicatedOptions };
//     });
//     return { result, duplicatedOptions };
// };
function BulkQuotation() {
    // const location: any = useLocation();
    // const [variants, setVariants] = useState<any>([]);
    // // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // const [_initialVariants, setInitialVariants] = useState<any>([]);

    // const [quotations, setQuotations] = useState<IQuotation[]>([]);
    // const [acceptedQuotations, setAcceptedQuotations] = useState<IQuotation[]>([]);
    // const [bulkQuantity, setBulkQuantity] = useState<any>(0);
    // const [customerCountries, setCustomerCountries] = useState<any>([]);
    // const [groupedCountries, setGroupedCountries] = useState<any>([]);
    // const [progressCount, setProgressCount] = useState(0);

    // useEffect(() => {
    //     getProduct(location.state.productId).then((res) => {
    //         if (res.data.product.quantity > 0) {
    //             setBulkQuantity(res.data.product.quantity);
    //         } else {
    //             setInitialVariants(res.data.product.variants);
    //         }
    //         setAcceptedQuotations(res.data.product.quotation);
    //         setCustomerCountries(res.data.product.shippingTo);
    //     });
    // }, []);

    // useEffect(() => {
    //     if (customerCountries && customerCountries.length > 0) {
    //         const options = countries.map((option) => {
    //             return {
    //                 group: customerCountries[0].split(',').includes(option.label)
    //                     ? 'User Countries'
    //                     : 'Other Countries',
    //                 ...option,
    //             };
    //         });
    //         const customerSelectedCountries = options.filter((option) =>
    //             customerCountries[0].split(',').includes(option.label),
    //         );
    //         const otherCountries = options.filter((option) => !customerCountries[0].split(',').includes(option.label));

    //         setGroupedCountries([...customerSelectedCountries, ...otherCountries]);
    //     }
    // }, [customerCountries]);

    // const [errors, setErrors] = useState<any>([]);
    // const [variantsErrors, setVariantsErrors] = useState<any>([]);

    // const [videoLink, setVideoLink] = useState('');
    // const [allImages, setAllImages] = useState<any>([]);
    // const onDragEnd = (result: DropResult) => {
    //     const { source, destination } = result;
    //     if (!destination) return;
    //     if (destination.droppableId === source.droppableId && destination.index === source.index) return;
    //     let add;
    //     const active = variants;
    //     const complete: any =
    //         destination.droppableId === 'variantList'
    //             ? [...quotations[Number(source.droppableId)].variants]
    //             : [...quotations[Number(destination.droppableId)].variants];
    //     // Source Logic
    //     if (source.droppableId === 'variantList') {
    //         add = active[source.index];
    //         active.splice(source.index, 1);
    //     } else {
    //         add = complete[source.index];
    //         complete.splice(source.index, 1);
    //     }

    //     // Destination Logic
    //     if (destination.droppableId === 'variantList') {
    //         active.splice(destination.index, 0, add);
    //     } else {
    //         complete.splice(destination.index, 0, add);
    //     }
    //     const quotationsCopy = [...quotations];

    //     if (destination.droppableId === 'variantList') {
    //         quotationsCopy[Number(source.droppableId)].variants = complete;
    //     } else {
    //         quotationsCopy[Number(destination.droppableId)].variants = complete;
    //     }

    //     setQuotations(quotationsCopy);
    //     setVariants(active);
    //     if (quotationsCopy[Number(destination.droppableId)].variants.length === 0) {
    //         const ve = [...variantsErrors];
    //         ve[Number(destination.droppableId)] = 'variant required';
    //         setVariantsErrors(ve);
    //     } else {
    //         const ve = [...variantsErrors];
    //         ve[Number(destination.droppableId)] = '';
    //         setVariantsErrors(ve);
    //     }
    // };

    return null;

    // return (
    //     <div>
    //         <Box>
    //             <DragDropContext onDragEnd={onDragEnd}>
    //                 {bulkQuantity === 0 && (
    //                     <Droppable droppableId="variantList">
    //                         {(provided) => (
    //                             <Box sx={variantsWrapper} ref={provided.innerRef} {...provided.droppableProps}>
    //                                 {variants &&
    //                                     variants.length > 0 &&
    //                                     variants.map((variant: any, index: number) => (
    //                                         <Draggable draggableId={variant._id} index={index}>
    //                                             {(provided) => (
    //                                                 <Box
    //                                                     {...provided.draggableProps}
    //                                                     {...provided.dragHandleProps}
    //                                                     ref={provided.innerRef}
    //                                                     sx={variantsContainer}
    //                                                 >
    //                                                     {variant.name}
    //                                                 </Box>
    //                                             )}
    //                                         </Draggable>
    //                                     ))}
    //                                 {provided.placeholder}
    //                             </Box>
    //                         )}
    //                     </Droppable>
    //                 )}

    //                 {bulkQuantity > 0 && <Box> Bulk Quantity: {bulkQuantity}</Box>}

    //                 <Box sx={addQuotation}>
    //                     <CustomButton
    //                         styleType="secondary"
    //                         sizeType="medium"
    //                         label="Add Quotation"
    //                         onClick={() => {
    //                             const x = [...quotations];
    //                             x.push({
    //                                 variants: [],
    //                                 options: [
    //                                     {
    //                                         type: '',
    //                                         quantity: '',
    //                                         unitPrice: '',
    //                                         shippingLine: '',
    //                                         shippingLineFee: '',
    //                                         country: '',
    //                                         packaging: '',
    //                                     },
    //                                 ],
    //                             });
    //                             setQuotations(x);
    //                             const y = [...errors];
    //                             y.push([
    //                                 {
    //                                     type: '',
    //                                     quantity: '',
    //                                     unitPrice: '',
    //                                     shippingLine: '',
    //                                     shippingLineFee: '',
    //                                     country: '',
    //                                     packaging: '',
    //                                 },
    //                             ]);
    //                             setErrors(y);
    //                             if (variants && variants.length > 0) {
    //                                 const z = [...variantsErrors];
    //                                 z.push('');
    //                                 setVariantsErrors(z);
    //                             }
    //                         }}
    //                     />
    //                 </Box>
    //                 <Box>
    //                     {acceptedQuotations && acceptedQuotations.length > 0 && (
    //                         <Box
    //                             sx={{
    //                                 ...quotationsWrapper(bulkQuantity === 0),
    //                                 alignItems: 'center',
    //                                 // position: 'sticky',
    //                                 top: '0',
    //                                 backgroundColor: 'secondaryBackground',
    //                                 // zIndex: '9999',
    //                             }}
    //                         >
    //                             {bulkQuantity === 0 && <Box>Variants</Box>}
    //                             <Box sx={quotationOptionsWrapper}>
    //                                 <Box
    //                                     sx={{
    //                                         ...quotationOptionsContainer,
    //                                         gridTemplateColumns: '1fr 1fr 1fr 2fr 1fr 2fr 1fr 1fr 1fr 1fr 1fr',
    //                                     }}
    //                                 >
    //                                     {' '}
    //                                     <Box>Moq</Box>
    //                                     <Box>Quantity</Box>
    //                                     <Box>Unit price</Box>
    //                                     <Box>Shipping line</Box>
    //                                     <Box>Shipping fee</Box>
    //                                     <Box>Country</Box>
    //                                     <Box>Packaging</Box>
    //                                     <Box>Total price</Box>
    //                                     <Box>Edit</Box>
    //                                     <Box>Delete</Box>
    //                                     <Box>Accept</Box>
    //                                 </Box>
    //                             </Box>
    //                         </Box>
    //                     )}
    //                     <Box sx={{ maxheight: '50vh', minHeight: '0px', overflowY: 'scroll', mb: 2 }}>
    //                         {acceptedQuotations &&
    //                             acceptedQuotations.length > 0 &&
    //                             groupedCountries &&
    //                             acceptedQuotations.map((quotation, index: number) => (
    //                                 <Box>
    //                                     <Box sx={{ ...quotationsWrapper(bulkQuantity === 0), alignItems: 'center' }}>
    //                                         <Box sx={quotationsContainer(bulkQuantity === 0)}>
    //                                             <Box sx={quotationVariantsWrapper}>
    //                                                 {quotation.variants.map((variant: any) => (
    //                                                     <Box sx={quotationVariantsContainer}>{variant.name}</Box>
    //                                                 ))}
    //                                             </Box>
    //                                         </Box>

    //                                         <Box sx={quotationOptionsWrapper}>
    //                                             {quotation.options.map((option: any, optionIndex: number) => (
    //                                                 // <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
    //                                                 <QuotationOption
    //                                                     option={option}
    //                                                     groupedCountries={groupedCountries}
    //                                                     productId={location.state.productId}
    //                                                     optionIndex={optionIndex}
    //                                                     quotationIndex={index}
    //                                                     quotationOptions={quotation.options}
    //                                                     quotationVariants={quotation.variants}
    //                                                     acceptedQuotations={acceptedQuotations}
    //                                                     setAcceptedQuotations={setAcceptedQuotations}
    //                                                     setBulkQuantity={setBulkQuantity}
    //                                                 />
    //                                                 // </Box>
    //                                             ))}
    //                                         </Box>
    //                                     </Box>
    //                                 </Box>
    //                             ))}
    //                     </Box>
    //                     <Box
    //                         sx={{
    //                             ...quotationsWrapper(bulkQuantity === 0),
    //                             alignItems: 'center',
    //                             // position: 'sticky',
    //                             top: '0',
    //                             backgroundColor: 'secondaryBackground',
    //                             // zIndex: '1',
    //                         }}
    //                     >
    //                         {bulkQuantity === 0 && <Box>Variants</Box>}
    //                         <Box sx={quotationOptionsWrapper}>
    //                             <Box sx={quotationOptionsContainer}>
    //                                 {' '}
    //                                 <Box>Moq</Box>
    //                                 <Box>Quantity</Box>
    //                                 <Box>Unit price</Box>
    //                                 <Box>Shipping line</Box>
    //                                 <Box>Shipping fee</Box>
    //                                 <Box>Country</Box>
    //                                 <Box>Packaging</Box>
    //                                 <Box>Total price</Box>
    //                             </Box>
    //                         </Box>
    //                     </Box>
    //                     <Box>
    //                         {quotations &&
    //                             quotations.length > 0 &&
    //                             quotations.map((quotation: any, index: number) => (
    //                                 <Box>
    //                                     <Box sx={quotationsWrapper(bulkQuantity === 0)}>
    //                                         <Box sx={quotationsContainer(bulkQuantity === 0)}>
    //                                             <Droppable droppableId={`${index}`}>
    //                                                 {(provided) => (
    //                                                     <Box
    //                                                         sx={quotationVariantsWrapper}
    //                                                         ref={provided.innerRef}
    //                                                         {...provided.droppableProps}
    //                                                     >
    //                                                         {quotation.variants.map((variant: any, idx: number) => (
    //                                                             <Draggable draggableId={variant._id} index={idx}>
    //                                                                 {(provided) => (
    //                                                                     <Box
    //                                                                         {...provided.draggableProps}
    //                                                                         {...provided.dragHandleProps}
    //                                                                         ref={provided.innerRef}
    //                                                                         sx={quotationVariantsContainer}
    //                                                                     >
    //                                                                         <Box
    //                                                                             sx={{
    //                                                                                 display: 'flex',
    //                                                                                 gap: '5px',
    //                                                                                 alignItems: 'center',
    //                                                                             }}
    //                                                                         >
    //                                                                             <Box> {variant.name} </Box>
    //                                                                             <Box
    //                                                                                 sx={{
    //                                                                                     cursor: 'pointer',
    //                                                                                     display: 'flex',
    //                                                                                     gap: '5px',
    //                                                                                     alignItems: 'center',
    //                                                                                 }}
    //                                                                                 onClick={() => {
    //                                                                                     const qt = [...quotations];
    //                                                                                     qt[index].variants.splice(
    //                                                                                         idx,
    //                                                                                         1,
    //                                                                                     );
    //                                                                                     setQuotations(qt);
    //                                                                                 }}
    //                                                                             >
    //                                                                                 <RemoveCircleIcon />
    //                                                                             </Box>
    //                                                                         </Box>
    //                                                                     </Box>
    //                                                                 )}
    //                                                             </Draggable>
    //                                                         ))}
    //                                                         {provided.placeholder}
    //                                                     </Box>
    //                                                 )}
    //                                             </Droppable>
    //                                             <Box sx={{ fontSize: '10px', color: 'red' }}>
    //                                                 {variantsErrors[index]}
    //                                             </Box>
    //                                         </Box>

    //                                         <Box sx={quotationOptionsWrapper}>
    //                                             {quotation.options.map((_option: any, optIndex: number) => (
    //                                                 <Box>
    //                                                     <Box sx={quotationOptionsContainer}>
    //                                                         <Box>
    //                                                             <Box>
    //                                                                 <InputField
    //                                                                     placeholder="MOQ"
    //                                                                     value={quotations[index].options[optIndex].type}
    //                                                                     onChange={(e) => {
    //                                                                         const qt = [...quotations];
    //                                                                         qt[index].options[optIndex].type =
    //                                                                             e.target.value;
    //                                                                         setQuotations(qt);
    //                                                                         if (e.target.value.length > 0) {
    //                                                                             if (isNaN(Number(e.target.value))) {
    //                                                                                 const err = [...errors];
    //                                                                                 err[index][optIndex].type =
    //                                                                                     'number field';
    //                                                                                 setErrors(err);
    //                                                                             } else {
    //                                                                                 const err = [...errors];
    //                                                                                 err[index][optIndex].type = '';
    //                                                                                 setErrors(err);
    //                                                                             }
    //                                                                         } else {
    //                                                                             const err = [...errors];
    //                                                                             err[index][optIndex].type = '';
    //                                                                             setErrors(err);
    //                                                                         }
    //                                                                     }}
    //                                                                 />
    //                                                             </Box>
    //                                                         </Box>
    //                                                         <Box>
    //                                                             <InputField
    //                                                                 placeholder="quantity"
    //                                                                 value={quotations[index].options[optIndex].quantity}
    //                                                                 onChange={(e) => {
    //                                                                     const qt = [...quotations];
    //                                                                     qt[index].options[optIndex].quantity =
    //                                                                         e.target.value;
    //                                                                     setQuotations(qt);

    //                                                                     if (isNaN(Number(e.target.value))) {
    //                                                                         const err = [...errors];
    //                                                                         err[index][optIndex].quantity =
    //                                                                             'number field';
    //                                                                         setErrors(err);
    //                                                                     } else if (e.target.value.length === 0) {
    //                                                                         const err = [...errors];
    //                                                                         err[index][optIndex].quantity = 'required';
    //                                                                         setErrors(err);
    //                                                                     } else {
    //                                                                         const err = [...errors];
    //                                                                         err[index][optIndex].quantity = '';
    //                                                                         setErrors(err);
    //                                                                     }
    //                                                                 }}
    //                                                             />
    //                                                         </Box>
    //                                                         <InputField
    //                                                             placeholder="unit price"
    //                                                             value={quotations[index].options[optIndex].unitPrice}
    //                                                             onChange={(e) => {
    //                                                                 const qt = [...quotations];
    //                                                                 qt[index].options[optIndex].unitPrice =
    //                                                                     e.target.value;
    //                                                                 setQuotations(qt);
    //                                                                 if (isNaN(Number(e.target.value))) {
    //                                                                     const err = [...errors];
    //                                                                     err[index][optIndex].unitPrice = 'number field';
    //                                                                     setErrors(err);
    //                                                                 } else if (e.target.value.length === 0) {
    //                                                                     const err = [...errors];
    //                                                                     err[index][optIndex].unitPrice = 'required';
    //                                                                     setErrors(err);
    //                                                                 } else {
    //                                                                     const err = [...errors];
    //                                                                     err[index][optIndex].unitPrice = '';
    //                                                                     setErrors(err);
    //                                                                 }
    //                                                             }}
    //                                                         />
    //                                                         <FormControl>
    //                                                             <Select
    //                                                                 id={`selectId${optIndex}`}
    //                                                                 placeholder="shipping line"
    //                                                                 name="shippingLine"
    //                                                                 value={
    //                                                                     quotations[index].options[optIndex].shippingLine
    //                                                                 }
    //                                                                 onChange={(e) => {
    //                                                                     const qt = [...quotations];
    //                                                                     qt[index].options[optIndex].shippingLine =
    //                                                                         e.target.value;
    //                                                                     setQuotations(qt);
    //                                                                     if (e.target.value.length === 0) {
    //                                                                         const err = [...errors];
    //                                                                         err[index][optIndex].shippingLine =
    //                                                                             'required';
    //                                                                         setErrors(err);
    //                                                                     } else {
    //                                                                         const err = [...errors];
    //                                                                         err[index][optIndex].shippingLine = '';
    //                                                                         setErrors(err);
    //                                                                     }
    //                                                                 }}
    //                                                             >
    //                                                                 {shippingLines.map((shippingLine: any) => (
    //                                                                     <MenuItem
    //                                                                         value={shippingLine}
    //                                                                         key={shippingLine}
    //                                                                     >
    //                                                                         {shippingLine}
    //                                                                     </MenuItem>
    //                                                                 ))}
    //                                                             </Select>
    //                                                         </FormControl>

    //                                                         <InputField
    //                                                             placeholder="shipping fee"
    //                                                             value={
    //                                                                 quotations[index].options[optIndex].shippingLineFee
    //                                                             }
    //                                                             onChange={(e) => {
    //                                                                 const qt = [...quotations];
    //                                                                 qt[index].options[optIndex].shippingLineFee =
    //                                                                     e.target.value;
    //                                                                 setQuotations(qt);
    //                                                                 if (isNaN(Number(e.target.value))) {
    //                                                                     const err = [...errors];
    //                                                                     err[index][optIndex].shippingLineFee =
    //                                                                         'number field';
    //                                                                     setErrors(err);
    //                                                                 } else if (e.target.value.length === 0) {
    //                                                                     const err = [...errors];
    //                                                                     err[index][optIndex].shippingLineFee =
    //                                                                         'required';
    //                                                                     setErrors(err);
    //                                                                 } else {
    //                                                                     const err = [...errors];
    //                                                                     err[index][optIndex].shippingLineFee = '';
    //                                                                     setErrors(err);
    //                                                                 }
    //                                                             }}
    //                                                         />

    //                                                         {groupedCountries && (
    //                                                             <Autocomplete
    //                                                                 id="grouped-demo"
    //                                                                 onChange={(_e, newValue: any) => {
    //                                                                     const qt = [...quotations];
    //                                                                     qt[index].options[optIndex].country =
    //                                                                         newValue.label;
    //                                                                     setQuotations(qt);
    //                                                                     if (!newValue) {
    //                                                                         const err = [...errors];
    //                                                                         err[index][optIndex].country = 'required';
    //                                                                         setErrors(err);
    //                                                                     } else {
    //                                                                         const err = [...errors];
    //                                                                         err[index][optIndex].country = '';
    //                                                                         setErrors(err);
    //                                                                     }
    //                                                                 }}
    //                                                                 options={groupedCountries}
    //                                                                 groupBy={(option: any) => option.group}
    //                                                                 getOptionLabel={(option) => option.label}
    //                                                                 sx={{ width: '100%' }}
    //                                                                 renderInput={(params) => (
    //                                                                     <TextField
    //                                                                         {...params}
    //                                                                         label="Country"
    //                                                                         sx={{ width: '100%' }}
    //                                                                     />
    //                                                                 )}
    //                                                             />
    //                                                         )}
    //                                                         <InputField
    //                                                             placeholder="packaging"
    //                                                             value={quotations[index].options[optIndex].packaging}
    //                                                             onChange={(e) => {
    //                                                                 const qt = [...quotations];
    //                                                                 qt[index].options[optIndex].packaging =
    //                                                                     e.target.value;
    //                                                                 setQuotations(qt);
    //                                                             }}
    //                                                         />
    //                                                         <Box>
    //                                                             {!Number.isNaN(
    //                                                                 Number(
    //                                                                     quotations[index].options[optIndex].unitPrice,
    //                                                                 ) +
    //                                                                     Number(
    //                                                                         quotations[index].options[optIndex]
    //                                                                             .shippingLineFee,
    //                                                                     ),
    //                                                             ) &&
    //                                                                 Number(
    //                                                                     quotations[index].options[optIndex].unitPrice,
    //                                                                 ) +
    //                                                                     Number(
    //                                                                         quotations[index].options[optIndex]
    //                                                                             .shippingLineFee,
    //                                                                     )}{' '}
    //                                                         </Box>
    //                                                         <Box sx={{ display: 'flex' }}>
    //                                                             {/* Duplicate row */}
    //                                                             <CustomBtnIcon
    //                                                                 color="primary"
    //                                                                 icon={
    //                                                                     <Tooltip title="Duplicate Row">
    //                                                                         <SubdirectoryArrowLeftIcon />
    //                                                                     </Tooltip>
    //                                                                 }
    //                                                                 onClick={() => {
    //                                                                     const qt = [...quotations];
    //                                                                     const x = { ...qt[index].options[optIndex] };
    //                                                                     qt[index].options.splice(optIndex + 1, 0, x);
    //                                                                     setQuotations(qt);
    //                                                                     const err = [...errors];
    //                                                                     const y = clone(err[index][optIndex]);
    //                                                                     err[index].splice(optIndex + 1, 0, y);
    //                                                                     setErrors(err);
    //                                                                 }}
    //                                                             />
    //                                                             {quotations[index].options.length > 1 && (
    //                                                                 <CustomBtnIcon
    //                                                                     color="primary"
    //                                                                     icon={
    //                                                                         <Tooltip title="Delete row">
    //                                                                             <DeleteForeverIcon
    //                                                                                 sx={{
    //                                                                                     color: 'textColor.errorColor',
    //                                                                                 }}
    //                                                                             />
    //                                                                         </Tooltip>
    //                                                                     }
    //                                                                     onClick={() => {
    //                                                                         const qt = [...quotations];
    //                                                                         qt[index].options.splice(optIndex, 1);
    //                                                                         setQuotations(qt);
    //                                                                         const err = [...errors];
    //                                                                         err[index].splice(optIndex, 1);
    //                                                                         setErrors(err);
    //                                                                     }}
    //                                                                 />
    //                                                             )}
    //                                                         </Box>
    //                                                     </Box>
    //                                                     <Box sx={quotationOptionsContainer}>
    //                                                         <Box sx={{ fontSize: '10px', color: 'red' }}>
    //                                                             {errors[index][optIndex].type}
    //                                                         </Box>
    //                                                         <Box sx={{ fontSize: '10px', color: 'red' }}>
    //                                                             {errors[index][optIndex].quantity}
    //                                                         </Box>
    //                                                         <Box sx={{ fontSize: '10px', color: 'red' }}>
    //                                                             {errors[index][optIndex].unitPrice}
    //                                                         </Box>
    //                                                         <Box sx={{ fontSize: '10px', color: 'red' }}>
    //                                                             {errors[index][optIndex].shippingLine}
    //                                                         </Box>
    //                                                         <Box sx={{ fontSize: '10px', color: 'red' }}>
    //                                                             {errors[index][optIndex].shippingLineFee}
    //                                                         </Box>
    //                                                         <Box sx={{ fontSize: '10px', color: 'red' }}>
    //                                                             {errors[index][optIndex].country}
    //                                                         </Box>
    //                                                         <Box />
    //                                                     </Box>
    //                                                 </Box>
    //                                             ))}
    //                                         </Box>
    //                                         <Box
    //                                             sx={{
    //                                                 display: 'flex',
    //                                                 flexDirection: 'column',
    //                                                 justifyContent: 'center',
    //                                             }}
    //                                         >
    //                                             <CustomBtnIcon
    //                                                 color="primary"
    //                                                 icon={
    //                                                     <Tooltip title="Duplicate Quotation" placement="top">
    //                                                         <RepeatIcon />
    //                                                     </Tooltip>
    //                                                 }
    //                                                 onClick={() => {
    //                                                     const qt: any = clone(quotations);
    //                                                     const x = clone(qt[index].options);
    //                                                     const y = clone(qt[index].variants);
    //                                                     qt.splice(index + 1, 0, { variants: y, options: x });
    //                                                     setQuotations(qt);
    //                                                     const err: any = clone(errors);
    //                                                     const z = clone(err[index]);
    //                                                     err.splice(index + 1, 0, z);
    //                                                     setErrors(err);
    //                                                     const a: any = clone(variantsErrors);
    //                                                     a.splice(index + 1, 0, a[index]);
    //                                                     setVariantsErrors(a);
    //                                                 }}
    //                                             />

    //                                             <CustomBtnIcon
    //                                                 color="primary"
    //                                                 icon={
    //                                                     <Tooltip title="Delete Quotation">
    //                                                         <DeleteForeverIcon sx={{ color: 'textColor.errorColor' }} />
    //                                                     </Tooltip>
    //                                                 }
    //                                                 onClick={() => {
    //                                                     const qt: any = clone(quotations);

    //                                                     qt.splice(index, 1);
    //                                                     setQuotations(qt);
    //                                                     const err: any = clone(errors);

    //                                                     err.splice(index, 1);
    //                                                     setErrors(err);
    //                                                     const a: any = clone(variantsErrors);
    //                                                     a.splice(index, 1);
    //                                                     setVariantsErrors(a);
    //                                                 }}
    //                                             />
    //                                         </Box>
    //                                     </Box>
    //                                 </Box>
    //                             ))}
    //                     </Box>
    //                 </Box>
    //             </DragDropContext>
    //         </Box>
    //         <Box sx={{ margin: '20px 0px' }}>
    //             {allImages.length > 0 ? (
    //                 <Box sx={productDesignImagesContainer}>
    //                     {allImages.length > 0 &&
    //                         allImages.map((image: any, index: number) => (
    //                             <ProductImageUploading
    //                                 title={image.name}
    //                                 img={image.url}
    //                                 progress={progressCount}
    //                                 index={index}
    //                                 allImages={allImages}
    //                                 setAllImages={setAllImages}
    //                             />
    //                         ))}
    //                 </Box>
    //             ) : (
    //                 <Box>
    //                     <ProductImageUpload
    //                         name="photo"
    //                         onChange={(e) => {
    //                             const file = e.target.files instanceof FileList && e.target.files[0];
    //                             if (file) {
    //                                 const reader = new FileReader();
    //                                 // eslint-disable-next-line @typescript-eslint/no-shadow
    //                                 reader.onload = () => {
    //                                     setAllImages([
    //                                         ...allImages,
    //                                         {
    //                                             file,
    //                                             name: file.name,
    //                                             url: URL.createObjectURL(file),
    //                                         },
    //                                     ]);
    //                                 };
    //                                 reader.readAsDataURL(file);
    //                                 const formData = new FormData();
    //                                 formData.append('image', URL.createObjectURL(file));
    //                                 const config = {
    //                                     onUploadProgress: (progressEvent: any) => {
    //                                         const completed = (progressEvent.loaded / progressEvent.total) * 50;

    //                                         setProgressCount(completed);
    //                                     },
    //                                     onDownloadProgress: (progressEvent: any) => {
    //                                         const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;

    //                                         setProgressCount(progress);
    //                                     },
    //                                 };
    //                                 axios.post('https://httpbin.org/post', formData, config);
    //                             }
    //                         }}
    //                     />
    //                 </Box>
    //             )}
    //             {allImages.length > 0 && (
    //                 <ProductImageUpload
    //                     onChange={(e) => {
    //                         const file = e.target.files instanceof FileList && e.target.files[0];
    //                         if (file) {
    //                             const reader = new FileReader();
    //                             // eslint-disable-next-line @typescript-eslint/no-shadow
    //                             reader.onload = () => {
    //                                 setAllImages([
    //                                     ...allImages,
    //                                     {
    //                                         file,
    //                                         name: file.name,
    //                                         url: URL.createObjectURL(file),
    //                                     },
    //                                 ]);
    //                             };
    //                             reader.readAsDataURL(file);
    //                         }
    //                     }}
    //                     userDisplayedImage={allImages}
    //                 />
    //             )}
    //             <Box sx={{ margin: '20px 0px' }}>
    //                 <InputField
    //                     label="Video Link"
    //                     placeholder="Video Link"
    //                     onChange={(e) => {
    //                         setVideoLink(e.target.value);
    //                     }}
    //                 />
    //             </Box>
    //         </Box>
    //         {quotations && quotations.length > 0 && (
    //             <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '20px 0px' }}>
    //                 <CustomButton
    //                     sizeType="medium"
    //                     styleType="primary"
    //                     label="Submit Quotation"
    //                     onClick={() => {
    //                         let noVariantsErrors = false;
    //                         if (bulkQuantity === 0) {
    //                             const ve = [...variantsErrors];
    //                             for (let i = 0; i < quotations.length; i++) {
    //                                 if (quotations[i].variants.length === 0) {
    //                                     ve.splice(i, 1, 'variant required');
    //                                 }
    //                             }
    //                             setVariantsErrors(ve);
    //                             let s = 0;
    //                             for (let j = 0; j < ve.length; j++) {
    //                                 if (ve[j] === '') s++;
    //                             }
    //                             if (s === ve.length) noVariantsErrors = true;
    //                         } else {
    //                             noVariantsErrors = true;
    //                         }

    //                         const err = [...errors];
    //                         for (let i = 0; i < quotations.length; i++) {
    //                             for (let c = 0; c < quotations[i].options.length; c++) {
    //                                 // if (quotations[i].options[c].type === '') {
    //                                 //     err[i][c].type = 'required';
    //                                 // }
    //                                 if (quotations[i].options[c].quantity === '') {
    //                                     err[i][c].quantity = 'required';
    //                                 }
    //                                 if (quotations[i].options[c].unitPrice === '') {
    //                                     err[i][c].unitPrice = 'required';
    //                                 }
    //                                 if (quotations[i].options[c].shippingLine === '') {
    //                                     err[i][c].shippingLine = 'required';
    //                                 }
    //                                 if (quotations[i].options[c].shippingLineFee === '') {
    //                                     err[i][c].shippingLineFee = 'required';
    //                                 }
    //                                 if (quotations[i].options[c].country === '') {
    //                                     err[i][c].country = 'required';
    //                                 }
    //                             }
    //                         }
    //                         setErrors(err);

    //                         let b = 0;
    //                         for (let h = 0; h < err.length; h++) {
    //                             let a = 0;
    //                             for (let k = 0; k < err[h].length; k++) {
    //                                 const isEmpty = Object.values(err[h][k]).every((x) => x === '');
    //                                 if (isEmpty) a++;
    //                             }
    //                             if (a === err[h].length) {
    //                                 b++;
    //                             }
    //                         }

    //                         if (noVariantsErrors && b === err.length) {
    //                             const formData = new FormData();
    //                             let groupedQuotationsMap = new Map<string, IQuotation>();
    //                             let groupedQuotations: IQuotation[] = [];

    //                             quotations.forEach((quotation, idx) => {
    //                                 const grouped = new Map<string, IQuotation>();
    //                                 if (quotation.variants.length > 0) {
    //                                     quotation.variants.forEach((variant) => {
    //                                         const value = {
    //                                             variants: [variant],
    //                                             options: quotation.options,
    //                                         };
    //                                         // if (quotation.accepted && quotation.accepted === true) {
    //                                         //     Object.assign(value, { accepted: quotation.accepted });
    //                                         // }
    //                                         if (grouped.has(variant._id)) {
    //                                             const mapValue = grouped.get(variant._id)!;
    //                                             mapValue.options = [...mapValue.options, ...value.options];
    //                                             grouped.set(variant._id, mapValue);
    //                                         } else {
    //                                             grouped.set(variant._id, value);
    //                                         }
    //                                     });
    //                                     groupedQuotationsMap = new Map([...groupedQuotationsMap, ...grouped]);
    //                                 } else {
    //                                     const value = new Map<string, IQuotation>();

    //                                     value.set(idx.toString(), { options: quotation.options, variants: [] });
    //                                     groupedQuotationsMap = new Map([...groupedQuotationsMap, ...value]);
    //                                 }
    //                             });
    //                             if (acceptedQuotations && acceptedQuotations.length > 0) {
    //                                 acceptedQuotations.forEach((quotation, idx) => {
    //                                     if (quotation.variants.length > 0) {
    //                                         quotation.variants.forEach((variant) => {
    //                                             const value = {
    //                                                 variants: quotation.variants,
    //                                                 options: quotation.options,
    //                                             };
    //                                             // if (quotation.accepted && quotation.accepted === true) {
    //                                             //     Object.assign(value, { accepted: quotation.accepted });
    //                                             // }
    //                                             if (groupedQuotationsMap.has(variant._id)) {
    //                                                 const mapValue = groupedQuotationsMap.get(variant._id)!;

    //                                                 const mergedOptions = [...mapValue.options, ...value.options];
    //                                                 groupedQuotationsMap.set(variant._id, {
    //                                                     ...mapValue,
    //                                                     options: mergedOptions,
    //                                                 });
    //                                             } else {
    //                                                 groupedQuotationsMap.set(variant._id, value);
    //                                             }
    //                                         });
    //                                     } else {
    //                                         const value = {
    //                                             variants: quotation.variants,
    //                                             options: quotation.options,
    //                                         };
    //                                         groupedQuotationsMap.set(`accepted-${idx}`, value);
    //                                     }
    //                                 });
    //                             }
    //                             // eslint-disable-next-line @typescript-eslint/no-unused-vars
    //                             groupedQuotations = Array.from(groupedQuotationsMap, ([_idx, value]) => value);

    //                             // deduplicate quotations
    //                             const { result, duplicatedOptions } = deduplicateQuotationOptions(groupedQuotations);
    //                             if (duplicatedOptions.length > 0) {
    //                                 toast.error(
    //                                     `${duplicatedOptions.length} duplicated options detected and ignored!`,
    //                                     {
    //                                         iconTheme: {
    //                                             primary: '#E3A008',
    //                                             secondary: '#FFFAEE',
    //                                         },
    //                                     },
    //                                 );
    //                             }
    //                             formData.append('quotation', JSON.stringify(result));

    //                             formData.append('video', videoLink);
    //                             formData.append('status', '3');
    //                             allImages.map((file: any) => {
    //                                 return formData.append(file.name, file.file);
    //                             });
    //                             updateProduct({
    //                                 id: location.state.productId,
    //                                 updatedData: formData,
    //                             })
    //                                 .then(() => {
    //                                     toast.success('Product Quotation Added');
    //                                     setTimeout(() => {
    //                                         window.location.reload();
    //                                     }, 3000);
    //                                     // getProduct(location.state.productId).then((res) => {
    //                                     //     setAcceptedQuotations(res.data.product.quotation);
    //                                     //     setQuotations([]);
    //                                     //     if (res.data.product.quantity > 0) {
    //                                     //         setBulkQuantity(res.data.product.quantity);
    //                                     //     } else {
    //                                     //         setVariants(res.data.product.variants);
    //                                     //     }
    //                                     // });
    //                                 })

    //                                 .catch((err) => {
    //                                     toast.error(err.response.data.errors.message);
    //                                 });
    //                         }
    //                     }}
    //                 />
    //             </Box>
    //         )}
    //     </div>
    // );
}

export default BulkQuotation;
