/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Sidebar } from 'flowbite-react';
import routes from 'routes';
import { ReactComponent as Lgst } from '../../assets/LogistioLogo.svg';
import { ReactComponent as LgstSmall } from '../../assets/LogistioLogocollapsed.svg';

const SourcingAgentSideBar: React.FC<{ collapsed: boolean }> = ({ collapsed }) => {
    const { pathname } = useLocation();

    return (
        <>
            <Sidebar
                aria-label="Default sidebar example"
                collapsed={collapsed}
                // className={` ${collapsed ? '!w-16' : '!w-52'} `}
                theme={{
                    root: {
                        // base: '!bg-red-400 ',
                        collapsed: {
                            off: '!w-56',
                            on: '!w-16',
                        },
                    },
                }}
            >
                <Link to="/accountManager/">
                    <div className="flex justify-center items-center mb-2">
                        {!collapsed ? (
                            <Lgst fill="var(--logoColor)" stroke="var(--logoColor)" height={45} />
                        ) : (
                            <LgstSmall fill="var(--logoColor)" stroke="var(--logoColor)" height={35} />
                        )}
                    </div>
                </Link>
                <Sidebar.Items>
                    {/* <Sidebar.ItemGroup>
                        {routes.map((prop: any, index: number) => {
                            if (prop.type === 'adminFeatures') {
                                return (
                                    <Link to={`${prop.layout}/${prop.path}`}>
                                        <Sidebar.Item key={index}>
                                            <prop.icon /> {prop.name}
                                        </Sidebar.Item>
                                    </Link>
                                );
                            }
                        })}
                    </Sidebar.ItemGroup> */}
                    <Sidebar.ItemGroup>
                        {routes.map((prop: any, index: number) => {
                            if (prop.type === 'ProductProviderFeatures') {
                                const path = `${prop.layout}/${prop.path}`;
                                return (
                                    <Link to={`${prop.layout}/${prop.path}`}>
                                        <div
                                            key={index}
                                            className={`flex  items-center text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 ${
                                                path === pathname ? 'dark:bg-gray-700 bg-gray-100' : ''
                                            }`}
                                        >
                                            {path === pathname && !collapsed && (
                                                <div className="h-3 w-0.5 dark:bg-gray-200 bg-gray-400 mr-2" />
                                            )}
                                            <div className="flex p-2">
                                                <prop.icon
                                                    className={`w-6 h-6 text-gray-500 transition duration-75  group-hover:text-gray-900 dark:group-hover:text-white ${
                                                        path === pathname ? 'dark:text-white' : 'dark:text-gray-400'
                                                    }`}
                                                />
                                                {!collapsed && <p className="ml-3">{prop.name}</p>}
                                            </div>
                                        </div>
                                    </Link>
                                );
                            }
                        })}
                    </Sidebar.ItemGroup>

                    <Sidebar.ItemGroup>
                        {routes.map((prop: any, index: number) => {
                            if (prop.type === 'ProductProviderParameters') {
                                const path = `${prop.layout}/${prop.path}`;
                                return (
                                    <Link to={`${prop.layout}/${prop.path}`}>
                                        <div
                                            key={index}
                                            className={`flex items-center  text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 ${
                                                path === pathname ? 'dark:bg-gray-700 bg-gray-200' : ''
                                            }`}
                                        >
                                            {path === pathname && !collapsed && (
                                                <div className="h-3 w-0.5 dark:bg-gray-200 bg-gray-400 mr-2" />
                                            )}

                                            <div className="flex p-2">
                                                <prop.icon
                                                    className={`w-6 h-6 text-gray-500 transition duration-75  group-hover:text-gray-900 dark:group-hover:text-white ${
                                                        path === pathname ? 'dark:text-white' : 'dark:text-gray-400'
                                                    }`}
                                                />
                                                {!collapsed && <div className="ml-3">{prop.name}</div>}
                                            </div>
                                        </div>
                                    </Link>
                                );
                            }
                        })}
                    </Sidebar.ItemGroup>
                </Sidebar.Items>
            </Sidebar>
        </>
    );
};

export default SourcingAgentSideBar;
