/* eslint-disable no-underscore-dangle */
import { Button, Modal } from 'flowbite-react';
import { HiInformationCircle } from 'react-icons/hi';
import React, { useState } from 'react';
import WalletOperation from 'pages/walletOperationsList/OperationDetails';
import { IOperationActions } from '../walletOperartions.types';

const PaymentActions = (props: IOperationActions) => {
    const [ModalDetails, setModalDetails] = useState(false);
    const DetailsModalOpen = () => {
        setModalDetails(true);
    };
    const DetailsModalClose = () => {
        setModalDetails(false);
    };
    const { operation, setRows, currentPage, currentFilter } = props;

    return (
        <>
            <Modal dismissible show={ModalDetails} popup onClose={DetailsModalClose}>
                <Modal.Header>Wallet Operation</Modal.Header>
                <Modal.Body>
                    <WalletOperation
                        operationData={operation as any}
                        setRows={setRows}
                        currentPage={currentPage}
                        currentFilter={currentFilter}
                    />
                </Modal.Body>
            </Modal>
            <Button onClick={DetailsModalOpen} pill size="xs">
                <HiInformationCircle className="mr-2 h-5 w-5" color="white" />
                Details
            </Button>
        </>
    );
};
export default PaymentActions;
