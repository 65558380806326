import * as Yup from 'yup';

export const UpdateCreditsInformations = Yup.object().shape({
    creditAmount: Yup.number().typeError('"Credit for ADS" must be a number').required(),
    adsPercent: Yup.number().typeError('"ADS invoice percentage" must be a number').required(),
});

export const UpdateCreditsInformationsForCogs = Yup.object().shape({
    creditAmount: Yup.number().typeError('"Credit amount" must be a number').required(),
    cogsUnitPrice: Yup.number().typeError('Unit Price must be a number').required(),
    product: Yup.string().min(10, 'Field required').required('Field required'),
});
