/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { ICredit, getAllCredits } from 'services/accountManager/credits';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { AiTwotoneThunderbolt } from 'react-icons/ai';
import { MdAdsClick, MdQueryStats } from 'react-icons/md';
import Datepicker from 'react-tailwindcss-datepicker';
import { currencyConverter } from 'utils/currencyConverter';

interface IfilterStats {
    dateRange?: { startDate?: string | number; endDate?: string | number };
}
interface IcareditStats {
    totalCredit?: number;
    creditAds?: number;
    creditCogs?: number;
    totalCreditsNumber?: number;
    cogsCreditsNumber?: number;
    adsCreditsNumber?: number;
}
export const CreditStatistic: React.FC = () => {
    const [filter, setFilter] = useState<IfilterStats>({});
    const [Currency] = useState('USD');
    const [dateRange, setRangeValue] = useState(null);
    const [creditsOut, setCreditsOut] = useState<IcareditStats>({
        totalCredit: 0,
        creditAds: 0,
        creditCogs: 0,
        totalCreditsNumber: 0,
        cogsCreditsNumber: 0,
        adsCreditsNumber: 0,
    });
    const [creditPayments, setCreditPayments] = useState<IcareditStats>({
        totalCredit: 0,
        creditAds: 0,
        creditCogs: 0,
        totalCreditsNumber: 0,
        cogsCreditsNumber: 0,
        adsCreditsNumber: 0,
    });
    const handleRangeFilterChange = (newValue: any) => {
        let newRange: typeof newValue | null;
        if (newValue && newValue.startDate && newValue.endDate) {
            const startD = moment.tz(newValue.startDate, 'Asia/Shanghai');
            const endD = moment.tz(newValue.endDate, 'Asia/Shanghai');
            endD.set('hour', 23);
            endD.set('minute', 59);
            endD.set('second', 59);
            newRange = {
                startDate: startD.format(),
                endDate: endD.format(),
            };
        }
        setRangeValue(newValue || null);

        setFilter({ dateRange: newRange });
    };
    useEffect(() => {
        getAllCredits(filter)
            .then(async (res) => {
                const creditData: ICredit[] = res?.data;
                const totalCredits = creditData.reduce(
                    (accumulator, currentValue) => accumulator + currentValue?.creditAmount,
                    0,
                );
                const totalCreditsNumber = creditData.length;
                const cogsCreditsNumber = creditData.filter((item) => item.creditType === 'Cogs').length;
                const adsCreditsNumber = creditData.filter((item) => item.creditType === 'Ads').length;
                const payedCreditsNumber = creditData.filter((item) => item.status === 3).length;
                const cogsPayedCreditsNumber = creditData.filter(
                    (item) => item.creditType === 'Cogs' && item.status === 3,
                ).length;
                const adsPayedCreditsNumber = creditData.filter(
                    (item) => item.creditType === 'Ads' && item.status === 3,
                ).length;
                const totalCreditsAds = creditData
                    .filter((item) => item.creditType === 'Ads')
                    .reduce((accumulator, currentValue) => accumulator + currentValue?.creditAmount, 0);
                const totalCreditsCogs = creditData
                    .filter((item) => item.creditType === 'Cogs')
                    .reduce((accumulator, currentValue) => accumulator + currentValue?.creditAmount, 0);
                const totalPaymentsCredit = creditData.reduce(
                    (accumulator, currentValue) => accumulator + currentValue?.payedCredit,
                    0,
                );
                const totalPaymentsCreditsAds = creditData
                    .filter((item) => item.creditType === 'Ads')
                    .reduce((accumulator, currentValue) => accumulator + currentValue?.payedCredit, 0);
                const totalPaymentsCreditsCogs = creditData
                    .filter((item) => item.creditType === 'Cogs')
                    .reduce((accumulator, currentValue) => accumulator + currentValue?.payedCredit, 0);
                setCreditsOut({
                    totalCredit: totalCredits,
                    creditAds: totalCreditsAds,
                    creditCogs: totalCreditsCogs,
                    totalCreditsNumber,
                    cogsCreditsNumber,
                    adsCreditsNumber,
                });
                setCreditPayments({
                    totalCredit: totalPaymentsCredit,
                    creditAds: totalPaymentsCreditsAds,
                    creditCogs: totalPaymentsCreditsCogs,
                    totalCreditsNumber: payedCreditsNumber,
                    cogsCreditsNumber: cogsPayedCreditsNumber,
                    adsCreditsNumber: adsPayedCreditsNumber,
                });
            })
            .catch(() => {
                // console.log('error here %o', err);
            });
    }, [filter]);
    return (
        <div>
            <section className="dark:bg-gray-900 flex items-center my-2">
                <div className=" relative w-full">
                    <div className="relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
                        <div className="w-60 p-2">
                            <div className="block">
                                <Datepicker
                                    inputId="deliveryDate"
                                    value={dateRange}
                                    containerClassName="relative z-100"
                                    // inputClassName="absolute"
                                    // toggleClassName="absolute
                                    showShortcuts
                                    showFooter
                                    useRange
                                    onChange={handleRangeFilterChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="grid gap-6 mb-3 md:grid-cols-2 xl:grid-cols-3">
                <div className="min-w-0 bg-white shadow-md sm:rounded-lg overflow-hidden  dark:bg-gray-800">
                    <div className="p-4 flex place-items-center">
                        <div>
                            <div className="flex items-center gap-2">
                                <div className="mb-2 p-2 rounded-full shadow-md text-blue-500 dark:text-blue-100 bg-blue-100 dark:bg-blue-500 mr-2">
                                    <MdQueryStats className="w-4 h-4" />
                                </div>
                                <p className="mb-2 text-sm font-semibold text-gray-700 dark:text-gray-200">
                                    Number Of Credits
                                </p>
                                <p className="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-200">
                                    {creditsOut.totalCreditsNumber}
                                </p>
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="mb-2 p-2 rounded-full shadow-md text-blue-500 dark:text-blue-100 bg-blue-100 dark:bg-blue-500 mr-2">
                                    <MdQueryStats className="w-4 h-4" />
                                </div>
                                <p className="mb-2 text-sm font-semibold text-gray-700 dark:text-gray-200">
                                    Number of paid Credits
                                </p>
                                <p className="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-200">
                                    {creditPayments.totalCreditsNumber}
                                </p>
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="mb-2 p-2 rounded-full shadow-md text-red-500 dark:text-red-100 bg-red-100 dark:bg-blue-500 mr-2">
                                    <FaArrowUp className="w-4 h-4" />
                                </div>
                                <p className="mb-2 text-sm font-semibold text-gray-700 dark:text-gray-200">
                                    Total Credits
                                </p>
                                <p className="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-200">
                                    {currencyConverter(creditsOut.totalCredit, Currency)}
                                </p>
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="mb-2 p-2 rounded-full shadow-md text-green-500 dark:text-red-100 bg-green-100 dark:bg-green-500 mr-2">
                                    <FaArrowDown className="w-4 h-4" />
                                </div>
                                <p className="mb-2 text-sm font-semibold text-gray-700 dark:text-gray-200">
                                    Total Credits payments
                                </p>
                                <p className="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-200">
                                    {currencyConverter(creditPayments?.totalCredit, Currency)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="min-w-0 bg-white shadow-md sm:rounded-lg overflow-hidden justify-items--center dark:bg-gray-800">
                    <div className="p-4 flex justify-items-center">
                        <div className="justify-items-center">
                            <div className="flex items-center gap-2">
                                <div className="mb-2 p-2 rounded-full shadow-md text-blue-500 dark:text-blue-100 bg-blue-100 dark:bg-blue-500 mr-2">
                                    <MdQueryStats className="w-4 h-4" />
                                </div>
                                <p className="mb-2 text-sm font-semibold text-gray-700 dark:text-gray-200">
                                    Number Of Credits
                                </p>
                                <p className="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-200">
                                    {creditsOut.cogsCreditsNumber}
                                </p>
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="mb-2 p-2 rounded-full shadow-md text-blue-500 dark:text-blue-100 bg-blue-100 dark:bg-blue-500 mr-2">
                                    <MdQueryStats className="w-4 h-4" />
                                </div>
                                <p className="mb-2 text-sm font-semibold text-gray-700 dark:text-gray-200">
                                    Number Of paid Credits
                                </p>
                                <p className="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-200">
                                    {creditPayments.cogsCreditsNumber}
                                </p>
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="mb-2 p-2 rounded-full shadow-md text-red-500 dark:text-red-100 bg-red-100 dark:bg-red-500 mr-2">
                                    <MdAdsClick className="w-4 h-4" />
                                </div>
                                <p className="mb-2 text-sm font-semibold text-gray-700 dark:text-gray-200">
                                    Credit for Ads
                                </p>
                                <p className="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-200">
                                    {currencyConverter(creditsOut.creditAds, Currency)}
                                </p>
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="mb-2 p-2 rounded-full shadow-md text-green-500 dark:text-green-100 bg-green-100 dark:bg-green-500 mr-2">
                                    <MdAdsClick className="w-4 h-4" />
                                </div>
                                <p className="mb-2 text-sm font-semibold text-gray-700 dark:text-gray-200">
                                    Credit for Ads payments
                                </p>
                                <p className="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-200">
                                    {currencyConverter(creditPayments?.creditAds, Currency)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="min-w-0 bg-white shadow-md sm:rounded-lg overflow-hidden justify-items--center dark:bg-gray-800">
                    <div className="p-4 flex justify-items-center">
                        <div className="justify-items-center">
                            <div className="flex items-center gap-2">
                                <div className="mb-2 p-2 rounded-full shadow-md text-blue-500 dark:text-blue-100 bg-blue-100 dark:bg-blue-500 mr-2">
                                    <MdQueryStats className="w-4 h-4" />
                                </div>
                                <p className="mb-2 text-sm font-semibold text-gray-700 dark:text-gray-200">
                                    Number Of Credits
                                </p>
                                <p className="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-200">
                                    {creditsOut.adsCreditsNumber}
                                </p>
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="mb-2 p-2 rounded-full shadow-md text-blue-500 dark:text-blue-100 bg-blue-100 dark:bg-blue-500 mr-2">
                                    <MdQueryStats className="w-4 h-4" />
                                </div>
                                <p className="mb-2 text-sm font-semibold text-gray-700 dark:text-gray-200">
                                    Number Of paid Credits
                                </p>
                                <p className="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-200">
                                    {creditPayments.adsCreditsNumber}
                                </p>
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="mb-2 p-2 rounded-full shadow-md text-red-500 dark:text-red-100 bg-red-100 dark:bg-red-500 mr-2">
                                    <AiTwotoneThunderbolt className="w-4 h-4" />
                                </div>
                                <p className="mb-2 text-sm font-semibold text-gray-700 dark:text-gray-200">
                                    credit for cogs
                                </p>
                                <p className="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-200">
                                    {currencyConverter(creditsOut.creditCogs, Currency)}
                                </p>
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="mb-2 p-2 rounded-full shadow-md text-green-500 dark:text-green-100 bg-green-100 dark:bg-green-500 mr-2">
                                    <AiTwotoneThunderbolt className="w-4 h-4" />
                                </div>
                                <p className="mb-2 text-sm font-semibold text-gray-700 dark:text-gray-200">
                                    credit for cogs payments
                                </p>
                                <p className="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-200">
                                    {currencyConverter(creditPayments?.creditCogs, Currency)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CreditStatistic;
