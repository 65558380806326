import { Modal, Table } from 'flowbite-react';
import moment from 'moment';
import { IInvoiceActions } from 'pages/invoices/invoice.types';
import React, { useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { currencyConverter } from 'utils/currencyConverter';
import { statusDisplay } from './invoiceListElements';

// TODO: Implement the appropriat actions for the invoice

// eslint-disable-next-line no-unused-vars
const VisaulisePrepaidInvoice = (props: IInvoiceActions) => {
    const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
    const { invoice } = props;
    const [Currency] = useState('USD');
    const totalFees = Number(invoice?.shippedLeads) + Number(invoice?.handleFees) + Number(invoice?.resellerFees);
    return (
        <>
            <Modal
                dismissible
                show={openInvoiceModal}
                popup
                onClose={() => setOpenInvoiceModal(false)}
                theme={{
                    root: {
                        show: {
                            on: 'flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 z-[9999] h-screen',
                        },
                    },
                }}
            >
                <Modal.Header>
                    <div>Prepaid Invoice</div>
                    <div> {statusDisplay[invoice.status]?.statusIcon}</div>
                </Modal.Header>
                <Modal.Body className="!p-0 overflow-y-auto h-[34rem]">
                    <div className="flex w-full justify-between pb-2 px-2">
                        <div>
                            <span className="font-medium text-gray-900 dark:text-white">Date</span>{' '}
                            {moment(
                                new Date(invoice?.createdAt!).toLocaleString('en-US', {
                                    timeZone: 'Asia/Shanghai',
                                }),
                            ).format('DD/MM/YYYY HH:mm')}
                        </div>
                    </div>
                    <div className="pb-2 px-2">
                        <span className="font-medium text-gray-900 dark:text-white">Reference</span> {invoice.reference}
                    </div>
                    <div className="px-2">
                        <span className="font-medium text-gray-900 dark:text-white">From </span>{' '}
                        {moment(
                            new Date(invoice?.from!).toLocaleString('en-US', {
                                timeZone: 'Asia/Shanghai',
                            }),
                        ).format('DD/MM/YYYY HH:mm')}
                        <span className="font-medium text-gray-900 dark:text-white"> to </span>
                        {moment(
                            new Date(invoice?.to!).toLocaleString('en-US', {
                                timeZone: 'Asia/Shanghai',
                            }),
                        ).format('DD/MM/YYYY HH:mm')}
                    </div>
                    <div className="px-2 pb-2">
                        <Table>
                            <Table.Head>
                                <Table.HeadCell>ITEM</Table.HeadCell>
                                <Table.HeadCell>NUMBER</Table.HeadCell>
                                <Table.HeadCell>VALUE</Table.HeadCell>
                            </Table.Head>
                            <Table.Body className="divide-y">
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        Total handle fees
                                    </Table.Cell>
                                    <Table.Cell> {invoice?.ordersNumber ?? 0}</Table.Cell>
                                    <Table.Cell>{currencyConverter(invoice?.handleFees || 0, Currency)}</Table.Cell>
                                </Table.Row>
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        Total shipping fees
                                    </Table.Cell>
                                    <Table.Cell> {invoice?.ordersNumber ?? 0}</Table.Cell>
                                    <Table.Cell>{currencyConverter(invoice?.shippedLeads || 0, Currency)}</Table.Cell>
                                </Table.Row>
                                {Object.values(invoice.resellerFeesDetails || {})?.map((reseller) => {
                                    return (
                                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 ">
                                            {' '}
                                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                                {` Reseller product(${reseller?.productName})`}
                                            </Table.Cell>
                                            <Table.Cell>{reseller?.totalItems ?? '--'}</Table.Cell>
                                            <Table.Cell>
                                                {currencyConverter(reseller?.totalFees || 0, Currency)}
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        Total Fees
                                    </Table.Cell>
                                    <Table.Cell />
                                    <Table.Cell>{currencyConverter(totalFees || 0, Currency)}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </div>
                </Modal.Body>
            </Modal>

            <BiSearch
                className="w-5 h-5 hover:cursor-pointer dark:text-gray-400  "
                onClick={() => {
                    setOpenInvoiceModal(true);
                }}
            />
        </>
    );
};
export default VisaulisePrepaidInvoice;
