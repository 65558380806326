export const getStatus = (status: number, type: number) => {
    switch (status) {
        case 1:
            return 'pending';
        case 2:
            return 'reviewing';
        case 3:
            return 'quoted';

        case 41:
            return 'Customer Treating quotations';
        case 42:
            return 'quotation refused';
        case 5:
            return 'waiting for payment';
        case 98:
            return 'the user rejected the shipping offer';
        case 6:
            return 'Paid';
        case 7:
            return 'preparing';
        case 8:
            if (type === 2) {
                return 'ready';
            }
            return 'ready, matching required';
        case 9:
            return 'waiting for pickup';
        case 10:
            return 'picked up';
        case 11:
            return 'pending delivery';
        case 12:
            return 'out for delivery ';
        case 13:
            return 'delivered';
        case 14:
            return 'delivery failed';
        case 20:
            return 'partially matched';
        case 21:
            return 'Being shipped to warehouse';
        case 99:
            return 'the offer rejected by the user';
        default:
            return 'pending';
    }
};

export const getDeliveryStatus = (status: number) => {
    switch (status) {
        case 1:
            return 'pending';
        case 2:
            return 'confirmed';
        case 3:
            return 'picked up';
        case 4:
            return 'pending delivery';
        case 5:
            return 'out of delivery';
        case 6:
            return 'delivered, ready';

        default:
            return 'pending';
    }
};

export const getStockStatus = (status: number) => {
    switch (status) {
        case 1:
            return 'Preparing stock';
        case 2:
            return 'Ready for pickup';
        case 30:
            return 'Stock received by carrier';
        case 31:
            return 'Sent to GuangZhou Airport';
        case 32:
            return 'Instation Scan GZ';
        case 33:
            return 'Arrived in loading warehouse';
        case 34:
            return 'Sent To HongKong';
        case 35:
            return 'Instation Scan HK';
        case 36:
            return 'Departed on Flight';
        case 37:
            return 'Arrived destination Airport';
        case 38:
            return 'Customs clearance start';
        case 39:
            return 'Customs Released_Import';
        case 40:
            return 'Arrive to headquarters warehouse';
        case 3:
            return 'Shipping';
        case 4:
            return 'Delivered';
        default:
            return '--';
    }
};

export const ordersStatus = (status: number) => {
    switch (status) {
        case 1:
            return 'New';
        case 2:
            return 'Canceled';
        case 3:
            return 'Unreachable';
        case 4:
            return 'Confirmed';
        case 5:
            return 'Waiting for shipment';
        case 6:
            return 'Out for shipment';
        case 7:
            return 'Shipped';
        case 8:
            return 'Picked up';
        case 9:
            return 'Out for delivery';
        case 10:
            return 'Delivery attempted';
        case 11:
            return 'Delivery failed';
        case 12:
            return 'Delivered';
        default:
            return 'New';
    }
};

export const PRODUCT_PHASES = ['Processing', 'Paid', 'Rejected'];
