/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box, Divider } from '@mui/material';
import MessageFile from 'components/messageFile/MessageFile';
import MessageInput from 'components/messageInput/MessageInput';
import MessageText from 'components/messageText/MessageText';
import { Badge, Modal } from 'flowbite-react';
import StatusTimeline from 'pages/orders/orderStatusTimeline';
import { orderStatus } from 'pages/orders/orders.styles';
import { IStatusTimeline } from 'pages/orders/orders.types';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FaHistory } from 'react-icons/fa';
import { IOrder, getHistory } from 'services/admin/order';
import { getOrderTraces } from 'services/admin/orderTrace';
import { getCurrentUser } from 'utils/helpers/auth';
import { socket } from 'utils/socketIO';
import { LoadPreviousMessages, LoadPreviousMessagesWrapper, MessageTexts } from './chat.styles';

const Chat = ({
    orderId,
    customerId,
    chatId,
    activeOrder,
}: {
    orderId: string;
    customerId: string;
    chatId: string;
    activeOrder: any;
}) => {
    const [currentRef, setCurrentRef] = useState('last');
    const [convMessages, setConvMessages] = useState<any>([]);
    const myRef = React.useRef<any>();
    const [limit, setLimit] = useState(0);
    const [totalMessages, setTotalMessages] = useState(0);

    useEffect(() => {
        myRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [convMessages]);

    useEffect(() => {
        socket.emit(
            'joinOrderChat',
            JSON.stringify({
                order: orderId,
                customerId,
                user: getCurrentUser()._id,
            }),
        );

        socket.on('paginate-order-messages', (data: any) => {
            if (data.totalDocs) setTotalMessages(data.totalDocs);
            if (data.limit) setLimit(data.limit);
            if (data.docs) {
                setConvMessages(data.docs);
            }
            setCurrentRef('last');
        });

        socket.on('new-order-message', (data: any) => {
            if (data.docs && (data.docs as any[]).every((doc) => doc.chat === chatId)) {
                setConvMessages((conv: any) => [...data.docs, ...conv]);
                if (getCurrentUser()._id !== data.docs[0].to) {
                    socket.emit('newChatMessage', {
                        user: getCurrentUser()._id,
                        about: 22, // new order message
                        customerId,
                        messageId: data.docs[0]._id,
                    });
                }
            }

            setCurrentRef('last');
        });

        return () => {
            socket.off('paginate-order-messages');
            socket.off('new-order-message');
        };
    }, [customerId, orderId]);

    const [openhistory, setOpenHistory] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<IOrder>();
    const [history, setHistory] = useState<IStatusTimeline>({ history: [], deliveryAttempts: [] }); // TODO: Replace `any` with its appropriate type
    const handleOrderHistory = () => {
        getHistory(orderId)
            .then((orderData) => {
                getOrderTraces({ order: orderId, logistioStatus: 10 })
                    .then((dataTraces) => {
                        setHistory({ history: orderData?.data, deliveryAttempts: dataTraces?.data.docs });

                        setSelectedOrder(activeOrder);
                        setOpenHistory(true);
                    })
                    .catch(() => {});
            })
            .catch(() => {
                setOpenHistory(false);
                toast.error('Something went wrong when fetching order history');
            });
    };

    const [fileName, setFileName] = useState('');
    const [fileSize, setFileSize] = useState(0);

    const [progressCount, setProgressCount] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [displayuploadingFile, setDisplayUploadingFile] = useState(false);

    useEffect(() => {
        setUploading(progressCount < 100 && progressCount > 0);
    }, [progressCount, displayuploadingFile]);

    return (
        <>
            <Modal
                dismissible
                show={openhistory}
                popup
                onClose={() => setOpenHistory(false)}
                theme={{
                    root: {
                        show: {
                            on: 'flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 z-[9999]',
                        },
                    },
                }}
            >
                <Modal.Header>Order Status History</Modal.Header>
                <Modal.Body className="!p-0 overflow-y-auto h-[34rem]">
                    {selectedOrder && (
                        <StatusTimeline setOpen={setOpenHistory} history={history} order={selectedOrder} />
                    )}
                </Modal.Body>
            </Modal>
            <div className="h-[90vh] bg-background-secondary relative  ">
                <div className="flex justify-center gap-3 w-full p-4 h-[50px]">
                    <div className="w-full">
                        <div className="flex justify-between w-full  mb-1">
                            <div className="flex gap-3">
                                <p className="truncate max-w-96 font-normal text-lg font-popin">
                                    Order ref: {activeOrder.orderRef ? activeOrder.orderRef : '-'}
                                </p>
                                <button type="button" onClick={handleOrderHistory}>
                                    <FaHistory />
                                </button>
                            </div>
                            <div style={{ width: 'fit-content' }}>
                                <Badge color={orderStatus(activeOrder.status).color}>
                                    {orderStatus(activeOrder.status).title}
                                </Badge>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider />
                <div className="h-[calc(90vh-(154px+0px-50px))] bg-background-secondary  overflow-hidden hover:overflow-y-scroll">
                    {limit < totalMessages && (
                        <Box sx={LoadPreviousMessagesWrapper}>
                            <Box
                                onClick={() => {
                                    socket.emit(
                                        'paginateOrderMessages',
                                        JSON.stringify({
                                            limit: limit + 20,
                                            user: getCurrentUser()._id,
                                            order: orderId,
                                        }),
                                    );
                                    setLimit((lmt) => lmt + 20);
                                    setCurrentRef('first');
                                }}
                                sx={LoadPreviousMessages}
                            >
                                <ExpandLessIcon /> Click to load previous messages
                            </Box>
                        </Box>
                    )}
                    {currentRef === 'first' && <Box ref={myRef} />}
                    {convMessages &&
                        convMessages
                            .slice(0)
                            .reverse()
                            .map((message: any) => (
                                <Box sx={MessageTexts} key={message._id}>
                                    {message.message ? (
                                        <MessageText
                                            entity={message.from}
                                            message={message.message}
                                            received={message.onModelFrom === 'Customer'}
                                            timestamp={message.createdAt}
                                            key={message.id}
                                        />
                                    ) : message.file ? (
                                        <MessageFile
                                            entity={message.from}
                                            file={message.file}
                                            received={message.onModelFrom === 'Customer'}
                                            timestamp={message.createdAt}
                                            fileName={message.fileName}
                                            fileSize={message.fileSize}
                                        />
                                    ) : null}
                                </Box>
                            ))}
                    {currentRef === 'last' && <Box ref={myRef} />}
                    {uploading && displayuploadingFile && (
                        <Box sx={MessageTexts}>
                            <MessageFile
                                file="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7wNPpW45HWrITlj89fzAHTMQvxzolD3mx_-Swrw2tvQ&s"
                                received={false}
                                fileName={fileName}
                                fileSize={fileSize}
                                uploading={uploading}
                                progressCount={progressCount}
                            />
                        </Box>
                    )}
                </div>

                <Divider />
                <MessageInput
                    socketRef={socket}
                    action="newOrderMessage"
                    orderId={orderId}
                    setProgressCount={setProgressCount}
                    setFileName={setFileName}
                    setFileSize={setFileSize}
                    setDisplayUploadingFile={setDisplayUploadingFile}
                />
            </div>
        </>
    );
};

export default Chat;
