/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-return-assign */
import { IProduct, IQuotation } from 'utils/Interfaces/products.interface';

export const getVariantQuantity = (product: IProduct) => {
    let quantity = 0;
    const quotation = product?.quotation;
    if (!quotation?.length) return product.quantity;

    (quotation[0]?.options || []).forEach((el) => {
        if (el.accepted || el.accepted === undefined) {
            quantity += el.quantity ? Number(el.quantity) : 0;
        }
    });

    return quantity;
};

export const getTotalQuantity = (product: IProduct) => {
    let quantity = 0;
    const quotation = product?.quotation;
    if (quotation?.length && ![42, 99].includes(product.status))
        quotation.forEach(async (quote) => {
            (quote.options || []).forEach((el) => {
                if (el.accepted || el.accepted === undefined) {
                    quantity += el.quantity ? Number(el.quantity) : 0;
                }
            });
        });
    else if (product.variants.length)
        ((product.variants as IProduct[]) || []).forEach((el) => {
            quantity += el?.quantity ? Number(el.quantity) : 0;
        });
    else return product.quantity;
    return quantity;
};
export const getCounteries = (product: IProduct) => {
    const Countries: string[] = [];
    const quotation = product?.parentProduct?.quotation;
    const shipment =
        // eslint-disable-next-line no-nested-ternary
        product.shippingTo?.length > 1
            ? product.shippingTo
            : product.shippingTo?.length === 1
              ? product.shippingTo[0]?.split(',')
              : [];
    if (!quotation) return shipment;
    quotation.forEach(async (quote) => {
        (quote.options || []).forEach((el) => {
            if (el.accepted || el.accepted === undefined) {
                Countries.push(el.country);
            }
        });
    });
    return Countries;
};

export const getTotalPrice = (quotations: IQuotation[]) => {
    let totalItemsPrice = 0;
    const countryList: {
        [key: string]: number;
    } = {};

    quotations?.length &&
        (quotations || []).forEach(({ options }: any) => {
            options.forEach(
                ({ unitPrice, quantity, totalShippingLineFee, shippingLineFee, country, accepted }: any) => {
                    if (accepted) totalItemsPrice += Number(unitPrice) * Number(quantity);
                    if (accepted && !(country in countryList))
                        countryList[country] = totalShippingLineFee
                            ? Number(totalShippingLineFee)
                            : Number(shippingLineFee);
                },
            );
        });
    Object.values(countryList).forEach((el) => (totalItemsPrice += el));
    return Number(totalItemsPrice.toFixed(2));
};
