import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { getCurrentUser } from 'utils/helpers/auth';
import SourcingAgentSideBar from 'components/sideBar/sourcingAgentSideBar';
import CustomNavbar from 'components/navbar/customNavbar';

const ProductProviderLayout: React.FC = () => {
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (getCurrentUser().role !== 'productProvider') {
            navigate(`/${getCurrentUser().role}`);
        }
    }, []);

    return (
        <>
            <div className=" ">
                <div className="absolute top-0 bottom-0 left-0 w-56 ">
                    <div className="h-screen fixed left-0 top-0 dark:bg-[#1f2937] ">
                        <SourcingAgentSideBar collapsed={collapsed} />
                    </div>
                </div>

                <div
                    className={`absolute top-0 bottom-0 right-0 ${
                        collapsed ? 'left-16' : 'left-56'
                    } bg-background-secondary`}
                >
                    <div>
                        <CustomNavbar collapsed={collapsed} setCollapsed={setCollapsed} />
                    </div>

                    <div className=" bg-background-secondary  px-2.5 pt-2.5">
                        <Outlet context={collapsed} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductProviderLayout;
