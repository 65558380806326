/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-fragments */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
import { Badge, Button, Card } from 'flowbite-react';
import React, { useState } from 'react';
import Zoom from 'react-medium-image-zoom';
import { Grid, Typography } from '@mui/material';
import { BsPencilSquare } from 'react-icons/bs';
import { FcCancel } from 'react-icons/fc';
import { IItems, IOrder } from 'services/admin/order';
import { IProduct } from 'utils/Interfaces/products.interface';
import Package from '../../../assets/package.png';
import UpdateItem from './updateItem';

const AvailableProducts: React.FC<{
    similarProducts: IProduct[];
    data: IOrder;
    setRows: React.Dispatch<React.SetStateAction<IOrder[]>>;
    currentPage: number;
    currentFilter?: Record<string, any>;
}> = ({ similarProducts, data, setRows, currentPage, currentFilter }) => {
    const [updateList, setUpdateList] = useState(false);
    // const filterImages = (design: string[]): string | undefined => {
    //     const isThere = design.filter(
    //         (prodImg: string) =>
    //             prodImg.endsWith('.jpg') ||
    //             prodImg.endsWith('.png') ||
    //             prodImg.endsWith('.jpeg') ||
    //             prodImg.endsWith('.webp') ||
    //             prodImg.endsWith('.svg%2Bxml'),
    //     );
    //     if (isThere && isThere.length > 0) {
    //         return isThere[0];
    //     }
    //     return undefined;
    // };
    const isThereAnImage = (imageArr: string[]) => {
        const isThere = imageArr?.filter(
            (prodImg) =>
                prodImg.endsWith('.jpg') ||
                prodImg.endsWith('.png') ||
                prodImg.endsWith('.jpeg') ||
                prodImg.endsWith('.webp') ||
                prodImg.endsWith('.svg%2Bxml'),
        );
        if (isThere && isThere.length > 0) {
            return (
                <Zoom>
                    <img
                        src={isThere[0]}
                        alt="quotation"
                        style={{
                            objectFit: 'contain',
                            borderRadius: 8,
                            overflow: 'hidden',
                            width: 100,
                            height: 70,
                        }}
                    />
                </Zoom>
            );
        }
        return (
            <Zoom>
                <img
                    src={Package}
                    alt="quotation"
                    style={{
                        objectFit: 'contain',
                        borderRadius: 8,
                        overflow: 'hidden',
                        width: 100,
                        height: 70,
                    }}
                />
            </Zoom>
        );
    };
    return (
        <>
            <div className="  p-2 " key="productDetailsHolder">
                {!data.prePaid && data.cod && (
                    <div className="flex justify-end mb-2">
                        <Button
                            onClick={() => {
                                setUpdateList(!updateList);
                            }}
                        >
                            <div className="flex justify-center items-center gap-2">
                                {updateList ? (
                                    <FcCancel className="h-6 w-6" />
                                ) : (
                                    <BsPencilSquare className="h-6 w-6 text-green-700" />
                                )}
                                {updateList ? (
                                    <p className="text-base">Cancel</p>
                                ) : (
                                    <p className="text-base"> Update list</p>
                                )}
                            </div>
                        </Button>
                    </div>
                )}
                <Card
                    theme={{
                        root: {
                            children: 'p-2',
                        },
                    }}
                    className="mb-1"
                >
                    <Grid container spacing={0}>
                        <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography />
                        </Grid>
                        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography>Product Name</Typography>
                        </Grid>
                        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography>Product Spec</Typography>
                        </Grid>
                        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography>Sku</Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography>Quantity</Typography>
                        </Grid>
                    </Grid>
                </Card>
                <Card
                    theme={{
                        root: {
                            children: 'p-2',
                        },
                    }}
                    className="mb-1"
                >
                    {!updateList ? (
                        <div>
                            {data?.items?.length > 0 &&
                                data.items.map((item: IItems) => {
                                    const itemProduct = item?.product as IProduct;
                                    let productName = '';
                                    let variant = '';
                                    let productDesign;
                                    if (itemProduct.itemKind === 'variant') {
                                        productName = itemProduct?.parentProduct?.name;
                                        variant = itemProduct?.name;
                                        productDesign = itemProduct?.parentProduct?.design;
                                    } else {
                                        productName = itemProduct?.name;
                                        variant = '-';
                                        productDesign = itemProduct.design;
                                    }
                                    return (
                                        <>
                                            {(item?.quantity || 0) > 0 && (
                                                <Card
                                                    key={itemProduct?.globalSKU}
                                                    theme={{
                                                        root: {
                                                            children: 'p-2',
                                                        },
                                                    }}
                                                    className="mb-1 relative"
                                                >
                                                    {itemProduct?.isReseller && (
                                                        <Badge
                                                            color="secondary"
                                                            className="inline-block absolute top-1 left-1 transform -rotate-12 z-10"
                                                            style={{ backgroundColor: '#F2EAFA', color: '#7828C8' }}
                                                        >
                                                            RESELLER
                                                        </Badge>
                                                    )}
                                                    <Grid container spacing={0}>
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            {isThereAnImage(productDesign)}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <Typography>{productName}</Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={3}
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <Typography>{variant}</Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={3}
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <Typography>{itemProduct?.globalSKU}</Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <Typography>{item?.quantity}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            )}
                                            {(item.upsellQuantity || 0) > 0 && (
                                                <Card
                                                    key={itemProduct?.globalSKU}
                                                    theme={{
                                                        root: {
                                                            children: 'p-2',
                                                        },
                                                    }}
                                                    className="mb-1 bg-gray-100 relative"
                                                >
                                                    {itemProduct?.isReseller && (
                                                        <Badge
                                                            color="secondary"
                                                            className="inline-block absolute top-1 left-1 transform -rotate-12 z-10"
                                                            style={{ backgroundColor: '#F2EAFA', color: '#7828C8' }}
                                                        >
                                                            RESELLER
                                                        </Badge>
                                                    )}
                                                    <Badge
                                                        color="success"
                                                        className="inline-block absolute top-3 right-1 transform rotate-12"
                                                    >
                                                        UPSELL
                                                    </Badge>

                                                    <Grid container spacing={0}>
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            {isThereAnImage(productDesign)}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <Typography>{productName}</Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={3}
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <Typography>{variant}</Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={3}
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <Typography>{itemProduct?.globalSKU}</Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <Typography>{item.upsellQuantity}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            )}
                                        </>
                                    );
                                })}
                        </div>
                    ) : (
                        <>
                            <UpdateItem
                                similarProducts={similarProducts as IProduct[]}
                                data={data}
                                setRows={setRows}
                                currentPage={currentPage}
                                currentFilter={currentFilter}
                                setUpdateList={setUpdateList}
                            />
                        </>
                    )}
                </Card>
            </div>
        </>
    );
};

export default AvailableProducts;
