import ResetPassword from 'pages/resetPassword/ResetPassword';
// import RecoverPassword from 'pages/recoverPassword/RecoverPassword';
import CallAgentProfile from 'pages/CallAgentsPages/ProfileSettings/callAgentProfile';
import DashboardIcon from '@mui/icons-material/Dashboard';
import productProviderDashboard from 'pages/dashboard/productProviderDashboard';
import accountManagerList from 'pages/accountManagerList/accountManagerList';
import adminsList from 'pages/adminsList/adminsList';
import productProviderList from 'pages/productProviderList/productProviderList';
// import SettingsIcon from '@mui/icons-material/Settings';
// import Settings from 'pages/settings/settings';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import ProductList from 'pages/productList/ProductList';
import Configuration from 'pages/configurationAdmin/configuration';
import productSettings from 'pages/productSettings/productSettings';
import CallAgentDashboard from 'pages/dashboard/callAgentDashboard';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import StoresListing from 'pages/storesList/StoresList';
import ShoppingCartSharpIcon from '@mui/icons-material/ShoppingCartSharp';
import OrdersListing from 'pages/orders/OrdersList';
import OrderDetails from 'pages/orders/OrderDetails';
import ChatIcon from '@mui/icons-material/Chat';
import Customers from 'pages/customers/customers';
import StockList from 'pages/stock/StockList';
import OrderListCA from 'pages/CallAgentsPages/orders/orderListCA';
import OrderListCCA from 'pages/CallCenterAdmin/orders/orderListCCA';
import Wallet from 'pages/walletOperation/wallet';
import Payments from 'pages/payments/payments';
import TuneIcon from '@mui/icons-material/Tune';
import { FaShoppingBag, FaFileInvoiceDollar } from 'react-icons/fa';
import ProfileSettings from 'pages/profileSetting/profileSettings';
import CallCenterAdminDashboard from 'pages/dashboard/CCADashboard/dashboard';
import SourcingAgentProfile from 'pages/sourcingAgentPages/Profile/sourcingAgentPofile';
import AcProfile from 'pages/accountManagerPagers/Profile/acProfile';
import Credits from 'pages/CreditsManagement/credits';
import CallAgentsList from 'pages/CallCenterAdmin/callAgentsList/callAgentsList';
import CreditsAccountManager from 'pages/accountManagerPagers/CreditsManagement/credits';
import CallAgentAdminProfile from 'pages/CallCenterAdmin/ProfileSettings/callAgentAdminProfile';
import Conversations from 'pages/conversations/Conversations';
import prepaidInvoiceList from 'pages/invoices/prepaidInvoice/invoiceList';
import ConfigurationCallCenterAdmin from 'pages/callCenterAdminList/configurationCCAdmin/ConfigurationCallCenterAdmin';
import ResellerProducts from 'pages/reseller/products/resellerProductsList';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import AddResellerProduct from 'pages/reseller/products/addResellerProduct';
import ResellerStocks from 'pages/reseller/stocks/resellerStocks';
import ResellerProductDetails from 'pages/reseller/products/resellerProductDetails';
import accountManagerDashboard from 'pages/dashboard/accountManagerDashboard';
import { FaBox } from 'react-icons/fa6';
import HomeIcon from '@mui/icons-material/Home';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import { CiWallet } from 'react-icons/ci';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import InventoryIcon from '@mui/icons-material/Inventory';
import StockShipments from 'pages/stockShipments/stockShipments';
import adminDashboard from './pages/dashboard/adminDashboard';
import Login from './pages/login/Login';
import callAgentList from './pages/callAgentList/callAgentList';
import callCenterAdminList from './pages/callCenterAdminList/callCenterAdminList';
import callCenterList from './pages/callCenterList/callCenterList';
import invoiceList from './pages/invoices/invoice/invoiceList';
import WalletOperationsList from './pages/walletOperationsList/walletOperations';
import purchasesList from './pages/purchases/purchasesList';

const routes = [
    {
        path: '/reset-password',
        name: 'ResetPassword',
        element: ResetPassword,
        layout: '/auth',
    },
    {
        path: '/login',
        name: 'Login',
        element: Login,
        layout: '/auth',
    },

    /* admin Area * */

    {
        path: '',
        name: 'Dashboard',
        icon: HomeIcon,
        element: adminDashboard,
        layout: '/admin',
        type: 'adminFeatures',
    },

    {
        name: 'Logistio Bulk',
        icon: FaBox,
        layout: '/admin',
        type: 'adminFeatures',
        children: [
            {
                path: 'products',
                name: 'Products',
                element: ProductList,
                layout: '/admin',
                type: 'adminFeatures',
            },

            {
                path: 'restock',
                name: 'Restock',
                element: ProductList,
                layout: '/admin',
                type: 'adminFeatures',
            },
        ],
    },

    {
        path: 'purchases',
        name: 'Purchases',
        icon: FaShoppingBag,
        element: purchasesList,
        layout: '/admin',
        type: 'adminFeatures',
    },

    {
        path: 'orders',
        name: 'Orders',
        icon: ShoppingCartSharpIcon,
        element: OrdersListing,
        layout: '/admin',
        type: 'adminFeatures',
    },
    {
        path: 'chat',
        name: 'Chat',
        icon: Inventory2Icon,
        element: OrderDetails,
        layout: '/admin',
        type: 'subpage',
    },

    {
        path: 'conversations',
        name: 'Chat',
        icon: ChatIcon,
        element: Conversations,
        layout: '/admin',
        type: 'adminFeatures',
    },

    {
        name: 'Stocks',
        icon: InventoryIcon,
        layout: '/admin',
        type: 'adminFeatures',
        children: [
            {
                path: 'stock',
                name: 'Stocks List',
                element: StockList,
                layout: '/admin',
                type: 'adminFeatures',
            },

            {
                path: 'stock-shipments',
                name: 'Stock Shipments',
                element: StockShipments,
                layout: '/admin',
                type: 'adminFeatures',
            },
        ],
    },

    {
        name: 'Reseller',
        icon: CardGiftcardIcon,
        layout: '/admin',
        type: 'adminFeatures',
        children: [
            {
                path: 'resellerProducts',
                name: 'Products',
                element: ResellerProducts,
                layout: '/admin',
                type: 'adminFeatures',
            },

            {
                path: 'resellerStocks',
                name: 'Stocks',
                element: ResellerStocks,
                layout: '/admin',
                type: 'adminFeatures',
            },
        ],
    },

    {
        path: 'resellerProducts-details',
        name: 'Reseller Product Details',
        icon: CardGiftcardIcon,
        element: ResellerProductDetails,
        layout: '/admin',
        type: 'subpage',
    },

    {
        path: 'new-reseller-Product',
        name: 'New Reseller Product',
        element: AddResellerProduct,
        layout: '/admin',
        type: 'subpage',
    },

    {
        path: 'invoices',
        name: 'COD Invoices',
        icon: FaFileInvoiceDollar,
        element: invoiceList,
        layout: '/admin',
        type: 'adminFeatures',
    },

    {
        name: 'Finance',
        icon: CiWallet,
        layout: '/admin',
        type: 'adminFeatures',
        children: [
            {
                path: 'payments',
                name: 'Payments',
                element: Payments,
                layout: '/admin',
                type: 'adminFeatures',
            },
            {
                path: 'walletOperations',
                name: 'Wallet Operations',
                element: WalletOperationsList,
                layout: '/admin',
                type: 'adminFeatures',
            },
            {
                path: 'prepaid-invoices',
                name: 'Prepaid invoices',
                element: prepaidInvoiceList,
                layout: '/admin',
                type: 'adminFeatures',
            },
            {
                path: 'credits',
                name: 'Credits',
                element: Credits,
                layout: '/admin',
                type: 'adminFeatures',
            },
        ],
    },

    {
        name: 'Sellers',
        icon: StoreMallDirectoryIcon,
        layout: '/admin',
        type: 'adminFeatures',
        children: [
            {
                path: 'sellers',
                name: 'List of sellers',
                element: Customers,
                layout: '/admin',
                type: 'adminFeatures',
            },

            {
                path: 'stores',
                name: 'Stores',
                element: StoresListing,
                layout: '/admin',
                type: 'adminFeatures',
            },
        ],
    },

    {
        name: 'Users',
        icon: PersonIcon,
        layout: '/admin',
        type: 'adminFeatures',
        children: [
            {
                path: 'adminList',
                name: 'Admins',
                element: adminsList,
                layout: '/admin',
                type: 'adminFeatures',
            },

            {
                path: 'accountManagerList',
                name: 'Account Managers',
                element: accountManagerList,
                layout: '/admin',
                type: 'adminFeatures',
            },

            {
                path: 'productProviderList',
                name: 'Sourcing Agents',
                element: productProviderList,
                layout: '/admin',
                type: 'adminFeatures',
            },
        ],
    },

    {
        name: 'Call Center',
        icon: HeadsetMicIcon,
        element: callCenterList,
        layout: '/admin',
        type: 'adminFeatures',
        children: [
            {
                path: 'callCenterList',
                name: 'List of Call Centers',
                element: callCenterList,
                layout: '/admin',
                type: 'adminFeatures',
            },

            {
                path: 'callCenterAdminList',
                name: 'Call Center Admins',
                element: callCenterAdminList,
                layout: '/admin',
                type: 'adminFeatures',
            },

            {
                path: 'callAgentList',
                name: 'Call Agents',
                element: callAgentList,
                layout: '/admin',
                type: 'adminFeatures',
            },
        ],
    },

    // {
    //     path: 'orders/details',
    //     name: 'subject',
    //     icon: ShoppingCartSharpIcon,
    //     element: OrderDetails,
    //     layout: '/admin',
    //     type: 'subpage',
    // },

    // {
    //     path: 'test',
    //     name: 'testadmin',
    //     icon: BugReportIcon,
    //     element: testtttt,
    //     layout: '/admin',
    //     type: 'adminParameters',
    // },

    {
        path: 'product-settings',
        name: 'Product Settings',
        icon: Inventory2Icon,
        element: productSettings,
        layout: '/admin',
        type: 'subpage',
    },
    {
        path: 'configuration',
        name: 'Settings',
        icon: SettingsIcon,
        element: Configuration,
        layout: '/admin',
        type: 'adminParameters',
    },

    /**
     * @alert do not Delete: used for further references
     */

    // {
    //     path: 'settings',
    //     name: 'Settings',
    //     icon: SettingsIcon,
    //     element: Settings,
    //     layout: '/admin',
    //     routes: [
    //         // {
    //         //     path: '/profile-settings',
    //         //     name: 'profile settings',
    //         //     element: ProfileSettings,
    //         //     layout: '/settings',
    //         // },
    //     ],
    //     type: 'adminParameters',
    // },
    {
        path: 'customer/:id/:wallet',
        name: '',
        element: Wallet,
        layout: '/admin',
    },

    {
        path: '/profile',
        name: 'profile settings',
        element: ProfileSettings,
        layout: '/admin',
    },

    /* Account Manager Area * */
    {
        path: '',
        name: 'Dashboard',
        icon: DashboardIcon,
        element: accountManagerDashboard,
        layout: '/accountManager',
        type: 'accountManagerFeatures',
    },
    {
        path: 'profile',
        name: 'Profile',
        // icon: DashboardIcon,
        element: AcProfile,
        layout: '/accountManager',
        // type: 'accountManagerFeatures',
    },

    {
        name: 'Logistio Bulk',
        icon: FaBox,
        layout: '/accountManager',
        type: 'accountManagerFeatures',
        children: [
            {
                path: 'products',
                name: 'Products',
                element: ProductList,
                layout: '/accountManager',
                type: 'accountManagerFeatures',
            },

            {
                path: 'restock',
                name: 'Restocks',
                element: ProductList,
                layout: '/accountManager',
                type: 'accountManagerFeatures',
            },
        ],
    },

    {
        path: 'purchases',
        name: 'Purchases',
        icon: FaShoppingBag,
        element: purchasesList,
        layout: '/accountManager',
        type: 'accountManagerFeatures',
    },

    {
        path: 'orders',
        name: 'Orders',
        icon: ShoppingCartSharpIcon,
        element: OrdersListing,
        layout: '/accountManager',
        type: 'accountManagerFeatures',
    },

    {
        path: 'chat',
        name: 'Chat',
        icon: Inventory2Icon,
        element: OrderDetails,
        layout: '/accountManager',
        type: 'subpage',
    },

    {
        path: 'conversations',
        name: 'Chat',
        icon: ChatIcon,
        element: Conversations,
        layout: '/accountManager',
        type: 'accountManagerParameters',
    },

    {
        name: 'Stocks',
        icon: InventoryIcon,
        layout: '/accountManager',
        type: 'accountManagerFeatures',
        children: [
            {
                path: 'stock',
                name: 'Stock List',
                element: StockList,
                layout: '/accountManager',
                type: 'accountManagerFeatures',
            },

            {
                path: 'stock-shipments',
                name: 'Stock Shipments',
                element: StockShipments,
                layout: '/accountManager',
                type: 'accountManagerFeatures',
            },
        ],
    },

    {
        name: 'Reseller',
        icon: CardGiftcardIcon,
        layout: '/accountManager',
        type: 'accountManagerFeatures',
        children: [
            {
                path: 'resellerProducts',
                name: 'Products',
                element: ResellerProducts,
                layout: '/accountManager',
                type: 'accountManagerFeatures',
            },

            {
                path: 'resellerStocks',
                name: 'Stocks',
                element: ResellerStocks,
                layout: '/accountManager',
                type: 'accountManagerFeatures',
            },
        ],
    },

    {
        path: 'invoices',
        name: 'COD invoices',
        icon: FaFileInvoiceDollar,
        element: invoiceList,
        layout: '/accountManager',
        type: 'accountManagerFeatures',
    },

    {
        name: 'Finance',
        icon: CiWallet,
        layout: '/accountManager',
        type: 'accountManagerFeatures',
        children: [
            {
                path: 'payments',
                name: 'Payments',
                element: Payments,
                layout: '/accountManager',
                type: 'accountManagerFeatures',
            },
            {
                path: 'walletOperations',
                name: 'Wallet Operations',
                element: WalletOperationsList,
                layout: '/accountManager',
                type: 'accountManagerFeatures',
            },

            {
                path: 'prepaid-invoices',
                name: 'Prepaid invoices',
                element: prepaidInvoiceList,
                layout: '/accountManager',
                type: 'accountManagerFeatures',
            },

            {
                path: 'credits',
                name: 'Credits',
                element: CreditsAccountManager,
                layout: '/accountManager',
                type: 'accountManagerFeatures',
            },
        ],
    },
    {
        path: 'resellerProducts-details',
        name: 'Reseller Product Details',
        icon: CardGiftcardIcon,
        element: ResellerProductDetails,
        layout: '/accountManager',
        type: 'subpage',
    },

    {
        path: 'product-settings',
        name: 'Product Settings',
        icon: Inventory2Icon,
        element: productSettings,
        layout: '/accountManager',
        type: 'subpage',
    },

    {
        path: 'new-reseller-Product',
        name: 'New Reseller Product',
        element: AddResellerProduct,
        layout: '/accountManager',
        type: 'subpage',
    },

    {
        name: 'Sellers',
        icon: StoreMallDirectoryIcon,
        layout: '/accountManager',
        type: 'accountManagerFeatures',
        children: [
            {
                path: 'sellers',
                name: 'List of sellers',
                element: Customers,
                layout: '/accountManager',
                type: 'accountManagerFeatures',
            },

            {
                path: 'stores',
                name: 'Stores',
                element: StoresListing,
                layout: '/accountManager',
                type: 'accountManagerFeatures',
            },
        ],
    },

    {
        path: 'order-details',
        name: 'Order Details',
        icon: Inventory2Icon,
        element: OrderDetails,
        layout: '/accountManager',
        type: 'subpage',
    },

    {
        path: 'customer/:id/:wallet',
        name: '',
        element: Wallet,
        layout: '/accountManager',
    },

    // {
    //     path: 'test',
    //     name: 'testAM',
    //     icon: DashboardIcon,
    //     element: testtttt,
    //     layout: '/accountManager',
    //     type: 'accountManagerFeatures',
    // },

    /* Product Provider Area * */

    {
        path: '',
        name: 'Dashboard',
        icon: DashboardIcon,
        element: productProviderDashboard,
        layout: '/productProvider',
        type: 'ProductProviderFeatures',
    },
    {
        path: 'products',
        name: 'Products',
        icon: Inventory2Icon,
        element: ProductList,
        layout: '/productProvider',
        type: 'ProductProviderFeatures',
    },
    {
        path: 'product-settings',
        name: 'Product Settings',
        icon: Inventory2Icon,
        element: productSettings,
        layout: '/productProvider',
        type: 'subpage',
    },

    {
        path: 'profile',
        // name: 'Settings',
        // icon: SettingsIcon,
        element: SourcingAgentProfile,
        layout: '/productProvider',
        // type: 'CallAgentParameters',
    },

    /* Call center Agent Area * */

    {
        path: '',
        name: 'Dashboard',
        icon: DashboardIcon,
        element: CallAgentDashboard,
        layout: '/callAgent',
        type: 'CallAgentFeatures',
    },
    // {
    //     path: 'test',
    //     name: 'testCA',
    //     icon: DashboardIcon,
    //     element: testtttt,
    //     layout: '/callAgent',
    //     type: 'CallAgentParameters',
    // },
    {
        path: 'orders',
        name: 'Orders',
        icon: ShoppingCartSharpIcon,
        element: OrderListCA,
        layout: '/callAgent',
        type: 'CallAgentFeatures',
    },
    {
        path: 'profile',
        // name: 'Settings',
        // icon: SettingsIcon,
        element: CallAgentProfile,
        layout: '/callAgent',
        // type: 'CallAgentParameters',
    },

    /* Call center admin Area * */

    {
        path: 'dashboard',
        name: 'Dashboard',
        icon: DashboardIcon,
        element: CallCenterAdminDashboard,
        layout: '/callCenterAdmin',
        type: 'CallCenterAdminFeatures',
    },
    {
        path: 'callAgents',
        name: 'Call Agents',
        icon: SupportAgentIcon,
        element: CallAgentsList,
        layout: '/callCenterAdmin',
        type: 'CallCenterAdminFeatures',
    },
    {
        path: 'orders',
        name: 'Orders',
        icon: ShoppingCartSharpIcon,
        element: OrderListCCA,
        layout: '/callCenterAdmin',
        type: 'CallCenterAdminFeatures',
    },
    {
        path: 'profile',
        name: 'Profile',
        // icon: ShoppingCartSharpIcon,
        element: CallAgentAdminProfile,
        layout: '/callCenterAdmin',
        // type: 'CallCenterAdminFeatures',
    },
    {
        path: 'configuration',
        name: 'Settings',
        icon: TuneIcon,
        element: ConfigurationCallCenterAdmin,
        layout: '/callCenterAdmin',
        type: 'CallCenterAdminParameters',
    },
];

export default routes;
