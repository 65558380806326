/* eslint-disable react/jsx-fragments */
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { addSchema } from 'utils/validations/addMemberValidation';
import CustomError from 'components/customError/CustomError';
import { addAccountManager, getAllAccountManagers } from 'services/admin/accountManager';
import toast from 'react-hot-toast';
import CustomBtn from 'components/customButton/customBtn';
import PhoneInput from 'react-phone-number-input';
import { Label, TextInput } from 'flowbite-react';
import { HiMail } from 'react-icons/hi';
import { NotifProps } from '../accountManager.types';
import '../../../utils/helpers/phoneInput.css';

const AddAccountManager: React.FC<NotifProps> = ({ setOpen, setAccountManagers }) => {
    const [isLoading, setIsLoading] = useState(false);

    const close = () => {
        setOpen(false);
    };

    const newAccountManager = (values: any) => {
        setIsLoading(true);
        addAccountManager(values)
            .then(() => {
                toast.success('Account Manager Added successfully');
                close();
                setIsLoading(false);
            })
            .then(() => getAllAccountManagers({}).then((res) => setAccountManagers(res.data.docs)))
            .catch((err) => {
                setIsLoading(false);
                toast.error(err?.response?.data?.errors?.message);
            });
    };

    return (
        <>
            <Formik
                initialValues={{
                    email: '',
                    firstName: '',
                }}
                onSubmit={(values, onSubmitProps) => {
                    newAccountManager(values);
                    onSubmitProps.setSubmitting(false);
                    onSubmitProps.resetForm();
                }}
                validationSchema={addSchema}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit} placeholder="">
                        <div className="p-6 flex flex-col gap-2 ">
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="username4"
                                        className="dark:text-white"
                                        color={formik.errors.email ? 'failure' : ''}
                                        value="Email"
                                    />
                                </div>
                                <TextInput
                                    {...formik.getFieldProps('email')}
                                    name="email"
                                    placeholder="jhondoe@email.com"
                                    color={formik.errors.email ? 'failure' : 'gray'}
                                    icon={HiMail}
                                    type="email"
                                    helperText={
                                        <React.Fragment>
                                            <CustomError name="email" component="div" />
                                        </React.Fragment>
                                    }
                                />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="username4"
                                        className="dark:text-white"
                                        color={formik.errors.firstName ? 'failure' : ''}
                                        value="First name"
                                    />
                                </div>
                                <TextInput
                                    {...formik.getFieldProps('firstName')}
                                    name="firstName"
                                    color={formik.errors.firstName ? 'failure' : 'gray'}
                                    type="text"
                                    helperText={
                                        <React.Fragment>
                                            <CustomError name="firstName" component="div" />
                                        </React.Fragment>
                                    }
                                />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="username4"
                                        className="dark:text-white"
                                        // color={formik.errors.lastName ? 'failure' : ''}
                                        value="Last Name"
                                    />
                                </div>
                                <TextInput
                                    {...formik.getFieldProps('lastName')}
                                    name="lastName"
                                    // color={formik.errors.lastName ? 'failure' : 'gray'}
                                    type="text"
                                    helperText={
                                        <React.Fragment>
                                            <CustomError name="lastName" component="div" />
                                        </React.Fragment>
                                    }
                                />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="username4"
                                        className="dark:text-white"
                                        // color={formik.errors.phone ? 'failure' : ''}
                                        value="Phone Number"
                                    />
                                </div>

                                <PhoneInput
                                    id="phoneNumber"
                                    name="phone"
                                    // value={formik.values.phone}
                                    onChange={(value) => formik.setFieldValue('phone', value)}
                                    onBlur={formik.handleBlur('phone')}
                                    placeholder="Enter phone number"
                                    className="mb-1"
                                />
                                <CustomError name="phone" component="div" />
                            </div>
                        </div>
                        <div className="flex gap-2 justify-between py-2 px-2 items-center rounded-b-lg  bg-background-secondary">
                            <CustomBtn
                                variant="failure"
                                outline
                                onClick={() => {
                                    formik.resetForm();
                                    close();
                                }}
                            >
                                Cancel
                            </CustomBtn>
                            <CustomBtn variant="primary" type="submit" isProcessing={isLoading} disabled={isLoading}>
                                Add account Manager
                            </CustomBtn>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddAccountManager;
