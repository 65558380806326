/* eslint-disable no-underscore-dangle */

import React, { useEffect, useState } from 'react';
import AccountRoleBadge from 'components/status/accountRoleBadge';
import { getCurrentUser } from 'utils/helpers/auth';
import { getAdmin } from 'services/admin/admin';
import { Accordion } from 'flowbite-react';
import { AllUserProps } from './profileSettings.types';
import PersonalInformation from './userUpdatePages/PersonalInformation';
import AccountInformation from './userUpdatePages/AccountInformation';
import PasswordInfo from './userUpdatePages/passwordInfo';
import ProfilePicture from './ProfilePicture';

const ProfileSettings = () => {
    const [admin, setAdmin] = useState<AllUserProps>();

    const currentId = getCurrentUser()._id;

    useEffect(() => {
        getAdmin(currentId).then((res) => {
            setAdmin(res.data);
        });
    }, []);
    return (
        <>
            <div className="flex justify-end items-center mb-3">
                <p className="mr-2">Current Role:</p>
                <AccountRoleBadge role={getCurrentUser().role} />
            </div>
            <div className="grid grid-flow-col">
                <div className="flex justify-center items-center ">
                    <ProfilePicture
                        id={currentId}
                        profImg={
                            admin?.profileImage ??
                            'https://image.similarpng.com/very-thumbnail/2021/08/Instagram-photo-frame-template-and-social-media-notification-icon-design-template-on-transparent-background-PNG.png'
                        }
                        setAdmin={setAdmin}
                    />
                </div>
                <div className="col-span-5">
                    <Accordion collapseAll alwaysOpen>
                        <Accordion.Panel>
                            <Accordion.Title>Personal Info</Accordion.Title>
                            <Accordion.Content>
                                {admin && (
                                    <PersonalInformation
                                        firstName={admin.firstName}
                                        lastName={admin.lastName}
                                        phone={admin.phone}
                                        id={currentId}
                                    />
                                )}
                            </Accordion.Content>
                        </Accordion.Panel>
                        <Accordion.Panel>
                            <Accordion.Title>Email</Accordion.Title>
                            <Accordion.Content>
                                {admin && <AccountInformation id={currentId} email={admin.email} />}
                            </Accordion.Content>
                        </Accordion.Panel>
                        <Accordion.Panel>
                            <Accordion.Title>Password</Accordion.Title>
                            <Accordion.Content>
                                <PasswordInfo id={currentId} />
                            </Accordion.Content>
                        </Accordion.Panel>
                    </Accordion>
                </div>
            </div>
        </>
    );
};

export default ProfileSettings;
