/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { FileInput, Label } from 'flowbite-react';

interface VideoUploadProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const VideoUpload: React.FC<VideoUploadProps> = ({ name, onChange, title }) => {
    return (
        <div className="flex  items-center justify-center">
            <Label
                htmlFor="dropzone-file-video"
                className="dark:hover:bg-bray-800 flex  cursor-pointer flex-col items-center justify-center rounded-lg dark:border-gray-600"
            >
                {/* <div className="flex flex-col items-center justify-center pb-6 pt-5">
                    <MdOutlineOndemandVideo className="h-8 w-8 text-gray-500" />
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">Click to upload</span>
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">Supports: mp4, ogg</p>
                </div> */}
                <div className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    {title}
                </div>
                <FileInput
                    id="dropzone-file-video"
                    className="hidden"
                    accept=".mp4, .ogg"
                    multiple
                    onChange={onChange}
                    name={name}
                />
            </Label>
        </div>
    );
};

export default VideoUpload;
