/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { FileInput, Label } from 'flowbite-react';

export interface ProductImageUploadProps extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;

    title: string;
}
const Uploader: React.FC<ProductImageUploadProps> = ({ name, accept, onChange, multiple, title }) => {
    return (
        <>
            <div className="flex items-center justify-center">
                <Label
                    htmlFor={`dropzone-file-${name}`}
                    className="dark:hover:bg-bray-800  cursor-pointer flex-col items-center justify-center rounded-lg "
                >
                    <div className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        {title}
                    </div>
                    <FileInput
                        id={`dropzone-file-${name}`}
                        className="hidden"
                        onChange={onChange}
                        name={name}
                        accept={accept}
                        multiple={multiple}
                    />
                </Label>
            </div>
        </>
    );
};

export default Uploader;
