/* eslint-disable react/jsx-fragments */
import React from 'react';
import { Formik, Form } from 'formik';
import { loginSchema } from 'utils/validations/loginValidation';
import CustomError from 'components/customError/CustomError';
import { Link, useNavigate } from 'react-router-dom';
import { login } from 'services/auth';
import { getCurrentUser } from 'utils/helpers/auth';
import { Label, TextInput } from 'flowbite-react';
import { HiMail } from 'react-icons/hi';
import CustomBtn from 'components/customButton/customBtn';
import toast from 'react-hot-toast';
import PasswordInput from 'components/inputField/passwordInput';

const Login: React.FC = () => {
    const navigate = useNavigate();

    const userLogin = (values: any) => {
        login(values)
            .then((res) => {
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('user', JSON.stringify(getCurrentUser()));
                if (getCurrentUser().role === 'admin') {
                    navigate('/admin');
                } else if (getCurrentUser().role === 'accountManager') {
                    navigate('/accountManager');
                } else if (getCurrentUser().role === 'productProvider') {
                    navigate('/productProvider');
                } else if (getCurrentUser().role === 'callAgent') {
                    navigate('/callAgent');
                } else if (getCurrentUser().role === 'callCenterAdmin') {
                    navigate('/callCenterAdmin');
                } else {
                    toast.error('Something went wrong !');
                }
            })
            .catch((err) => {
                toast.error(err.response.data.errors.message);
            });
    };

    return (
        <div className="container mx-auto w-80 flex flex-col justify-center h-screen">
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                }}
                onSubmit={(values) => userLogin(values)}
                validationSchema={loginSchema}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit} placeholder="">
                        <div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="username4"
                                        className="dark:text-white"
                                        color={formik.errors.email ? 'failure' : ''}
                                        value="Email"
                                    />
                                </div>
                                <TextInput
                                    {...formik.getFieldProps('email')}
                                    name="email"
                                    className="w-full"
                                    placeholder="jhondoe@email.com"
                                    color={formik.errors.email ? 'failure' : 'gray'}
                                    icon={HiMail}
                                    type="email"
                                    helperText={
                                        <React.Fragment>
                                            <CustomError name="email" component="div" />
                                        </React.Fragment>
                                    }
                                />
                            </div>
                            <div>
                                <PasswordInput
                                    {...formik.getFieldProps('password')}
                                    name="password"
                                    colorLabel={formik.errors.password ? 'failure' : ''}
                                    helperText={<CustomError name="password" component="div" />}
                                    label="Password"
                                />
                            </div>
                            <div>
                                <Link to="/auth/reset-password" className="flex justify-end hover:underline mb-1">
                                    <p>Forgot Password?</p>
                                </Link>
                            </div>
                            <CustomBtn variant="primary" type="submit" className="w-full">
                                Login
                            </CustomBtn>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default Login;
