/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box, Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import MessageFile from 'components/messageFile/MessageFile';
import MessageInput from 'components/messageInput/MessageInput';
import MessageText from 'components/messageText/MessageText';
import React, { forwardRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getCurrentUser } from 'utils/helpers/auth';
import { socket } from 'utils/socketIO';
import ModalLoader from 'utils/helpers/themeStyle/modalLoader';
import {
    LoadPreviousMessages,
    LoadPreviousMessagesWrapper,
    MessageTexts,
    MessageTextsContainer,
    messagesPageStyle,
} from './chat.styles';
import OrderHeader from './ChatHeader';

const Chat = forwardRef(() => {
    const location: any = useLocation();
    const [currentRef, setCurrentRef] = useState('last');
    const [convMessages, setConvMessages] = useState<any>([]);
    const myRef = React.useRef<any>();
    const [limit, setLimit] = useState(0);
    const [orderInfo, setOrderInfo] = useState<any>({});
    const [totalMessages, setTotalMessages] = useState(0);

    useEffect(() => {
        myRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [convMessages]);

    useEffect(() => {
        // Creates a WebSocket connection

        socket.emit(
            'joinOrderChat',
            JSON.stringify({
                order: location.state.data._id,
                customerId: location.state.data.customer,
                user: getCurrentUser()._id,
            }),
        );

        socket.on('paginate-order-messages', (data: any) => {
            if (data.totalDocs) setTotalMessages(data.totalDocs);
            if (data.docs) {
                if (data.docs.length === 1) {
                    setConvMessages((conv: any) => [...data.docs, ...conv]);
                } else setConvMessages(data.docs);
            }

            setCurrentRef('last');
        });

        socket.on('new-order-message', (data: any) => {
            if (data.docs) {
                if (data.docs.length === 1) {
                    setConvMessages((conv: any) => [...data.docs, ...conv]);
                } else setConvMessages(data.docs);
                if (getCurrentUser()._id !== data.docs[0].to) {
                    socket.emit('newChatMessage', {
                        user: getCurrentUser()._id,
                        about: 22, // new order message
                        customerId: location.state.customerId,
                        messageId: data.docs[0]._id,
                    });
                }
            }

            setCurrentRef('last');
        });

        return () => {
            socket.off('paginate-order-messages');
            socket.off('new-order-message');
        };
    }, []);

    useEffect(() => {
        if (location.state?.data) setOrderInfo(location.state.data);
    }, [location.state?.data]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_progressCount, setProgressCount] = useState(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_fileName, setFileName] = useState('');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_fileSize, setFileSize] = useState(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_displayuploadingFile, setDisplayUploadingFile] = useState(false);

    if (!orderInfo) {
        return <ModalLoader />;
    }

    return (
        <Grid container sx={messagesPageStyle}>
            <Grid item xs={12} md={12}>
                {orderInfo && <OrderHeader order={orderInfo} />}

                <Divider />
                {limit < totalMessages && (
                    <Box sx={LoadPreviousMessagesWrapper}>
                        <Box
                            onClick={() => {
                                socket.emit(
                                    'paginateOrderMessages',
                                    JSON.stringify({
                                        limit: limit + 20,
                                        user: getCurrentUser()._id,
                                        order: location.state.data._id,
                                    }),
                                );
                                setLimit((lmt) => lmt + 20);
                                setCurrentRef('first');
                            }}
                            sx={LoadPreviousMessages}
                        >
                            <ExpandLessIcon /> Click to load previous messages
                        </Box>
                    </Box>
                )}

                <Box sx={MessageTextsContainer}>
                    {currentRef === 'first' && <Box ref={myRef} />}
                    {convMessages &&
                        convMessages
                            .slice(0)
                            .reverse()
                            .map((message: any) => (
                                <Box sx={MessageTexts}>
                                    {message.message ? (
                                        <MessageText
                                            entity={message.from}
                                            message={message.message}
                                            received={message.onModelFrom === 'Customer'}
                                            timestamp={message.createdAt}
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={message.id}
                                        />
                                    ) : message.file ? (
                                        <MessageFile
                                            file={message.file}
                                            received={message.onModelFrom === 'Customer'}
                                            timestamp={message.createdAt}
                                            fileName={message.fileName}
                                            fileSize={message.fileSize}
                                        />
                                    ) : null}
                                </Box>
                            ))}
                    {currentRef === 'last' && <Box ref={myRef} />}
                </Box>
                <Divider />
                <MessageInput
                    socketRef={socket}
                    action="newOrderMessage"
                    setProgressCount={setProgressCount}
                    setFileName={setFileName}
                    setFileSize={setFileSize}
                    setDisplayUploadingFile={setDisplayUploadingFile}
                />
            </Grid>
        </Grid>
    );
});

export default Chat;
