import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Outlet, useLocation } from 'react-router-dom';

import Messages from 'pages/messages/Messages';
import EditProduct from 'pages/editProduct/EditProduct';
import Quotation from 'pages/quotation/Quotation';
import { IProduct } from 'utils/Interfaces/products.interface';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div className="p-2">{children}</div>}
        </div>
    );
}

const productSettings: React.FC = () => {
    const location: any = useLocation();
    const [value, setValue] = useState(0);
    // TODO: Update the type declarations
    const [cache, setCache] = useState<{ editProduct?: IProduct; quotation?: any }>({});
    useEffect(() => {
        setValue(location.state.tabId);
        // if (location.state && location.state.tabId) {
        //     setValue(location.state.tabId);
        // } else navigate('/admin/products');
    }, []);

    const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
        setValue(Number(newValue));
    };
    return (
        <div>
            <div>
                <Tabs
                    value={value}
                    onChange={(e, newValue) => handleChange(e, newValue)}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="Edit Product" />
                    <Tab label="Quotation" />
                    <Tab label="Chat" />
                </Tabs>

                <TabPanel value={value} index={0}>
                    <EditProduct navCache={cache} setNavCache={setCache} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Quotation navCache={cache} setNavCache={setCache} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Messages />
                </TabPanel>
            </div>
            <Outlet />
        </div>
    );
};

export default productSettings;
