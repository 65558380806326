/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-lone-blocks */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-fragments */
import CustomError from 'components/customError/CustomError';
import { Badge, Label, Select, TextInput } from 'flowbite-react';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { UpdateCreditsInformations } from 'utils/validations/CreditsInformationsValidation';
import CustomBtn from 'components/customButton/customBtn';
import { FiPercent } from 'react-icons/fi';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { getProducts } from 'services/admin/product';
import { IProduct } from 'utils/Interfaces/products.interface';
import AutoComplete from 'components/autocomplete/autoComplete';
import { addCredit, getCredits } from 'services/accountManager/credits';
import { ICreditInfos } from './credits.types';
import AddCreditCog from './addCreditCogsForm';

const AddCredit: React.FC<ICreditInfos> = ({ customers, setRows, setModalAddCredit }) => {
    const [applyForCreditLoader, setApplyForCreditLoader] = useState(false);
    const [productList, setProductList] = useState<IProduct[]>([]);
    const [customer, setCustomer] = useState<string>('');
    const [creditType, setCreditType] = useState<string>('');
    const setProductsList = (customerId: string) => {
        getProducts({ customer: customerId }).then((res) => {
            setProductList(res.data.docs);
        });
    };

    const handleCustomerChange = (customerId: string) => {
        setCustomer(customerId);
        setProductsList(customerId);
    };
    const newCreditAds = (values: any) => {
        setApplyForCreditLoader(true);
        addCredit({ ...values, status: 1, customer, creditType })
            .then(() => {
                toast.success('Credit application added successfully');
                setApplyForCreditLoader(false);
                setModalAddCredit(false);
            })
            .then(() => {
                getCredits({}).then((res) => setRows(res.data.docs));
            })
            .catch((err) => {
                toast.error(err.response.data.errors.message);
                setApplyForCreditLoader(false);
            });
    };

    return (
        <>
            <div id="selectCreditType" className="p-4 flex flex-col gap-2 ">
                <div>
                    <Label htmlFor="creditType" className="dark:text-white" value="Credit Type" />
                    <Select
                        id="creditType"
                        required
                        onChange={(e) => {
                            setCreditType(e.target.value);
                        }}
                    >
                        <option value="" selected>
                            Choose credit Type
                        </option>
                        <option value="Ads">Credit for ADS</option>
                        <option value="Cogs">Credit for Cogs</option>
                    </Select>
                    <div className="py-2">
                        <div className="mb-2 block">
                            <Label htmlFor="customerField" className="dark:text-white" value="Customer" />
                        </div>
                        <AutoComplete
                            key="id"
                            placeholder="Seller"
                            options={customers as []}
                            getOptionLabel={(option: any) => `${option.firstName} ${option.lastName}`}
                            // eslint-disable-next-line no-underscore-dangle
                            onChange={(option) => handleCustomerChange(option?._id as string)}
                        />
                    </div>
                </div>
                {creditType === 'Cogs' ? (
                    <AddCreditCog
                        productList={productList}
                        customer={customer}
                        setRows={setRows}
                        setModalAddCredit={setModalAddCredit}
                    />
                ) : creditType === 'Ads' ? (
                    <Formik
                        initialValues={{
                            creditAmount: '',
                            adsPercent: '',
                            customer: '',
                        }}
                        onSubmit={(values, onSubmitProps) => {
                            newCreditAds(values);
                            onSubmitProps.setSubmitting(false);
                            onSubmitProps.resetForm();
                        }}
                        validationSchema={UpdateCreditsInformations}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit} placeholder="">
                                <div className=" flex flex-col gap-2 overflow-auto max-h-[37rem]">
                                    <div>
                                        <div className="w-1/4">
                                            <Label
                                                htmlFor="creditAmount"
                                                className="dark:text-white"
                                                color={
                                                    formik.touched.creditAmount && formik.errors.creditAmount
                                                        ? 'failure'
                                                        : ''
                                                }
                                                value="Credit for ADS"
                                            />
                                        </div>
                                        <TextInput
                                            {...formik.getFieldProps('creditAmount')}
                                            name="creditAmount"
                                            color={
                                                formik.touched.creditAmount && formik.errors.creditAmount
                                                    ? 'failure'
                                                    : 'gray'
                                            }
                                            type="text"
                                            helperText={
                                                <React.Fragment>
                                                    <CustomError name="creditAmount" component="div" />
                                                </React.Fragment>
                                            }
                                        />
                                    </div>
                                    <div>
                                        <div>
                                            <Label
                                                htmlFor="adsPercent"
                                                className="dark:text-white"
                                                color={
                                                    formik.touched.adsPercent && formik.errors.adsPercent
                                                        ? 'failure'
                                                        : ''
                                                }
                                                value="ADS percentage"
                                            />
                                        </div>
                                        <TextInput
                                            {...formik.getFieldProps('adsPercent')}
                                            name="adsPercent"
                                            color={
                                                formik.touched.adsPercent && formik.errors.adsPercent
                                                    ? 'failure'
                                                    : 'gray'
                                            }
                                            type="text"
                                            rightIcon={FiPercent}
                                            helperText={
                                                <React.Fragment>
                                                    <CustomError name="adsPercent" component="div" />
                                                </React.Fragment>
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="flex justify-end items-center rounded-b-lg mt-3">
                                    <CustomBtn
                                        pill
                                        variant="primary"
                                        type="submit"
                                        disabled={
                                            !formik.isValid ||
                                            !formik.dirty ||
                                            formik.isSubmitting ||
                                            applyForCreditLoader
                                        }
                                        isProcessing={applyForCreditLoader}
                                    >
                                        Save
                                    </CustomBtn>
                                </div>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <Badge color="warning" size="sm" icon={AiOutlineInfoCircle}>
                        Choose credit type first
                    </Badge>
                )}
            </div>
        </>
    );
};

export default AddCredit;
