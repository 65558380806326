/* eslint-disable no-underscore-dangle */
import PaymentsStatus from 'components/status/paymentsStatus';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from 'react-icons/fa';
import { RiRefund2Fill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { IWalletCurrencyConfig, getConfiguration } from 'services/admin/configuration';
import { getCustomerWallet, updateCustomerWalletAmount } from 'services/admin/wallet';
import { currencyConverter } from 'utils/currencyConverter';
import { getCurrentUser } from 'utils/helpers/auth';
import { primaryButton, primaryOutlinedBtn } from 'utils/helpers/themeStyle/buttons';
import ModalLoader from 'utils/helpers/themeStyle/modalLoader';
import { socket } from 'utils/socketIO';
import ImageProofVerification from '../../components/ImagePoofVerification/ImageproodVerification';
import { IOperationProps } from './walletOperartions.types';

const WalletOperation: React.FC<IOperationProps> = ({ operationData, setRows, currentPage, currentFilter }) => {
    const navigate = useNavigate();
    const [walletOpLoader, setWalletOpLoader] = useState(false);
    const [loader, setLoader] = useState(true);
    const [currencyRateMissed, setCurrencyRateMissed] = useState(false);
    const getOperationsAfterUpdate = () => {
        getCustomerWallet({ page: currentPage, limit: 20, ...currentFilter })
            .then((res) => {
                const data = res.data.response;
                setRows(data.docs);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message);
                // toast.error('error fel customerwallet get');
            });
    };
    const updateWalletOp = (status: number, operation: any) => {
        const toastId = toast.loading('Treating Wallet Operation...');

        setWalletOpLoader(true);
        updateCustomerWalletAmount(operation?._id, {
            status,
        })
            .then(() => {
                socket.emit('newWalletOperation', {
                    user: getCurrentUser()._id,
                    about: 41,
                    walletOperation: operation?._id,
                    customer: operation?.wallet?.customer?._id,
                });
                if (status === 2) {
                    toast.success(`Amount of  ${operation?.amount} ACCEPTED `, {
                        id: toastId,
                    });
                } else {
                    toast.error(`Amount of  ${operation?.amount} REJECTED `, {
                        id: toastId,
                    });
                }
                setWalletOpLoader(false);

                getOperationsAfterUpdate();
            })
            .catch((err: any) => {
                toast.error(err?.response?.data?.errors?.message, {
                    id: toastId,
                });

                setWalletOpLoader(false);
            });
    };
    const checkCurrencyConfig = (currency: string, currencies: IWalletCurrencyConfig[]) => {
        const currencyRate = currencies.find((currConfig) => currConfig.currency === currency);
        if (currencyRate && currencyRate.currencyValue) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        getConfiguration()
            .then((res) => {
                setCurrencyRateMissed(() => {
                    return checkCurrencyConfig(operationData.operationCurrency, res?.data?.walletCurrency || []);
                });
                setLoader(false);
            })
            .catch((err: any) => {
                toast.error(err?.response?.data?.errors?.message || 'Error ,please try again');
            });
    }, []);

    function getIconWalletOpType(): React.ReactNode {
        if (operationData.operationType === 'IN') {
            return <FaArrowAltCircleDown className="w-5 h-5 text-green-600" />;
        }
        if (operationData.operationType === 'OUT') {
            return <FaArrowAltCircleUp className="w-5 h-5 text-red-600" />;
        }
        if (operationData.operationType === 'REFUND') {
            return <RiRefund2Fill className="w-5 h-5 text-blue-600" />;
        }
        return <></>;
    }

    return loader ? (
        <>
            <ModalLoader />
        </>
    ) : (
        <>
            {' '}
            <div className="container mx-auto mb-2">
                <div
                    className={`${
                        operationData.operationType === 'IN' ? 'grid grid-cols-2 gap-4' : ''
                    } justify-items-center`}
                >
                    <div className="w-full  p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                        <div className="flex items-center justify-between mb-4">
                            <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                                Operation Details
                            </h5>
                        </div>
                        <div className="flow-root">
                            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                                {operationData.operationType === 'IN' && (
                                    <li className="py-3 sm:py-4">
                                        <div className="flex items-center">
                                            <div className="flex-shrink-0" />
                                            <div className="flex-1 min-w-0 ms-4">
                                                <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                                    Account Name
                                                </p>
                                                <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                                    {operationData?.accountName}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                )}
                                <li className="py-3 sm:py-4">
                                    <div className="flex items-center ">
                                        <div className="flex-shrink-0" />
                                        <div className="flex-1 min-w-0 ms-4">
                                            <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                                Status
                                            </p>
                                        </div>
                                        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                            <PaymentsStatus status={operationData?.status} />
                                        </div>
                                    </div>
                                </li>
                                <li className="py-3 sm:py-4">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0" />
                                        <div className="flex-1 min-w-0 ms-4">
                                            <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                                Amount
                                            </p>
                                        </div>
                                        <div className="inline-flex gap-1 items-center text-base font-semibold text-gray-900 dark:text-white">
                                            {currencyConverter(
                                                operationData?.amount,
                                                operationData.operationCurrency
                                                    ? operationData.operationCurrency
                                                    : 'USD',
                                            )}
                                            {getIconWalletOpType()}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {operationData.operationType === 'IN' && (
                        <div className="w-full  p-2 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                            <ImageProofVerification verifImage={operationData} />
                        </div>
                    )}
                </div>
            </div>
            <div>
                {operationData.status === 1 && (
                    <div className="container mx-auto mb-1">
                        <div
                            className={`flex gap-3 items-center ${
                                currencyRateMissed ? 'justify-center' : 'justify-between'
                            }`}
                        >
                            {!currencyRateMissed && (
                                <button
                                    type="button"
                                    className={primaryOutlinedBtn}
                                    onClick={() => {
                                        updateWalletOp(3, operationData);
                                    }}
                                    disabled={walletOpLoader || currencyRateMissed}
                                    // isProcessing={walletOpLoader}
                                >
                                    Reject
                                    <AiOutlineClose className="ml-2 w-4 h-4" />
                                </button>
                            )}
                            {currencyRateMissed && (
                                <div className="items-center text-center">
                                    <h5 className="flex items-center text-md justify-center text-red-600 dark:text-red-500 py-2">
                                        {`Missing currency rate conversion for ${operationData.operationCurrency} .`}
                                    </h5>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            navigate('/admin/configuration');
                                        }}
                                        className={primaryButton}
                                        // isProcessing={walletOpLoader}
                                    >
                                        Go to wallet currencies settings
                                    </button>
                                </div>
                            )}

                            {!currencyRateMissed && (
                                <button
                                    type="button"
                                    onClick={() => {
                                        updateWalletOp(2, operationData);
                                    }}
                                    disabled={walletOpLoader || currencyRateMissed}
                                    className={primaryButton}
                                    // isProcessing={walletOpLoader}
                                >
                                    Confirm
                                    <AiOutlineCheck className="ml-2 w-4 h-4" />
                                </button>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default WalletOperation;
