import axios from 'utils/axios';
import { ICustomer } from 'pages/customers/customer.types';
import { IProduct } from 'utils/Interfaces/products.interface';
import { getBearerToken } from 'utils/helpers/auth';

export interface ICredit {
    _id: string;
    customer: ICustomer;
    creditType: string;
    creditAmount: number;
    adsPercent: number;
    product: IProduct;
    cogsUnitPrice: number;
    status: number;
    payedCredit: number;
}
interface IUpdateData {
    status: number;
}
export const addCredit = async (data: any) => {
    const newCustomer = await axios.post(`/credits`, data, getBearerToken());
    return newCustomer;
};
export const getCredits = async (data: any) => {
    const config = getBearerToken();
    const response = await axios.post(`/credits/filter`, data, config);
    return response;
};
export const updatecredit = async (id: string, data: IUpdateData) => {
    const config = getBearerToken();
    const response = await axios.put(`/credits/${id}`, data, config);
    return response;
};
export const getHistory = async (creditId: string) => {
    const config = getBearerToken();
    const creditHistory = await axios.get(`/creditsOperations/${creditId}`, config);
    return creditHistory;
};
export const getAllCredits = async (data: any) => {
    const config = getBearerToken();
    const orderHistory = await axios.post(`/credits/allCredits`, data, config);
    return orderHistory;
};
