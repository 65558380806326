/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Modal } from 'flowbite-react';
import { getAllProductProviders } from 'services/admin/productProviders';
import UseDocumentTitle from 'utils/dynamicTitle/dynamicTitle';
import { HiUserAdd } from 'react-icons/hi';
import CustomBtn from 'components/customButton/customBtn';
import useDebouncedValue from 'utils/hooks/useDebouncedValue';
import toast from 'react-hot-toast';
import ProductProviderModal from './addProductProvider/productProviderModal';
import { columns, statusDisplay } from './sourcingAgentTableActions/productProviderListElements';
import DataTable from '../../components/tables/tables';
import { ISourcingAgent } from './productProvider.types';

interface Imeta {
    totalDocs: number;
    limit: number;
    totalPages: number;
    page: number;
    pagingCounter: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    prevPage: number | null;
    nextPage: number | null;
}
interface Ipagination {
    page: number;
    limit: number;
}

const productProviderList = () => {
    const [open, setOpen] = useState(false);
    const [productProviders, setProductProviders] = useState<Array<ISourcingAgent>>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });
    const [filter] = useState({});
    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 20,
    });

    const handlePaginationChange = (value: { [key: string]: any }) => {
        setPagination((prevPagination) => {
            const data = { ...prevPagination, ...value };
            if (value.limit) {
                data.page = 1;
            }
            return data;
        });
        setMeta((prev) => ({ ...prev, page: -1 }));
    };

    const debouncedPagination = useDebouncedValue(pagination, 500);
    const debouncedFilter = useDebouncedValue(filter, 500);

    useEffect(() => {
        const queryOptions = { ...debouncedPagination, ...debouncedFilter };
        getAllProductProviders(queryOptions)
            .then((result) => {
                setProductProviders(result.data.docs);
                setMeta({
                    hasNextPage: result.data.hasNextPage,
                    hasPrevPage: result.data.hasPrevPage,
                    limit: result.data.limit,
                    nextPage: result.data.nextPage,
                    page: result.data.page,
                    pagingCounter: result.data.pagingCounter,
                    prevPage: result.data.prevPage,
                    totalDocs: result.data.totalDocs,
                    totalPages: result.data.totalPages,
                });
                setIsLoading(false);
            })
            .catch((err) => {
                toast.error((err as any)?.response?.data?.errors?.message || 'Unable to load data.');
            });
    }, [debouncedFilter, debouncedPagination]);

    UseDocumentTitle('Sourcing Agents');

    return (
        <>
            <h3>Sourcing agent List</h3>

            <div className="flex justify-end mb-2">
                <CustomBtn
                    variant="primary"
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    <HiUserAdd className="mr-2 h-5 w-5" />
                    Add Sourcing agent
                </CustomBtn>
                <Modal dismissible show={open} popup onClose={() => setOpen(false)}>
                    <Modal.Header>Add New Sourcing agent</Modal.Header>
                    <Modal.Body className="!p-0">
                        <ProductProviderModal setOpen={setOpen} setProductProviders={setProductProviders} />
                    </Modal.Body>
                </Modal>
            </div>
            <DataTable
                rows={productProviders}
                columns={columns}
                icons={statusDisplay}
                setRows={setProductProviders}
                pageState={{
                    isLoading,
                    total: meta.totalDocs,
                    pageSize: pagination.limit,
                    page: pagination.page,
                    count: meta.totalPages,
                }}
                setPageState={handlePaginationChange}
            />
        </>
    );
};

export default productProviderList;
