/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Button, Select, TextInput } from 'flowbite-react';
import { HiPencilAlt } from 'react-icons/hi';
import { getConfiguration, updateConfigItem } from 'services/admin/configuration';
import toast from 'react-hot-toast';
import { FaSave } from 'react-icons/fa';
import { LogistioBulkProps } from './logistioBulk.types';

interface Setting {
    status: string;
    value?: number;
    type?: string;
    edit: boolean;
    key: string;
    typeKey: string;
}

const LogistioBulkConfiguration: React.FC<LogistioBulkProps> = ({
    logistioBulkConfig,
    configId,
    setLogistioBulkConfig,
}) => {
    const [settings, setSettings] = useState<Setting[]>([
        {
            status: 'Under review',
            value: logistioBulkConfig?.review,
            type: logistioBulkConfig?.reviewType,
            edit: false,
            key: 'review',
            typeKey: 'reviewType',
        },
        {
            status: 'Quoted',
            value: logistioBulkConfig?.quotedExpiration,
            type: logistioBulkConfig?.quotedExpirationType,
            edit: false,
            key: 'quotedExpiration',
            typeKey: 'quotedExpirationType',
        },
        {
            status: 'Payment pending',
            value: logistioBulkConfig?.paymentExpiration,
            type: logistioBulkConfig?.paymentExpirationType,
            edit: false,
            key: 'paymentExpiration',
            typeKey: 'paymentExpirationType',
        },
    ]);

    useEffect(() => {
        if (logistioBulkConfig) {
            setSettings([
                {
                    status: 'Quoted',
                    value: logistioBulkConfig.quotedExpiration,
                    type: logistioBulkConfig.quotedExpirationType,
                    edit: false,
                    key: 'quotedExpiration',
                    typeKey: 'quotedExpirationType',
                },
                {
                    status: 'Payment pending',
                    value: logistioBulkConfig.paymentExpiration,
                    type: logistioBulkConfig.paymentExpirationType,
                    edit: false,
                    key: 'paymentExpiration',
                    typeKey: 'paymentExpirationType',
                },
            ]);
        }
    }, [logistioBulkConfig]);

    const handleEdit = (index: number) => {
        setSettings((prevSettings) =>
            prevSettings.map((setting, i) => (i === index ? { ...setting, edit: true } : setting)),
        );
    };

    const handleSave = (index: number) => {
        setSettings((prevSettings) =>
            prevSettings.map((setting, i) => (i === index ? { ...setting, edit: false } : setting)),
        );

        const currentSetting = settings[index];
        const updateData = {
            logistioBulkConfiguration: {
                [currentSetting.key]: currentSetting.value,
                [currentSetting.typeKey]: currentSetting.type,
            },
        };

        updateConfigItem(configId, updateData)
            .then((res) => {
                toast.success(res?.data?.message);
                getConfiguration().then((result) => {
                    setLogistioBulkConfig(result.data?.logistioBulkConfiguration);
                });
            })
            .catch((err) => {
                toast.error(err.response.data.errors.message);
            });
    };

    const handleCancel = (index: number) => {
        setSettings((prevSettings) =>
            prevSettings.map((setting, i) => (i === index ? { ...setting, edit: false } : setting)),
        );
    };

    const handleChange = (index: number, field: 'value' | 'type', value: string | number) => {
        setSettings((prevSettings) =>
            prevSettings.map((setting, i) => (i === index ? { ...setting, [field]: value } : setting)),
        );
    };

    return (
        <div className="flex bg-white p-2 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div className="w-1/3 p-4 border-r">
                <h2 className="text-lg font-semibold mb-4">Expired Settings</h2>
                <p className="text-sm font-semibold">Define the expired times for the Logistio Bulk request.</p>
            </div>
            <div className="w-2/3 p-4">
                {settings.map((setting, index) => (
                    <div key={index} className="flex items-center mb-2 py-2 border-b">
                        <div
                            className={`w-3 h-3 rounded-full mr-2 ${
                                setting.status === 'Under review'
                                    ? 'bg-blue-500'
                                    : setting.status === 'Quoted'
                                      ? 'bg-green-500'
                                      : 'bg-yellow-500'
                            }`}
                        />
                        <span className="w-1/4 text-sm font-medium">{setting.status}</span>

                        {setting.edit ? (
                            <>
                                <TextInput
                                    className="w-16 mr-2"
                                    value={setting.value}
                                    onChange={(e) => handleChange(index, 'value', parseInt(e.target.value, 10) || 0)}
                                />
                                <Select
                                    className="w-24 mr-2"
                                    value={setting.type}
                                    onChange={(e) => handleChange(index, 'type', e.target.value)}
                                >
                                    <option value="hours">hours</option>
                                    <option value="days">days</option>
                                </Select>
                                <Button pill size="sm" onClick={() => handleSave(index)}>
                                    <FaSave className="mr-2 h-5 w-5" />
                                    Save
                                </Button>
                                <Button
                                    pill
                                    color="failure"
                                    size="sm"
                                    className="ml-2"
                                    onClick={() => handleCancel(index)}
                                >
                                    Cancel
                                </Button>
                            </>
                        ) : (
                            <>
                                <span className="w-1/4 text-sm">
                                    {setting.status === 'Under review'
                                        ? `Respond in ${setting.value} ${setting.type}`
                                        : `Expires after ${setting.value} ${setting.type}`}
                                </span>
                                <Button pill size="sm" className="ml-auto" onClick={() => handleEdit(index)}>
                                    <HiPencilAlt className="mr-2 h-5 w-5" />
                                    Edit
                                </Button>
                            </>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LogistioBulkConfiguration;
