import React from 'react';
import { IStausInterface, TableColDef } from 'components/tables/tables.types';
import { Avatar, Badge } from 'flowbite-react';
import PDF from 'assets/pdf.png';

export const columns: TableColDef[] = [
    {
        field: 'customer',
        headerName: 'Seller',
        fieldType: 'nestedCombo',
        fieldPath: ['wallet', 'customer'],
        child: ['firstName', 'lastName'],
    },

    {
        field: 'status',
        headerName: 'status',
        fieldType: 'status',
    },
    {
        field: 'amount',
        fieldType: 'currency',
        fieldPath: ['wallet', 'customer'],
        headerName: 'Amount',
        child: ['currency'],
    },
    {
        field: 'createdAt',
        headerName: 'Date',
        fieldType: 'date',
    },
    {
        field: 'operationType',
        headerName: 'Operation Type',
    },
    // {
    //     field: 'verificationImage',
    //     headerName: 'Charging Proof',
    //     fieldType: 'image',
    // },
    {
        field: 'verificationImage',
        headerName: 'Charging Proof',
        fieldType: 'render',
        renderCell: (params) => (
            <div>
                {params.verificationImage && params.verificationImage.endsWith('.pdf') ? (
                    <Avatar img={(pr) => <img alt="img" referrerPolicy="no-referrer" src={PDF} {...pr} />} />
                ) : (
                    <Avatar
                        img={(pr) => (
                            <img alt="img" referrerPolicy="no-referrer" src={params.verificationImage} {...pr} />
                        )}
                    />
                )}
            </div>
        ),
    },
    {
        field: 'Action',
        actionType: 'WalletOperation',
        headerName: 'Options',
    },
];
export const statusDisplay: { [keys: string]: IStausInterface } = {
    1: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Pending
            </Badge>
        ),
        statusLabel: 'Pending',
    },
    2: {
        statusIcon: (
            <Badge color="success" size="sm">
                Confirmed
            </Badge>
        ),
        statusLabel: 'Confirmed',
    },
    3: {
        statusIcon: (
            <Badge color="failure" size="sm">
                Rejected
            </Badge>
        ),
        statusLabel: 'Rejected',
    },
};
