/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prettier/prettier */
import * as React from 'react';
import { Label, TextInput, Button } from 'flowbite-react';
import { Form, Formik } from 'formik';
import { FaSave } from 'react-icons/fa';
import { CallCenterUpdateInfoSchema } from 'utils/validations/ordersValidations';
import { HiPencilAlt } from 'react-icons/hi';
import moment from 'moment';
import CustomError from 'components/customError/CustomError';
import { IOrder } from 'services/admin/order';

export const OrderInformation: React.FC<{
    data: IOrder;
    editInfo: boolean;
    setEditInfo: React.Dispatch<React.SetStateAction<boolean>>;
    updateOrderInformations: React.Dispatch<React.SetStateAction<any>>;
    orderDetailsLoader: boolean;
}> = ({ data, editInfo, setEditInfo, updateOrderInformations, orderDetailsLoader }) => {
    return (
        <div className="bg-white p-2 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div className="flex items-center space-x-4">
                <div className="min-w-0 flex-1">
                    <h1 className="text-lg font-bold leading-none text-gray-900 dark:text-white">Buyer information</h1>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    {!editInfo && (
                        <Button
                            pill
                            size="xs"
                            onClick={() => {
                                setEditInfo(true);
                            }}
                        >
                            <HiPencilAlt className="mr-2 h-5 w-5" />
                            Edit
                        </Button>
                    )}
                </div>
            </div>
            {editInfo ? (
                <Formik
                    initialValues={{
                        company: data.company,
                        province: data.province,
                        phone: data.phone,
                        updateType: 2,
                    }}
                    onSubmit={(values, onSubmitProps) => {
                        updateOrderInformations(values);
                        onSubmitProps.setSubmitting(false);
                    }}
                    validationSchema={CallCenterUpdateInfoSchema}
                >
                    {(formik) => (
                        <Form onSubmit={formik.handleSubmit} placeholder="">
                            <div className="py-2">
                                <div>
                                    <div className="mb-2 block">
                                        <Label htmlFor="base" value="Company" />
                                    </div>
                                    <TextInput
                                        id="company"
                                        type="text"
                                        sizing="md"
                                        {...formik.getFieldProps('company')}
                                    />
                                </div>
                                <div>
                                    <div className="mb-2 block">
                                        <Label htmlFor="base" value="Phone" />
                                    </div>
                                    <TextInput
                                        id="company"
                                        type="tel"
                                        sizing="md"
                                        {...formik.getFieldProps('phone')}
                                        helperText={
                                            <>
                                                <CustomError name="phone" component="div" />
                                            </>
                                        }
                                    />
                                </div>
                                <div>
                                    <div className="mb-2 block">
                                        <Label htmlFor="base" value="Province" />
                                    </div>
                                    <TextInput
                                        id="company"
                                        type="text"
                                        sizing="md"
                                        {...formik.getFieldProps('province')}
                                    />
                                </div>
                                <div className="flex flex-wrap gap-4 justify-between py-2">
                                    <div>
                                        <Button
                                            pill
                                            color="failure"
                                            onClick={() => {
                                                setEditInfo(false);
                                            }}
                                        >
                                            cancel
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                            pill
                                            type="submit"
                                            disabled={
                                                !formik.isValid ||
                                                !formik.dirty ||
                                                formik.isSubmitting ||
                                                orderDetailsLoader
                                            }
                                            isProcessing={orderDetailsLoader}
                                        >
                                            <FaSave className="mr-2 h-5 w-5" />
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            ) : (
                <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                    <li className="py-3 sm:py-4">
                        <div className="flex items-center space-x-4">
                            <div className="min-w-0 flex-1">
                                <p className="truncate text-sm font-medium text-gray-900 dark:text-white">Company:</p>
                            </div>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                {data.company ?? '--'}
                            </div>
                        </div>
                    </li>
                    <li className="py-3 sm:py-4">
                        <div className="flex items-center space-x-4">
                            <div className="min-w-0 flex-1">
                                <p className="truncate text-sm font-medium text-gray-900 dark:text-white">Phone:</p>
                            </div>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                {data.phone ?? '--'}
                            </div>
                        </div>
                    </li>
                    <li className="py-3 sm:py-4">
                        <div className="flex items-center space-x-4">
                            <div className="min-w-0 flex-1">
                                <p className="truncate text-sm font-medium text-gray-900 dark:text-white">Province:</p>
                            </div>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                {data.province ?? '--'}
                            </div>
                        </div>
                    </li>
                    <li className="py-3 sm:py-4">
                        <div className="flex items-center space-x-4">
                            <div className="min-w-0 flex-1">
                                <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                    Created At:
                                </p>
                            </div>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                {moment(data.createdAt).format('MM/DD/YYYY HH:mm:SS')}
                            </div>
                        </div>
                    </li>
                    <li className="pt-3 pb-0 sm:pt-4">
                        <div className="flex items-center space-x-4">
                            <div className="min-w-0 flex-1">
                                <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                    Updated At:
                                </p>
                            </div>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                {moment(data.updatedAt).format('MM/DD/YYYY HH:mm:SS')}
                            </div>
                        </div>
                    </li>
                </ul>
            )}
        </div>
    );
};
