import {
    ICAStatistic,
    ICCAlLeads,
    ICCAlLeadsDahboard,
    IFilterCCADashboard,
} from 'pages/dashboard/CCADashboard/dashboard.types';
import axios from 'utils/axios';
import { getBearerToken } from 'utils/helpers/auth';

export interface IfilterDashboard extends IFilterCCADashboard {
    currentStatus?: number[];
}
export const getOrders = async (filter: IFilterCCADashboard) => {
    const config = await getBearerToken();
    const orderHistory = await axios.post<{ ordersCount: number }>(`/dashboard/orders/count`, filter, config);
    return orderHistory;
};
export const getHistoryOrders = async (data: IfilterDashboard) => {
    const config = await getBearerToken();
    const orderHistory = await axios.post<{ ordersCount: number }>(
        `/dashboard/orders/countHistory`,
        { ...data },
        config,
    );
    return orderHistory;
};

export const getAgentStatistic = async (data: IFilterCCADashboard) => {
    const config = await getBearerToken();
    const orderHistory = await axios.post<ICAStatistic[]>(`/dashboard/agents`, { ...data }, config);
    return orderHistory;
};
export const getOrdersHistoryCount = async (filter: IfilterDashboard) => {
    const config = await getBearerToken();
    const orderHistory = await axios.post<{ ordersCount: number }>(`/dashboard/ordershistory/count`, filter, config);
    return orderHistory;
};
export const getLeadsStats = async (data: IfilterDashboard) => {
    const config = await getBearerToken();
    const orderHistory = await axios.post<{ leadsStatistic: ICCAlLeadsDahboard }>(
        `/dashboard/leadsDahboard`,
        { ...data },
        config,
    );
    return orderHistory;
};
export const getLeads = async (data: IfilterDashboard) => {
    const config = await getBearerToken();
    const orderHistory = await axios.post<{ today: ICCAlLeads; period: ICCAlLeads }>(
        `/dashboard/leads`,
        { ...data },
        config,
    );
    return orderHistory;
};
export const getStats = async (data: any) => {
    const config = await getBearerToken();
    const orderHistory = await axios.post<any[]>(`/stats/filterStats`, { ...data }, config);
    return orderHistory;
};
