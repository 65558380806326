export const messagesPageStyle = {
    background: 'primaryBackground',
    // height: '85vh',
    borderTop: '1px solid',
    borderColor: '#DBDBDC',
};

export const messagesContainerStyle = { borderRight: '1px solid', borderColor: 'secondaryBackground' };

export const MessageBoxStyle = {
    height: '88vh',
    overflow: 'hidden',
    '&:hover': {
        overflowY: 'scroll',
    },
};

export const MessageTextsContainer = {
    // backgroundColor: 'red',
    padding: '10px',
    height: '62vh',
    overflow: 'hidden',
    '&:hover': {
        overflowY: 'scroll',
    },
};

export const MessageTexts = { margin: '10px' };
export const LoadPreviousMessagesWrapper = { display: 'flex', justifyContent: 'center', padding: '5px' };

export const LoadPreviousMessages = {
    backgroundColor: 'secondaryBackground',
    padding: '10px',
    borderRadius: '10px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};
