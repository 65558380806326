import React, { useEffect, useState } from 'react';
import { Modal, Select, TextInput } from 'flowbite-react';
import UseDocumentTitle from 'utils/dynamicTitle/dynamicTitle';
import CustomBtn from 'components/customButton/customBtn';
import { HiUserAdd } from 'react-icons/hi';
import { getAllCallCenters } from 'services/admin/callCenter';
import useDebouncedValue from 'utils/hooks/useDebouncedValue';
import { AiOutlineSearch } from 'react-icons/ai';
import toast from 'react-hot-toast';
import AddCallCenter from './addCallCenter/addCC';
import PerCent from './updateCallCenter/perCent';
import DataTable from '../../components/tables/tables';
import { columns, statusDisplay } from './callCenterTableActions/callCenterListElements';
import { ICallCenter } from './callCenter.types';

interface Ipagination {
    page: number;
    limit: number;
}
interface Imeta {
    totalDocs: number;
    limit: number;
    totalPages: number;
    page: number;
    pagingCounter: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    prevPage: number | null;
    nextPage: number | null;
}

interface Ifilter {
    status?: number;
    name?: string;
    location?: string;
}
const callCenterList = () => {
    const [open, setOpen] = useState(false);
    const [openPerCent, setOpenPerCent] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [callCenter, setCallCenter] = useState<Array<ICallCenter>>([]);

    const [filter, setFilter] = useState<Ifilter>({});

    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 20,
    });
    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });

    const handlePaginationChange = (value: { [key: string]: any }) => {
        setPagination((prevPagination) => {
            const data = { ...prevPagination, ...value };
            if (value.limit) {
                data.page = 1;
            }
            return data;
        });
        setMeta((prev) => ({ ...prev, page: -1 }));
    };

    function handleChangeFilter(event: any) {
        if (event.target.value !== '') {
            setFilter({ ...filter, ...{ [event.target.name]: event.target.value } });
        } else {
            setFilter({ ...filter, ...{ [event.target.name]: undefined } });
        }
        setPagination((prevPagination) => ({ ...prevPagination, page: 1 }));
        setMeta({ ...meta, page: -1 });
    }

    const debouncedPagination = useDebouncedValue(pagination, 500);
    const debouncedFilter = useDebouncedValue(filter, 500);
    useEffect(() => {
        const feedbackToast = toast.loading('Loading data...');
        setIsLoading(true);
        const queryOptions = { ...debouncedPagination, ...debouncedFilter };
        getAllCallCenters(queryOptions)
            .then((result) => {
                toast.dismiss(feedbackToast);
                setCallCenter(result.data.docs);
                setMeta({
                    hasNextPage: result.data.hasNextPage,
                    hasPrevPage: result.data.hasPrevPage,
                    limit: result.data.limit,
                    nextPage: result.data.nextPage,
                    page: result.data.page,
                    pagingCounter: result.data.pagingCounter,
                    prevPage: result.data.prevPage,
                    totalDocs: result.data.totalDocs,
                    totalPages: result.data.totalPages,
                });
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                toast.error((err as any)?.response?.data?.errors?.message || 'Unable to load data.', {
                    id: feedbackToast,
                });
            });
    }, [debouncedFilter, debouncedPagination]);

    UseDocumentTitle('Call Center');

    return (
        <>
            <h3>List of call centers</h3>
            <div className="flex justify-between mb-2">
                <div className="flex gap-2">
                    <TextInput
                        icon={AiOutlineSearch}
                        id="customerName"
                        placeholder="Search a call center"
                        name="name"
                        onChange={(e) => {
                            handleChangeFilter(e);
                        }}
                    />
                    <div id="select">
                        <Select
                            id="statusType"
                            name="status"
                            required
                            value={filter.status?.toString()}
                            onChange={(e) => {
                                handleChangeFilter(e);
                            }}
                        >
                            <option value="">All Status</option>
                            <option value="1">Active</option>
                            <option value="2">Disabled</option>
                        </Select>
                    </div>
                </div>
                <div className="flex gap-2">
                    <CustomBtn
                        variant="secondary"
                        onClick={() => {
                            setOpenPerCent(true);
                        }}
                    >
                        Configure call center percentage
                    </CustomBtn>
                    <Modal dismissible show={openPerCent} popup onClose={() => setOpenPerCent(false)}>
                        <Modal.Header>Percentage Distribution</Modal.Header>
                        <Modal.Body className="!p-0">
                            <PerCent setOpen={setOpenPerCent} setCallCenters={setCallCenter} />
                        </Modal.Body>
                    </Modal>
                    <CustomBtn
                        variant="primary"
                        onClick={() => {
                            setOpen(true);
                        }}
                    >
                        <HiUserAdd className="mr-2 h-5 w-5" />
                        Add a new call center
                    </CustomBtn>
                    <Modal dismissible show={open} popup onClose={() => setOpen(false)}>
                        <Modal.Header>Add a call center</Modal.Header>
                        <Modal.Body className="!p-0">
                            <AddCallCenter setOpen={setOpen} setCallCenters={setCallCenter} />
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
            <DataTable
                rows={callCenter}
                columns={columns}
                icons={statusDisplay}
                setRows={setCallCenter}
                pageState={{
                    isLoading,
                    total: meta.totalDocs,
                    pageSize: pagination.limit,
                    page: pagination.page,
                    count: meta.totalPages,
                }}
                setPageState={handlePaginationChange}
                filterState={filter}
            />
        </>
    );
};
export default callCenterList;
