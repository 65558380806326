/* eslint-disable no-unused-vars */
import { createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';

declare module '@mui/material/Button' {
    // eslint-disable-next-line no-unused-vars
    interface ButtonPropsVariantOverrides {
        primary: true;
        secondary: true;
        tertiary: true;
        quaternary: true;
        variantBox: true;
    }
}
declare module '@mui/material/styles' {
    interface Palette {
        selected: { [keys: string]: string };
        textColor: { [keys: string]: string };
        primaryBackground: string;
        secondaryBackground: string;
        grayBackground: string;
        borderColor: string;
        tertiaryBackground: string;
        inputBackground: string;
        disabledInputBackground: string;
        addIconColor?: string;
        notifcationRingColor?: string;
        errorColor?: string;
    }
    // allow configuration using `createTheme`
    interface PaletteOptions {
        selected?: { [keys: string]: string };
        textColor?: { [keys: string]: string };
        primaryBackground?: string;
        secondaryBackground?: string;
        tertiaryBackground?: string;
        grayBackground?: string;
        borderColor?: string;
        inputBackground?: string;
        disabledInputBackground: string;
        addIconColor?: string;
        notifcationRingColor?: string;
        errorColor?: string;
    }
}

// eslint-disable-next-line import/no-mutable-exports
let customTheme = createTheme({
    palette: {
        mode: 'light',
        primaryBackground: '#fff',
        secondaryBackground: '#f9f9fb',
        tertiaryBackground: '#f4f4f9',
        borderColor: '#ececec',
        grayBackground: '#f4f4f9',
        inputBackground: '#F4F4F9',
        disabledInputBackground: '#fff',
        addIconColor: orange[500],
        notifcationRingColor: '#161B22',
        primary: {
            main: '#578aff',
            dark: '#4b80fa',
            light: '#578aff',
        },
        secondary: {
            main: '#eef3ff',
            dark: '#e1e9fc',
        },

        textColor: {
            primary: '#6f6f9b',
            secondary: '#7e8cab',
            tertiary: '#505050',
            quaternary: '#6f6f9b',
            variantBox: '#000',
            selected: '#578aff',
            errorColor: '#D74242',
        },
    },
});
// eslint-disable-next-line no-unused-vars
customTheme = createTheme(customTheme, {
    typography: {
        fontFamily: 'Roboto',
        subtitle2: {
            color: customTheme.palette.textColor.tertiary,
            fontWeight: 600,
            fontSize: '14px',
            // marginBottom: '30px',
        },
        h4: {
            color: customTheme.palette.textColor.tertiary,
            fontWeight: 600,
            fontSize: '20px',
            margin: '10px 0px',
        },
        body1: {
            color: customTheme.palette.textColor.primary,
            fontWeight: 500,
            fontSize: '14px',
            margin: '0px',
        },
        body2: {
            color: customTheme.palette.textColor.primary,
            fontWeight: 500,
            fontSize: '12px',
        },
    },
    shape: {
        borderRadius: 8,
    },
    components: {
        MuiBox: {
            styleOverrides: {
                root: {
                    background: customTheme.palette.primaryBackground,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    background: customTheme.palette.primaryBackground,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
    },
});
export default customTheme;
