import * as Yup from 'yup';

const errorType = 'Field must be a number';
const errorRequired = 'Required Field';
export const AddPricingConfig = Yup.object().shape({
    country: Yup.string().required(),
    newLeadPrice: Yup.number().typeError(errorType).required(errorRequired),
    confirmedLeadPrice: Yup.number().typeError(errorType).required(errorRequired),
    deliveredLeadPrice: Yup.number().typeError(errorType).required(errorRequired),
    shippedLeadPrice: Yup.number().typeError(errorType).required(errorRequired),
    percentFromSellPrice: Yup.number().typeError(errorType).required(errorRequired),
    deliveredPriceforCC: Yup.number().typeError(errorType).required(errorRequired),
});

export const UpdatePricingConfig = Yup.object().shape({
    country: Yup.string().optional(),
    newLeadPrice: Yup.number().typeError(errorType).required(errorRequired),
    confirmedLeadPrice: Yup.number().typeError(errorType).required(errorRequired),
    deliveredLeadPrice: Yup.number().typeError(errorType).required(errorRequired),
    shippedLeadPrice: Yup.number().typeError(errorType).required(errorRequired),
    percentFromSellPrice: Yup.number().typeError(errorType).required(errorRequired),
    deliveredPriceforCC: Yup.number().typeError(errorType).required(errorRequired),
});
// Cod Pricing No call Center Validation
export const AddPricingCodNoCC = Yup.object().shape({
    country: Yup.string().required(),
    deliveredLeadPrice: Yup.number().typeError(errorType).required(errorRequired),
    shippedLeadPrice: Yup.number().typeError(errorType).required(errorRequired),
    percentFromSellPrice: Yup.number().typeError(errorType).required(errorRequired),
    handleFees: Yup.number().typeError(errorType).required(errorRequired),
});
export const UpdatePricingCodNoCC = Yup.object().shape({
    country: Yup.string().optional(),
    deliveredLeadPrice: Yup.number().typeError(errorType).required(errorRequired),
    shippedLeadPrice: Yup.number().typeError(errorType).required(errorRequired),
    percentFromSellPrice: Yup.number().typeError(errorType).required(errorRequired),
    handleFees: Yup.number().typeError(errorType).required(errorRequired),
});
// Prepaid Pricing No call Center Validation
export const AddPricingPrepaidNoCC = Yup.object().shape({
    country: Yup.string().required(),
    shippedLeadPrice: Yup.number().typeError(errorType).required(errorRequired),
    handleFees: Yup.number().typeError(errorType).required(errorRequired),
});
export const UpdatePricingPrepaidNoCC = Yup.object().shape({
    country: Yup.string().optional(),
    shippedLeadPrice: Yup.number().typeError(errorType).required(errorRequired),
    handleFees: Yup.number().typeError(errorType).required(errorRequired),
});
// Currency Rate No call Center Validation
export const AddPricingCurrencyRate = Yup.object().shape({
    country: Yup.string().required(),
    currencyValue: Yup.number().typeError(errorType).required(errorRequired),
});
export const UpdatePricingCurrencyRate = Yup.object().shape({
    country: Yup.string().optional(),
    currencyValue: Yup.number().typeError(errorType).required(errorRequired),
});
