export const ChatStyleHeader = {
    padding: '0px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'secondaryBackground',
    height: '55px',
};

export const ImageNextToText = {
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'center',
    gap: 1,
};

export const TitleStyle = { display: 'flex', gap: 1 };

export const StatusContainer = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 1,
    border: 'solid 1px #ececef',
    padding: '5px',
};

const renderColor = (status: number) => {
    switch (status) {
        case 1:
            return '#ff9a5c';
        case 2:
            return '#5c558a';
        case 3:
            return '#bc63e3';
        case 4:
            return '#93c66e';
        case 5:
            return '#ff7777';
        default:
            return '#578aff';
    }
};

export const StatusBall = (status: number) => {
    return {
        width: '2px',
        padding: '4px',
        background: renderColor(status),
        borderRadius: '50%',
        marginRight: '4px',
    };
};

export const DetailsStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: '20px',
};
