import React from 'react';
import { IStausInterface, TableColDef } from 'components/tables/tables.types';
import { Badge } from 'flowbite-react';

export const columns: TableColDef[] = [
    {
        field: 'storeName',
        headerName: 'Store Name',
    },
    {
        field: 'customer',
        headerName: 'Store Owner',
        fieldType: 'combo',
        child: ['firstName', 'lastName'],
    },
    {
        field: 'storeType',
        headerName: 'Store Type',
    },
    {
        field: 'storeLink',
        headerName: 'Store Link',
        fieldType: 'link',
    },
];
export const statusDisplay: { [keys: string]: IStausInterface } = {
    true: {
        statusIcon: (
            <Badge color="success" size="sm">
                Yes
            </Badge>
        ),
        statusLabel: 'Yes',
    },
    false: {
        statusIcon: (
            <Badge color="failure" size="sm">
                No
            </Badge>
        ),
        statusLabel: 'No',
    },
};
