/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import AutoComplete from 'components/autocomplete/autoComplete';
import { Select } from 'flowbite-react';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Datepicker from 'react-tailwindcss-datepicker';
import { getCustomers } from 'services/admin/customer';
import { IPayments } from 'services/admin/payments';
import { getCustomerWallet } from 'services/admin/wallet';
import UseDocumentTitle from 'utils/dynamicTitle/dynamicTitle';
import useDebouncedValue from 'utils/hooks/useDebouncedValue';
import DataTable from '../../components/tables/tables';
import { columns, statusDisplay } from './operationsTableActions/walletOpElements';

interface Ipagination {
    page: number;
    limit: number;
}

interface Imeta {
    totalDocs: number;
    limit: number;
    totalPages: number;
    page: number;
    pagingCounter: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    prevPage: number | null;
    nextPage: number | null;
}

interface IdateRange {
    startDate: string;
    endDate: string;
}

interface Ifilter {
    status?: number;
    createdAt?: object;
    operationType?: string;
    dateRange?: IdateRange;
    customer?: string;
}

const WalletOpertaions = () => {
    UseDocumentTitle('Wallet Opertaions');

    const [operationsList, setOperationsList] = useState<Array<IPayments>>([]);

    // const [paymentData, setPaymentData] = useState<IPayment | {}>({});

    const [customers, setCustomers] = useState([]);

    const [customer] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [Rangevalue, setRangeValue] = useState(null);
    useEffect(() => {
        if (customer) getCustomers({ limit: 1000, email: customer }).then((res) => setCustomers(res.data.docs));
        else getCustomers({ limit: 1000 }).then((res) => setCustomers(res.data.docs));
    }, [customer]);

    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 20,
    });
    const [filter, setFilter] = useState<Ifilter>({ dateRange: Rangevalue || undefined });
    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });
    const [isLoading, setIsLoading] = useState(false);
    const handleRangeFilterChange = (newValue: any) => {
        let newRange: typeof newValue | undefined;
        if (newValue && newValue.startDate && newValue.endDate) {
            const startD = moment.tz(newValue.startDate, 'Asia/Shanghai');
            const endD = moment.tz(newValue.endDate, 'Asia/Shanghai');
            endD.set('hour', 23);
            endD.set('minute', 59);
            endD.set('second', 59);
            newRange = {
                startDate: startD.format(),
                endDate: endD.format(),
            };
        }
        setRangeValue(newValue || null);
        setFilter({ ...filter, ...{ dateRange: newRange } });
        setPagination({ ...pagination, page: 1 });
    };

    const handleCustomerFilterChange = (newFilter: Ifilter) => {
        if (newFilter.customer === undefined || newFilter.customer === '') {
            setFilter((oldFilter) => {
                const temp = oldFilter;
                delete temp.customer;
                return { ...temp };
            });
        } else {
            setFilter({ ...filter, ...newFilter });
        }
    };

    const debouncedPagination = useDebouncedValue(pagination, 500);
    const debouncedFilter = useDebouncedValue(filter, 500);
    // fetch stores on component mount & pagination or filter change
    useEffect(() => {
        const queryOptions = { ...debouncedPagination, ...debouncedFilter };
        setIsLoading(true);
        getCustomerWallet(queryOptions)
            .then((res) => {
                const data = res.data.response;
                setOperationsList(data.docs);
                setMeta({
                    hasNextPage: data.hasNextPage,
                    hasPrevPage: data.hasPrevPage,
                    limit: data.limit,
                    nextPage: data.nextPage,
                    page: data.page,
                    pagingCounter: data.pagingCounter,
                    prevPage: data.prevPage,
                    totalDocs: data.totalDocs,
                    totalPages: data.totalPages,
                });
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                toast.error(err.response.data.errors.message);
            });
    }, [debouncedFilter, debouncedPagination, customerId]);

    const handlePaginationChange = (value: { [key: string]: any }) => {
        setPagination((prevPagination) => {
            const data = { ...prevPagination, ...value };
            if (value.limit) {
                data.page = 1;
            }
            return data;
        });
        setMeta((prev) => ({ ...prev, page: -1 }));
    };

    return (
        <>
            <div className="flex gap-2 items-center  mb-2">
                <div className="w-72">
                    <AutoComplete
                        key="id"
                        placeholder="Sellers"
                        options={customers as []}
                        getOptionLabel={(option: any) =>
                            option?.lastName && option?.firstName
                                ? `${option.firstName} ${option.lastName}`
                                : option?.email
                        }
                        // eslint-disable-next-line no-underscore-dangle
                        onChange={(option) => {
                            handleCustomerFilterChange({ customer: option._id as string });
                            setCustomerId(option._id as string);
                        }}
                    />
                </div>
                <div id="select">
                    <Select
                        id="operationStatus"
                        required
                        value={filter.status?.toString()}
                        onChange={(e) => {
                            if (e.target.value !== '') {
                                setFilter({ ...filter, status: Number(e.target.value) });
                            } else {
                                setFilter({ ...filter, status: undefined });
                            }
                            setMeta({ ...meta, page: -1 });
                        }}
                    >
                        <option value="">All Status</option>
                        <option value="1">Pending</option>
                        <option value="2">Confirmed</option>
                        <option value="3">Rejected</option>
                    </Select>
                </div>
                <div id="selectType">
                    <Select
                        id="operationType"
                        required
                        value={filter.operationType?.toString()}
                        onChange={(e) => {
                            if (e.target.value !== '') {
                                setFilter({ ...filter, operationType: e.target.value });
                            } else {
                                setFilter({ ...filter, operationType: undefined });
                            }
                        }}
                    >
                        <option value="">All Types</option>
                        <option value="IN">IN</option>
                        <option value="OUT">OUT</option>
                        <option value="REFUND">REFUND</option>
                    </Select>
                </div>
                <div className="block">
                    <Datepicker
                        inputId="deliveryDate"
                        value={Rangevalue}
                        containerClassName="relative z-100"
                        // inputClassName="absolute"
                        // toggleClassName="absolute
                        useRange
                        onChange={handleRangeFilterChange}
                    />
                </div>
            </div>

            <DataTable
                rows={operationsList}
                columns={columns}
                icons={statusDisplay}
                setRows={setOperationsList}
                pageState={{
                    isLoading,
                    total: meta.totalDocs,
                    pageSize: pagination.limit,
                    page: pagination.page,
                    count: meta.totalPages,
                }}
                setPageState={handlePaginationChange}
                filterState={filter}
            />
        </>
    );
};

export default WalletOpertaions;
