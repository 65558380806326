import { Label, TextInput } from 'flowbite-react';
import React from 'react';
import { IConfig } from 'services/admin/configuration';

const OrderConfiguration: React.FC<{ orderConfig: IConfig['order'] }> = ({ orderConfig }) => {
    return (
        <div className=" p-4 mb-2 gap-4 w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <h1 className="mb-5 text-lg font-bold leading-none text-gray-900 dark:text-white">
                Unreachable / Expired Orders
            </h1>
            <div className="mb-4">
                <div className="mb-2 block">
                    <Label htmlFor="assignUnreachableAfterHours" value="Auto reassign unreachable orders after" />
                </div>
                <div className="flex items-center dark:bg-gray-700 bg-gray-100 w-fit rounded-md">
                    <TextInput
                        name="assignUnreachableAfterHours"
                        type="text"
                        sizing="md"
                        value={`${orderConfig?.assignUnreachableAfterHours || ''}`}
                        className="max-w-[50px]"
                        disabled
                    />
                    <p className="px-2 font-bold">hours</p>
                </div>
                <p className="opacity-50 text-xs p-2">
                    *Automatically reassign <span className="font-bold">unreachable</span> orders after the specified
                    period
                </p>
            </div>

            <div className="mb-4">
                <div className="mb-2 block">
                    <Label htmlFor="orderMaxUnreachable" value="Order attempts before expiration" />
                </div>
                <div className="flex items-center dark:bg-gray-700 bg-gray-100 w-fit rounded-md">
                    <TextInput
                        name="orderMaxUnreachable"
                        type="text"
                        sizing="md"
                        className="max-w-[50px]"
                        value={`${orderConfig?.orderMaxUnreachable || ''}`}
                        disabled
                    />
                    <p className="px-2 font-bold">attempts</p>
                </div>
                <p className="opacity-50 text-xs p-2">
                    *Update the <span className="font-bold">unreachable</span> orders status to{' '}
                    <span className="font-bold">expired</span> after the specified attempts
                </p>
            </div>
        </div>
    );
};

export default OrderConfiguration;
