/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Box, Button } from '@mui/material';
import { ProductImageUploadProps } from './ProductImageUpload.types';
import {
    clickTextStyle,
    CustomFileInput,
    CustomLabelInput,
    imageSizeStyle,
    imageTypes,
    uploadContainer,
    uploadTextWrapper,
    uploadWrapper,
} from './ProductImageUpload.styles';
import Upload from '../../assets/upload.png';

const ProductImageUpload: React.FC<ProductImageUploadProps> = ({ name, onChange, userDisplayedImage }) => {
    return (
        <Button sx={uploadWrapper}>
            <CustomFileInput
                type="file"
                id="file"
                accept="image/*, .pdf, .ai, .psd"
                onChange={onChange}
                multiple
                name={name}
            />
            <CustomLabelInput htmlFor="file">
                <Box sx={userDisplayedImage && uploadContainer}>
                    <img src={Upload} alt="" width="120px" />
                    <Box sx={userDisplayedImage && uploadTextWrapper}>
                        <Box sx={clickTextStyle}>Click here to upload your files</Box>
                        <Box sx={imageSizeStyle}>To upload product image (Max size 2 MB)</Box>
                        <Box sx={imageTypes}>Supports: jpg, jpeg, png, pdf, ai, psd, svg</Box>
                    </Box>
                </Box>
            </CustomLabelInput>
        </Button>
    );
};

export default ProductImageUpload;
