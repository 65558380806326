import { IResellerStock } from 'services/admin/resellerStock';

export interface IResellerQuotation {
    country: string;
    packaging: string;
    quantity: number;
    totalShippingFee: number;
    shippingDuration: string;
    productCost: number;
    unitPurchasePrice: number;
    recommendedPrice: Number;
    sellingPrice: Number;
    unitProfit: Number;
    type: string;
    trackingCode?: string | undefined;
    accepted?: boolean;
    isHidden?: boolean;
    editable?: boolean;
}
export interface ResellerProduct {
    _id: string;
    name: string;
    adCopies?: [string];
    link: string;
    shippingTo: [string];
    quotation: IResellerQuotation[];
    status: 1 | 2 | 3 | 4 | 11;
    quantity: number;
    sellingPrice: { min: number; max?: number };
    thumbnail: string;
    images: string[];
    videos: string[];
    files: string[];
    chineseName: string;
    weight: number;
    width: number;
    height: number;
    length: number;
    // at least on index should be available
    stockConfigs: [
        {
            warehouseId: string;
            quantity: string;
            country: string;
        },
        ...{
            warehouseId: string;
            quantity: string;
            country: string;
        }[],
    ];
    hsCode: string;
    barCode: string;
    vnlinId?: string;
    sku: string;
    canBeOrdered: boolean;
    openForSale: boolean;
    categories: [string];
    landingPages?: [string];
    declarationAmount: number;
    customers: string[];
    quantityLimit: number;
    notificationType?: string[];
    description?: string;
}
export type VNLIN_TRANSPORT = 'A' | 'E' | 'S';
export type VNLIN_PLATFORM = '速卖通' | '亚马逊FLEX' | 'NOON FLEX' | '独立站' | '其他';

export interface IBox {
    ctns: number;
    weight: number;
    height: number;
    length: number;
    width: number;
    productQuantity: number;
    boxNumber: number;
}
export interface IUpdateStock {
    boxes: IBox[];
    is_solely: 'YES' | 'NO';
    noofPieces: number;
    battery: 0 | 1;
    transportation: VNLIN_TRANSPORT;
    plateform: VNLIN_PLATFORM;
    logo: 0 | 1;
    customsDeclaration?: 'YES' | 'NO';
    documents: string;
}
export interface IStockPackingProps {
    stock: IResellerStock;
    setRows: React.Dispatch<React.SetStateAction<IResellerStock[]>>;
    currentPage?: number;
    currentFilter?: Record<string, any>;
    setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
export const STOCK_TRANSPORTATION = [
    { label: 'By Air', value: 'A' },
    { label: 'B2B E-commerce', value: 'E' },
    { label: 'By Sea', value: 'S' },
];
export const STOCK_PLATEFORM = [
    { label: 'Aliexpress', value: '速卖通' },
    { label: 'Amazon FLEX', value: '亚马逊FLEX' },
    { label: 'NOON FLEX', value: 'NOON FLEX' },
    { label: 'E-commerce', value: '独立站' },
    { label: 'Other', value: '其他' },
];
export interface Imeta {
    totalDocs: number;
    limit: number;
    totalPages: number;
    page: number;
    pagingCounter: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    prevPage: number | null;
    nextPage: number | null;
}
export interface Ipagination {
    page: number;
    limit: number;
}
export interface IResellerStockFilter {
    warehouse?: string;
    sku?: string;
    resellerProduct?: string;
}
export interface HistoryProps {
    stock: IResellerStock;
}
