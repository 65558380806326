/* eslint-disable react/jsx-fragments */
import CustomBtn from 'components/customButton/customBtn';
import React, { useState } from 'react';
import { AiOutlineAppstoreAdd } from 'react-icons/ai';
import CountriesAccordion from './countriesAccordion';
import AddConfiguration from './addConfiguration';
import { IPricingProps } from '../pricingConfiguration.types';

const PricingPrepaidNoCC: React.FC<IPricingProps> = ({ pricing, configId, setConfigPricing }) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <AddConfiguration
                open={open}
                setOpen={setOpen}
                configPricing={pricing}
                setConfigPricing={setConfigPricing}
            />
            <div className="flex justify-end gap-2 p-2">
                <CustomBtn variant="primary" onClick={() => setOpen(true)} pill>
                    <AiOutlineAppstoreAdd className="mr-2 h-5 w-5" />
                    New
                </CustomBtn>
            </div>

            <div>
                <CountriesAccordion pricing={pricing} configId={configId} setConfigPricing={setConfigPricing} />
            </div>
        </>
    );
};

export default PricingPrepaidNoCC;
