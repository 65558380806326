/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-unused-vars */
import React from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import Layout from 'layouts/layout';
import routes from 'routes';
import AuthLayout from 'layouts/authLayout';
import AccountManagerLayout from 'layouts/accountManagerLayout';
import AdminLayout from 'layouts/adminLayout';
import ProductProviderLayout from 'layouts/productProviderLayout';
import ProtectedRoute from 'utils/helpers/protectedRoute';
import PublicRoute from 'utils/helpers/publicRoute';
import NotFound from 'pages/404NotFound/notFound';
import CallAgentLayout from 'layouts/callAgentLayout';
import CallCenterAdminLayout from 'layouts/callCenterAdminLayout';

const App: React.FC = () => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const getRoutes = (routes: any, routePath: any) => {
        return routes.map((prop: any, key: any) => {
            if (prop.layout === routePath) {
                if (prop.children) {
                    return prop.children
                        .map((child: any, childKey: any) => {
                            return (
                                <Route key={childKey} path={child.layout + child.path} element={<child.element />} />
                            );
                        })
                        .flat();
                }
                if (prop.routes) {
                    return (
                        <Route
                            key={key}
                            path={prop.layout + prop.path}
                            element={
                                <prop.element>
                                    <Outlet />
                                </prop.element>
                            }
                        >
                            {prop.routes.map((property: any, index: any) => {
                                if (property.path === '/all' || property.path === '/profile-settings')
                                    return <Route index element={<property.element />} key={index} />;
                                return (
                                    <Route
                                        path={property.layout + property.path}
                                        element={<property.element />}
                                        key={index}
                                    />
                                );
                            })}
                        </Route>
                    );
                }
                return <Route path={prop.layout + prop.path} element={<prop.element />} key={key} />;
            }
            return null;
        });
    };
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route element={<PublicRoute />}>
                        <Route path="/auth" element={<AuthLayout />}>
                            {getRoutes(routes, '/auth')}
                        </Route>
                    </Route>

                    <Route element={<ProtectedRoute />}>
                        <Route path="/admin" element={<AdminLayout />}>
                            {getRoutes(routes, '/admin')}
                        </Route>
                        <Route path="/productProvider" element={<ProductProviderLayout />}>
                            {getRoutes(routes, '/productProvider')}
                        </Route>
                        <Route path="/accountManager" element={<AccountManagerLayout />}>
                            {getRoutes(routes, '/accountManager')}
                        </Route>

                        <Route path="/callAgent" element={<CallAgentLayout />}>
                            {getRoutes(routes, '/callAgent')}
                        </Route>
                        <Route path="/callCenterAdmin" element={<CallCenterAdminLayout />}>
                            {getRoutes(routes, '/callCenterAdmin')}
                        </Route>
                        <Route path="/" element={<Layout />}>
                            {getRoutes(routes, '/')}
                        </Route>
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
};

export default App;
