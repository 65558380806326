/* eslint-disable no-underscore-dangle */
import GeneralDrawer from 'components/generalDrawer/generalDrawer';
import DataTable from 'components/tables/tables';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { IoMdCloseCircle } from 'react-icons/io';
import { TbTruckDelivery } from 'react-icons/tb';
import { IStock } from 'services/admin/stock';
import { getStockShipmentsByStockId, IShipment } from 'services/admin/stockShipment';
import { shipmentDetailsColumns } from '../StockListElements';

const ShipmentDetails: React.FC<{ data: IStock }> = ({ data }) => {
    const [shipmentDetails, setShipmentDetails] = useState<IShipment[]>([]);
    const [openDetails, setOpenDetails] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (data?._id && openDetails) {
            const toastId = toast.loading('Loading stock shipments details data...');
            setIsLoading(true);
            getStockShipmentsByStockId(data?._id)
                .then((res) => {
                    toast.dismiss(toastId);
                    setShipmentDetails(res.data);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                    toast.error('Something went wrong', { id: toastId });
                });
        }
    }, [data._id, openDetails]);

    return (
        <>
            <TbTruckDelivery className="w-5 h-5 cursor-pointer" onClick={() => setOpenDetails(true)} />
            <GeneralDrawer
                anchor="right"
                open={openDetails}
                onClose={setOpenDetails}
                PaperProps={{
                    sx: { width: '60%' },
                }}
            >
                <div className="p-4">
                    <div className="flex justify-between items-center">
                        <div>
                            <p className="text-xl font-bold">Shipping details</p>
                        </div>
                        <button
                            type="button"
                            onClick={() => setOpenDetails(false)}
                            className="p-1 hover:dark:bg-gray-700 hover:bg-gray-100 rounded-md"
                        >
                            <IoMdCloseCircle className="w-8 h-8" color="grey" />
                        </button>
                    </div>

                    <div className="flex items-center w-full my-7">
                        <p className="text-xl font-medium">
                            {data?.product?.itemKind === 'variant'
                                ? data?.parentProduct?.restockFor?.name || data?.parentProduct?.name
                                : data?.product?.name}
                        </p>
                        {data?.product?.itemKind === 'variant' && (
                            <p className="text-l font-medium pt-2 pl-2">{data?.product?.name || ''}</p>
                        )}
                    </div>

                    <DataTable
                        rows={shipmentDetails}
                        setRows={setShipmentDetails}
                        columns={shipmentDetailsColumns}
                        pageState={{
                            isLoading,
                            total: 0,
                            pageSize: 0,
                            page: 0,
                            count: 0,
                        }}
                        setPageState={() => {}}
                    />
                </div>
            </GeneralDrawer>
        </>
    );
};
export default ShipmentDetails;
