/* eslint-disable no-underscore-dangle */
import React from 'react';
import { IIconsInterface, IStausInterface, TableColDef } from 'components/tables/tables.types';
import { Badge } from 'flowbite-react';
import { IPayments } from 'services/admin/payments';

export const paymentSource: { [keys: string]: IIconsInterface } = {
    wallet: {
        icon: (
            <Badge color="info" size="sm">
                Wallet Operation
            </Badge>
        ),
        label: 'Wallet Operation',
    },
    bank: {
        icon: (
            <Badge color="gray" size="sm">
                Bank Transaction
            </Badge>
        ),
        label: 'Bank Transaction',
    },
    walletBank: {
        icon: (
            <Badge color="purple" size="sm">
                Wallet Operation and Bank Transaction
            </Badge>
        ),
        label: 'Wallet Operation and BANK Transaction',
    },
    other: {
        icon: (
            <Badge color="purple" size="sm">
                Other
            </Badge>
        ),
        label: 'Other',
    },
};
export const paymentType: { [keys: string]: IIconsInterface } = {
    order: {
        icon: (
            <Badge color="pink" size="sm">
                Order Fees
            </Badge>
        ),
        label: 'Order Fees',
    },
    product: {
        icon: (
            <Badge color="success" size="sm">
                Product Sourcing
            </Badge>
        ),
        label: 'Product Sourcing',
    },
    restock: {
        icon: (
            <Badge color="success" size="sm">
                Restock Product
            </Badge>
        ),
        label: 'Restock Product',
    },
    invoice: {
        icon: (
            <Badge color="indigo" size="sm">
                Invoice Payment
            </Badge>
        ),
        label: 'Invoice Payment',
    },
    resellerProduct: {
        icon: (
            <Badge color="indigo" size="sm">
                Reseller Product Fees
            </Badge>
        ),
        label: 'Reseller Product Fees',
    },
    other: {
        icon: (
            <Badge color="purple" size="sm">
                Other
            </Badge>
        ),
        label: 'Other',
    },
};
export const columns: TableColDef[] = [
    {
        field: 'customer',
        headerName: 'Seller',
        fieldType: 'combo',
        child: ['firstName', 'lastName'],
    },
    {
        field: 'accountName',
        headerName: 'Transaction Name',
    },
    {
        field: 'status',
        headerName: 'status',
        fieldType: 'status',
    },
    {
        field: 'amount',
        fieldType: 'currency',
        fieldPath: ['customer'],
        headerName: 'Amount',
        child: ['currency'],
    },
    {
        field: 'createdAt',
        headerName: 'Date',
        fieldType: 'date',
    },
    {
        field: 'source',
        headerName: 'Source',
        fieldType: 'render',
        renderCell: (params: IPayments) => (
            <>
                <div className="flex items-center">{paymentSource[params.source]?.icon}</div>
            </>
        ),
    },
    {
        field: 'type',
        headerName: 'Type',
        fieldType: 'render',
        renderCell: (params: IPayments) => (
            <>
                <div className="flex items-center">{paymentType[params.type]?.icon}</div>
            </>
        ),
    },
    {
        field: 'Action',
        actionType: 'Payment',
        headerName: 'Options',
    },
];
export const statusDisplay: { [keys: string]: IStausInterface } = {
    1: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Pending
            </Badge>
        ),
        statusLabel: 'Pending',
    },
    2: {
        statusIcon: (
            <Badge color="success" size="sm">
                Confirmed
            </Badge>
        ),
        statusLabel: 'Confirmed',
    },
    3: {
        statusIcon: (
            <Badge color="failure" size="sm">
                Rejected
            </Badge>
        ),
        statusLabel: 'Rejected',
    },
};
