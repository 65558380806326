/* eslint-disable no-underscore-dangle */
import { AxiosResponse } from 'axios';
import TextError from 'components/customError/customErrorText';
import FormError from 'components/customError/formError';
import { Label, Progress, Radio, TextInput, Textarea } from 'flowbite-react';
import { FieldArray, Form, Formik } from 'formik';

import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FaPlus } from 'react-icons/fa';
import { IoMdRemoveCircle } from 'react-icons/io';
import { useLocation, useNavigate } from 'react-router-dom';
import { confirmResellerProduct, getProduct, updateResellerProduct, uploadMedia } from 'services/admin/resellerProduct';
import { getCurrentUser } from 'utils/helpers/auth';
import { addResellerSchema, draftResellerSchema } from 'utils/validations/addResellerProduct';
import Upload from '../../../assets/productDesign.svg';
import { ResellerProduct } from '../reseller.types';
import GeneralInformations from './helpers/generalInformations';
import MediaUploader from './helpers/mediaUploader';
import QuotationForm from './helpers/quotationForm';
import { FORM_SUBMIT, IAddResellerProduct, MediaData } from './helpers/types';

const ResellerProductDetails: React.FC = () => {
    const [resellerProduct, setResellerProduct] = useState<ResellerProduct>();
    const [initialData, setInitialData] = useState<IAddResellerProduct>({
        name: resellerProduct?.name || '',
        adCopies: resellerProduct?.adCopies || [''],
        chineseName: resellerProduct?.chineseName || '',
        quantity: resellerProduct?.quantity || 0,
        weight: resellerProduct?.weight || 0,
        width: resellerProduct?.width || 0,
        height: resellerProduct?.height || 0,
        length: resellerProduct?.length || 0,
        declarationAmount: resellerProduct?.declarationAmount || 0,
        resellerQuotation: resellerProduct?.quotation || [],
        canBeOrdered: resellerProduct?.canBeOrdered || false,
        notificationType: [], // from stock data
        categories: resellerProduct?.categories || [],
        quantityLimit: 0, // from stock data
        landingPages: resellerProduct?.landingPages || [''],
        description: resellerProduct?.description || '',
    });
    const [allImages, setAllImages] = useState<MediaData[]>([]);
    const [resellerVideos, setResellerVideos] = useState<string[]>([]);
    const [urlImage, setUrlImage] = useState(Upload);

    const [canBeOrdered, setCanBeOrdered] = useState(resellerProduct?.canBeOrdered || false);
    const [isLoading, setIsLoading] = useState(false);
    const [allVideos, setAllVideos] = useState<MediaData[]>([]);
    const [progressCount, setProgressCount] = useState(0);
    const [thumbnail, setThumbnail] = useState<MediaData | null>(null);
    const location = useLocation();
    const navigate = useNavigate();
    const [formLoader, setFormLoader] = useState(false);
    const clearMediaFields = () => {
        setAllImages([]);
        setAllVideos([]);
        setResellerVideos([]);
        setThumbnail(null);
        setUrlImage(Upload);
    };

    const handleUpdate = async (values: IAddResellerProduct) => {
        const config = {
            onUploadProgress: (progressEvent: any) => {
                const progress = Math.round((progressEvent.loaded * 50) / progressEvent.total);
                setProgressCount(progress);
            },
            onDownloadProgress: (progressEvent: any) => {
                const progress = Math.round(50 + (progressEvent.loaded * 50) / progressEvent.total);
                setProgressCount(progress);
            },
        };
        values.resellerQuotation.map((quot) => {
            const newval = quot;
            delete newval.editable;
            return newval;
        });
        const newProductData = {
            name: values.name,
            chineseName: values.chineseName,
            adCopies: values.adCopies,
            quantity: values.quantity,
            weight: values.weight,
            width: values.width,
            height: values.height,
            length: values.length,
            quotation: values.resellerQuotation,
            declarationAmount: values.declarationAmount,
            quantityLimit: values.quantityLimit,
            notificationType: values.notificationType,
            categories: values.categories,
            landingPages: values.landingPages,
            canBeOrdered,
            description: values.description,
        };
        let assetsFormData = new FormData();
        const existingVidsUrls: string[] = [];
        const existingImagesUrls: string[] = [];
        const existingFilesUrls: string[] = [];
        allVideos.forEach((vidData) => {
            if (vidData.file) {
                assetsFormData.append(vidData.name, vidData.file);
            } else {
                existingVidsUrls.push(vidData.url);
            }
        });
        allImages.forEach((imageData) => {
            if (imageData.file) {
                assetsFormData.append(imageData.name, imageData.file);
            } else {
                existingImagesUrls.push(imageData.url);
            }
        });

        if (allVideos.length > 0 || allImages.length > 0 || thumbnail?.file) {
            const toastId = toast.loading('Uploading product assets...');
            let uploadRes: AxiosResponse<
                {
                    uploderImages?: string[] | undefined;
                    uplodervideos?: string[] | undefined;
                    uploaderFiles?: string[] | undefined;
                },
                any
            >;
            uploadRes = await uploadMedia(assetsFormData, config);
            if (uploadRes.data.uploderImages && uploadRes.data.uploderImages.length > 0) {
                Object.assign(newProductData, { images: [...existingImagesUrls, ...uploadRes.data.uploderImages] });
            }
            if (uploadRes.data.uploaderFiles && uploadRes.data.uploaderFiles.length > 0) {
                Object.assign(newProductData, { files: [...existingFilesUrls, ...uploadRes.data.uploaderFiles] });
            }
            if (uploadRes.data.uplodervideos && uploadRes.data.uplodervideos.length > 0) {
                Object.assign(newProductData, { videos: [...existingVidsUrls, ...uploadRes.data.uplodervideos] });
            }
            toast.success('Product assets uploaded', { id: toastId });
            if (thumbnail?.file) {
                toast.loading('Uploading product thumbnail...', { id: toastId });
                assetsFormData = new FormData();
                assetsFormData.append(thumbnail.name, thumbnail.file);
                uploadRes = await uploadMedia(assetsFormData, config);
                if (uploadRes.data.uploderImages && uploadRes.data.uploderImages.length > 0) {
                    Object.assign(newProductData, { thumbnail: uploadRes.data.uploderImages[0] });
                    toast.success('Product thumbnail uploaded', { id: toastId });
                }
            }
        }
        const dataToast = toast.loading('Saving new reseller product...');
        const { productId } = location.state as { productId: string };
        updateResellerProduct(newProductData, productId, config)
            .then(() => {
                toast.success('Reseller product updated', { id: dataToast });
                const user = getCurrentUser();
                let navigation = 'admin';
                if (user.role === 'accountManager') {
                    navigation = 'accountManager';
                }
                if (user.role === 'productProvider') {
                    navigation = 'productProvider';
                }
                navigate(`/${navigation}/resellerProducts`);
            })
            .catch((err) => {
                toast.error(`Sorry update failed! Error: ${err?.response?.data?.errors?.message}`, { id: dataToast });
            })
            .finally(() => {
                setIsLoading(false);
                setProgressCount(0);
            });
    };
    const handleConfirmation = async (values: IAddResellerProduct) => {
        const config = {
            onUploadProgress: (progressEvent: any) => {
                const progress = Math.round((progressEvent.loaded * 50) / progressEvent.total);
                setProgressCount(progress);
            },
            onDownloadProgress: (progressEvent: any) => {
                const progress = Math.round(50 + (progressEvent.loaded * 50) / progressEvent.total);
                setProgressCount(progress);
            },
        };
        values.resellerQuotation.map((quot) => {
            const newval = quot;
            delete newval.editable;
            return newval;
        });
        const newProductData = {
            name: values.name,
            chineseName: values.chineseName,
            adCopies: values.adCopies,
            quantity: values.quantity,
            weight: values.weight,
            width: values.width,
            height: values.height,
            length: values.length,
            quotation: values.resellerQuotation,
            declarationAmount: values.declarationAmount,
            quantityLimit: values.quantityLimit,
            notificationType: values.notificationType,
            categories: values.categories,
            landingPages: values.landingPages,
            canBeOrdered,
        };
        let assetsFormData = new FormData();
        const existingVidsUrls: string[] = [];
        const existingImagesUrls: string[] = [];
        const existingFilesUrls: string[] = [];
        allVideos.forEach((vidData) => {
            if (vidData.file) {
                assetsFormData.append(vidData.name, vidData.file);
            } else {
                existingVidsUrls.push(vidData.url);
            }
        });
        allImages.forEach((imageData) => {
            if (imageData.file) {
                assetsFormData.append(imageData.name, imageData.file);
            } else {
                existingImagesUrls.push(imageData.url);
            }
        });

        if (allVideos.length > 0 || allImages.length > 0 || thumbnail?.file) {
            const toastId = toast.loading('Uploading product assets...');
            let uploadRes: AxiosResponse<
                {
                    uploderImages?: string[] | undefined;
                    uplodervideos?: string[] | undefined;
                    uploaderFiles?: string[] | undefined;
                },
                any
            >;
            uploadRes = await uploadMedia(assetsFormData, config);
            if (uploadRes.data.uploderImages && uploadRes.data.uploderImages.length > 0) {
                Object.assign(newProductData, { images: [...existingImagesUrls, ...uploadRes.data.uploderImages] });
            }
            if (uploadRes.data.uploaderFiles && uploadRes.data.uploaderFiles.length > 0) {
                Object.assign(newProductData, { files: [...existingFilesUrls, ...uploadRes.data.uploaderFiles] });
            }
            if (uploadRes.data.uplodervideos && uploadRes.data.uplodervideos.length > 0) {
                Object.assign(newProductData, { videos: [...existingVidsUrls, ...uploadRes.data.uplodervideos] });
            }
            toast.success('Product assets uploaded', { id: toastId });
            if (thumbnail?.file) {
                toast.loading('Uploading product thumbnail...', { id: toastId });
                assetsFormData = new FormData();
                assetsFormData.append(thumbnail.name, thumbnail.file);
                uploadRes = await uploadMedia(assetsFormData, config);
                if (uploadRes.data.uploderImages && uploadRes.data.uploderImages.length > 0) {
                    Object.assign(newProductData, { thumbnail: uploadRes.data.uploderImages[0] });
                    toast.success('Product thumbnail uploaded', { id: toastId });
                }
            }
        }
        const dataToast = toast.loading('Saving new reseller product...');
        const { productId } = location.state as { productId: string };
        confirmResellerProduct(newProductData, productId, config)
            .then(() => {
                toast.success('Reseller product updated', { id: dataToast });
                const user = getCurrentUser();
                let navigation = 'admin';
                if (user.role === 'accountManager') {
                    navigation = 'accountManager';
                }
                if (user.role === 'productProvider') {
                    navigation = 'productProvider';
                }
                navigate(`/${navigation}/resellerProducts`);
            })
            .catch((err) => {
                toast.error(`Sorry update failed! Error: ${err?.response?.data?.errors?.message}`, { id: dataToast });
            })
            .finally(() => {
                setIsLoading(false);
                setProgressCount(0);
            });
    };
    const [buttonAction, setButtonAction] = useState<FORM_SUBMIT>('CONFIRM');
    useEffect(() => {
        getProduct((location.state as { productId: string }).productId).then((result) => {
            setResellerProduct(result.data);

            setInitialData({
                name: result.data?.name || '',
                adCopies: result.data?.adCopies || [''],
                landingPages: result.data?.landingPages || [''],
                chineseName: result.data?.chineseName || '',
                quantity: result.data?.quantity || 0,
                weight: result.data?.weight || 0,
                width: result.data?.width || 0,
                height: result.data?.height || 0,
                length: result.data?.length || 0,
                declarationAmount: result.data?.declarationAmount || 0,
                resellerQuotation: result.data?.quotation || [],
                canBeOrdered: result.data?.canBeOrdered || false,
                notificationType: result.data?.notificationType || [], // from stock data
                categories: result.data?.categories || [],
                quantityLimit: result.data?.quantityLimit || 0, // from stock data
                description: result.data?.description || '',
            });
            setCanBeOrdered(result.data?.canBeOrdered);
            setAllImages([
                ...result.data.images.map((url: string) => ({
                    name: url.substring(url.indexOf('.com/') + 5), // selects 'imgName' from 'https://domain.com/imgName'
                    url,
                })),
            ]);
            setUrlImage(result.data.thumbnail);
            setResellerVideos([...result.data.videos.map((url: string) => url)]);
            setAllVideos([
                ...result.data.videos.map((url: string) => ({
                    name: url.substring(url.indexOf('.com/') + 5), // selects 'imgName' from 'https://domain.com/imgName'
                    url,
                })),
            ]);
        });
    }, []);

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={initialData}
                onSubmit={async (values) => {
                    setFormLoader(true);
                    switch (buttonAction) {
                        case 'CONFIRM':
                            handleConfirmation(values);
                            break;
                        case 'DRAFT':
                            handleUpdate(values);
                            break;
                        default:
                    }

                    // onSubmitProps.resetForm();
                }}
                validationSchema={buttonAction === 'CONFIRM' ? addResellerSchema : draftResellerSchema}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit} placeholder="">
                        <GeneralInformations
                            formik={formik}
                            setThumbnail={setThumbnail}
                            thumbnail={thumbnail}
                            urlImage={urlImage}
                            setUrlImage={setUrlImage}
                        />
                        <div className="bg-white  rounded-lg shadow p-4 dark:bg-gray-800 mb-2">
                            <div className="grid grid-cols-6 gap-4 ">
                                <div>
                                    <div className="mb-1 mt-1 block flex">
                                        <Label
                                            htmlFor="quantity-input"
                                            className="block text-xs font-medium text-gray-900 dark:text-white"
                                            value="Total quantity "
                                        />
                                        <span className="text-red-600 "> *</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="mb-1 mt-1 block flex">
                                        <Label
                                            htmlFor="declarationAmount-input"
                                            className="block text-xs font-medium text-gray-900 dark:text-white"
                                        >
                                            Unit Declaration amount(USD) <span className="text-red-600 "> *</span>
                                        </Label>
                                    </div>
                                </div>
                                <div>
                                    <div className="mb-1 mt-1 block flex">
                                        <Label
                                            htmlFor="weight-input"
                                            className="block text-xs font-medium text-gray-900 dark:text-white"
                                            value="Unit Weight (Kg)"
                                        />
                                        <span className="text-red-600 "> *</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="mb-1 mt-1 block flex">
                                        <Label
                                            htmlFor="width-input"
                                            className="block text-xs font-medium text-gray-900 dark:text-white"
                                            value="Unit Width (cm)"
                                        />
                                        <span className="text-red-600 "> *</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="mb-1 mt-1 block flex">
                                        <Label
                                            htmlFor="height-input"
                                            className="block text-xs font-medium text-gray-900 dark:text-white"
                                            value="Unit Height (cm)"
                                        />
                                        <span className="text-red-600 "> *</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="mb-1 mt-1 block flex">
                                        <Label
                                            htmlFor="length-input"
                                            className="block text-xs font-medium text-gray-900 dark:text-white"
                                            value="Unit Length (cm)"
                                        />
                                        <span className="text-red-600 "> *</span>
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-6 gap-4">
                                <div>
                                    <TextInput
                                        type="text"
                                        id="quantity-input"
                                        aria-describedby="helper-text-explanation"
                                        placeholder="Total quantity"
                                        {...formik.getFieldProps('quantity')}
                                        name="quantity"
                                    />
                                    <span>
                                        <FormError name="quantity" component="div" />
                                    </span>
                                </div>
                                <div>
                                    <TextInput
                                        type="text"
                                        id="declarationAmount-input"
                                        placeholder="Declaration amount"
                                        {...formik.getFieldProps('declarationAmount')}
                                        name="declarationAmount"
                                        addon="USD"
                                    />
                                    <span>
                                        <FormError name="declarationAmount" component="div" />
                                    </span>
                                </div>
                                <div>
                                    <TextInput
                                        type="text"
                                        id="weight-input"
                                        aria-describedby="helper-text-explanation"
                                        placeholder="weight"
                                        {...formik.getFieldProps('weight')}
                                        name="weight"
                                        addon="Kg"
                                    />
                                    <span>
                                        <FormError name="weight" component="div" />
                                    </span>
                                </div>
                                <div>
                                    <TextInput
                                        type="text"
                                        id="width-input"
                                        aria-describedby="helper-text-explanation"
                                        placeholder="Width"
                                        {...formik.getFieldProps('width')}
                                        name="width"
                                        addon="cm"
                                    />
                                    <span>
                                        <FormError name="width" component="div" />
                                    </span>
                                </div>
                                <div>
                                    <TextInput
                                        type="text"
                                        id="height-input"
                                        aria-describedby="helper-text-explanation"
                                        placeholder="Height"
                                        {...formik.getFieldProps('height')}
                                        name="height"
                                        addon="cm"
                                    />
                                    <span>
                                        <FormError name="height" component="div" />
                                    </span>
                                </div>
                                <div>
                                    <TextInput
                                        type="text"
                                        id="length-input"
                                        aria-describedby="helper-text-explanation"
                                        placeholder="Length"
                                        {...formik.getFieldProps('length')}
                                        name="length"
                                        addon="cm"
                                    />
                                    <span>
                                        <FormError name="length" component="div" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <QuotationForm formik={formik} edit isDraft={resellerProduct?.status === 11} />
                        <div className=" gap-4 bg-white rounded-lg shadow p-4 dark:bg-gray-800 mb-2">
                            <div className="flex items-center gap-2">
                                <Label className="p-2">
                                    Would you like your users to place orders for this product?{' '}
                                    <span className="text-red-600 "> *</span>
                                </Label>
                                <fieldset className="flex max-w-md  gap-4 p-2">
                                    <div className="flex items-center gap-2">
                                        <Radio
                                            id="yes-radio"
                                            checked={canBeOrdered}
                                            name="canBeOrdered"
                                            onChange={() => {
                                                setCanBeOrdered(true);
                                            }}
                                        />
                                        <Label htmlFor="yes-radio">Yes</Label>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <Radio
                                            id="no-radio"
                                            checked={!canBeOrdered}
                                            name="canBeOrdered"
                                            onChange={() => {
                                                setCanBeOrdered(false);
                                            }}
                                        />
                                        <Label htmlFor="no-radio">No</Label>
                                    </div>
                                </fieldset>
                            </div>
                            <fieldset className="grid grid-cols-1 items-center gap-2 p-2">
                                <div className="w-32">
                                    <div className="mb-2 mt-1 block flex ">
                                        <Label
                                            htmlFor="quantityLimit-input"
                                            className="block text-sm font-medium text-gray-900 dark:text-white"
                                            value="Quantity Limit"
                                        />
                                        <span className="text-red-600 "> *</span>
                                    </div>
                                    <div className="mb-2 mt-1 block flex ">
                                        {/* <span className="text-red-600 ">
                                            <FaCircleInfo />
                                        </span> */}
                                        <Label
                                            htmlFor="quantityLimit-input"
                                            className="block text-sm font-medium text-gray-600 dark:text-white"
                                            value="Nb :"
                                        />
                                    </div>
                                    <TextInput
                                        type="text"
                                        sizing="sm"
                                        id="quantityLimit-input"
                                        aria-describedby="helper-text-explanation"
                                        placeholder="Quantity Limit"
                                        {...formik.getFieldProps('quantityLimit')}
                                        name="quantityLimit"
                                    />
                                    <span>
                                        <FormError name="quantityLimit" component="div" />
                                    </span>
                                </div>
                                <Label>Notify users of stock quantity limit</Label>
                                <div className="flex gap-4">
                                    <div className="flex items-center mb-4">
                                        <input
                                            id="WhatsApp-checkbox"
                                            type="checkbox"
                                            disabled
                                            {...formik.getFieldProps(`notificationType`)}
                                            value="Whatsapp"
                                            checked={formik.values.notificationType.indexOf('Whatsapp') !== -1}
                                            name="notificationType"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <Label
                                            htmlFor="WhatsApp-checkbox"
                                            className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Whats&apos;App
                                        </Label>
                                    </div>
                                    <div className="flex items-center mb-4">
                                        <input
                                            id="Email-checkbox"
                                            type="checkbox"
                                            {...formik.getFieldProps(`notificationType`)}
                                            checked={formik.values.notificationType.indexOf('Email') !== -1}
                                            value="Email"
                                            name="notificationType"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <Label
                                            htmlFor="Email-checkbox"
                                            className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Email
                                        </Label>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div className=" gap-4 bg-white rounded-lg shadow p-4 dark:bg-gray-800 mb-2">
                            <h5 className="text-base font-semibold leading-7 text-gray-900">Description</h5>
                            <Textarea
                                placeholder="Enter your text..."
                                rows={4}
                                {...formik.getFieldProps(`description`)}
                                name="description"
                                helperText={
                                    <span>
                                        <TextError name="description" component="div" />
                                    </span>
                                }
                            />
                        </div>
                        <div className=" gap-4 bg-white rounded-lg shadow p-4 dark:bg-gray-800 mb-2">
                            <h5 className="text-base font-semibold leading-7 text-gray-900">Ad Copies</h5>
                            <FieldArray
                                name="adCopies"
                                render={(arrayHelpers) => (
                                    <div>
                                        {formik.values.adCopies.map((descrip, index) => {
                                            return (
                                                <div className="relative">
                                                    <div className="absolute right-2 p-2">
                                                        {' '}
                                                        <IoMdRemoveCircle
                                                            className="h-6 w-6 absolute right-2"
                                                            color="red"
                                                            role="button"
                                                            onClick={() => arrayHelpers.remove(index)}
                                                        />
                                                    </div>
                                                    <Textarea
                                                        placeholder="..."
                                                        rows={4}
                                                        {...formik.getFieldProps(`adCopies[${index}]`)}
                                                        name={`adCopies[${index}]`}
                                                        helperText={
                                                            <span>
                                                                <TextError
                                                                    name={`adCopies[${index}]`}
                                                                    component="div"
                                                                />
                                                            </span>
                                                        }
                                                    />
                                                </div>
                                            );
                                        })}
                                        <button
                                            onClick={() => arrayHelpers.push('')}
                                            type="button"
                                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                        >
                                            <FaPlus className="w-4 h-4" />
                                        </button>
                                    </div>
                                )}
                            />
                            <span>
                                <FormError name="adCopies" component="div" />
                            </span>
                        </div>
                        <div className=" gap-4 bg-white rounded-lg shadow p-4 dark:bg-gray-800 mb-2">
                            <h5 className="text-base font-semibold leading-7 text-gray-900">Landing pages section</h5>
                            <FieldArray
                                name="landingPages"
                                render={(arrayHelpers) => (
                                    <div>
                                        {formik.values.landingPages.map((lp, index) => {
                                            return (
                                                <div className="relative pb-2">
                                                    <div className="absolute right-2 p-2">
                                                        {' '}
                                                        <IoMdRemoveCircle
                                                            className="h-6 w-6 absolute right-2"
                                                            color="red"
                                                            role="button"
                                                            onClick={() => arrayHelpers.remove(index)}
                                                        />
                                                    </div>
                                                    <Textarea
                                                        placeholder="Enter your text..."
                                                        rows={1}
                                                        {...formik.getFieldProps(`landingPages[${index}]`)}
                                                        name={`landingPages[${index}]`}
                                                    />
                                                </div>
                                            );
                                        })}
                                        <button
                                            onClick={() => arrayHelpers.push('')}
                                            type="button"
                                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                        >
                                            <FaPlus className="w-4 h-4" />
                                        </button>
                                    </div>
                                )}
                            />
                        </div>
                        <MediaUploader
                            allImages={allImages}
                            allVideos={allVideos}
                            resellerVideos={resellerVideos}
                            setAllImages={setAllImages}
                            setAllVideos={setAllVideos}
                            setResellerVideos={setResellerVideos}
                        />
                        {isLoading && <Progress progress={progressCount} size="sm" className="mb-2" />}
                        <div className="flex gap-2 justify-end py-2 px-2 items-center rounded-b-lg  bg-background-secondary">
                            <div className="w-1/6">
                                <button
                                    onClick={() => {
                                        formik.resetForm();
                                        formik.setTouched({}, false);
                                        formik.setErrors({});
                                        clearMediaFields();
                                    }}
                                    disabled={formik.isSubmitting}
                                    type="submit"
                                    className="text-gray-800 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 rounded-full  focus:outline-none focus:ring-blue-300 font-medium  text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
                                >
                                    {formLoader && (
                                        <svg
                                            aria-hidden="true"
                                            role="status"
                                            className="inline w-5 h-5 me-1 text-white animate-spin"
                                            viewBox="0 0 100 101"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#5e6070"
                                            />
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    )}
                                    Cancel
                                </button>
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    disabled={formik.isSubmitting}
                                    onClick={() => {
                                        setButtonAction('CONFIRM');
                                    }}
                                    className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                >
                                    {formLoader && (
                                        <svg
                                            aria-hidden="true"
                                            role="status"
                                            className="inline w-5 h-5 me-1 text-white animate-spin"
                                            viewBox="0 0 100 101"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#5e6070"
                                            />
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    )}
                                    <span>Confirm</span>
                                </button>
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    disabled={formik.isSubmitting}
                                    onClick={() => {
                                        setButtonAction('DRAFT');
                                    }}
                                    className="text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:outline-none focus:ring-4 focus:ring-[#F7BE38]/50 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-[#F7BE38] dark:hover:bg-[#F7BE38]/90 dark:focus:ring-[#F7BE38]/50"
                                >
                                    {formLoader && (
                                        <svg
                                            aria-hidden="true"
                                            role="status"
                                            className="inline w-5 h-5 me-1 text-white animate-spin"
                                            viewBox="0 0 100 101"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#5e6070"
                                            />
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    )}
                                    <span>Update</span>
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
export default ResellerProductDetails;
