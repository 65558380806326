import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from 'utils/helpers/auth';
import CustomBtn from 'components/customButton/customBtn';
import './notFound.css';

const NotFound = () => {
    const navigate = useNavigate();

    const redirection = () => {
        if (!getCurrentUser()) {
            navigate('/auth/login');
        } else {
            navigate(`${getCurrentUser().role}`);
        }
    };
    return (
        <>
            <div className="bg-[#2F3242] w-screen h-screen flex items-center justify-center">
                <svg
                    width="500px"
                    height="500px"
                    viewBox="0 0 837 1045"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g
                        transform="translate(150,650.000000) scale(0.500000,-0.500000)"
                        stroke="none"
                        id="Page-1"
                        strokeWidth="1"
                        fillRule="evenodd"
                    >
                        <path
                            id="Polygon-1"
                            fill="#007FB2"
                            d="M205 928 c-3 -7 -4 -204 -3 -438 l3 -425 295 0 295 0 0 85 0 85 -197 3 -198 2 -2 348 -3 347 -93 3 c-68 2 -94 -1 -97 -10z m157 -375 l3 -348 198 -3 197 -2 0 -50 0 -50 -260 0 -260 0 0 400 0 400 60 0 60 0 2 -347z"
                        />
                    </g>
                    <g
                        transform="translate(400,400.000000) scale(0.350000,-0.350000) rotate(-15)"
                        stroke="none"
                        id="Page-1"
                        strokeWidth="1"
                        fillRule="evenodd"
                    >
                        <path
                            id="Polygon-2"
                            fill="#EF4A5B"
                            d="M383 945 c-77 -21 -130 -51 -179 -102 -165 -170 -165 -523 1 -688 152 -152 438 -153 588 -2 135 136 168 408 72 597 -83 164 -288 247 -482 195z m291 -64 c133 -65 206 -201 206 -381 0 -185 -77 -322 -219 -389 -49 -23 -68 -26 -161 -26 -93 0 -112 3 -161 26 -142 67 -219 204 -219 389 0 166 66 301 178 367 70 41 126 53 225 49 73 -3 98 -9 151 -35z"
                        />
                        <path
                            id="Polygon-2"
                            fill="#EF4A5B"
                            d="M419 807 c-123 -46 -188 -171 -176 -337 9 -131 49 -207 135 -257 40 -24 58 -28 123 -28 67 0 81 4 125 30 93 58 141 174 131 315 -10 133 -56 219 -140 262 -52 26 -149 33 -198 15z m166 -46 c46 -20 87 -65 112 -120 23 -51 23 -231 0 -282 -84 -185 -315 -185 -393 0 -42 99 -23 271 39 346 58 71 159 94 242 56z"
                        />
                    </g>
                    <g
                        transform="translate(-50.000000,500.000000) scale(0.250000,-0.250000) rotate(-5)"
                        fill="#F2773F"
                        stroke="none"
                    >
                        <path
                            id="Polygon-3"
                            d="M406 950 c-63 -16 -155 -71 -193 -114 -127 -148 -150 -412 -52 -596 40 -75 92 -123 178 -164 62 -29 73 -31 176 -31 98 0 116 3 167 27 79 37 121 71 157 127 42 65 62 152 59 253 l-3 83 -185 0 -185 0 0 -75 0 -75 89 -3 88 -3 -6 -32 c-13 -68 -68 -115 -143 -124 -104 -12 -183 33 -227 127 -17 36 -21 65 -21 150 0 95 3 110 28 163 41 83 88 112 182 112 86 0 114 -15 158 -86 l30 -50 86 3 86 3 -3 34 c-4 48 -58 145 -105 187 -65 59 -136 86 -236 90 -47 2 -103 -1 -125 -6z m249 -59 c77 -34 147 -112 170 -188 6 -22 4 -23 -48 -23 -55 0 -55 0 -81 44 -34 58 -82 87 -155 93 -114 11 -203 -43 -253 -152 -30 -66 -33 -232 -5 -310 46 -133 178 -202 313 -165 87 25 152 115 142 198 -3 27 -4 27 -90 30 l-88 3 0 39 0 40 151 0 152 0 -5 -93 c-8 -151 -62 -239 -180 -293 -55 -25 -75 -28 -158 -29 -122 0 -182 20 -254 85 -162 149 -170 476 -16 643 68 75 161 109 280 104 50 -3 92 -11 125 -26z"
                        />
                    </g>
                    <g
                        transform="translate(0.000000,800.000000) scale(0.200000,-0.200000) rotate(10)"
                        fill="#795D9C"
                        stroke="none"
                    >
                        <path
                            id="Polygon-4"
                            d="M405 928 c-3 -7 -4 -209 -3 -448 l3 -435 95 0 95 0 0 445 0 445 -93 3 c-68 2 -94 -1 -97 -10z m155 -438 l0 -410 -60 0 -60 0 0 410 0 410 60 0 60 0 0 -410z"
                        />
                    </g>
                    <g
                        transform="translate(150.000000,1000.000000) scale(0.300000,-0.300000) rotate(-10)"
                        fill="#36B455"
                        stroke="none"
                    >
                        <path
                            id="Polygon-5"
                            d="M382 944 c-71 -19 -106 -38 -151 -83 -86 -86 -94 -234 -19 -326 41 -49 109 -81 236 -110 59 -14 121 -31 137 -37 81 -31 98 -87 42 -143 -76 -76 -245 -50 -286 43 l-15 33 -90 -3 c-85 -3 -91 -4 -94 -25 -5 -36 38 -120 82 -161 70 -64 140 -86 276 -86 97 0 124 4 174 23 138 55 212 187 177 317 -28 106 -104 159 -280 200 -143 32 -199 57 -207 91 -17 68 38 114 136 114 74 1 118 -22 143 -74 l19 -37 84 0 c96 0 104 6 86 68 -18 58 -60 116 -111 150 -85 58 -225 77 -339 46z m217 -35 c56 -12 132 -57 155 -91 8 -13 22 -40 31 -60 l15 -38 -58 0 c-57 0 -59 1 -75 35 -23 49 -81 76 -162 76 -105 0 -172 -43 -181 -118 -10 -82 39 -120 204 -158 184 -42 237 -72 273 -150 32 -70 22 -156 -27 -222 -14 -18 -53 -46 -91 -65 -65 -32 -73 -33 -183 -33 -110 0 -118 1 -182 33 -68 33 -97 63 -123 126 l-15 36 59 0 c56 0 60 -2 80 -33 11 -18 39 -44 63 -57 58 -34 184 -36 238 -4 70 41 97 112 66 173 -25 48 -78 72 -236 106 -179 39 -250 104 -250 228 0 159 189 261 399 216z"
                        />
                    </g>
                    <g
                        transform="translate(400.000000,800.000000) scale(0.35000,-0.350000) rotate(-40)"
                        fill="#dbd223"
                        stroke="none"
                    >
                        <path
                            id="Polygon-2"
                            d="M144 927 c-3 -8 -4 -43 -2 -78 l3 -64 127 -3 128 -3 2 -357 3 -357 95 0 95 0 3 357 2 357 128 3 127 3 0 75 0 75 -353 3 c-291 2 -353 0 -358 -11z m676 -67 l0 -39 -128 -3 -127 -3 -3 -357 -2 -358 -60 0 -60 0 -2 357 -3 358 -127 3 -128 3 0 39 0 40 320 0 320 0 0 -40z"
                        />
                    </g>
                </svg>

                <div className="ml-7">
                    <p className="text-8xl font-light text-center">404</p>
                    <p className="text-5xl font-light">Page not found</p>
                    <div className="mt-7">
                        <CustomBtn
                            variant="primary"
                            size="lg"
                            onClick={() => {
                                redirection();
                            }}
                            className="w-full"
                        >
                            Go back home
                        </CustomBtn>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NotFound;
