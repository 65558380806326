/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-fragments */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import CustomError from 'components/customError/CustomError';
import toast from 'react-hot-toast';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Label, TextInput } from 'flowbite-react';
import CustomBtn from 'components/customButton/customBtn';
import { getAllCallCenters, updateCallCenter } from 'services/admin/callCenter';
import { ICallCenterActions } from '../callCenter.types';

import '../../../utils/helpers/phoneInput.css';

interface FormValues {
    name: string;
    location: string;
}

const CallCenterInfo: React.FC<ICallCenterActions> = ({ callCenter, setRows, currentPage, currentFilter }) => {
    const [isLoading, setIsLoading] = useState(false);

    const info = (values: any) => {
        setIsLoading(true);
        updateCallCenter(callCenter._id, values)
            .then((res) => {
                toast.success(res.data.message);
                setIsLoading(false);
            })
            .then(() => {
                getAllCallCenters({ page: currentPage, ...currentFilter }).then((res) => {
                    setRows(res.data.docs);
                });
            })
            .catch((err) => {
                setIsLoading(false);
                toast.error(err.response.data.errors.message);
            });
    };

    return (
        <Formik
            initialValues={{
                name: callCenter.name,
                location: callCenter.location,
            }}
            onSubmit={(values: FormValues, onSubmitProps) => {
                info(values);
                onSubmitProps.setSubmitting(false);
            }}
        >
            {(formik) => (
                <Form onSubmit={formik.handleSubmit} placeholder="">
                    <div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="name"
                                    className="dark:text-white"
                                    color={formik.errors.name ? 'failure' : ''}
                                    value="Name"
                                />
                            </div>
                            <TextInput
                                {...formik.getFieldProps('name')}
                                name="name"
                                color={formik.errors.name ? 'failure' : 'gray'}
                                type="text"
                                helperText={
                                    <React.Fragment>
                                        <CustomError name="name" component="div" />
                                    </React.Fragment>
                                }
                            />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="location"
                                    className="dark:text-white"
                                    color={formik.errors.location ? 'failure' : ''}
                                    value="Location"
                                />
                            </div>
                            <TextInput
                                {...formik.getFieldProps('location')}
                                name="location"
                                color={formik.errors.location ? 'failure' : 'gray'}
                                type="text"
                                helperText={
                                    <React.Fragment>
                                        <CustomError name="location" component="div" />
                                    </React.Fragment>
                                }
                            />
                        </div>
                    </div>

                    <div className="flex justify-end">
                        <CustomBtn type="submit" variant="primary" isProcessing={isLoading} disabled={isLoading}>
                            Save Changes
                        </CustomBtn>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default CallCenterInfo;
