/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from 'react';
import { Ipagination } from 'services/admin/product';
import { PiBarcodeDuotone } from 'react-icons/pi';
import UseDocumentTitle from 'utils/dynamicTitle/dynamicTitle';
import { v4 as uuidv4 } from 'uuid';
import Image from 'assets/no-image.png';

import toast from 'react-hot-toast';
import DataTable from 'components/tables/tables';
import { getStockShipments, IStockShipment, IStockShipmentFiler } from 'services/admin/stockShipment';
import { Imeta } from 'pages/reseller/reseller.types';
import { vnlinWarehouses } from 'utils/helpers/warehouses';
import { Avatar, Button, Dropdown, Select, TextInput } from 'flowbite-react';
import { FaRegCopy } from 'react-icons/fa6';
import { TableColDef } from 'components/tables/tables.types';
import { TbCapture } from 'react-icons/tb';
import { PickUpDrawer } from 'components/pickUpDrawer/pickUpDrawer';
import { AiOutlineSearch } from 'react-icons/ai';
import {
    BarCode,
    copyToClickboard,
    downloadLabels,
    handleDownloadBarCode,
    LABELSIZES,
    stockShipmentsStatus,
} from './utils';
import ShipmentStatus from './shipmentStatus';
import ShipmentHistory from './shipmentHistory';

const StockShipments = () => {
    UseDocumentTitle('StockShipments');
    const barcodePdfRef = useRef(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [stockShipments, setStockShipments] = useState<IStockShipment[]>([]);
    const [selectedShipments, setSelectedShipments] = useState<IStockShipment[]>([]);
    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 20,
    });
    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });
    const [filter, setFilter] = useState<IStockShipmentFiler>({});
    const [openModal, setOpenModal] = useState<boolean>(false);

    useEffect(() => {
        const toastId = toast.loading('Loading stock shipments data...');
        setIsLoading(true);
        getStockShipments({ ...pagination, ...filter })
            .then((res) => {
                toast.dismiss(toastId);
                setStockShipments(res.data.docs);
                setMeta({
                    hasNextPage: res.data.hasNextPage,
                    hasPrevPage: res.data.hasPrevPage,
                    limit: res.data.limit,
                    nextPage: res.data.nextPage,
                    page: res.data.page,
                    pagingCounter: res.data.pagingCounter,
                    prevPage: res.data.prevPage,
                    totalDocs: res.data.totalDocs,
                    totalPages: res.data.totalPages,
                });

                setIsLoading(false);
                if (!res.data.docs?.length) {
                    toast('No results found for your search criteria', {
                        icon: '👏',
                        style: {
                            borderRadius: '10px',
                        },
                    });
                }
            })
            .catch(() => {
                setIsLoading(false);
                toast.error('Something went wrong', { id: toastId });
            });
    }, [filter, pagination]);

    const refetchShipments = () => {
        const toastId = toast.loading('Loading stock shipments data...');
        setIsLoading(true);
        getStockShipments({ ...pagination, ...filter })
            .then((res) => {
                toast.dismiss(toastId);
                setStockShipments(res.data.docs);
                setMeta({
                    hasNextPage: res.data.hasNextPage,
                    hasPrevPage: res.data.hasPrevPage,
                    limit: res.data.limit,
                    nextPage: res.data.nextPage,
                    page: res.data.page,
                    pagingCounter: res.data.pagingCounter,
                    prevPage: res.data.prevPage,
                    totalDocs: res.data.totalDocs,
                    totalPages: res.data.totalPages,
                });
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                toast.error('Something went wrong', { id: toastId });
            });
    };

    const handlePaginationChange = (value: { [key: string]: any }) => {
        setPagination((prevPagination) => {
            const data = { ...prevPagination, ...value };
            if (value.limit) {
                data.page = 1;
            }
            return data;
        });
        setMeta((prev) => ({ ...prev, page: -1 }));
    };

    const handleFilter = (value: any, element: string) => {
        if (value !== '') {
            setFilter({
                ...filter,
                [element]: value,
            });
        } else {
            setFilter({ ...filter, [element]: '' });
        }
    };

    const handleOpenModal = () => {
        if (
            !selectedShipments?.every((shipment: IStockShipment) => shipment.status === 1) ||
            !selectedShipments?.every(
                (shipment: IStockShipment) => shipment?.warehouse === selectedShipments?.[0]?.warehouse,
            )
        ) {
            toast.error('Selected products cannot be shipped together');
        } else {
            setOpenModal(true);
        }
    };

    const stockShipmentsColumns: TableColDef<IStockShipment>[] = [
        {
            field: 'product.name',
            headerName: 'Product',
            fieldType: 'render',
            renderCell: (params: IStockShipment) => {
                const isThere = (
                    params.product.design?.length ? params.product.design : params?.parentProduct?.design || []
                )?.filter(
                    (prodImg: any) =>
                        prodImg.endsWith('.jpg') ||
                        prodImg.endsWith('.png') ||
                        prodImg.endsWith('.jpeg') ||
                        prodImg.endsWith('.webp'),
                );

                return (
                    <>
                        <div className="flex items-center gap-2">
                            {isThere?.length > 0 ? <Avatar img={isThere[0]} /> : <Avatar img={Image} />}
                            <p className="font-bold">{`${
                                params?.parentProduct?.restockFor?.name ||
                                params?.parentProduct?.name ||
                                params?.product?.name ||
                                ''
                            } `}</p>
                        </div>
                    </>
                );
            },
        },

        {
            field: 'product',
            headerName: 'Variant / SKU',
            fieldType: 'render',
            renderCell: (params: IStockShipment) =>
                params.product.itemKind === 'variant' ? (
                    <div className="flex flex-col ">
                        <div>
                            <p className="font-bold text-gray-700 dark:text-gray-400">{params?.product?.name}</p>
                        </div>
                        <div>
                            <div className="flex items-center gap-2">
                                <p className="font-normal dark:font-bold text-gray-400 dark:text-gray-500">
                                    {params?.product?.globalSKU}
                                </p>
                                <Button
                                    size="l"
                                    color="white"
                                    title="Copy tracking code to clipboard"
                                    onClick={() => {
                                        copyToClickboard(params?.product?.globalSKU || '', 'Sku');
                                    }}
                                >
                                    <FaRegCopy className="w-4 h-4" />
                                </Button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="flex  items-center gap-2">
                            <p className="font-bold  ">{params?.product?.globalSKU}</p>
                            <Button
                                size="l"
                                color="white"
                                title="Copy tracking code to clipboard"
                                onClick={() => {
                                    copyToClickboard(params?.product?.globalSKU || '', 'Sku');
                                }}
                            >
                                <FaRegCopy className="w-4 h-4" />
                            </Button>
                        </div>
                    </div>
                ),
        },

        {
            field: 'warehouse',
            headerName: 'Warehouse',
            fieldType: 'render',
            renderCell: (params: IStockShipment) => (
                <div className="flex flex-col">
                    <p className="font-bold">{`${vnlinWarehouses[params.warehouse as '64' | '90' | '91' | '99']
                        ?.name}`}</p>
                    <span className="opacity-70 text-xs">({params.country})</span>
                </div>
            ),
        },

        {
            field: 'quantity',
            headerName: 'Qty',
            fieldType: 'render',
            renderCell: (params: IStockShipment) => <p className="font-bold">{`${params?.quantity || ''} `}</p>,
        },

        {
            field: 'lgstTrackingCode',
            headerName: 'Logistio Code',
            fieldType: 'render',
            renderCell: (params: IStockShipment) =>
                params?.lgstTrackingCode ? (
                    <div>
                        <div className="flex  items-center gap-2">
                            <p className="font-bold  ">{params?.lgstTrackingCode}</p>
                            <Button
                                size="l"
                                color="white"
                                title="Copy Logistio code to clipboard"
                                onClick={() => {
                                    copyToClickboard(params?.lgstTrackingCode || '', 'Logistio code');
                                }}
                            >
                                <FaRegCopy className="w-4 h-4" />
                            </Button>
                        </div>
                    </div>
                ) : (
                    <p>-</p>
                ),
        },

        {
            field: 'trackingCode',
            headerName: 'Tracking Code',
            fieldType: 'render',
            renderCell: (params: IStockShipment) =>
                params?.trackingCode ? (
                    <div>
                        <div className="flex  items-center gap-2">
                            <p className="font-bold  ">{params?.trackingCode}</p>
                            <Button
                                size="l"
                                color="white"
                                title="Copy tracking code to clipboard"
                                onClick={() => {
                                    copyToClickboard(params?.trackingCode || '', 'Tracking code');
                                }}
                            >
                                <FaRegCopy className="w-4 h-4" />
                            </Button>
                        </div>
                    </div>
                ) : (
                    <p>-</p>
                ),
        },

        {
            field: 'status',
            headerName: 'Status',
            fieldType: 'render',
            renderCell: (params: IStockShipment) => (
                <>
                    <ShipmentStatus shipmentData={params} key={params._id} refetchShipments={refetchShipments} />
                </>
            ),
        },

        {
            field: '',
            headerName: '',
            fieldType: 'render',
            renderCell: (params: IStockShipment) => (
                <div className="flex justify-around items-center">
                    <div>
                        <Dropdown
                            label={<TbCapture size="2em" />}
                            dismissOnClick
                            color="white"
                            arrowIcon={false}
                            placement="right-start"
                            disabled={!params.trackingCode}
                            title={
                                !params.trackingCode
                                    ? 'Shipment does not have a tracking code'
                                    : 'Download stock labels as pdf'
                            }
                        >
                            <Dropdown.Header>
                                <span className="block text-sm">Label size</span>
                            </Dropdown.Header>
                            {LABELSIZES.map((size) => (
                                <Dropdown.Item
                                    key={size}
                                    onClick={() => {
                                        downloadLabels(size, params);
                                    }}
                                >
                                    {size}
                                </Dropdown.Item>
                            ))}
                        </Dropdown>
                    </div>

                    <div>
                        <Button
                            color="white"
                            title="download lable"
                            onClick={() => handleDownloadBarCode(params.product.barCode, barcodePdfRef)}
                        >
                            <PiBarcodeDuotone size="2em" />
                        </Button>
                        <div className="fixed left-[-1000rem]">
                            <BarCode barCode={params.product.barCode} elRef={barcodePdfRef} />
                        </div>
                    </div>

                    <div>
                        <ShipmentHistory data={params} />
                    </div>
                </div>
            ),
        },
    ];

    return (
        <>
            <section className="flex flex-col items-center p-4 w-full">
                <div className="flex w-full justify-between items-center py-4">
                    <div>
                        <h4>Stock Shipments</h4>
                    </div>
                    <div>
                        <Button disabled={!selectedShipments.length} onClick={handleOpenModal}>
                            Ready for pickup
                        </Button>
                    </div>
                </div>

                <div className="p-2 w-full flex items-center justify-between ">
                    <div className="flex items-center gap-2">
                        <div className="flex gap-1 ml-1">
                            <Select
                                sizing="md"
                                id="countrySelect"
                                required
                                value={filter.warehouse?.toString()}
                                onChange={(e) => {
                                    handleFilter(e.target.value, 'warehouse');
                                    setPagination({
                                        page: 1,
                                        limit: 20,
                                    });
                                }}
                            >
                                <option value="">All Warehouses</option>
                                {Object.values(vnlinWarehouses).map((warehouse) => {
                                    return (
                                        <option key={warehouse.id} value={warehouse.id}>
                                            {warehouse.name}
                                        </option>
                                    );
                                })}
                            </Select>
                        </div>
                        <div className="flex gap-1 ml-1">
                            <Select
                                sizing="md"
                                id="countrySelect"
                                required
                                value={filter.status?.toString()}
                                onChange={(e) => {
                                    handleFilter(e.target.value, 'status');
                                    setPagination({
                                        page: 1,
                                        limit: 20,
                                    });
                                }}
                            >
                                <option value="">All Status</option>
                                {stockShipmentsStatus.map((status) => {
                                    return (
                                        <option key={status.value} value={status.value}>
                                            {status.label}
                                        </option>
                                    );
                                })}
                            </Select>
                        </div>
                    </div>
                    <div>
                        <TextInput
                            icon={AiOutlineSearch}
                            style={{ width: '350px' }}
                            id="skuSearch"
                            placeholder="Search by SKU, tracking code or logistio code"
                            name="sku"
                            onChange={(e) => {
                                handleFilter(e.target.value, 'skuOrTrackingCode');
                                setPagination({
                                    page: 1,
                                    limit: 20,
                                });
                            }}
                        />
                    </div>
                </div>
            </section>
            <div>
                <DataTable
                    rows={stockShipments}
                    columns={stockShipmentsColumns}
                    setRows={setStockShipments}
                    pageState={{
                        isLoading,
                        total: meta.totalDocs,
                        pageSize: pagination.limit,
                        page: pagination.page,
                        count: meta.totalPages,
                    }}
                    setPageState={handlePaginationChange}
                    selectable
                    setRowsSelected={setSelectedShipments}
                    filterState={filter}
                />
            </div>
            {openModal && (
                <PickUpDrawer
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    key={uuidv4()}
                    shipmentsData={selectedShipments}
                    refetchShipments={refetchShipments}
                />
            )}
        </>
    );
};

export default StockShipments;
