import GeneralDrawer from 'components/generalDrawer/generalDrawer';
import React, { useState } from 'react';

const CallAgentDashboard = () => {
    const [openDrawer, setOpenDrawer] = useState(false);
    return (
        <>
            <div>callAgentDashboard</div>
            <button type="button" onClick={() => setOpenDrawer(!openDrawer)}>
                {' '}
                aze
            </button>
            <GeneralDrawer anchor="right" open={openDrawer} onClose={setOpenDrawer}>
                azeaze
            </GeneralDrawer>
        </>
    );
};

export default CallAgentDashboard;
