import axios from 'utils/axios';

export interface IUser {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    role: 'admin' | 'productProvider' | 'accountManager' | 'callAgent' | 'callCenterAdmin' | 'apiOnly';
    status: number;
    workingTimeStart?: string;
    workingTimeEnd?: string;
    profileImage?: string;
}

export const login = async (data: any) => {
    const log = await axios.post(`/auth/signin`, data);
    return log;
};

export const resetPassword = async (data: any) => {
    const resetPwd = await axios.post(`/auth/reset`, data);
    return resetPwd;
};
