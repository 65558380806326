import { IOrder, IOrderHistory } from 'services/admin/order';
import { IOrderTrace } from 'services/admin/orderTrace';

export interface IOrderActions {
    orderData: IOrder;
    setRows: React.Dispatch<React.SetStateAction<IOrder[]>>;
    currentPage: number;
    currentFilter?: Record<string, any>;
}
export interface IStatus {
    id: number;
    label: string;
}

export interface IStatusTimeline {
    history: IOrderHistory[];
    deliveryAttempts: IOrderTrace[];
}
export interface IOrderStatus {
    id: number;
    label: string;
}
export interface IOrderoptionFilter {
    value: string;
    label: string;
}
export interface HistoryProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    history: IStatusTimeline;
    order: IOrder;
}
export const orderStatusList: IOrderStatus[] = [
    { id: 1, label: 'New' },
    { id: 14, label: 'Invalid' },
    { id: 2, label: 'Canceled' },
    { id: 21, label: 'Wrong number' },
    { id: 3, label: 'Unreachable' },
    { id: 4, label: 'Confirmed' },
    { id: 43, label: 'Scheduled call' },
    { id: 41, label: 'Pending answer' },
    { id: 42, label: 'Question answered' },
    { id: 13, label: 'Expired' },
    // { id: 5, label: 'Waiting for shipment' },
    // { id: 6, label: 'Out for shipment' },
    // { id: 7, label: 'Shipped' },
    { id: 99, label: 'Check required' },
    { id: 8, label: 'Pick and pack' },
    { id: 81, label: 'Order received by carrier' },
    { id: 82, label: 'Arrive to headquarters warehouse' },
    { id: 83, label: 'Order transferred' },
    { id: 84, label: 'Arrival at Hub' },

    { id: 9, label: 'Out for delivery' },
    { id: 10, label: 'Delivery attempted' },
    { id: 101, label: 'Order returned to Hub' },
    { id: 102, label: 'Order returned to Warehouse' },
    { id: 103, label: 'New delivery request' },
    { id: 104, label: 'Wait to WMS' },
    { id: 105, label: 'Delivery scheduled' },

    { id: 11, label: 'Delivery failed' },
    { id: 111, label: 'Return to origin - Out' },
    { id: 112, label: 'Return to origin - In' },
    { id: 113, label: 'Transport management system to Warehouse' },
    { id: 114, label: 'Back to stock' },

    { id: 100, label: 'Hold - No stock available' },
    { id: 12, label: 'Delivered' },
];
export const orderTypes: IOrderoptionFilter[] = [
    { value: 'cod', label: 'COD Orders' },
    { value: 'prePaid', label: 'Prepaid Orders' },
];
export const callCenterOption: IOrderoptionFilter[] = [
    { value: 'withCC', label: 'With Call Center' },
    { value: 'noCC', label: 'Without Call Center' },
];
export interface IdateRange {
    startDate: string | null | Date;
    endDate: string | null | Date;
}
