/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable react/style-prop-object */
/* eslint-disable no-underscore-dangle */

import DataTable from 'components/tables/tables';
// import CustomModal from 'components/modal/Modal';
import AutoComplete from 'components/autocomplete/autoComplete';
import CustomBtn from 'components/customButton/customBtn';
import { Modal, Tabs, TabsRef, TextInput } from 'flowbite-react';
import { Iadmin } from 'pages/adminsList/admin.types';
import { ICallCenterAdmin } from 'pages/callCenterAdminList/callCenterAdmin.types';
import { ICallCenter } from 'pages/callCenterList/callCenter.types';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { AiOutlineSearch } from 'react-icons/ai';
import { BsClipboardCheckFill } from 'react-icons/bs';
import { FaUserCheck } from 'react-icons/fa';
import { MdOutlineShoppingCart } from 'react-icons/md';
import { getAllCallAgents, getCallAgent } from 'services/admin/callAgent';
import { getAllCallCenters } from 'services/admin/callCenter';
import { IOrder, assignCallAgent, assignOrdersToCallCenter, getOrders } from 'services/admin/order';
import { getCallAgentAdmin } from 'services/callAgentAdmin/callAgentAdminProfile';
import UseDocumentTitle from 'utils/dynamicTitle/dynamicTitle';
import { getCurrentUser } from 'utils/helpers/auth';
import { ORDER_PHASES, columns, orderStatusListForAdminCC, statusDisplay } from './ordersElements';

interface Ipagination {
    page: number;
    limit: number;
    type?: number;
}

interface Imeta {
    type: number;
    totalDocs: number;
    limit: number;
    totalPages: number;
    page: number;
    pagingCounter: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    prevPage: number | null;
    nextPage: number | null;
}

interface Ifilter {
    startDate?: string | number;
    endDate?: string | number;
    name?: string;
    zipCode?: number;
    customer?: string;
    country?: string;
    fulfillement?: string;
    goodsCost?: number;
    ShippingCost?: number;
    total?: number;
    status?: number;
    trackingCode?: string;
    callCenterAttempt?: number;
    store?: string | number;
    callAgent?: string;
    orderRefOrPhone?: string;
    withCC: true;
    callCenter?: string[];
    orderPhase?: string;
}

interface Ireset {
    status: boolean;
    callCenter: boolean;
    callAgent: boolean;
}

const OrderListCCA = () => {
    UseDocumentTitle('Orders');
    const tabsRef = useRef<TabsRef>(null);

    const [ordersList, setOrdersList] = useState<IOrder[]>([]);
    const [ordersSelected, setOrdersSelected] = useState<IOrder[]>([]);

    const [resetValue, setresetValue] = useState<Ireset>({ status: false, callCenter: false, callAgent: false });
    // Pagination States
    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 20,
    });
    const [filter, setFilter] = useState<Ifilter>({ withCC: true, orderPhase: ORDER_PHASES[0] });
    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });
    const [isLoading, setIsLoading] = useState(false);

    const [callAgent, setCallAgent] = useState<string | null>(null);
    const [callCenter, setCallCenter] = useState<string | null>(null);
    const [callAgents, setCallAgents] = useState<Iadmin[]>([]);
    const [callCenters, setCallCenters] = useState<ICallCenter[]>([]);
    const [availableCallCenters, setAvailableCallCenters] = useState<ICallCenter[]>([]);
    const [openAssignModal, setOpenAssignModal] = useState(false);
    const [openAssignCcModal, setOpenAssignCcModal] = useState(false);
    const [managedCallCenters, setManagedCallCenters] = useState<string[]>([]);
    const [callAgentAdmin, setCallAgentAdmin] = useState<ICallCenterAdmin>();
    useEffect(() => {
        // get managed call centers
        const currentUser = getCurrentUser();
        getCallAgent(currentUser._id).then((res) => {
            setManagedCallCenters(res.data?.callCenter || []);
        });
        getCallAgentAdmin(currentUser._id).then((res) => {
            setCallAgentAdmin(res.data);
        });
    }, []);

    const parseOrders = (queryOptions: Partial<Ipagination & Ifilter>) => {
        getOrders(queryOptions)
            .then(({ data }) => {
                setOrdersList(data.docs);
                setMeta({
                    // type: data.type,
                    hasNextPage: data.hasNextPage,
                    hasPrevPage: data.hasPrevPage,
                    limit: data.limit,
                    nextPage: data.nextPage,
                    page: data.page,
                    pagingCounter: data.pagingCounter,
                    prevPage: data.prevPage,
                    totalDocs: data.totalDocs,
                    totalPages: data.totalPages,
                });
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                toast.error('Something went wrong, please try again');
            });
    };

    useEffect(() => {
        if (callAgent) getAllCallAgents({ limit: 1000, email: callAgent }).then((res) => setCallAgents(res.data.docs));
        else getAllCallAgents({ limit: 1000 }).then((res) => setCallAgents(res.data.docs));
    }, [callAgent]);

    useEffect(() => {
        const callCenterFilter: {
            limit: number;
            status: number;
            name?: string;
        } = {
            limit: 1000,
            status: 1,
        };

        if (callCenter) {
            callCenterFilter.name = callCenter;
        }

        getAllCallCenters({ limit: 1000, status: 1 })
            .then((res) => setCallCenters(res.data.docs))
            .catch((e) => {
                toast.error(e?.response?.data?.errors?.message || 'Unable to get call centers');
            });
    }, [callCenter]);

    const [callAgentAssignLoader, setCallAgentAssignLoader] = useState(false);
    const [callCenterAssignLoader, setCallCenterAssignLoader] = useState(false);

    const filterAvailableCallCenters = () => {
        const assignedCallCenterIds = new Set(ordersSelected.map((order) => order?.callCenter?._id));
        const available = callCenters.filter(
            (cc) => !assignedCallCenterIds.has(cc._id) || assignedCallCenterIds.size > 1,
        );
        setAvailableCallCenters(available);
    };

    useEffect(() => {
        if (ordersSelected.length > 0) {
            filterAvailableCallCenters();
        }
    }, [ordersSelected, callCenters]);

    function assignOrderCallAgent() {
        setCallAgentAssignLoader(true);
        const orders = ordersSelected.map((order) => order?._id);

        assignCallAgent({ orders, callAgent })
            .then(() => {
                toast.success('Orders updated successfully');
                setOpenAssignModal(false);
                setCallAgentAssignLoader(false);
                parseOrders({ ...pagination, ...filter });
            })
            .catch((err) => {
                toast.error(err.response.data.errors.message);
                setOpenAssignModal(false);
                setCallAgentAssignLoader(false);
            });
    }

    function assignOrdersCallCenter() {
        setCallCenterAssignLoader(true);
        const ordersIds = ordersSelected.map((order) => order._id);

        if (!callCenter) {
            toast.error('No call center is selected');
            return;
        }
        assignOrdersToCallCenter({ ordersIds, callCenterId: callCenter })
            .then((res) => {
                const { data } = res;
                // collect failed assign
                const errs: string[] = [];
                if (data.failedOrders && Object.keys(data.failedOrders).length) {
                    Object.values(data.failedOrders).forEach((orderErrs) => {
                        errs.push(orderErrs.join(', '));
                    });
                }
                toast.success(
                    `${data.assignedOrdersIds.length} orders updated successfully. ${
                        errs.length ? `Failed to assign ${errs.length} orders. Errors: ${errs.join(', ')}` : ''
                    }`,
                );
                parseOrders({ ...pagination, ...filter });
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message || 'Something went wrong');
            })
            .finally(() => {
                setOpenAssignCcModal(false);
                setCallCenterAssignLoader(false);
            });
    }

    // Date Picker states
    // const [dateRange, setDateRange] = useState<DateRange | undefined>();

    // fetch orders on component mount & pagination or filter change
    useEffect(() => {
        /**
         * apply pagination & filter if filter options exists
         * else ignore filter options and only use pagination
         */
        const queryOptions = Object.values(filter)[0] ? { ...pagination, ...filter } : { ...pagination };
        /**
         * check if we are requesting an active page (already fetched and is displayed)
         */
        const fetchedAndActive = meta.page === pagination.page;
        /**
         * ignore request if page is available
         */
        if (!fetchedAndActive) {
            setIsLoading(true);
            parseOrders(queryOptions);
        }
    }, [filter, pagination]);

    const handleFilterChange = (newFilter: React.SetStateAction<Partial<Ifilter>>) => {
        setFilter({ ...filter, ...newFilter });
        setMeta({ ...meta, page: -1 });
        setPagination((prev) => ({ ...prev, page: 1 }));
    };

    const handleStatusFilterChange = (newFilter: Partial<Ifilter>) => {
        if (newFilter.status === 0) {
            setFilter((oldFilter) => {
                const temp = { ...oldFilter };
                temp.status = undefined;
                return { ...temp };
            });
        } else {
            setFilter({ ...filter, ...newFilter });
        }
        setMeta({ ...meta, page: -1 });
        setPagination((prev) => ({ ...prev, page: 1 }));
    };

    const handleOrderFilters = (newFilter: Partial<Ifilter>) => {
        if (newFilter.orderRefOrPhone === '') {
            setFilter((oldFilter) => {
                const temp = oldFilter;
                delete temp.orderRefOrPhone;
                return { ...temp };
            });
        } else {
            setFilter({ ...filter, ...newFilter });
        }
        setMeta({ ...meta, page: -1 });
        setPagination((prev) => ({ ...prev, page: 1 }));
    };

    const handlePaginationChange = (value: { [key: string]: any }) => {
        setPagination((prevPagination) => {
            const data = { ...prevPagination, ...value };
            if (value.limit) {
                data.page = 1;
            }
            return data;
        });
        setMeta((prev) => ({ ...prev, page: -1 }));
    };

    const activeCallAgents = callAgents.filter((activeCA) => activeCA.status === 1);
    const renderColumns = () => {
        let columnsToRender = [...columns];
        if (callAgentAdmin?.callCenter && callAgentAdmin?.callCenter.length <= 1) {
            columnsToRender = columnsToRender.filter((column) => column.field !== 'callCenter');
        }
        return columnsToRender;
    };
    return (
        <div>
            <Modal
                dismissible
                show={openAssignModal}
                popup
                onClose={() => {
                    setOpenAssignModal(false);
                    setCallAgent(null);
                }}
            >
                <Modal.Header>Assign Orders to a call agent</Modal.Header>
                <Modal.Body className="!p-2 overflow-y-auto ">
                    {callAgents && (
                        <div className="flex flex-col justify-between p-2 space-y-3 md:flex-row md:space-y-0 md:space-x-4 h-[20rem]">
                            <div className="grow  ">
                                <AutoComplete
                                    key="id"
                                    placeholder="Call Agent"
                                    options={activeCallAgents as []}
                                    getOptionLabel={(option: any) => `${option.firstName} ${option.lastName}`}
                                    // eslint-disable-next-line no-underscore-dangle
                                    onChange={(option) => setCallAgent(option._id as string)}
                                />
                            </div>
                            <div className="">
                                <CustomBtn
                                    variant="primary"
                                    onClick={() => {
                                        assignOrderCallAgent();
                                    }}
                                    isProcessing={callAgentAssignLoader}
                                    disabled={callAgentAssignLoader}
                                >
                                    <BsClipboardCheckFill className="mr-2 h-5 w-5" />
                                    Assign
                                </CustomBtn>
                            </div>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
            <Modal
                dismissible
                show={openAssignCcModal}
                popup
                onClose={() => {
                    setOpenAssignCcModal(false);
                    setCallCenter(null);
                }}
            >
                <Modal.Header>Assign Orders to a call center</Modal.Header>
                <Modal.Body className="!p-2 overflow-y-auto ">
                    {availableCallCenters && (
                        <div className="flex flex-col justify-between p-2 space-y-3 md:flex-row md:space-y-0 md:space-x-4 h-[20rem]">
                            <div className="grow">
                                <AutoComplete
                                    key="id"
                                    placeholder="Call Center"
                                    options={availableCallCenters}
                                    getOptionLabel={(option: any) => `${option.name}`}
                                    // eslint-disable-next-line no-underscore-dangle
                                    onChange={(option) => setCallCenter(option._id as string)}
                                />
                            </div>
                            <div className="">
                                <CustomBtn
                                    variant="primary"
                                    onClick={() => {
                                        assignOrdersCallCenter();
                                    }}
                                    isProcessing={callCenterAssignLoader}
                                    disabled={callCenterAssignLoader}
                                >
                                    <BsClipboardCheckFill className="mr-2 h-5 w-5" />
                                    Assign
                                </CustomBtn>
                            </div>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
            <section className="dark:bg-gray-900 flex items-center my-2">
                <div className=" relative w-full">
                    <div className="flex justify-between my-4">
                        <div className="flex ">
                            <MdOutlineShoppingCart size={32} /> <span className="font-medium text-2xl">Orders</span>
                        </div>
                        {managedCallCenters.length === 1 && (
                            <CustomBtn
                                variant="primary"
                                onClick={() => {
                                    setOpenAssignModal(true);
                                }}
                                className="md:!ml-auto"
                            >
                                <FaUserCheck className="mr-2 h-5 w-5" />
                                Assign to Call agent
                            </CustomBtn>
                        )}
                    </div>
                    <div className="flex justify-between">
                        <div className="flex gap-2">
                            <AutoComplete
                                key="id"
                                placeholder="All Status"
                                options={orderStatusListForAdminCC as []}
                                getOptionLabel={(option: any) => option.label}
                                // eslint-disable-next-line no-underscore-dangle
                                onChange={(option) => {
                                    setresetValue({ ...resetValue, status: false });
                                    // eslint-disable-next-line no-unused-expressions
                                    option?.type && tabsRef.current?.setActiveTab(ORDER_PHASES.indexOf(option.type));
                                    handleStatusFilterChange({
                                        status: option.id,
                                        orderPhase: option.type || filter.orderPhase,
                                    });
                                }}
                                resetValue={resetValue.status}
                            />
                            <AutoComplete
                                key="id"
                                placeholder="Filter by attempts"
                                options={Array.from({ length: 11 }, (_, i: number) => i) as []}
                                getOptionLabel={(option: any) => `${option} ${option > 1 ? 'attempts' : 'attempt'}`}
                                // eslint-disable-next-line no-underscore-dangle
                                onChange={(option) => {
                                    // eslint-disable-next-line no-restricted-globals
                                    if (typeof option === 'number') {
                                        handleFilterChange({ callCenterAttempt: Number(option) });
                                    } else {
                                        handleFilterChange({ callCenterAttempt: undefined });
                                    }
                                }}
                            />
                            <AutoComplete
                                key="id"
                                placeholder="Call Agent"
                                options={activeCallAgents as []}
                                getOptionLabel={(option: any) => `${option.firstName} ${option.lastName}`}
                                onFocus={() => setresetValue({ ...resetValue, callAgent: false })}
                                // eslint-disable-next-line no-underscore-dangle
                                onChange={(option) => {
                                    setCallAgent(option._id as string);
                                    const value = option._id
                                        ? { callAgent: option._id, callCenter: undefined }
                                        : { callAgent: option._id };
                                    handleFilterChange(value);
                                    option?._id && setresetValue({ ...resetValue, callCenter: true });
                                }}
                                resetValue={resetValue.callAgent}
                            />

                            {callAgentAdmin?.callCenter && callAgentAdmin?.callCenter?.length > 1 && (
                                <div id="select" className="w-52">
                                    <AutoComplete
                                        key="id"
                                        placeholder="All Call Centers"
                                        options={callAgentAdmin?.callCenter as []}
                                        getOptionLabel={(option: any) => option.name}
                                        onFocus={() => setresetValue({ ...resetValue, callCenter: false })}
                                        // eslint-disable-next-line no-underscore-dangle
                                        onChange={(option) => {
                                            if (option._id) {
                                                handleFilterChange({ callCenter: [option._id] });
                                                setresetValue({ ...resetValue, callAgent: true });
                                            } else {
                                                const temp = { ...filter };
                                                delete temp.callCenter;
                                                handleFilterChange({ ...temp });
                                            }
                                        }}
                                        resetValue={resetValue.callCenter}
                                    />
                                </div>
                            )}
                        </div>
                        <TextInput
                            icon={AiOutlineSearch}
                            placeholder="Order Ref Or Phone Number..."
                            id="orderRefOrPhone"
                            type="text"
                            sizing="md"
                            style={{ maxWidth: 270 }}
                            onChange={(e) => {
                                handleOrderFilters({ orderRefOrPhone: e.target.value });
                            }}
                        />
                    </div>
                </div>
            </section>
            <Tabs
                aria-label="Tabs with icons"
                style="underline"
                theme={{
                    tablist: {
                        tabitem: {
                            base: 'flex items-center justify-center px-4 h-14 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 ',
                        },
                    },
                }}
                ref={tabsRef}
                onActiveTabChange={(tab) => {
                    const orderPhase = ORDER_PHASES[tab];
                    handleOrderFilters({ orderPhase, status: undefined });
                }}
            >
                {ORDER_PHASES.map((item: any) => (
                    <Tabs.Item
                        title={
                            // reset the status when we select one of the tab
                            <div onClick={() => setresetValue({ ...resetValue, status: true })} className="h-full py-4">
                                {item}
                            </div>
                        }
                        key={item}
                        style={{ height: '64px !important', padding: '16px 24px' }}
                        className="!py-0 xs:!h-14"
                    >
                        <>
                            <DataTable
                                rows={ordersList}
                                columns={renderColumns()}
                                icons={statusDisplay}
                                setRows={setOrdersList}
                                pageState={{
                                    isLoading,
                                    total: meta.totalDocs,
                                    pageSize: pagination.limit,
                                    page: pagination.page,
                                    count: meta.totalPages,
                                }}
                                setPageState={handlePaginationChange}
                                selectable
                                setRowsSelected={setOrdersSelected}
                                filterState={filter}
                            />
                        </>
                    </Tabs.Item>
                ))}
            </Tabs>
        </div>
    );
};

export default OrderListCCA;
