/* eslint-disable no-console */
/* eslint-disable no-debugger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-fragments */
// import { Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CustomBtn from 'components/customButton/customBtn';
import toast from 'react-hot-toast';
import CustomError from 'components/customError/CustomError';
import { addCallCenter, getAllCallCenters } from 'services/admin/callCenter';
import { Label, TextInput } from 'flowbite-react';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { NotifProps } from '../callCenter.types';

import '../../../utils/helpers/phoneInput.css';

interface FormValues {
    name: string;
    location: string;
}

const AddCallCenter: React.FC<NotifProps> = ({ setOpen, setCallCenters }) => {
    const [isLoading, setIsLoading] = useState(false);

    const close = () => {
        setOpen(false);
    };

    const newCallCenter = async (values: any) => {
        setIsLoading(true);
        addCallCenter(values)
            .then((res) => {
                toast.success(res.data.message);
                close();
            })
            .then(() => getAllCallCenters({}).then((res) => setCallCenters(res.data.docs)))
            .catch((err) => {
                setIsLoading(false);
                toast.error(err?.response?.data?.errors?.message);
            });
    };

    return (
        <Formik
            initialValues={{
                name: '',
                location: '',
            }}
            onSubmit={(values: FormValues, onSubmitProps) => {
                newCallCenter(values);
                onSubmitProps.setSubmitting(false);
                onSubmitProps.resetForm();
            }}
        >
            {(formik) => (
                <Form onSubmit={formik.handleSubmit} placeholder="">
                    <div className="p-6 flex flex-col gap-2 ">
                        <div className="flex">
                            <Label htmlFor="info" value="General Information" className="dark:text-white" />
                            <hr className="m-auto w-3/4 dark:text-white" />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="name"
                                    className="dark:text-white"
                                    color={formik.errors.name ? 'failure' : ''}
                                    value="Name"
                                />
                            </div>
                            <TextInput
                                {...formik.getFieldProps('name')}
                                name="name"
                                color={formik.errors.name ? 'failure' : 'gray'}
                                type="text"
                                helperText={<CustomError name="name" component="div" />}
                            />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="location"
                                    className="dark:text-white"
                                    color={formik.errors.location ? 'failure' : ''}
                                    value="Location"
                                />
                            </div>
                            <TextInput
                                {...formik.getFieldProps('location')}
                                name="location"
                                color={formik.errors.location ? 'failure' : 'gray'}
                                type="text"
                                helperText={<CustomError name="location" component="div" />}
                            />
                        </div>
                    </div>
                    <div className="flex gap-2 justify-between py-2 px-2 items-center rounded-b-lg bg-background-secondary">
                        <CustomBtn
                            variant="failure"
                            outline
                            onClick={() => {
                                formik.resetForm();
                                close();
                            }}
                        >
                            Cancel
                        </CustomBtn>
                        <CustomBtn variant="primary" type="submit" isProcessing={isLoading} disabled={isLoading}>
                            Save
                        </CustomBtn>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default AddCallCenter;
