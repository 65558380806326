/* eslint-disable no-underscore-dangle */
import AccountRoleBadge from 'components/status/accountRoleBadge';
import { Accordion } from 'flowbite-react';
import { AllUserProps } from 'pages/profileSetting/profileSettings.types';
import React, { useEffect, useState } from 'react';
import { getSourcingAgent } from 'services/sourcingAgent/sourcingAgentProfile';
import { getCurrentUser } from 'utils/helpers/auth';
import ProfilePicture from './profilePicture';
import AccountInformation from './sourcingAgentUpdate/AccountInformation';
import PersonalInformation from './sourcingAgentUpdate/PersonalInformation';
import PasswordInfo from './sourcingAgentUpdate/passwordInfo';

const SourcingAgentProfile = () => {
    const [sourcingAgent, setSourcingAgent] = useState<AllUserProps>();

    const currentId = getCurrentUser()._id;

    useEffect(() => {
        getSourcingAgent(currentId).then((res) => {
            setSourcingAgent(res.data);
        });
    }, []);

    return (
        <>
            <div className="flex justify-end items-center mb-3">
                <p className="mr-2">Current Role:</p>
                <AccountRoleBadge role={getCurrentUser().role} />
            </div>
            <div className="grid grid-flow-col">
                <div className="flex justify-center items-center ">
                    <ProfilePicture
                        id={currentId}
                        profImg={
                            sourcingAgent?.profileImage ??
                            'https://image.similarpng.com/very-thumbnail/2021/08/Instagram-photo-frame-template-and-social-media-notification-icon-design-template-on-transparent-background-PNG.png'
                        }
                        setSourcingAgent={setSourcingAgent}
                    />
                </div>
                <div className="col-span-5">
                    <Accordion collapseAll alwaysOpen>
                        <Accordion.Panel>
                            <Accordion.Title>Personal Info</Accordion.Title>
                            <Accordion.Content>
                                {sourcingAgent && (
                                    <PersonalInformation
                                        firstName={sourcingAgent?.firstName}
                                        lastName={sourcingAgent?.lastName}
                                        phone={sourcingAgent?.phone}
                                        id={currentId}
                                    />
                                )}
                            </Accordion.Content>
                        </Accordion.Panel>
                        <Accordion.Panel>
                            <Accordion.Title>Email</Accordion.Title>
                            <Accordion.Content>
                                {sourcingAgent && <AccountInformation id={currentId} email={sourcingAgent.email} />}
                            </Accordion.Content>
                        </Accordion.Panel>
                        <Accordion.Panel>
                            <Accordion.Title>Password</Accordion.Title>
                            <Accordion.Content>
                                <PasswordInfo id={currentId} />
                            </Accordion.Content>
                        </Accordion.Panel>
                    </Accordion>
                </div>
            </div>
        </>
    );
};

export default SourcingAgentProfile;
