/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Box, Typography } from '@mui/material';
import CustomBtn from 'components/customButton/customBtn';
import { Button, Carousel } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from 'react-icons/bs';
import Zoom from 'react-medium-image-zoom';
import { useLocation } from 'react-router-dom';
import { getProduct, updateProduct } from 'services/admin/product';
import { downloadFilesAsBase64 } from 'utils/helpers/productFilesDownloader';
import { IProduct } from 'utils/Interfaces/products.interface';
import { getVariantQuantity } from 'utils/helpers/productsHelpers/getVariantInfo';
import Package from '../../assets/package.png';
import { variantsTableHeadWrapper } from './EditProduct.styles';
import VariantInfo from './VariantInfo';

const EditProduct: React.FC<{
    navCache: { editProduct?: IProduct; quotation?: any };
    setNavCache: React.Dispatch<
        React.SetStateAction<{
            editProduct?: IProduct;
            quotation?: any;
        }>
    >;
}> = ({ navCache, setNavCache }) => {
    const location: any = useLocation();
    const [variants, setVariants] = useState<IProduct[] | string[]>();
    const [product, setProduct] = useState<IProduct>();

    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [haveErrors, SethaveErrors] = useState<boolean[]>([]);
    const [showMore, setShowMore] = useState(false);

    const handleSubmitUpdate = async () => {
        const toastLoader = toast.loading('Processing update...');
        setLoadingUpdate(true);
        const updatePromises: any[] = [];
        if (variants) {
            // const updatePromisest = product.variants.map((variant: any) => {
            (variants as IProduct[]).forEach((variant: IProduct) => {
                updatePromises.push(
                    updateProduct({
                        // eslint-disable-next-line no-underscore-dangle
                        id: variant._id,
                        updatedData: {
                            chineseName: variant.chineseName,
                            productHeight: variant.productHeight,
                            productWeight: variant.productWeight,
                            productLength: variant.productLength,
                            productWidth: variant.productWidth,
                            declarationAmount: variant.declarationAmount,
                        },
                    }),
                );
            });
        } else if (product) {
            updatePromises.push(
                updateProduct({
                    // eslint-disable-next-line no-underscore-dangle
                    id: product._id,
                    updatedData: {
                        productHeight: product.productHeight,
                        productWeight: product.productWeight,
                        productLength: product.productLength,
                        productWidth: product.productWidth,
                        declarationAmount: product.declarationAmount,
                        chineseName: product.chineseName,
                    },
                }),
            );
        }
        await Promise.all(updatePromises)
            .then(() => {
                // 🏷️ remove cached values for `editProduct`
                setNavCache((prev) => {
                    const temp = { ...prev };
                    delete temp.editProduct;
                    return temp;
                });
                toast.success('Updated product successfully!', { id: toastLoader });
            })
            .catch(() => {
                toast.error(`Sorry something went wrong!`, { id: toastLoader });
            })
            .finally(() => {
                setLoadingUpdate(false);
            });
    };

    useEffect(() => {
        const toastLoader = toast.loading('Loading product data...');
        if (navCache.editProduct && Object.keys(navCache.editProduct).length > 0) {
            setProduct(navCache.editProduct);
            if (navCache.editProduct.variants && navCache.editProduct.variants.length > 0) {
                setVariants(navCache?.editProduct?.variants || []);
            }
        } else {
            getProduct(location.state.productId)
                .then((res) => {
                    toast.dismiss(toastLoader);
                    setProduct(res.data.product);
                    if (res.data.product.variants && res.data.product.variants.length > 0) {
                        setVariants(res.data.product.variants || []);
                    }
                })
                .catch((err) => {
                    toast.error(`Sorry something went wrong! ${err}`, { id: toastLoader });
                });
        }
    }, []);
    const textDescription = () => {
        if (product?.description && product?.description.length < 400) {
            return (
                <div className="flex items-center gap-1 ">
                    <Typography variant="subtitle1" sx={{ fontSize: '12px' }}>
                        Description:{' '}
                    </Typography>
                    <Typography variant="body2">{product.description}</Typography>
                </div>
            );
        }
        return (
            <div>
                <div className="flex items-center gap-1">
                    <Typography variant="subtitle1" sx={{ fontSize: '12px' }}>
                        Description:{' '}
                    </Typography>
                    <Typography variant="body2">
                        {showMore ? product?.description : `${product?.description.substring(0, 400)}...`}
                    </Typography>
                </div>
                <div onClick={() => setShowMore(!showMore)} className="cursor-pointer hover">
                    {showMore ? 'Show less' : 'Show more'}
                </div>
            </div>
        );
    };

    const isThereAnImage = () => {
        const isThere = product?.design.filter(
            (prodImg) =>
                prodImg.endsWith('.jpg') ||
                prodImg.endsWith('.png') ||
                prodImg.endsWith('.jpeg') ||
                prodImg.endsWith('.webp'),
        );
        if (isThere && isThere.length > 0) {
            return isThere.map((prodImg) => (
                <Zoom>
                    <img
                        key={prodImg}
                        src={prodImg}
                        alt="quotation"
                        style={{
                            objectFit: 'contain',
                            borderRadius: 8,
                            overflow: 'hidden',
                            width: 270,
                            height: 270,
                        }}
                    />
                </Zoom>
            ));
        }
        return (
            <Zoom>
                <img
                    src={Package}
                    alt="quotation"
                    style={{
                        objectFit: 'contain',
                        borderRadius: 8,
                        overflow: 'hidden',
                        width: 270,
                        height: 270,
                    }}
                />
            </Zoom>
        );
    };
    // counting the files attached to the product
    const countFileTypes = (files: string[]): Record<string, number> => {
        const fileTypeCounts: Record<string, number> = {};

        files.forEach((file) => {
            const fileExtension = file.split('.').pop()?.toLowerCase() || 'unknown';
            const groupedExtension =
                fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'jpg'
                    ? 'Images'
                    : fileExtension === 'svg%2bxml' || fileExtension === 'SVG%2BXML' || fileExtension === 'svg'
                      ? 'SVG'
                      : fileExtension;

            fileTypeCounts[groupedExtension] = (fileTypeCounts[groupedExtension] || 0) + 1;
        });

        return fileTypeCounts;
    };

    const results = Object.keys(countFileTypes(product?.design || [])).map((key) => (
        <div key={key} className=" border-[0.3px] dark:border-gray-800 border-gray-300 flex gap-1 p-2 ">
            <p>{key.toLocaleUpperCase()}:</p>
            <p>{countFileTypes(product?.design as [])[key]}</p>
        </div>
    ));

    return (
        <Box>
            <Box>
                {navCache.editProduct && (
                    <p className="bg-orange-500 text-gray-100 py-1 px-2 font-bold my-1 w-[100%] text-center">
                        You have unsaved data! Confirm the update or your changes will be lost.
                    </p>
                )}
                {/* Pending UI */}
                {!product && (
                    <Box
                        sx={{
                            height: '55px',
                            border: '1px solid',
                            borderRadius: '4px',
                            borderColor: 'borderColor',
                            margin: '10px 0px',
                            padding: '0px 15px',
                            backgroundColor: 'secondaryBackground',
                            opacity: 0.4,
                        }}
                    />
                )}

                {product && (
                    <div className="grid grid-cols-3">
                        <div className="flex justify-center items-center flex-col" key="carouselforrodvari">
                            <Carousel
                                className="w-[250px] h-[250px] sticky top-0"
                                leftControl={<BsFillArrowLeftCircleFill className=" w-9 h-9 text-[#ffffff8c]" />}
                                rightControl={<BsFillArrowRightCircleFill className=" w-9 h-9 text-[#ffffff8c]" />}
                                theme={{
                                    indicators: {
                                        active: {
                                            off: 'bg-black/30 hover:bg-black/40 dark:bg-gray-800/50 dark:hover:bg-gray-800',
                                            on: ' bg-black/50 dark:bg-gray-800',
                                        },
                                        base: 'h-1 w-3 rounded',
                                        wrapper: 'absolute bottom-2 left-1/2 flex -translate-x-1/2 space-x-2 ',
                                    },
                                }}
                            >
                                {isThereAnImage()}
                            </Carousel>
                            {product.design && product.design.length > 0 ? (
                                <div className="mt-1">
                                    <Button onClick={() => downloadFilesAsBase64(product?.design, product?.name)}>
                                        Download all Files
                                    </Button>
                                </div>
                            ) : (
                                <div className="mt-1">
                                    <Button disabled>No files attached to download</Button>
                                </div>
                            )}
                        </div>
                        <div className=" col-span-2 ">
                            {product?.name && (
                                <div className="mb-2">
                                    <Typography variant="subtitle2" sx={{ fontSize: '15px' }}>
                                        {' '}
                                        {product?.name}{' '}
                                    </Typography>
                                    <div className="flex gap-2">
                                        <Typography variant="subtitle1" sx={{ fontSize: '12px' }}>
                                            Product link:
                                        </Typography>
                                        <div className="max-w-[500px] overflow-hidden whitespace-nowrap">
                                            <Typography variant="subtitle1" sx={{ fontSize: '12px' }}>
                                                <a
                                                    href={product.refrenceLink}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="text-blue-500"
                                                >
                                                    {product.refrenceLink ?? '--'}
                                                </a>
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {product.description ? textDescription() : 'No description provided.'}
                            <div className="flex gap-1">{results}</div>
                        </div>
                    </div>
                )}

                <Box sx={variantsTableHeadWrapper}>
                    <Box>Index</Box>
                    <Box>Variants</Box>
                    <Box>Chinese Name</Box>
                    <Box>Quantity</Box>
                    <Box>UNIT Weight</Box>
                    <Box>UNIT Height</Box>
                    <Box>UNIT Width</Box>
                    <Box>UNIT Length</Box>
                    <Box>UNIT Declaration Amount</Box>
                </Box>

                {/* update inputs list */}
                {product && variants && variants.length > 0
                    ? variants
                          //   .filter((variant: IProduct) => {
                          //       // WTF is this ?
                          //       if (variant.disabled) {
                          //           return variant.disabled === false;
                          //       }
                          //       return variant;
                          //   })
                          .map((variant: any, index: any) => {
                              const quantity = getVariantQuantity(variant);
                              if (!quantity) return;
                              return (
                                  <VariantInfo
                                      variantIndex={index}
                                      currentVariant={variant}
                                      setData={setProduct}
                                      data={product}
                                      setHaveErrors={SethaveErrors}
                                      key={variant._id}
                                      setNavCache={setNavCache}
                                      navCache={navCache}
                                      quantity={quantity}
                                  />
                              );
                          })
                    : product && (
                          <VariantInfo
                              data={product}
                              setData={setProduct}
                              currentVariant={product}
                              setHaveErrors={SethaveErrors}
                              setNavCache={setNavCache}
                              navCache={navCache}
                          />
                      )}
                {/* Pending UI */}
                {!product && (
                    <Box
                        sx={{
                            height: '40vh',
                            border: '1px solid',
                            borderRadius: '4px',
                            borderColor: 'borderColor',
                            margin: '10px 0px',
                            padding: '0px 15px',
                            backgroundColor: 'secondaryBackground',
                            opacity: 0.4,
                        }}
                    />
                )}
                {/* Update button (updates all fields at once) */}
                <div className="flex justify-end">
                    <CustomBtn
                        variant={!product || loadingUpdate || haveErrors.some((el) => el) ? 'secondary' : 'primary'}
                        onClick={handleSubmitUpdate}
                        disabled={!product || loadingUpdate || haveErrors.some((el) => el)}
                        isProcessing={loadingUpdate}
                    >
                        Update product
                    </CustomBtn>
                </div>
            </Box>
        </Box>
    );
};

export default EditProduct;
