import { createTheme } from '@mui/material/styles';

declare module '@mui/material/Button' {
    // eslint-disable-next-line no-unused-vars
    interface ButtonPropsVariantOverrides {
        primary: true;
        secondary: true;
        tertiary: true;
        quaternary: true;
        variantBox: true;
    }
}
declare module '@mui/material/styles' {
    interface Palette {
        selected: { [keys: string]: string };
        textColor: { [keys: string]: string };
        primaryBackground: string;
        secondaryBackground: string;
        grayBackground: string;
        inputBackground: string;
        disabledInputBackground: string;
        borderColor: string;
        tertiaryBackground: string;
        addIconColor?: string;
        notifcationRingColor?: string;
        errorColor?: string;
    }
    // allow configuration using `createTheme`
    interface PaletteOptions {
        selected?: { [keys: string]: string };
        textColor?: { [keys: string]: string };
        primaryBackground?: string;
        secondaryBackground?: string;
        tertiaryBackground?: string;
        grayBackground?: string;
        borderColor?: string;
        inputBackground?: string;
        disabledInputBackground: string;
        addIconColor?: string;
        notifcationRingColor?: string;
        errorColor?: string;
    }
}

// eslint-disable-next-line import/no-mutable-exports
let darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primaryBackground: '#161B22', // sideNar, data tables ...
        secondaryBackground: '#0D1117', // layout
        tertiaryBackground: '#161B22',
        borderColor: '#30363d', // line colors
        grayBackground: '#0D1117',
        inputBackground: '#0D1117',
        disabledInputBackground: '#161B22',
        addIconColor: '#0D1117',
        notifcationRingColor: '#fff',
        primary: {
            // main: '#eef3ff',
            // dark: '#e1e9fc',
            main: '#578aff',
            dark: '#5D64FC',
            light: '#6bbafa',
        },
        secondary: {
            main: '#90CAF9',
            dark: '#6bbafa',
        },

        textColor: {
            primary: '#C9D1D9',
            secondary: '#7e8cab',
            tertiary: '#fff',
            quaternary: '#0D1117',
            variantBox: '#C9D1D9',
            selected: 'red',
            errorColor: '#D74242',
        },
    },
});
// eslint-disable-next-line no-unused-vars
darkTheme = createTheme(darkTheme, {
    typography: {
        fontFamily: 'Roboto',
        subtitle2: {
            color: darkTheme.palette.textColor.tertiary,
            fontWeight: 600,
            fontSize: '14px',
            // marginBottom: '30px',
        },
        h4: {
            color: darkTheme.palette.textColor.tertiary,
            fontWeight: 600,
            fontSize: '20px',
            margin: '10px 0px',
        },
        body1: {
            color: darkTheme.palette.textColor.primary,
            fontWeight: 500,
            fontSize: '14px',
            margin: '0px',
        },
        body2: {
            color: darkTheme.palette.textColor.primary,
            fontWeight: 500,
            fontSize: '12px',
        },
    },
    shape: {
        borderRadius: 8,
    },
    components: {
        MuiBox: {
            styleOverrides: {
                root: {
                    background: darkTheme.palette.primaryBackground,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    background: darkTheme.palette.primaryBackground,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
    },
});
export default darkTheme;
