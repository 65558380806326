/* eslint-disable no-underscore-dangle */
import Loader from 'components/tables/loader';
import { Accordion, Card } from 'flowbite-react';
import { ICallCenterAdminConfig } from 'pages/callCenterAdminList/callCenterAdmin.types';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { getCallCenterConfig } from 'services/callCenter/callCenterService';
import { accordContent, accordTitle } from '../configuration.styles';
import JobsConfiguration from './jobsConfiguration';
import OrderConfiguration from './orderConfiguration';

const CallCenter = () => {
    const [callCenters, setCallCenters] = useState<ICallCenterAdminConfig[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getCallCenterConfig({ limit: 1000 })
            .then((res) => {
                setCallCenters(res?.data?.docs);
                setIsLoading(false);
            })
            .catch((e) => {
                setIsLoading(false);
                toast.error(e.response.data.errors.message);
            });
    }, []);

    return (
        <>
            <div className=" p-4 mb-2 gap-4 w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <div className="my-5">
                    <p className="dark:text-[#6874F5] text-2xl font-semibold font-poppin">Call Center</p>
                </div>
                {isLoading ? (
                    <Loader />
                ) : (
                    <Card
                        theme={{
                            root: {
                                base: 'flex rounded-lg border border-gray-200 bg-white  dark:border-gray-700 dark:bg-transparent',
                                children: 'flex h-full flex-col justify-center p-2 ',
                            },
                        }}
                    >
                        {callCenters?.length ? (
                            <Accordion collapseAll alwaysOpen flush>
                                {callCenters?.map((callCenter: any) => (
                                    <Accordion.Panel key={callCenter?._id}>
                                        <div className="w-full">
                                            <Accordion.Title as="h4" theme={accordTitle}>
                                                {callCenter?.name}
                                            </Accordion.Title>
                                        </div>

                                        <Accordion.Content theme={accordContent}>
                                            <div className="my-2 mx-4 ">
                                                <JobsConfiguration
                                                    key={`jobs_${callCenter?._id}`}
                                                    jobsConfig={callCenter?.jobsConfig}
                                                />
                                                <OrderConfiguration
                                                    key={`order_${callCenter?._id}`}
                                                    orderConfig={callCenter?.ordersConfig}
                                                />
                                            </div>
                                        </Accordion.Content>
                                    </Accordion.Panel>
                                ))}
                            </Accordion>
                        ) : null}
                    </Card>
                )}
            </div>
        </>
    );
};

export default CallCenter;
