import { Ifilter, Ipagination } from 'services/admin/wallet';

export interface WalletVerifProps {
    walletCustomerHistory?: any;
    operationId: string;
    pagination: Ipagination;
    setwalletCustomerHistory: React.Dispatch<any>;
    noFilter: Ifilter;
}

export interface CustomerWallet {
    amount: string;
}

export const getStatus = (status: number) => {
    switch (status) {
        case 1:
            return 'Pending';
        case 2:
            return 'Confirmed';
        case 3:
            return 'Rejected';
        default:
            return 'Pending';
    }
};
