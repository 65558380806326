import { Modal } from 'flowbite-react';
import React, { useState } from 'react';
import { HiOutlineIdentification } from 'react-icons/hi';
import { IOrder } from 'services/admin/order';

const OrderClientInfo: React.FC<{ data: IOrder }> = ({ data }) => {
    const [openModal, setOpenModal] = useState(false);
    return (
        <>
            <Modal dismissible show={openModal} popup onClose={() => setOpenModal(false)}>
                <Modal.Header>Client Details</Modal.Header>
                <Modal.Body className="!p-1 overflow-y-auto ">
                    <div className=" p-2">
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <tbody>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Name:
                                    </th>
                                    <td className="px-6 py-4">
                                        {data.firstName}&nbsp;
                                        {data.lastName}
                                    </td>
                                </tr>
                                <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Phone
                                    </th>
                                    <td className="px-6 py-4">{data.phone}</td>
                                </tr>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Adresss 1
                                    </th>
                                    <td className="px-6 py-4">{data.address1}</td>
                                </tr>
                                <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Adresss 2
                                    </th>
                                    <td className="px-6 py-4">{data.address2}</td>
                                </tr>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        City
                                    </th>
                                    <td className="px-6 py-4">{data.city}</td>
                                </tr>
                                <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Province
                                    </th>
                                    <td className="px-6 py-4">{data.province}</td>
                                </tr>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Zip Code
                                    </th>
                                    <td className="px-6 py-4">{data.zip}</td>
                                </tr>
                                <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Country
                                    </th>
                                    <td className="px-6 py-4">{data.country}</td>
                                </tr>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Country code
                                    </th>
                                    <td className="px-6 py-4">{data.countryCode}</td>
                                </tr>
                                <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Company
                                    </th>
                                    <td className="px-6 py-4">{data.company}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
            </Modal>
            <div>
                <HiOutlineIdentification className="w-5 h-5 cursor-pointer" onClick={() => setOpenModal(true)} />
            </div>
        </>
    );
};

export default OrderClientInfo;
