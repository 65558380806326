import React from 'react';
import { useLocation } from 'react-router-dom';
import { IProduct } from 'utils/Interfaces/products.interface';
import DropshippingQuotation from './DropshippingQuotation';
import LogistioBulkQuotation from './LogistioBulkQuotation';
import BulkQuotation from './BulkQuotation';

const Quotation: React.FC<{
    navCache: { editProduct?: IProduct; quotation?: any };
    setNavCache: React.Dispatch<
        React.SetStateAction<{
            editProduct?: IProduct;
            quotation?: any;
        }>
    >;
}> = ({ navCache, setNavCache }) => {
    const location: any = useLocation();

    return (
        <div>
            {location.state.type === 1 && (
                <div>
                    <DropshippingQuotation />
                </div>
            )}
            {location.state.type === 2 && (
                <div>
                    <BulkQuotation />
                </div>
            )}
            {location.state.type === 3 && (
                <div>
                    <LogistioBulkQuotation setNavCache={setNavCache} navCache={navCache} />
                </div>
            )}
        </div>
    );
};

export default Quotation;
