import React from 'react';
import './flagsIcons.css';

const FlagIcon: React.FC<{ flag: string; className?: string }> = ({ flag, className }) => {
    // const flagCode = () => {
    //     switch (flag.toLocaleLowerCase()) {
    //         case 'eur':
    //             return 'eu';

    //         case 'usd':
    //             return 'us';

    //         case 'cad':
    //             return 'ca';
    //         default:
    //             return 'xx';
    //     }
    // };

    return (
        <>
            <span className={`flag-icon flag-icon-${flag?.toLocaleLowerCase()} ${className}`} />
        </>
    );
};

export default FlagIcon;
