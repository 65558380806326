/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-fragments */
import toast from 'react-hot-toast';
import CustomBtn from 'components/customButton/customBtn';
import CustomError from 'components/customError/CustomError';
import FlagIcon from 'components/flags/flagIcon';
import { Label, Modal, Select, TextInput } from 'flowbite-react';
import { Form, Formik } from 'formik';
import React from 'react';
import { AddWalletCurrencyConfigValidation } from 'utils/validations/walletCurrencyConfig';
import { logistioCurrencies } from 'utils/countryList';
import { IWalletCurrencyConfig, addCountryConfiguration, getConfiguration } from 'services/admin/configuration';
import { AddWalletCurrencyConfig } from './walletCurrencyConfig';

const NewWalletCurrencyModal: React.FC<AddWalletCurrencyConfig> = ({
    open,
    setOpen,
    walletCurrencyConfig,
    setWalletCurrencyConfig,
}) => {
    const AddWalletConfig = (values: IWalletCurrencyConfig) => {
        const toastLoader = toast.loading('Loading...');
        let newValues = walletCurrencyConfig;
        if (walletCurrencyConfig) {
            newValues.push(values);
        } else {
            newValues = [values];
        }
        addCountryConfiguration({ walletCurrency: newValues })
            .then((res) => {
                toast.success(res.data.message, { id: toastLoader });
                getConfiguration().then((result) => {
                    setWalletCurrencyConfig(result?.data?.walletCurrency || []);
                });
            })
            .catch((err) => toast.error(err.response.data.errors.message, { id: toastLoader }));
    };

    const checkIfCurrencyExist = (currencyCode: string) => {
        return walletCurrencyConfig && walletCurrencyConfig.find((el) => el.currency === currencyCode);
    };

    const getCurrenciesContent = () => {
        const content = [];
        for (const item of logistioCurrencies) {
            if (!checkIfCurrencyExist(item.code)) {
                content.push(
                    <option key={item.code} value={item.code}>
                        <FlagIcon flag={item?.code} /> {item.label}
                    </option>,
                );
            } else {
                content.push(
                    <option key={item.code} value={item.code} disabled>
                        <FlagIcon flag={item?.code} /> {item.label}
                    </option>,
                );
            }
        }
        return content;
    };

    return (
        <>
            <Modal dismissible show={open} popup onClose={() => setOpen(false)}>
                <Modal.Header>Wallet Currency configuration</Modal.Header>
                <Modal.Body className=" !p-0">
                    <Formik
                        initialValues={{
                            currency: '',
                            currencyValue: 1,
                        }}
                        onSubmit={(values, onSubmitProps) => {
                            AddWalletConfig(values);
                            setOpen(false);
                            onSubmitProps.setSubmitting(false);
                            onSubmitProps.resetForm();
                        }}
                        validationSchema={AddWalletCurrencyConfigValidation}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit} placeholder="">
                                <div className="p-2 flex flex-col gap-2 overflow-auto max-h-[37rem]">
                                    <div className="grid gap-6 grid-cols-1">
                                        <div>
                                            <div className="mb-2 block">
                                                <Label className="dark:text-white" value="Currency" />
                                            </div>
                                            <Select id="currency" {...formik.getFieldProps('currency')}>
                                                <option key="select" value="">
                                                    Select currency
                                                </option>
                                                {getCurrenciesContent()}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-2">
                                    <div className="flex gap-2 justify-between py-2 px-2 items-center rounded-b-lg  bg-background-secondary mb-2 ">
                                        <div>
                                            <div className="mb-2 block">
                                                <Label
                                                    htmlFor="currencyValUDS"
                                                    className="dark:text-white"
                                                    value="USD value"
                                                />
                                            </div>
                                            <TextInput
                                                value={1}
                                                name="currencyValUDS"
                                                // placeholder="jhondoe@email.com"
                                                disabled
                                                type="text"
                                                helperText={
                                                    <React.Fragment>
                                                        <CustomError name="currencyValUDS" component="div" />
                                                    </React.Fragment>
                                                }
                                            />
                                        </div>
                                        <div>
                                            <div className="mb-2 block">
                                                <Label
                                                    htmlFor="currencyValue"
                                                    className="dark:text-white"
                                                    // color={
                                                    //     formik.touched.currencyValue && formik.errors.currencyValue
                                                    //         ? 'failure'
                                                    //         : ''
                                                    // }
                                                    value="Conversion rate to USD"
                                                />
                                            </div>
                                            <TextInput
                                                {...formik.getFieldProps('currencyValue')}
                                                name="currencyValue"
                                                // placeholder="jhondoe@email.com"
                                                // color={
                                                //     formik.touched.currencyValue && formik.errors.currencyValue
                                                //         ? 'failure'
                                                //         : 'gray'
                                                // }
                                                type="text"
                                                helperText={
                                                    <React.Fragment>
                                                        <CustomError name="currencyValue" component="div" />
                                                    </React.Fragment>
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex gap-2 justify-between py-2 px-2 items-center rounded-b-lg  bg-background-secondary">
                                    <CustomBtn
                                        variant="failure"
                                        outline
                                        pill
                                        type="reset"
                                        onClick={() => {
                                            setOpen(false);
                                            formik.resetForm();
                                        }}
                                    >
                                        Cancel
                                    </CustomBtn>
                                    <CustomBtn
                                        pill
                                        variant="primary"
                                        type="submit"
                                        disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                                    >
                                        Add
                                    </CustomBtn>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default NewWalletCurrencyModal;
