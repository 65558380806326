import { Card } from 'flowbite-react';
import React from 'react';
import { CustomCProps } from './CustomCards.types';

const CustomizedCard: React.FC<CustomCProps> = ({ children, shadow, horizontal, imgAlt, imgSrc, className }) => {
    return (
        <>
            {' '}
            {imgAlt && imgSrc ? (
                <Card
                    className={` ${!shadow ? '!shadow-none' : ''}  dark:text-white   ${className}`}
                    /* horizental will work fine when imgSrc is used */
                    horizontal={horizontal}
                    imgAlt={imgAlt}
                    imgSrc={imgSrc}
                >
                    {children}
                </Card>
            ) : (
                <div
                    className={`block  p-3 bg-white border  border-gray-200 rounded-lg ${
                        !shadow ? '!shadow-none' : 'shadow'
                    }  hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 ${className}`}
                >
                    <div className="dark:text-white">{children}</div>
                </div>
            )}
        </>
    );
};

export default CustomizedCard;
