/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-fragments */
import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import toast from 'react-hot-toast';
import '../../../utils/helpers/phoneInput.css';
import { getAllCallCenters, updateCallCenter } from 'services/admin/callCenter';
import CustomBtn from 'components/customButton/customBtn';

import { Slider } from '@mui/material';
import { ICallCenter, NotifProps } from '../callCenter.types';

const PerCent: React.FC<NotifProps> = ({ setOpen, setCallCenters }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [callCenters, setCallCentersState] = useState<Array<ICallCenter>>([]);
    const [sliderValue, setSliderValue] = useState(50);

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setSliderValue(newValue as number);
    };

    const close = () => {
        setOpen(false);
    };

    const updateCallCenters = async () => {
        setIsLoading(true);
        try {
            const callCenterUpdates = callCenters.map((callCenter, index) => {
                const perCentLeads = index === 0 ? sliderValue : 100 - sliderValue;
                const status = 1;
                return updateCallCenter(callCenter._id, { perCentLeads, status });
            });
            await Promise.all(callCenterUpdates);
            toast.success('Call centers updated successfully');
            const res = await getAllCallCenters({ limit: 1000 });
            setCallCenters(res.data.docs);
            close();
        } catch (err) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getAllCallCenters({ limit: 1000 }).then((res) => {
            setCallCentersState(res.data.docs);
            setSliderValue(res.data.docs[0].perCentLeads);
        });
    }, []);

    return (
        <Formik
            initialValues={{
                name: '',
            }}
            onSubmit={(values, onSubmitProps) => {
                updateCallCenters();
                onSubmitProps.setSubmitting(false);
                onSubmitProps.resetForm();
            }}
        >
            {(formik) => (
                <Form onSubmit={formik.handleSubmit} placeholder="">
                    <div className="p-6 flex flex-col gap-2">
                        <label>Percentage</label>
                        <Slider
                            value={sliderValue}
                            onChange={handleSliderChange}
                            aria-labelledby="percentage-slider"
                            step={5}
                            min={0}
                            max={100}
                        />
                        {callCenters.length >= 2 && (
                            <>
                                <div>
                                    {callCenters[0]?.name} : {sliderValue}%
                                </div>
                                <div>
                                    {callCenters[1]?.name} : {100 - sliderValue}%
                                </div>
                            </>
                        )}
                    </div>
                    <div className="flex gap-2 justify-between py-2 px-2 items-center rounded-b-lg bg-background-secondary">
                        <CustomBtn
                            variant="failure"
                            outline
                            onClick={() => {
                                formik.resetForm();
                                close();
                            }}
                        >
                            Cancel
                        </CustomBtn>
                        <CustomBtn variant="primary" type="submit" isProcessing={isLoading} disabled={isLoading}>
                            Save
                        </CustomBtn>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default PerCent;
