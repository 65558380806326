/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { IReadyForPickUp, IStockShipment, setShipmentReadyForPickUp } from 'services/admin/stockShipment';
import { Button } from 'flowbite-react';
import { v4 as uuidv4 } from 'uuid';

import GeneralDrawer from 'components/generalDrawer/generalDrawer';
import toast from 'react-hot-toast';
import { IoMdClose } from 'react-icons/io';
import { Boxes } from './boxes';
import { handleErrors } from './utils';
import { Transportation } from './transportation';

export interface IBoxProduct {
    id: string;
    _id: string;
    name: string;
    qty: number;
    shipmentId: string;
}
export interface IBoxes {
    id: number | string;
    weight: number | string;
    width: number | string;
    length: number | string;
    height: number | string;
    products: IBoxProduct[];
}

export interface IErrors {
    element: string;
    message: string;
    box?: number;
}

export interface IProductAttributes {
    [productId: string]: {
        name: string;
        battery: boolean;
        branding: boolean;
        solely: boolean;
    };
}

export interface data {
    shipmentIds: string[];
    boxes: {
        id: number | string;
        weight: number;
        width: number;
        length: number;
        height: number;
        products: { id: string; _id: string; name: string; qty: number; shipmentId: string };
    }[];
    productAttributes: {
        [productId: string]: {
            name: string;
            battery: boolean;
            branding: boolean;
            solely: boolean;
        };
    };
    transportation: string;
    platform: string;
    noofPieces: number;
    status: string;
}

export const PickUpDrawer: React.FC<{
    shipmentsData: IStockShipment[];
    openModal: boolean;
    setOpenModal: any;
    refetchShipments: () => void;
}> = ({ shipmentsData, openModal, setOpenModal, refetchShipments }) => {
    const [step, setStep] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<IErrors[]>([]);
    const [transportation, setTransporation] = useState<string>('');
    const [platform, setPlatform] = useState<'其他' | '独立站' | 'NOON FLEX' | '亚马逊FLEX' | '速卖通' | ''>('');
    const [documents, setDocuments] = useState<string>('');
    const [productAttributes, setProductAttributes] = useState<IProductAttributes>({
        '': {
            name: '',
            battery: false,
            branding: false,
            solely: false,
        },
    });
    const [boxes, setBoxes] = useState<IBoxes[]>([
        {
            id: uuidv4(),
            weight: '',
            width: '',
            length: '',
            height: '',
            products: [{ id: uuidv4(), name: '', _id: '', qty: 0, shipmentId: '' }],
        },
    ]);

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleNextStep = () => {
        const newErrors = handleErrors(boxes, shipmentsData, transportation, platform, step);
        setErrors(newErrors);
        if (!newErrors?.length) {
            const uniqueProducts = Array.from(
                new Map(
                    (boxes?.flatMap((elt) => elt.products) as IBoxProduct[])?.map((product) => [product._id, product]),
                ).values(),
            );

            const newProductAttributes: IProductAttributes = {};

            uniqueProducts.forEach((product: IBoxProduct) => {
                newProductAttributes[product._id] = {
                    name: product.name,
                    battery: productAttributes?.[product?._id]?.battery || false,
                    branding: productAttributes?.[product?._id]?.branding || false,
                    solely: productAttributes?.[product?._id]?.solely || false,
                };
            });

            setProductAttributes(newProductAttributes);
            setStep(1);
        }
    };

    const handleSubmit = () => {
        const newErrors = handleErrors(boxes, shipmentsData, transportation, platform, step);
        setErrors(newErrors);
        if (!newErrors?.length) {
            setIsLoading(true);
            const toastId = toast.loading('Loading for updating shipment status...');
            const dataForPickUp: IReadyForPickUp = {
                boxes: boxes?.map((box) => ({
                    ...box,
                    height: Number(box.height),
                    length: Number(box.length),
                    width: Number(box.width),
                    weight: Number(box.weight),
                })),
                platform,
                transportation,
                productAttributes,
                status: 2,
                documents,
                country: shipmentsData?.[0]?.country,
            };

            setShipmentReadyForPickUp(dataForPickUp)
                .then(() => {
                    toast.dismiss(toastId);
                    toast.success('Shipments status updated successfuly');
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.errors?.message);
                    setOpenModal(false);
                })
                .finally(() => {
                    refetchShipments();
                    setIsLoading(false);
                    setOpenModal(false);
                });
        }
    };

    return (
        <GeneralDrawer
            anchor="right"
            open={openModal}
            onClose={setOpenModal}
            PaperProps={{
                sx: { width: '60%', padding: '5px 10px', height: '100%' },
            }}
        >
            <div className="px-4 py-1 flex items-center justify-between w-full">
                <div>
                    <h4 className=" font-bold text-gray-700 dark:text-gray-400">Goods info</h4>
                </div>
                <Button size="s" onClick={handleCloseModal}>
                    <IoMdClose className="w-5 h-5" />
                </Button>
            </div>

            {step === 0 ? (
                <Boxes
                    errors={errors}
                    shipmentsData={shipmentsData}
                    setBoxes={setBoxes}
                    boxes={boxes}
                    key="goodInformationModal"
                />
            ) : (
                <Transportation
                    numberOfBoxes={boxes?.length}
                    setProductAttributes={setProductAttributes}
                    productAttributes={productAttributes}
                    platform={platform}
                    setPlatform={setPlatform}
                    transportation={transportation}
                    setTransportation={setTransporation}
                    errors={errors}
                    documents={documents}
                    setDocuments={setDocuments}
                />
            )}

            <div className="flex flex-col w-full   px-4">
                {errors
                    ?.filter((error: IErrors) => error?.element === 'products')
                    ?.map((error: IErrors, index: number) => (
                        <div
                            key={`${error.element}_${index}`}
                            className="bg-red-100 text-red-800 border border-red-300 rounded p-1 mb-2"
                        >
                            {error?.message}
                        </div>
                    ))}
                <div className="flex w-full h-full  justify-end gap-2">
                    {step === 0 ? (
                        <Button color="light" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    ) : (
                        <Button color="light" onClick={() => setStep(0)}>
                            Previous
                        </Button>
                    )}

                    {step === 0 ? (
                        <Button color="blue" onClick={() => handleNextStep()}>
                            Next
                        </Button>
                    ) : (
                        <Button color="blue" onClick={handleSubmit} disabled={isLoading}>
                            Submit
                        </Button>
                    )}
                </div>
            </div>
        </GeneralDrawer>
    );
};
