import { ICustomer } from 'pages/customers/customer.types';
import axios from 'utils/axios';
import { getBearerToken } from 'utils/helpers/auth';
import { IProduct } from 'utils/Interfaces/products.interface';
import { IPaginationData } from 'utils/Interfaces/types';

export interface IStock {
    _id: string;
    parentProduct: IProduct;
    warehouse: string;
    country: string;
    customer: string | ICustomer;
    status: number;
    statusDescription?: string;
    product: IProduct;
    quantity: number;
    incomingQuantity: number;
    availableQuantity: number;
    pickingQuantity: number;
    outboundQuantity: number;
    deliveredQuantity: number;
    packagedQuantity: number;
    boxes: {
        boxNumber: number;
        product?: IProduct;
        quantity: number;
    }[];
    externalOrderId?: string;
    trackingCode?: string;
    lgstTrackingCode?: string;
    lastTrace: string;
    warning?: string;
    createdAt: string;
}

export type LABEL_SIZES = 'mini' | 'a4';

export const getStocks = async (data: any) => {
    const config = getBearerToken();
    const response = await axios.post<IPaginationData<IStock>>(`/stocks/filter`, data, config);
    return response;
};

export const updateDeliveryStock = async (data: any) => {
    const config = getBearerToken();
    const response = await axios.put(`/stocks/update/${data.id}`, data.updatedData, config);
    return response;
};

export const getLabels = async (data: { shipmentId: string; size: LABEL_SIZES }) => {
    const config = getBearerToken();
    const response = await axios.post<Buffer>(
        `/stocks/getLabels/${data.shipmentId}`,
        {
            size: data.size,
        },
        { ...config, responseType: 'arraybuffer' },
    );
    return response;
};
export const getStocksProducts = async () => {
    const config = await getBearerToken();
    const response = await axios.get<IProduct[]>(`/stocks/products`, config);
    return response;
};
