/* eslint-disable react/style-prop-object */
/* eslint-disable no-underscore-dangle */
import { Iinvoice } from 'pages/invoices/invoice.types';
import React from 'react';

import toast from 'react-hot-toast';
import { AiFillPrinter } from 'react-icons/ai';
import { getInvoicePdf } from 'services/admin/invoice';

const PrintInvoice: React.FC<{ invoice: Iinvoice }> = ({ invoice }) => {
    const downloadInvoice = async (invoiceId: string) => {
        const downloadToast = toast.loading('Loading invoice...');
        try {
            const response = await getInvoicePdf({
                invoiceId,
                timezone: 'Asia/Shanghai',
                invoiceType: invoice.invoiceType,
            });
            toast.loading('Generating Invoice...', { id: downloadToast });

            const blob = new Blob([response.data]);
            const reader = new FileReader();
            reader.onloadend = () => {
                const downloadLink = document.createElement('a');
                downloadLink.href = reader.result as string;
                downloadLink.download = response.headers['Content-Disposition'] || `${invoice.reference}.pdf`;
                downloadLink.style.display = 'none';
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                toast.success('Invoice loaded successfully', { id: downloadToast });
            };
            reader.readAsDataURL(blob);
        } catch (e) {
            toast.error((e as any)?.response?.data?.errors?.message || 'Unable to load invoice', { id: downloadToast });
        }
    };

    return (
        <>
            <div>
                <AiFillPrinter className="w-5 h-5 cursor-pointer" onClick={() => downloadInvoice(invoice._id!)} />
            </div>
        </>
    );
};

export default PrintInvoice;
