import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { IStore } from 'services/admin/store';
import LinkIcon from '@mui/icons-material/Link';
import { Container, titleBoxStyle } from '../dashboard/dashboard.styles';

interface ISingleStoreProps {
    storeData: IStore;
}

interface IListItem {
    label: string;
    value: string;
    type?: 'link' | 'boolean';
}

const ListItem: React.FC<IListItem> = ({ label, value, type }) => {
    return (
        <Box style={{ padding: 10 }}>
            <p style={{ fontSize: 14, fontWeight: 500, color: '#7e8cab' }}>{label}</p>
            <div
                style={{
                    margin: '10px 24px 0 0',
                    padding: '12px 35px 12px 12px',
                    width: 'fit-content',
                    minWidth: 250,
                    borderRadius: 8,
                    border: 'solid 1px #578aff',
                    fontSize: 14,
                    fontWeight: 500,
                    color: '#578aff',
                }}
            >
                {!type && <p>{value}</p>}

                {type && type === 'link' && (
                    <a
                        href={value}
                        style={{
                            all: 'unset',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            gap: 5,
                            cursor: 'pointer',
                        }}
                        title={value}
                    >
                        <LinkIcon />
                        {value.length > 66 ? `${value.substring(0, 66)}...` : value}
                    </a>
                )}
                {type && type === 'boolean' && (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 5 }}>
                        <div
                            style={{
                                width: 10,
                                height: 10,
                                borderRadius: 30,
                                backgroundColor: `${value ? '#93c66e' : '#ff7777'}`,
                            }}
                        />
                        <p>{value ? 'Yes' : 'No'}</p>
                    </div>
                )}
            </div>
        </Box>
    );
};

const SingleStore: React.FC<ISingleStoreProps> = ({ storeData }) => {
    return (
        <Container>
            <Box sx={titleBoxStyle}>
                <Typography variant="h4">Store info</Typography>
            </Box>
            <Divider />
            <Box
                style={{
                    padding: '10px 0px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                }}
            >
                <ListItem label="Store Name" value={storeData.storeName} />
                <ListItem label="Store Type" value={storeData.storeType} />
                <ListItem label="Store Link" value={storeData.storeLink} type="link" />
            </Box>
            <Divider />{' '}
            <Box
                style={{
                    padding: '10px 0px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                }}
            >
                <ListItem label="Delevery Level" value={storeData.deliveryLevels} />
            </Box>
            <Divider />
            <Box
                style={{
                    padding: '10px 0px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                }}
            >
                <ListItem label="Fulfillment Processing" value={storeData.fulfillmentProcessing} />
                <ListItem label="Partially Fulfilled Processing" value={storeData.partiallyFulfilledProcessing} />
            </Box>
            <Divider />
            <Box
                style={{
                    padding: '10px 0px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                }}
            >
                <ListItem label="Riscky Order Processing" value={storeData.riskyOrderProcessing} type="boolean" />
            </Box>
        </Container>
    );
};

export default SingleStore;
