/* eslint-disable react/jsx-fragments */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
import CustomBtn from 'components/customButton/customBtn';
import CustomError from 'components/customError/CustomError';
import { Button, Card, Select, TextInput } from 'flowbite-react';
import { FieldArray, FieldArrayRenderProps, Form, Formik } from 'formik';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { AiOutlineDelete } from 'react-icons/ai';
import Zoom from 'react-medium-image-zoom';
import { getOrders, IItems, IOrder, updateOrderItems } from 'services/admin/order';
import { IProduct } from 'utils/Interfaces/products.interface';
import { UpdateOrderProduct } from 'utils/validations/ordersValidations';
import { v4 as uuidv4 } from 'uuid';
import Package from '../../../../assets/package.png';

const UpdateItem: React.FC<{
    similarProducts: IProduct[];
    data: IOrder;
    setRows: React.Dispatch<React.SetStateAction<IOrder[]>>;
    currentPage: number;
    setUpdateList: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ similarProducts, data, setRows, currentPage, setUpdateList }) => {
    let ConfiguredProducts: any[] = [];

    const productExist = (product: string) => {
        const existance = ConfiguredProducts.some((pr) => {
            return pr?._id === product || pr === product;
        });
        return existance;
    };
    const handleChange = (arrayHelpers: any) => {
        ConfiguredProducts = arrayHelpers;
    };

    const itemSelectList = (idx: number) => {
        const itemList: any = [];
        if (similarProducts?.length > 0) {
            similarProducts
                ?.sort((a: IProduct, b: IProduct) => {
                    const customA = a.itemKind === 'variant' ? a?.parentProduct?.name : a.name;
                    const customB = b.itemKind === 'variant' ? b?.parentProduct?.name : b.name;
                    return customA?.localeCompare(customB);
                })
                ?.forEach((prdt) => {
                    if (
                        ConfiguredProducts[idx]?.product?._id === prdt?._id ||
                        ConfiguredProducts[idx]?.product === prdt?._id ||
                        !productExist(prdt?._id)
                    ) {
                        itemList.push(
                            <option key={uuidv4()} value={prdt._id}>
                                {`${prdt?.parentProduct?.name || ''} ${prdt?.name || ''}`}
                            </option>,
                        );
                    }
                });
        }
        return itemList;
    };

    const [availableProductsLoader, setAvailableProductLoader] = useState(false);
    const updateOrderInformations = (values: any) => {
        setAvailableProductLoader(true);
        updateOrderItems({ id: data._id, ...values })
            .then((res) => {
                toast.success(res.data.message);
                setAvailableProductLoader(false);
            })
            .then(() => {
                getOrders({ page: currentPage })
                    .then((orderData) => {
                        setRows(orderData.data.docs);
                        setUpdateList(false);
                        setAvailableProductLoader(false);
                    })
                    .catch(() => {
                        toast.error(`Something went wrong, please try again`);
                        setAvailableProductLoader(false);
                    });
            })
            .catch((err) => {
                toast.error(err.response.data.errors.message);
            });
    };

    const addProduct = (arrayHelpers: FieldArrayRenderProps) => {
        arrayHelpers.push({ quantity: 0, totalQuantity: 0, key: uuidv4() });
        ConfiguredProducts = arrayHelpers.form.values.items;
    };

    return (
        <>
            <Formik
                initialValues={{
                    items:
                        (data.items &&
                            data.items.map<IItems & { totalQuantity?: number; key?: string }>((item) => ({
                                key: uuidv4(),
                                ...item,
                                totalQuantity: Number(item.quantity) + Number(item.upsellQuantity || 0),
                            }))) ||
                        [],
                }}
                onSubmit={(values, onSubmitProps) => {
                    const updatedData = values.items.map((item, idx) => {
                        const draftItem = { ...item };
                        // in case the item does not exist in the order items list then, it's an upsell
                        if (!data.items || !data.items[idx]) {
                            draftItem.upsellQuantity = Number(item.totalQuantity);
                            draftItem.quantity = 0; // the quantity field in upsell items is 0
                            draftItem.product = (draftItem.product as IProduct)._id || (draftItem.product as string);

                            delete draftItem.key;
                            delete draftItem.totalQuantity;
                            return draftItem;
                        }
                        let originalItemId: string | null = null;
                        const product = item?.product as IProduct;
                        if (product?._id) {
                            originalItemId = product?._id;
                        }
                        const variant = item?.product as IProduct;
                        if (variant?._id) {
                            originalItemId = variant?._id;
                        }
                        draftItem.product = (draftItem.product as IProduct)._id;
                        if (originalItemId) {
                            // in case there is an upsell, get the upsell quantity
                            if (Number(item.totalQuantity) > Number(item.quantity)) {
                                draftItem.upsellQuantity = Number(item.totalQuantity! - item.quantity!);
                                draftItem.quantity = Number(item.quantity);
                                delete draftItem.totalQuantity;
                                delete draftItem.key;
                                return draftItem;
                            }

                            if (
                                Number(item.totalQuantity) <=
                                Number(item.quantity) + Number(item.upsellQuantity || 0)
                            ) {
                                // in case there is no upsell, remove the upsellQuantity field if existing
                                if (Number(item.totalQuantity) <= Number(item.quantity)) {
                                    draftItem.quantity = Number(item.totalQuantity);
                                    delete draftItem.totalQuantity;
                                    delete draftItem.key;
                                    if (draftItem.upsellQuantity || Number(draftItem.upsellQuantity) === 0) {
                                        delete draftItem.upsellQuantity;
                                    }
                                    return draftItem;
                                }
                                // in case the current item is already an upsell, update its upsell quantity
                                if (
                                    Number(item.totalQuantity) <= Number(item.upsellQuantity || 0) &&
                                    !Number(data.items[idx].quantity)
                                ) {
                                    draftItem.upsellQuantity = Number(item.totalQuantity);
                                    delete draftItem.totalQuantity;
                                    delete draftItem.key;
                                    return draftItem;
                                }

                                // in case the upsell quantity decreased, update the upsell quantity
                                draftItem.upsellQuantity = Number(item.totalQuantity) - Number(item.quantity);
                                delete draftItem.totalQuantity;
                                delete draftItem.key;
                                return draftItem;
                            }
                        }
                        delete draftItem.totalQuantity;
                        delete draftItem.key;
                        return draftItem;
                    });
                    updateOrderInformations({ ...values, items: updatedData });
                    onSubmitProps.setSubmitting(false);
                    // onSubmitProps.resetForm();
                }}
                validationSchema={UpdateOrderProduct}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit} placeholder="">
                        <div className=" flex flex-col gap-2 overflow-auto max-h-[37rem]">
                            <div>
                                <FieldArray
                                    name="items"
                                    render={(arrayHelpers) => (
                                        <>
                                            {formik.values.items?.map((item, idx) => (
                                                <Card
                                                    key={item.key}
                                                    theme={{
                                                        root: {
                                                            children: 'p-2 dark:bg-[#374151] bg-white rounded',
                                                        },
                                                    }}
                                                    className="mb-1 "
                                                >
                                                    <div
                                                        key={(item?.product as IProduct)?._id}
                                                        className="grid grid-cols-5 gap-1 items-center"
                                                    >
                                                        <div className="">
                                                            <Zoom>
                                                                <img
                                                                    src={
                                                                        (item?.product as IProduct)?.design?.[0] ??
                                                                        Package
                                                                    }
                                                                    alt="productImg"
                                                                    style={{
                                                                        objectFit: 'cover',
                                                                        borderRadius: 8,
                                                                        overflow: 'hidden',
                                                                        width: 50,
                                                                        height: 50,
                                                                    }}
                                                                />
                                                            </Zoom>
                                                        </div>
                                                        <div className="col-span-3 text-center">
                                                            {(item?.product as IProduct)?.name ? (
                                                                <div className=" grid grid-cols-3">
                                                                    <div>
                                                                        <p>
                                                                            {(item?.product as IProduct)?.parentProduct
                                                                                ?.name ||
                                                                                (item?.product as IProduct)?.name}
                                                                        </p>
                                                                    </div>
                                                                    <div>
                                                                        <p>
                                                                            {(item?.product as IProduct)?.itemKind ===
                                                                            'variant'
                                                                                ? (item?.product as IProduct)?.name
                                                                                : '-'}
                                                                        </p>
                                                                    </div>
                                                                    <div>
                                                                        <p>
                                                                            {(item?.product as IProduct)?.globalSKU
                                                                                ? (item?.product as IProduct)?.globalSKU
                                                                                : '-'}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className=" grid grid-cols-3">
                                                                    <div>
                                                                        <Select
                                                                            id="item"
                                                                            {...formik.getFieldProps(
                                                                                `items.${idx}.product`,
                                                                            )}
                                                                            helperText={
                                                                                <React.Fragment>
                                                                                    <CustomError
                                                                                        name={`items.${idx}.product`}
                                                                                        component="div"
                                                                                    />
                                                                                </React.Fragment>
                                                                            }
                                                                        >
                                                                            <option key="null val" value="">
                                                                                Select product
                                                                            </option>
                                                                            {handleChange(
                                                                                arrayHelpers.form.values.items,
                                                                            )}
                                                                            {itemSelectList(idx)}
                                                                        </Select>
                                                                    </div>
                                                                    <div />

                                                                    <div />
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="flex items-center gap-1 ">
                                                            <div className="flex flex-col gap-1">
                                                                <TextInput
                                                                    type="text"
                                                                    {...formik.getFieldProps(
                                                                        `items.${idx}.totalQuantity`,
                                                                    )}
                                                                    name={`items.${idx}.totalQuantity`}
                                                                />
                                                                <CustomError
                                                                    name={`items.${idx}.totalQuantity`}
                                                                    component="div"
                                                                />
                                                            </div>
                                                            <div className="">
                                                                {formik.values.items.length === 1 ? (
                                                                    ''
                                                                ) : (
                                                                    <div
                                                                        key={idx}
                                                                        className="flex gap-1 items-center mb-2"
                                                                    >
                                                                        <AiOutlineDelete
                                                                            className="h-6 w-6 text-red-500 cursor-pointer"
                                                                            onClick={() => {
                                                                                arrayHelpers.remove(idx);
                                                                                ConfiguredProducts =
                                                                                    arrayHelpers.form.values.items;
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            ))}
                                            <div
                                                className={`flex items-center ${
                                                    formik.values.items.length === 1 ? 'justify-between' : 'justify-end'
                                                } `}
                                            >
                                                {formik.values.items.length === 1 ? (
                                                    <div className="text-red-500">
                                                        WARNING: Minimum 1 item per order
                                                    </div>
                                                ) : (
                                                    ''
                                                )}

                                                <div className="flex  items-center rounded-b-lg mt-3 gap-5">
                                                    <div title="Add Product">
                                                        <Button
                                                            color="success"
                                                            onClick={() => {
                                                                addProduct(arrayHelpers);
                                                            }}
                                                        >
                                                            +
                                                        </Button>
                                                    </div>
                                                    <CustomBtn
                                                        variant="primary"
                                                        type="submit"
                                                        disabled={
                                                            !formik.isValid ||
                                                            !formik.dirty ||
                                                            formik.isSubmitting ||
                                                            availableProductsLoader
                                                        }
                                                        isProcessing={availableProductsLoader}
                                                    >
                                                        Save
                                                    </CustomBtn>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UpdateItem;
