/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-fragments */
import CustomBtn from 'components/customButton/customBtn';
import CustomError from 'components/customError/CustomError';
import { Accordion, Card, Label, Modal, Select, TextInput } from 'flowbite-react';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { countries } from 'utils/countryList';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { ICodNoCCPricing, getConfiguration, updateConfigItem } from 'services/admin/configuration';
import { UpdatePricingCodNoCC } from 'utils/validations/pricingConfiguration';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { accordContent, accordTitle } from 'pages/configurationAdmin/configuration.styles';
import { IPricingProps } from '../pricingConfiguration.types';

const CountriesAccordion: React.FC<IPricingProps> = ({ pricing, configId, setConfigPricing }) => {
    const [open, setOpen] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(0);

    const [updateLoader, setUpdateLoader] = useState(false);

    const updatePricing = (values: ICodNoCCPricing, id: string, indexItem: number) => {
        setUpdateLoader(true);
        const newValues = pricing;
        newValues.codNoCC[indexItem] = values;
        updateConfigItem(id, { pricing: newValues })
            .then((res) => {
                toast.success(res.data.message);
                setUpdateLoader(false);
                getConfiguration().then((result) => {
                    setConfigPricing(result.data?.pricing);
                });
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message);
                setUpdateLoader(false);
            });
    };

    const deletePricing = (index: number) => {
        const toastLoader = toast.loading('Loading...');
        const newValues = pricing;
        newValues?.codNoCC.splice(index, 1);
        updateConfigItem(configId, { pricing: newValues })
            .then(() => {
                toast.success('Country configuration deleted', { id: toastLoader });
                getConfiguration().then((result) => {
                    setConfigPricing(result.data.pricing);
                });
            })
            .catch((err) => toast.error(err?.response?.data?.errors?.message, { id: toastLoader }));
        setOpen(false);
    };

    return (
        <>
            {pricing.codNoCC && (
                <>
                    <Modal show={open} size="md" popup onClose={() => setOpen(false)}>
                        <Modal.Header />
                        <Modal.Body>
                            <div className="text-center">
                                <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                                <p className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                    Are you sure you want to delete this country pricing ?
                                    {pricing?.codNoCC[deleteIndex]?.country}
                                </p>
                                <div className="flex justify-center gap-4">
                                    <CustomBtn variant="tertiary" onClick={() => setOpen(false)}>
                                        No, cancel
                                    </CustomBtn>
                                    <CustomBtn
                                        variant="failure"
                                        onClick={() => {
                                            deletePricing(deleteIndex);
                                        }}
                                    >
                                        Yes, am sure
                                    </CustomBtn>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Accordion collapseAll alwaysOpen flush>
                        {pricing?.codNoCC.map((accord) => (
                            <Accordion.Panel key={accord._id} className="!ring-0 focus:!ring-0 focus:!ring-transparent">
                                <Accordion.Title as="h4" theme={accordTitle}>
                                    {accord.country}
                                </Accordion.Title>
                                <Accordion.Content theme={accordContent}>
                                    <Formik
                                        initialValues={{
                                            _id: accord._id,
                                            country: accord.country,
                                            deliveredLeadPrice: accord.deliveredLeadPrice,
                                            shippedLeadPrice: accord.shippedLeadPrice,
                                            percentFromSellPrice: accord.percentFromSellPrice,
                                            handleFees: accord.handleFees,
                                        }}
                                        onSubmit={(values, onSubmitProps) => {
                                            updatePricing(values, configId, pricing?.codNoCC.indexOf(accord));
                                            onSubmitProps.setSubmitting(false);
                                        }}
                                        validationSchema={UpdatePricingCodNoCC}
                                    >
                                        {(formik) => (
                                            <Form onSubmit={formik.handleSubmit} placeholder="">
                                                <div>
                                                    <div>
                                                        <Card
                                                            theme={{
                                                                root: {
                                                                    base: 'flex rounded-lg border border-gray-200 bg-white  dark:border-gray-700 dark:bg-transparent p-6  gap-x-4',
                                                                    children:
                                                                        'flex h-full flex-col justify-center gap-4 p-2',
                                                                },
                                                            }}
                                                        >
                                                            <div>
                                                                <div className="mb-2 block">
                                                                    <Label htmlFor="country" value="Country" />
                                                                </div>
                                                                <Select
                                                                    id="countries"
                                                                    required
                                                                    {...formik.getFieldProps('country')}
                                                                    name="country"
                                                                    helperText={
                                                                        <React.Fragment>
                                                                            <CustomError
                                                                                name="country"
                                                                                component="div"
                                                                            />
                                                                        </React.Fragment>
                                                                    }
                                                                >
                                                                    {countries.map((country) => (
                                                                        <option
                                                                            key={`${country.code}`}
                                                                            value={country.label}
                                                                        >
                                                                            {country.label}
                                                                        </option>
                                                                    ))}
                                                                </Select>
                                                            </div>
                                                            <div className="grid md:grid-cols-2 gap-2">
                                                                <div>
                                                                    <div className="mb-2 block">
                                                                        <Label
                                                                            htmlFor="price3"
                                                                            className="dark:text-white"
                                                                            color={
                                                                                formik.touched.deliveredLeadPrice &&
                                                                                formik.errors.deliveredLeadPrice
                                                                                    ? 'failure'
                                                                                    : ''
                                                                            }
                                                                            value="Shipped & delivered"
                                                                        />
                                                                    </div>
                                                                    <TextInput
                                                                        {...formik.getFieldProps('deliveredLeadPrice')}
                                                                        name="deliveredLeadPrice"
                                                                        color={
                                                                            formik.touched.deliveredLeadPrice &&
                                                                            formik.errors.deliveredLeadPrice
                                                                                ? 'failure'
                                                                                : 'gray'
                                                                        }
                                                                        type="text"
                                                                        helperText={
                                                                            <React.Fragment>
                                                                                <CustomError
                                                                                    name="deliveredLeadPrice"
                                                                                    component="div"
                                                                                />
                                                                            </React.Fragment>
                                                                        }
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <div className="mb-2 block">
                                                                        <Label
                                                                            htmlFor="price4"
                                                                            className="dark:text-white"
                                                                            color={
                                                                                formik.errors.shippedLeadPrice &&
                                                                                formik.touched.shippedLeadPrice
                                                                                    ? 'failure'
                                                                                    : ''
                                                                            }
                                                                            value="Shipped & Returned"
                                                                        />
                                                                    </div>
                                                                    <TextInput
                                                                        {...formik.getFieldProps('shippedLeadPrice')}
                                                                        name="shippedLeadPrice"
                                                                        type="text"
                                                                        color={
                                                                            formik.errors.shippedLeadPrice &&
                                                                            formik.touched.shippedLeadPrice
                                                                                ? 'failure'
                                                                                : 'gray'
                                                                        }
                                                                        helperText={
                                                                            <React.Fragment>
                                                                                <CustomError
                                                                                    name="shippedLeadPrice"
                                                                                    component="div"
                                                                                />
                                                                            </React.Fragment>
                                                                        }
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <div className="mb-2 block">
                                                                        <Label
                                                                            htmlFor="price5"
                                                                            className="dark:text-white"
                                                                            color={
                                                                                formik.errors.percentFromSellPrice &&
                                                                                formik.touched.percentFromSellPrice
                                                                                    ? 'failure'
                                                                                    : ''
                                                                            }
                                                                            value="COD Percentage"
                                                                        />
                                                                    </div>
                                                                    <TextInput
                                                                        {...formik.getFieldProps(
                                                                            'percentFromSellPrice',
                                                                        )}
                                                                        name="percentFromSellPrice"
                                                                        type="text"
                                                                        color={
                                                                            formik.errors.percentFromSellPrice &&
                                                                            formik.touched.percentFromSellPrice
                                                                                ? 'failure'
                                                                                : 'gray'
                                                                        }
                                                                        helperText={
                                                                            <React.Fragment>
                                                                                <CustomError
                                                                                    name="percentFromSellPrice"
                                                                                    component="div"
                                                                                />
                                                                            </React.Fragment>
                                                                        }
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <div className="mb-2 block">
                                                                        <Label
                                                                            htmlFor="handleFees"
                                                                            className="dark:text-white"
                                                                            color={
                                                                                formik.touched.handleFees &&
                                                                                formik.errors.handleFees
                                                                                    ? 'failure'
                                                                                    : ''
                                                                            }
                                                                            value="Handle Fees"
                                                                        />
                                                                    </div>
                                                                    <TextInput
                                                                        {...formik.getFieldProps('handleFees')}
                                                                        name="handleFees"
                                                                        // placeholder="jhondoe@email.com"
                                                                        color={
                                                                            formik.touched.handleFees &&
                                                                            formik.errors.handleFees
                                                                                ? 'failure'
                                                                                : 'gray'
                                                                        }
                                                                        type="text"
                                                                        helperText={
                                                                            <React.Fragment>
                                                                                <CustomError
                                                                                    name="handleFees"
                                                                                    component="div"
                                                                                />
                                                                            </React.Fragment>
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="flex justify-end gap-2">
                                                                <CustomBtn
                                                                    variant="failure"
                                                                    pill
                                                                    onClick={() => {
                                                                        setOpen(true);
                                                                        setDeleteIndex(
                                                                            pricing?.codNoCC.indexOf(accord),
                                                                        );
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <AiFillDelete className="mr-2 h-5 w-5" />
                                                                    Delete
                                                                </CustomBtn>
                                                                <CustomBtn
                                                                    variant="secondary"
                                                                    type="submit"
                                                                    pill
                                                                    disabled={
                                                                        !formik.isValid ||
                                                                        !formik.dirty ||
                                                                        formik.isSubmitting ||
                                                                        updateLoader
                                                                    }
                                                                    isProcessing={updateLoader}
                                                                >
                                                                    <AiFillEdit className="mr-2 h-5 w-5" /> Update
                                                                </CustomBtn>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </Accordion.Content>
                            </Accordion.Panel>
                        ))}
                    </Accordion>
                </>
            )}
        </>
    );
};

export default CountriesAccordion;
