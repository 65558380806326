/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-fragments */
import CustomBtn from 'components/customButton/customBtn';
import CustomError from 'components/customError/CustomError';
import { Accordion, Card, Label, Modal, Select, TextInput } from 'flowbite-react';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { countries } from 'utils/countryList';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { ICodPricingItem, getConfiguration, updateConfigItem } from 'services/admin/configuration';
import { UpdatePricingConfig } from 'utils/validations/pricingConfiguration';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { accordContent, accordTitle } from 'pages/configurationAdmin/configuration.styles';
import { EditConfig } from '../pricingConfiguration.types';

const CountriesAccordion: React.FC<EditConfig> = ({ pricing, configId, setConfigPricing }) => {
    const [open, setOpen] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(0);

    const [updateLoader, setUpdateLoader] = useState(false);

    const updatePricing = (values: ICodPricingItem, id: string, indexItem: number) => {
        setUpdateLoader(true);
        const newValues = pricing;
        newValues.codWithCC[indexItem] = values;
        updateConfigItem(id, { pricing: newValues })
            .then((res) => {
                toast.success(res.data.message);
                setUpdateLoader(false);
                getConfiguration().then((result) => {
                    setConfigPricing(result.data?.pricing);
                });
            })
            .catch((err) => {
                toast.error(err.response.data.errors.message);
                setUpdateLoader(false);
            });
    };

    const deletePricing = (index: number) => {
        const loadingToast = toast.loading('Loading...');

        const newValues = pricing;
        newValues?.codWithCC.splice(index, 1);
        updateConfigItem(configId, { pricing: newValues })
            .then(() => {
                toast.success('Country configuration deleted', { id: loadingToast });
                getConfiguration().then((result) => {
                    setConfigPricing(result.data.pricing);
                });
            })
            .catch((err) => toast.error(err?.response?.data?.errors?.message, { id: loadingToast }));
        setOpen(false);
    };
    return (
        <div className="p-2">
            {pricing.codWithCC && (
                <>
                    <Modal show={open} size="md" popup onClose={() => setOpen(false)}>
                        <Modal.Header />
                        <Modal.Body>
                            <div className="text-center">
                                <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                                <p className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                    Are you sure you want to delete this country pricing ?
                                    {pricing?.codWithCC[deleteIndex]?.country}
                                </p>
                                <div className="flex justify-center gap-4">
                                    <CustomBtn variant="tertiary" onClick={() => setOpen(false)}>
                                        No, cancel
                                    </CustomBtn>
                                    <CustomBtn
                                        variant="failure"
                                        onClick={() => {
                                            deletePricing(deleteIndex);
                                        }}
                                    >
                                        Yes, am sure
                                    </CustomBtn>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Card
                        theme={{
                            root: {
                                base: 'flex rounded-lg border border-gray-200 bg-white  dark:border-gray-700 dark:bg-transparent',
                                children: 'flex h-full flex-col justify-center gap-4 p-2',
                            },
                        }}
                    >
                        <Accordion collapseAll alwaysOpen flush>
                            {pricing?.codWithCC.map((accord) => (
                                <Accordion.Panel key={accord._id}>
                                    <Accordion.Title as="h4" theme={accordTitle}>
                                        {accord.country}
                                    </Accordion.Title>
                                    <Accordion.Content theme={accordContent}>
                                        <Formik
                                            initialValues={{
                                                _id: accord._id,
                                                country: accord.country,
                                                newLeadPrice: accord.newLeadPrice,
                                                confirmedLeadPrice: accord.confirmedLeadPrice,
                                                deliveredLeadPrice: accord.deliveredLeadPrice,
                                                shippedLeadPrice: accord.shippedLeadPrice,
                                                percentFromSellPrice: accord.percentFromSellPrice,
                                                deliveredPriceforCC: accord.deliveredPriceforCC,
                                                upDownCross: accord.upDownCross,
                                            }}
                                            onSubmit={(values, onSubmitProps) => {
                                                updatePricing(values, configId, pricing?.codWithCC.indexOf(accord));
                                                onSubmitProps.setSubmitting(false);
                                            }}
                                            validationSchema={UpdatePricingConfig}
                                        >
                                            {(formik) => (
                                                <Form onSubmit={formik.handleSubmit} placeholder="">
                                                    <div className=" grid md:grid-cols-2 gap-x-4 ">
                                                        <Card
                                                            theme={{
                                                                root: {
                                                                    base: 'flex rounded-lg border border-gray-200 bg-white dark:border-gray-700 dark:bg-transparent',
                                                                    children:
                                                                        'flex h-full flex-col justify-center gap-4 p-2',
                                                                },
                                                            }}
                                                        >
                                                            <h5 className="text-xl font-medium text-gray-500 dark:text-gray-400">
                                                                Call center leads costs
                                                            </h5>
                                                            <div>
                                                                <div className="mb-2 block">
                                                                    <Label
                                                                        htmlFor="price1"
                                                                        className="dark:text-white"
                                                                        color={
                                                                            formik.touched.newLeadPrice &&
                                                                            formik.errors.newLeadPrice
                                                                                ? 'failure'
                                                                                : ''
                                                                        }
                                                                        value="Entered"
                                                                    />
                                                                </div>
                                                                <TextInput
                                                                    {...formik.getFieldProps('newLeadPrice')}
                                                                    name="newLeadPrice"
                                                                    // placeholder="jhondoe@email.com"
                                                                    color={
                                                                        formik.touched.newLeadPrice &&
                                                                        formik.errors.newLeadPrice
                                                                            ? 'failure'
                                                                            : 'gray'
                                                                    }
                                                                    type="text"
                                                                    helperText={
                                                                        <React.Fragment>
                                                                            <CustomError
                                                                                name="newLeadPrice"
                                                                                component="div"
                                                                            />
                                                                        </React.Fragment>
                                                                    }
                                                                />
                                                            </div>
                                                            <div>
                                                                <div className="mb-2 block">
                                                                    <Label
                                                                        htmlFor="price2"
                                                                        className="dark:text-white"
                                                                        color={
                                                                            formik.touched.confirmedLeadPrice &&
                                                                            formik.errors.confirmedLeadPrice
                                                                                ? 'failure'
                                                                                : ''
                                                                        }
                                                                        value="Confirmed"
                                                                    />
                                                                </div>
                                                                <TextInput
                                                                    {...formik.getFieldProps('confirmedLeadPrice')}
                                                                    name="confirmedLeadPrice"
                                                                    color={
                                                                        formik.touched.confirmedLeadPrice &&
                                                                        formik.errors.confirmedLeadPrice
                                                                            ? 'failure'
                                                                            : 'gray'
                                                                    }
                                                                    type="text"
                                                                    helperText={
                                                                        <React.Fragment>
                                                                            <CustomError
                                                                                name="confirmedLeadPrice"
                                                                                component="div"
                                                                            />
                                                                        </React.Fragment>
                                                                    }
                                                                />
                                                            </div>
                                                            <div>
                                                                <div className="mb-2 block">
                                                                    <Label
                                                                        htmlFor="price4"
                                                                        className="dark:text-white"
                                                                        color={
                                                                            formik.errors.deliveredPriceforCC &&
                                                                            formik.touched.deliveredPriceforCC
                                                                                ? 'failure'
                                                                                : ''
                                                                        }
                                                                        value="Delivered"
                                                                    />
                                                                </div>
                                                                <TextInput
                                                                    {...formik.getFieldProps('deliveredPriceforCC')}
                                                                    name="deliveredPriceforCC"
                                                                    type="text"
                                                                    color={
                                                                        formik.errors.deliveredPriceforCC &&
                                                                        formik.touched.deliveredPriceforCC
                                                                            ? 'failure'
                                                                            : 'gray'
                                                                    }
                                                                    helperText={
                                                                        <React.Fragment>
                                                                            <CustomError
                                                                                name="deliveredPriceforCC"
                                                                                component="div"
                                                                            />
                                                                        </React.Fragment>
                                                                    }
                                                                />
                                                            </div>
                                                        </Card>
                                                        <div>
                                                            <Card
                                                                theme={{
                                                                    root: {
                                                                        base: 'flex rounded-lg border border-gray-200 bg-white  dark:border-gray-700 dark:bg-transparent',
                                                                        children:
                                                                            'flex h-full flex-col justify-center gap-4 p-2',
                                                                    },
                                                                }}
                                                            >
                                                                <h5 className="text-xl font-medium text-gray-500 dark:text-gray-400">
                                                                    Orders Costs
                                                                </h5>
                                                                <div>
                                                                    <div className="mb-2 block">
                                                                        <Label
                                                                            htmlFor="price3"
                                                                            className="dark:text-white"
                                                                            color={
                                                                                formik.touched.deliveredLeadPrice &&
                                                                                formik.errors.deliveredLeadPrice
                                                                                    ? 'failure'
                                                                                    : ''
                                                                            }
                                                                            value="Shipped & delivered"
                                                                        />
                                                                    </div>
                                                                    <TextInput
                                                                        {...formik.getFieldProps('deliveredLeadPrice')}
                                                                        name="deliveredLeadPrice"
                                                                        color={
                                                                            formik.touched.deliveredLeadPrice &&
                                                                            formik.errors.deliveredLeadPrice
                                                                                ? 'failure'
                                                                                : 'gray'
                                                                        }
                                                                        type="text"
                                                                        helperText={
                                                                            <React.Fragment>
                                                                                <CustomError
                                                                                    name="deliveredLeadPrice"
                                                                                    component="div"
                                                                                />
                                                                            </React.Fragment>
                                                                        }
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <div className="mb-2 block">
                                                                        <Label
                                                                            htmlFor="price4"
                                                                            className="dark:text-white"
                                                                            color={
                                                                                formik.errors.shippedLeadPrice &&
                                                                                formik.touched.shippedLeadPrice
                                                                                    ? 'failure'
                                                                                    : ''
                                                                            }
                                                                            value="Shipped & Returned"
                                                                        />
                                                                    </div>
                                                                    <TextInput
                                                                        {...formik.getFieldProps('shippedLeadPrice')}
                                                                        name="shippedLeadPrice"
                                                                        type="text"
                                                                        color={
                                                                            formik.errors.shippedLeadPrice &&
                                                                            formik.touched.shippedLeadPrice
                                                                                ? 'failure'
                                                                                : 'gray'
                                                                        }
                                                                        helperText={
                                                                            <React.Fragment>
                                                                                <CustomError
                                                                                    name="shippedLeadPrice"
                                                                                    component="div"
                                                                                />
                                                                            </React.Fragment>
                                                                        }
                                                                    />
                                                                </div>
                                                            </Card>
                                                            <div className="pt-1 px-2">
                                                                <div className="mb-2 block">
                                                                    <Label
                                                                        htmlFor="price5"
                                                                        className="dark:text-white"
                                                                        color={
                                                                            formik.errors.percentFromSellPrice &&
                                                                            formik.touched.percentFromSellPrice
                                                                                ? 'failure'
                                                                                : ''
                                                                        }
                                                                        value="COD Percentage"
                                                                    />
                                                                </div>
                                                                <TextInput
                                                                    {...formik.getFieldProps('percentFromSellPrice')}
                                                                    name="percentFromSellPrice"
                                                                    type="text"
                                                                    color={
                                                                        formik.errors.percentFromSellPrice &&
                                                                        formik.touched.percentFromSellPrice
                                                                            ? 'failure'
                                                                            : 'gray'
                                                                    }
                                                                    helperText={
                                                                        <React.Fragment>
                                                                            <CustomError
                                                                                name="percentFromSellPrice"
                                                                                component="div"
                                                                            />
                                                                        </React.Fragment>
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="px-2 pt-3">
                                                            <div className="mb-2 block">
                                                                <Label htmlFor="country" value="Country" />
                                                            </div>
                                                            <Select
                                                                id="countries"
                                                                required
                                                                {...formik.getFieldProps('country')}
                                                                name="country"
                                                                helperText={
                                                                    <React.Fragment>
                                                                        <CustomError name="country" component="div" />
                                                                    </React.Fragment>
                                                                }
                                                            >
                                                                {countries.map((country) => (
                                                                    <option
                                                                        key={`${country.code}`}
                                                                        value={country.label}
                                                                    >
                                                                        {country.label}
                                                                    </option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                        <div className="px-2 pt-3">
                                                            <div className="mb-2 block">
                                                                <Label
                                                                    htmlFor="upDownCross"
                                                                    className="dark:text-white"
                                                                    color={
                                                                        formik.touched.upDownCross &&
                                                                        formik.errors.upDownCross
                                                                            ? 'failure'
                                                                            : ''
                                                                    }
                                                                    value="Up Down Cross sell"
                                                                />
                                                            </div>
                                                            <TextInput
                                                                {...formik.getFieldProps('upDownCross')}
                                                                name="upDownCross"
                                                                // placeholder="jhondoe@email.com"
                                                                color={
                                                                    formik.touched.upDownCross &&
                                                                    formik.errors.upDownCross
                                                                        ? 'failure'
                                                                        : 'gray'
                                                                }
                                                                type="text"
                                                                helperText={
                                                                    <React.Fragment>
                                                                        <CustomError
                                                                            name="upDownCross"
                                                                            component="div"
                                                                        />
                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-end gap-2">
                                                        <CustomBtn
                                                            variant="failure"
                                                            pill
                                                            onClick={() => {
                                                                setOpen(true);
                                                                setDeleteIndex(pricing?.codWithCC.indexOf(accord));
                                                            }}
                                                        >
                                                            <AiFillDelete className="mr-2 h-5 w-5" />
                                                            Delete
                                                        </CustomBtn>
                                                        <CustomBtn
                                                            variant="secondary"
                                                            type="submit"
                                                            pill
                                                            disabled={
                                                                !formik.isValid ||
                                                                !formik.dirty ||
                                                                formik.isSubmitting ||
                                                                updateLoader
                                                            }
                                                            isProcessing={updateLoader}
                                                        >
                                                            <AiFillEdit className="mr-2 h-5 w-5" /> Update
                                                        </CustomBtn>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </Accordion.Content>
                                </Accordion.Panel>
                            ))}
                        </Accordion>
                    </Card>
                </>
            )}
        </div>
    );
};

export default CountriesAccordion;
