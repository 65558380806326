/* eslint-disable no-underscore-dangle */
import { Badge, Timeline } from 'flowbite-react';
import moment from 'moment';
import { statusDisplay } from 'pages/orders/orderElements';
import React, { useEffect, useState } from 'react';
import { FaMoneyBill } from 'react-icons/fa';
import { getHistory } from 'services/accountManager/credits';
import { currencyConverter } from 'utils/currencyConverter';
import { HistoryProps } from './credits.types';

export const CreditHistory: React.FC<HistoryProps> = ({ credit }) => {
    const [historyCredit, setHistoryCredit]: any[] = useState([]);
    const [Currency] = useState('USD');
    useEffect(() => {
        getHistory(credit?._id).then((data) => {
            setHistoryCredit(data?.data);
        });
    }, []);
    return (
        <div>
            {historyCredit.length > 0 ? (
                <div className="overflow-y-auto relative h-96 w-full p-4">
                    <Timeline>
                        {historyCredit.map((histo: any) => {
                            return (
                                <Timeline.Item>
                                    <Timeline.Point icon={FaMoneyBill} />
                                    <Timeline.Content>
                                        <Timeline.Time>
                                            {moment(histo?.createdAt).format('DD MMMM YYYY , HH:mm:ss')}
                                        </Timeline.Time>
                                        <Timeline.Title>
                                            <div className="flex flex-wrap gap-2">
                                                {histo?.newStatus && statusDisplay[histo?.newStatus].statusIcon}
                                            </div>
                                        </Timeline.Title>
                                        <Timeline.Body>
                                            <div className="flex flex-wrap gap-2">
                                                {`Credit Amount paid ${currencyConverter(
                                                    Number(histo?.amount),
                                                    Currency,
                                                )}`}

                                                {}
                                            </div>
                                        </Timeline.Body>
                                    </Timeline.Content>
                                </Timeline.Item>
                            );
                        })}
                    </Timeline>
                </div>
            ) : (
                <div className="flex justify-center items-center h-[10rem]">
                    <Badge color="gray">No Operations for current credit</Badge>
                </div>
            )}
        </div>
    );
};
export default CreditHistory;
