/* eslint-disable react/jsx-fragments */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import CustomError from 'components/customError/CustomError';
import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import React, { useState } from 'react';
import { Label, TextInput } from 'flowbite-react';
import CustomBtn from 'components/customButton/customBtn';
import { HiMail } from 'react-icons/hi';
import PhoneInput from 'react-phone-number-input';
import '../../../utils/helpers/phoneInput.css';
import { getCustomers, updateCustomer } from 'services/admin/customer';
import { changeCustomerInfos } from 'utils/validations/personalInformationValidation';
import CustomerPassword from './customerPassword';
import { ICustomerActions } from '../customer.types';

const CustomerPersonalInfo: React.FC<ICustomerActions> = ({ customer, setRows, currentPage, currentFilter }) => {
    const [isLoading, setIsLoading] = useState(false);

    const personalInfo = (values: any) => {
        setIsLoading(true);
        updateCustomer(customer._id, values)
            .then((res) => {
                toast.success(res?.data?.message);
                setIsLoading(false);
            })
            .then(() => {
                getCustomers({ page: currentPage, ...currentFilter }).then((res) => {
                    setRows(res.data.docs);
                });
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message);
                setIsLoading(false);
            });
    };

    return (
        <>
            <Formik
                initialValues={{
                    firstName: customer.firstName,
                    lastName: customer.lastName,
                    phone: customer.phone,
                    email: customer.email,
                }}
                onSubmit={(values) => {
                    personalInfo(values);
                }}
                validationSchema={changeCustomerInfos}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit} placeholder="">
                        <div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="username4"
                                        className="dark:text-white"
                                        color={formik.errors.firstName ? 'failure' : ''}
                                        value="First name"
                                    />
                                </div>
                                <TextInput
                                    {...formik.getFieldProps('firstName')}
                                    name="firstName"
                                    color={formik.errors.firstName ? 'failure' : 'gray'}
                                    type="text"
                                    helperText={
                                        <React.Fragment>
                                            <CustomError name="firstName" component="div" />
                                        </React.Fragment>
                                    }
                                />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="username4"
                                        className="dark:text-white"
                                        color={formik.errors.lastName ? 'failure' : ''}
                                        value="Last Name"
                                    />
                                </div>
                                <TextInput
                                    {...formik.getFieldProps('lastName')}
                                    name="lastName"
                                    color={formik.errors.lastName ? 'failure' : 'gray'}
                                    type="text"
                                    helperText={
                                        <React.Fragment>
                                            <CustomError name="lastName" component="div" />
                                        </React.Fragment>
                                    }
                                />
                            </div>
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="username4"
                                    className="dark:text-white"
                                    color={formik.errors.phone ? 'failure' : ''}
                                    value="Phone Number"
                                />
                            </div>
                            <PhoneInput
                                id="phoneNumber"
                                name="phone"
                                value={formik.values.phone}
                                onChange={(value) => formik.setFieldValue('phone', value)}
                                onBlur={formik.handleBlur('phone')}
                                placeholder="Enter phone number"
                                className="mb-1"
                            />
                            <CustomError name="phone" component="div" />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="username4"
                                    className="dark:text-white"
                                    color={formik.errors.email ? 'failure' : ''}
                                    value="Email"
                                />
                            </div>
                            <TextInput
                                {...formik.getFieldProps('email')}
                                name="email"
                                color={formik.errors.email ? 'failure' : 'gray'}
                                icon={HiMail}
                                type="email"
                                helperText={
                                    <React.Fragment>
                                        <CustomError name="email" component="div" />
                                    </React.Fragment>
                                }
                            />
                        </div>

                        <div className="flex justify-end">
                            <CustomBtn type="submit" variant="primary" isProcessing={isLoading} disabled={isLoading}>
                                Save Changes
                            </CustomBtn>
                        </div>
                    </Form>
                )}
            </Formik>
            <CustomerPassword id={customer._id} />
        </>
    );
};

export default CustomerPersonalInfo;
