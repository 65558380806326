import { Badge, Timeline } from 'flowbite-react';
import moment from 'moment';
import { statusDisplay } from 'pages/orders/orderElements';
import React from 'react';
import { HiCalendar } from 'react-icons/hi';
import { HistoryProps } from './orders.types';

export const StatusTimeline: React.FC<HistoryProps> = ({ history, order }) => {
    const rolesMatch = {
        admin: 'Admin',
        productProvider: 'Product Provider',
        accountManager: 'Account Manger',
        callAgent: 'Call Agent',
        callCenterAdmin: 'Call center Admin',
        apiOnly: 'Automated action',
    };

    const feedBacksMatch: { [key: string]: number } = {};
    let feedbackIndex = 1;
    history.history.forEach((el) => {
        if (el.newStatus === 3 || el.newStatus === 4) {
            // eslint-disable-next-line no-underscore-dangle
            feedBacksMatch[el._id!] = feedbackIndex;
            feedbackIndex += 1;
        }
    });

    return (
        <div className="realative overflow-y-auto relative h-full w-full p-4 md:h-auto max-w-2xl">
            <div className="gap-2 ml-5 mb-3 font-bold">Order ref: {order?.orderRef ? order.orderRef : '--'}</div>
            <div className="gap-2 ml-5 mb-3 font-bold">Logistio Code: {order?.lgstTrackingCode}</div>
            <Timeline>
                <Timeline.Item>
                    <Timeline.Point icon={HiCalendar} />
                    <Timeline.Content>
                        <Timeline.Time>
                            {moment(
                                new Date(order?.createdAt).toLocaleString('en-US', {
                                    timeZone: 'Asia/Shanghai',
                                }),
                            ).format('DD MMMM YYYY , HH:mm:ss')}
                        </Timeline.Time>
                        <>
                            {history.history[0] ? (
                                <>
                                    <Timeline.Title>
                                        <div className="flex flex-wrap gap-2">
                                            {statusDisplay[history?.history[0]?.oldStatus]?.statusIcon}
                                        </div>
                                    </Timeline.Title>
                                    <Timeline.Body />
                                </>
                            ) : (
                                <>
                                    <Timeline.Title>
                                        <div className="flex flex-wrap gap-2">
                                            {order?.status && statusDisplay[String(order.status)].statusIcon}
                                        </div>
                                    </Timeline.Title>
                                    <Timeline.Body />
                                </>
                            )}
                        </>
                    </Timeline.Content>
                </Timeline.Item>
                {history?.history?.map((histo, idx) => {
                    return (
                        <Timeline.Item>
                            <Timeline.Point icon={HiCalendar} />
                            <Timeline.Content>
                                <Timeline.Time className="text-gray-700">
                                    {moment(
                                        new Date(histo.createdAt).toLocaleString('en-US', {
                                            timeZone: 'Asia/Shanghai',
                                        }),
                                    ).format('DD MMMM YYYY , HH:mm:ss')}
                                </Timeline.Time>
                                <Timeline.Title>
                                    <div className="flex flex-wrap gap-2">
                                        {(histo?.newStatus && statusDisplay[histo?.newStatus]?.statusIcon) || (
                                            <Badge color="dark" size="sm">
                                                Unsupported Status ${histo?.newStatus}
                                            </Badge>
                                        )}
                                    </div>
                                </Timeline.Title>
                                {histo.newStatus === 43 && histo.order.scheduledCallAt && (
                                    <Timeline.Time className="text-gray-700">
                                        Scheduled to:
                                        {moment(histo.order.scheduledCallAt).format('DD MMMM YYYY , HH:mm:ss')}
                                    </Timeline.Time>
                                )}
                                <Timeline.Body>
                                    {history.history.length - 1 === idx && order.statusDescription ? (
                                        <div className="flex flex-wrap gap-2 py-2">
                                            <Badge size="sm" color="gray">
                                                {order.statusDescription}
                                            </Badge>
                                        </div>
                                    ) : (
                                        (histo.newStatus === 10 ||
                                            histo.newStatus === 105 ||
                                            histo.newStatus === 11) && (
                                            <div className="flex flex-wrap gap-2 py-2">
                                                <Badge size="sm">{histo.description}</Badge>
                                            </div>
                                        )
                                    )}
                                    {histo.onModel === 'User' ? (
                                        <div className="flex flex-wrap gap-2 text-sm">
                                            status updated
                                            {/* <b>{histo?.newStatus && statusDisplay[histo?.newStatus].statusLabel}</b> */}
                                            {histo.doneBy.role.toLowerCase() !== 'apionly' && (
                                                <>
                                                    {' '}
                                                    by{' '}
                                                    <b>
                                                        {histo?.doneBy?.firstName} {histo?.doneBy?.lastName}
                                                    </b>
                                                </>
                                            )}
                                            ({rolesMatch[histo?.doneBy?.role]}){' '}
                                        </div>
                                    ) : (
                                        <div className="flex flex-wrap gap-2 text-sm">
                                            status updated by
                                            <b>
                                                {histo?.doneBy?.firstName} {histo?.doneBy?.lastName}
                                            </b>
                                            (Customer)
                                        </div>
                                    )}
                                    {(histo.newStatus === 3 || histo.newStatus === 4) && (
                                        <div className="flex flex-wrap gap-2">
                                            <Badge color="gray">
                                                {/* eslint-disable-next-line no-underscore-dangle */}
                                                {feedBacksMatch[histo._id!] && histo?.order?.callCenterFeedback
                                                    ? // eslint-disable-next-line no-underscore-dangle
                                                      histo?.order?.callCenterFeedback[feedBacksMatch[histo._id!] - 1]
                                                    : 'Feedback not specified'}
                                            </Badge>
                                        </div>
                                    )}
                                </Timeline.Body>
                            </Timeline.Content>
                        </Timeline.Item>
                    );
                })}
            </Timeline>
        </div>
    );
};
export default StatusTimeline;
