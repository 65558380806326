import React from 'react';

import { FaCircle } from 'react-icons/fa';

export const StatusBadge = ({ name, color, style, Icon }: any) => (
    <span
        className={`${style} flex gap-1 bg-${color}-100 text-${color}-500 h-[30px] text-xs font-medium me-2 px-2.5 py-0.5 rounded-full !dark:bg-${color}-900 !dark:text-${color}-300 items-center`}
    >
        <FaCircle size="8px" />
        {name}
        {Icon && <Icon />}
    </span>
);
