import { IStausInterface, TableColDef } from 'components/tables/tables.types';
import { Badge } from 'flowbite-react';
import React from 'react';

export const columns: TableColDef[] = [
    {
        field: 'thumbnail',
        headerName: 'Thumbnail',
        fieldType: 'profileImage',
    },
    {
        field: 'name',
        headerName: 'Name',
        classField: 'whitespace-nowrap font-medium text-gray-900 dark:text-white',
    },
    {
        field: 'country',
        headerName: 'Warehouse',
        classField: 'whitespace-nowrap font-medium text-gray-900 dark:text-white',
    },
    {
        field: 'quantity',
        headerName: 'Quantity',
    },
    {
        field: 'unitPrice',
        headerName: 'Unit Price',
    },
    {
        field: 'unitSellPrice',
        headerName: 'Selling Price',
    },
    {
        field: 'status',
        headerName: 'Status',
        fieldType: 'status',
    },
];
export const statusDisplay: { [keys: string]: IStausInterface } = {
    1: {
        statusIcon: (
            <Badge color="warning" size="xs" className="text-xs">
                New
            </Badge>
        ),
        statusLabel: 'New',
    },
    2: {
        statusIcon: (
            <Badge color="purple" size="xs" className="text-xs">
                Preparing stock
            </Badge>
        ),
        statusLabel: 'Preparing stock',
    },
    3: {
        statusIcon: (
            <Badge color="success" size="xs" className="text-xs">
                Ready for sell
            </Badge>
        ),
        statusLabel: 'Ready for sell',
    },
    4: {
        statusIcon: (
            <Badge color="failure" size="xs" className="flex items-center text-xs">
                Out of stock
            </Badge>
        ),
        statusLabel: 'Out of stock',
    },
    11: {
        statusIcon: (
            <Badge color="info" size="xs" className="text-xs">
                Draft
            </Badge>
        ),
        statusLabel: 'Draft',
    },
};
