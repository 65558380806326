import axios from 'utils/axios';
import { getBearerToken } from 'utils/helpers/auth';

export const addAdmin = async (data: any) => {
    const newAdmin = await axios.post(`/admins`, data, getBearerToken());
    return newAdmin;
};

export const getAll = async (filter: any) => {
    const admins = await axios.post(`/admins/filter`, filter, getBearerToken());
    return admins;
};

export const getAdmin = async (id: any) => {
    const admin = await axios.get(`/admins/${id}`, getBearerToken());
    return admin;
};

export const updateAdmin = async (id: any, data: any) => {
    const adminUpdate = await axios.put(`/admins/${id}`, data, getBearerToken());
    return adminUpdate;
};
