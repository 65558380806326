import axios from 'utils/axios';
import { getBearerToken } from 'utils/helpers/auth';

export const updateCallCenterConfig = async (id: any, data: any) => {
    const callCenterConfigUpdated = await axios.put(`/callcenter/update-config/${id}`, data, getBearerToken());
    return callCenterConfigUpdated;
};

export const getCallCenterConfig = async (filter: any) => {
    const callCenter = await axios.post(`/callcenter/filter`, filter, getBearerToken());
    return callCenter;
};
