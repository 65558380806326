import { Button } from 'flowbite-react';
import React from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import { Qsz, Qxz } from './CustomButton.styles';
import { BtnProps } from './CustomButton.types';

const CustomBtn: React.FC<BtnProps> = ({
    children,
    className,
    pill,
    size,
    disabled,
    variant,
    outline,
    isProcessing,
    ...rest
}) => {
    return (
        <>
            {outline || variant === 'secondary' ? (
                <Button
                    className={`${Qxz(variant)} ${className}`}
                    pill={pill}
                    size={size}
                    disabled={disabled}
                    isProcessing={isProcessing}
                    processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />}
                    {...rest}
                >
                    {children}
                </Button>
            ) : (
                <Button
                    className={`${Qsz(variant)} ${className}`}
                    pill={pill}
                    size={size}
                    color={variant}
                    disabled={disabled}
                    isProcessing={isProcessing}
                    processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />}
                    {...rest}
                >
                    {children}
                </Button>
            )}
        </>
    );
};

export default CustomBtn;
