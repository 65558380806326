/* eslint-disable react/jsx-fragments */
import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import CustomError from 'components/customError/CustomError';
import { resetPassword } from 'services/auth';
import { useNavigate } from 'react-router-dom';
import { Label, TextInput } from 'flowbite-react';
import { HiMail } from 'react-icons/hi';
import CustomBtn from 'components/customButton/customBtn';
import toast from 'react-hot-toast';

const ResetPassword = () => {
    const navigate = useNavigate();

    const resetPwd = (values: any) => {
        resetPassword(values)
            .then((res) => {
                toast.success(res.data.message);
                setTimeout(function time() {
                    navigate('/auth/login');
                }, 3000);
            })
            .catch((err) => {
                toast.error(err.response.data.errors.message);
            });
    };
    return (
        <div className="container mx-auto  w-80 flex flex-col justify-center h-screen">
            <Formik
                initialValues={{
                    email: '',
                }}
                onSubmit={(values) => {
                    resetPwd(values);
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('Email is invalid').required('Email is required'),
                })}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit} placeholder="">
                        <div>
                            <h4>Password Recovery</h4>

                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="username4"
                                        className="dark:text-white text-xs"
                                        color={formik.errors.email ? 'failure' : ''}
                                        value="Enter your Email and we’ll send you a verification code"
                                    />
                                </div>
                                <TextInput
                                    {...formik.getFieldProps('email')}
                                    name="email"
                                    className="w-full"
                                    placeholder="jhondoe@email.com"
                                    color={formik.errors.email ? 'failure' : 'gray'}
                                    icon={HiMail}
                                    type="email"
                                    helperText={
                                        <React.Fragment>
                                            <CustomError name="email" component="div" />
                                        </React.Fragment>
                                    }
                                />
                            </div>

                            <CustomBtn variant="primary" type="submit" className="w-full">
                                Reset Password
                            </CustomBtn>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ResetPassword;
