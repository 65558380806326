import axios from 'utils/axios';
import { IPaginationData } from 'utils/Interfaces/types';
import { getBearerToken } from '../../utils/helpers/auth';

export interface IOptions {
    storeName?: string;
    storeType?: string;
    store?: string;
    customer?: string;
    page?: number;
    limit?: number;
}

export interface IStore {
    _id: string;
    storeName: string;
    storeType: string;
    storeLink: string;
    accessToken: string;
    fulfillmentProcessing: string;
    riskyOrderProcessing: string;
    partiallyFulfilledProcessing: string;
    deliveryLevels: string;
    customer: any;
    offer: string;
    funnel?: string;
    withCC: boolean;
}

interface IPaginatedStore extends IPaginationData<IStore> {}

export const getStores = async (options: IOptions) => {
    const config = getBearerToken();
    const response = await axios.post<IPaginatedStore>(`/stores/filter`, options, config);
    return response;
};

export const getStore = async (id: string) => {
    const config = getBearerToken();
    const response = await axios.get<IStore>(`/stores/${id}`, config);
    return response;
};
