/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useRef } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { IAutoComplete } from './autoComplete.types';

const MultiSelect: React.FC<IAutoComplete> = ({
    onChange,
    options,
    getOptionLabel,
    placeholder,
    defaultValue,
    styles,
}) => {
    const [selectedOptions, setSelectedOptions] = useState<any>(defaultValue || []);
    const [showOptions, setShowOptions] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [cursor, setCursor] = useState(-1);
    const inputRef = useRef<HTMLInputElement>(null);

    const toggleOption = (option: any) => {
        const isSelected = selectedOptions.some((item: { _id: string }) => item._id === option._id);
        let updatedOptions;
        if (isSelected) {
            updatedOptions = selectedOptions.filter((item: { _id: string }) => item._id !== option._id);
        } else {
            updatedOptions = [...selectedOptions, option];
        }
        setSelectedOptions(updatedOptions);
        setInputValue('');
        onChange(updatedOptions);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
        setShowOptions(true);
        setCursor(-1);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
            setShowOptions(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const getClassname = (i: number, arr: any[]) => {
        let className = 'px-4 hover:bg-gray-100 hover:dark:bg-[#333a47] ';
        if (i === 0 && arr.length === 1) {
            className += 'py-2 rounded-lg ';
        } else if (i === arr.length - 1) {
            className += 'pt-1 pb-2 rounded-b-lg ';
        } else if (i === 0) {
            className += ' pt-2 pb-1 rounded-t-lg ';
        } else {
            className += 'py-1';
        }
        if (cursor === i) {
            className += ' bg-gray-100 dark:bg-[#333a47]';
        }
        return className;
    };

    return (
        <div className="relative">
            <input
                style={styles}
                ref={inputRef}
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onFocus={() => setShowOptions(true)}
                placeholder={placeholder}
                id="autoComplete"
                className="block w-full p-2.5 pl-3 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-200 focus:ring-blue-500 focus:border-blue-500 dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
            {showOptions && (
                <ul
                    className={`absolute w-full rounded-lg shadow-lg bg-background-primary max-h-52 overflow-auto z-[9999] ${
                        !showOptions && 'hidden'
                    } select-none`}
                >
                    {options
                        .filter((option: any) =>
                            getOptionLabel(option).toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()),
                        )
                        .map((option, i: number, arr: any) => (
                            <li key={option._id} className={getClassname(i, arr)}>
                                <input
                                    type="checkbox"
                                    checked={selectedOptions.some((item: { _id: string }) => item._id === option._id)}
                                    onChange={() => toggleOption(option)}
                                    className="mr-2"
                                />
                                <span>{getOptionLabel(option)}</span>
                            </li>
                        ))}
                </ul>
            )}
            {selectedOptions.length > 0 && (
                <div className="flex flex-wrap mt-2">
                    {selectedOptions.map((option: any) => (
                        <div
                            key={option._id}
                            className="flex items-center mr-2 mb-2 bg-gray-100 dark:bg-gray-600 p-1 rounded-lg"
                        >
                            <span className="mr-1">{getOptionLabel(option)}</span>
                            <button onClick={() => toggleOption(option)} className="ml-1" type="button">
                                <MdOutlineCancel className="h-4 w-4" />
                            </button>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MultiSelect;
