export const renderOpSatatusColor = (param: number) => {
    switch (param) {
        case 1:
            return 'text-[#578aff]';
        case 2:
            return 'text-[#93c66e]';
        case 3:
            return 'text-[#ff7777]';
        default:
            return 'text-[#578aff]';
    }
};
