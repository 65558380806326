/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Modal, Select, TextInput } from 'flowbite-react';
import UseDocumentTitle from 'utils/dynamicTitle/dynamicTitle';
import CustomBtn from 'components/customButton/customBtn';
import { HiUserAdd } from 'react-icons/hi';
import { getAllCallCenterAdmins } from 'services/admin/callCenterAdmin';
import useDebouncedValue from 'utils/hooks/useDebouncedValue';
import { AiOutlineSearch } from 'react-icons/ai';
import toast from 'react-hot-toast';
import { ICallCenter } from 'pages/callCenterList/callCenter.types';
import { getAllCallCenters } from 'services/admin/callCenter';
import AddCallCenterAdmin from './addCallCenterAdmin/addCCAdmin';
import DataTable from '../../components/tables/tables';
import { columns, statusDisplay } from './callCenterAdminTableActions/callCenterAdminListElements';
import { ICallCenterAdmin } from './callCenterAdmin.types';

interface Ipagination {
    page: number;
    limit: number;
}
interface Imeta {
    totalDocs: number;
    limit: number;
    totalPages: number;
    page: number;
    pagingCounter: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    prevPage: number | null;
    nextPage: number | null;
}

interface Ifilter {
    status?: number;
    fullName?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    callCenter?: string;
}
const callAgentList = () => {
    const [open, setOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [callCenterAdmins, setCallCenterAdmins] = useState<Array<ICallCenterAdmin>>([]);
    const [callCenter, setCallCenter] = useState<Array<ICallCenter>>([]);

    const [filter, setFilter] = useState<Ifilter>({});
    // const [pageState, setPageState] = useState({
    //     isLoading: false,
    //     total: 0,
    //     pageSize: 0,
    //     page: 1,
    //     count: 0,
    // });
    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 20,
    });
    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });

    const handlePaginationChange = (value: { [key: string]: any }) => {
        setPagination((prevPagination) => {
            const data = { ...prevPagination, ...value };
            if (value.limit) {
                data.page = 1;
            }
            return data;
        });
        setMeta((prev) => ({ ...prev, page: -1 }));
    };

    function handleChangeFilter(event: any) {
        if (event.target.value !== '') {
            setFilter({ ...filter, ...{ [event.target.name]: event.target.value } });
        } else {
            setFilter({ ...filter, ...{ [event.target.name]: undefined } });
        }
        setPagination((prevPagination) => ({ ...prevPagination, page: 1 }));
        setMeta({ ...meta, page: -1 });
    }

    const debouncedPagination = useDebouncedValue(pagination, 500);
    const debouncedFilter = useDebouncedValue(filter, 500);
    useEffect(() => {
        const feedbackToast = toast.loading('Loading data...');
        setIsLoading(true);
        const queryOptions = { ...debouncedPagination, ...debouncedFilter };
        getAllCallCenterAdmins(queryOptions)
            .then((result) => {
                toast.dismiss(feedbackToast);
                setCallCenterAdmins(result.data.docs);
                setMeta({
                    hasNextPage: result.data.hasNextPage,
                    hasPrevPage: result.data.hasPrevPage,
                    limit: result.data.limit,
                    nextPage: result.data.nextPage,
                    page: result.data.page,
                    pagingCounter: result.data.pagingCounter,
                    prevPage: result.data.prevPage,
                    totalDocs: result.data.totalDocs,
                    totalPages: result.data.totalPages,
                });
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                toast.error((err as any)?.response?.data?.errors?.message || 'Unable to load data.', {
                    id: feedbackToast,
                });
            });
        getAllCallCenters({ limit: 1000 }).then((res) => {
            setCallCenter(res.data.docs);
        });
    }, [debouncedFilter, debouncedPagination]);

    UseDocumentTitle('Call Agents');

    return (
        <>
            <h3>List of call center administrators</h3>
            <div className="flex justify-between mb-2">
                <div className="flex gap-2">
                    <TextInput
                        icon={AiOutlineSearch}
                        id="customerName"
                        placeholder="Search by admin name"
                        name="fullName"
                        onChange={(e) => {
                            handleChangeFilter(e);
                        }}
                    />
                    <div id="se lect">
                        <Select
                            id="statusType"
                            name="status"
                            required
                            value={filter.status?.toString()}
                            onChange={(e) => {
                                handleChangeFilter(e);
                            }}
                        >
                            <option value="">All Status</option>
                            <option value="1">Active</option>
                            <option value="2">Disabled</option>
                        </Select>
                    </div>
                    <div id="select" className="max-w-48">
                        <Select
                            id="callcenters"
                            required
                            onChange={(e) => {
                                if (e.target.value !== '') {
                                    setFilter({ ...filter, callCenter: e.target.value });
                                } else {
                                    setFilter({ ...filter, callCenter: undefined });
                                }
                            }}
                        >
                            <option value="">All Call Centers</option>
                            {callCenter.map((callCenters: ICallCenter) => {
                                return (
                                    <option value={callCenters._id} key={callCenters._id}>
                                        {callCenters.name}
                                    </option>
                                );
                            })}
                        </Select>
                    </div>
                </div>
                <CustomBtn
                    variant="primary"
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    <HiUserAdd className="mr-2 h-5 w-5" />
                    Add a new call center admin
                </CustomBtn>
                <Modal dismissible show={open} popup onClose={() => setOpen(false)}>
                    <Modal.Header>General Information</Modal.Header>
                    <Modal.Body className="!p-0">
                        <AddCallCenterAdmin
                            setOpen={setOpen}
                            setCallCenterAdmins={setCallCenterAdmins}
                            setCallCenter={setCallCenter}
                            callCenter={callCenter}
                        />
                    </Modal.Body>
                </Modal>
            </div>
            <DataTable
                rows={callCenterAdmins}
                columns={columns}
                icons={statusDisplay}
                setRows={setCallCenterAdmins}
                pageState={{
                    isLoading,
                    total: meta.totalDocs,
                    pageSize: pagination.limit,
                    page: pagination.page,
                    count: meta.totalPages,
                }}
                setPageState={handlePaginationChange}
                filterState={filter}
            />
        </>
    );
};
export default callAgentList;
