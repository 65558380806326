export const cities = {
    Bahrain: [
        'Abu Beham',
        'Abu Saiba',
        'Adiliya',
        'Al Burhama',
        'Al Dur',
        'Al Eker',
        'Al Hajar',
        'Al Hidd',
        'Al Jasra',
        'Al Lawzi',
        'Al Mazrowiah',
        'Al Qalah',
        'Al Safriyah',
        'Amwaj',
        'Arad',
        'Askar',
        'Awali',
        'Bani Jamra',
        'Barbar',
        'Bilad Al Qadeem',
        'Budaiya',
        'Buquwa',
        'Buri',
        'Busaiteen',
        'Daih',
        'Dair',
        'Dar Kulaib',
        'Diraz',
        'Dumistan',
        'Gafool',
        'Galali',
        'Ghuraifa',
        'Hamad Town',
        'Hamala',
        'Hillat Abdulsaleh',
        'Isa Town',
        'Janabiya',
        'Jannusan',
        'Jaww',
        'Jiblat Habshi',
        'Jid Alhaj',
        'Jid Ali',
        'Jidda Island',
        'Jiddah',
        'Jidhafs',
        'Jordab',
        'Juffair',
        'Karbabad',
        'Karrana',
        'Karzakkan',
        'Khamis',
        "Ma'ameer",
        'Madinat Hamad',
        'Malikiya',
        'Manama',
        'Markh',
        'Meqsha',
        'Mina salman',
        'Muharraq',
        'Muqaba',
        'Musalla',
        'Nasfah',
        'North Sehla',
        'Nuwaidrat',
        'Qadam',
        'Qalali',
        'Qella',
        'Qudaibiya',
        'Qurayya',
        'Riffa',
        'Saar',
        'Sadad',
        'Sakheir',
        'Salihiya',
        'Salmabad',
        'Samaheej',
        'Sanabis',
        'Sanad',
        'Seef',
        'Shahrakan',
        'Shakhura',
        'Sitra',
        'South Sehla',
        'Tala Island',
        'Tashan',
        'Tubli',
        'Um Alhassam',
        'Um al naasan',
        'Umm Alsabban (Mohammadiya)',
        'Zallaq',
        'Zayed Town',
        'Zinj',
        'aali',
        'al sayh',
        'أم النعسان/Um Al Naasan',
        'أَبُو بَهَام/Abu Baham',
        'الجبيلات/Jubailat',
        'الجنبية/Janabiyah',
        'الدير/Al Dair',
        'الساية/Al Sayh',
        'الشاخورة/Shakhurah',
        'العمر/Aleumr',
        'القدم/Al Qadam',
        'القرية/Al Qurayyah',
        'الكورة/Kawarah',
        'المالكية/Malkiya',
        'المدينة الشمالية/Northern City',
        'المرخ/Al Markh',
        'المصلى/Al Musalla',
        'المقشع/Al Maqsha',
        'المهزة/Mahazza',
        'الهجير/Hujjair',
        'الوردة/Alwarda',
        'بو قوة/Bu Quwah',
        'جبلة حبشي/Jeblat Hebshi',
        'جرداب/Jurdab',
        'جزر أمواج/Amwaj Islands',
        'جزر نورانا/Nurana Islands',
        'جزيرة المرجان/Marjan Island',
        'جزيرة النبيه صالح/Nabih Saleh',
        'جزيرة النجمة/Najma Island',
        'جزيرة دلمونيا/Dilmunia Island',
        'جِد الحاج/Jid Al Haj',
        'حالة السلطة/Halat Seltah',
        'حالة نعيم/Halat Naim',
        'حلة عبد الصالح/Hillat Abdul Saleh',
        'درة البحرين/Durrat Al Bahrain',
        'دمستان/Damistan',
        'ديار المحرق/Diyar Al-Muharraq',
        'شهركان/Shahrakkan',
        "عالي/A'ali",
        'كرانة/Karranah',
        'مدينة عائمة/Floating City',
        'مقابة/Maqabah',
        "هورة عالي/Hoarat A'ali",
    ],
    Kuwait: [
        'ABDALI',
        'ABDULLA AL SALEM',
        'ABDULLA AL- MUBARAK',
        'ABDULLA HARBOUR',
        'ABDULLAH MUBARAK WEST JLEEB SHYOOKH',
        'ABU FATEERA',
        'ABU HALEEFA',
        'ABU HASSANIYA',
        'ABU- HALAIFA',
        'ADDAN',
        'AHMADI 2',
        'AHMADI CITY',
        'AIRPORT',
        'AL - MUTLAA 10',
        'AL - MUTLAA 11',
        'AL - MUTLAA 12',
        'AL - MUTLAA 13',
        'AL - MUTLAA 2',
        'AL - MUTLAA 3',
        'AL - MUTLAA 4',
        'AL - MUTLAA 5',
        'AL - MUTLAA 6',
        'AL - MUTLAA 7',
        'AL - MUTLAA 8',
        'AL - MUTLAA 9',
        'AL - MUTLAA COMERCIAL',
        'AL - ROZATAIN',
        'AL MSEILAH',
        'AL MUTLAA',
        'AL NAEEM',
        'AL NAHDA (EAST SULAIBIKHAT)',
        'AL NASEEM',
        'AL OYOUN',
        'AL QASER',
        'AL QUSOOR',
        'AL RAI',
        'AL SALEM',
        'AL WAHA',
        'AL WOSTA',
        'AL-FARWANIYAH',
        'AL-JAHRA',
        "AL-MUTLA'A",
        'AL-SALAM',
        'AL-SHA`AB AL-BAHREE',
        'AL-SHUHADA',
        'AL-WAHA',
        'AMGHARA',
        'ANDALUS',
        'AQEELAH',
        'ARDIYAH',
        'ARDIYAH 5',
        'ARDIYAH 6',
        'Abbasiya',
        'Abdullah Al Mubarak Al Sabah',
        'Abdullah al-Salem',
        'Abraq Khaitan',
        'Abu Al Hasaniya',
        'Abu Fatira',
        'Abu Halifa',
        'Adailiya',
        'Adan',
        'Ahmadi',
        'Al Abdaliyah',
        "Al Ad'Ami",
        'Al Jahra Stables',
        'Al Khiran',
        'Al Kuber',
        'Al Maghasil',
        'Al Magwa',
        'Al Matla',
        'Al Matla Comercial',
        'Al Sulaibiya Industrial 1',
        'Al Sulaibiya Industrial 2',
        'Al Wafrah',
        'Al-Bahra',
        'Al-Bidea',
        'Al-Dajeej',
        "Al-Julaia'a",
        'Al-Masayel',
        'Al-Nuwaiseeb',
        'Al-Riqqa',
        'Al-Shadadiya',
        'Al-Shuaiba',
        'Al-Siddeeq',
        'Ali Sabah Al Salem',
        'Amgarah Industrial Area',
        'Andalous',
        'Anjafa',
        'Ardiya',
        'Ardiya Small Industrial',
        'Ardiya Storage Zone',
        'Assabahiyah',
        'At Tawil',
        'BIDAA',
        'BNEID EL GAR',
        'BUBYAN',
        'Bayan',
        'Bnaider',
        'Bneid Al Qar',
        'Bnied Al-Gar',
        'Bubiyan Island',
        'CEMETARY',
        'COLLECTION DAJEEJ',
        'COST LINE A',
        'COST LINE B',
        'COST LINE C',
        'DAHER',
        'DAJEEJ',
        'DHAHAR',
        'Dahar',
        'Daiya',
        'Dasma',
        'Dasman',
        'Doha',
        'EAST AHMADI',
        'Eqaila',
        'FAHAD AL-AHMAED',
        'FAHAHEEL INDUTRIAL',
        'FAHED AL AHMAD',
        'FAILAKA',
        'FARAWANIYAH',
        'FARDOS',
        'FAYHA',
        'FUHAIHEEL',
        'Fahad Al Ahmad',
        'Fahaheel',
        'Faiha',
        'Failaka Island',
        'Farwaniyah',
        'Fintas',
        'Firdous',
        'Fnaitees',
        'GERNATA',
        'GREEN BELT',
        'Granada',
        'HADIYAH',
        'HAWALLI',
        'HITTEEN',
        'HUTEEN',
        'Hadiya',
        'Hateen',
        'Hawally',
        'IDAILIYAH',
        'ISHBEELYAH',
        'Ishbiliya',
        'JABER AL AHMED',
        'JABER AL-ALI',
        'JABRIYAH',
        'JAHRA INDUSTRIAL',
        'JAHRA MILITARY CAMPS',
        'JEAIDAN',
        'JELAIAA',
        'JLEEB AL SHYOOKH',
        'Jaber Al Ahmad',
        'Jaber Al Ali',
        'Jabriya',
        'Jahra',
        'Jeleeb Al-Shuyoukh',
        'Jibla',
        'KABD',
        'KAZMAH',
        'KHAIRAN',
        'KHAIRAN-1',
        'KHAIRAN-2',
        'KHAIRAN-3',
        'KHAIRAN-4',
        'KHAIRAN-5',
        'KHALDIYAH',
        'KUBAR ISLAND',
        'Kabed',
        'Kaifan',
        'Khaitan',
        'Khaldiya',
        'Kuwait City',
        'Kuwait Free Trade Zone',
        'Kuwait International Airport',
        'MADAEN',
        'MAIDAN HAWALLI',
        'MANQAF',
        'MANSOORIYAH',
        'MAQWAA',
        'MASSAEL',
        'MESKAN ISLAND',
        'MINA AL ZOUR',
        'MUBARAK ABDULLA',
        'MUNTAZAH',
        'MUQUWE',
        'MURGAB',
        'Mahboula',
        'Maidan Hawally',
        'Mangaf',
        'Mansouriya',
        'Messila',
        'Ministries Zone',
        'Mirqab',
        'Mishref',
        'Miskan Island',
        'Mubarak Al Kabeer',
        'Mubarak Al-Abdullah',
        'NORTH SHUAIBAH',
        'NUWAISEB',
        'Naeem',
        'Nahdha',
        'Naseem',
        'North West Al-Sulaibikhat',
        'Nuzha',
        'OHA ISLAND',
        'OMARIYAH',
        'OQAILAH',
        'Omariya',
        'Ouha Island',
        'Oyoun',
        'QADISIYAH',
        'QAROUH ISLAND',
        'QAYRAWAN (SOUTH DOHA)',
        'QIBLEH',
        'QRAIN',
        'QUBLAH',
        'Qadsiya',
        'Qairawan',
        'Qaruh Island',
        'Qasr',
        'Qortuba',
        'Qurain',
        'Qusor',
        'RABYAH',
        'RAS',
        'RAWDATAIN',
        'RIHAB',
        'RIQI',
        'RIQQA',
        'RMAITHIYA',
        'Rabia',
        'Rai',
        'Rawda',
        'Rehab',
        'Rigai',
        'Riqqah District',
        'Rumaithiya',
        'SAAD AL-ABDULLA',
        'SABAH AL NASER',
        'SABAH AL- AHMED',
        'SABAH AL-SALEM A',
        'SABAH AL-SALEM B',
        'SABAH AL-SALEM C',
        'SABAHIYAH',
        'SABAHIYYAH',
        'SABHAN',
        'SAFAT',
        'SALEEBIYAH',
        'SALEEBIYAH AGRICULTURAL',
        'SALEEBIYAH INDUSTRIAL 1',
        'SALEEBIYAH INDUSTRIAL 2',
        'SALMI',
        'SALMIYAH',
        'SCRAP',
        'SHALAYHAT AL DUBAIYA',
        'SHAMIYAH',
        'SHA`AB',
        'SHIEB',
        'SHIKAH SAAD AIRPORT',
        'SHOHADA',
        'SHUAIBAH',
        'SHUWAIKH B',
        'SHUWAIKH INDUSTRY',
        'SHWEIKH (B-RESIDENTIAL)',
        'SHWEIKH COMMERCIAL',
        'SHWEIKH EDUCATIONAL',
        'SHWEIKH HEALTH',
        'SHWEIKH INDUSTRIAL 1',
        'SHWEIKH INDUSTRIAL 2',
        'SHWEIKH INDUSTRIAL 3',
        'SHWEIKH SEA FRONT',
        'SIDEEQ',
        'SORRA',
        'SOUK DAKHILI',
        'SOUTH ABDULLA AL-MUBARAK',
        'SOUTH AL WOSTA',
        'SOUTH DOHA',
        'SOUTH EAST JAHRA',
        'SOUTH JAHRA',
        'SOUTH KHAITAN',
        'SOUTH SAAD AL-ABDULLA 1',
        'SOUTH SAAD AL-ABDULLA 10',
        'SOUTH SAAD AL-ABDULLA 11',
        'SOUTH SAAD AL-ABDULLA 12',
        'SOUTH SAAD AL-ABDULLA 13',
        'SOUTH SAAD AL-ABDULLA 2',
        'SOUTH SAAD AL-ABDULLA 3',
        'SOUTH SAAD AL-ABDULLA 4',
        'SOUTH SAAD AL-ABDULLA 5',
        'SOUTH SAAD AL-ABDULLA 6',
        'SOUTH SAAD AL-ABDULLA 8',
        'SOUTH SAAD AL-ABDULLA 9',
        'SOUTH SAAD AL-ABDULLA COMERCIAL',
        'SOUTH SAAD AL-ABDULLA7',
        'SOUTH SABAHIYA',
        'SOUTH SABAHIYAH',
        'SOUTH SHUAIBAH',
        'SUDEEQ',
        'SULEIBIKHAT',
        'Saad Al Abdullah',
        'Sabah Al Ahmad',
        'Sabah Al Nasser',
        'Sabah Al Salem',
        'Sabhan Industrial Area',
        'Salam',
        'Salhiya',
        'Salmiya',
        'Salwa',
        'Shaab',
        'Shalayhat Mina Abdullah',
        'Shamiya',
        'Sharq',
        'Sheikh Saad Aviation Terminal',
        'Shuaiba Block 1',
        'Shuhada',
        'Shumaymah',
        'Shuwaikh Administrative',
        'Shuwaikh Educational',
        'Shuwaikh Industrial',
        'Shuwaikh Industrial 1',
        'Shuwaikh Industrial 2',
        'Shuwaikh Industrial 3',
        'Shuwaikh Medical',
        'Shuwaikh Port',
        'Shuwaikh Residential',
        'Souk Al Mubarakeya',
        'South Khaitan-Exhibits',
        'South Wista',
        'Subahiya',
        'Subaysiyah',
        'Sulaibikhat',
        'Sulaibiya',
        'Surra',
        'TAIMA',
        'THOHOR',
        "Taima'",
        'UM - SEDDAH',
        'UM AL HAIMAN - ALI SABAH AL SALEM',
        'UM AL-AISH',
        'UM ALNAMEL ISLAND',
        'UMALMARADEM ISLAND',
        'Umm Al Aish',
        'Umm Al Maradim Island',
        'Umm Al Namil Island',
        'Umm Seddah',
        'WAFRA',
        'WARA',
        'WARBA',
        'WATTEYAH',
        'WEST JAHRA',
        'Waha',
        'Warbah Island',
        'West Industrial Shuaiba',
        'West of Abu Fatira Al-Herafia',
        'Wista',
        'Yarmouk',
        'ZAHRAA',
        'Zahra',
        'Zour',
    ],
    Oman: [
        "A'wabee Azzhadi",
        'AL Ashkarah',
        'AL Bidiyah',
        'Ablah',
        'Abu Abali',
        'Abu Habara',
        'Abu Khabi',
        'Abu Silah',
        'Abud',
        'Ad Dahhas',
        'Ad Dariz',
        'Ad Dif',
        'Adam',
        'Adh Dhahir',
        'Adh Dhahur',
        'Adh Dhuwayhur',
        'Afi',
        'Aflaj',
        'Ajdarawt',
        'Ajib',
        'Ajran',
        'Aktam',
        "Al 'Uwayd",
        'Al Abyad',
        'Al Afia',
        'Al Afrad',
        'Al Ain',
        'Al Amarat',
        'Al Amirat',
        'Al Amrath',
        'Al Aqabat',
        'Al Aqariyah',
        'Al Aqd',
        'Al Aqil',
        'Al Aqir',
        'Al Araqi',
        'Al Arhamiyyah',
        'Al Arid',
        'Al Arja',
        'Al Ashkharah',
        'Al Awabi',
        'Al Awamir',
        'Al Ayn',
        'Al Aynayn',
        'Al Ayshah',
        'Al Banah',
        'Al Bashuq',
        "Al Bid'ah",
        'Al Bidayah',
        'Al Budayah',
        'Al Bun',
        'Al Buraimi',
        'Al Burj Al Saliya',
        'Al Buyda',
        'Al Dhahir',
        'Al Dreez',
        'Al Falij',
        "Al Farra'Ah",
        'Al Fath',
        'Al Fatik',
        'Al Fayy',
        'Al Fujayjah',
        'Al Fulayj',
        'Al Furfarah',
        'Al Ghabah',
        'Al Ghadifah',
        'Al Ghafat',
        'Al Ghuaysa',
        'Al Ghubar',
        'Al Ghurwah',
        'Al Ghwil',
        'Al Hadd',
        'Al Hadib',
        'Al Hafa',
        'Al Hail',
        'Al Hajar',
        'Al Hajir',
        'Al Hambar',
        'Al Hamra',
        'Al Harthy Complex',
        'Al Hashimi',
        'Al Hawiyah',
        'Al Hawmaniyah',
        'Al Hayal',
        'Al Hayema',
        'Al Hayl',
        'Al Hayma',
        'Al Hayyal',
        'Al Hazm',
        'Al Hijir',
        'Al Hijr',
        'Al Hina',
        'Al Hiwayjir',
        'Al Hub',
        'Al Hubi',
        'Al Humaydah',
        'Al Hutah',
        'Al Huwayl',
        'Al Huyul',
        "Al Ja'adiyah",
        'Al Jadi',
        'Al Jafnayn',
        'Al Jarda',
        'Al Jarwah',
        'Al Jaylah',
        'Al Jaze',
        'Al Jazm',
        'Al Jiltah',
        'Al Jirri',
        'Al Jizayrat',
        'Al Jufar',
        'Al Jumaylah',
        'Al Juwairiya',
        'Al Kamil Wal Wafi',
        'Al Karbi',
        'Al Khaburah',
        'Al Khaddah',
        'Al Khadra',
        'Al Khaisa',
        'Al Khaluf',
        'Al Khasah',
        'Al Khashabah',
        'Al Khawd',
        'Al Khibab',
        'Al Khitaym',
        'Al Khubar',
        'Al Khubayb',
        'Al Khudrah',
        'Al Khuzaymiyah',
        'Al Kuwair',
        "Al Ma'Mur",
        'Al Maabela',
        'Al Mabduea',
        'Al Madinah',
        'Al Mahbab',
        'Al Mahda',
        'Al Mahyul',
        'Al Malda',
        'Al Maldagh',
        'Al Malhah',
        'Al Maqham',
        'Al Marji',
        'Al Masarrah',
        'Al Masnaah',
        'Al Matabbah',
        'Al Maydan',
        'Al Mayhah',
        'Al Maysil',
        'Al Mazim',
        'Al Midayrub',
        'Al Midfa',
        'Al Mihayniyah',
        'Al Mikaynnah',
        'Al Mirayr',
        'Al Misdar',
        'Al Mishk',
        'Al Muaydan',
        'Al Mudayq',
        'Al Mudhaireb',
        'Al Mukhatta',
        'Al Mulaynah',
        'Al Multaqaa',
        'Al Muraybi',
        'Al Murrah',
        'Al Musanaah',
        'Al Musanna',
        'Al Musannah',
        'Al Musaydirah',
        'Al Musaydrah',
        'Al Mussanah',
        'Al Mutaila',
        'Al Muthayab',
        'Al Nadha',
        'Al Nahdah',
        'Al Nubra',
        'Al Nujayd',
        'Al Qabil',
        'Al Qariyatain',
        'Al Qarti',
        'Al Qasabiyat',
        'Al Qasf',
        'Al Radda',
        'Al Rakhah',
        'Al Riyam',
        'Al Rumais',
        'Al Safa',
        'Al Sharkha',
        "Al Shija'",
        "Al Shu'Iyah",
        'Al Shuwiei',
        'Al Sirab',
        'Al Subaykhi',
        'Al Sudairah',
        'Al Turayf',
        'Al Ulyah',
        'Al Uwaynat',
        'Al Wadi Al Kabir',
        'Al Wafi',
        'Al Wahrah',
        'Al Wajajah',
        'Al Washl',
        'Al Wasil',
        'Al Wasit',
        'Al Wudayy',
        'Al Wuqbah',
        'Al Zahib',
        'Al Zuwayharia',
        'Al ghubra',
        'Al kamil',
        'Al khoud',
        'Al-Bar',
        'Al-Masnaah',
        'Al-Mazyona',
        'Amal',
        'Amlah',
        'An Nahdah',
        'An Nid',
        'An Nujayd',
        'An Nuwamiyah',
        'Anzawr',
        'Ar Rajlah',
        'Ar Ralah',
        'Ar Rawdah',
        'Ar Rayy',
        'Ar Riwaybah',
        'Ar Rumays',
        'Ar Rusays',
        'Ar Rustaq',
        'Arqi',
        'Arraida',
        "As Saw'",
        'As Sayfiyyah',
        'As Sayh',
        'As Sib',
        'As Sifah',
        'As Sirab',
        'As Subakh',
        'As Subaykhi',
        'As Sumaydah',
        'As Sunaynah',
        'As Suwadi as Sahil',
        'As Suwamhan',
        'As Suwayq',
        'As-Sunaynah',
        'Ash Shakhar',
        'Ash Shibika',
        "Ash Shija'",
        'Ash Shirs',
        'Ash Shiyah',
        "Ash Shu'iyah",
        'Ash Shuwayhah',
        'Ash Shuwaymiyyah',
        'Askhiyat',
        "Asrar Bani 'Umar",
        'Aswad',
        'At Tabaqah',
        'At Tayib',
        'At Tuwyan',
        'Ath Thuqbah',
        'Aydam',
        'Ayn Umq',
        'Az Zuwayhir',
        'Azaiba',
        'Badiyah',
        'Bahla',
        'Balaqa',
        'Bald Sayt',
        'Bandar Jissah',
        "Bani Na'ab",
        'Barka',
        'Barzman',
        'Bat',
        "Bath'A Hilal",
        'Bawshar',
        'Bayda',
        'Bayha',
        'Bid Bid',
        "Bid'Ah",
        'Bidbid',
        'Biddi',
        'Bideih',
        'Bidiya',
        'Bidiyah',
        'Bidiyyah',
        'Bilad Sayt',
        'Billa',
        'Bilt',
        'Bimma',
        'Birkat al Mouz',
        'Bisyah',
        'Bousher',
        'Bu Baqarah',
        'Buali',
        'Bukha',
        'Bulaydah',
        'Buraimi',
        'Buwaydir',
        'Central Post Office,Salalah',
        "Dab'ayn",
        'Dabuf',
        'Dafiyat',
        'Daghmar',
        'Dahir Al Fwaris',
        'Damm',
        'Dank',
        'Daqiq',
        "Dar as Sawda'",
        'Dawanji',
        'Dawwah',
        'Dema Wa Thaieen',
        'Demeet Village',
        'Dhahir',
        'Dhalkut',
        'Dhank',
        'Diba al Bayah',
        'Dibab',
        'Dibba',
        'Dil alAbd as Salam',
        'Diyan al Bu Said',
        'Doqal',
        'Duqm',
        'Dyl Al Brik',
        'Fahud',
        'Falaj',
        'Falaj Al Harth',
        'Falaj Al Qabail',
        'Falaj Al Sham',
        "Falaj Bani Rabi'ah",
        'Falaj Bani Umar',
        'Falaj Sudayriyin',
        'Falij Al Mashayikh',
        'Fanja',
        'Fasah',
        'Fathi',
        'Fazah',
        'Fida',
        'Filim',
        'Fins',
        'Fudgha',
        'Fujayj',
        'Fulayj',
        'Ghala',
        'Ghayl Al Shabul',
        'Ghayl Subay',
        'Ghumda',
        'Ghuruba',
        'Ghuwayfat Al Habb',
        'Ghuzayn',
        'Habus',
        'Haddis',
        'Hadf',
        'Hafayt',
        'Haffah',
        'Hafiri Al Janubia',
        'Hailain',
        'Hailat',
        'Hailat Bin Anauz',
        "Hailat La'Mia",
        'Hailat bin Aruq',
        'Haima',
        'Hajir',
        'Hajz',
        'Halahil',
        'Halat Al Burj',
        'Halban',
        'Halhal',
        'Hamadiyin',
        'Hamra Al Drooa',
        "Hamrat Ad Duru'",
        'Hamriyah',
        'Harat Al Hisin',
        'Harat Al Hisn',
        'Harat An Nadd',
        'Harat Khatu',
        'Hardhi',
        'Harf Ghabi',
        'Harim',
        'Hasik',
        'Hasina',
        'Hawl',
        'Hawqayn',
        'Hay Al Shuruq',
        'Hay Al Turath',
        'Hay Asim',
        'Hayawt',
        'Haybi',
        'Hayda',
        'Hayl',
        'Hayl Manadhirah',
        'Hayl Mishak',
        "Hayl Ra'sah",
        'Hayl al Ghaf',
        'Hayul Bani Eissa',
        "Hayy as Sa'ad",
        'HiasShruq',
        'Hibra',
        'Hijal',
        'Hil',
        'Hil Al Najd',
        'Hilm',
        'Hujayrimat',
        'Humaydah',
        'Huwa',
        'Ibra',
        'Ibri',
        'Ilayat Fida',
        'Imti',
        'Israr Bani Saad',
        'Istal',
        'Izki',
        'Jaalan Bani Bu Hassan',
        'Jaalan Bani BuAli',
        'Jabal Shams',
        'Jalal',
        'Jalan Bani Bu Ali',
        'Jalan Bani Bu Hassan',
        'Janub Al Dahariz',
        "Jawharat A'Shati",
        'Jaylil',
        'Jib Jibah',
        'Jibjat',
        'Jibroo',
        'Juraym',
        'Jusor',
        'Kabout Village',
        'Kabura',
        'Kahanat',
        'Kanut',
        'Khabbat Un Nawafil',
        'Khadal',
        'Khadra as Sad',
        'Khafdi',
        'Khahil',
        'Khalil',
        'Kharma',
        'Khatum',
        'Khawr Al Siyabi',
        'Khaws',
        'Khorsel',
        'Khoula Hospital',
        'Khubaytah',
        'Kibrit',
        'Kitnah',
        'Kubarah',
        'Kudayran',
        'Kumzar',
        'Kusat',
        'Lakabi',
        'Lakba',
        'Lasail',
        'Lihban',
        'Limah',
        'Limays',
        'Liqie',
        'Liwa',
        'Lizeg',
        'Lizq',
        "Ma'Wal",
        "Ma'murah",
        'Mabah',
        'Mabella',
        'Madfa',
        'Madha',
        'Madhina Qaboos',
        'Madinat Alhakl',
        'Madruj',
        'Mahab',
        'Mahalil',
        'Mahdah',
        'Mahout',
        'Majzi',
        'Manadhif',
        'Manah',
        'Maqabil',
        'Maqammah',
        'Maqniyat',
        'Maqsad',
        'Maradi',
        'Marbat',
        "Masha'Rub",
        'Masram',
        'Matrah',
        'Mawella',
        'Mazari al Balush',
        'Meilf',
        'Mihaydith',
        'Mina al Fahal',
        'Mirbat',
        'Miri',
        'Misfah',
        'Misfah Al Shurayqiyan',
        'Misfah As Safil',
        "Muaskar Al Murtafa'a",
        'Mudayy',
        'Mudhaibi',
        'Muhara',
        'Mujuz Alsaghraa',
        'Mukaysa',
        'Mukhi',
        'Mukhsayr',
        'Mulab',
        'Muladdah',
        'Munjarid',
        'Muqal',
        'Muqasarit',
        'Muqaydan',
        'Muqshin',
        'Murayjat',
        'Murrah',
        'Murri',
        'Murtafaeat Saham',
        "Musayna'ah",
        'Muscat',
        'Muscat International Airport',
        'Muslif',
        'Muslimat',
        'Muttrah',
        'Nafun',
        'Nahdah',
        'Nakhal',
        'Nasib',
        'Nizwa',
        'Nuway',
        'Oman Commercial Centre',
        'Oshal',
        'PDO Marmul Camp',
        'Qadah',
        'Qalhat',
        'Qantab',
        'Qarayah Qata',
        'Qarn Al Alam',
        'Qarut North',
        'Qarut South',
        'Qasabiat Al Zieab',
        "Qasabiyat Az Za'Ab",
        'Qaysad',
        'Qubiyat',
        'Qumayra',
        'Qurayyat',
        'Quriyat',
        'Quriyath',
        'Qurum',
        'Quwayrah',
        "Ra'S Ar Ru'Ays",
        'Raast al Milh',
        'Rabi',
        'Rahbah',
        'Rajmi',
        'Rakhbah',
        'Rakhyut',
        'Ramlah',
        'Raqi',
        'Ras Al Jinz',
        'Ras Madrakah',
        'Rawdah',
        'Rawiyyah',
        'Raysut',
        'Riwaygh',
        'Romail',
        'Rusayl',
        'Rustaq',
        'Ruwi',
        'SADA',
        "Sa'abah",
        "Sa'al",
        'Sabt',
        'Sadadib',
        'Sadah',
        'Saham',
        'Sahban',
        'Sahlat',
        'Sahnawt Al Shamaliyyah',
        'Saiq',
        'Salalah',
        'Salmah',
        'Samad Al Shan',
        'Samail',
        'Samra',
        'Samya',
        'Sanaw',
        'Saraima',
        'Sarfayt',
        'Sarkhar',
        'Saruj',
        'Sawqirah',
        "Sayh Al Qa'",
        'Sayq',
        'Sayyah',
        'Seeb',
        'Shafa',
        'Shalim',
        'Shalim Wa Juzor Al Hallaniyyat',
        'Shama',
        'Sharkh',
        'Shathi Al Qurm',
        'Shayraym',
        'Shidah',
        'Shinas',
        "Shiqqat Al 'Awd",
        'Shisrayt',
        'Shuwasi',
        'Shuwaymirah',
        'Shuwayr',
        'Sifalat Fida',
        'Sih Al Maeashi',
        'Sima',
        'Sinaw',
        'Sini',
        'Sint',
        'Sohar',
        'Sqna',
        'Subae',
        'Subaykhah',
        'Sudamah',
        'Sudh',
        'Suhaylah',
        'Suid Alma',
        'Sultan Qaboos University',
        'Sumayni',
        'Sumayyan',
        'Sunaybah',
        'Sur',
        'Sur Al Balush',
        'Sur Al Bugrain',
        'Sur Al Mazari',
        'Sur Al Shiyadi',
        'Sur Masirah',
        'Suwadi Al Batha',
        'Suwayq',
        "Tan'am",
        'Tanuf',
        'Taqa',
        'Tarif Map',
        'Tawa',
        'Tawi Ajuz',
        'Tawi Bedi Guish',
        'Tawi Hatim',
        'Tawi Kuraysh',
        'Tawiyah',
        'Taww',
        'Tharmad',
        'Thinqayr',
        'Thumrait',
        'Tibat',
        'Tiwi',
        'Tumayt',
        'Tumayt Al Jadeed',
        'Ubar',
        'Umm al Jamamil',
        "Umm ul 'Innah",
        "Umm' Sulb",
        'Wadd',
        'Wadi Adi',
        "Wadi Al Ala'",
        'Wadi Al Arshi',
        'Wadi Al Jawabir',
        'Wadi Al Maawil',
        'Wadi As Safil',
        'Wadi Bani Harras',
        'Wadi Bani Khaled',
        'Wadi Bani Khalid',
        'Wadi Jil',
        'Wadi La',
        'Wadi Sal',
        'Wakan',
        'Wasad',
        'Wasit',
        'Watif',
        'Wattaya',
        'Wayt',
        'Wilayat Al Mudaybi',
        'Wudam Al Sahil',
        'Yanqul',
        'Yenkit',
        'Yiqa Al Jadeed',
        'Yiti',
        'Zabyan',
        'Zaghi',
        'Zakit',
        'Zawliyah',
        'Zaymi',
        'khasab',
    ],
    Qatar: [
        'Abu Dhalouf',
        'Abu Hamour',
        'Abu Samra',
        'Ad Dawhah al Jadidah',
        'Ain Khaled',
        'Ain Sinan',
        'Al Aziziya',
        'Al Bidda',
        'Al Daayen',
        'Al Dafna',
        'Al Ebb',
        'Al Egla',
        'Al Gharrafa',
        'Al Ghuwariyah',
        'Al Hilal',
        'Al Jasrah',
        'Al Jemailiya',
        'Al Jeryan',
        'Al Karaana',
        'Al Kharaitiyat',
        'Al Kharayej',
        'Al Kharrara',
        'Al Kheesa',
        'Al Khor',
        'Al Khor City',
        'Al Khulaifat',
        'Al Luqta',
        'Al Mamoura',
        'Al Mansoura',
        'Al Markhiya',
        'Al Mashaf',
        'Al Masrouhiya',
        'Al Mearad',
        'Al Messila',
        'Al Mirqab',
        'Al Najada',
        'Al Nasraniya',
        'Al Qassar',
        'Al Rayyan',
        'Al Rufaa',
        'Al Sadd',
        'Al Sailiya',
        'Al Sakhama',
        'Al Seej',
        'Al Shagub',
        'Al Shahaniya',
        'Al Shamal',
        'Al Souq',
        'Al Tarfa',
        'Al Thakhira',
        'Al Themaid',
        'Al Thumama',
        'Al Utouriya',
        'Al Waab',
        'Al Wajbah',
        'Al Wakrah',
        'Al Wukair',
        'Al-Shahaniya City',
        "Ar Ru'ays",
        'As Salatah',
        'Baaya',
        'Bani Hajer',
        'Barahat Al Jufairi',
        'Bu Fasseela',
        'Bu Samra',
        'Bu Sidra',
        'DOHA',
        'Dahl Al Hamam',
        'Doha International Airport',
        'Doha Port',
        'Duhail',
        'Dukhan',
        'Fereej Abdel Aziz',
        'Fereej Al Amir',
        'Fereej Al Asiri',
        'Fereej Al Asmakh',
        'Fereej Al Manaseer',
        'Fereej Al Murra',
        'Fereej Al Nasr',
        'Fereej Al Soudan',
        'Fereej Al Zaeem',
        'Fereej Bin Durham',
        'Fereej Bin Mahmoud',
        'Fereej Bin Omran',
        'Fereej Kulaib',
        'Fereej Mohamed Bin Jasim',
        'Fuwayrit',
        'Gharrafat Al Rayyan',
        'Hamad Medical City',
        'Hazm Al Markhiya',
        'Industrial Area',
        'Izghawa',
        'Jabal Thuaileb',
        'Jelaiah',
        'Jeryan Jenaihat',
        'Jeryan Nejaima',
        'Khawr al Udayd',
        'Leabaib',
        'Lebday',
        'Lejbailat',
        'Leqtaifiya',
        'Luaib',
        'Lusail',
        'Madinat Al Kaaban',
        'Madinat Khalifa North',
        'Madinat Khalifa South',
        'Madinat ash Shamal',
        'Mebaireek',
        'Mehairja',
        'Mesaieed',
        'Mesaieed Industrial Area',
        'Mesaimeer',
        'Muaither',
        'Muraikh',
        'Mushayrib',
        'Najma',
        'New Al Hitmi',
        'New Al Mirqab',
        'New Al Rayyan',
        'New Fereej Al Ghanim',
        'New Fereej Al Khulaifat',
        'New Salatah',
        'Nuaija',
        'Old Airport',
        'Old Al Ghanim',
        'Old Al Hitmi',
        'Old Al Rayyan',
        'Onaiza',
        'Ras Abu Aboud',
        'Ras Laffan',
        'Rawdat Al Hamama',
        'Rawdat Al Khail',
        'Rawdat Egdaim',
        'Rawdat Rashed',
        'Rumeilah',
        'Saina Al-Humaidi',
        'Sawda Natheel',
        'Shagra',
        'Simaisma',
        'Umm Al Amad',
        'Umm Bab',
        'Umm Birka',
        'Umm Ebairiya',
        'Umm Ghuwailina',
        'Umm Lekhba',
        'Umm Qarn',
        'Umm Salal',
        'Umm Salal Ali',
        'Umm Salal Mohammed',
        'Wadi Al Banat',
        'Wadi Al Sail',
        'Wadi Al Wasaah',
        'Wadi Lusail',
        'Zone 01',
        'Zone 02',
        'Zone 03',
        'Zone 04',
        'Zone 05',
        'Zone 06',
        'Zone 07',
        'Zone 10',
        'Zone 11',
        'Zone 12',
        'Zone 13',
        'Zone 14',
        'Zone 15',
        'Zone 16',
        'Zone 17',
        'Zone 18',
        'Zone 19',
        'Zone 20',
        'Zone 21',
        'Zone 22',
        'Zone 23',
        'Zone 24',
        'Zone 25',
        'Zone 26',
        'Zone 27',
        'Zone 28',
        'Zone 30',
        'Zone 31',
        'Zone 32',
        'Zone 33',
        'Zone 34',
        'Zone 35',
        'Zone 36',
        'Zone 37',
        'Zone 38',
        'Zone 39',
        'Zone 40',
        'Zone 41',
        'Zone 42',
        'Zone 43',
        'Zone 44',
        'Zone 45',
        'Zone 46',
        'Zone 47',
        'Zone 48',
        'Zone 49',
        'Zone 50',
        'Zone 51',
        'Zone 52',
        'Zone 53',
        'Zone 54',
        'Zone 55',
        'Zone 56',
        'Zone 57',
        'Zone 58',
        'Zone 61',
        'Zone 63',
        'Zone 64',
        'Zone 65',
        'Zone 66',
        'Zone 67',
        'Zone 68',
        'Zone 69',
        'Zone 70',
        'Zone 71',
        'Zone 72',
        'Zone 73',
        'Zone 74',
        'Zone 75',
        'Zone 76',
        'Zone 77',
        'Zone 78',
        'Zone 79',
        'Zone 80',
        'Zone 81',
        'Zone 82',
        'Zone 83',
        'Zone 84',
        'Zone 85',
        'Zone 86',
        'Zone 90',
        'Zone 91',
        'Zone 92',
        'Zone 93',
        'Zone 94',
        'Zone 95',
        'Zone 96',
        'Zone 97',
        'Zone 98',
        'Zubarah',
        'أبو سدرة/Abu Sidrah',
        'أبو صلبة/Abu Sulba',
        'أبو ظلوف/Abu Al Dhalouf',
        'أبو ظلوف/Abu az Zuluf（Abu Dhalouf）',
        'أبو قرن/Abu Qarn',
        'أبو نخله/Abu Nakhlah',
        'أذباح/Adhbah',
        'أسمية/Asmiyah',
        'أم الأفاعي/Umm Al Afaei',
        'أم الحول/Umm Al Houl',
        'أم السنيم/Umm Al Seneem',
        'أم الصف/Umm As Suf',
        'أم الغيلام/Umm Al Ghayl Am',
        'أم القهاب العتيق/Umm Al Quhab Al Atiq',
        'أم الكلاب/Umm Al Kilab',
        'أم اللزام/Umm Al Izam',
        'أم الهوائر/Umm Al Hawair',
        'أم سويجة/Umm Suwaijah',
        'أم صلال/Umm Salal Muhammed',
        'أم كلب/Umm Kalb',
        'أَبُو ثَيْلَة/Abu Thaylah',
        'أُمّ طَاقَة‎/Umm Taqa',
        'أُمّاَلشُّبْرُم/Umm ash Shubrum',
        'إمليح/Imlaih',
        'ابا اربيات/Aba Erbayyat',
        'ابا العصافير/Abal Asafeer',
        'ابا القراريص/Abal Gararees',
        'ابال حلايم/Abal Halayem',
        'اقريات الفايدة/Grayyat Al Faida',
        'الأشقر/Ash Shaqra',
        'الباهية/Al Bahya',
        'الثاني/As Sani',
        'الجسرة/Al Jasra',
        'الجميل/Al Jamil',
        'الجميلية/Al-Jumayliyah',
        'الحي الثقافي - كتارا/Katara Cultural Village',
        'الخرساة/Al Kharsaah',
        'الخريجة/Al Khareeja',
        'الخليفات الجديدة/Al Khulaifat Al Jadeeda',
        'الخيسة/Al-Khisah',
        'الدحيليات/Al Dehailiyat',
        'الدرب/Ad Darb',
        'الدغيشيات/Al Deghaishiyat',
        'الدوحة الجديدة/Al Doha Al Jadeeda',
        'الدوحة/Doha',
        'الذخيرة/Al Dhakira',
        'الرحية/Al Rehayya',
        'الرفيق/Al Refaig',
        'الرفيق/Ar Rufayq',
        'الركيات/Ar Rakiyat',
        'الرويدة/Ar Ruwaydah',
        'الرويس الغربية/Al Ruwais West',
        'الرويس/Al Ruwais',
        'الريان الجديد/Al Rayyan Al Jadeed',
        'الريان القديم/Al Rayyan Al Qadeem',
        'الريان/Ar-Rayyan',
        'الزبارة/Al Zubara',
        'الزرقاء/Az Zarqa',
        'السدري/Al Sidri',
        'السلطه الجديده/As Salatah al Jadidah',
        'السلطه/Al Salata',
        'السمرية/Al Samriya',
        'السمرية‎/Al Samriya',
        'السهلة الشمالية/Al Sahla Al Shamaliya',
        'السيد السليمى سسيم بن حمد/As Sulaymi Susaym Bin Hamad',
        'الشاهنية/Al Shahniya',
        'الشحانية/Al-Shahaniya',
        'الشحانية/Ash-Shahaniyah',
        'الشــبهانـه/Al Shabhana',
        'الشفا اللحية/Ash Shafallahiyah',
        'الشكيكات/Al Shekaikat',
        'الشليبدينات/Al Shelaibdeenat',
        'الصدرية/As Sidriyah',
        'الصعلوكية/Al Saalookiya',
        'الظاهرة/Ad-Dahirah',
        'العامرية/Al Aamriya',
        'العريش/Al-ʽArish',
        'العقدة/Al Egda',
        'العوينة/Al Uwaynah',
        'الغاف/Al Ghaf',
        'الغشامية/Al Ghashamiya',
        'الغشامية/Ghasham',
        'الغويرية/Al-Ghuwayriyah',
        'القریه/Al Ghariyah',
        'الكرعانة/Al-Kiranah',
        'الكعبية/Al Ka`Biyah',
        'الكعبية/Al Qa`Abiyah',
        'المايدة/Al Mayeda',
        'المبتعدة/Al Mebtaeda',
        'المرخية الغربية/Al Markhiya West',
        'المرقاب الجديد/Al Mirqab Al Jadeed',
        'المشرب/Al Mashrab',
        'المعموره/Al Maamoura',
        'المفجر/Al Mafjar',
        'المنتزة/Al Muntazah',
        'المنطقة الصناعية الخور/Al-Khor Industrial Area',
        'النعمان/Al Numan',
        'النهيدين/Al Nehaidain',
        'الهتمي/Al Hitmi',
        'الهشيمية/Al Hasheemiya',
        'الهملة/Al Hamla',
        'الوب/Al Wa`B',
        'الوجبة/Al Wajba',
        'الوكرة جنوب غرب/Al Wakra South West',
        'ام اسميرة/Umm Smaira',
        'ام اعنيق/Umm Enaig',
        'ام الجراثيم/Umm Al Jaratheem',
        'ام الرمرام/Umm Al Rumram',
        'ام الزبار الغربية/Umm Al Zubar West',
        'ام الصوب/Umm Al Suwab',
        'ام القهاب الغربية/Umm Al Quhab',
        'ام القهاب الغربية/Western Umm Al-Qhab',
        'ام القهاب‎/Umm Al Qahab',
        'ام جاسم/Umm Jasim',
        'ام دحل/Umm Dahal',
        'ام سدره/Umm Sidra',
        'ام سمرة/Umm Samra',
        'ام قرين/Umm Grain',
        'ام لبرك/Umm Lebrak',
        'ام لخيا/Umm Lekhya',
        'ام لقهاب/Umm Leghab',
        'ام وادي/Umm Wadi',
        'ام وشاح/Umm Wishah',
        'امهات حيش/Ummhat Heesh',
        'بئر زكريت/Bir Zekrit',
        'بئر زكريت/Bir Zikrit',
        'برقا الخراز/Barga Al Kharaz',
        'بركة العوامر/Birkat Al Awamer',
        'بركة العوامر‎/Birkat Al Awamer',
        'بركة رابعة/Birkat Rabiah',
        'بروق/Broog',
        'بلدية الشمال/Madinat Ash Shamal',
        'بو اصوي/Bu Swayy',
        'بو اليوابي/Bul Yuwabi',
        'بو بريكة/Bu Braika',
        'بو ثيله/Bu Thaila',
        'بو حصيه/Bu Hesayya',
        'بو صليلة/Bu Slaila',
        'بو عنز/Bu Anz',
        'بو غميلة/Bu Ghameela',
        'بو غولانة/Bu Ghawlana',
        'بو فاس/Bu Fass',
        'بو فسيلة/Bu Fesseela',
        'بو قرن/Bu Garn',
        'بير الحسين/Bir Al Husayn',
        'تنباك/Tinbak',
        'ثرب/Tharb',
        'جبل مرمي/Jabal Marmi',
        'جر خليل/Jarr Khaleel',
        'جري الخنجر/Jery Al Khanjar',
        'جري الدابي/Jery Al Dabi',
        'جري الذياب/Jery Al Theyab',
        'جري المطروشي/Jery Al Matrooshi',
        'جري بو جفير/Jery Bu Jefeer',
        'جري لحميدات/Jery Lehmaidat',
        'جريان البطنة/Jeryan Al Butna',
        'جريان الريوق/Jeryam Al Reyoog',
        'جزيرة الموز/Banana Island',
        'جزيرة رأس ركن/Jazirat Ras Rakan',
        'جو اسريع/Jaww Esrayya',
        'جو الباب/Jaww Al Bab',
        'جو البنات/Jaww Al Banat',
        'جو النصلة/Jaww Al Nasla',
        'جو بشير/Jaww Basheer',
        'حينة المعشاب/Heenat Al Meashab',
        'خاصومة/Khasooma',
        'خوزان/Khawzan',
        'راس الغارية/Ras Al Ghariya',
        'راس بروق/Ras Broog',
        'راس بو فنطاس/Ras Bu Fontas',
        'راس عشيرج/Ras Eshairij',
        'راس مطبخ/Ras Matbakh',
        'رشيدة/Rasheeda',
        'روضة الارنب/Rawdat Al Arnab',
        'روضة الجهانية/Rawdat Al Jahhaniya',
        'روضة العجوز/Rawdat al Ajuz',
        'روضة الفرس/Rawdat Al Faras',
        'روضة بخيلة/Rawdat Bakheela',
        'روضة جرة/Rawdat Jarra',
        'روضة حوطان/Rawdat Hawtan',
        'روضة مريغان/Rawdat Mraighan',
        'زغب/Zaghab',
        'زغين البحث/Zughayn al Bahth',
        'زكريت/Zekreet',
        'سباريت/Sebareet',
        'سدرية الخيسة/Sidriyat Al Kheesah',
        'سليمي البمبره/Selaimi Al Bambara',
        'سمسمة/Simsimah',
        'سميسمة/Sumaysimah',
        'سوق الجملة/Wholesale Market',
        'سويدات الورق/Swaidat Al Warag',
        'شرق الرميلة/Al Rumaila East',
        'صبيحة/Sbaiha',
        'صنيع لحميدي/Sunay Lehmaidi',
        'عصمة/Asma',
        'عين سنان/Ayn Sinan',
        'عين صالح/Ain Saleh',
        'غرب الرميلة/Al Rumaila West',
        'غرب وادي السيل/Wadi Al Sail West',
        'فريج العلي/Fereej Al Ali',
        'فريج محمد بن جاسم/Fereej Mohammed Bin Jasim',
        'قصاصيل/Qasasil',
        'قطر/Film City',
        'قلعة الثقب/Qalaat al Thaqab',
        'لبرقة/Lebrega',
        'لبريثات/Lebraithat',
        'لجذيع/Lejthaya',
        'لحسنية/Lehsiniya',
        'لحنيزيبة/Lehnaizeeba',
        'لخشينة/Lekhshaina',
        'لخضيرا/Lekhdaira',
        'لخوير/Lekhwair',
        'لعطورية/Leatooriya',
        'لعقوريات/Leagooriyat',
        'لغوير/Leghwair',
        'لقطيفية/Legtaifiya',
        'لمتيبنات/Lemtaibnat',
        'لمزرع/Lemzarra',
        'لمشاش/Lemshash',
        'لوبرة/Lubara',
        'لوسيل الغربية/Lusail West',
        'ليشا/Lisha',
        'مدينة الكعبان/Madinat al Kaban',
        'مدينة بروة/Barwa City',
        'مزرعة الغشام/Mazraat al Ghisham',
        'مزرعة ترينة/Mazraat Turayna',
        'مسيعيد - المنطقة الحرة/Mesaieed-Free Zone',
        'مسيكة/Musaykah',
        'مشاش وادي الذياب/Imshash Wadi Al Theyab',
        'مشروع اللؤلؤة/The Pearl Island',
        'مشيرب/Mushaireb',
        'مشيرب/Musherib',
        'معيذر ام الزبار/Muaither Umm Al Zubar',
        'مكينس/Mekaines',
        'مكينس/Mukaynis',
        'منطقة التخطيط 33/Planning Zone 33',
        'منطقة النعيم/Alnuaimi Area',
        'نبت الأصيل/Nabat al Usayl',
        'نقا بو بركة/Nega Bu Birka',
        'نقا بو قطبتين/Nega Bu Gutbetain',
        'وادي الجاهلية/Wadi Al Jahiliya',
        'وادي الجلتة/Wadi Al Jalta',
        'وادي الدحول/Wadi Al Dehool',
        'وادي الغربان/Wadi Abal Gherban',
        'وادي الغربان/Wadi Al Gherban',
        'وادي المجهولة/Wadi Al Majhoola',
        'وادي الهرم/Wadi Al Harm',
        'وادي الوعب/Wadi Al Waab',
        'وادي امهلط/Wadi Imhalat',
        'وادي جلا/Wadi Jallal',
        'وادي لهولة/Wadi Lehwela',
        'وعب المشرب/Waab Al Mashrab',
        'وعب لبارق/Waab Labareg',
    ],
    'Saudi Arabia': [
        "'Abualtuyur",
        'AD DILAM',
        "AD DIR'IYAH",
        'AD DUWADIMI',
        'AHAD AL MUSARIHAH',
        'AHAD RIFAYDAH',
        "AL 'ARIDAH",
        "AL 'UWAYQILIYAH",
        "AL ADARI'",
        'AL AQIQ',
        'AL ARDHIYAH AL SHIMALIYAH',
        'AL ARTAWIYAH',
        'AL ASYAH',
        "AL BADA'",
        "AL BADAI'",
        'AL BIJADIYAH',
        'AL BUKAYRIYAH',
        'AL GHAT',
        'AL HADDAR',
        'AL HAFUF',
        'AL HAMR',
        'AL HARAJAH',
        'AL HILALIYAH',
        'AL HILWAH',
        'AL HINAKIYAH',
        'AL ISAWIYAH',
        'AL JISH',
        'AL JUBAIL',
        'AL JUMUM',
        "AL KHABRA'",
        'AL KHAFJI',
        'AL KHARJ',
        'AL KHURMAH',
        'AL LITH',
        'AL MAJARDAH',
        'AL MAJMAAH',
        'AL MANDAQ',
        'AL MARMUTHAH',
        'AL MIDHNAB',
        'AL MUBARRAZ',
        'AL MUKHWAH',
        'AL MUZAHIMIYAH',
        'AL QASAB',
        'AL QATIF',
        'AL QAWZ',
        'AL QAYSUMAH',
        'AL QUNFIDHAH',
        'AL QURAYYAT',
        "AL QUWAY'IYAH",
        'AL ULA',
        'AL UMRAN',
        'AL UYAINAH',
        'AL UYUN',
        'AL WAJH',
        'ALHASAT',
        'AN NABHANIYAH',
        'AN NAMAS',
        "AN NAQI'",
        "AN NU'AYRIYAH",
        "AR RAFI'AH",
        'AR RASS',
        'AR RAYN',
        'AR RUWAYDAH',
        'AS SUDAH',
        'AS SULAYYIL',
        'ASH SHAMLI',
        'ASH SHIMASIYAH',
        'ASH SHINAN',
        'AT TAIF',
        'AZ ZULFI',
        'Aba Alworood',
        'Abayt',
        'Abha',
        'Abha Manhal',
        'Abu Ajram',
        'Abu Al Arj',
        'Abu Al Karsh',
        'Abu Al Nurah',
        'Abu AlSadad',
        'Abu Arish',
        'Abu Arradeef',
        'Abu Danqur',
        'Abu Dhiba',
        'Abu Hadriyah',
        'Abu Hajar',
        'Abu Haramel',
        'Abu Muloh',
        'Abu Rakah',
        'Abu Rakh',
        'Abu Shakeyr',
        'Ad Dahinah',
        "Ad Dalfa'ah",
        'Ad Darb',
        'Ad Difinah',
        'Ad Disah',
        'Ad Dumayriyah',
        'Ad Duram',
        'Ad Duwwarah',
        'Adal',
        'Addayer',
        'Adham',
        'Afif',
        'Aflaj',
        'Afqara',
        'Afraa',
        'Ahad Bani Zayd',
        'Ahad Masarha',
        'Aiban',
        'Ain Al Nakhal',
        'Ain Dar',
        'Airj',
        "Al 'Ataya",
        "Al 'Uwainid",
        'Al Abadilah',
        'Al Abar',
        'Al Abbasiyah',
        'Al Abdaleyah',
        'Al Abwa',
        'Al Abyar',
        'Al Aeen',
        'Al Ahmar',
        'Al Ainah',
        'Al Ais',
        'Al Aithmah',
        'Al Ajfar',
        'Al Akhal',
        'Al Alloleiah',
        'Al Amaaria',
        'Al Amar',
        'Al Amlah',
        'Al Ammarah',
        'Al Amoah',
        'Al An Nanisah',
        'Al Aqal',
        'Al Aradiyah',
        'Al Araq',
        'Al Arin',
        'Al Arja',
        'Al Arooj',
        'Al Aroos',
        'Al Artawi',
        'Al Aseelh',
        'Al Ashah',
        'Al Assim',
        'Al Assiyah',
        'Al Atawilah',
        'Al Atheeb',
        'Al Athlah',
        'Al Athowan',
        'Al Awamer',
        'Al Awshaziyah',
        'Al Aybah Al Ulya',
        'Al Azaherah',
        'Al Azzah',
        'Al Badawi',
        'Al Badayea',
        'Al Badi Al Qarafi',
        "Al Badi'",
        'Al Badie Al Janobi',
        'Al Badie Al Shamali',
        'Al Baheem',
        'Al Baqasha',
        'Al Barakah',
        'Al Barzah',
        'Al Bashair',
        'Al Bashayer',
        'Al Batha',
        'Al Batilah',
        'Al Birk',
        'Al Birkah',
        'Al Bkhtah Central',
        'Al Braber',
        'Al Buraym',
        'Al Butayn',
        'Al Dagharir',
        'Al Dalemya',
        'Al Dhahreyah',
        'Al Dheelah',
        'Al Dughaimiyah',
        'Al Dulaymiyah',
        'Al Duraeah',
        'Al Edabi',
        'Al Eshash',
        'Al Etanah',
        'Al Faarah',
        'Al Faidh',
        "Al Faqa'ali",
        'Al Faqirah',
        'Al Faraah',
        'Al Fareeq',
        'Al Farhaniyah',
        'Al Farridah',
        'Al Farshah',
        'Al Fatiha',
        'Al Fatkha',
        'Al Fawwarah',
        'Al Fera',
        'Al Figrah',
        'Al Foqaha',
        'Al Furaysh',
        'Al Fuwayliq',
        'Al Gabal Al Asoad',
        'Al Gafrat',
        'Al Gamri',
        'Al Gandla',
        'Al Gawamshah',
        'Al Gayed',
        'Al Ghafel',
        'Al Ghamar',
        'Al Gharayyib',
        'Al Ghayl',
        'Al Ghazalah',
        'Al Ghulah',
        'Al Ghurabah',
        'Al Ghuzlani',
        'Al Gofol',
        'Al Habeel',
        'Al Habib',
        'Al Habjeia',
        'Al Hadan',
        'Al Hadidiyah',
        'Al Hadithah',
        'Al Hadror',
        'Al Hafayer',
        'Al Hajrah',
        'Al Hajrain',
        'Al Hamad',
        'Al Haqla South',
        'Al Haqu',
        'Al Harara',
        'Al Haridhah',
        'Al Hariq',
        'Al Hasamah',
        'Al Hassa',
        'Al Hasuniyyah',
        'Al Hathera',
        'Al Hati',
        'Al Hawtah',
        'Al Hayathem',
        'Al Hayeet',
        'Al Hayyaniyah',
        'Al Hazim',
        'Al Hefnah',
        'Al Hejanbah',
        'Al Henayah',
        'Al Hendeyah',
        'Al Heno',
        'Al Heyaj',
        'Al Hiddah',
        'Al Hijf',
        'Al Hijif',
        'Al Hinnah',
        'Al Hufayr',
        'Al Hufayyirah',
        'Al Hulayfah As Sufla',
        'Al Humaid',
        'Al Humidah',
        'Al Humiyat',
        'Al Humrah',
        'Al Hunayy',
        'Al Husayni',
        'Al Husayniyah',
        'Al Husayy',
        'Al Huwaya',
        'Al Huwayd',
        'Al Ijliyah',
        'Al Jabiriyah',
        "Al Jade'a",
        'Al Jadid',
        'Al Jadida',
        'Al Jafdor',
        'Al Jafr',
        'Al Jaizah',
        'Al Jara',
        'Al Jaradiyah',
        'Al Jarf',
        'Al Jarn',
        'Al Jawah',
        'Al Jawshan',
        "Al Jazza'h",
        'Al Jerisiyah',
        'Al Jernafh',
        'Al Jialah',
        'Al Jifah',
        'Al Jihfah',
        'Al Joroof',
        "Al Ju'ranah",
        'Al Jubaylah',
        'Al Judaida',
        'Al Judaieen',
        'Al Kadami',
        'Al Kadarah',
        'Al Kadhra Al Shamalia',
        'Al Kadis',
        'Al Kahafah',
        'Al Kamil',
        'Al Karbus',
        'Al Khabbah',
        'Al Khadra Al Janoubia',
        'Al Khalas',
        'Al Khaldiyah',
        'Al Khaniq',
        'Al Kharabah',
        'Al Kharkhir',
        'Al Kharma',
        'Al Kharma Al Shimaliah',
        'Al Khashabiyah',
        'Al Khashah',
        'Al Khasiybah',
        'Al Khasrah',
        'Al Khelfh',
        'Al Khitah',
        'Al Khobh',
        'Al Khoms',
        'Al Khuffiyah',
        'Al Khunfusah',
        'Al Khuraybah',
        'Al Khushaybi',
        'Al Khuznah',
        'Al Kihayfiyah',
        'Al Lahabah',
        'Al Lahien',
        'Al Laqayit',
        'Al Laqiya',
        'Al Lsawiyah',
        "Al Ma'abooj",
        'Al Mada',
        'Al Madaya',
        'Al Madeed',
        'Al Madha',
        'Al Mahadhin',
        'Al Mahani',
        'Al Mahd',
        'Al Mahdam',
        'Al Malqa',
        'Al Mansoria',
        'Al Maqnah',
        'Al Maqrah',
        'Al Marameh',
        'Al Marrekheiah',
        'Al Masgi',
        'Al Maskhyah',
        'Al Massamah',
        'Al Mataein',
        'Al Matrafiyah',
        'Al Mawarid',
        'Al Mayyah',
        'Al Mehalhala',
        'Al Meharrq',
        'Al Mejammah',
        'Al Mejannah',
        'Al Meqarqam',
        'Al Mesaidah',
        'Al Mindassah',
        'Al Minjarh',
        'Al Mishaliah',
        'Al Mishilasah',
        'Al Mogali',
        'Al Mohammed',
        'Al Mothalath',
        "Al Mu'ashibah",
        'Al Muaeliah',
        'Al Mubarak',
        'Al Mubarakah',
        'Al Mudayyih',
        'Al Mudhaylif',
        'Al Mufrihat',
        'Al Mukaily',
        'Al Mulaylih',
        'Al Muqarah',
        "Al Muraba'a",
        'Al Murayfiq',
        'Al Musayjid',
        'Al Mushaytiyah',
        'Al Mustajiddah',
        'Al Muwaileh',
        'Al Muwassam',
        'Al Nabah',
        'Al Nafei South',
        'Al Nagaf',
        'Al Najameiah',
        'Al Nasfah',
        'Al Nashifah',
        'Al Neqrah',
        'Al Nkheel',
        "Al Nuaibea'a",
        'Al Nur',
        'Al Nuzha',
        'Al Ous',
        'Al Petra',
        "Al Qa'",
        'Al Qadeimah',
        'Al Qahmah',
        'Al Qaid',
        'Al Qaiyah',
        'Al Qalibah',
        'Al Qanboor',
        'Al Qaraa',
        'Al Qarah',
        'Al Qarn',
        'Al Qattan',
        'Al Qirah',
        'Al Qouz',
        'Al Qrash',
        'Al Quamah',
        'Al Qulayyib',
        'Al Qura',
        'Al Qurainah',
        'Al Qurayn',
        'Al Quwah',
        'Al Quwarah',
        'Al Radha',
        'Al Rafaya',
        'Al Rathaya',
        'Al Rayyan',
        'Al Reeth',
        'Al Rehab',
        'Al Rokobah',
        'Al Runah',
        'Al Sahalil',
        'Al Sahban Subdivision',
        'Al Salaf',
        'Al Salamah',
        'Al Salhaniyah',
        'Al Sarhan',
        'Al Sawarima',
        'Al Sedr',
        'Al Selselah',
        'Al Sewideiah',
        "Al Sha'afoleiah",
        'Al Shaaf',
        'Al Shaafoleiah',
        'Al Shabain',
        'Al Shabhah',
        'Al Shabitah',
        'Al Shadakh',
        'Al Sharjah',
        'Al Shegrah',
        'Al Shfeah',
        'Al Shlayil',
        'Al Shuqaiq',
        'Al Sir',
        'Al Subaykhah',
        'Al Sufah',
        'Al Sulaimi',
        'Al Taraf',
        'Al Tarafayh Al Sharqiyah',
        'Al Teraibel',
        'Al Thamad',
        'Al Thuqbah',
        'Al Tiraq',
        'Al Torkiyah',
        'Al Turshiah',
        'Al Uferiah',
        'Al Uqdah',
        'Al Uwayqilah',
        'Al Uyaynah',
        'Al Uzaym',
        'Al Wadeen',
        'Al Wadiah',
        'Al Wahla',
        'Al Wannan',
        'Al Wasah',
        "Al Wasita'a",
        'Al Wastah',
        'Al Weted',
        'Al Wozeyh',
        'Al Wuhait',
        'Al Wusayyiah',
        'Al Zabira',
        'Al khobar',
        'Al-Adwa',
        'Al-Asamlah Alkobra',
        'Al-Batna',
        'Al-Fuwayliq',
        'Al-Hourani',
        'Al-Matan',
        'Al-Mnsala',
        'AlJahow',
        "AlMu'tadil",
        'Alaliya',
        'Alamrah Al Olyah',
        'Alamrah Al Soflahg',
        'Alarish',
        'Alarjeen',
        'Alashah',
        'Alashwah',
        'Alazhar',
        'Albahir',
        'Albaisary',
        'Albakhtah North',
        'Albakhtah South',
        'Albatinah',
        'Aldaghasah',
        'Aldhabyah',
        'Alduwaid',
        'Aleumud',
        'Alfaraaien',
        'Alfawaleqah',
        'Alfisalah Wal Dagharih',
        'Algayed',
        'Alhada',
        'Alhaqawih',
        'Alhawamidah Jahish',
        'Alhergah',
        'Alhijaz',
        'Alhijfar',
        'Alhilah',
        'Alhoseniah',
        'Alhrateem',
        'Alhumaimah',
        "Alja'adiah",
        'Aljfarah',
        'Alkhalayef',
        'Alkhawaliduh',
        'Alkhhafah',
        'Alkwamlah',
        'Alkwifriah',
        'Allaghamees',
        'Almahalah',
        'Almajdirah',
        'Almaqdearah',
        'Almarabi',
        "Alme'azab",
        'Almijasas',
        'Almnadh',
        'Almorshediah',
        'Almuruj',
        'Almuruj Al Shimali',
        'Almuzaylif',
        'Almuzayri',
        'Alomaireyah',
        'Aloqdah',
        'Alqalh',
        'Alqarei',
        'Alqasadah',
        'Alquaba',
        'Alrawnah',
        'Alsadliyah',
        'Alsadyah',
        'Alsawalimah',
        'Alsawarimah',
        'Alshawajirat Wal Jarabiyah',
        'Alshuqayri',
        'Alshwaaq',
        'Alsilaa',
        'Alsourah',
        'Alwahlah',
        'Alwasly',
        'Alwasqah',
        'Alyutamah',
        'Alzawyah',
        'Amaaer Ben Sanaa',
        'Amaq',
        'An Nabk Abu Qasr',
        'An Nafiah',
        'An Nawwariyyah',
        'An Nusbah',
        'Aqiq',
        'Ar Rawdah',
        'Ar Rayis',
        'Ar Rayjah',
        'Ar Rishawiyah',
        'Ar Rudaymah',
        'Ar Ruqi',
        'Ar Ruwaidah',
        'Arab',
        'Arar',
        'Ardah',
        'Arya',
        'As Saban',
        'As Sadawi',
        'As Saddain',
        'As Saddain Balharith',
        'As Saffaniyah',
        'As Safra',
        'As Sahhar',
        'As Sail Al Kabeer',
        'As Salhiya',
        'As Sarf',
        'As Sarrar',
        'As Sayl As Saghir',
        'As Shwimes',
        'As Siayyirah',
        'As Sihaf',
        'As Silaa',
        'As Suairah',
        'As Sufayri',
        'As Sufun',
        'As Suh',
        'As Sulaimaniyah',
        'As Sulubiayh',
        'As Sumayrah',
        'As Sunayna',
        'As Suwadah',
        'As Suwadirah',
        'Asbtar',
        'Asfan',
        'Ash Shaalaniyah',
        'Ash Shafa',
        'Ash Shahmah',
        'Ash Shamlul',
        'Ash Shananah',
        "Ash Shara'I",
        'Ash Sharif',
        'Ash Shihiyah',
        'Ash Shihyah',
        "Ash Shu'ara",
        'Ash Shubah',
        'Ash Shubaykiyah',
        'Ash Shumaysi',
        'Ashayrah',
        'Assalmanyah',
        'Asuwayq',
        'Asuwayriqyah',
        'At Tahiriyah',
        'At Tarafiyah',
        'At Tawdihiyah',
        'At Tuwaim',
        'At Tuwal',
        'At Tuwalah',
        'At Tuwayr',
        'Atawleh',
        'Ateef',
        'Ath Thamiriyah',
        'Ath Thybiyah',
        'Awamiah',
        'Awirah',
        'Ayn Fuhayd',
        'Ayn Ibn Fuhayd',
        'Az Zafir',
        'Az Zalal',
        'Az Zemah',
        'Az Zughayn',
        'BAHAH',
        'BANI HASAN',
        "BAQ'A",
        'BILLAHMAR',
        'BILLASMAR',
        'BIR IBN HERMAS',
        'BISH',
        'BUQAYQ',
        'BURAIDAH',
        'Bada',
        "Badaa'i Alsadiah",
        'Badaya',
        'Badr',
        'Badr Al Janoub',
        'Bahara',
        'Bahr Abu Sukaynah',
        'Bahrah',
        'Bahrat Al Moujoud',
        'Baish Alolia',
        'Balqarn',
        'Bani Amr',
        'Bani Horairah',
        'Bani Kabir',
        'Bani Mazin',
        'Bani Mouhamad',
        'Bani Saad',
        'Baqa Ash Sharqiyah',
        "Baqa'A",
        'Bareq',
        'Bariq',
        'Bashout',
        'Bayda Natheel',
        "Beda'a Ben Khalaf",
        'Bidah Valley',
        'Biljurashi',
        'Bir Askar',
        'Birk',
        'Bishah',
        'Bny Yazed',
        'Burudan',
        'Butishah',
        'DARIN',
        'DARIYAH',
        'DHAHRAN AL JANUB',
        'DUBA',
        'DUKHNAH',
        'DURUMA',
        "Da'a",
        'Dabir',
        'Dahaban',
        'Damad',
        'Dammam',
        'Darb',
        'Dawmat Al Jandal',
        'Dayhamah',
        'Deli Rashid',
        'Deraab',
        'Dhahban',
        'Dhahran',
        'Dhahran Al Janoob',
        'Dhalm',
        'Dhubaya Jumah',
        'Doos',
        'Doqah',
        'Dremeha',
        "Dubay'ah",
        'Dulay Rasheed',
        'Dulayhan',
        'Economic City',
        'Edadh',
        'Ein As Suwaine',
        'Either',
        'Enaker',
        'Establatal Taraf',
        'Fadhla',
        'Fayd',
        'Faydah',
        'Faydat Athqab',
        'Faydat Ibn Suwaylim',
        'Fayfa',
        'Fels',
        'Feyadh Tabrjal',
        'Gayal',
        'Ghaf Al Jawa',
        'Ghamrah',
        'Ghanwa',
        'Ghazaial',
        'Ghazalah',
        'Ghizlan',
        'Ghomygah',
        'Ghran',
        'Ghtai',
        'Gilwa',
        'Gizan Economic City',
        'HAFAR AL BATIN',
        'HARMAH',
        'HAWTAT SUDAIR',
        'HUBUNA',
        'Habbes',
        'Habil',
        'Hadaad Bani Malek',
        'Hadabah',
        'Hadda',
        'Haddadah',
        'Haddat Ash Sham',
        'Hafir Kishb',
        'Hafirat Nisah',
        'Hafr Al Atk',
        'Hail',
        'Hajanbah',
        'Hajar',
        'Hakimat Abu Arish',
        'Hakimat Al Dagharir',
        'Hakimatabi Arish',
        'Hakimatal Daghadir',
        'Halaba',
        'Halahmishary',
        'Halat Ammar',
        'Halban',
        'Haleefah',
        'Hali',
        'Hanidh',
        'Haql',
        'Haradh',
        'Harajah',
        'Harjah',
        'Harub',
        'Hawea(Taif)',
        'Hawea/Taif',
        'Haweyah',
        'Hawiyah',
        'Hawtat Bani Tamim',
        'Hazem Aljalamid',
        'Hazwa',
        'Hedeb',
        'Hefar',
        'Hibran',
        'Hijrat Fadila',
        'Hulayfa',
        'Hullatal Ahwass',
        'Huraymila',
        'Husayniyah',
        'Husnah',
        'INAK',
        'Industrial Area',
        'Industrial City',
        'Isharah',
        'Iskan City',
        'Ithrah',
        'JALAJIL',
        'JAWATHA',
        "Ja'araneh",
        'Jafar',
        'Jamajm',
        'Jamalah',
        'Jarab',
        'Jash',
        'Jawat Shihdan',
        'Jazan',
        'Jazan City for Basic and Downstream Industries',
        'Jeddah',
        'Jilah',
        'Joraibah',
        'Joribah',
        'Judah',
        'Juha',
        'Jukhairah',
        'Julayjilah',
        'KHAYBAR',
        'KHULAYS',
        'KING ABDULLAH ECONOMIC CITY',
        'Kahlah',
        "Kara'a",
        'Karboos',
        'Keyad',
        'Khabt Saeed',
        'Khaiber Al Janoub',
        'Khaiber Al-Janoub',
        'Khairan',
        'Khamaseen',
        'Khamis Mushayt',
        'Khasawyah',
        'Khathaam',
        'Khathirah',
        'Khawarah',
        'Khbash',
        'Khef Hussain',
        'Khodaria',
        'Khudair',
        'Khudayrat Ayash',
        'Khumsiyah',
        'Khurais',
        'Khursaniyah',
        'King Abdullah Housing',
        'King Khalid Military City',
        'Kubadah',
        'LAYLA',
        'Lahumah',
        'Laith',
        'Linah',
        'MAHD ADH DHAHAB',
        'MARAT',
        'Madain Saleh',
        'Madinah',
        'Madrak',
        'Madrakah',
        'Maghsheiah',
        'Magna',
        'Mahad Al Dahab',
        'Mahalah',
        'Mahleiah',
        'Makkah',
        'Makshosh',
        'Malakan',
        'Malha',
        'Malham',
        'Mandak',
        'Manifah',
        'Manshabah',
        'Mansiyah',
        'Maqra',
        'Marabah',
        'Marj Allsoban',
        'Masader',
        'Masliyah',
        'Mastorah',
        'Mastura',
        'Mawqaq',
        'Maysaan',
        'Meegowa',
        'Mijannah',
        'Mikhwa',
        'Minwa',
        'Mishalah',
        'Mishash Jurud',
        'Mizhirah',
        'Mogayra',
        'Mokhtara',
        'Morighan',
        'Mubayid',
        'Mubhel',
        'Mudarraj',
        'Mudaysis',
        'Muhayil',
        'Muhayriqah',
        'Mulaija',
        'Mulayh',
        'Mulayjah',
        'Musaylah',
        'Muthaleif',
        'Muwassam',
        'Muwayh',
        'Muzneb',
        'Nabat',
        'Nabiya',
        'Nahufah Agricultural Subdivision',
        'Najran',
        'Najran Regional Airport',
        'Namas',
        'Namerah',
        'Naylat',
        'Nebak',
        'New Ain Dar',
        'New Aseer Industrial Zone',
        'New Muwayh',
        'Nifi',
        'Nimra',
        'Nimran',
        'Nisab',
        'Nita',
        'Nkheelan',
        'Noweirieh',
        'Nubayha',
        'Nwariah',
        'Ojam',
        'Old Ain Dar',
        'Osran',
        'Othmanyah',
        'Ottoman fortress',
        'Oyaynah',
        'Oyoon Al Jawa',
        "QARYAT AL 'ULYA",
        'QIBAH',
        'Qahmah',
        'Qaim Al Khamseen',
        'Qaim Aldash',
        'Qais',
        'Qampourah',
        'Qana',
        'Qanbourah',
        'Qara',
        'Qarah',
        'Qasab',
        'Qasr Ibn Ogail',
        'Qays',
        'Qaysoomah',
        'Qbah',
        'Qia',
        'Qilwah',
        'Qina',
        'Qlayyb Khedr',
        'Qouzal Jaafrah',
        'Qudaid',
        'Qufar',
        'Qusaiba',
        "RAFHA'",
        'RAWDAT SUDAIR',
        'RIYAD AL KHABRA',
        'Rabigh',
        'Radifah',
        'Radwan',
        'Rafaya Al Jamsh',
        'Rahal Hagagah',
        'Rahat',
        'Rahima',
        'Rajlaal Janubiyyah Agricultural Subdivision',
        'Rajlaash Shamaliyyah Agricultural Subdivision',
        'Ramada',
        'Rania',
        'Ranyah',
        'Ras Al Khair',
        'Ras Al Kheir',
        'Ras Tannurah',
        'Rawd Ibn Hadi',
        'Rawdat Al Hisu',
        'Rawdat Habbas',
        "Redesal A'laa",
        'Redesal Asfal',
        "Rejal Alma'a",
        'Riaa Albekr',
        'Rijal Alma',
        'Riyadh',
        'Rojal',
        'Rowdat Sodair',
        'Rumah',
        'Ruwayghib',
        'Rwaydah',
        "SABT AL 'ALAYAH",
        'SAKAKA',
        'SARAT ABIDAH',
        "SHAQRA'",
        'SHARURAH',
        'SUWAYR',
        "Sa'Ad",
        'Saabar',
        'Sabbath Shumran',
        'Sabkah',
        'Sabt Aljarah',
        'Sabt El Alaya',
        'Sabya',
        'Sadyan',
        'Safanyah',
        'Safinah',
        'Safwa',
        'Sahna',
        'Sajir',
        'Salamahal Darag',
        'Salamh Al Darraj',
        'Salasil',
        'Salbookh',
        'Salbukh',
        'Salm Alzwaher',
        'Salwa',
        'Samakh',
        'Samhah',
        'Sammed',
        'Samrat Al Jed',
        'Samtah',
        'Saq',
        'Saqf',
        'Sarar',
        'Sarat Obeida',
        "Satorp (Jubail Ind'l 2)",
        'Sayaa',
        'Sayadah',
        'Sayhat',
        'Sayyabah',
        'Seddeqah',
        "Sha'brir Residential Subdivision",
        'Shaghab',
        'Shari',
        'Sharma',
        'Shawyah',
        'Shaybah',
        'Shedgum',
        'Shifiyah',
        'Shigry',
        'Shinanh',
        'Shoaiba',
        'Shoqsan',
        "Shraie'e",
        'Shumeisi',
        'Shumran',
        'Shuwaq',
        'Starah',
        "Su'aydan",
        'Subaih',
        'Subheka',
        "Sude'a",
        'Suhailah',
        'Sulailah Juhainah',
        'Sumairaa',
        'Sunbah',
        'Suq al layl',
        'TABAB',
        'TANUMAH',
        'TARIB',
        "TAYMA'",
        'THADIQ',
        'TIMIAT',
        'TUBARJAL',
        'TURAIF',
        'TURBAH',
        'Tabah',
        'Tabalah',
        'Tabashah',
        'Tabrjal',
        'Tabuk',
        'Taiba',
        'Takhayil',
        "Tala'at Naza",
        'Tallan',
        'Tamniah',
        'Tanajib',
        'Tanjeeb',
        'Tanuma',
        'Tarut',
        'Tathlith',
        'Tebrak',
        'Tendaha',
        'Thabya',
        'Thaj',
        'Thar',
        'Tharmada',
        'Tharmda',
        'Thebea',
        'Thloth Al Mandhar',
        'Thumair',
        'Thuqba',
        'Thuwal',
        'Tihan',
        'Tirah',
        'Trubah',
        'Tuhayyi',
        'Tumair',
        'Turubah',
        'Tyhan (Hulat Tyhan)',
        'UMLUJ',
        'UMM AL HAMAM',
        'UYUN AL JAWA',
        'Ubayrah',
        'Udhailiyah',
        'Udhaliyah',
        'Um Alerrad',
        'Um Aljerem',
        'Um Ash Shifallah',
        'Um Saad',
        'Umm Al Furugh',
        'Umm Al Jamajm',
        'Umm Aldoom',
        'Umm Hazim',
        'Umm Rujoum',
        'Unayzah',
        'Uqlat As Suqur',
        'Uqlat Ibn Dani',
        'Uqlat Ibn Twalah',
        'Urayarah',
        'Urayja',
        'Ushaiqer',
        'Ushairat Sudair',
        'Ushayqir',
        'Utayiq',
        'Uthal',
        'Uthmaniyah',
        'Uyaynah',
        'WADI AD DAWASIR',
        'Waad Al Shamal',
        'Waaer',
        'Wadeien',
        "Wadi Al Fora'a",
        'Wadi Amoud',
        'Wadi Badhan',
        'Wadi Bin Hasbal',
        'Wadi Fatmah',
        'Wadi Ibn Hashbal',
        'Wadi Shora',
        'Wajeh (al Wajh)',
        'Walan',
        'Wushai',
        'YABRIN',
        'YADAMAH',
        'YANBU AL SINAIYAH',
        'Yaara',
        'Yalamlam',
        'Yanbu',
        'Yanbu Al Baher',
        'Yanbu Nakhil',
        'Zalom',
        'Zamzam',
        'arja',
        "آل إشعيل/Al Ishay'Il",
        'آل بيكاس/Al Bikas',
        'آل مش اب/Al Mish Ab',
        'أبو سديرة/Abu Sidayrah',
        'أبو شعيب/Abu Shuayb',
        'أبو عشرة/Abu Isharah',
        "أبو معرج/Abu Ma'raj",
        'أبوحليفاء ملح/Abo Halaefa',
        "أبيار الماشي/Bi'r al Mashi",
        'أحد ثربان/Ahad Tharban',
        'أم الشباقا/Shabagah',
        'ابن لبدة/Abn Labada',
        'اد دابا/Ad Dabbah',
        "الإسكان الخيري/Al'iskan Alkhayri",
        'الابرق/Al Abraq',
        'البرَة/Albra',
        'البزازة/Al Bazazah',
        'البعجة/Al-Biajah',
        "البعيثية/Al Bu'Aythiyah",
        'البوق/Albouq',
        'التنومه/Tanumah',
        'الجبيلي/Al Jabayli',
        'الجريرة/Al Jarerah',
        "الجعيمة/Al Juaima'H",
        'الجغادمة/Al- Jaghadimah',
        'الجمالة/Al Jammalah',
        'الجمانية/Al Jammaniyah',
        'الجيبة/Al Jayba',
        "الحائرية/Al Ha'iriyah",
        'الحمراء/Al Hamrah',
        'الخالدية/Alkhaldiyah',
        'الخضراء/Al Khadra',
        'الخيس/Al Khis',
        'الدية/Aldyah',
        'الرزح/Arrzha',
        'الرفيعة/Ar Rafiyah',
        'الرمحية/Ar Rumahiyah',
        'الرمضة/Al Ramdhah',
        'الرميضة/Rumaidah',
        'الرنيفة/Al Ranifa',
        'الرويضات/Al Ruwaydat',
        'الزاهرية/Al Zahria',
        'السطيحة/Al-Satihah',
        'الشامية/Ash Shamiyah',
        "الشعرة/Ash Sha'arah",
        'الصعاق/As Sieaq',
        'الضبيعة/Ad Dubayjah',
        'العبيديات/Aleabidiayat',
        'العجاجة/Al Ajajah',
        "العد/Al A'ad",
        'العرايد/Al Araeyd',
        'العشيرة/Al Asheyrah',
        'العصامي/Al Osami',
        "العلوة/Al 'Alawah",
        'العمق/Al Umaq',
        'العين/Al Ain',
        'الغزيز/Al Ghuzayaz',
        'الفج الكريمي/Alfaj Alkaremi',
        'اللقفة/Al Laghfah',
        'المبنى/Al Mbna',
        'المحامة/Al Mahhamah',
        'المحدار/Almhadr',
        'المحطة/Al Mahattah',
        'المصرخ/Almasrakh',
        'المعقص/Al-Moaqqas',
        'المفرق/Al Mafraq',
        'المنتزه/Al Muntazah',
        'المنصا/Almansa',
        'الميسري/Al Maysari',
        'النبعية/Al Nebaayah',
        'النبقيه/Al Naqbiyah',
        'النحيطية/An Nuhaytiyah',
        'النسيم/Al Nasim',
        'النومانية/An Nawmaniyah',
        'الوطيات/Alwtyat',
        'ام خشبة/Umm Khashabah',
        'بئر زهيميلة/Bir Zahaymila',
        'بلاد بني سهيم/Bilad Biny Suhaim',
        "بو خصيفة/Abu Khusayfa'",
        "بير الدبا/Bi'r ad Dibba",
        "ترعة/Tara'ah",
        'ترقش/Turgech',
        'ثجر/Thajir',
        'ج1/1c',
        'ج4/4c',
        'ج5/5c',
        'جفن ضب/Jafn Dabb',
        'جنيدة/Gneidah',
        'حدبة رافع/Whodabai rapon',
        'حضرة علي خنبيش/Hajrat Al Khinbish',
        'حفيرة الإيداء/Hafirat Al-Aida',
        'خربقاء/Kharbaqa',
        'دارب ال ابل/Darb al Ibl',
        'داس هو/Dasis',
        'دباية/Dubayah',
        'دلقان/Dalqan',
        'ذهبي/Gold',
        'رخو/Rakho',
        'رغوة/Reghawah',
        'رملان/Ramlan',
        "زالما/Zalma'",
        'سامسان/Samsan',
        'سبت بني بشر/Sabt Bani Bisher',
        "شعار/Sha'Ar",
        'شويهة/Shuaiha',
        'صاوي/Sawi',
        'طرقوش/Tarqush',
        'طريب/Tereeb',
        'طور آل الحسن/Tor Al Alhassan',
        'طولة/Tawala',
        'عجاج/Ajaj',
        'عرمان/Arman',
        'عسيلة/esialh',
        'عقدة/Uqdah',
        'عقلة الغويري/Euqlat Alghuyry',
        'عين البراحة/Ain Albaraha',
        'غمر/Ghamr',
        'فرع العبادلة/Alubadla Branch',
        'في الطويلة/At Tawilah',
        'قرن قريش/Qarn Quraysh',
        'قرى عالقه/Aalqa Village',
        'قرية الحصن/Alhusn Village',
        'قرية الناصرية/Al Nasseriah',
        "قرية بادي/Badi'S Village",
        'قرية غريف الحضن/Alhudnun Ghirif Village',
        'قطان/Qatan',
        'كرس عياش/Kers Ayash',
        'كلية/Klayah',
        'كما السومرية/As Sumariyah',
        'ماسيل/Masil',
        'مركز الحماطيات/Markaz Al Hamatiyat',
        'مشرف/Mushrif',
        'مشيلها/Mashilha',
        'مصعب/Musabis',
        'منفذ الخفجي/Manfadh Al Khafji',
        'ناظم/An Nazim',
        'ناوان/Nawan',
        'هيجة مرقة/Hayjah Muraqqah',
        'وادي الحياة/Wadi Al Hayat',
        'واسط/Wasit',
        'وبرة/Wabrah',
        "يرفا/Yarfa'",
    ],
    'United Arab Emirates': [
        'Abu Dhabi',
        'Abu Hail',
        'Ajman',
        'Al Ain',
        'Al Awir First',
        'Al Awir Second',
        'Al Bada',
        'Al Bandar',
        'Al Baraha',
        'Al Barsha First',
        'Al Barsha Second',
        'Al Barsha South First',
        'Al Barsha South Second',
        'Al Barsha South Third',
        'Al Barsha Third',
        'Al Buteen',
        'Al Dhagaya',
        'Al Falah',
        'Al Garhoud',
        'Al Guoz Fourth',
        'Al Hamriya Dubai',
        'Al Hamriya Port',
        'Al Hudaiba',
        'Al Jaddaf',
        'Al Jafiliya',
        'Al Jazirah Al Hamra',
        'Al Karama',
        'Al Khabisi',
        'Al Khwaneej First',
        'Al Khwaneej Second',
        'Al Kifaf',
        'Al Mamzar',
        'Al Manara',
        'Al Mankhool',
        'Al Maryah Island',
        'Al Merkad',
        'Al Mina',
        'Al Mirfa',
        'Al Mizhar First',
        'Al Mizhar Second',
        'Al Muraqqabat',
        'Al Murar',
        'Al Mushrif',
        'Al Muteena',
        'Al Muteena First',
        'Al Muteena Second',
        'Al Nahda First',
        'Al Nahda Second',
        'Al Nasr,Dubai',
        'Al Quoz First',
        'Al Quoz Industrial First',
        'Al Quoz Industrial Fourth',
        'Al Quoz Industrial Second',
        'Al Quoz Industrial Third',
        'Al Quoz Second',
        'Al Quoz Third',
        'Al Qusais First',
        'Al Qusais Industrial Fifth',
        'Al Qusais Industrial First',
        'Al Qusais Industrial Fourth',
        'Al Qusais Industrial Second',
        'Al Qusais Industrial Third',
        'Al Qusais Second',
        'Al Qusais Third',
        'Al Raffa',
        'Al Rahah',
        'Al Rahbah',
        'Al Ras',
        'Al Rashidiya',
        'Al Reem Island',
        'Al Rigga',
        'Al Sabkha',
        'Al Safa First',
        'Al Safa Second',
        'Al Safouh First',
        'Al Safouh Second',
        'Al Samhah',
        'Al Satwa',
        'Al Shahama',
        'Al Shalelah',
        'Al Shamkha',
        'Al Shawamekh',
        'Al Shindagha',
        'Al Souq Al Kabeer',
        'Al Tawelah',
        'Al Twar First',
        'Al Twar Second',
        'Al Twar Third',
        'Al Waheda',
        'Al Warqa a Fifth',
        'Al Warqa a First',
        'Al Warqa a Fourth',
        'Al Warqa a Second',
        'Al Warqa a Third',
        'Al Wasl',
        'Al Wathba',
        'Al-Yahar',
        'Aleyas',
        'Ar-Rams',
        'Arabian Ranches',
        'Ayal Nasir',
        'Bani Yas City',
        'Bateen Liwa',
        'Bu Kadra',
        'Dibba Al-Hisn',
        'Diqdaqah',
        'Dubai',
        'Dubai Investment Park Second',
        'Dubai Investment park First',
        'Emirates Hill First',
        'Emirates Hill Second',
        'Emirates Hill Third',
        'Fujairah',
        'Habshan',
        'Hatta',
        'Hor Al Anz',
        'Hor Al Anz East',
        'Huwaylat',
        'Jebel Ali 1',
        'Jebel Ali 2',
        'Jebel Ali Industrial',
        'Jebel Ali Palm',
        'Jumeira First',
        'Jumeira Second',
        'Jumeira Third',
        'Kalba',
        'Khalifa Industrial',
        'Khalifa Port',
        'Khatt',
        'Khawr Khuwayr',
        'Khor Fakkan',
        'Madinat Zayed',
        'Marsa Dubai',
        'Masafi',
        'Masdar City',
        'Mezyad',
        'Mirdif',
        'Muhaisanah Fourth',
        'Muhaisanah Second',
        'Muhaisanah Third',
        'Muhaisnah First',
        'Musaffah',
        'Mussafah',
        'Mussaffa',
        'Nad Al Hammar',
        'Nad Shamma',
        'Nadd Al Shiba Fourth',
        'Nadd Al Shiba Second',
        'Nadd Al Shiba Third',
        'Naif',
        'Palm Jumeira',
        'Port Saeed',
        'Port Zayed',
        'Ras Al Khaimah',
        'Ras Al Khor',
        'Ras Al Khor Industrial First',
        'Ras Al Khor Industrial Second',
        'Ras Al Khor Industrial Third',
        'Rigga Al Buteen',
        'SHARJAH',
        'Saadiyat Island',
        'Shakhbout',
        'Sila',
        'Swehan',
        'Sweihan',
        'Trade Centre 1',
        'Trade Centre 2',
        'UMM AL QUWAIN',
        'Umm Al Sheif',
        'Umm Hurair First',
        'Umm Hurair Second',
        'Umm Ramool',
        'Umm Suqeim First',
        'Umm Suqeim Second',
        'Umm Suqeim Third',
        'Wadi Alamardi',
        'Warsan First',
        'Warsan Second',
        'Yas Island',
        'Za abeel First',
        'Za abeel Second',
        'آل جيل/Al Ghail',
        'آل ماتاف/Al Mataf',
        'آل نهيان/Al Nahyan',
        'آل هالي/Al Haray',
        'أبراج بحيرات جميرا/Jumeirah Lake Towers',
        'أبو دَنق/Al Bu Daniq',
        'أبو شغارة/Abu Shagara',
        'أبو ظبي/Sas Al Nakhl',
        'أبوظبي الصناعية 1/Industrial City Icad I',
        'أرض المستودعات/Warehouses Lands',
        'أريالي/Alyhyali',
        'أعسمة/Asimah',
        'أغات/Adgat',
        'أكاديمية اتصالات/Etisalat Academy',
        'أكاديمية جيمس دبي الأمريكية/Gems Dubai American Academy',
        'أل ريم أ/Al Reem 2',
        'أل ريم ع/Al Reem 3',
        'أل ريم ١/Al Reem 1',
        'أم الثعوب/Umm Al Thuoob',
        'أم الطرافة/Um Tarrafa',
        'أم القيوين القديمة/Old Umm Al Quwain',
        'أم القيوين/Umm Al Quwain',
        'أم درع/Umm Dera',
        'أم سديرة/Um Sidira',
        'أم غافة/Um Ghaffa',
        'أم هرير 1/1 Umm Hurair Rd',
        'أم هرير 1/Umm Hurair 1',
        'أم هرير 2/Umm Hurair 2',
        'أوحلة/Wahlah',
        'إصفي (وادي إصفي)/Isfi (Isfi Valley)',
        'إيست ساوث ريدج/South Ridge',
        'اب تاون موتور سيتي/Up Town Motor City',
        'ابتاون مردف/Uptown Mirdiff Mall',
        'ابراج البطين/Bateen Towers',
        'ابن بطوطة مول/Jebel Ali (Ibn Battuta Mall)',
        'ابو سمرة/Abu Samrah',
        'اتصالات الفجيرة/Fujairah Telecom',
        'اخفارا/Ihfarah',
        'ارض الاحلام/Dream Land',
        'اس كي ام سي/Skmc',
        'اساطير دبي لاند/Asateer',
        'اسفاي (وادي اسفاي)/Esfai ( Wadi Esfai )',
        'اسفيني (وادي اسفيني)/Esfini ( Wadi Isfini )',
        'اصفني (وادي اصفني)/Asifni (Asifni Valley)',
        'اكاديمة الشرطة في ابوظبي/Abu Dhabi Police Academy',
        'اكاسيا افينيواكاديمية الشرطة/Dubai Police Academy',
        'الأبرب/Al Abrab',
        'الأبرق 1/Al Abraq1',
        'الأبرق 2/Al Abraq2',
        'الأبرق2/Al Abraq2',
        'الأخضر الموبارة/Green Mubazzarah',
        'الأقرن/Al Aqran',
        'الإماراتي/Emarati',
        'الابرق/Al Abraq',
        'الاتحاد/Al-Ittihad',
        'الاتحاد/Union Engineering Consultant',
        'البادية/Al Badiya',
        'البانه/Al Banah',
        'الباهية (الباهية الجديدة)/Al Bahya New',
        'الباهية/Al Bahya',
        'البثنة/Al Bithnah',
        'البحيرات/The Lakes',
        "البدع/Al Bada'A",
        'البدية/Bidiyah',
        'البراحة/Al Barahaa',
        'البراري/Al Barari',
        'البرشاء 1/Al Barsha 1',
        'البرشاء 2/Al Barsha 2',
        'البرشاء 3/Al Barsha 3',
        'البرشاء/Al Barsha',
        'البرقا/Al Barqaa',
        'البرقاء/Al Barqaa',
        'البريرات/Al Brerat',
        'البريرات/Al Burairat',
        'البطائح/Al Bataeh',
        'البطين/Al Bateen',
        'البطين/Al Bateen',
        'البطين/Al Butain',
        'البلدية/Al Baladiya',
        'البلدية/Municipal',
        'البوغه/Al Bogh',
        'البياتة/Al Biyatah',
        'البيداء/Ud Al Bayda',
        'البِحوث/Al Bihouth',
        'الترفة/Al Turfa',
        'الترفة/Al-Turfa',
        'التلال للأزياء الرجالية/Al Telal Gents Fashion',
        'التوبان/Tawban',
        'الثريا/Al Thurayya',
        'الثنية 5/5 Al Thanya St',
        'الثنيه 2/2 Al Thanya St',
        'الثنيه 3/3 Al Thanya St',
        'الثنيه 4/4 Al Thanya St',
        'الجافلية/Al Jaffiliya',
        'الجاهلي/Al Jahili',
        'الجبيل/Al Jubail',
        'الجرف 1/Al Jerf 1',
        'الجرف 2/Al Jerf 2',
        'الجرينة/Al Juraina',
        'الجلايلة/Al Jalailah',
        'الجويس/Al Juwais',
        'الجير/Al Jeer',
        'الجيمي/Al Jimi',
        'الحثمة، حي/Al Hathmah',
        'الحدائق/The Gardens',
        'الحديثة/Al Hadeetha',
        'الحرامل/Al Haramal',
        'الحزام الأخضر/Al Hezam Al Akhdar',
        'الحزام الأخضر/Green Belt',
        'الحسين/Al Husayn',
        'الحصن/Al Hisn',
        'الحضارم/Al Hadharem',
        'الحضيبة/Al Hudaibah',
        'الحلاه/Al Halah',
        'الحليفات/Al Hlaifat',
        'الحليو/Al Helio',
        'الحمرا/Al Hamra',
        'الحمرا/Al Hamrah',
        'الحمرة/Al Hamrah',
        'الحمري/Al Hamri',
        'الحمرية/Al Hamriya',
        'الحمرية/Al Hamriyah',
        'الحومة/Al Homah',
        'الحوية/Al Haweah',
        'الحويه/Al Haweah',
        'الحي الإنجليزي/England Cluster',
        'الحي الفرنسي/France Cluster',
        'الحي المغربي/Morocco Cluster',
        'الحيل الصناعية/Al Hayl Industrial',
        'الحيل/Al Hail',
        'الحيل/Al Hail',
        'الحييرة/Al Heerah',
        'الخاری/Al Khari',
        'الخالدية/Al Khalidiyah',
        'الخبيرة/Al Khubeirah',
        'الخبيصي/Al Khabaisi',
        'الخبيصي/Al Khibeesi',
        'الخران/Al Kharran',
        'الخرماء/Al Kharmaa',
        'الخريجة/Al Khuraijah',
        'الخزان/Al Khazzan',
        'الخليج التجاري/Business Bay',
        'الخوانيج 1/Al Khawaneej 1',
        'الخوانيج 2/Al Khawaneej 2',
        'الخوانيج/Al Khawaneej',
        'الخور 1/Al Khour 1',
        'الخور 2/Al Khour 2',
        'الخور الشرقي/Al Khor Al Sharqi',
        'الخور1/Al Khour 1',
        'الخيران/Al Kheeran',
        'الخيس/Al Khis',
        'الدار البيضاء/Al Dar Al Baidaa',
        'الدار لانج/Al Dar Lang',
        'الدانا/Al Dana',
        'الدانة/Al Danah',
        'الدربجانية/Al Darbijaniyah',
        'الدربيجانية/Al Darbijaniyah',
        'الدقداقة/Al Digdaga',
        'الدقداقة/Digdagga',
        'الدهماء/Al Dahma',
        'الدهيسة/Al Duhaisah',
        'الدور 1/Al Door',
        'الدور 2/Al Door',
        'الدور/Al Door',
        'الدُهَيسة/Al Duhaisah',
        'الذيد/Al Dhaid',
        'الذيد/Dhaid',
        'الرأس الأخضر/Al Ras Al Akhdar',
        'الراس 1/Al Raas 1',
        'الراس 2/Al Raas 2',
        'الراشدية/Al Rashdiya',
        'الراعفة/Al Raafah',
        'الرحمانية/Al Rahmaniya',
        'الرحيب/Al Raheeb',
        'الرحيل/Al Rahail',
        'الردة/Al Raddah',
        'الرزين/Al Razeen',
        'الرس 1/Al Raas 1',
        'الرغيلات/Al Righailat',
        'الرفاع/Al Rifa',
        "الرفاع/Al Rifa'A",
        'الرفاع/Al Riffa',
        'الرفاعة/Al Rifaah',
        'الرفاعة/Al Riffa',
        'الرقة/Al Riqah',
        'الرقة/Al Riqqah',
        'الرقعة الحمراء/Al Ruqa Al Hamra',
        'الرقيبة 1/Al Riqaibah 1',
        'الرقيبة 2/Al Riqaibah 2',
        'الرقيبة 3/Al Riqaibah 3',
        'الرقيبة 4/Al Riqaibah 4',
        'الرقيبة 5/Al Riqaibah 5',
        'الرقيبة 6/Al Riqaibah 6',
        'الرقيبة 7/Al Riqaibah 7',
        'الرقيبة 8/Al Riqaibah 8',
        'الرقيبة/Al Rigaibah',
        'الرمس - ضاية/Al Rams Dhaya',
        'الرمس/Al Rams',
        'الرملة 1/Al Ramlah1',
        'الرملة 2/Al Ramlah2',
        'الرملة 3/Al Ramlah3',
        'الرملة ١/Al Ramlah A',
        'الرملة ٣/Al Ramlah3',
        'الرملة/Al Ramlah',
        'الرميلة/Al Rimailah',
        'الروضة/Al Raudah',
        'الروضة/Al Rawdah',
        'الروضة/Al Rawdah',
        'الروضة/Al Rowdah',
        'الروضة/Kindergarten',
        'الرويضة/Al Rawaidah',
        'الرويكه/Al Ruwaikah',
        'الرياضية/Al Riyadiya',
        'الريانة/Al Rayyana',
        'الريف/Al Reef',
        'الزاهية/Al Zahia',
        'الزاهية/Al Zahiyah',
        'الزبارة/Zubara',
        'الزبارة/Zubarah',
        'الزبير 1/Alzubair 1',
        'الزبير 2/Alzubair 2',
        'الزبير 3/Alzubair 3',
        'الزبير 4/Alzubair 4',
        'الزبير 5/Alzubair 5',
        'الزبير 6/Alzubair 6',
        'الزبير 7/Alzubair 7',
        'الزبير 8/Alzubair 8',
        'الزبير/Alzubair',
        'الزعاب/Al Zaab',
        'الزهراء/Al Zahra',
        'الزوير/Al Zowair',
        'الزينة/Al Zeina',
        'السرة/Al Surrah',
        'السري/Secret',
        "السعادة/Al Sa'Adah",
        'السلامات 1/Al Salamat 1',
        'السلامات 2/Al Salamat 2',
        'السلامات/Al Salamat',
        'السلامة 1/Safety 1',
        'السلامة 2/Safety 2',
        'السلامة 3/Safety 3',
        "السلع/Al Sila'",
        'السلمة 1/Al Salamah 1',
        'السلمة 2/Al Salamah 2',
        'السلمة 3/Al Salamah 3',
        'السلمة/Al Salamah',
        'السلمة/Al Salmah',
        'السمحة شرق/Al Samha East',
        'السمحة غرب/Al Samha West',
        'السهول 2/Meadows 2',
        'السوان/Al Sawan',
        'السوان/Al Sewaan',
        'السوق الرئيسي/Central Market',
        'السويحات 1/Al Suehat 1',
        'السويحات 2/Al Suehat 2',
        'السويدي/Al Suwaifi',
        'السير/Al Seer',
        'السيف/Al Seef',
        'السينية/Al Seanneeah',
        'الشارقة/Sharjah',
        'الشامخة 1/Al Shamkha 1',
        'الشامخة 16/Al Shamkha 16',
        'الشامخة 17/Al Shamkha 17',
        'الشامخة 19/Al Shamkha 19',
        'الشامخة 2/Al Shamkha 2',
        'الشامخة 22/Al Shamkha 22',
        'الشامخة 24/Al Shamkha 24',
        'الشامخة 3/Al Shamkha 3',
        'الشامخة 6/Al Shamkha 6',
        'الشامخة 7/Al Shamkha 7',
        'الشامخة 8/Al Shamkha 8',
        'الشامخة 9/Al Shamkha 9',
        'الشامخة جنوب/Al Shamkha South',
        'الشامخة شرق/Al Shamkha East',
        'الشامخة/Al Shamkhah',
        'الشبيكة/Al Shebeakah',
        'الشرية 1/Al Sharyah 1',
        'الشرية/Al Sharyah',
        'الشريشة/Al Sharisha',
        'الشريشة/Al Sharishah',
        'الشريعة/Al Shareiah',
        'الشريعة/Al Shereiah',
        'الشص/Al Shas',
        'الشعبية/Shabiya',
        'الشليلة/Al Shaleela',
        'الشنوف 1/Al Shinouf 1',
        'الشنوف 2/Al Shinouf 2',
        'الشنوف 3/Al Shinouf 3',
        'الشنوف 4/Al Shinouf 4',
        'الشنوف 5/Al Shinouf 5',
        'الشنوف 6/Al Shinouf 6',
        'الشهامة الجديدة جنوب/New Shahama South',
        'الشهامة الجديدة/New Shahama',
        'الشهامة القديمة/Al Shahama Old',
        'الشهامة القديمة/Old Shahamma',
        'الشهامة/Al Shahamah',
        'الشهباء/Al Shahba',
        'الشوامخ حوض 1/Al Shawamekh Sector 1',
        'الشوامخ حوض 10/Al Shawamekh Sector 10',
        'الشوامخ حوض 11/Al Shawamekh Sector 11',
        'الشوامخ حوض 2/Al Shawamekh Sector 2',
        'الشوامخ حوض 3/Al Shawamekh Sector 3',
        'الشوامخ حوض 4/Al Shawamekh Sector 4',
        'الشوامخ حوض 5/Al Shawamekh Sector 5',
        'الشوامخ حوض 6/Al Shawamekh Sector 6',
        'الشوامخ حوض 7/Al Shawamekh Sector 7',
        'الشوامخ حوض 8/Al Shawamekh Sector 8',
        'الشوامخ حوض 9/Al Shawamekh Sector 9',
        'الشوامخ/Al Shawamek',
        'الشويب/Al Shiwayb',
        'الشويبه/Al Shuaibah',
        'الشويهين/Al Shuwaihean',
        'الشيس/Al Shes',
        'الشيوخ/Al Shiokh',
        'الصاروج/Al Sarooj',
        'الصال/Al Sall',
        'الصفا 1/Al Safa 1',
        'الصفا ٢/Al Safa 2',
        'الصفا/Al Safa',
        'الصفوح 1/Al Sufouh 1',
        'الصفوح 2/Al Sufouh 2',
        'الصفوح/Al Sufouh',
        'الصلال، حي/Al Selal',
        'الصناعية الجديدة/New Al Sanaiya',
        'الصور/Al Soor',
        'الصويفي/Al Tabbiyah',
        'الصير/Fir',
        'الطراق/Taraq',
        'الطرفا/Al Tarfa',
        "الطلاع/Al Tala'A",
        'الطوار ١/Al Twar 1',
        'الطوار ٢/Al Twar 2',
        'الطوار ٣/Al Twar 3',
        'الطوار/Al Twar',
        'الطوية/Al Tiwayya',
        'الطويلة/Al Taweelah',
        'الطويلة/Taweelah',
        'الطوين/At Tuwaynah',
        'الطي شرق/Al Tay East',
        'الطیبة/Tayybah',
        'الظاهر 5/Al Dhahir 5',
        'الظاهر الجديد/Al Dhahir New',
        'الظاهر/Al Dhahir',
        'الظفرة/Al Dhafra',
        'الظيت الجنوبي/Al Dhait South',
        'الظيت الشمالي/Al Dhait North',
        'العالية/Al Alia',
        'العامرة/Al Amerah',
        'العجبان/Al Ajban',
        'العذيب/Al Atheab',
        'العريبي/Al Uraibi',
        'العقابية/Al Agaabiya',
        'العقة/Al Aqdah',
        'العقة/Al Iqqah',
        'العكامية/Al Ikkamiyah',
        'العوير/Al Aweer',
        'العوينة/Al Owainah',
        'العِبَيان/Al Ibayyan',
        'الغافات/Al Gavat',
        'الغافية/Al Ghafia',
        'الغب/Al Ghub',
        'الغب/Al Ghubb',
        'الغبيبة/Al Ghubaiba',
        'الغرفة/Al Ghurfah',
        'الغليلة/Al Ghaleelah',
        'الغوب/Ghub',
        'الغيل الشعبية/Al Gail Shabiya',
        'الغيل الشعبية/Al Ghail',
        'الغيل/Ghayl',
        'الفارسي/Persia',
        'الفجيرة اتصالات/Fujairah Etisalat',
        'الفجيرة التراثية/Fujairah Emirate',
        'الفرات/Forat',
        'الفرية/Al Feriya',
        'الفشت 1/Al Fisht 1',
        'الفصيل/Al Faseel',
        'الفقيت/Al Fqait',
        'الفلاح 1/Al Falah 1',
        'الفلاح 2/Al Falah 2',
        'الفلاح 4/Al Falah 4',
        'الفورادا/Alvorada',
        "الفُوعَة/Al Fou'Ah",
        'القرائن 1/Al Qarayen 1',
        'القرائن/Al Gharayen',
        'القراين 1/Al Qraun 1',
        'القراين 2/Al Qraun 2',
        'القرم/Al Qurm',
        'القرم/Al Qurm',
        'القرهود/Garhoud',
        'القرية العالمية/Global Village',
        'القرية العربية/Arabian Village',
        'القرية المعاصرة/Contemporary Village',
        'القرية/Al Grayyeh',
        'القرية/Global Village',
        'القریاه/Al Qurayyah',
        'القصيدات/Al Qusaidat',
        'القصيص منطقة صناعية 2/Al Qusais Industrial 2',
        'القصيص منطقة صناعية ١/Al Qusais Industrial Area 1',
        'القصيص منطقة صناعية ٢/Al Qusais Industrial Area 2',
        'القصيص منطقة صناعية ٣/Al Qusais Industrial Area 3',
        'القصيص منطقة صناعية ٤/Al Qusais Industrial Area 4',
        'القصيص منطقة صناعية ٥/Al Qusais Industrial Area 5',
        'القصيص منطقة صناعية/Al Qusais Industrial Area',
        'القصيص ١/Al Qusais 1',
        'القصيص ٢/Al Qusais 2',
        'القصيص ٣/Al Qusais 3',
        'القصيص/Al Qusais',
        'القصيص/Al Twar Fourth',
        'القطارة/Al Qattarah',
        'القطينة 1/Al Gitanah 1',
        'القطينة 2/Al Gitanah 2',
        'القطينة 3/Al Gitanah 3',
        'القطينة 4/Al Gitanah 4',
        'القطينة 5/Al Gitanah 5',
        'القطينة 6/Al Gitanah 6',
        'القوز 1/Al Quoz 1',
        'القوز 2/Al Quoz 2',
        'القوز 3/Al Quoz 3',
        'القوز 4/Al Quoz 4',
        'القوز مول/Al Qouz Mall',
        'القوز/Al Quoz',
        'القيعان/Al Qaian',
        'القُسَيدات/Al Qusaidat',
        'القُسَيدات/Qusaidat',
        'الكاسر/Al Kasir',
        'الكرامة سنتر/Karama Centre',
        'الكورنيش/Corniche',
        'الكورنيش/Corniche',
        'اللؤلؤية/Hanawi Farm',
        'اللبسة/Al Lebsa',
        'اللبسه/Al Lebsa',
        'اللمغدر/Lamagdar',
        'الليان 1/Layan',
        'الليسيلي/Al Lisaili',
        'الليسيلي/Al Lissaily',
        'المارينا/Al Marina',
        'المثلوثه/Al Mathloothah',
        'المجاز/Al Majaz',
        'المجرة/Al Mujarrah',
        'المحترقة/Al Mihtaraqah',
        'المحطة/Al Mahattah',
        'المدام/Al Madam',
        'المدر 1/Al Madar1',
        'المدر 2/Al Madar2',
        'المدر 2/Al Madr 2',
        'المدر 3/Al Madar3',
        'المدر 3/Al Madr 3',
        'المدفق/Al Madfaq',
        'المدفق/Al Madfaq',
        'المدفق/Al Medfeq',
        'المديفي/Al Mudaifi',
        'المدينة الأكاديمية/Academic City',
        'المدينة الصناعية 2/Industrial City Icad Ii',
        'المدينة الصناعية بأبوظبي/Industrial City Of Abu Dhabi',
        'المدينة العالمية/International City',
        'المدينة اللوجستية/Dubai Logistics City',
        'المرابع العربية 2/Arabian Ranches 2',
        'المربعة/Al Mrabba Ah',
        'المرخانية/Al Markhaniyyah',
        'المرقبات/Al Morgbat',
        'المركز التجاري الأولي/Trade Centre1',
        'المركز التجاري الثانية/Trade Center 2',
        'المركزية غرب/Al Markaziyah West',
        'المريد/Al Mareed',
        'المزهر ١/Al Mizhar 1',
        'المزهر ٢/Al Mizhar 2',
        'المزهر/Al Mizhar',
        'المزون/Al Muzoun',
        "المسعودي/Al Mas'Oudi",
        'المشرف 1/Al Mushrif 1',
        'المشرف 2/Al Mushrif 2',
        'المشرف 3/Al Mushrif 3',
        'المشرف 4/Al Mushrif 4',
        'المشرف 5/Al Mushrif 5',
        'المصفح الشعبية/Mussafah Shabia',
        'المضيفي/Al Manzil',
        'المضيفي/Mudaifi',
        'المطار/Al Matar',
        'المطاف/Al Mataf',
        'المطاوعة/Al Mutawaa',
        'المطينة/Muteena',
        "المعترض/Al Mu'Tarid",
        'المعريض/Al Mairid',
        'المعزز جنوب/Al Moazaz South',
        'المعزز/Al Moazaz',
        "المعمورة/Al Ma'Mourah",
        'المعمورة/Al Mamourah',
        'المعيريض/Al Mairid',
        'المفرق/Al Mafraq',
        'المقام/Al Maqam',
        'المقر الرئيسي لتاكسي دبي/Dubai Taxi Corparation, Main Office 3',
        'المقطع 1/Al Maqtaa 1',
        'المقطع 1/Al Muqta 1',
        'المقطع 2/Al Muqta 2',
        'المقطع 3/Al Muqta 3',
        'الملا بلازا/Al Mulla Plaza',
        'المناصير/Al Manaseer',
        'المنامة/Al Manama',
        'المنامة/Manama',
        'المنتزه/Al Muntazah',
        'المنطقة الحرة بالحمرية/Hamriya Free Zone',
        'المنطقة الحرة مطار الشارقة/Sharjah International Airport Free Zone',
        'المنطقة الحرة/Free Zone',
        'المنطقة الصناعية/Industrial Area',
        'المنطقة الصناعية/Industrial Area',
        'المنطقة الصناعية/Industrial Area 2',
        'المنهل/Al Manhal',
        'المنيرة/Al Muneera',
        'المنيزفة/Al Mnaizfah',
        'المنيعي/Al Muniai',
        'المنيعي/Munay',
        'المهرة/Al Mahra',
        'الموردة 1/Al Mowrrada 1',
        'الموردة 2/Al Mowrrada 2',
        'الموردة 3/Al Mowrrada 3',
        'الموردة 4/Al Mowrrada 4',
        'الموردة 5/Al Mowrrada 5',
        'الموردة 6/Al Mowrrada 6',
        'الموردة 7/Al Mowrrada 7',
        'الموردة 8/Al Mowrrada 8',
        "المويجعي/Al Muwaij'I",
        'المويهات 1/Al Mowaihat 1',
        'المويهات 2/Al Mowaihat 2',
        'المويهات 3/Al Mowaihat 3',
        'الميدان/Al Medan',
        'الميدان/Al Meedan Stage',
        'النابغة/Genius',
        "النباعة/Al Naba'Ah",
        'النبغة/Al Nabgghah',
        'النجيمات/Al Nijaimat',
        'النخيل/Al Nakheel',
        'النخيلة/Al Nekhailah',
        'النخيلة/Al Nekheila',
        'الندود/Al Nudood',
        'الندية/Al Nadiya',
        'الندية/Al Nadiyah',
        'النهدة ٢/Al Nahda 2',
        'النهدة/Al Nahda',
        'النهضة الجديدة/New Al Nahdha',
        'النهضة العسكرية (شرق)/Al Nahda East',
        'النهضة العسكرية (غرب)/Al Nahda West',
        'النهضة/Al Nahdah',
        'النهضة/Al Nahdah',
        'النهضة/Al Nahdha',
        'النود/Al Noud',
        'النودود/Al Nudood',
        'النوف 1/Alnoof 1',
        'النوف 2/Alnoof 2',
        'النوف 3/Alnoof 3',
        'النوف 4/Alnoof 4',
        'النوف/Al Noaf',
        'النيفة/Al Neefah',
        'الهبوب 1/Al Huboob 1',
        'الهبوب 2/Al Huboob 2',
        'الواحة العامرة/Al Waha Al Amirah',
        'الواحة/Al Waha',
        'الوثبة شمال/Al-Wathba North',
        'الوثبة/Al Wathbah',
        'الورقاء ١/Al Warqa 1',
        'الورقاء ٢/Al Warqa 2',
        'الورقاء ٣/Al Warqa 3',
        'الورقاء ٤/Al Warqa 4',
        'الورقاء ٥/Al Warqa 5',
        'الورقاء/Al Warqa',
        'الوياية/Al Wayayah',
        'اليارية/Al Yarya',
        'الياسمين/Al Yasmeen',
        'اليحر/Al Yahar',
        'اليرموك خورفكان/Al Yarmouk',
        'اليرموك/Yarmook',
        'اليفرة 2/Al Yufrah 2',
        'الينابيع 1/Springs 1',
        'الينابيع 10/Springs 10',
        'الينابيع 11/Springs 11',
        'الينابيع 12/Springs 12',
        'الينابيع 14/Springs 14',
        'الينابيع 15/Springs 15',
        'الينابيع 2/Springs 2',
        'الينابيع 3/Springs 3',
        'الينابيع 4/Springs 4',
        'الينابيع 5/Springs 5',
        'الينابيع 6/Springs 6',
        'الينابيع 7/Springs 7',
        'الينابيع 8/The Springs 8',
        'الينابيع 9/Springs 9',
        'اليونان/Greece',
        'ام الثعوب/Umm Al Thuoob',
        'ام السلي 2/Umm Eseley 2',
        'ام السلي/Umm Eselay',
        'ام الكراوين/Umm Al Karaaween',
        'ام النار/Umm Al Nar',
        'ام سقيم 1/Umm Suqeim 1',
        'ام سقيم 2/Umm Suqeim 2',
        'ام سقيم 3/Umm Suqeim 3',
        'ام سقيم/Umm Suqeim',
        'بارك بوينت/Park Point - Dubai Hills Estate',
        'باسم سيمسيمسانية/As Simsimsaniyyah',
        'باكار/Baqal',
        'بالميرا/Palmera',
        'بر دبي/Bur Dubai',
        'برج الجابر/Al Jaber Tower',
        'برج الجابر/Burj Khalifa',
        'برج الحديقة/Garden Tower',
        'برج العرب/Burj Al-Arab',
        'برج الفجيرة/Fujairah Tower',
        'برج سكاي كورت/Skycourts Towers',
        'برج عجمان1/Ajman 1 Tower',
        'بساتين الزبير/Al Zubair Orchards',
        'بساتين الفلاح/Basateen Al Falah',
        'بطحاء العلي/Batha Al Ali',
        'بطين السمر/Butain Al Samer',
        'بلدية أبو ظبي/Abu Dhabi Muncipality',
        'بلدية الفجيرة/Fujairah Municipality',
        'بليدوع/Boo Lydoa',
        'بن اسماعيل/Bin Asmad',
        'بناية الشيخ حمدان/Mugatrah Farm Sheikh Hamdan',
        'بناية شارع البنوك/Bank Street Building',
        'بني زيد/Bani Zaid',
        'بني ياس شرق 01-2/Baniyas East 2-01',
        'بني ياس شرق 02-2/Baniyas East 2-02',
        'بني ياس شرق 03-2/Baniyas East 2-03',
        'بني ياس شرق 04-2/Baniyas East 2-04',
        'بني ياس شرق 05-2/Baniyas East 2-05',
        'بني ياس شرق 1/Baniyas East 1',
        'بني ياس شرق 10/Baniyas East 10',
        'بني ياس شرق 11/Baniyas East 11',
        'بني ياس شرق 13/Baniyas East 13',
        'بني ياس شرق 2/Baniyas East 2',
        'بني ياس شرق 3/Baniyas East 3',
        'بني ياس شرق 4/Baniyas East 4',
        'بني ياس شرق 5/Baniyas East 5',
        'بني ياس شرق 6/Baniyas East 6',
        'بني ياس شرق 7/Baniyas East 7',
        'بني ياس شرق 8/Baniyas East 8',
        'بني ياس شرق 9/Baniyas East 9',
        'بني ياس شرق/Baniyas East',
        'بني ياس شمال/Baniyas North',
        'بني ياس غرب 2/Baniyas West 2',
        'بني ياس غرب 3/Baniyas West 3',
        'بني ياس غرب 4/Baniyas West 4',
        'بني ياس غرب/Baniyas West',
        'بني ياس/Bani Yas',
        'بو الذياب/Bu Al Dhiyab',
        'بو الياسات/Boo Lyasat',
        'بو كدرة/Bukadra',
        'بوابة البحرية/Navy Gate',
        'بوابة الشرق/Bawabat Al Sharq',
        'بوابة دانيت/Danet Gateway',
        'بياتة/Biyatah',
        'بين الجسرين/Between The Bridges',
        'بَوَّابة أبوظبي/Bawabat Abu Dhabi',
        'تاون سكوير دبي/Town Square Sales Center',
        'تايجر وودز دبي/Tiger Woods Dubai',
        'تجارة تاون/Tijarah Town',
        'تجارية مويلح/Muweilah Commercial',
        'تراث الفجيرة/Fujairah Heritage',
        'تكنوبارك/Technopark',
        'تلال الإمارات ٢/Emirates Hills 2',
        'تلال الإمارات/Emirates Hills',
        'تلال/Tilal',
        'توين/Taween',
        'تيرا نوفا/Terra Nova',
        'جامعة الإمارات الجديدة/Uae University New Campus',
        'جامعة الشارقة/University Of Sharjah',
        'جبل حفيت/Jebel Hafeet',
        'جبل علي الثالثة/Jabal Ali Third',
        'جبل علي الثانية/Jabal Ali Second',
        'جبل علي الصناعية الثانية/Jabal Ali Industrial Second',
        'جبل علي/Jebel Ali',
        'جبل علي/Mina Jebel Ali',
        'جراند سيتي مول/Grand City Mall',
        'جرن يافور/Jarn Yafour',
        'جريرة/Jereirah',
        'جرين كومينيتي إيست/Green Community East',
        'جرين كوميونيتي موتور سيتي/Green Community Motor City',
        'جرين كوميونيتي ويست/Green Community West',
        'جزر السرايا/Saraya Islands',
        'جزر العالم/The World Islands',
        'جزر النخيل/Palm Islands',
        'جزر جميرا/Jumeirah Islands',
        'جزيرة الحصن/Gazertalhesin',
        'جزيرة الحمرا 1/Jazirat Al Hamra-Free Zone 1',
        'جزيرة الحمرا 2/Jazirat Al Hamra-Free Zone 2',
        'جزيرة الحمرا/Jazirat Al Hamra',
        'جزيرة اللولو/Lulu Island',
        'جزيرة المرجان/Jazeerat Al Marjan',
        'جزيرة المرجان/Marjan Island',
        'جزيرة الموزة/Banana Island',
        'جزيرة بلوواترز/Bluewaters Island',
        'جزيرة جوز الهند/Coconut Island',
        'جزيرة مشيرب/Mushayrib Island',
        'جزيرة ناريل/Nareel Island',
        'جزيرة نوراي/Nurai Island',
        'جسر المقطع/Maqta Bridge',
        'جلان/Glan',
        'جلفار/Julfar',
        'جميرا باي/Jumeirah Bay',
        'جميرا ١/Jumeirah 1',
        'جميرا ٢/Jumeirah 2',
        'جميرا ٣/Jumeirah 3',
        'جميرا/Jumeirah',
        'جنوب البرشاء/Al Barsha South',
        'جنوب الوثبة الجديدة/Al Wathba South New',
        'جنوب الوثبة/Al-Wathba South',
        'جولان/Julan',
        'جولدن ساندز/Golden Sands',
        'جيراث/Jeera',
        'جيرث/Geearth',
        'حاكم دادنا/Rul Dadna',
        'حاكم دبا/Rul Dibba',
        'حبحب/Habhab',
        'حدائق الجرف/Al Jurf Gardens',
        'حدائق الجولف/Golf Gardens',
        'حدائق الراحة/Al Raha Gardens',
        'حدائق الشيخ محمد بن راشد المنطقة الأولى/Rashed Park',
        'حدائق الشيخ محمد بن راشد/Hadaeq Sheikh Mohammed Bin Rashid City District One',
        'حداثق أورينكس/Orynx Gardens',
        "حدبة الزعفرانة/Hadbat Al Za'Faranah",
        'حديقة الجميرا/Jumeirah Park',
        'حديقة الصفا/Safa Park',
        'حديقة جداف ووترفرونت للفنون/Jaddaf Waterfront',
        'حديقة زعبيل/Zabeel Park',
        'حديقة مشرف/Mushrif Park',
        'حزيوة/Hezaiwah',
        'حطين/Hatin',
        'حوامي/Hawami',
        'حوشي/Hoshi',
        'حي الجفير الجديد/Hai Jafeer Al Jadeed',
        'حي الدبدبة جنوب/Hay Aldubdabaah South',
        'حي الدوب/Hay Aldoob',
        'حي السيح/Hay Alseeh',
        'حي الشمالي/Hay Alshamali',
        'حي الغربي/Hay Algharby',
        'حي المطار/Airport District',
        'حي المهلب/Hay Almohalb',
        'حي خالد/Hai Khalid',
        'حي مسجد زايد/Hai Zayed Mosque',
        'حياة ريجنسي/Hyatt Regency',
        'حيدريات/Hudayriat',
        'حَدِيْقَة ٱلْحَيْوَانَات بِٱلْعَيْن/Al Ain Zoo And Aquarium',
        'خت القليعه/Khatt Al Qulayyah',
        'خران/Kharan',
        'خران/Kharran',
        'خزام/Khuzam',
        'خليفة بارك/Khalifa Park',
        'خور البيضاء/Al Houtah',
        'خور البيضاء/Khor Al Baida',
        "خور المقطع/Khor Al Maqta'A",
        'خور اليفرة/Khor Al Yeefrah',
        'خور خوير/Khor Khwair',
        'خور كلباء/Falafel Al Nile Kalba',
        'خور كلباء/Khor Kalba',
        'خور/Khoor',
        'خورفكان القادسية/Khorfakkan Qadisiya',
        'خورفكان اليرموك/Khorfakkan Yarmook',
        'خورفكان/Khorfakkan',
        'دار ميرا/Mira Townhouses Community',
        'داراتول ميا/Daratul Mia',
        'داشل/Dahir',
        'داماك هيلز 2/Damac Hills2',
        'داماك هيلز/Damac Hills',
        'دبا الحسن/Dibba Al Husn',
        'دبا الحصن/Dibba Al Hisn',
        'دبا الفجيرة/Dibba Al-Fujairah',
        'دبا/Dibba',
        'دبي أوتودروم ومجمع الأعمال/Dubai Autodrome & Business Park',
        'دبي اوتلت مول/Dubai Outlet Mall',
        'دبي بتروليوم/Dubai Petroleum',
        'دبي سكاي كورت/Dubai Sky Courts',
        'دبي مارينا/Dubai Marina',
        'دبي مول/The Dubai Mall',
        'دبي ووترفرونت اتريوم/Dubai Waterfront',
        'دريم لاند/Dream Land',
        'دفان المعيريض/Dafan Al Maireed',
        'دفان النخيل/Dafan Al Nakheel',
        'دفن الخور/Dafan Al Khor',
        'دقداقة/Al Digdaga',
        'دلم/Dalam',
        'دهان/Dahan',
        'دوغاز/Dubai Natural Gas Company Limited',
        'ديرة/Deira',
        'ديسكفري جاردنز/Discovery Gardens',
        'ذا جرين/The Greens',
        'ذا فيلا/The Villa',
        'ذا فيوز/The Views',
        'ذا لاجون/Lagoons',
        'ذي كلوب هاوس/The Club House',
        'ذيد/Sheikh Zayed Mosque',
        'رأس الخيمة/Ras Al-Khaimah',
        'رأس الدارة/Ras Al Dahrah',
        'راس الخيمة القديمة/Old Ras Al Khaimah',
        'راس الخيمة القديمه/Old Ras Al Khaimah',
        'رافاق/Rafaq',
        'ربدان/Rabdan',
        'رقة البطين/Riggat Al Buteen',
        'رل ضدنا/Roul Dadna',
        'رمرام/Remraam',
        'رملة زاخر/Ramlat Zakher',
        'روضة أبوظبي/Rowdhat Abu Dhabi',
        'روضة الريف/Al Reef Downtown',
        'روضة الريف/Rawdat Al Reef',
        'رول دبا/Rul Dibba',
        'رول ضدنا/Rul Dadna',
        'ريفرسايد/Riverside',
        'زاخر/Zakhir',
        'زبارة/Zubara',
        'زبيدة/Zubaida',
        'زبيدة/Zubaidah',
        "زعبيل ١/Za'Abeel 1",
        "زعبيل ٢/Za'Abeel 2",
        'زهرة تاون هاوسيس/Zahra Townhouses',
        'زياد مخيمر للتجارة/Ziyad Mukhaimar Trading',
        'ساحة الخليج/Bay Square',
        'سافانا/Savannah | Arabian Ranches',
        'سبخة/Sabkhah',
        'سحيم/Sahim',
        'سدروه/Sidroh',
        'سرايا/Saraya',
        'سكمكم/Skamkam',
        'سكن عمال الجابر - المفرق/Al Jaber Mafraq Camp',
        'سهيلة/Suhaila',
        'سهيلة/Suhailah',
        'سوق الجمعة/Friday Market',
        "سوق الجمعة/Souq Al Jum'Ah",
        'سوق الذهب/Gold Souk',
        'سوق السمك دبي/Fish Market',
        'سوق الكبير/Meena Bazar Burdubai',
        'سوق مدينة جميرا/Souk Madinat Jumeirah',
        'سوق مرشد/Murshid Bazar',
        'سيتي أوف آرابيا/City Of Arabia',
        'سيتي سنتر الفجيرة/Fujairah City Center Fcc',
        "سيتي سنتر الفجيرة/Nando'S City Centre",
        'سيتي سنتر ديرة/City Centre Deira Dubai',
        'سيجي/Siji',
        'سيجي/Siji Greenhouse',
        'سيح البانة/Seih Al Bana',
        'سيح البريرات/Seih Al Burairat',
        'سيح البير/Seih Al Beer',
        'سيح البُرَيرات/Seih Al Burairat',
        'سيح الحرف/Seih Al Harf',
        'سيح الحضيبة/Seih Al Hudaibah',
        'سيح الدعي/Sayh Al Daay',
        'سيح العريبي/Seih Al Uraibi',
        'سيح القصيدات/Seih Al Qussaidat',
        'سيح القُسَيدات/Seih Al Qusaidat',
        'سيح دبا/Seeh Dibba',
        'سيح شهيار/Seih Shihyar',
        'سيح ميدر/Seeh Meyadher',
        'سيدروه/Sidroh',
        'سيزونز كوميونيتي/Seasons Community',
        'سَيْح مُزَيْرَع/Sayh Mudayrah',
        'سُهَيم/Suhaim',
        'شارع آل مكتوم/Al Maktoum Rd',
        'شارع إسبانيا/Spain Cluster',
        'شارع الاستقلال/Istiklal Street',
        'شارع الخليج العربي/Al Khaleej Al Arabi St',
        'شارع الخور/Al Khor Rd',
        'شارع الدفاع/Defence Road',
        'شارع الرولا/Al Rolla Rd',
        'شارع السلام/Al Salam Street',
        'شارع الفلاح/Al Falah Street',
        'شارع الكترا/Electra Street',
        'شارع اللولو (شارع ليوا)/Lulu Street',
        "شارع المرابع/Al Marabea' St",
        'شارع المطار/Airport Street',
        'شارع النجدة/Al Najda Street',
        'شارع النصر/Al Naser Street',
        'شارع الوحيدة/Al Wuheida St',
        'شارع بغداد/Baghdad Street',
        'شارع بينونة/Baynoonah Street',
        'شارع حمدان/Hamdan Street',
        'شارع خالد بن الوليد/Khalid Bin Al Waleed Rd',
        'شارع خليفة/Khalifa Street',
        'شارع دلما/Delma Street',
        'شارع دمشق/Damascus Street',
        'شارع سفانا/Sahaynah',
        'شارع صلاح الدين/Salah Al Din St',
        'شارع طريف/Street',
        'شارع طريف/Tarif Str',
        'شارع كلباء الوحدة/Kalba Alwahda Str',
        'شارع كورنيش القاسمي/Al Qasimi Corniche Rd',
        'شارع كورنيش القاسمي/Al Qasmi Corniche Road',
        'شارع مصلى العيد/Al Rayyan',
        'شارع هزاع بن زايد/Hazza Bin Zayed Street',
        'شاطئ الراحة/Al Raha Beach',
        'شركة أبوظبي للإعلام/Abu Dhabi Media Co',
        'شركة الامارات للسواقة/Emirates Driving Co.',
        'شركة موتور أبوظبي/Abu Dhabi Motor Co',
        'شرم الشيخ/Sharm',
        'شرم/Sharm',
        'شروق/Shorooq',
        'شعاب الأشخر/Shiab Al Ashkhar',
        'شعبية البديع/Albadei',
        'شعبية الشرطة/Dubai Police Colony',
        'شعبية المقام/Shaabiyat Al Maqam',
        'شعبية عيسى/Shabyat Essa',
        "شعم/Sha'Am",
        'شغرافة 1/Shaghrafah 1',
        'شغرافة 2/Shaghrafah 2',
        'شغرافة 3/Shaghrafah 3',
        'شغرافة 4/Shaghrafah 4',
        'شمال جلفار/Shamal Julphar',
        'شمال حقيل/Shamal Haqueel',
        'شمال/Shamal',
        'شمل/Shamal Julphar',
        'شنتير/Shanteer',
        'شنطير/Chantir',
        'شوكا/Showka',
        'شوكة/Golden Fork Fujairah',
        'شوكة/Shawka',
        'صفد/Safad',
        'صلة الحامض/Salat Al Hamid',
        'صمبريد/Sambareed',
        'صهيل/Saheel',
        'ضاحية البديع/Al Bedea Suburb',
        'ضاحية الحيرة/Al Heera Suburb',
        'ضاحية الخالدية/Al Khaledia Suburb',
        'ضاحية السيوح/Al Suyoh Suburb',
        'ضاحية اللية/Al Layyeh Suburb',
        'ضدنا/Dhadna',
        'طريق الخور/Al Khor Road',
        'طريق الخور/Al Khor St',
        'طريق بني ياس/Baniyas Rd',
        'طريق مازدا القديم/Old Mazda Road',
        'طريق مصلى عيد/Musalla Eid Road',
        'طوي ربيع/Tawi Rbaya',
        'طوي ربيَع/Tawi Rbaya',
        'طوي مهذب زراعي/Mohathab',
        'طوي مهذب سكني/Tuwey Muhathab Resdential',
        'ظاهر الصفوة/Dhahir Al Safwah',
        'عالم فيراري/Ferrari World',
        'عبادلة/Abadilah',
        'عدن/Adan',
        'عدن/Aden Tire Repair Workshop',
        'عرادة/Arada',
        'عرض ازياء الفهدي/Al Fahidi',
        'عرقوب الصناعية/Argoub Industrial',
        'عرقوب المزاحمي/Arqoub Al Muzahmi',
        'عرقوب/Argoub',
        'عشارج/Asharej',
        'عصب/Asab',
        'عوافي/Awafi',
        'عود الطاير 1/Oud Al Tayer1',
        'عود الطاير 2/Oud Al Tayer2',
        'عود العويد/Oud Al Owaid',
        'عود المطينة/Oud Al Muteena',
        'عود بن صقحان/Oud Bin Sag-Han',
        'عود ميثاء/Oud Metha',
        "عيسى/Je'Eisah",
        'عين الفايضة/Ain Al Faydah',
        'عين لغمور/Ayn Ul Ghamur',
        'غافات الشويهي/Ghafat Al Shwaihi',
        'غافات النيّار/Gafat Al Nayyar',
        'غرا/Ghara',
        'غريبة/Ghireebah',
        'غليلة/Ghalilah',
        'غوب/Ghob St',
        'غوب/Goob',
        'غياثي/Ghiyathi',
        'غيلان/Gheelan',
        'فالكن سِتي أوف وندرز/Falcon City Of Wonders',
        'فشلا/Fashrah',
        'فلاح الزراعية/Agricultural Falah',
        'فلج الشيخ/Falaj Al Sheik',
        'فلج الشيخ/Falaj Al Sheikh',
        'فلج المعلا/Falaj Al Moalla',
        'فلج المعلا/Falaj Al Mualla',
        'فلج المعلا/Fallaj Al Muwallah',
        "فلج هزاع/Falaj Hazza'",
        'فلل السدر/Cedre Villas',
        "فلل الضباط (إسكان الدفاع)/Officer'S Villas (Defense Houses)",
        'فلل الفرجان/Al Furjan',
        'فندق قصر الامارات/Emirates Palace Hotel',
        'فهد/Faha',
        'قادسية خورفكان/Qadisiyah Khorfakkan',
        'قدفع/Qidfa',
        'قدفع/Qidfi',
        'قراط/Qirat',
        'قرية أذن/Adhen Village',
        'قرية الأعمال/Business Village',
        'قرية البحر الأبيض المتوسط/Mediterranean Village',
        'قرية الحيل/Al Hail Village',
        'قرية الخالدية/Khalidiya Village',
        'قرية الصحراء/Desert Village',
        'قرية الظفرة للعمال/Al Dhafra Workers Village',
        'قرية القرم/Mangrove Village',
        'قرية اللولو/Lulu Village',
        'قرية المجتمع الخضراء/Green Community Village',
        'قرية المعرفة/Dubai Knowledge Park',
        'قرية تلال البادية/Al Badia Hill Side Village',
        'قرية جبل علي/Jebel Ali Village',
        'قرية جميرا الدائرية/Jumeirah Village Circle',
        'قرية جميرا/Jumeirah Village',
        'قرية ساس النخل/Sas Al Nakhl Village',
        'قرية مارينا/Marina Village',
        'قرية هيدرا/Hydra Village',
        'قرية ورسان/Warsan Village',
        'قرية/Gurayya',
        'قصر البحر/Qasr Al Bahr',
        'قصر السراب/Qasr Al Sarab',
        'قيام/Kayyam',
        'كابر/Kabir',
        'كادرا/Kadra',
        'كارفور طريق المطار/Carrefour Airport Road',
        'كامينو/The Centro',
        'كبير/Kaber',
        'كتلة الصين/China Cluster',
        'كدرا/Kadra',
        'كدرا/Kadrah',
        'كشيشة 1/Kshishah 1',
        'كشيشة 2/Kshishah 2',
        'كشيشة 3/Kshishah 3',
        'كشيشة 4/Kshishah 4',
        'كشيشة 5/Kshishah 5',
        'كشيشة 6/Kshishah 6',
        'كلباء شارع الوحدة/Al Wahda St',
        'كلية الشرطة/Police College',
        'كلية دبي/Dubai College',
        'كورال بوتيك فيلا/Coral Boutique Villas',
        'كورنيش البحيرة/Buhaira Corniche',
        'كورنيش خورفكان/Khorfakkan Beach',
        'كورنيش خورفكان/Khorfakkan Corniche',
        'كورنيش ديرة/Corniche Deira',
        'كورنيش كلباء/Kalba Corniche',
        'كورنيش كلباء/Kalba Corniche Park',
        'كيزاد/Kizad',
        'لا كوليسيون/La Coleccion',
        'لامسي بلازا 1/Lamcy Plaza',
        'لبصه/Lubsah',
        'لعطين 1/Leatain 1',
        'لعطين 2/Leatain 2',
        'لمغدر/Al Limghadar',
        'لمغدر/Lamagdar',
        'لهباب/Lahbab',
        'لواء بدر/Liwa Badr',
        'لولايا/Lulayya',
        'ليجيند/Legends',
        'ليمحة/Eleemha',
        'ليوان/Liwan',
        'ماغربيا/Maghribiyah',
        'مانجروف ون/Mangrove One',
        'مبزرة الخضراء/Mbazzarah Al Khadra',
        'مبنى تجاري/Margab Farm',
        'مثلث قرية جميرا حي رقم 1/Jumeirah Village District 1',
        'مثلث قرية جميرا حي رقم 2/Jumeirah Village District 2',
        'مثلث قرية جميرا حي رقم 3/Jumeirah Village District 3',
        'مثلث قرية جميرا/Jumeirah Village Triangle',
        'مجان/Majan',
        'مجلود/Majlood',
        'مجمع أدكو/Adco Compound',
        'مجمع إعمار للأعمال/Emaar Business Park',
        'مجمع الشيخ حمدان/Sheikh Hamdan Colony',
        'مجمع الشيخ راشد للإسكان/Rashid Complex',
        'مجمع الشيخ سرور/Sheikh Suroor Compound',
        'مجمع الصناعات الوطنية/National Industries Park',
        'مجمع الوزارات/Ministries Complex',
        'مجمع الياسات/Al Yasat Complex',
        'مجمع خليفة للطاقة/Khalifa Energy Complex',
        'مجمع دبي بيرل السكني/Dubai Pearl',
        'مجمع دبي لاند السكني/Dubai Land Residence Complex',
        'مجمع دبي للإستثمار 2/Dubai Investment Park 2',
        'مجمع دبي للإستثمار/Dubai Investments Park',
        'مجمع دبي للعلوم/Dubai Science Park',
        'مجمع غدير السكني/Ghadeer Community',
        'مجمع ليان/Layan Community',
        'مجمع مرسى الخور للأعمال/Marsa Al Khor',
        'مجمع مزيد/Mazyad Compound',
        'محاكم دبي/Dubai Courts',
        'محطة حافلات ابوظبي/Abu Dhabi Bus Station',
        'محمد بن راشد 1/Mohammed Bin Rashid 1',
        'محمد بن راشد 2/Mohammed Bin Rashid 2',
        'محمع دبي للإستثمار 1/Dubai Investment Park 1',
        'محوي/Mahwi',
        'محيصنة ١/Muhaisnah 1',
        'محيصنة ٢/Muhaisnah 2',
        'محيصنة ٣/Muhaisnah 3',
        'محيصنة ٤/Muhaisnah 4',
        'محيصنة/Muhaisnah',
        'مخيم الدفاع/Defence Camp',
        'مخيم الشركات مزيد/Companies Camp Mezyad',
        'مدرسة ابوظبي الهندية/Abu Dhabi Indian School',
        'مدرسة الشويفات الدولية/International School Of Choueifat',
        'مدفق/Mudfak',
        'مدهب/Madhab',
        'مدينة أبوظبي الصناعية/Abu Dhabi Industrial City',
        'مدينة الجولف/Golf City',
        'مدينة الرويس الصناعية/Al Ruways Industrial City',
        'مدينة الرياض/Madinat Al Riyad',
        'مدينة الشهداء/City Of Martyrs',
        'مدينة الشهداء/Madinat Al Shuhada',
        'مدينة الشيخ خليفة الطبية/Sheikh Khalifa Medical City',
        'مدينة الضباط/Officers City',
        'مدينة الظنة/Al Dhannah City',
        'مدينة العين الترفيهية/Al Ain Fun City',
        'مدينة الفجيرة/Fujairah City',
        'مدينة الفجيرة/Fujairah Town',
        'مدينة الفلاح/Al Falah City',
        'مدينة المفرق للعمال/Al Mafraq Workers City',
        'مدينة الهلال/Al Hilal City',
        'مدينة بني ياس/Baniyas City',
        'مدينة خليفة - ب/Khalifha City B',
        'مدينة خليفة 1/Khalifa City 1',
        'مدينة خليفة 2/Khalifa City 2',
        'مدينة خليفة أ/Khalifa City A',
        'مدينة خليفة بن زايد/Khalifa Bin Zayed City',
        'مدينة خليفة/Khalifa City',
        'مدينة خليفة/Khalifah City',
        'مدينة دبي الأكاديمية/Dubai International Academic City',
        'مدينة دبي الرياضية/Dubai Sports City',
        'مدينة دبي الصناعية/Dubai Industrial City',
        'مدينة دبي الطبية/Dubai Healthcare City',
        'مدينة دبي المستدامة/The Sustainable City',
        'مدينة دبي الملاحية/Dubai Maritime City',
        'مدينة دبي لايف ستايل/Dubai Lifestyle City',
        'مدينة دبي للإستديوهات/Dubai Studio City',
        'مدينة دبي للإعلام/Dubai Media City',
        'مدينة دبي للإنترنت/Dubai Internet City',
        'مدينة دبي للاقمشة/Dubai Textile City',
        'مدينة زايد الرياضية/Zayed Sports City',
        'مدينة زايد العسكرية/Zayed Military City',
        'مدينة زايد/Zayed City',
        'مدينة شخبوط حوض 1/Shakhbout Block 1',
        'مدينة شخبوط حوض 10/Shakhbout Block 10',
        'مدينة شخبوط حوض 11/Shakhbout Block 11',
        'مدينة شخبوط حوض 12/Shakhbout Block 12',
        'مدينة شخبوط حوض 13/Shakhbout Block 13',
        'مدينة شخبوط حوض 14/Shakhbout Block 14',
        'مدينة شخبوط حوض 15/Shakhbout Block 15',
        'مدينة شخبوط حوض 16/Shakhbout Block 16',
        'مدينة شخبوط حوض 17/Shakhbout Block 17',
        'مدينة شخبوط حوض 18/Shakhbout Block 18',
        'مدينة شخبوط حوض 19/Shakhbout Block 19',
        'مدينة شخبوط حوض 2/Shakhbout Block 2',
        'مدينة شخبوط حوض 20/Shakhbout Block 20',
        'مدينة شخبوط حوض 21/Shakhbout Block 21',
        'مدينة شخبوط حوض 22/Shakhbout Block 22',
        'مدينة شخبوط حوض 23/Shakhbout Block 23',
        'مدينة شخبوط حوض 24/Shakhbout Block 24',
        'مدينة شخبوط حوض 25/Shakhbout Block 25',
        'مدينة شخبوط حوض 26/Shakhbout Block 26',
        'مدينة شخبوط حوض 27/Shakhbout Block 27',
        'مدينة شخبوط حوض 28/Shakhbout Block 28',
        'مدينة شخبوط حوض 29/Shakhbout Block 29',
        'مدينة شخبوط حوض 3/Shakhbout Block 3',
        'مدينة شخبوط حوض 30/Shakhbout Block 30',
        'مدينة شخبوط حوض 31/Shakhbout Block 31',
        'مدينة شخبوط حوض 4/Shakhbout Block 4',
        'مدينة شخبوط حوض 5/Shakhbout Block 5',
        'مدينة شخبوط حوض 6/Shakhbout Block 6',
        'مدينة شخبوط حوض 7/Shakhbout Block 7',
        'مدينة شخبوط حوض 8/Shakhbout Block 8',
        'مدينة شخبوط حوض 9/Shakhbout Block 9',
        'مدينة شخبوط/Shakhbout City',
        'مدينة محمد بن زايد 1/Z 1 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 12/Z 12 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 13/Z 13 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 14/Z 14 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 15/Z 15 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 16/Z 16 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 17/Z 17 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 18/Z 18 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 19/Z 19 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 2/Z 2 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 20/Z 20 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 21/Z 21 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 22/Z 22 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 23/Z 23 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 24/Z 24 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 25/Z 25 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 26/Z 26 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 27/Z 27 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 29/Z 29 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 3/Z 3 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 30/Z 30 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 31/Z 31 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 32/Z 32 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 33/Z 33 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 34/Z 34 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 35/Z 35 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 36/Z 36 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 4/Z 4 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 5/Z 5 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 6/Z 6 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 7/Z 7 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 8/Z 8 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد 9/Z 9 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد Z1/Mohamed Bin Zayed City Z1',
        'مدينة محمد بن زايد Z13/Mohamed Bin Zayed City Z13',
        'مدينة محمد بن زايد Z14/Mohamed Bin Zayed City Z14',
        'مدينة محمد بن زايد Z15/Mohamed Bin Zayed City Z15',
        'مدينة محمد بن زايد Z16/Mohamed Bin Zayed City Z16',
        'مدينة محمد بن زايد Z17/Mohamed Bin Zayed City Z17',
        'مدينة محمد بن زايد Z18/Mohamed Bin Zayed City Z18',
        'مدينة محمد بن زايد Z19/Mohamed Bin Zayed City Z19',
        'مدينة محمد بن زايد Z2/Mohamed Bin Zayed City Z2',
        'مدينة محمد بن زايد Z20/Mohamed Bin Zayed City Z20',
        'مدينة محمد بن زايد Z21/Mohamed Bin Zayed City Z21',
        'مدينة محمد بن زايد Z22/Mohamed Bin Zayed City Z22',
        'مدينة محمد بن زايد Z23/Mohamed Bin Zayed City Z23',
        'مدينة محمد بن زايد Z24/Mohamed Bin Zayed City Z24',
        'مدينة محمد بن زايد Z25/Mohamed Bin Zayed City Z25',
        'مدينة محمد بن زايد Z26/Mohamed Bin Zayed City Z26',
        'مدينة محمد بن زايد Z27/Mohamed Bin Zayed City Z27',
        'مدينة محمد بن زايد Z29/Mohamed Bin Zayed City Z29',
        'مدينة محمد بن زايد Z3/Mohamed Bin Zayed City Z3',
        'مدينة محمد بن زايد Z30/Mohamed Bin Zayed City Z30',
        'مدينة محمد بن زايد Z31/Mohamed Bin Zayed City Z31',
        'مدينة محمد بن زايد Z32/Mohamed Bin Zayed City Z32',
        'مدينة محمد بن زايد Z33/Mohamed Bin Zayed City Z33',
        'مدينة محمد بن زايد Z34/Mohamed Bin Zayed City Z34',
        'مدينة محمد بن زايد Z36/Mohamed Bin Zayed City Z36',
        'مدينة محمد بن زايد Z4/Mohamed Bin Zayed City Z4',
        'مدينة محمد بن زايد Z5/Mohamed Bin Zayed City Z5',
        'مدينة محمد بن زايد Z6/Mohamed Bin Zayed City Z6',
        'مدينة محمد بن زايد Z7/Mohamed Bin Zayed City Z7',
        'مدينة محمد بن زايد Z8/Mohamed Bin Zayed City Z8',
        'مدينة محمد بن زايد/Mohammed Bin Zayed City',
        'مدينة محمد بن زايد/Mohammed Bin Zayed City',
        'مدينة محمد بن زايد01 - 6/Z 6-01 Mohammed Bin Zayed City',
        'مدينة محمد بن زايد01 -9/Z 9-01 Mohammed Bin Zayed City',
        'مدينة مهرجان دبي/Dubai Festival City',
        'مدينة مواد البناء/Building Material City',
        'مدينة هند 1/Madinat Hind 1',
        'مدينة هند 4/Madinat Hind 4',
        'مدينة وافي/Wafi City',
        'مرباح/Murbah',
        'مربح/Mirbah',
        'مربض/Murbad',
        'مرتفعات البرشاء/Barsha Heights',
        'مرحلة المدينة الدولية ٢/International City Phase 2',
        'مرشد بازار/Bazaar Guide',
        'مرغم/Margham',
        'مرقب/Murqquab',
        'مركز أبوظبي للمعارض (أدنيك)/Abu Dhabi National Exhibition Centre',
        'مركز الشرطة الذكي/Dubai Police Hq Reception',
        'مركز العاصمة/Capital Centre',
        'مركز الفجيرة التجاري/Fujairah Trade Centre',
        'مركز المدينة/Fujairah Town Center',
        'مركز تايم سكوير دبي/Times Square Center Dubai',
        'مركز دبي التجاري العالمي/Dubai World Trade Centre',
        'مركز دبي العالمي/Dubai South',
        'مركز دبي المالي العالمي/Dubai International Financial Center Difc',
        'مركز دبي للتقنيات والأبحاث/Mohammed Bin Rashid Medical Research Institute',
        'مركز دبي للزهور/Dubai Flower Center',
        'مركز شرطة جبل علي/Jebel Ali Police Station',
        'مرور ابوظبي/Abu Dhabi Traffic',
        'مريشيد/Mraisheed',
        'مزارع أم غافة/Um Ghaffa Farms',
        'مزيرعة/Mazairah',
        "مزيرعة/Mzeer'Ah",
        'مساكن شاطئ جميرا  جي بي آر/Jumeirah Beach Residence',
        'مساكن فيرمونت/Fairmont Residences',
        'مستشفى راشد/Rashid Hospital',
        'مستشفى لطيفة/Latifa Women And Children Hospital',
        'مستشفي النور/Al Noor Hospital',
        'مستمر/Deema',
        'مسجد المزروعي/Masjid Al Mazroui',
        'مشارة/Meshara',
        'مشرع/Meshara',
        'مشرف فيلج/Mushrif Village',
        'مشرف/Mushrif',
        'مشروع جزيرة ياس للإسكان/Yas Island National Housing Project',
        'مشروع وطني/Watani Project',
        'مشيرف/Meshairef',
        'مصفح M-1/Musaffah M-1',
        'مصفح M-10/Musaffah M-10',
        'مصفح M-11/Musaffah M-11',
        'مصفح M-12/Musaffah M-12',
        'مصفح M-13/Musaffah M-13',
        'مصفح M-14/Musaffah M-14',
        'مصفح M-15/Musaffah M-15',
        'مصفح M-16/Musaffah M-16',
        'مصفح M-17/Musaffah M-17',
        'مصفح M-19/Musaffah M-19',
        'مصفح M-2/Musaffah M-2',
        'مصفح M-20/Musaffah M-20',
        'مصفح M-21/Musaffah M-21',
        'مصفح M-23/Musaffah M-23',
        'مصفح M-24/Musaffah M-24',
        'مصفح M-25/Musaffah M-25',
        'مصفح M-26/Musaffah M-26',
        'مصفح M-3/Musaffah M-3',
        'مصفح M-32/1/Musaffah M-32/1',
        'مصفح M-32/2/Musaffah M-32/2',
        'مصفح M-33/Musaffah M-33',
        'مصفح M-36/Musaffah M-36',
        'مصفح M-37/Musaffah M-37',
        'مصفح M-38/Musaffah M-38',
        'مصفح M-39/Musaffah M-39',
        'مصفح M-4/Musaffah M-4',
        'مصفح M-40/Musaffah M-40',
        'مصفح M-42/Musaffah M-42',
        'مصفح M-43/Musaffah M-43',
        'مصفح M-44/Musaffah M-44',
        'مصفح M-45/Musaffah M-45',
        'مصفح M-46/Musaffah M-46',
        'مصفح M-5/Musaffah M-5',
        'مصفح M-6/Musaffah M-6',
        'مصفح M-7/Musaffah M-7',
        'مصفح M-8/Musaffah M-8',
        'مصفح M-9/Musaffah M-9',
        'مصفح Me-10/Musaffah Me-10',
        'مصفح Me-11/Musaffah Me-11',
        'مصفح Me-9/Musaffah Me-9',
        'مصفح Mn-1/Musaffah Mn-1',
        'مصفح Mn-2/Musaffah Mn-2',
        'مصفح Mn-3/Musaffah Mn-3',
        'مصفح Mn-4/Musaffah Mn-4',
        'مصفح Mn-5/Musaffah Mn-5',
        'مصفح Mn-6/Musaffah Mn-6',
        'مصفح Mw-4/Musaffah Mw-4',
        'مصفح Mw-5/Musaffah Mw-5',
        'مصفح الصناعية م 1/Mussaffa Industrial M 1',
        'مصفح الصناعية م 10/Mussaffa Industrial M 10',
        'مصفح الصناعية م 11/Mussaffa Industrial M 11',
        'مصفح الصناعية م 12/Mussaffa Industrial M 12',
        'مصفح الصناعية م 13/Mussaffa Industrial M 13',
        'مصفح الصناعية م 14/Mussaffa Industrial M 14',
        'مصفح الصناعية م 15/Mussaffa Industrial M 15',
        'مصفح الصناعية م 16/Mussaffa Industrial M 16',
        'مصفح الصناعية م 17/Mussaffa Industrial M 17',
        'مصفح الصناعية م 18/Mussaffa Industrial M 18',
        'مصفح الصناعية م 19/Mussaffa Industrial M 19',
        'مصفح الصناعية م 2/Mussaffa Industrial M 2',
        'مصفح الصناعية م 20/Mussaffa Industrial M 20',
        'مصفح الصناعية م 21/Mussaffa Industrial M 21',
        'مصفح الصناعية م 22/Mussaffa Industrial M 22',
        'مصفح الصناعية م 23/Mussaffa Industrial M 23',
        'مصفح الصناعية م 24/Mussaffa Industrial M 24',
        'مصفح الصناعية م 26/Mussaffa Industrial M 26',
        'مصفح الصناعية م 3/Mussaffa Industrial M 3',
        'مصفح الصناعية م 32/1/Mussaffa Industrial M 32/1',
        'مصفح الصناعية م 32/2/Mussaffa Industrial M 32/2',
        'مصفح الصناعية م 33/Mussaffa Industrial M 33',
        'مصفح الصناعية م 35/Mussaffa Industrial M 35',
        'مصفح الصناعية م 36/Mussaffa Industrial M 36',
        'مصفح الصناعية م 37/Mussaffa Industrial M 37',
        'مصفح الصناعية م 38/Mussaffa Industrial M 38',
        'مصفح الصناعية م 39/Mussaffa Industrial M 39',
        'مصفح الصناعية م 4/Mussaffa Industrial M 4',
        'مصفح الصناعية م 40/Mussaffa Industrial M 40',
        'مصفح الصناعية م 42/Mussaffa Industrial M 42',
        'مصفح الصناعية م 43/Mussaffa Industrial M 43',
        'مصفح الصناعية م 44/Mussaffa Industrial M 44',
        'مصفح الصناعية م 45/Mussaffa Industrial M 45',
        'مصفح الصناعية م 46/Mussaffa Industrial M 46',
        'مصفح الصناعية م 5/Mussaffa Industrial M 5',
        'مصفح الصناعية م 6/Mussaffa Industrial M 6',
        'مصفح الصناعية م 7/Mussaffa Industrial M 7',
        'مصفح الصناعية م 8/Mussaffa Industrial M 8',
        'مصفح الصناعية م 9/Mussaffa Industrial M 9',
        'مصفح شرق 1,مصفح شبيا/Me 1,mussaffa Shabiya',
        'مصفح شرق 10,مصفح شبيا/Me 10,mussaffa Shabiya',
        'مصفح شرق 11,مصفح شبيا/Me 11,mussaffa Shabiya',
        'مصفح شرق 12,مصفح شبيا/Me 12,mussaffa Shabiya',
        'مصفح شرق 13,مصفح شبيا/Me 13,mussaffa Shabiya',
        'مصفح شرق 14,مصفح شبيا/Me 14,mussaffa Shabiya',
        'مصفح شرق 15,مصفح شبيا/Me 15,mussaffa Shabiya',
        'مصفح شرق 16,مصفح شبيا/Me 16,mussaffa Shabiya',
        'مصفح شرق 2,مصفح شبيا/Me 2,mussaffa Shabiya',
        'مصفح شرق 3,مصفح شبيا/Me 3,mussaffa Shabiya',
        'مصفح شرق 4,مصفح شبيا/Me 4,mussaffa Shabiya',
        'مصفح شرق 5,مصفح شبيا/Me 5,mussaffa Shabiya',
        'مصفح شرق 6,مصفح شبيا/Me 6,mussaffa Shabiya',
        'مصفح شرق 7,مصفح شبيا/Me 7,mussaffa Shabiya',
        'مصفح شرق 8,مصفح شبيا/Me 8,mussaffa Shabiya',
        'مصفح شرق 9,مصفح شبيا/Me 9,mussaffa Shabiya',
        'مصفح غرب 4/Mussaffa West 4 - Mw 4',
        'مصفوت/Masfut',
        'مصلى العيد/Musallah Al Eid',
        'مصيبيخ/Msaibeekh',
        'مضب/Madab',
        'مضمار جبل علي/Jebel Ali Racecourse',
        'مطار أبو ظبي الدولي/Abu Dhabi International Airport',
        'مطار البطين/Al Bateen Airport',
        'مطار دبي الدولي/Dubai International Airport',
        'مطار رأس الخيمة الدولي/Rak Airport Area',
        'معسكر الفلاح/Al Falah Camp',
        'معهد البترول/Petrolium Institute',
        'معيصم 1/Dubai Production City, City Centre Meaisem 1',
        "معيصم 2/City Centre Me'Aisem",
        'معين/Maeen',
        'مقاطرة/Mqatrh Tower',
        'مقر الظبي/Magar Al Dhabi',
        'مكتب بريد حمدان/Hamdan Post Office',
        'ملاح/Mallah',
        'ملاعب عقارات جميرا للغولف/Jumeirah Golf Estates',
        'ملاقط/Malaqit',
        'ملعب هزاع بن زايد/Hazza Bin Zayed Stadium',
        'مليحة/Mleiha',
        'ممدوح/Mamdouh',
        'ممشي هيدرا للغولف/Hydra Golf Walk',
        'مناصر جديد/New Manasir',
        'مناطق جبل علي الصناعية/Jebel Ali Industrial Area',
        'منامة/Pajamas',
        'منتجات فورايفر ليفنج/Flp Dubai Salahuddin Road Office',
        'منتجع بن ماجد فلامينجو بيتش/Bin Majid Flamingo Beach Resort',
        'منتجع بن ماجد فلامينجو/Bin Majid St',
        'منطقة الإنتاج الفنيimpz/Dubai Production City',
        'منطقة الابرق/Al Hariq',
        'منطقة الحزان/Al Hazzan Area',
        'منطقة الحزان/Sadness Area',
        'منطقة الحوية/Al Hawiyah Area',
        'منطقة الحيل الصناعية/Al Hayl Industrial',
        'منطقة الحيل الصناعية/Al Hayl Industrial Area',
        'منطقة الخور/Al Khor Area',
        'منطقة الرملة/Al Khurma',
        'منطقة الرملة/Al Ramlah Area',
        'منطقة السفارة/Embassy Area',
        'منطقة السلامة/Al Salamah',
        'منطقة السلامة/Al Salamah Area',
        'منطقة الغرفة/Gurfah Area',
        'منطقة الغرفه/Al Ghurfah',
        'منطقة الفصيل/Al Faseel',
        'منطقة الفصيل/Al Faseel Area',
        'منطقة القرية التجارية/Falcon City Of Wonders - Phase 2',
        'منطقة القنصليات/Consulates Area',
        'منطقة القوز الصناعية 1/Al Quoz Industrial Area 1',
        'منطقة القوز الصناعية 2/Al Quoz Industrial Area 2',
        'منطقة القوز الصناعية 3/Al Quoz Industrial Area 3',
        'منطقة القوز الصناعية 4/Al Quoz Industrial Area 4',
        'منطقة المدار/Al Madar',
        'منطقة المدار/Al Madar Area',
        'منطقة المستودعات/Warehouse District',
        'منطقة المصفح الصناعية/Mussafah Industrial Area',
        'منطقة المطار/Airport Area',
        'منطقة المفرق الصناعية/Mafraq Industrial Area',
        'منطقة الهيلي / الخليف/Al Khaleef',
        'منطقة الوحدة مول/Al Wahda',
        'منطقة جبل علي الحرة/Jebel Ali Freezone',
        'منطقة دبلوماسية/Diplomatic Area',
        'منطقة دبي للتعهيد/Dubai Outsource City',
        'منطقة رأس الخور الصناعية - ٣/Ras Al Khor Industrial Area 3',
        'منطقة رأس الخور الصناعية -٢/Ras Al Khor Industrial Area 2',
        'منطقة رأس الخور الصناعية-١/Ras Al Khor Industrial Area 1',
        'منطقة زياني/Ziany Area',
        'منطقة صناعية ٦/Industrial Area',
        'منطقة صناعية/Industrial Area',
        'منطقة قاعدة الظفرة الجوية/Al Dhafra Air Base Area',
        'منطقة قصر الشاطئ/Qasr Al Shati Area',
        'منطقة مرسى السعديات/Saadiyat Marina District',
        'منطقة مركز شرطة المصفح/Mussafah Police Station Area',
        'منقطة الفجيرة الحرة 1 و2/Fujairah Free Zone Authority',
        'مهادب/Mohadhub',
        'مهذب/Mohadub',
        'مهذب/Muhathab Agricultural',
        'موتور سيتي/Motor City',
        'مول ابوظبي/Abu Dhabi Mall',
        'مول الخالدية/Khalidiya Mall',
        'مول مارينا/Marina Mall',
        'موناي/Monkey',
        'مونيه/Munae',
        'مويلحه/Muwailha',
        'ميدان هايتس/Meydan Heights',
        'ميرادور/Mirador',
        'ميراشيد/Merashid',
        'ميلينيوم إستايتس من ميدان/Millennium Estates, Meydan',
        'مينا العرب/Mina Al Arab',
        'مينا بازار/Mina Bazaar',
        'ميناء الحمرية/Alhamriya Port',
        'ميناء العرب/Mina Al Arab',
        'ميناء العرب/Mina Al Arab',
        'ميناء الفجر/Mina Al Fajer',
        'ميناء الفجر/Mina Al Fajer Resort',
        'ميناء الفجيرة/Fujairah Port',
        "ميناء الفجيرة/Mina' Al Fujayrah",
        'ميناء خورفكان/Khorfakkan Port',
        'ميناء خورفكان/Port Of Fujairah',
        'ميناء راشد/Port Rashid',
        'ميناء زايد/Zayed Port',
        'مُدُن/Mudon',
        'مُصَفَّح شعبية 12/Musaffah Shabiya 12',
        'نادي الإمارات للغولف/Emirates Golf Club',
        'نادي الضباط/Officer Club',
        'نادي الفجيرة لكرة القدم/Al Jazira Fujairah',
        'نادي الفجيرة لكرة القدم/Fujairah Football Club',
        'نادي جبل علي للرماية/Ja Shooting Club',
        'نادي دبي الدولي للرياضات البحرية/Dubai International Marine Club',
        'نادي دبي للبولو والفروسية/Plation Equestrian Polo Club',
        'نادي و منتجع ابوظبي للغولف/Abu Dhabi Golf Club',
        'نخلات ديرة/Palm Deira',
        'نخلة جبل علي/Nakhlat Jebel Ali',
        'نخلة جميرا/The Palm Jumeirah',
        'نخلة ديرة/Deira Islands',
        'نخيل/Nakheel',
        'نخيل/Palm',
        'ند الحمر/Nadd Al Hamar',
        'ند الشبا 1/Nad Al Sheba 1',
        'ند الشبا 2/Nad Al Sheba 2',
        'ند الشبا 3/Nad Al Sheba 3',
        'ند الشبا 4/Nad Al Sheba 4',
        'ند الشبا/Nad Al Sheba',
        'نزوى/Nazwa',
        "نعمة/Ni'Mah",
        'نهي/Nhay',
        'هتان 1/Hattan 1',
        'هتان 2/Hattan 2',
        'هتان 3/Hattan 3',
        'هتان/Hattan',
        'هزيوه/Hazeem',
        'هيئة المنطقة الحرة بالفجيرة 1&2/Fujairah Freezone 1 & 2',
        'هيلي سنايا/Hili Sanaya',
        'هيلي/Hili',
        'واحة دبي السيليكون/Dubai Silicon Oasis',
        'وادي اصفني/Sifuni',
        'وادي الشبك/Wadi Al Shabak',
        'وادي الصفا 2/Wadi Al Safa 2',
        'وادي الصفا 3/Wadi Al Safa 3',
        'وادي الصفا 4/Wadi Al Safa 4',
        'وادي الصفا 5/Wadi Al Safa 5',
        'وادي الصفا 7/Wadi Al Safa 7',
        'وادي العمردي/Wadi Al Amardi',
        'وادي العين 2 - الخرير/Wadi Al Ain 2 - Al Khrair',
        'وادي العين 3 - الخرير/Wadi Al Ain 3 - Al Khrair',
        'وادي القور/Al Qor',
        'وادي الوريعة/Al Wurayah Valley',
        'وادي الوريعة/Wadi Wurayah',
        'وادي حقيل/Wadi Haqeel',
        'وادي سهم/Wadi Saham',
        'وادي شعم/Wadi Shaam',
        'وادي عشواني/Wadi Ashwani',
        'وادي عمار/Wadi Ammar',
        'وادي غليلة/Wadi Ghalilah',
        'وادي ملحة/Wadi Malaha',
        'وادي ممدوح/Wadi Mamduah',
        'واسط/Wasit',
        'وذيل/Wedhail',
        'ورسان 2/Warsan 2',
        'ورسان 3/Warsan 3',
        'ورسان 4/Warsan 4',
        'وسط المدينة/Central District',
        'وسط مدينة الريف/Central District ( Town Center )',
        'وسط مدينة جبل علي/Downtown Jabel Ali',
        'وسط مدينة دبي/Downtown Dubai',
        "وعيب الحنا/W'Aid Al Hinna",
        'ياس ايكرز/Yas Acres',
        'يلدية الفجيرة/Fujairah Town Center',
        'ٱلٌميلة/Al Rumailah',
    ],
};
