/* eslint-disable no-underscore-dangle */
import GeneralDrawer from 'components/generalDrawer/generalDrawer';
import DataTable from 'components/tables/tables';
import { format } from 'date-fns';
import { Button } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FaRegCopy } from 'react-icons/fa6';
import { IoMdCloseCircle } from 'react-icons/io';
import { VscHistory } from 'react-icons/vsc';
import { IStock } from 'services/admin/stock';
import { getShipmentHistory, IShipmentTrace } from 'services/admin/stockShipment';
import { handleCopyTC } from 'utils/helpers/copyTC';
import { StatusBadge } from 'utils/helpers/statusBadge';
import { shipmentColumns, stockStatusMatch } from '../StockListElements';

const ShippingHistory: React.FC<{ data: IStock }> = ({ data }) => {
    const [shippingHistory, setShippingHistory] = useState<IShipmentTrace[]>([]);
    const [openDetails, setOpenDetails] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (data?._id && openDetails) {
            const toastId = toast.loading('Loading stock shipments details data...');
            setIsLoading(true);
            getShipmentHistory(data?._id)
                .then((res) => {
                    toast.dismiss(toastId);
                    setShippingHistory(res.data);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                    toast.error('Something went wrong', { id: toastId });
                });
        }
    }, [data?._id, openDetails]);

    return (
        <>
            <VscHistory className="w-5 h-5 cursor-pointer" onClick={() => setOpenDetails(true)} />
            <GeneralDrawer
                anchor="right"
                open={openDetails}
                onClose={setOpenDetails}
                PaperProps={{
                    sx: { width: '60%' },
                }}
            >
                <div className="p-4">
                    <div className="flex justify-between items-center">
                        <div>
                            <p className="text-xl font-bold">Shipping History</p>
                        </div>
                        <button
                            type="button"
                            onClick={() => setOpenDetails(false)}
                            className="p-1 hover:dark:bg-gray-700 hover:bg-gray-100 rounded-md"
                        >
                            <IoMdCloseCircle className="w-8 h-8" color="grey" />
                        </button>
                    </div>

                    <div className="flex items-center w-full my-7">
                        <p className="text-xl font-medium">
                            {data?.product?.itemKind === 'variant'
                                ? data?.parentProduct?.restockFor?.name || data?.parentProduct?.name
                                : data?.product?.name}
                        </p>
                        {data?.product?.itemKind === 'variant' && (
                            <p className="text-l font-medium pt-2 pl-2">{data?.product?.name || ''}</p>
                        )}
                    </div>

                    <div className="my-2 relative overflow-x-auto shadow-sm sm:rounded-lg border dark:border-gray-700 transition duration-9000 ease-in-out">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 relative">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        Date
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Tracking Code
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Logistio Code
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Status
                                    </th>
                                </tr>
                            </thead>

                            {isLoading ? (
                                <tbody>
                                    <tr className="border-b bg-white dark:bg-gray-800 dark:border-gray-700 animate-pulse">
                                        <th
                                            scope="row"
                                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                        >
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                        <td className="px-6 py-4 flex gap-2">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody>
                                    <tr
                                        className="border-b bg-white dark:bg-gray-800 dark:border-gray-700"
                                        key={data?._id}
                                    >
                                        <th
                                            scope="row"
                                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                        >
                                            {data?.createdAt ? format(new Date(data?.createdAt), 'dd/MM/yyyy') : '-'}
                                        </th>

                                        <td className="px-6 py-4">
                                            {data?.trackingCode ? (
                                                <div className="flex items-center gap-2">
                                                    <p className="font-bold">{data?.trackingCode}</p>
                                                    <Button
                                                        size="l"
                                                        color="white"
                                                        title="Copy Tracking code to clipboard"
                                                        onClick={() => {
                                                            handleCopyTC(data?.trackingCode || '', 'Tracking code');
                                                        }}
                                                    >
                                                        <FaRegCopy className="w-4 h-4" />
                                                    </Button>
                                                </div>
                                            ) : (
                                                '-'
                                            )}
                                        </td>

                                        <td className="px-6 py-4 flex gap-2">
                                            {data?.lgstTrackingCode ? (
                                                <div className="flex items-center gap-2">
                                                    <p className="font-bold">{data?.lgstTrackingCode}</p>
                                                    <Button
                                                        size="l"
                                                        color="white"
                                                        title="Copy Logistio code to clipboard"
                                                        onClick={() => {
                                                            handleCopyTC(data?.lgstTrackingCode || '', 'Logistio code');
                                                        }}
                                                    >
                                                        <FaRegCopy className="w-4 h-4" />
                                                    </Button>
                                                </div>
                                            ) : (
                                                '-'
                                            )}
                                        </td>

                                        <td className="px-6 py-4">
                                            {data?.status ? (
                                                <StatusBadge
                                                    name={
                                                        stockStatusMatch[
                                                            data.status?.toString() as '1' | '2' | '3' | '4'
                                                        ]?.label
                                                    }
                                                    style={`${
                                                        stockStatusMatch[
                                                            data.status?.toString() as '1' | '2' | '3' | '4'
                                                        ].badgeColor
                                                    } w-fit`}
                                                />
                                            ) : (
                                                '-'
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                    </div>
                    <div className="py-2">
                        <DataTable
                            rows={shippingHistory}
                            setRows={setShippingHistory}
                            columns={shipmentColumns}
                            pageState={{
                                isLoading,
                                total: 0,
                                pageSize: 0,
                                page: 0,
                                count: 0,
                            }}
                            setPageState={() => {}}
                        />
                    </div>
                </div>
            </GeneralDrawer>
        </>
    );
};
export default ShippingHistory;
