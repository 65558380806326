/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-fragments */
import React, { useState } from 'react';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import toast from 'react-hot-toast';
import { Modal, TextInput } from 'flowbite-react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import CustomBtn from 'components/customButton/customBtn';
import { logistioCurrencies } from 'utils/countryList';
import CustomError from 'components/customError/CustomError';
import { UpdateWalletCurrencyConfig } from 'utils/validations/walletCurrencyConfig';
import { IWalletCurrencyConfig, getConfiguration, updateConfigItem } from 'services/admin/configuration';
import { Form, Formik } from 'formik';
import { RiExchangeFill } from 'react-icons/ri';
import FlagIcon from 'components/flags/flagIcon';
import { MdCancel } from 'react-icons/md';
import { FaSave } from 'react-icons/fa';
import { walletCurrencyAccordionProps } from './walletCurrencyConfig';

const WalletCurrenciesAccordion: React.FC<walletCurrencyAccordionProps> = ({
    accords,
    configId,
    setWalletCurrencyConfig,
}) => {
    const [open, setOpen] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(0);

    const [updatingRows, setUpdatingRows] = useState<Array<boolean>>(accords.map(() => false));
    const [toggleAccord, setToggleAccord] = useState<Array<boolean>>(accords.map(() => false));

    const openAccord = (index: number) => {
        setToggleAccord((prevToggleAccord) => {
            const toggleAccordImage = [...prevToggleAccord];
            toggleAccordImage[index] = !toggleAccordImage[index];
            return toggleAccordImage;
        });
    };

    const updatePricing = (values: IWalletCurrencyConfig, id: string, indexItem: number) => {
        setUpdatingRows((prevUpdatingRows) => {
            const newUpdatingRows = [...prevUpdatingRows];
            newUpdatingRows[indexItem] = true;
            return newUpdatingRows;
        });
        const newValues = accords;
        newValues[indexItem] = values;
        updateConfigItem(id, { walletCurrency: newValues })
            .then((res) => {
                toast.success(res.data.message);
                setUpdatingRows((prevUpdatingRows) => {
                    const newUpdatingRows = [...prevUpdatingRows];
                    newUpdatingRows[indexItem] = false;
                    return newUpdatingRows;
                });
                setToggleAccord((prevToggleAccord) => {
                    const toggleAccordImage = [...prevToggleAccord];
                    toggleAccordImage[indexItem] = !toggleAccordImage[indexItem];
                    return toggleAccordImage;
                });
                getConfiguration().then((result) => {
                    setWalletCurrencyConfig(result.data?.walletCurrency || []);
                });
            })
            .catch((err) => {
                toast.error(err.response.data.errors.message);
                setUpdatingRows((prevUpdatingRows) => {
                    const newUpdatingRows = [...prevUpdatingRows];
                    newUpdatingRows[indexItem] = false;
                    return newUpdatingRows;
                });
            });
    };

    const deletePricing = (index: number) => {
        const newValues = accords;
        newValues.splice(index, 1);
        updateConfigItem(configId, { walletCurrency: newValues })
            .then(() => {
                toast.success('Currency configuration deleted');
                getConfiguration().then((result) => {
                    setWalletCurrencyConfig(result.data.walletCurrency || []);
                });
            })
            .catch((err) => toast.error(err.response.data.errors.message));
        setOpen(false);
    };

    return (
        <>
            {accords && (
                <>
                    <Modal show={open} size="md" popup onClose={() => setOpen(false)}>
                        <Modal.Header />
                        <Modal.Body>
                            <div className="text-center">
                                <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                                <p className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                    Are you sure you want to delete this country pricing ?{' '}
                                </p>
                                <div className="flex justify-center gap-4">
                                    <CustomBtn variant="tertiary" onClick={() => setOpen(false)}>
                                        No, cancel
                                    </CustomBtn>
                                    <CustomBtn
                                        variant="failure"
                                        onClick={() => {
                                            deletePricing(deleteIndex);
                                        }}
                                    >
                                        Yes, am sure
                                    </CustomBtn>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <div className=" border border-gray-200 dark:border-gray-700 dark:bg-gray-800 rounded-lg">
                        {accords.map((accord: any, index) => (
                            <>
                                {toggleAccord && toggleAccord[index] ? (
                                    <Formik
                                        key={accord.currency}
                                        initialValues={{
                                            currency: accord.currency || '',
                                            currencyValue: accord.currencyValue || '',
                                        }}
                                        onSubmit={(values, onSubmitProps) => {
                                            updatePricing(values, configId, accords.indexOf(accord));
                                            onSubmitProps.setSubmitting(false);
                                        }}
                                        validationSchema={UpdateWalletCurrencyConfig}
                                    >
                                        {(formik) => (
                                            <Form onSubmit={formik.handleSubmit} placeholder="">
                                                <div className=" grid grid-cols-6 text-center items-center p-2 ">
                                                    <div className="whitespace-nowrap font-medium text-gray-900 dark:text-white ">
                                                        1
                                                    </div>
                                                    <div className="whitespace-nowrap font-medium text-gray-900 dark:text-white flex items-center justify-center gap-2">
                                                        <FlagIcon flag="USD" />
                                                        <div>United States Dollar</div>
                                                    </div>
                                                    <div className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                                        <RiExchangeFill className="h-7 w-7" color="green" />
                                                    </div>
                                                    <div className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                                        <div>
                                                            <TextInput
                                                                {...formik.getFieldProps('currencyValue')}
                                                                name="currencyValue"
                                                                type="text"
                                                                helperText={
                                                                    <React.Fragment>
                                                                        <CustomError
                                                                            name="currencyValue"
                                                                            component="div"
                                                                        />
                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="whitespace-nowrap font-medium text-gray-900 dark:text-white flex gap-2 items-center justify-center">
                                                        <FlagIcon
                                                            flag={
                                                                logistioCurrencies?.find(
                                                                    (element) => element.code === accord.currency,
                                                                )?.code as string
                                                            }
                                                        />
                                                        <div>
                                                            {
                                                                logistioCurrencies?.find(
                                                                    (element) => element.code === accord.currency,
                                                                )?.label
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                                        <div className="flex gap-1">
                                                            <CustomBtn
                                                                variant="success"
                                                                size="sm"
                                                                pill
                                                                type="submit"
                                                                isProcessing={updatingRows[index]}
                                                                disabled={
                                                                    !formik.isValid ||
                                                                    !formik.dirty ||
                                                                    formik.isSubmitting ||
                                                                    updatingRows[index]
                                                                }
                                                            >
                                                                <FaSave className="h-6" />
                                                            </CustomBtn>
                                                            <CustomBtn
                                                                variant="failure"
                                                                pill
                                                                size="sm"
                                                                onClick={() => {
                                                                    openAccord(index);
                                                                }}
                                                            >
                                                                <MdCancel className="h-6" />
                                                            </CustomBtn>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                ) : (
                                    <div className=" grid grid-cols-6 text-center items-center p-2 ">
                                        <div className="whitespace-nowrap font-medium text-gray-900 dark:text-white ">
                                            1
                                        </div>
                                        <div className="whitespace-nowrap font-medium text-gray-900 dark:text-white flex items-center justify-center gap-2">
                                            <FlagIcon flag="USD" />
                                            <div>United States Dollar</div>
                                        </div>
                                        <div className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                            <RiExchangeFill className="h-7 w-7" color="green" />
                                        </div>
                                        <div className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                            <div>{accord.currencyValue}</div>
                                        </div>
                                        <div className="whitespace-nowrap font-medium text-gray-900 dark:text-white flex gap-2 items-center justify-center">
                                            <FlagIcon
                                                flag={
                                                    logistioCurrencies?.find(
                                                        (element) => element?.code === accord?.currency,
                                                    )?.code as string
                                                }
                                            />
                                            <div>
                                                {
                                                    logistioCurrencies?.find(
                                                        (element) => element.code === accord.currency,
                                                    )?.label
                                                }
                                            </div>
                                        </div>
                                        <div className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                            <div className="flex gap-1">
                                                <CustomBtn
                                                    variant="primary"
                                                    size="sm"
                                                    pill
                                                    onClick={() => {
                                                        openAccord(index);
                                                    }}
                                                >
                                                    <AiFillEdit className="h-6" />
                                                </CustomBtn>
                                                <CustomBtn
                                                    variant="failure"
                                                    pill
                                                    size="sm"
                                                    onClick={() => {
                                                        setOpen(true);
                                                        setDeleteIndex(accords.indexOf(accord));
                                                    }}
                                                >
                                                    <AiFillDelete className="h-6" />
                                                </CustomBtn>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {accords.length && accords.length > index + 1 ? (
                                    <div className="flex justify-center px-4">
                                        <div className="border-t border-gray-200 dark:border-gray-700  w-full  " />
                                    </div>
                                ) : (
                                    ''
                                )}
                            </>
                        ))}
                    </div>
                </>
            )}
        </>
    );
};

export default WalletCurrenciesAccordion;
