/* eslint-disable no-underscore-dangle */
import { Box } from '@mui/material';
import React from 'react';
import { getHistory, IOrder } from 'services/admin/order';
import { Badge, Button, Modal } from 'flowbite-react';
import { orderStatus } from 'pages/orders/orders.styles';
import { FaHistory } from 'react-icons/fa';
import { getOrderTraces } from 'services/admin/orderTrace';
import { IStatusTimeline } from 'pages/orders/orders.types';
import StatusTimeline from 'pages/orders/orderStatusTimeline';
import { ChatStyleHeader } from './chatHeader.styles';

const OrderHeader = ({ order }: { order: IOrder }) => {
    const [history, setHistory] = React.useState<IStatusTimeline>({ history: [], deliveryAttempts: [] });
    const [open, setOpen] = React.useState(false);

    const showHistory = () => {
        getHistory(order._id).then((data) => {
            getOrderTraces({ order: order._id, logistioStatus: 10 }).then((dataTraces) => {
                setHistory({ history: data?.data, deliveryAttempts: dataTraces?.data.docs });
                setOpen(true);
            });
        });
    };

    return (
        <>
            <Box sx={ChatStyleHeader}>
                <div className="flex justify-center gap-3 w-full">
                    <div className="w-full">
                        <div className="flex justify-between items-center gap-3 w-full flex-wrap ">
                            <div>
                                <div className="flex items-center gap-4">
                                    <p className="truncate max-w-96 font-bold text-base font-popin ">
                                        Order ref: {order?.orderRef}{' '}
                                    </p>

                                    <Button className="fit" pill color="light" onClick={showHistory}>
                                        <FaHistory />
                                    </Button>
                                </div>

                                <p className="font-normal ">{order.country}</p>
                            </div>
                            <Badge color={orderStatus(order?.status).color} className="w-fit">
                                {orderStatus(order?.status).title}
                            </Badge>
                        </div>
                    </div>
                </div>
            </Box>
            <Modal dismissible show={open} popup onClose={() => setOpen(false)}>
                <Modal.Header>Order Status History</Modal.Header>
                <Modal.Body className="!p-0 overflow-y-auto h-[34rem]">
                    {order && <StatusTimeline setOpen={setOpen} history={history} order={order} />}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default OrderHeader;
