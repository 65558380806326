import React, { useState } from 'react';
import CustomBtn from 'components/customButton/customBtn';
import { getAllCallAgents, updateCallAgent } from 'services/admin/callAgent';
import toast from 'react-hot-toast';

const CallAgentAccountManagment: React.FC<{
    status: number;
    id: string;
    setRows: React.Dispatch<React.SetStateAction<never[]>>;
    currentPage: number;
    currentFilter?: Record<string, any>;
}> = ({ status, id, setRows, currentPage, currentFilter }) => {
    const [isLoading, setIsLoading] = useState(false);

    const desactivateAccount = (value: any) => {
        setIsLoading(true);
        updateCallAgent(id, value)
            .then(() => {
                getAllCallAgents({ page: currentPage, ...currentFilter }).then((res) => setRows(res.data.docs));
            })
            .then(() => {
                if (status === 2) {
                    toast.success('Account Activated');
                    setIsLoading(false);
                } else {
                    toast.success('Account Desactivated');
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                toast.error(err.response.data.errors.message);
                setIsLoading(false);
            });
    };
    return (
        <>
            <div className="flex items-center gap-3 p-2 justify-center">
                <div>
                    <CustomBtn
                        variant={status === 2 ? 'success' : 'failure'}
                        onClick={() => {
                            let newVal = status;
                            if (status === 1) {
                                newVal = 2;
                            } else {
                                newVal = 1;
                            }
                            desactivateAccount({ status: newVal });
                        }}
                        isProcessing={isLoading}
                        disabled={isLoading}
                    >
                        {status === 2 ? 'Activate account' : 'Disable account'}
                    </CustomBtn>
                </div>
            </div>
        </>
    );
};

export default CallAgentAccountManagment;
