export const MessageStyleHeader = {
    padding: '0px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'secondaryBackground',
    height: '70px',
};

export const ImageNextToText = {
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'center',
    gap: 3,
};

export const TitleStyle = { display: 'flex', gap: 3 };
