/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable react/button-has-type */
/* eslint-disable no-underscore-dangle */
import Widget from 'components/dashboard/card';
import React, { useState } from 'react';

import { Select } from 'flowbite-react';
import Datepicker from 'react-tailwindcss-datepicker';
import { countries } from 'utils/countryList';

import ExcelJS from 'exceljs';
import moment from 'moment';

import CustomBtn from 'components/customButton/customBtn';
import toast from 'react-hot-toast';
import { getLeads } from 'services/callAgentAdmin/dashboard';
import { selectTheme } from '../dashboard.styles';
import { IData } from '../dashboard.types';
import { CardInfo, IFilterCCADashboard } from './dashboard.types';
import { reportGeneralInfo, reportcards } from './elements';

export interface IdateRange {
    startDate: string | null | Date;
    endDate: string | null | Date;
}

const ReportLeads: React.FC<{
    filter: IFilterCCADashboard;
    setFilter: any;
    data: IData;
    loading: boolean;
    callAgentAdmin: any;
    callcenterName: string;
}> = ({ filter, setFilter, data, loading, callAgentAdmin, callcenterName }) => {
    const [RangeValue, setRangeValue] = useState<IdateRange | null>(
        filter.dateRange
            ? {
                  startDate: moment.tz(filter.dateRange.startDate, 'Asia/Shanghai').format('YYYY-MM-DD'),
                  endDate: moment.tz(filter.dateRange.endDate, 'Asia/Shanghai').format('YYYY-MM-DD'),
              }
            : null,
    );
    const fetchData = (countryFilter: any) => {
        return Promise.all([
            getLeads({
                ...filter,
                ...(callAgentAdmin?.callCenter?.length === 1
                    ? { callCenter: [callAgentAdmin?.callCenter[0]._id] }
                    : {}),
                country: countryFilter,
            }),
        ]);
    };

    const formatPercValue = (val = 0) => {
        return Number.isNaN(val) || 0 ? 0 : val;
    };
    const handleExport = async () => {
        const countryArr = ['United Arab Emirates', 'Qatar', 'Saudi Arabia', 'Oman', 'Bahrain', 'Kuwait'];
        const promises = countryArr.map(fetchData);
        const res = await Promise.all(promises)
            .then((results) => {
                return countryArr.map((country, index) => {
                    const leadsResult = results[index][0];
                    const leads = leadsResult?.data?.period;

                    return {
                        country,
                        total_orders: leads.ordersAssignedCount,
                        confirmed_orders: leads.confirmedCount,
                        delivery_orders: leads.deliveryCount,
                        canceled_orders: leads.canceledCount,
                        wrong_number_orders: leads.wrongNumberCount,
                        expired_orders: leads.expiredCount,
                        other: leads.otherCount,
                        confirmation_rate: formatPercValue(leads?.confirmedCount / leads.ordersAssignedCount),
                        delivery_rate: formatPercValue(leads?.deliveryCount / leads.confirmedCount),
                        canceled_rate: formatPercValue(leads?.canceledCount / leads.ordersAssignedCount),
                        wrong_number_rate: formatPercValue(leads?.wrongNumberCount / leads.ordersAssignedCount),
                        expired_rate: formatPercValue(leads?.expiredCount / leads.ordersAssignedCount),
                        other_rate: formatPercValue(leads?.otherCount / leads.ordersAssignedCount),
                    };
                });
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message || 'Unable to load data.');
                return [];
            });
        if (!res.length) return;

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Call Center Report');
        const start = moment(RangeValue?.startDate).format('MM/DD/YYYY') || '';
        const end = moment(RangeValue?.endDate).format('MM/DD/YYYY') || '';
        if (callAgentAdmin?.callCenter?.length > 1) {
            worksheet.getCell('A1').value = 'Selected call center';
            worksheet.getCell('B1').value = callcenterName || 'All call center';
            worksheet.getCell('A2').value = 'Selected period';
            worksheet.getCell('B2').value = start;
            worksheet.getCell('C2').value = end;
        } else {
            worksheet.getCell('A1').value = 'Selected period';
            worksheet.getCell('B1').value = start;
            worksheet.getCell('C1').value = end;
        }
        worksheet.getCell('A1').font = { bold: true };
        worksheet.getCell('A2').font = { bold: true };
        worksheet.addRow([]);
        const headers = {
            country: 'Country',
            total_orders: 'Total orders',
            confirmed_orders: 'Confirmed orders',
            delivery_orders: 'Delivered orders',
            canceled_orders: 'Canceled orders',
            wrong_number_orders: 'Wrong number orders',
            expired_orders: 'Expired orders',
            other: 'Other',
            confirmation_rate: 'Confirmation rate',
            delivery_rate: 'Delivery rate',
            canceled_rate: 'Canceled rate',
            wrong_number_rate: 'Wrong number rate',
            expired_rate: 'Expired rate',
            other_rate: 'Other rate',
        };
        worksheet.addRow(Object.values(headers));
        res.forEach((row: any) => {
            const arr = Object.keys(headers).map((el: string) => row[el]);
            worksheet.addRow(arr);
        });

        // eslint-disable-next-line prefer-const
        let sum: any = {};
        res.forEach((row: any) => {
            Object.keys(headers).forEach((el: string) => {
                if (el === 'country') return;
                sum[el] = sum[el] ? sum[el] + Number(row[el]) : Number(row[el]);
            });
        });

        const total = Object.keys(sum).map((el) => {
            const label =
                el === 'confirmation_rate'
                    ? 'confirmed_orders'
                    : el === 'other_rate'
                      ? 'other'
                      : el.replace('rate', 'orders');
            const val = !el.toLowerCase().includes('rate')
                ? sum[el]
                : sum[label] / (el.toLowerCase().includes('delivery') ? sum.confirmed_orders : sum.total_orders);
            return formatPercValue(val);
        });

        worksheet.addRow(['Total', ...total]);

        const headerRow = worksheet.getRow(4);
        headerRow.font = { bold: true };

        for (let i = 1; i <= Object.keys(headers).length; i++) {
            const col = worksheet.getColumn(i);
            worksheet.getColumn(i).eachCell((cell: ExcelJS.Cell) => {
                if (
                    Object.keys(headers)[i - 1].includes('rate') &&
                    typeof cell.value === 'number' &&
                    !Number.isNaN(cell.value)
                ) {
                    cell.numFmt = '0.00%';
                }
            });
            col.width = 18;
            if (i > 1) col.alignment = { vertical: 'middle', horizontal: 'center' };
        }

        workbook.xlsx.writeBuffer().then((dataBlob) => {
            const blob = new Blob([dataBlob], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = 'dashboard_data_by_country.xlsx';
            anchor.click();
            window.URL.revokeObjectURL(url);
        });
    };

    const handleRangeFilterChange = (newValue: any) => {
        let newRange: typeof newValue | undefined;
        if (newValue && newValue.startDate && newValue.endDate) {
            const startD = moment.tz(newValue.startDate, 'Asia/Shanghai');
            const endD = moment.tz(newValue.endDate, 'Asia/Shanghai');
            startD.set('hour', 0);
            startD.set('minute', 0);
            startD.set('second', 0);
            endD.set('hour', 23);
            endD.set('minute', 59);
            endD.set('second', 59);
            newRange = {
                startDate: startD.format(),
                endDate: endD.format(),
            };
            setRangeValue({
                startDate: startD.format('YYYY-MM-DD'),
                endDate: endD.format('YYYY-MM-DD'),
            });
        } else setRangeValue(newValue);
        setFilter({ ...filter, dateRange: newRange });
    };

    return (
        <div>
            <div className="flex justify-between ">
                <div className="flex gap-4">
                    <Datepicker
                        inputId="deliveryDate"
                        value={RangeValue}
                        containerClassName="relative z-100"
                        showShortcuts
                        showFooter={false}
                        useRange
                        onChange={handleRangeFilterChange}
                    />
                    <Select
                        theme={selectTheme}
                        color="general"
                        id="countryFilter"
                        required
                        onChange={(e) => {
                            const temp = { ...filter };
                            if (e.target.value !== '') temp.country = e.target.value;
                            else delete temp.country;
                            setFilter(temp);
                        }}
                        value={filter.country || ''}
                    >
                        <option value="">All Countries</option>
                        {countries.map((el) => {
                            return (
                                <option value={el.label} key={el.phone}>
                                    {el.label}
                                </option>
                            );
                        })}
                    </Select>
                </div>
                <div className="flex items-center">
                    <CustomBtn variant="primary" disabled={loading} onClick={handleExport}>
                        Export CSV
                    </CustomBtn>
                </div>
            </div>
            {/* <hr className="w-full col-span-full" /> */}
            <div>
                <div className="grid gap-4 mt-2 tablet:grid-cols-2 laptop:grid-cols-3 desktop:grid-cols-3 py-2">
                    {reportGeneralInfo.map(({ key, Icon, color, bgColor, title }: CardInfo) => {
                        const value = data
                            ? data[key as keyof IData]?.percent?.toFixed(2) || data[key as keyof IData]?.total
                            : 0;
                        return (
                            <Widget
                                key={key}
                                title={title}
                                inputValue={value as number}
                                isPerc={key !== 'ordersAssignedCount'}
                                icon={
                                    <div className="w-1/3 h-10  rounded-full flex justify-center items-center ">
                                        <Icon className="w-7 h-7" color={color} />
                                    </div>
                                }
                                bgcolor={bgColor || color}
                                loading={loading}
                            />
                        );
                    })}
                </div>
            </div>
            <div className="text-lg">Orders Status</div>

            <div>
                <div className="grid gap-4 mb-8 tablet:grid-cols-2 laptop:grid-cols-3 desktop:grid-cols-4 py-2">
                    {reportcards.map(({ key, Icon, color, bgColor, title }: CardInfo) => (
                        <Widget
                            key={key}
                            loading={loading}
                            title={title}
                            inputValue={data && data[key as keyof IData]?.total}
                            statPourcentage={data && data[key as keyof IData]?.percent}
                            icon={
                                <div className="w-10 h-10 rounded-full flex justify-center items-center ">
                                    <Icon className="w-7 h-7" color={color} />
                                </div>
                            }
                            footer
                            bgcolor={bgColor || color}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ReportLeads;
