import axios, { AxiosResponse } from 'axios';
import { getBearerToken, isAuthenticated } from './helpers/auth';

const headers = {};
if (isAuthenticated()) {
    const { Authorization } = getBearerToken().headers;
    Object.assign(headers, { Authorization });
}

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers,
});

const onFulfilled = (config: AxiosResponse<any, any>) => {
    return config;
};

const onRejected = (error: any) => {
    if (error && error?.response && error?.response?.status === 401) {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('user');
        const baseURL = window.location.origin;
        window.location.assign(`${baseURL}/auth/login`);
    }
    return Promise.reject(error);
};

axiosInstance.interceptors.response.use(onFulfilled, onRejected);

export default axiosInstance;
