import React from 'react';

import { ErrorMessage } from 'formik';
import { ErrorProps } from './CustomError.types';

const TextError: React.FC<ErrorProps> = ({ name }) => {
    return (
        <>
            <ErrorMessage name={name} render={(msg) => <span className="font-medium text-red-600 ">{msg}</span>} />
        </>
    );
};

export default TextError;
