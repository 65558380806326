import axios from 'utils/axios';
import { getBearerToken } from 'utils/helpers/auth';

export const addProductProviders = async (data: any) => {
    const newAdmin = await axios.post(`/admin/productproviders`, data, getBearerToken());
    return newAdmin;
};

export const getAllProductProviders = async (filter: any) => {
    const admins = await axios.post(`/admin/productproviders/filter`, filter, getBearerToken());
    return admins;
};

export const getProductProviders = async (id: any) => {
    const admin = await axios.get(`/admin/productproviders/${id}`, getBearerToken());
    return admin;
};

export const updateProductProviders = async (id: any, data: any) => {
    const adminUpdate = await axios.put(`/admin/productproviders/${id}`, data, getBearerToken());
    return adminUpdate;
};
