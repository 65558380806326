/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-fragments */
/* eslint-disable no-underscore-dangle */
import { Avatar, Badge, Label, Textarea } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
// import { cities } from 'utils/vnlinCities';
import CustomBtn from 'components/customButton/customBtn';
import CustomError from 'components/customError/CustomError';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { IOrder, getOrders, updateOrder } from 'services/admin/order';
import { IStore } from 'services/admin/store';
import { IUser } from 'services/auth';
import { getQuestionHistory, pendingQuestion } from 'services/callAgent/orders';
import { getCurrentUser } from 'utils/helpers/auth';
import { AccountManagerInquiryResponseSchema } from 'utils/validations/ordersValidations';
import { IProduct } from 'utils/Interfaces/products.interface';
import { ICustomer } from 'pages/customers/customer.types';
import { getSimilarProducts } from 'services/admin/product';
import { OrderDetail } from './orderCardDetail';
import { OrderCordinateAdmin } from './orderCoordinateAdmin';
import { OrderInformation } from './orderInformation';
import AvailableProducts from './similarProduct/availableProducts';

const OrderDetails: React.FC<{
    data: IOrder;
    setRows: React.Dispatch<React.SetStateAction<IOrder[]>>;
    currentPage: number;
    currentFilter?: Record<string, any>;
    setOpenDetails: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ data, setRows, currentPage, currentFilter, setOpenDetails }) => {
    const [editCordinate, setEditCordinate] = useState(false);
    const [editInfo, setEditInfo] = useState(false);
    const [similarProducts, setSimilarProducts] = useState<IProduct[]>([]);

    const [saveCordinateLoader, setSaveCordinateLoader] = useState(false);
    const [saveOrderInformationLoader, setSaveOrderInformationLoader] = useState(false);

    const updateOrderInformations = (Feedback: any) => {
        setSaveCordinateLoader(true);
        updateOrder({ id: data._id, ...Feedback })
            .then((res) => {
                toast.success(res.data.message);
                setEditCordinate(false);
                setEditInfo(false);
                setSaveCordinateLoader(false);
            })
            .then(() => {
                getOrders({ page: currentPage, ...(currentFilter || {}) })
                    .then((orderData) => {
                        setRows(orderData.data.docs);
                        if (orderData.data.docs.length === 0) {
                            getOrders({ page: 1, ...(currentFilter || {}) })
                                .then((prevPageData) => {
                                    setRows(prevPageData.data.docs);
                                })
                                .catch(() => {
                                    toast.error(`Something went wrong, please try again`);
                                });
                        } else {
                            setRows(orderData.data.docs);
                        }
                    })
                    .catch(() => {
                        toast.error(`Something went wrong, please try again`);
                    });
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message);
                setSaveCordinateLoader(false);
            });
    };

    const updateBuyerInformation = (Feedback: any) => {
        setSaveOrderInformationLoader(true);
        updateOrder({ id: data._id, ...Feedback })
            .then((res) => {
                toast.success(res.data.message);
                setEditCordinate(false);
                setEditInfo(false);
                setSaveOrderInformationLoader(false);
            })
            .then(() => {
                getOrders({ page: currentPage, ...(currentFilter || {}) })
                    .then((orderData) => {
                        setRows(orderData.data.docs);
                        if (orderData.data.docs.length === 0) {
                            getOrders({ page: 1, ...(currentFilter || {}) })
                                .then((prevPageData) => {
                                    setRows(prevPageData.data.docs);
                                })
                                .catch(() => {
                                    toast.error(`Something went wrong, please try again`);
                                });
                        } else {
                            setRows(orderData.data.docs);
                        }
                    })
                    .catch(() => {
                        toast.error(`Something went wrong, please try again`);
                    });
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message);
                setSaveOrderInformationLoader(false);
            });
    };

    useEffect(() => {
        getSimilarProducts({
            stocked: true,
            store: (data.store as IStore)._id,
            customer: (data.customer as ICustomer)._id,
            country: data.country,
        })
            .then((res) => {
                setSimilarProducts(res.data);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message || 'Something went wrong, please try again');
            });
    }, []);

    const [answerHistory, setAnswerHistory] = useState<IOrder['inquiriesConversation']>();

    useEffect(() => {
        getQuestionHistory(data._id)
            .then((result) => {
                setAnswerHistory(result.data);
            })
            .catch((e) => {
                toast.error(e?.response?.data?.errors?.message || 'Something went wrong');
            });
    }, []);

    const [editing, setEditing] = useState(false);

    return (
        <div className="p-2">
            <div className="flex flex-col gap-2 p-2">
                <div className="flex gap-2 flex-wrap  ">
                    <Badge color="indigo" size="xs" className="font-medium text-xs">
                        Store:{' '}
                        <span className="font-bold">
                            {(data?.store as IStore)?.storeName ? (data.store as IStore).storeName : '--'}
                        </span>
                    </Badge>
                    <Badge size="xs" color="indigo" className="font-medium text-xs">
                        Order reference: <span className="font-bold">{data?.orderRef ? data.orderRef : '--'}</span>
                    </Badge>
                    <Badge size="xs" color="blue" className="font-medium text-xs">
                        Logistio code: <span className="font-bold">{data?.lgstTrackingCode}</span>
                    </Badge>

                    {(data?.store as IStore)?.storeLink && (
                        <a
                            href={
                                (data?.store as IStore).storeLink.startsWith('http:') ||
                                (data?.store as IStore).storeLink.startsWith('https:')
                                    ? (data?.store as IStore).storeLink
                                    : `//${(data?.store as IStore).storeLink}`
                            }
                            target="_blank"
                            rel="noreferrer"
                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                        >
                            <span className="inline-block overflow-hidden overflow-ellipsis  whitespace-nowrap">
                                Store link
                            </span>
                        </a>
                    )}
                </div>
            </div>
            <div className="grid grid-cols-3 p-2 mb-2 gap-4 w-full">
                <OrderCordinateAdmin
                    data={data}
                    editCordinate={editCordinate}
                    setEditCordinate={setEditCordinate}
                    updateOrderInformations={updateOrderInformations}
                    saveCordinateLoader={saveCordinateLoader}
                />
                <OrderInformation
                    data={data}
                    editInfo={editInfo}
                    setEditInfo={setEditInfo}
                    updateOrderInformations={updateBuyerInformation}
                    saveOrderInformationLoader={saveOrderInformationLoader}
                />
                <OrderDetail data={data} />
            </div>

            <AvailableProducts
                similarProducts={similarProducts as IProduct[]}
                data={data}
                setRows={setRows}
                currentPage={currentPage}
                currentFilter={currentFilter}
            />

            {data.withCC && answerHistory && answerHistory?.length > 0 && (
                <div className="bg-white p-2 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  ">
                    <h5 className="mb-2">Previous requests :</h5>
                    <div className="flex px-2 gap-4 ">
                        <div>
                            <Avatar
                                rounded
                                img={getCurrentUser().profileImage}
                                alt={`${getCurrentUser().firstName} ${getCurrentUser().lastName}`}
                            />
                        </div>
                        <div className="flex-grow">
                            {editing ? (
                                <div className=" p-2">
                                    <Formik
                                        initialValues={{
                                            content: '',
                                        }}
                                        onSubmit={(values, onSubmitProps) => {
                                            setSaveOrderInformationLoader(true);
                                            pendingQuestion({ orderId: data._id, content: values.content })
                                                .then(() => {
                                                    toast.success('Question Sent');
                                                    setSaveOrderInformationLoader(false);
                                                    setEditing(false);
                                                    setOpenDetails(false);
                                                    getOrders({ page: currentPage, ...(currentFilter || {}) })
                                                        .then((orderData) => {
                                                            setRows(orderData.data.docs);
                                                            if (orderData.data.docs.length === 0) {
                                                                getOrders({ page: 1, ...(currentFilter || {}) })
                                                                    .then((prevPageData) => {
                                                                        setRows(prevPageData.data.docs);
                                                                    })
                                                                    .catch(() => {
                                                                        toast.error(
                                                                            `Something went wrong, please try again`,
                                                                        );
                                                                    });
                                                            } else {
                                                                setRows(orderData.data.docs);
                                                            }
                                                        })
                                                        .catch(() => {
                                                            toast.error(`Something went wrong, please try again`);
                                                        });
                                                })
                                                .catch((e) => {
                                                    toast.error(e?.response?.data?.errors?.message);
                                                    setSaveOrderInformationLoader(false);
                                                    setEditing(false);
                                                    setOpenDetails(false);
                                                });

                                            onSubmitProps.setSubmitting(false);
                                        }}
                                        validationSchema={AccountManagerInquiryResponseSchema}
                                    >
                                        {(formik) => (
                                            <Form onSubmit={formik.handleSubmit} placeholder="">
                                                <div>
                                                    <div>
                                                        <div id="textarea">
                                                            <div className="mb-2 block">
                                                                <Label htmlFor="content" value="Provide your answer" />
                                                            </div>
                                                            <Textarea
                                                                id="feedback"
                                                                placeholder="Write your message ..."
                                                                rows={4}
                                                                {...formik.getFieldProps('content')}
                                                                name="content"
                                                                helperText={
                                                                    <React.Fragment>
                                                                        <CustomError name="content" component="div" />
                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="flex gap-1 ">
                                                        <CustomBtn
                                                            variant="primary"
                                                            type="submit"
                                                            // className="w-full"
                                                            disabled={
                                                                !formik.isValid || !formik.dirty || formik.isSubmitting
                                                            }
                                                            isProcessing={saveOrderInformationLoader}
                                                        >
                                                            Save
                                                        </CustomBtn>
                                                        <CustomBtn
                                                            variant="tertiary"
                                                            type="button"
                                                            onClick={() => setEditing(false)}
                                                        >
                                                            Cancel
                                                        </CustomBtn>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            ) : (
                                <div
                                    className="border border-gray-600 p-2 rounded-md mb-4 cursor-text "
                                    onClick={() => setEditing(true)}
                                >
                                    Click to write your message...
                                </div>
                            )}
                        </div>
                    </div>

                    <div>
                        {answerHistory?.map((inquiry) => {
                            const user = inquiry.sentBy as Pick<
                                IUser,
                                'firstName' | 'lastName' | 'profileImage' | 'role' | '_id'
                            >;
                            return (
                                <div className=" flex p-2 mb-2 ">
                                    <div className="flex-shrink-0">
                                        <Avatar
                                            img={user.profileImage}
                                            rounded
                                            alt={`${user.firstName} ${user.lastName}`}
                                        />
                                    </div>
                                    <div className="flex-1 min-w-0 ms-4  dark:bg-[#374152] bg-[#F1F2F5] rounded-lg py-2 px-3">
                                        <div className="flex gap-2">
                                            <p className=" text-sm font-medium text-gray-900  dark:text-white flex gap-1">
                                                {user.firstName} {user.lastName}
                                            </p>
                                            <Badge color="indigo" className="w-fit" size="xs">
                                                {user.role}
                                            </Badge>
                                        </div>
                                        <div>
                                            <p className=" text-xs font-light text-gray-500  dark:text-gray-400">
                                                {moment(inquiry.sentAt).format('MM/DD/YYYY HH:mm:SS')}
                                            </p>
                                        </div>
                                        <p className=" text-base text-gray-900   dark:text-white pt-1">
                                            {inquiry.content}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default OrderDetails;
