export const vnlinWarehouses = {
    '64': {
        id: '64',
        country: 'United Arab Emirates',
        name: 'UAE warehouse',
        transportMode: 7630,
    },
    '90': { id: '90', country: 'Qatar', name: 'Qatar warehouse', transportMode: 7625 },
    '91': { id: '91', country: 'Kuwait', name: 'Kuwait warehouse', transportMode: 7626 },
    '99': {
        id: '99',
        country: 'Saudi Arabia',
        name: 'Riyadh Second Warehouse',
        transportMode: 7622,
    },
};
