/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */

import VideoUpload from 'components/mediaUpload/videoUpload';
import { Button, Modal } from 'flowbite-react';
import React, { useState } from 'react';
import { IoMdRemoveCircle } from 'react-icons/io';
import { IoSearch } from 'react-icons/io5';
import { openPdfInNewTab } from 'utils/helpers/productFilesDownloader';

import Uploader from 'components/mediaUpload/imageUpload';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { deleteMedia } from 'services/admin/resellerProduct';
import FileOther from '../../../../assets/file.png';
import FilePdf from '../../../../assets/pdf.png';
import { IMediaUploaderProps } from './types';

const MediaUploader: React.FC<IMediaUploaderProps> = (props) => {
    const location: any = useLocation();
    const { setAllImages, allImages, resellerVideos, setResellerVideos, allVideos, setAllVideos } = props;
    const [selectedImgForDelete, setSelectedImgForDelete] = useState('');
    const [selectedVidForDelete, setSelectedVidForDelete] = useState('');
    const [openDeleteVideoModal, setOpenDeleteVideoModal] = useState(false);
    const [openDeleteImgModal, setOpenDeleteImgModal] = useState(false);
    const handleOpenDeleteImgModal = (state: boolean) => {
        setOpenDeleteImgModal(state);
    };
    const handleOpenDeleteVideoModal = (state: boolean) => {
        setOpenDeleteVideoModal(state);
    };
    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            Array.from(e.target.files).forEach((file) => {
                if (file) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        const newImage: (typeof allImages)[number] = {
                            name: file.name,
                            url: URL.createObjectURL(file),
                            file,
                            base64: reader.result,
                        };
                        setAllImages((imgs) => {
                            return [...imgs, newImage];
                        });
                    };
                }
            });
        }
    };

    const handleVideoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            Array.from(e.target.files).forEach((file) => {
                if (file) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        const newVideo = {
                            name: file.name,
                            url: URL.createObjectURL(file),
                            file,
                            base64: reader.result,
                        };

                        setAllVideos((vids) => [...vids, newVideo]);
                        setResellerVideos((urls) => [...urls, newVideo.url]);
                    };
                }
            });
        }
    };
    const handleDeleteVideo = () => {
        // Pending UI logic

        // setIsLoading(true);
        // toast.loading('Deleting image in progress...');

        /**
         * 🏷️ Check if the image to delete is already updloaded or not
         *
         * Not uploaded images have a field called `file` containing the
         * image blob so already uploaded images does not have this field
         *  */
        const isNewVideo = allVideos.some((video) => {
            return video.url === selectedVidForDelete && !!video.file;
        });

        /**
         * 🏷️ Delete action
         *
         * If the video is not uploaded yet (`isNewVideo === true`):
         *      we remove its data from the local states
         *      &
         *      we release its objectURL from the browser (performance & memory usage optimisation)
         * Else (`isNewVideo === false`):
         *      we send a delete request to the backend.
         */
        if (isNewVideo) {
            const updatedData: typeof allVideos = [];
            allVideos.forEach((video) => {
                if (video.url !== selectedVidForDelete) {
                    updatedData.push(video);
                } else {
                    URL.revokeObjectURL(video.url);
                }
            });
            setAllVideos([...updatedData]);
            setResellerVideos(() => [...updatedData.map((video) => video.url)]);
            // Action feedback

            // setIsLoading(false);
            toast.success('Video deleted successfully');
        } else {
            deleteMedia({ productId: location.state.productId, link: selectedVidForDelete, type: 'videos' })
                .then((res) => {
                    setAllVideos([
                        ...res.data.map((url: string) => ({
                            name: url.substring(url.indexOf('.com/') + 5), // selects 'imgName' from 'https://domain.com/imgName'
                            url,
                        })),
                    ]);
                    setResellerVideos([...res.data.map((url: string) => url)]);
                    toast.success('Video deleted successfully');
                })
                .catch(() => {
                    toast.error('Sorry, something went wrong! Please try again.');
                })
                .finally(() => {
                    // setIsLoading(false);
                });
        }
        setOpenDeleteVideoModal(false);
    };
    const handleDeleteImage = () => {
        const isNewImage = allImages.some((image) => {
            return image.url === selectedImgForDelete && !!image.file;
        });

        if (isNewImage) {
            const updatedData: typeof allImages = [];
            allImages.forEach((img) => {
                if (img.url !== selectedImgForDelete) {
                    updatedData.push(img);
                } else {
                    URL.revokeObjectURL(img.url);
                }
            });
            setAllImages([...updatedData]);
            toast.success('Image deleted successfully');
        } else {
            deleteMedia({ productId: location.state.productId, link: selectedImgForDelete, type: 'images' })
                .then((res) => {
                    setAllImages([
                        ...res.data.map((url: string) => ({
                            name: url.substring(url.indexOf('.com/') + 5), // selects 'imgName' from 'https://domain.com/imgName'
                            url,
                        })),
                    ]);
                    toast.success('Image deleted successfully');
                })
                .catch(() => {
                    toast.error('Sorry, something went wrong! Please try again.');
                })
                .finally(() => {
                    // setIsLoading(false);
                });
        }

        setOpenDeleteImgModal(false);
    };
    return (
        <div>
            <Modal show={openDeleteImgModal}>
                <Modal.Body>
                    <div
                        style={{
                            padding: '30px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minWidth: '500px',
                        }}
                    >
                        <p style={{ fontSize: 17, padding: '10px 20px' }}>Delete image ? </p>
                        <p>Deleted images can&apos;t be restored!</p>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '10px',
                                margin: '20px',
                            }}
                        >
                            <Button color="failure" onClick={handleDeleteImage} pill>
                                Delete
                            </Button>
                            <Button
                                color="gray"
                                pill
                                onClick={() => {
                                    handleOpenDeleteImgModal(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={openDeleteVideoModal}>
                <Modal.Body>
                    <div
                        style={{
                            padding: '30px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minWidth: '500px',
                        }}
                    >
                        <p style={{ fontSize: 17, padding: '10px 20px' }}>Delete video?</p>
                        <p>Deleted videos can&apos;t be restored!</p>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '10px',
                                margin: '20px',
                            }}
                        >
                            <Button color="failure" onClick={handleDeleteVideo} pill>
                                Delete
                            </Button>
                            <Button
                                color="gray"
                                pill
                                onClick={() => {
                                    handleOpenDeleteVideoModal(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <div className="grid  gap-4 bg-white rounded-lg shadow p-4 dark:bg-gray-800 mb-2">
                <h5 className="text-base font-semibold leading-7 text-gray-900">Images Section</h5>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
                        {allImages.map((imgLink) => {
                            return (
                                <div style={{ position: 'relative' }} key={imgLink.url}>
                                    {imgLink.name.endsWith('.jpg') ||
                                    imgLink.name.endsWith('.png') ||
                                    imgLink.name.endsWith('.jpeg') ||
                                    imgLink.name.endsWith('.webp') ||
                                    imgLink.name.endsWith('.svg') ||
                                    imgLink.name.endsWith('.svg%2Bxml') ? (
                                        <>
                                            <IoMdRemoveCircle
                                                className="h-6 w-6 absolute right-2"
                                                color="red"
                                                role="button"
                                                onClick={() => {
                                                    setSelectedImgForDelete(imgLink.url);
                                                    handleOpenDeleteImgModal(true);
                                                }}
                                            />

                                            <img
                                                src={(imgLink?.base64 as string) || imgLink.url}
                                                alt="quotation"
                                                title="Click to view PDF"
                                                className="cursor-pointer "
                                                style={{
                                                    objectFit: 'cover',
                                                    borderRadius: 8,
                                                    overflow: 'hidden',
                                                    width: 100,
                                                    height: 100,
                                                }}
                                                onClick={() => openPdfInNewTab(imgLink.url, 'Image preview')}
                                            />
                                        </>
                                    ) : imgLink.name.endsWith('.pdf') ? (
                                        <>
                                            <IoMdRemoveCircle
                                                className="h-6 w-6 absolute right-2"
                                                color="red"
                                                role="button"
                                                onClick={() => {
                                                    setSelectedImgForDelete(imgLink.url);
                                                    handleOpenDeleteImgModal(true);
                                                }}
                                            />

                                            <div className="relative">
                                                <img
                                                    src={FilePdf}
                                                    alt="quotation"
                                                    title="Click to view PDF"
                                                    className="cursor-pointer "
                                                    style={{
                                                        objectFit: 'cover',
                                                        borderRadius: 8,
                                                        overflow: 'hidden',
                                                        width: 100,
                                                        height: 100,
                                                    }}
                                                    onClick={() => openPdfInNewTab(imgLink.url, 'Image preview')}
                                                />
                                                <IoSearch
                                                    onClick={() => openPdfInNewTab(imgLink.url, 'Image preview')}
                                                    title="Click to view PDF"
                                                    className="absolute top-1 cursor-pointer left-4 h-5 w-5 text-red-500 hover:bg-red-200 hover:ring-2 ring-red-200 "
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <IoMdRemoveCircle
                                                className="h-6 w-6 absolute right-2"
                                                color="red"
                                                role="button"
                                                onClick={() => {
                                                    setSelectedImgForDelete(imgLink.url);
                                                    handleOpenDeleteImgModal(true);
                                                }}
                                            />

                                            <img
                                                src={FileOther}
                                                alt="reseller product"
                                                style={{
                                                    objectFit: 'cover',
                                                    borderRadius: 8,
                                                    overflow: 'hidden',
                                                    width: 100,
                                                    height: 100,
                                                }}
                                            />
                                        </>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div>
                    <div>
                        <Uploader
                            name="photo"
                            onChange={(e) => {
                                // handleUploadProductImage(e);
                                handleImageChange(e);
                            }}
                            accept=".jpg,.jpeg,.png,.webp,.svg,.svg%2Bxml"
                            multiple
                            title="Add Image"
                        />
                    </div>
                </div>
            </div>

            <div className="grid gap-4 bg-white rounded-lg shadow p-4 dark:bg-gray-800 mb-2">
                <h5 className="text-base font-semibold leading-7 text-gray-900">Videos Section</h5>
                <div>
                    {resellerVideos && (
                        <div className="grid grid-cols-4 gap-2">
                            {resellerVideos.map((videoLink) => {
                                let videoSrc = videoLink;
                                if (
                                    !videoSrc.toLowerCase().startsWith('https://') &&
                                    !videoSrc.toLowerCase().startsWith('http://') &&
                                    !videoSrc.toLowerCase().startsWith('blob')
                                ) {
                                    videoSrc = `https://${videoLink}`;
                                }

                                return (
                                    <div className="relative w-64" key={videoLink}>
                                        <IoMdRemoveCircle
                                            className="h-6 w-6 absolute right-2 z-10"
                                            color="red"
                                            role="button"
                                            onClick={() => {
                                                setSelectedVidForDelete(videoLink);
                                                handleOpenDeleteVideoModal(true);
                                            }}
                                        />

                                        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                                        <video
                                            src={videoSrc}
                                            controls
                                            className="cursor-pointer object-cover rounded-lg overflow-hidden w-64 h-40 object-cover "
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
                <div>
                    <VideoUpload
                        multiple
                        name="video"
                        onChange={(e) => {
                            handleVideoChange(e);
                        }}
                        title="Add video"
                    />
                </div>
            </div>
        </div>
    );
};
export default MediaUploader;
