/* eslint-disable no-nested-ternary */

import Modal from 'components/modal/Modal';
import React, { useEffect, useState } from 'react';
import { getStores, IStore } from 'services/admin/store';
import { getCustomers } from 'services/admin/customer';
import AutoComplete from 'components/autocomplete/autoComplete';
import UseDocumentTitle from 'utils/dynamicTitle/dynamicTitle';
import useDebouncedValue from 'utils/hooks/useDebouncedValue';
import toast from 'react-hot-toast';
import { AiOutlineSearch } from 'react-icons/ai';
import { TextInput } from 'flowbite-react';
import DataTable from '../../components/tables/tables';
import { columns, statusDisplay } from './tableElements';
import SingleStore from './SingleStore';

interface Ipagination {
    page: number;
    limit: number;
}

interface Imeta {
    totalDocs: number;
    limit: number;
    totalPages: number;
    page: number;
    pagingCounter: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    prevPage: number | null;
    nextPage: number | null;
}

interface Ifilter {
    storeName?: string;
    storeType?: string;
}

const StoresListing = () => {
    UseDocumentTitle('Stores');
    const [storesList, setStoresList] = useState<Array<IStore>>([]);
    const [storeData] = useState<IStore | {}>({});
    const [customers, setCustomers] = useState([]);

    const [customer] = useState('');
    const [customerId, setCustomerId] = useState('');
    useEffect(() => {
        if (customer) getCustomers({ limit: 1000, email: customer }).then((res) => setCustomers(res.data.docs));
        else getCustomers({ limit: 1000 }).then((res) => setCustomers(res.data.docs));
    }, [customer]);

    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 20,
    });
    const [filter, setFilter] = useState<Ifilter>({});
    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });
    const [isLoading, setIsLoading] = useState(false);

    const [openModal, setOpenModal] = useState(false);
    const handleChange = (event: any) => {
        setFilter({ ...filter, ...{ [event.target.name]: event.target.value || undefined } });
    };
    // fetch stores on component mount & pagination or filter change
    const debouncedPagination = useDebouncedValue(pagination, 500);
    const debouncedFilter = useDebouncedValue(filter, 500);
    useEffect(() => {
        /**
         * apply pagination & filter if filter options exists
         * else ignore filter options and only use pagination
         */
        const queryOptions = Object.values(debouncedFilter)[0]
            ? customerId !== ''
                ? { ...debouncedPagination, ...debouncedFilter, customer: customerId }
                : { ...pagination, ...filter }
            : customerId !== ''
              ? { ...debouncedPagination, customer: customerId }
              : { ...debouncedPagination };
        /**
         * check if we are requesting an active page (already fetched and is displayed)
         */
        // const fetchedAndActive = meta.page === pagination.page;
        /**
         * ignore request if page is available
         */
        /**
         * handle customer id filter change doesn't block request
         */
        // if (!fetchedAndActive) {
        setIsLoading(true);
        const toastLoader = toast.loading('Loading stores...');
        getStores(queryOptions)
            .then(({ data }) => {
                setStoresList(data.docs as never[]);
                setMeta({
                    hasNextPage: data.hasNextPage,
                    hasPrevPage: data.hasPrevPage,
                    limit: data.limit,
                    nextPage: data.nextPage,
                    page: data.page,
                    pagingCounter: data.pagingCounter,
                    prevPage: data.prevPage,
                    totalDocs: data.totalDocs,
                    totalPages: data.totalPages,
                });
                setIsLoading(false);
            })
            .catch((err) => {
                toast.error(err.response.data.errors.message, { id: toastLoader });
                setIsLoading(false);
            });
        //  }
    }, [debouncedFilter, debouncedPagination, customerId]);

    const handlePaginationChange = (value: { [key: string]: any }) => {
        setPagination((prevPagination) => {
            const data = { ...prevPagination, ...value };
            if (value.limit) {
                data.page = 1;
            }
            return data;
        });
        setMeta((prev) => ({ ...prev, page: -1 }));
    };

    // const handleRowClick = (id: GridRowId) => {
    //     setIsLoading(true);
    //     getStore(id as string)
    //         .then(({ data }) => {
    //             if (!data) {
    //                 setIsLoading(false);
    //                 setErrorData({
    //                     notifMessage: 'Oops something went wrong unexpectedly!',
    //                     notifType: 'error',
    //                     open: true,
    //                 });
    //                 return;
    //             }
    //             setStoreData(data);
    //             setIsLoading(false);
    //             setOpenModal(true);
    //         })
    //         .catch((err) => {
    //             setIsLoading(false);
    //             setErrorData({
    //                 notifMessage: err.response.data.errors.message,
    //                 notifType: 'error',
    //                 open: true,
    //             });
    //         });
    // };

    return (
        <>
            <h4>All Stores</h4>

            <div className="w-100 mb-1 flex gap-3">
                <AutoComplete
                    key="id"
                    placeholder="Sellers"
                    options={customers as []}
                    getOptionLabel={(option: any) =>
                        option?.lastName && option?.firstName ? `${option.firstName} ${option.lastName}` : option?.email
                    }
                    // eslint-disable-next-line no-underscore-dangle
                    onChange={(option) => setCustomerId(option._id as string)}
                />
                <TextInput
                    icon={AiOutlineSearch}
                    id="storeName"
                    placeholder="Search by store name"
                    required
                    type="text"
                    name="storeName"
                    onChange={handleChange}
                />
            </div>

            <DataTable
                rows={storesList}
                columns={columns}
                icons={statusDisplay}
                setRows={setStoresList}
                pageState={{
                    isLoading,
                    total: meta.totalDocs,
                    pageSize: pagination.limit,
                    page: pagination.page,
                    count: meta.totalPages,
                }}
                setPageState={handlePaginationChange}
            />
            <Modal open={openModal} setOpen={setOpenModal} body={<SingleStore storeData={storeData as IStore} />} />
        </>
    );
};

export default StoresListing;
