import React, { useEffect, useRef, useState } from 'react';
import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Box } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { calendarElement, calendarWrap, inputBox } from './dateRangePicker.styles';
import './DatePicker.css';

interface DateRangeCompProps {
    handleWalletChange: Function;
}
const DateRangeComp = (props: DateRangeCompProps) => {
    // date state
    const { handleWalletChange } = props;
    const [range, setRange] = useState<any>([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 0),
            key: 'selection',
        },
    ]);
    // open close
    const [openPop, setOpenPop] = useState(false);

    // get the target element to toggle
    const refOne = useRef<HTMLDivElement>(null);

    // hide dropdown on ESC press
    const hideOnEscape = (e: any) => {
        if (e.key === 'Escape') {
            setOpenPop(false);
        }
    };

    const hideOnClickOutside = (e: any) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            setOpenPop(false);
        }
    };

    useEffect(() => {
        // event listeners
        document.addEventListener('keydown', hideOnEscape, true);
        document.addEventListener('click', hideOnClickOutside, true);
    }, []);

    return (
        <Box sx={calendarWrap}>
            <DateRangeIcon sx={inputBox} onClick={() => setOpenPop((open) => !open)} fontSize="large" />
            <Box ref={refOne}>
                {openPop && (
                    <Box sx={calendarElement}>
                        <DateRange
                            onChange={(item) => {
                                setRange([item.selection]);
                                handleWalletChange({ dateRange: item.selection });
                            }}
                            editableDateInputs
                            moveRangeOnFirstSelection={false}
                            ranges={range}
                            months={2}
                            direction="vertical"
                            showDateDisplay={false}
                            className="datePicker"
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default DateRangeComp;
