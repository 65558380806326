/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */

import { Select, TextInput } from 'flowbite-react';
import { ErrorMessage, FieldArray, useField, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';

import { IoMdRemoveCircle } from 'react-icons/io';

import { countries } from 'utils/countryList';

import TextError from 'components/customError/customErrorText';
import { IResellerQuotation } from 'pages/reseller/reseller.types';
import { IAddResellerProduct, QuotationFormProps } from './types';

const ProductCost = (props: any) => {
    const { name, index } = props;
    const {
        values: { resellerQuotation },
        setFieldValue,
    } = useFormikContext<IAddResellerProduct>();
    const [field] = useField(name);

    useEffect(() => {
        // set the value of textC, based on textA and textB
        let unitShippingFee = 0;
        if (Number(resellerQuotation[index].totalShippingFee) && Number(resellerQuotation[index].quantity)) {
            unitShippingFee =
                Number(resellerQuotation[index].totalShippingFee) / Number(resellerQuotation[index].quantity);
        }
        const productCost = unitShippingFee + Number(resellerQuotation[index].unitPurchasePrice);
        setFieldValue(name, productCost.toFixed(2));
    }, [
        resellerQuotation[index].quantity,
        resellerQuotation[index].totalShippingFee,
        resellerQuotation[index].unitPurchasePrice,
    ]);

    return (
        <div key="productCost">
            <TextInput
                disabled
                {...field}
                name={name}
                helperText={
                    <span>
                        <TextError name={name} component="div" />
                    </span>
                }
                type="text"
                sizing="sm"
                theme={{
                    base: 'flex',
                    addon: 'inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-200 px-3 text-sm text-gray-900 dark:border-gray-600 dark:bg-gray-600 dark:text-gray-400',
                    field: {
                        base: 'relative w-full',
                        icon: {
                            base: 'pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3',
                            svg: 'h-5 w-5 text-gray-500 dark:text-gray-400',
                        },
                        rightIcon: {
                            base: 'pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3',
                            svg: 'h-5 w-5 text-gray-500 dark:text-gray-400',
                        },
                        input: {
                            base: 'block w-full border disabled:cursor-not-allowed disabled:opacity-80 disabled:decoration-solid',
                        },
                    },
                }}
            />
        </div>
    );
};
const UnitProfit = (props: any) => {
    const { name, index } = props;
    const {
        values: { resellerQuotation },
        setFieldValue,
    } = useFormikContext<IAddResellerProduct>();
    const [field] = useField(name);

    useEffect(() => {
        // set the value of textC, based on textA and textB
        const unitProfit = Number(resellerQuotation[index].sellingPrice) - Number(resellerQuotation[index].productCost);
        setFieldValue(name, unitProfit.toFixed(2));
    }, [resellerQuotation[index].sellingPrice, resellerQuotation[index].productCost]);

    return (
        <div key="productCost">
            <TextInput
                disabled
                {...field}
                name={name}
                helperText={
                    <span>
                        <TextError name={name} component="div" />
                    </span>
                }
                type="text"
                sizing="sm"
                theme={{
                    base: 'flex',
                    addon: 'inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-200 px-3 text-sm text-gray-900 dark:border-gray-600 dark:bg-gray-600 dark:text-gray-400',
                    field: {
                        base: 'relative w-full',
                        icon: {
                            base: 'pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3',
                            svg: 'h-5 w-5 text-gray-500 dark:text-gray-400',
                        },
                        rightIcon: {
                            base: 'pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3',
                            svg: 'h-5 w-5 text-gray-500 dark:text-gray-400',
                        },
                        input: {
                            base: 'block w-full border disabled:cursor-not-allowed disabled:opacity-80 disabled:decoration-solid',
                        },
                    },
                }}
            />
        </div>
    );
};
const QuotationForm: React.FC<QuotationFormProps> = (props) => {
    const { formik, edit, isDraft } = props;
    const checkCountrySelected = (country: string, quotations: IResellerQuotation[]): boolean => {
        let isContained = false;
        // eslint-disable-next-line no-unreachable-loop
        for (const quotation of quotations) {
            if (quotation.country === country) {
                isContained = true;

                break;
            }
        }
        return isContained;
    };
    return (
        <div className="bg-white  rounded-lg shadow p-4 dark:bg-gray-800 mb-2">
            <div className="flex border border-gray-900 rounded-lg content-center  p-2 mb-2 gap-2">
                <div className="grid grid-cols-9 gap-2 grow">
                    <div>
                        Country <span className="text-red-600 "> *</span>
                    </div>
                    <div>
                        Quantity <span className="text-red-600 "> *</span>
                    </div>
                    <div>
                        Unit purchase price ($)<span className="text-red-600 "> *</span>
                    </div>
                    <div>
                        Total shipping fee ($)<span className="text-red-600 "> *</span>
                    </div>
                    <div>
                        Product cost ($)<span className="text-red-600 "> *</span>
                    </div>
                    <div>
                        Unit selling price ($)<span className="text-red-600 "> *</span>
                    </div>
                    <div>
                        Unit Profit ($)<span className="text-red-600 "> *</span>
                    </div>
                    <div>Recommended price</div>
                    <div>Tracking code</div>
                </div>
                <div className="w-8" />
            </div>
            <FieldArray
                name="resellerQuotation"
                render={(arrayHelpers) => (
                    <div>
                        {formik.values.resellerQuotation.map((quotation, index) => {
                            return (
                                <div className="flex border border-gray-900 rounded-lg content-center  p-2 mb-2 gap-2">
                                    <div className="grid grid-cols-9 gap-2 grow">
                                        <div key="country">
                                            <Select
                                                id="countriesList"
                                                {...formik.getFieldProps(`resellerQuotation[${index}].country`)}
                                                name={`resellerQuotation[${index}].country`}
                                                helperText={<TextError name={`resellerQuotation[${index}].country`} />}
                                                sizing="sm"
                                                disabled={
                                                    // (edit && !formik.values.resellerQuotation[index].editable) ||
                                                    edit && !isDraft
                                                }
                                            >
                                                <option key="null val" value="">
                                                    Choose country
                                                </option>
                                                {countries.map((country) => {
                                                    return !checkCountrySelected(
                                                        country.label,
                                                        formik.values.resellerQuotation,
                                                    ) ? (
                                                        <option key={country.code} value={country.label}>
                                                            {country.label}
                                                        </option>
                                                    ) : (
                                                        <option key={country.code} value={country.label} disabled>
                                                            {country.label}
                                                        </option>
                                                    );
                                                })}
                                            </Select>
                                        </div>
                                        <div key="quantity">
                                            <TextInput
                                                {...formik.getFieldProps(`resellerQuotation.${index}.quantity`)}
                                                name={`resellerQuotation[${index}].quantity`}
                                                type="text"
                                                helperText={
                                                    <span>
                                                        <TextError
                                                            name={`resellerQuotation[${index}].quantity`}
                                                            component="div"
                                                        />
                                                    </span>
                                                }
                                                sizing="sm"
                                                disabled={edit && !isDraft}
                                            />
                                        </div>
                                        <div key="unitPurchasePrice">
                                            <TextInput
                                                {...formik.getFieldProps(
                                                    `resellerQuotation.${index}.unitPurchasePrice`,
                                                )}
                                                name={`resellerQuotation[${index}].unitPurchasePrice`}
                                                type="text"
                                                helperText={
                                                    <span>
                                                        <TextError
                                                            name={`resellerQuotation[${index}].unitPurchasePrice`}
                                                            component="div"
                                                        />
                                                    </span>
                                                }
                                                sizing="sm"
                                            />
                                        </div>
                                        <div key="totalShippingFee">
                                            <TextInput
                                                {...formik.getFieldProps(`resellerQuotation.${index}.totalShippingFee`)}
                                                name={`resellerQuotation[${index}].totalShippingFee`}
                                                type="text"
                                                helperText={
                                                    <span>
                                                        <TextError
                                                            name={`resellerQuotation[${index}].totalShippingFee`}
                                                            component="div"
                                                        />
                                                    </span>
                                                }
                                                sizing="sm"
                                            />
                                        </div>
                                        <ProductCost index={index} name={`resellerQuotation[${index}].productCost`} />
                                        <div key="sellingPrice" className="text-center">
                                            <TextInput
                                                {...formik.getFieldProps(`resellerQuotation.${index}.sellingPrice`)}
                                                name={`resellerQuotation[${index}].sellingPrice`}
                                                helperText={
                                                    <span>
                                                        <TextError
                                                            name={`resellerQuotation[${index}].sellingPrice`}
                                                            component="div"
                                                        />
                                                    </span>
                                                }
                                                type="text"
                                                sizing="sm"
                                            />
                                        </div>
                                        <UnitProfit index={index} name={`resellerQuotation[${index}].unitProfit`} />

                                        <div key="recommendedPrice">
                                            <TextInput
                                                {...formik.getFieldProps(`resellerQuotation.${index}.recommendedPrice`)}
                                                name={`resellerQuotation[${index}].recommendedPrice`}
                                                helperText={
                                                    <span>
                                                        <TextError
                                                            name={`resellerQuotation[${index}].recommendedPrice`}
                                                            component="div"
                                                        />
                                                    </span>
                                                }
                                                type="text"
                                                sizing="sm"
                                            />
                                        </div>
                                        {/* tracking code is useful if the stock related to the warehouse is alredy shipped and delivered */}
                                        <div key="trackingCode">
                                            <TextInput
                                                {...formik.getFieldProps(`resellerQuotation.${index}.trackingCode`)}
                                                name={`resellerQuotation[${index}].trackingCode`}
                                                type="text"
                                                sizing="sm"
                                                disabled={edit && !isDraft}
                                            />
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        onClick={() => arrayHelpers.remove(index)}
                                        disabled={edit && !isDraft}
                                    >
                                        <IoMdRemoveCircle className="h-8 w-8" color="red" />
                                    </button>
                                </div>
                            );
                        })}
                        <button
                            onClick={() =>
                                arrayHelpers.push({
                                    country: '',
                                    packaging: '',
                                    quantity: 0,
                                    totalShippingFee: 0,
                                    type: '',
                                    unitPurchasePrice: 0,
                                    sellingPrice: 0,
                                    trackingCode: undefined,
                                    productCost: 0,
                                    unitProfit: 0,
                                    recommendedPrice: undefined,
                                    accepted: true,
                                    isHidden: false,
                                    // to check wich field can be updated
                                    editable: true,
                                })
                            }
                            type="button"
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                            <FaPlus className="w-4 h-4" />
                        </button>
                    </div>
                )}
            />
            <>
                <ErrorMessage
                    name="resellerQuotation"
                    render={(msg) => (
                        <>
                            {typeof msg === 'string' ? (
                                <span>
                                    <span className="text-xs text-red-600 font-semibold">*{msg} </span>
                                </span>
                            ) : null}
                        </>
                    )}
                />
            </>
        </div>
    );
};
export default QuotationForm;
