/* eslint-disable no-underscore-dangle */
import GeneralDrawer from 'components/generalDrawer/generalDrawer';
import { Modal } from 'flowbite-react';
import { StatusTimeline } from 'pages/orders/orderStatusTimeline';
import { IStatusTimeline } from 'pages/orders/orders.types';
import React, { useState } from 'react';
import { FaHistory } from 'react-icons/fa';
import { IoCloseSharp, IoEyeOutline } from 'react-icons/io5';
import { IOrder, getHistory } from 'services/admin/order';
import { getOrderTraces } from 'services/admin/orderTrace';
import OrderDetails from './orderDetails';

const CAOrderActions: React.FC<{
    orderData: IOrder;
    setRows: React.Dispatch<React.SetStateAction<IOrder[]>>;
    currentPage: number;
    currentFilter?: Record<string, any>;
}> = ({ orderData, setRows, currentPage, currentFilter }) => {
    const [history, setHistory] = useState<IStatusTimeline>({ history: [], deliveryAttempts: [] }); // TODO: Replace `any` with its appropriate type ✅
    const [openHistoryModal, setOpenHistoryModal] = useState(false);
    const [openDetailsModal, setOpenDetailsModal] = useState(false);

    const showHistory = () => {
        getHistory(orderData._id).then((data) => {
            getOrderTraces({ order: orderData._id, logistioStatus: 10 }).then((dataTraces) => {
                setHistory({ history: data?.data, deliveryAttempts: dataTraces?.data.docs });
                setOpenHistoryModal(true);
            });
        });
    };

    const showDetails = () => {
        setOpenDetailsModal(true);
    };

    const navigationList = [
        {
            label: 'Details',
            action: showDetails,
            IconAction: IoEyeOutline,
        },
        {
            label: 'History',
            action: showHistory,
            IconAction: FaHistory,
        },
    ];

    return (
        <>
            <Modal dismissible show={openHistoryModal} popup onClose={() => setOpenHistoryModal(false)}>
                <Modal.Header>Order Status History </Modal.Header>
                <Modal.Body className="!p-0 overflow-y-auto h-[34rem]">
                    {orderData && <StatusTimeline setOpen={setOpenHistoryModal} history={history} order={orderData} />}
                </Modal.Body>
            </Modal>
            {/* <Modal size="7xl" dismissible show={openDetailsModal} popup onClose={() => setOpenDetailsModal(false)}>
                <Modal.Header>Order Configuration</Modal.Header>
                <Modal.Body className="!p-2 overflow-y-auto max-h-[70vh]">
                    {orderData && (
                        <OrderDetails
                            setOpenDetailsModal={setOpenDetailsModal}
                            data={orderData}
                            setRows={setRows}
                            currentPage={currentPage}
                            currentFilter={currentFilter}
                        />
                    )}
                </Modal.Body>
            </Modal> */}
            <GeneralDrawer
                anchor="right"
                open={openDetailsModal}
                onClose={setOpenDetailsModal}
                PaperProps={{
                    sx: { width: '75%' },
                }}
            >
                <div className="p-2">
                    <div className="flex justify-between items-center">
                        <div>
                            <p className="text-lg font-medium">Order Configuration</p>
                        </div>
                        <button
                            type="button"
                            onClick={() => setOpenDetailsModal(false)}
                            className="p-1 hover:dark:bg-gray-700 hover:bg-gray-100 rounded-md"
                        >
                            <IoCloseSharp className="w-5 h-5" />
                        </button>
                    </div>
                    {orderData && (
                        <OrderDetails
                            data={orderData}
                            setRows={setRows}
                            currentPage={currentPage}
                            currentFilter={currentFilter}
                            setOpenDetailsModal={setOpenDetailsModal}
                        />
                    )}
                </div>
            </GeneralDrawer>
            <div className="flex gap-2">
                {navigationList
                    .filter((elt) => {
                        if (![1, 21, 13, 2, 3, 41, 42, 43].includes(orderData?.status)) {
                            return elt.label !== 'Details';
                        }
                        return elt;
                    })
                    .map(({ IconAction, ...navigation }) => {
                        return (
                            // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
                            <div onClick={navigation.action} key={navigation.label}>
                                <IconAction size={16} />
                            </div>
                        );
                    })}
            </div>
        </>
    );
};

export default CAOrderActions;
