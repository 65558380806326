import React from 'react';

import { Outlet } from 'react-router-dom';

import Chat from 'pages/chat/Chat';

const OrderDetails: React.FC = () => {
    return (
        <div>
            <div>
                <Chat />
            </div>
            <Outlet />
        </div>
    );
};

export default OrderDetails;
