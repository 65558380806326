/* eslint-disable react/button-has-type */
/* eslint-disable no-underscore-dangle */
import Widget from 'components/dashboard/card';
import React from 'react';

import { Select } from 'flowbite-react';
import { countries } from 'utils/countryList';
import { IData } from '../dashboard.types';

import { selectTheme } from '../dashboard.styles';
import { activityGeneralInfo, activitycards } from './elements';

// eslint-disable-next-line react/no-unused-prop-types
const CCADashboardLeads: React.FC<{
    filter: any;
    setFilter: any;
    data: any;
    loading: boolean;
}> = ({ filter, setFilter, data = {}, loading }) => {
    return (
        <div>
            <div className="flex justify-end items-center">
                <div id="select" className="w-52 mb-2">
                    <Select
                        theme={selectTheme}
                        color="general"
                        id="countryFilter"
                        required
                        onChange={(e) => {
                            const temp = { ...filter };
                            if (e.target.value !== '') temp.country = e.target.value;
                            else delete temp.country;
                            setFilter(temp);
                        }}
                        value={filter.country || ''}
                    >
                        <option value="">All Countries</option>
                        {countries.map((el) => {
                            return (
                                <option value={el.label} key={el.phone}>
                                    {el.label}
                                </option>
                            );
                        })}
                    </Select>
                </div>
            </div>
            <div>
                <div className="grid gap-4 tablet:grid-cols-2 laptop:grid-cols-3 desktop:grid-cols-3 py-2">
                    {activityGeneralInfo.map(({ key, Icon, color, bgColor, title }: any) => (
                        <Widget
                            key={key}
                            title={title}
                            loading={loading}
                            inputValue={data && data[key as keyof IData]?.total}
                            statPourcentage={data && data[key as keyof IData]?.percent}
                            icon={
                                <div className="w-1/3 h-10  rounded-full flex justify-center items-center ">
                                    <Icon className="w-7 h-7" color={color} />
                                </div>
                            }
                            bgcolor={bgColor || color}
                        />
                    ))}
                </div>
            </div>
            <div className="text-lg">Lead status</div>
            <div>
                <div className="grid gap-4 mb-8 tablet:grid-cols-2 laptop:grid-cols-3 desktop:grid-cols-4 py-2">
                    {activitycards.map(({ key, Icon, color, bgColor, title }: any) => (
                        <Widget
                            key={key}
                            loading={loading}
                            title={title}
                            inputValue={data && data[key as keyof IData]?.total}
                            statPourcentage={data && data[key as keyof IData]?.percent}
                            icon={
                                <div className="w-10 h-10  rounded-full flex justify-center items-center ">
                                    <Icon className="w-7 h-7" color={color} />
                                </div>
                            }
                            bgcolor={bgColor || color}
                            footer
                            percText="out of called leads"
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CCADashboardLeads;
