import React from 'react';
import { IStausInterface, TableColDef } from 'components/tables/tables.types';
import { Badge } from 'flowbite-react';

export const columns: TableColDef[] = [
    {
        field: 'profileImage',
        headerName: 'Picture',
        fieldType: 'profileImage',
    },
    {
        field: 'firstName',
        headerName: 'First Name',
    },
    {
        field: 'lastName',
        headerName: 'Last Name',
    },

    {
        field: 'email',
        headerName: 'E-Mail',
    },

    {
        field: 'role',
        headerName: 'User Role',
    },
    {
        field: 'phone',
        headerName: 'Mobile Number',
    },
    {
        field: 'status',
        headerName: 'Status',
        fieldType: 'status',
    },
    {
        field: 'Action',
        actionType: 'Sourcing Agent',
        headerName: 'Options',
    },
];
export const statusDisplay: { [keys: string]: IStausInterface } = {
    1: {
        statusIcon: (
            <Badge color="success" size="sm">
                Active
            </Badge>
        ),
        statusLabel: 'Active',
    },
    2: {
        statusIcon: (
            <Badge color="failure" size="sm">
                Disabled
            </Badge>
        ),
        statusLabel: 'Disabled',
    },
};
