/* eslint-disable no-underscore-dangle */
import { Dropdown } from 'flowbite-react';
import { IPurchasesActions } from 'pages/purchases/purchases.types';
import React from 'react';
import toast from 'react-hot-toast';
import { HiDotsVertical, HiPencil } from 'react-icons/hi';
import { getPurchasesList, updatePurchases } from 'services/admin/purchases';

const PurchasesActions = (props: IPurchasesActions) => {
    const { purchases, setRows, currentPage, filter } = props;
    const updatePurchasesStatus = (status: Number) => {
        updatePurchases(purchases._id, { status })
            .then(() => {
                toast.success('Updated successfuly');
            })
            .then(() => {
                getPurchasesList({ page: currentPage, ...filter }).then((result) =>
                    setRows(result?.data?.response?.docs),
                );
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message);
            });
    };
    const optionsList = [
        {
            label: 'Set to Purchased',
            action: () => updatePurchasesStatus(2),
            iconAcion: HiPencil,
        },
        {
            label: 'Set to Canceled',
            action: () => updatePurchasesStatus(3),
            iconAcion: HiPencil,
        },
    ];
    return (
        <>
            <Dropdown inline arrowIcon={false} label={<HiDotsVertical />}>
                {optionsList.map((action) => {
                    return (
                        <Dropdown.Item icon={action.iconAcion} onClick={action.action} key={action.label}>
                            {`${action.label}`}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown>
        </>
    );
};
export default PurchasesActions;
