/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-fragments */
import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import toast from 'react-hot-toast';
import { addSchema } from 'utils/validations/addMemberValidation';
import CustomError from 'components/customError/CustomError';
import PhoneInput from 'react-phone-number-input';
import '../../../utils/helpers/phoneInput.css';
import { addCallCenterAdmin, getAllCallCenterAdmins } from 'services/admin/callCenterAdmin';
import CustomBtn from 'components/customButton/customBtn';
import { Label, TextInput } from 'flowbite-react';
import { HiMail } from 'react-icons/hi';
import MultiSelect from 'components/multiSelect/autoComplete';
import { getAllCallCenters } from 'services/admin/callCenter';
import { ICallCenter } from 'pages/callCenterList/callCenter.types';
import { NotifProps } from '../callCenterAdmin.types';

const AddCallCenterAdmin: React.FC<NotifProps> = ({ setOpen, setCallCenterAdmins }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [callCenter, setCallCenters] = useState<Array<ICallCenter>>([]);
    const close = () => {
        setOpen(false);
    };

    const newCallCenterAdmin = (values: any) => {
        setIsLoading(true);
        addCallCenterAdmin(values)
            .then((res) => {
                toast.success(res.data.message);
                close();
            })
            .then(() => getAllCallCenterAdmins({}).then((res) => setCallCenterAdmins(res.data.docs)))
            .catch((err) => {
                setIsLoading(false);
                toast.error(err?.response?.data?.errors?.message);
            });
    };

    useEffect(() => {
        getAllCallCenters({ limit: 1000, status: 1 }).then((res) => {
            setCallCenters(res.data.docs);
        });
    }, []);

    return (
        <>
            <Formik
                initialValues={{
                    email: '',
                    firstName: '',
                    callCenter: [],
                }}
                onSubmit={(values, onSubmitProps) => {
                    newCallCenterAdmin(values);
                    onSubmitProps.setSubmitting(false);
                    onSubmitProps.resetForm();
                }}
                validationSchema={addSchema}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit} placeholder="">
                        <div className="p-6 flex flex-col gap-2 ">
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="username4"
                                        className="dark:text-white"
                                        color={formik.errors.email ? 'failure' : ''}
                                        value="Email"
                                    />
                                </div>
                                <TextInput
                                    {...formik.getFieldProps('email')}
                                    name="email"
                                    placeholder="jhondoe@email.com"
                                    color={formik.errors.email ? 'failure' : 'gray'}
                                    icon={HiMail}
                                    type="email"
                                    helperText={
                                        <React.Fragment>
                                            <CustomError name="email" component="div" />
                                        </React.Fragment>
                                    }
                                />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="username4"
                                        className="dark:text-white"
                                        color={formik.errors.firstName ? 'failure' : ''}
                                        value="First name"
                                    />
                                </div>
                                <TextInput
                                    {...formik.getFieldProps('firstName')}
                                    name="firstName"
                                    color={formik.errors.firstName ? 'failure' : 'gray'}
                                    type="text"
                                    helperText={
                                        <React.Fragment>
                                            <CustomError name="firstName" component="div" />
                                        </React.Fragment>
                                    }
                                />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="username4"
                                        className="dark:text-white"
                                        // color={formik.errors.lastName ? 'failure' : ''}
                                        value="Last Name"
                                    />
                                </div>
                                <TextInput
                                    {...formik.getFieldProps('lastName')}
                                    name="lastName"
                                    // color={formik.errors.lastName ? 'failure' : 'gray'}
                                    type="text"
                                    helperText={
                                        <React.Fragment>
                                            <CustomError name="lastName" component="div" />
                                        </React.Fragment>
                                    }
                                />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="username4"
                                        className="dark:text-white"
                                        // color={formik.errors.phone ? 'failure' : ''}
                                        value="Phone Number"
                                    />
                                </div>

                                <PhoneInput
                                    id="phoneNumber"
                                    name="phone"
                                    // value={String(formik.values.phone)}
                                    onChange={(value) => formik.setFieldValue('phone', value)}
                                    onBlur={formik.handleBlur('phone')}
                                    placeholder="Enter phone number"
                                    className="mb-1"
                                />
                                <CustomError name="phone" component="div" />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="username4"
                                        className="dark:text-white"
                                        value="Assign to a Call Center"
                                    />
                                </div>

                                <MultiSelect
                                    key="id"
                                    {...formik.getFieldProps('callCenter')}
                                    name="callCenter"
                                    placeholder="Assign to a Call Center"
                                    options={callCenter}
                                    getOptionLabel={(option: any) => `${option.name}`}
                                    onChange={(newValue: any) => {
                                        formik.setFieldValue(
                                            'callCenter',
                                            newValue.map((item: { _id: string }) => item._id),
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex gap-2 justify-between py-2 px-2 items-center rounded-b-lg  bg-background-secondary">
                            <CustomBtn
                                variant="failure"
                                outline
                                onClick={() => {
                                    formik.resetForm();
                                    close();
                                }}
                            >
                                Cancel
                            </CustomBtn>
                            <CustomBtn variant="primary" type="submit" isProcessing={isLoading} disabled={isLoading}>
                                Save
                            </CustomBtn>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddCallCenterAdmin;
