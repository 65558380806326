/* eslint-disable no-underscore-dangle */
import { IStockShipment } from 'services/admin/stockShipment';
import { IBoxes, IErrors } from './pickUpDrawer';

export const handleErrors = (
    boxes: IBoxes[],
    shipmentsData: IStockShipment[],
    transportation: string,
    platform: string,
    step: number,
) => {
    const errorsSet = new Set<string>();
    const newErrors: IErrors[] = [];

    boxes.forEach((box: IBoxes, index: number) => {
        if (Number.isNaN(Number(box.weight)) || !box.weight) {
            const weightError = `Weight must be a valid number`;
            if (!errorsSet.has(weightError)) {
                errorsSet.add(weightError);
                newErrors.push({ element: 'weight', message: weightError, box: index });
            }
        }

        if (Number.isNaN(Number(box.height)) || !box.height) {
            const heightError = `Height must be a valid number`;
            if (!errorsSet.has(heightError)) {
                errorsSet.add(heightError);
                newErrors.push({ element: 'height', message: heightError, box: index });
            }
        }

        if (Number.isNaN(Number(box.width)) || !box.width) {
            const widthError = `Width must be a valid number`;
            if (!errorsSet.has(widthError)) {
                errorsSet.add(widthError);
                newErrors.push({ element: 'width', message: widthError, box: index });
            }
        }

        if (Number.isNaN(Number(box.length)) || !box.length) {
            const lengthError = `Length must be a valid number`;
            if (!errorsSet.has(lengthError)) {
                errorsSet.add(lengthError);
                newErrors.push({ element: 'length', message: lengthError, box: index });
            }
        }

        if (!box.products?.length) {
            const productsError = `Please select box ${index + 1} products`;
            if (!errorsSet.has(productsError)) {
                errorsSet.add(productsError);
                newErrors.push({ element: 'products', message: productsError, box: index });
            }
        }
    });

    const productTotals: { [key: string]: number } = {};

    boxes?.forEach((box) => {
        box?.products?.forEach((product) => {
            if (!productTotals[product?._id]) {
                productTotals[product._id] = 0;
            }
            productTotals[product?._id] += product?.qty;
        });
    });

    shipmentsData?.forEach((shipment) => {
        const initialQuantity = shipment?.quantity;
        const totalQuantity = productTotals?.[shipment?.product._id] || 0;

        if (!productTotals?.[shipment?.product._id]) {
            const productsError = `Please select a product`;
            if (!errorsSet.has(productsError)) {
                errorsSet.add(productsError);
                newErrors.push({ element: 'products', message: productsError });
            }
        }
        if (totalQuantity && (totalQuantity > initialQuantity || totalQuantity < initialQuantity)) {
            const exceedsError = `The total quantity for ${
                shipment?.product?.itemKind === 'variant'
                    ? `${shipment?.parentProduct?.name} ${shipment?.product?.name}`
                    : shipment.product.name
            } must be equal to ${initialQuantity}.`;
            if (!errorsSet.has(exceedsError)) {
                errorsSet.add(exceedsError);
                newErrors.push({
                    element: 'products',
                    message: exceedsError,
                });
            }
        }
    });

    if (shipmentsData?.length !== Object.keys(productTotals)?.length) {
        const productsError = `All the products must be selected`;
        if (!errorsSet.has(productsError)) {
            errorsSet.add(productsError);
            newErrors.push({ element: 'products', message: productsError });
        }
    }

    if (!transportation && step === 1) {
        const transportationError = `Please select transportation`;
        if (!errorsSet.has(transportationError)) {
            errorsSet.add(transportationError);
            newErrors.push({ element: 'transportation', message: transportationError });
        }
    }

    if (!platform && step === 1) {
        const platformError = `Please select platform`;
        if (!errorsSet.has(platformError)) {
            errorsSet.add(platformError);
            newErrors.push({ element: 'platform', message: platformError });
        }
    }

    return newErrors;
};
