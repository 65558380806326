/* eslint-disable no-underscore-dangle */
import AutoComplete from 'components/autocomplete/autoComplete';
import { ICustomer } from 'pages/customers/customer.types';
import React, { useEffect, useState } from 'react';
import { getCustomers } from 'services/admin/customer';
import { getInvoiceList } from 'services/admin/invoice';
import UseDocumentTitle from 'utils/dynamicTitle/dynamicTitle';

import Datepicker from 'react-tailwindcss-datepicker';
import { IStore, getStores } from 'services/admin/store';
// import useDebouncedValue from 'utils/hooks/useDebouncedValue';
import moment from 'moment-timezone';
import toast from 'react-hot-toast';
import DataTable from '../../../components/tables/tables';
import { Ifilter, Iinvoice, Ipagination } from '../invoice.types';
import { columns, statusDisplay } from './invoiceListElements';

const prepaidInvoiceList = () => {
    UseDocumentTitle('Prepaid Invoices');
    const [isLoading, setIsLoading] = useState(true);
    const [invoices, setInvoices] = useState<Array<Iinvoice>>([]);
    const [customers, setCustomers] = useState<ICustomer[]>([]);
    const [stores, setStores] = useState<IStore[]>([]);
    const [store] = useState('');
    const [customer] = useState('');
    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 20,
    });
    const [Rangevalue, setRangeValue] = useState(null);
    const [filter, setFilter] = useState<Ifilter>({ invoiceType: ['PrepaidNoCC'] });

    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });
    const [, setInvoicesSelected] = useState<any[]>([]);
    const [resetTable] = useState<Date>(new Date());
    interface Imeta {
        totalDocs: number;
        limit: number;
        totalPages: number;
        page: number;
        pagingCounter: number;
        hasPrevPage: boolean;
        hasNextPage: boolean;
        prevPage: number | null;
        nextPage: number | null;
    }

    const handlePaginationChange = (value: { [key: string]: any }) => {
        setPagination((prevPagination) => {
            const data = { ...prevPagination, ...value };
            if (value.limit) {
                data.page = 1;
            }
            return data;
        });
        setMeta((prev) => ({ ...prev, page: -1 }));
    };

    const handleRangeFilterChange = (newValue: any) => {
        let newRange: typeof newValue | undefined;
        if (newValue && newValue.startDate && newValue.endDate) {
            const startD = moment.tz(newValue.startDate, 'Asia/Shanghai');
            const endD = moment.tz(newValue.endDate, 'Asia/Shanghai');
            endD.set('hour', 23);
            endD.set('minute', 59);
            endD.set('second', 59);
            newRange = {
                startDate: startD.format(),
                endDate: endD.format(),
            };
        }
        setRangeValue(newValue || null);
        setFilter({ ...filter, ...{ dateRange: newRange } });
        setMeta({ ...meta, page: -1 });
    };
    useEffect(() => {
        if (customer) getCustomers({ limit: 1000, email: customer }).then((res) => setCustomers(res.data.docs));
        else getCustomers({ limit: 1000 }).then((res) => setCustomers(res.data.docs));
        if (store) getStores({ limit: 1000, storeName: store }).then((res) => setStores(res.data.docs));
        else getStores({ limit: 1000 }).then((res) => setStores(res.data.docs));
    }, [customer, store]);

    useEffect(() => {
        const feedbackToast = toast.loading('Loading data...');
        setIsLoading(true);
        // const queryOptions = { ...debouncedPagination, ...debouncedFilter };

        const queryOptions = { ...pagination, ...filter };

        getInvoiceList(queryOptions)
            .then((result) => {
                toast.dismiss(feedbackToast);
                setInvoices(result.data.docs);
                setIsLoading(false);
                setMeta({
                    hasNextPage: result.data.hasNextPage,
                    hasPrevPage: result.data.hasPrevPage,
                    limit: result.data.limit,
                    nextPage: result.data.nextPage,
                    page: result.data.page,
                    pagingCounter: result.data.pagingCounter,
                    prevPage: result.data.prevPage,
                    totalDocs: result.data.totalDocs,
                    totalPages: result.data.totalPages,
                });
            })
            .catch((err) => {
                setIsLoading(false);
                toast.error((err as any)?.response?.data?.errors?.message || 'Unable to load data.', {
                    id: feedbackToast,
                });
            });
    }, [filter, pagination]);

    return (
        <>
            <h3>Prepaid Invoices</h3>
            <section className="dark:bg-gray-900 flex items-center my-2">
                <div className=" relative w-full">
                    <div className="relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
                        <div className="flex flex-col items-center p-2 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
                            <div className="w-48">
                                <AutoComplete
                                    key="id"
                                    placeholder="Sellers"
                                    options={customers as []}
                                    getOptionLabel={(option: any) =>
                                        option?.lastName && option?.firstName
                                            ? `${option.firstName} ${option.lastName}`
                                            : option?.email
                                    }
                                    // eslint-disable-next-line no-underscore-dangle
                                    onChange={(option) => {
                                        setFilter({ ...filter, ...{ customer: option._id as string } });
                                        setMeta({ ...meta, page: -1 });
                                    }}
                                />
                            </div>
                            <div className="w-48">
                                <AutoComplete
                                    key="id"
                                    placeholder="Store"
                                    options={stores as []}
                                    getOptionLabel={(option: any) => `${option.storeName}`}
                                    // eslint-disable-next-line no-underscore-dangle
                                    onChange={(option) => {
                                        setFilter({ ...filter, ...{ store: option._id as string } });
                                        setMeta({ ...meta, page: -1 });
                                    }}
                                />
                            </div>
                            <div className="mb-2 block">
                                <Datepicker
                                    inputId="deliveryDate"
                                    value={Rangevalue}
                                    containerClassName="relative z-100"
                                    // inputClassName="absolute"
                                    // toggleClassName="absolute
                                    useRange
                                    onChange={handleRangeFilterChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <DataTable
                rows={invoices}
                columns={columns}
                icons={statusDisplay}
                setRows={setInvoices}
                pageState={{
                    isLoading,
                    total: meta.totalDocs,
                    pageSize: pagination.limit,
                    page: pagination.page,
                    count: meta.totalPages,
                }}
                setPageState={handlePaginationChange}
                selectable
                setRowsSelected={setInvoicesSelected}
                filterState={filter}
                resetTable={resetTable}
            />
        </>
    );
};

export default prepaidInvoiceList;
