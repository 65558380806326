import { HiCheckCircle, HiClock, HiMinusCircle } from 'react-icons/hi';

export const StatusContainer = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 1,
    border: 'solid 1px #ececef',
    padding: '5px',
};

const renderColorProduct = (param: number) => {
    switch (param) {
        case 6:
            return '#93c66e';
        case 1:
            return '#ff9a5c';
        case 2:
            return '#5c558a';
        case 3:
            return '#bc63e3';
        case 5:
            return '#ff7777';
        case 42:
            return '#ff7777';
        case 41:
            return '#FFFF00';
        default:
            return '#578aff';
    }
};

export const StatusBall = (label: number) => {
    return {
        width: '2px',
        padding: '4px',
        background: renderColorProduct(label),
        borderRadius: '50%',
        marginRight: '4px',
    };
};

const renderColorAccount = (param: number) => {
    switch (param) {
        case 2:
            return '#ff7777';
        default:
            return '#93c66e';
    }
};

export const AccountStatus = (label: number) => {
    return {
        width: '2px',
        padding: '4px',
        background: renderColorAccount(label),
        borderRadius: '50%',
        marginRight: '4px',
    };
};

const renderColorCustomerAccount = (param: number) => {
    switch (param) {
        case 2:
            return '#93c66e';
        case 3:
            return '#ff7777';

        default:
            return '#578aff';
    }
};

export const CustomerAccountStatus = (label: number) => {
    return {
        width: '2px',
        padding: '4px',
        background: renderColorCustomerAccount(label),
        borderRadius: '50%',
        marginRight: '4px',
    };
};

/**
 * Orders Status
 */

const renderColorOrders = (param: number) => {
    switch (param) {
        case 1:
            return '#578aff';
        case 2:
            return '#ff7777';
        case 3:
            return '#ff9a5c';
        case 4:
            return '#93c66e';
        case 5:
            return '#bc63e3';
        case 6:
            return '#ff7f50';
        case 7:
            return '#ff69b4';
        case 8:
            return '#9400d3';
        case 9:
            return '#ffd700';
        case 10:
            return '#00bfff';
        case 11:
            return '#dc143c';
        case 12:
            return '#58b49c';
        default:
            return '#578aff';
    }
};

export const OrdersStatusBall = (label: number) => {
    return {
        width: '2px',
        padding: '4px',
        background: renderColorOrders(label),
        borderRadius: '50%',
        marginRight: '4px',
    };
};
export const ColorCustomerAccountStatus = (param: number) => {
    switch (param) {
        case 3:
            return { color: 'failure', icon: HiMinusCircle };
        case 2:
            return { color: 'success', icon: HiCheckCircle };
        default:
            return { color: 'info', icon: HiClock };
    }
};

export const ColorAccountStatus = (param: number) => {
    switch (param) {
        case 2:
            return { color: 'failure', icon: HiMinusCircle };
        default:
            return { color: 'success', icon: HiCheckCircle };
    }
};

export const RoleBadgeColor = (param: string) => {
    switch (param) {
        case 'admin':
            return { color: 'info' };
        case 'productProvider':
            return { color: 'gray' };
        case 'callAgent':
            return { color: 'indigo' };
        case 'accountManager':
            return { color: 'purple' };
        default:
            return { color: 'info' };
    }
};

export const getStatus = (status: number) => {
    switch (status) {
        case 1:
            return 'Pending';
        case 2:
            return 'Confirmed';
        case 3:
            return 'Rejected';
        default:
            return 'Pending';
    }
};
