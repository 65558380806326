/* eslint-disable react/jsx-fragments */
import CustomError from 'components/customError/CustomError';
import { Button, Label, TextInput, Textarea } from 'flowbite-react';
import { Form, Formik } from 'formik';
import React from 'react';
import { IConfig, ISimpleBank } from 'services/admin/configuration';
import { MdDeleteForever } from 'react-icons/md';
import { FaSave } from 'react-icons/fa';
import {
    BankConfigEur,
    BankConfigUSDInternational,
    BankConfigUSDlocal,
} from 'utils/validations/bankInformationsValidation';

const UpdateBanklInfos: React.FC<{
    config: ISimpleBank;
    updateBankInformations: (values: any, index: number) => void;
    bankConfig: IConfig['bankInformations'];
    setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    setDeleteIndex: React.Dispatch<React.SetStateAction<number>>;
    editItem: any;
    setEditItem: React.Dispatch<React.SetStateAction<any[]>>;
}> = ({ config, updateBankInformations, bankConfig, editItem, setEditItem, setOpenDeleteModal, setDeleteIndex }) => {
    return (
        <>
            <div>
                {config.accountCurrency === 'USD' && config?.paymentType === 'local' && (
                    <Formik
                        initialValues={{
                            accountCurrency: config?.accountCurrency,
                            paymentType: config?.paymentType,
                            accountNumber: config?.accountNumber,
                            accountHolder: config?.accountHolder,
                            accountType: config?.accountType,
                            routingNumber: config?.routingNumber,
                            description: config?.description,
                        }}
                        onSubmit={(values, onSubmitProps) => {
                            updateBankInformations(values, bankConfig.simpleBank.indexOf(config));
                            onSubmitProps.setSubmitting(false);
                        }}
                        validationSchema={BankConfigUSDlocal}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit} placeholder="">
                                <div className="grid grid-cols-2 gap-4 ">
                                    <div className="bg-white p-2 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                        <div className="relative w-full mb-6 group">
                                            <div className="mb-2 block">
                                                <Label htmlFor="accountHolder" value="Account Holder" />
                                            </div>
                                            <TextInput
                                                id="accountHolder"
                                                type="text"
                                                sizing="md"
                                                {...formik.getFieldProps('accountHolder')}
                                                helperText={
                                                    <>
                                                        <CustomError name="accountHolder" component="div" />
                                                    </>
                                                }
                                            />
                                        </div>
                                        <div className="relative w-full mb-6 group">
                                            <div className="mb-2 block">
                                                <Label htmlFor="accountNumber" value="Account Number" />
                                            </div>
                                            <TextInput
                                                id="accountNumber"
                                                type="text"
                                                sizing="md"
                                                {...formik.getFieldProps('accountNumber')}
                                                helperText={
                                                    <>
                                                        <CustomError name="accountNumber" component="div" />
                                                    </>
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="bg-white p-2 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                        <div className="relative w-full mb-6 group">
                                            <div className="mb-2 block">
                                                <Label htmlFor="routingNumber" value="Routing Number" />
                                            </div>
                                            <TextInput
                                                id="routingNumber"
                                                type="text"
                                                sizing="md"
                                                {...formik.getFieldProps('routingNumber')}
                                                helperText={
                                                    <>
                                                        <CustomError name="routingNumber" component="div" />
                                                    </>
                                                }
                                            />
                                        </div>

                                        <div>
                                            <div className="mb-2 block">
                                                <Label className="dark:text-white" value="Description" />
                                            </div>
                                            <Textarea
                                                {...formik.getFieldProps('description')}
                                                name="description"
                                                id="comment"
                                                placeholder="Leave a description..."
                                                helperText={
                                                    <React.Fragment>
                                                        <CustomError name="description" component="div" />
                                                    </React.Fragment>
                                                }
                                                rows={4}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-wrap gap-4 justify-between py-2">
                                    <div>
                                        <Button
                                            pill
                                            color="failure"
                                            onClick={() => {
                                                const nextEditItem = editItem.map((c: any, i: number) => {
                                                    if (i === bankConfig.simpleBank.indexOf(config)) {
                                                        return false;
                                                    }
                                                    return c;
                                                });
                                                setEditItem(nextEditItem);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                    <div className="flex gap-2">
                                        <Button
                                            pill
                                            color="failure"
                                            onClick={() => {
                                                setOpenDeleteModal(true);
                                                setDeleteIndex(bankConfig.simpleBank.indexOf(config));
                                            }}
                                        >
                                            <MdDeleteForever className="mr-2 h-5 w-5" />
                                            Delete
                                        </Button>

                                        <Button pill type="submit">
                                            <FaSave className="mr-2 h-5 w-5" />
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}

                {config.accountCurrency === 'USD' && config?.paymentType === 'international' && (
                    <Formik
                        initialValues={{
                            accountCurrency: config?.accountCurrency,
                            paymentType: config?.paymentType,
                            accountNumber: config?.accountNumber,
                            accountHolder: config?.accountHolder,
                            swiftBic: config?.swiftBic,
                            routingNumber: config?.routingNumber,
                            description: config?.description,
                        }}
                        onSubmit={(values, onSubmitProps) => {
                            updateBankInformations(values, bankConfig.simpleBank.indexOf(config));
                            onSubmitProps.setSubmitting(false);
                        }}
                        validationSchema={BankConfigUSDInternational}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit} placeholder="">
                                <div className="grid grid-cols-2 gap-4 ">
                                    <div className="bg-white p-2 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                        <div className="relative w-full mb-6 group">
                                            <div className="mb-2 block">
                                                <Label htmlFor="accountHolder" value="Account Holder" />
                                            </div>
                                            <TextInput
                                                id="accountHolder"
                                                type="text"
                                                sizing="md"
                                                {...formik.getFieldProps('accountHolder')}
                                                helperText={
                                                    <>
                                                        <CustomError name="accountHolder" component="div" />
                                                    </>
                                                }
                                            />
                                        </div>
                                        <div className="relative w-full mb-6 group">
                                            <div className="mb-2 block">
                                                <Label htmlFor="accountNumber" value="Account Number" />
                                            </div>
                                            <TextInput
                                                id="accountNumber"
                                                type="text"
                                                sizing="md"
                                                {...formik.getFieldProps('accountNumber')}
                                                helperText={
                                                    <>
                                                        <CustomError name="accountNumber" component="div" />
                                                    </>
                                                }
                                            />
                                        </div>
                                        <div className="relative w-full mb-6 group">
                                            <div className="mb-2 block">
                                                <Label
                                                    htmlFor="price3"
                                                    className="dark:text-white"
                                                    color={
                                                        formik.touched.swiftBic && formik.errors.swiftBic
                                                            ? 'failure'
                                                            : ''
                                                    }
                                                    value="SWIFT / BIC"
                                                />
                                            </div>
                                            <TextInput
                                                {...formik.getFieldProps('swiftBic')}
                                                name="swiftBic"
                                                color={
                                                    formik.touched.swiftBic && formik.errors.swiftBic
                                                        ? 'failure'
                                                        : 'gray'
                                                }
                                                type="text"
                                                helperText={
                                                    <React.Fragment>
                                                        <CustomError name="swiftBic" component="div" />
                                                    </React.Fragment>
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="bg-white p-2 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                        <div className="relative w-full mb-6 group">
                                            <div className="mb-2 block">
                                                <Label htmlFor="routingNumber" value="Routing Number" />
                                            </div>
                                            <TextInput
                                                id="routingNumber"
                                                type="text"
                                                sizing="md"
                                                {...formik.getFieldProps('routingNumber')}
                                                helperText={
                                                    <>
                                                        <CustomError name="routingNumber" component="div" />
                                                    </>
                                                }
                                            />
                                        </div>

                                        <div>
                                            <div className="mb-2 block">
                                                <Label className="dark:text-white" value="Description" />
                                            </div>
                                            <Textarea
                                                {...formik.getFieldProps('description')}
                                                name="description"
                                                id="comment"
                                                placeholder="Leave a description..."
                                                helperText={
                                                    <React.Fragment>
                                                        <CustomError name="description" component="div" />
                                                    </React.Fragment>
                                                }
                                                rows={4}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-wrap gap-4 justify-between py-2">
                                    <div>
                                        <Button
                                            pill
                                            color="failure"
                                            onClick={() => {
                                                const nextEditItem = editItem.map((c: any, i: number) => {
                                                    if (i === bankConfig.simpleBank.indexOf(config)) {
                                                        return false;
                                                    }
                                                    return c;
                                                });
                                                setEditItem(nextEditItem);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                    <div className="flex gap-2">
                                        <Button
                                            pill
                                            color="failure"
                                            onClick={() => {
                                                setOpenDeleteModal(true);
                                                setDeleteIndex(bankConfig.simpleBank.indexOf(config));
                                            }}
                                        >
                                            <MdDeleteForever className="mr-2 h-5 w-5" />
                                            Delete
                                        </Button>

                                        <Button pill type="submit">
                                            <FaSave className="mr-2 h-5 w-5" />
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
                {config.accountCurrency === 'EUR' && (
                    <Formik
                        initialValues={{
                            accountCurrency: config?.accountCurrency,
                            paymentType: config?.paymentType,
                            accountNumber: config?.accountNumber,
                            accountHolder: config?.accountHolder,
                            swiftBic: config?.swiftBic,
                            iban: config?.iban,
                            description: config?.description,
                        }}
                        onSubmit={(values, onSubmitProps) => {
                            updateBankInformations(values, bankConfig.simpleBank.indexOf(config));
                            onSubmitProps.setSubmitting(false);
                        }}
                        validationSchema={BankConfigEur}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit} placeholder="">
                                <div className="grid grid-cols-2 gap-4 ">
                                    <div className="bg-white p-2 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                        <div className="relative w-full mb-6 group">
                                            <div className="mb-2 block">
                                                <Label htmlFor="accountHolder" value="Account Holder" />
                                            </div>
                                            <TextInput
                                                id="accountHolder"
                                                type="text"
                                                sizing="md"
                                                {...formik.getFieldProps('accountHolder')}
                                                helperText={
                                                    <>
                                                        <CustomError name="accountHolder" component="div" />
                                                    </>
                                                }
                                            />
                                        </div>
                                        <div className="relative w-full mb-6 group">
                                            <div className="mb-2 block">
                                                <Label htmlFor="accountNumber" value="Account Number" />
                                            </div>
                                            <TextInput
                                                id="accountNumber"
                                                type="text"
                                                sizing="md"
                                                {...formik.getFieldProps('accountNumber')}
                                                helperText={
                                                    <>
                                                        <CustomError name="accountNumber" component="div" />
                                                    </>
                                                }
                                            />
                                        </div>
                                        <div className="relative w-full mb-6 group">
                                            <div className="mb-2 block">
                                                <Label
                                                    htmlFor="price3"
                                                    className="dark:text-white"
                                                    color={
                                                        formik.touched.swiftBic && formik.errors.swiftBic
                                                            ? 'failure'
                                                            : ''
                                                    }
                                                    value="SWIFT / BIC"
                                                />
                                            </div>
                                            <TextInput
                                                {...formik.getFieldProps('swiftBic')}
                                                name="swiftBic"
                                                color={
                                                    formik.touched.swiftBic && formik.errors.swiftBic
                                                        ? 'failure'
                                                        : 'gray'
                                                }
                                                type="text"
                                                helperText={
                                                    <React.Fragment>
                                                        <CustomError name="swiftBic" component="div" />
                                                    </React.Fragment>
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="bg-white p-2 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                        <div className="relative w-full mb-6 group">
                                            <div className="mb-2 block">
                                                <Label className="dark:text-white" value="IBAN" />
                                            </div>
                                            <TextInput
                                                {...formik.getFieldProps('iban')}
                                                name="iban"
                                                type="text"
                                                helperText={
                                                    <React.Fragment>
                                                        <CustomError name="iban" component="div" />
                                                    </React.Fragment>
                                                }
                                            />
                                        </div>

                                        <div>
                                            <div className="mb-2 block">
                                                <Label className="dark:text-white" value="Description" />
                                            </div>
                                            <Textarea
                                                {...formik.getFieldProps('description')}
                                                name="description"
                                                id="comment"
                                                placeholder="Leave a description..."
                                                helperText={
                                                    <React.Fragment>
                                                        <CustomError name="description" component="div" />
                                                    </React.Fragment>
                                                }
                                                rows={4}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-wrap gap-4 justify-between py-2">
                                    <div>
                                        <Button
                                            pill
                                            color="failure"
                                            onClick={() => {
                                                const nextEditItem = editItem.map((c: any, i: number) => {
                                                    if (i === bankConfig.simpleBank.indexOf(config)) {
                                                        return false;
                                                    }
                                                    return c;
                                                });
                                                setEditItem(nextEditItem);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                    <div className="flex gap-2">
                                        <Button
                                            pill
                                            color="failure"
                                            onClick={() => {
                                                setOpenDeleteModal(true);
                                                setDeleteIndex(bankConfig.simpleBank.indexOf(config));
                                            }}
                                        >
                                            <MdDeleteForever className="mr-2 h-5 w-5" />
                                            Delete
                                        </Button>

                                        <Button pill type="submit">
                                            <FaSave className="mr-2 h-5 w-5" />
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </>
    );
};

export default UpdateBanklInfos;
