/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Box, Button } from '@mui/material';
import {
    clickTextStyle,
    CustomFileInput,
    CustomLabelInput,
    // imageSizeStyle,
    imageTypes,
    uploadContainer,
    uploadTextWrapper,
    uploadWrapper,
} from './videoUpload.styles';
import Upload from '../../assets/upload.png';

interface VideoUploadProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const VideoUpload: React.FC<VideoUploadProps> = ({ name, onChange, multiple }) => {
    return (
        <Button sx={uploadWrapper}>
            <CustomFileInput
                multiple={multiple}
                type="file"
                id="video-file"
                accept=".mp4, .ogg"
                onChange={onChange}
                // multiple
                name={name}
            />
            <CustomLabelInput htmlFor="video-file">
                <Box sx={uploadContainer}>
                    <img src={Upload} alt="upload icon" width="120px" />
                    <Box sx={uploadTextWrapper}>
                        <Box sx={clickTextStyle}>Click here to upload your video</Box>
                        {/* <Box sx={imageSizeStyle}>To upload product image (Max size 2 MB)</Box> */}
                        <Box sx={imageTypes}>Supports: mp4, ogg.</Box>
                    </Box>
                </Box>
            </CustomLabelInput>
        </Button>
    );
};

export default VideoUpload;
