// import { styled } from '@mui/material/styles';
// import OutlinedInput from '@mui/material/OutlinedInput';

export const LabelStyling = {
    // color: 'white',
};

export const StyledTextField = (disabled: boolean) => {
    return {
        width: '100%',
        margin: '10px 0px',
        borderRadius: '8px',
        height: '50px',
        backgroundColor: disabled ? 'disabledInputBackground' : 'inputBackground',
    };
};
