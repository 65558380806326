import { Box, Typography } from '@mui/material/';
import { Button, Modal } from 'flowbite-react';
import { statusDisplayColor } from 'pages/productList/ProductListElements';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCurrentUser } from 'utils/helpers/auth';
import { StatusBadge } from 'utils/helpers/statusBadge';
import Package from 'assets/package.png';
import { IoIosBarcode } from 'react-icons/io';
import Tablesku from 'components/listSkus/TableSku';
import ProductImage from '../productImage/ProductImage';
import { MessageStyleHeader } from './MessageHeader.styles';
import { MessageHeaderProps } from './MessageHeader.types';

const MessageHeader: React.FC<MessageHeaderProps> = ({
    productImage,
    productTitle,
    sku,
    price,
    status,
    type,
    productId,
    customerId,
    productInfo,
}) => {
    const location = useLocation();
    const failedLoadingSkuTxt = 'Unable to show skus! Try reloading the page.';
    const loadingSkuTxt = 'Loading skus...';
    const [skuText, setSkuText] = useState(loadingSkuTxt);

    useEffect(() => {
        if (sku !== '' && sku !== undefined) {
            setSkuText(sku);
        }
        const skuTxtTimeout = setTimeout(() => {
            if (sku === '' || sku === undefined) {
                setSkuText(failedLoadingSkuTxt);
            }
        }, 1000);
        return () => {
            clearTimeout(skuTxtTimeout);
        };
    }, [sku]);

    const [open, setOpen] = useState(false);
    const displaySkus = () => {
        const singleSKuRegex = /^LS-\d+$/;

        if (singleSKuRegex.test(skuText)) {
            return (
                <Typography color={skuText === failedLoadingSkuTxt ? 'red' : undefined} variant="body2">
                    SKU: {skuText}
                </Typography>
            );
        }
        if (skuText.startsWith('[')) {
            return (
                <>
                    <Modal
                        dismissible
                        show={open}
                        popup
                        onClose={() => setOpen(false)}
                        theme={{
                            content: {
                                base: 'w-fit',
                                inner: 'relative rounded-lg bg-white shadow dark:bg-gray-700 flex flex-col max-h-[90vh] w-fit',
                            },
                        }}
                    >
                        {' '}
                        <Modal.Header className="p-4 pb-0">Product Skus - {productTitle || '--'}</Modal.Header>
                        <Modal.Body className=" flex !p-4 ">
                            <Tablesku product={productInfo?.productInfo || null} />
                        </Modal.Body>
                    </Modal>

                    <Button color="gray" size="small" onClick={() => setOpen(true)} className="p-1">
                        <IoIosBarcode />
                    </Button>
                </>
            );
        }
        return <div>{skuText}</div>;
    };
    const navigate = useNavigate();
    const goToProduct = () => {
        const user = getCurrentUser();
        let navigation = 'admin';
        if (user.role === 'accountManager') {
            navigation = 'accountManager';
        }
        if (user.role === 'productProvider') {
            navigation = 'productProvider';
        }

        if (location.pathname.includes('/conversations')) {
            navigate(`/${navigation}/product-settings`, {
                state: {
                    tabId: 0,
                    productId,
                    type,
                    customerId,
                    productName: productTitle,
                },
            });
        }
    };

    return (
        <>
            <Box sx={MessageStyleHeader}>
                <div className="flex justify-center gap-3 w-full">
                    <ProductImage src={productImage || Package} alt="" />

                    <div className="w-full">
                        <div className="flex justify-between items-center gap-3 w-full flex-wrap ">
                            <div>
                                <button type="button" onClick={goToProduct} title={productTitle}>
                                    <p className="truncate max-w-96 font-normal text-base font-popin ">
                                        {productTitle}
                                    </p>
                                </button>

                                {displaySkus()}
                                {price && <Typography variant="body2">Price Sample:{price}</Typography>}
                            </div>
                            <StatusBadge
                                name={statusDisplayColor[status]?.statusLabel}
                                style={statusDisplayColor[status]?.statusClass}
                            />
                        </div>
                    </div>
                </div>
            </Box>
        </>
    );
};

export default MessageHeader;
