/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import AutoComplete from 'components/autocomplete/autoComplete';
import DataTable from 'components/tables/tables';
import { Tabs, TabsRef, TextInput } from 'flowbite-react';
import { ORDER_PHASES } from 'pages/CallCenterAdmin/orders/ordersElements';
import { ICustomer } from 'pages/customers/customer.types';
import { statusDisplay } from 'pages/orders/orderElements';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { AiOutlineSearch } from 'react-icons/ai';
import { MdOutlineShoppingCart } from 'react-icons/md';
import { IOrder, getOrders } from 'services/admin/order';
import UseDocumentTitle from 'utils/dynamicTitle/dynamicTitle';
import { getCurrentUser } from 'utils/helpers/auth';
import { columns } from './ordersElements';
import { orderStatusListForCA } from './ordersStatus.types';

interface Ipagination {
    page: number;
    limit: number;
}

interface Imeta {
    type: number;
    totalDocs: number;
    limit: number;
    totalPages: number;
    page: number;
    pagingCounter: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    prevPage: number | null;
    nextPage: number | null;
}

interface Ifilter {
    dateRange?: { startDate?: string | number; endDate?: string | number };
    name?: string;
    zipCode?: number;
    customer?: ICustomer;
    country?: string;
    fulfillement?: string;
    goodsCost?: number;
    ShippingCost?: number;
    total?: number;
    status?: number;
    trackingCode?: string;
    callCenterAttempt?: number;
    orderRefOrPhone?: string;
    store?: string;
    withCC: true;
    orderPhase: string;
}

const OrderListCA = () => {
    UseDocumentTitle('Orders');
    const tabsRef = useRef<TabsRef>(null);
    const OrderPhase = useMemo(() => ORDER_PHASES.slice(0, 2), []);
    // Data states
    const [ordersList, setOrdersList] = useState<IOrder[]>([]);
    const [resetValue, setresetValue] = useState(false);

    // Pagination States
    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 20,
    });
    const [filter, setFilter] = useState<Ifilter>({ withCC: true, orderPhase: OrderPhase[0] });
    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });
    const [isLoading, setIsLoading] = useState(false);

    // fetch orders on component mount & pagination or filter change
    useEffect(() => {
        /**
         * apply pagination & filter if filter options exists
         * else ignore filter options and only use pagination
         */
        const queryOptions = Object.values(filter)[0] ? { ...pagination, ...filter } : { ...pagination };

        /**
         * check if we are requesting an active page (already fetched and is displayed)
         */
        const fetchedAndActive = meta.page === pagination.page;
        /**
         * ignore request if page is available
         */
        if (!fetchedAndActive) {
            setIsLoading(true);
            getOrders({ ...queryOptions, callAgent: getCurrentUser()._id })
                .then((data: any) => {
                    setOrdersList(data?.data?.docs);
                    setMeta({
                        // type: data.type,
                        hasNextPage: data?.data?.hasNextPage,
                        hasPrevPage: data?.data?.hasPrevPage,
                        limit: data?.data?.limit,
                        nextPage: data?.data?.nextPage,
                        page: data?.data?.page,
                        pagingCounter: data?.data?.pagingCounter,
                        prevPage: data?.data?.prevPage,
                        totalDocs: data?.data?.totalDocs,
                        totalPages: data?.data?.totalPages,
                    });
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false);
                    toast.error(err);
                });
        }
    }, [filter, pagination]);

    const handleFilterChange = (newFilter: React.SetStateAction<Partial<Ifilter>>) => {
        setFilter({ ...filter, ...newFilter });
        setMeta({ ...meta, page: -1 });
        setPagination((prev) => ({ ...prev, page: 1 }));
    };

    const handlePaginationChange = (value: { [key: string]: any }) => {
        setPagination((prevPagination) => {
            const data = { ...prevPagination, ...value };
            if (value.limit) {
                data.page = 1;
            }
            return data;
        });
        setMeta((prev) => ({ ...prev, page: -1 }));
    };

    const handleStatusFilterChange = (newFilter: Partial<Ifilter>) => {
        if (newFilter.status === null) {
            setFilter((oldFilter) => {
                const temp = oldFilter;
                delete temp.status;
                return { ...temp };
            });
        } else {
            setFilter({ ...filter, ...newFilter });
        }
        setMeta({ ...meta, page: -1 });
        setPagination((prev) => ({ ...prev, page: 1 }));
    };

    const handleOrderFilters = (newFilter: Partial<Ifilter>) => {
        if (newFilter.orderRefOrPhone === '') {
            setFilter((oldFilter) => {
                const temp = oldFilter;
                delete temp.orderRefOrPhone;
                return { ...temp };
            });
        } else {
            setFilter({ ...filter, ...newFilter });
        }
        setMeta({ ...meta, page: -1 });
        setPagination((prev) => ({ ...prev, page: 1 }));
    };

    return (
        <div>
            <section className="dark:bg-gray-900 flex items-center my-3">
                <div className=" relative w-full">
                    <div className="flex justify-between my-4">
                        <div className="flex ">
                            <MdOutlineShoppingCart size={32} /> <span className="font-medium text-2xl">Orders</span>
                        </div>
                    </div>
                    <div className="flex justify-between ">
                        <div className="flex p-2 space-y-3 md:flex-row md:space-y-0 md:space-x-4 ">
                            <AutoComplete
                                key="id"
                                placeholder="All Status"
                                options={orderStatusListForCA as []}
                                getOptionLabel={(option: any) => option.label}
                                // eslint-disable-next-line no-underscore-dangle
                                onChange={(option) => {
                                    setresetValue(false);
                                    // eslint-disable-next-line no-unused-expressions
                                    option?.type && tabsRef.current?.setActiveTab(OrderPhase.indexOf(option.type));
                                    handleStatusFilterChange({
                                        status: option.id,
                                        orderPhase: option.type || filter.orderPhase,
                                    });
                                }}
                                resetValue={resetValue}
                            />
                            <AutoComplete
                                key="id"
                                placeholder="Filter by attempts"
                                options={Array.from({ length: 11 }, (_, i: number) => i) as []}
                                getOptionLabel={(option: any) => `${option} ${option > 1 ? 'attempts' : 'attempt'}`}
                                // eslint-disable-next-line no-underscore-dangle
                                onChange={(option) => {
                                    // eslint-disable-next-line no-restricted-globals
                                    if (typeof option === 'number') {
                                        handleFilterChange({ callCenterAttempt: Number(option) });
                                    } else {
                                        handleFilterChange({ callCenterAttempt: undefined });
                                    }
                                }}
                            />
                        </div>
                        <TextInput
                            icon={AiOutlineSearch}
                            placeholder="Order Ref Or Phone Number..."
                            id="orderRefOrPhone"
                            type="text"
                            sizing="md"
                            style={{ maxWidth: 200 }}
                            className='class="flex p-2 space-y-3 md:flex-row md:space-y-0 md:space-x-4'
                            onChange={(e) => {
                                handleOrderFilters({ orderRefOrPhone: e.target.value });
                            }}
                        />
                    </div>
                </div>
            </section>
            <Tabs
                aria-label="Tabs with icons"
                theme={{
                    tablist: {
                        tabitem: {
                            base: 'flex items-center justify-center p-4 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 ',
                        },
                    },
                }}
                ref={tabsRef}
                onActiveTabChange={(tab) => {
                    const orderPhase = OrderPhase[tab];
                    handleStatusFilterChange({ orderPhase, status: undefined });
                }}
            >
                {OrderPhase.map((item: any) => (
                    <Tabs.Item
                        title={
                            // reset the status when we select one of the tab
                            <div onClick={() => setresetValue(true)} className="h-full py-4">
                                {item}
                            </div>
                        }
                        key={item}
                    >
                        <>
                            <DataTable
                                rows={ordersList}
                                columns={columns}
                                icons={statusDisplay}
                                setRows={setOrdersList}
                                pageState={{
                                    isLoading,
                                    total: meta.totalDocs,
                                    pageSize: pagination.limit,
                                    page: pagination.page,
                                    count: meta.totalPages,
                                }}
                                setPageState={handlePaginationChange}
                                filterState={filter}
                            />
                        </>
                    </Tabs.Item>
                ))}
            </Tabs>

            {/* <Modal
                fullWidth
                maxWidth="lg"
                open={openModal}
                setOpen={setOpenModal}
                body={<OrderDetails data={orderData} />}
            /> */}
        </div>
    );
};

export default OrderListCA;
