import socketIOClient from 'socket.io-client';

const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_SERVER_URL;
export const socket = socketIOClient(SOCKET_SERVER_URL as string, {
    path: '/socket.io/',
    forceNew: true,
    reconnectionAttempts: 3,
    timeout: 2000,
    transports: ['websocket', 'polling'],
});
