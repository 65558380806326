/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */

import { Badge, Dropdown, Label, TextInput } from 'flowbite-react';
import React from 'react';

import FormError from 'components/customError/formError';
import { MdAddPhotoAlternate } from 'react-icons/md';
import { resellerCategories } from 'services/admin/resellerProduct';

import { AiFillCloseCircle } from 'react-icons/ai';
import { badgeTheme } from 'utils/helpers/themeStyle/badge';
import { GeneralInfoProps } from './types';

const GeneralInformations: React.FC<GeneralInfoProps> = (props) => {
    const { setThumbnail, formik, urlImage, setUrlImage } = props;

    return (
        <div className="grid grid-cols-3 gap-4 bg-white  rounded-lg shadow p-4 dark:bg-gray-800 mb-2">
            <div>
                <label
                    htmlFor="dropzone-file-thumbnail"
                    className="relative w-48 h-48 justify-center cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                    <div className="relative  w-48 h-48">
                        <img
                            className="object-cover rounded-lg overflow-hidden border-2 border-gray-300 border-dashed rounded-lg  w-48 h-48"
                            src={urlImage}
                            alt="thumbnail"
                        />
                        <div className="absolute inset-x-0 bottom-0 shadow-xl bg-gray-950/70 w-full mx-auto -mt-1 rounded-lg rounded-t-none">
                            <span className="flex place-content-center justify-items-center p-2 text-white">
                                <MdAddPhotoAlternate className="w-6 h-6" color="white" />
                                Upload
                            </span>
                        </div>
                    </div>
                    <input
                        id="dropzone-file-thumbnail"
                        type="file"
                        accept="image/png, image/jpg, image/jpeg,"
                        className="hidden"
                        onChange={(e) => {
                            const file = e.target.files && e.target.files[0];
                            if (file) {
                                formik.setFieldValue('verificationImage', file);
                                const reader = new FileReader();
                                reader.readAsDataURL(file);
                                setUrlImage(URL.createObjectURL(file));
                                reader.onload = () => {
                                    setThumbnail({
                                        file,
                                        name: file.name,
                                        url: URL.createObjectURL(file),
                                        base64: reader.result,
                                    });
                                };
                            }
                        }}
                    />
                </label>
            </div>
            <div>
                <div>
                    <div className="mb-2 mt-1 block flex">
                        <Label
                            htmlFor="productName"
                            value="Product Name"
                            className="block text-sm font-medium text-gray-900 dark:text-white"
                        />
                        <span className="text-red-600 "> *</span>

                        <span>
                            <FormError name="name" component="div" />
                        </span>
                    </div>
                    <TextInput
                        id="productName"
                        type="text"
                        {...formik.getFieldProps('name')}
                        placeholder="Product Name"
                        name="name"
                    />
                </div>
                <div>
                    <div className="mb-2 mt-1 block flex">
                        <Label
                            htmlFor="chineeseName"
                            value="Chinese Name"
                            className="block text-sm font-medium text-gray-900 dark:text-white"
                        />
                        <span className="text-red-600 "> *</span>

                        <span>
                            <FormError name="chineseName" component="div" />
                        </span>
                    </div>
                    <TextInput
                        id="chineeseName"
                        type="text"
                        {...formik.getFieldProps('chineseName')}
                        placeholder="Chinese Name"
                        name="chineseName"
                    />
                </div>
                {/* <div>
                    <div className="mb-2 mt-1 block flex">
                        <Label
                            htmlFor="productLink"
                            value="Link"
                            className="block text-sm font-medium text-gray-900 dark:text-white"
                        />
                        <span className="text-red-600 "> *</span>

                        <span>
                            <FormError name="link" component="div" />
                        </span>
                    </div>
                    <TextInput
                        id="productLink"
                        type="text"
                        placeholder="Link"
                        {...formik.getFieldProps('link')}
                        name="link"
                    />
                </div> */}
            </div>
            <div>
                <div key="categories" className="w-full">
                    <div className="mb-2 mt-1 block flex">
                        <Label
                            htmlFor="categoriesList"
                            value="Categories"
                            className="block text-sm font-medium text-gray-900 dark:text-white"
                        />
                        <span className="text-red-600 "> *</span>

                        <span>
                            <FormError name="categories" component="div" />
                        </span>
                    </div>
                    <Dropdown
                        label=""
                        renderTrigger={() => (
                            <div className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                <div className="flex flex-wrap gap-2">
                                    {formik.values.categories.length > 0
                                        ? formik.values.categories.map((cat, index) => (
                                              <Badge color="blue" theme={badgeTheme}>
                                                  <div className="inline-flex gap-2 text-center items-center ">
                                                      <AiFillCloseCircle
                                                          role="button"
                                                          onClick={() => {
                                                              const newCategories = formik.values.categories;
                                                              newCategories.splice(index, 1);
                                                              formik.setFieldValue('categories', newCategories);
                                                          }}
                                                      />
                                                      {cat}
                                                  </div>
                                              </Badge>
                                          ))
                                        : 'Categories'}
                                </div>
                            </div>
                        )}
                        dismissOnClick={false}
                        aria-checked
                        className="h-48 overflow-x-auto "
                        color="gray"
                    >
                        {resellerCategories.map((cat) => (
                            <Dropdown.Item>
                                <div className="flex items-center gap-2">
                                    <div className="flex items-center mb-4">
                                        <input
                                            id={`checkbox${cat}`}
                                            type="checkbox"
                                            {...formik.getFieldProps(`categories`)}
                                            checked={formik.values.categories.indexOf(cat) !== -1}
                                            value={cat}
                                            name="categories"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <Label
                                            htmlFor={`checkbox${cat}`}
                                            className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            {cat}
                                        </Label>
                                    </div>
                                </div>
                            </Dropdown.Item>
                        ))}
                    </Dropdown>
                </div>
            </div>
        </div>
    );
};
export default GeneralInformations;
