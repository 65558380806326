import moment from 'moment';
import { ICallAgent } from 'pages/callAgentList/callAgent.types';

type WorkingTime = Exclude<ICallAgent['workingTime'], undefined>;

/**
 * Helper function for converting call agent's
 * working hours from the specified timezone to UTC
 *
 * (to get the valid timezones values use `moment.tz.names()`)
 *
 */
export function convertWorkingHoursToUTC(localWorkingTime: WorkingTime, timezoneValue: string): WorkingTime {
    const UTCWorkingTime: WorkingTime = [];
    const currentDateInTZ = moment().tz(timezoneValue);
    localWorkingTime.forEach((dailyWorkingTimeInTZ, dayOfWeekInTZ) => {
        Object.keys(dailyWorkingTimeInTZ).forEach((period) => {
            // convert daily hourly periods to UTC
            const [startHourInTz, endHourInTz] = period.split('-');
            const startDateInUTC = currentDateInTZ.clone().day(dayOfWeekInTZ).hours(Number(startHourInTz)).utc();
            const endDateInUTC = currentDateInTZ.clone().day(dayOfWeekInTZ).hours(Number(endHourInTz)).utc();
            const startDateStringInUTC = startDateInUTC.clone().format();
            const endDateStringInUTC = endDateInUTC.clone().format();

            // get the period in utc
            const startHourInUTC = startDateStringInUTC.substring(
                startDateStringInUTC.indexOf('T') + 1,
                startDateStringInUTC.indexOf(':'),
            );
            const endHourInUTC = endDateStringInUTC.substring(
                endDateStringInUTC.indexOf('T') + 1,
                endDateStringInUTC.indexOf(':'),
            );
            const periodInUTC = `${startHourInUTC}-${endHourInUTC}`;

            const dayOfWeekInUTC = startDateInUTC.day();

            // collect matched periods in utc to those in tz
            if (!UTCWorkingTime[dayOfWeekInUTC]) {
                UTCWorkingTime[dayOfWeekInUTC] = { [periodInUTC]: dailyWorkingTimeInTZ[period] };
            } else {
                Object.assign(UTCWorkingTime[dayOfWeekInUTC], { [periodInUTC]: dailyWorkingTimeInTZ[period] });
            }
        });
    });
    return UTCWorkingTime;
}

/**
 * Helper function for converting call agent's
 * working hours from UTC to the specified timezone
 *
 * (to get the valid timezones values use `moment.tz.names()`)
 */
export function convertWorkingHoursToAgentTz(
    UTCWorkingTime: WorkingTime,
    agentTimezone: ICallAgent['timeZone'],
): WorkingTime {
    // return the passed workingTime as it is if no timezone is passed (no tz conversion will be done)
    if (!agentTimezone) {
        return UTCWorkingTime;
    }
    const tzWorkingTime: WorkingTime = [];
    const currentDateInUTC = moment().utc();
    // const tzWorkingTime: [string, boolean][][] = [];
    UTCWorkingTime.forEach((dailyUTCWorkingTime, dayOfWeekInUTC) => {
        Object.keys(dailyUTCWorkingTime).forEach((period) => {
            const [startHourInUTC, endHourInUTC] = period.split('-');
            const startDateInTz = currentDateInUTC
                .clone()
                .day(dayOfWeekInUTC)
                .hours(Number(startHourInUTC))
                .tz(agentTimezone);
            const endDateInTz = currentDateInUTC
                .clone()
                .day(dayOfWeekInUTC)
                .hours(Number(endHourInUTC))
                .tz(agentTimezone);
            const startDateStringInTz = startDateInTz.clone().format();
            const endDateStringInTz = endDateInTz.clone().format();
            // get the period in TZ
            const startHourInTz = startDateStringInTz.substring(
                startDateStringInTz.indexOf('T') + 1,
                startDateStringInTz.indexOf(':'),
            );
            const endHourInTz = endDateStringInTz.substring(
                endDateStringInTz.indexOf('T') + 1,
                endDateStringInTz.indexOf(':'),
            );
            const periodInTz = `${startHourInTz}-${endHourInTz}`;

            const dayOfWeekInTZ = startDateInTz.day();

            // collect matched periods in tz to those in utc
            if (!tzWorkingTime[dayOfWeekInTZ]) {
                tzWorkingTime[dayOfWeekInTZ] = { [periodInTz]: dailyUTCWorkingTime[period] };
            } else {
                Object.assign(tzWorkingTime[dayOfWeekInTZ], { [periodInTz]: dailyUTCWorkingTime[period] });
            }
        });
    });

    const sortedTzworkingHours: WorkingTime = [];
    tzWorkingTime.forEach((wt, wd) => {
        const periods = Object.keys(wt).sort();
        periods.forEach((period) => {
            if (sortedTzworkingHours[wd]) {
                Object.assign(sortedTzworkingHours[wd], { [period]: wt[period] });
            } else {
                sortedTzworkingHours[wd] = { [period]: wt[period] };
            }
        });
    });
    return sortedTzworkingHours;
}
