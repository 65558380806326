/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import InputField from 'components/inputField/InputField';
import { Box, Typography } from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { IProduct } from 'utils/Interfaces/products.interface';
import { optionStyle, variantKeyMode, variantsTableRows } from './EditProduct.styles';

const VariantInfo: React.FC<{
    variantIndex?: number;
    currentVariant: IProduct;
    data: IProduct;
    setData: React.Dispatch<IProduct>;
    setHaveErrors: React.Dispatch<React.SetStateAction<boolean[]>>;
    navCache: { editProduct?: IProduct; quotation?: any };
    quantity?: number;
    setNavCache: React.Dispatch<
        React.SetStateAction<{
            editProduct?: IProduct;
            quotation?: any;
        }>
    >;
}> = ({ variantIndex, currentVariant, data, quantity, setData, setHaveErrors, navCache, setNavCache }) => {
    const [chineseName, setChineseName] = useState('');
    const [productHeight, setProductHeight] = useState('');
    const [productWeight, setProductWeight] = useState('');
    const [productWidth, setProductWidth] = useState('');
    const [productLength, setProductLength] = useState('');
    const [variantDeclarationAmount, setVariantDeclarationAmount] = useState('');
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        const currData: { [key: string]: any } = {}; // TODO: add proper type declaration
        if (navCache.editProduct && navCache.editProduct.variants.length > 0) {
            (navCache.editProduct.variants as IProduct[]).forEach((variant) => {
                if (variant._id === currentVariant._id) {
                    // read variant from navCache
                    currData.variantHeight = variant.productHeight;
                    currData.variantWeight = variant.productWeight;
                    currData.variantWidth = variant.productWidth;
                    currData.variantLength = variant.productLength;
                    currData.declarationAmount = variant.declarationAmount;
                    currData.chineseName = variant.chineseName;
                }
            });
        } else if (navCache.editProduct && navCache.editProduct.variants.length === 0) {
            // read product from navCache
            const productData = navCache.editProduct;
            currData.productHeight = productData.productHeight;
            currData.productWeight = productData.productWeight;
            currData.productWidth = productData.productWidth;
            currData.productLength = productData.productLength;
            currData.declarationAmount = productData.declarationAmount;
            currData.chineseName = productData.chineseName;
        } else {
            Object.assign(currData, currentVariant);
        }

        /**
         * 🏷️ This useEffect handels initializing the inputs with their appropiat values if available
         * TODO: Rewrite this logic
         * I had to keep this ugly logic as rewriting it will cause a major refactor for this component
         */
        if (currData.chineseName) {
            setChineseName(currData.chineseName);
        }
        if (currData.productHeight || currData.variantHeight)
            setProductHeight(currData.productHeight || currData.variantHeight);
        if (currData.productWeight || currData.variantWeight)
            setProductWeight(currData.productWeight || currData.variantWeight);
        if (currData.productWidth || currData.variantWidth)
            setProductWidth(currData.productWidth || currData.variantWidth);
        if (currData.productLength || currData.variantLength)
            setProductLength(currData.productLength || currData.variantLength);
        if (currData.declarationAmount) setVariantDeclarationAmount(currData.declarationAmount);
        if (currData.chineseName) setChineseName(currData.chineseName);
    }, []);

    /** 🏷️ validate user input
     * If invalid input then update the error state to include the proper error message
     *
     * Invalid inputs:
     * 1. input is zero: '0'
     * 2. input includes spaces: ' 1 1'
     * 3. input is not a valid number: '111have-a-string' (only chineses name can include letters)
     *
     * ⚠️ All input fields are validate except the input with name 'chineseName' as it should be a string
     * */
    const validateInput = ({
        inputName,
        inputLabel,
        inputValue,
    }: {
        inputName: string;
        inputLabel: string;
        inputValue: string;
    }) => {
        let isValid = false;

        if (
            inputName !== 'chineseName' &&
            // is not a valid number || include spaces
            (Number.isNaN(Number(inputValue)) || inputValue.includes(' '))
        ) {
            setErrors((err) => ({ ...err, [inputName]: `${inputLabel} must be a valid number` }));
            isValid = false;
        } else if (inputName !== 'chineseName' && inputValue === '0') {
            setErrors((err) => ({ ...err, [inputName]: `${inputLabel} must be a greater than 0` }));
            isValid = false;
        } else {
            /**
             * ⚠️ If the input is valid then we clear the error text
             */
            const haveErrors = Object.values(errors).some((err) => err.length > 0);
            isValid = !haveErrors;
            setErrors((err) => ({ ...err, [inputName]: '' }));
        }

        return isValid;
    };

    /** 🏷️ Update inputs
     *
     * This function runs the validation function against the input that it is handling and
     * updates the product state in the parent compnent (its value is passed through `data` prop)
     *
     */
    const handleInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const draftData = { ...data };
        const isValid = validateInput({
            inputName: e.target.name,
            inputLabel: e.target.placeholder ?? '',
            inputValue: e.target.value,
        });
        setHaveErrors((prevErrors) => {
            const draftHaveErrorsArr = [...prevErrors];
            if (variantIndex !== undefined) {
                draftHaveErrorsArr[variantIndex] = !isValid;
            } else {
                draftHaveErrorsArr[0] = !isValid;
            }
            return draftHaveErrorsArr;
        });
        if (variantIndex !== undefined) {
            // If the keys to update are in product.variants[]
            ((draftData.variants as IProduct[])[variantIndex][e.target.name as keyof IProduct] as any) = e.target.value;
        } else {
            // If the keys to update are directly in product object
            draftData[e.target.name as 'productHeight' | 'productLength' | 'productWidth' | 'productWeight'] =
                e.target.value;
        }
        setData({ ...draftData });
        setNavCache((prev) => {
            if (e.target.name === 'chineseName' && variantIndex !== undefined) {
                (draftData.variants as IProduct[])[variantIndex].chineseName = e.target.value;
            } else if (e.target.name === 'chineseName' && variantIndex === undefined) {
                draftData.chineseName = e.target.value;
            }
            return { ...prev, editProduct: draftData };
        });
    };

    return (
        <>
            {/* update inputs */}
            <Box sx={variantsTableRows}>
                <Box sx={optionStyle}>{variantIndex !== undefined ? `variant ${variantIndex + 1}` : ' - '}</Box>
                <Box sx={variantKeyMode}>{currentVariant.name}</Box>
                <InputField
                    value={chineseName}
                    placeholder="chinese name"
                    name="chineseName"
                    onChange={(e) => {
                        handleInputValueChange(e);
                        setChineseName(e.target.value);
                    }}
                />
                <InputField value={quantity || currentVariant?.quantity} disabled />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center ' }}>
                    <InputField
                        value={productWeight}
                        placeholder={variantIndex !== undefined ? 'variant weight' : 'product weight'}
                        name="productWeight"
                        onChange={(e) => {
                            handleInputValueChange(e);
                            setProductWeight(e.target.value);
                        }}
                    />
                    <Typography
                        sx={{
                            backgroundColor: 'borderColor',
                            padding: '15px',
                            marginLeft: '-5px',
                            borderTopRightRadius: '7px',
                            borderBottomRightRadius: '7px',
                        }}
                    >
                        KG
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <InputField
                        value={productHeight}
                        name="productHeight"
                        placeholder={variantIndex !== undefined ? 'variant height' : 'product height'}
                        onChange={(e) => {
                            handleInputValueChange(e);
                            setProductHeight(e.target.value);
                        }}
                    />
                    <Typography
                        sx={{
                            backgroundColor: 'borderColor',
                            padding: '15px',
                            marginLeft: '-5px',
                            borderTopRightRadius: '7px',
                            borderBottomRightRadius: '7px',
                        }}
                    >
                        CM
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <InputField
                        value={productWidth}
                        placeholder={variantIndex !== undefined ? 'variant width' : 'product width'}
                        name="productWidth"
                        onChange={(e) => {
                            handleInputValueChange(e);
                            setProductWidth(e.target.value);
                        }}
                    />
                    <Typography
                        sx={{
                            backgroundColor: 'borderColor',
                            padding: '15px',
                            marginLeft: '-5px',
                            borderTopRightRadius: '7px',
                            borderBottomRightRadius: '7px',
                        }}
                    >
                        CM
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <InputField
                        value={productLength}
                        placeholder={variantIndex !== undefined ? 'variant length' : 'product length'}
                        name="productLength"
                        onChange={(e) => {
                            handleInputValueChange(e);
                            setProductLength(e.target.value);
                        }}
                    />
                    <Typography
                        sx={{
                            backgroundColor: 'borderColor',
                            padding: '15px',
                            marginLeft: '-5px',
                            borderTopRightRadius: '7px',
                            borderBottomRightRadius: '7px',
                        }}
                    >
                        CM
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <InputField
                        value={variantDeclarationAmount}
                        placeholder="declaration amount"
                        name="declarationAmount"
                        onChange={(e) => {
                            handleInputValueChange(e);
                            setVariantDeclarationAmount(e.target.value);
                        }}
                    />
                    <Typography
                        sx={{
                            backgroundColor: 'borderColor',
                            padding: '15px',
                            marginLeft: '-5px',
                            borderTopRightRadius: '7px',
                            borderBottomRightRadius: '7px',
                        }}
                    >
                        USD
                    </Typography>
                </Box>
            </Box>
            {/* errors display */}
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '5px',
                }}
            >
                {Object.keys(errors).map((invalidField) => {
                    return (
                        <Box key={invalidField}>
                            {errors[invalidField] && (
                                <Box
                                    sx={{
                                        color: '#ff7777',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '5px',
                                        border: '1px solid',
                                        borderColor: 'borderColor',
                                        borderRadius: '4px',
                                        padding: '5px',
                                        width: 'fit-content',
                                    }}
                                >
                                    <ReportProblemIcon />
                                    <Typography variant="caption">{errors[invalidField]}</Typography>
                                </Box>
                            )}
                        </Box>
                    );
                })}
            </Box>
        </>
    );
};

export default VariantInfo;
