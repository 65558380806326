import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { Box } from '@mui/material';
import { ModalProps } from './Modal.types';
import { bodyStyle } from './Modal.styles';

const Modal: React.FC<ModalProps> = ({ body, setOpen, open, maxWidth, fullWidth }) => {
    const closeModal = () => {
        setOpen(false);
    };
    return (
        <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            sx={bodyStyle}
            onClose={closeModal}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <Box>{body}</Box>
        </Dialog>
    );
};

export default Modal;
