/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/style-prop-object */
/* eslint-disable no-underscore-dangle */
import { Checkbox, Dropdown } from 'flowbite-react';
import { IStatustActions } from 'pages/productList/product.types';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { HiPencil } from 'react-icons/hi';
import { MdDangerous } from 'react-icons/md';
import { getProducts, updateProduct } from 'services/admin/product';
import { getExpiredDate } from 'utils/helpers/expiredDate';
import { statusList } from './ProductListElements';

const StatusActions = (props: IStatustActions) => {
    const { product, setRows, currentPage, currentFilter, Icon, setActiveTab } = props;
    const [expiredIn, setexpiredIn] = useState<string>();

    useEffect(() => {
        if (!([3, 5].includes(product.status) && product.expirationDate)) return;
        setexpiredIn(getExpiredDate(product.expirationDate)?.expiredIn || '');

        const interval = setInterval(() => {
            const expired = getExpiredDate(product.expirationDate)?.expiredIn;
            setexpiredIn(expired || '');
            !expired && clearInterval(interval);
        }, 60000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const updateProductStatus = (status: Number) => {
        const updatedData = { status };
        if (status === 1 && product?.quotation) {
            // reset the quotation data when the product is set to "pending"
            const draftProductData = { ...product };
            draftProductData.quotation = [];
            Object.assign(updatedData, { ...draftProductData, status });
        }
        updateProduct({ id: product._id, updatedData })
            .then(() => {
                toast.success('Updated successfuly');
            })
            .then(async () => {
                const productPhase = statusList.find((el) => el.id === status)?.type;
                if (productPhase && setActiveTab && productPhase !== currentFilter?.productPhase) {
                    setActiveTab(productPhase);
                } else {
                    const res = await getProducts({ page: currentPage, ...currentFilter, ...({ productPhase } || {}) });
                    setRows(res.data.docs);
                }
                return null;
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message);
            });
    };

    const optionsList = [
        {
            label: 'Pending',
            action: () => {
                if ([2, 3, 100].includes(product.status)) {
                    updateProductStatus(1);
                } else {
                    toast.error(
                        `Unable to update product status to "Pending". Product status must be "Reviewing" or "Quoted".`,
                    );
                }
            },
            iconAcion: HiPencil,
            status: 1,
        },
        {
            label: 'Reviewing',
            action: () => updateProductStatus(2),
            iconAcion: HiPencil,
            status: 2,
        },
        {
            label: 'Waiting for payment',
            action: () => updateProductStatus(5),
            iconAcion: HiPencil,
            status: 5,
        },
        {
            label: 'Paid',
            action: () => updateProductStatus(6),
            iconAcion: HiPencil,
            status: 6,
        },
        {
            label: 'Product not available',
            action: () => updateProductStatus(99),
            iconAcion: MdDangerous,
            status: 99,
        },
        {
            label: 'Expired',
            action: () => updateProductStatus(100),
            iconAcion: HiPencil,
            status: 100,
        },
    ];
    const bulkOptions = [
        ...optionsList,
        {
            label: 'Waiting for pickup',
            action: () => updateProductStatus(9),
            iconAcion: HiPencil,
            status: 9,
        },
        {
            label: 'Picked up',
            action: () => updateProductStatus(10),
            iconAcion: HiPencil,
            status: 10,
        },
        {
            label: 'Pending delivery',
            action: () => updateProductStatus(11),
            iconAcion: HiPencil,
            status: 11,
        },
        {
            label: 'Out for delivery',
            action: () => updateProductStatus(12),
            iconAcion: HiPencil,
            status: 12,
        },
        {
            label: 'Delivered',
            action: () => updateProductStatus(13),
            iconAcion: HiPencil,
            status: 13,
        },
        {
            label: 'Delivery failed',
            action: () => updateProductStatus(14),
            iconAcion: HiPencil,
            status: 14,
        },
    ];

    return (
        <div className="grid gap-1">
            <Dropdown inline arrowIcon={false} label={Icon}>
                {Number(product.type) !== 2
                    ? optionsList.map((action) => {
                          return (
                              <Dropdown.Item onClick={action.action} key={action.label} className="gap-2">
                                  <Checkbox checked={product.status === action.status} /> {`Set to ${action.label}`}
                              </Dropdown.Item>
                          );
                      })
                    : bulkOptions.map((action) => {
                          return (
                              <Dropdown.Item onClick={action.action} key={action.label} className="gap-2">
                                  {`Set to ${action.label}`}
                              </Dropdown.Item>
                          );
                      })}
            </Dropdown>
            {expiredIn && <span className="text-red-500 mt-1">{`Expires in ${expiredIn}`}</span>}
        </div>
    );
};
export default StatusActions;
