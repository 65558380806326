/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import CustomBtn from 'components/customButton/customBtn';
import Modal from 'components/modal/Modal';
import ProductImageUpload from 'components/productImageUpload/ProductImageUpload';
import VideoUpload from 'components/videoUpload/VideoUpload';
import { Alert, Progress, TextInput } from 'flowbite-react';
import toast from 'react-hot-toast';
import { HiInformationCircle } from 'react-icons/hi';
import { IoSearch } from 'react-icons/io5';
import Zoom from 'react-medium-image-zoom';
import { useLocation } from 'react-router-dom';
import { deleteQuotationImage, deleteQuotationVideo, getProduct, updateQuotation } from 'services/admin/product';
import { currencyConverter } from 'utils/currencyConverter';
import { downloadFilesAsBase64, openPdfInNewTab } from 'utils/helpers/productFilesDownloader';
import { IProduct, IQuotation } from 'utils/Interfaces/products.interface';
import { getExpiredDate } from 'utils/helpers/expiredDate';
import { uploadMedia } from 'services/fileUploader';
import FileOther from '../../assets/file.png';
import FilePdf from '../../assets/pdf.png';

const LogistioBulkQuotation: React.FC<{
    // TODO: Add proper type definition for `quotation`
    navCache: {
        editProduct?: IProduct;
        quotation?: (IQuotation & { country: string })[];
    };
    setNavCache: React.Dispatch<
        React.SetStateAction<{
            editProduct?: IProduct;
            quotation?: (IQuotation & { country: string })[];
        }>
    >;
}> = ({ navCache, setNavCache }) => {
    const location: any = useLocation();
    const [productNotAvailabe, setProductNotAvailabe] = useState<boolean>(false);
    const [quotations, setQuotations] = useState<(IQuotation & { country: string })[]>([]);
    const [progressCount, setProgressCount] = useState(0);
    const [quotationImgs, setQuotationImgs] = useState<string[]>([]);
    const [allImages, setAllImages] = useState<{ name: string; url: string; file?: File }[]>([]);
    const [selectedImgForDelete, setSelectedImgForDelete] = useState('');
    const [selectedVidForDelete, setSelectedVidForDelete] = useState('');
    const [allVideos, setAllVideos] = useState<{ name: string; url: string; file?: File }[]>([]);
    const [openDeleteImgModal, setOpenDeleteImgModal] = useState(false);
    const [openDeleteVideoModal, setOpenDeleteVideoModal] = useState(false);
    const [isStatusQuoted, setIsStatusQuoted] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [quotationVideos, setQuotationVideos] = useState<string[]>([]);
    const [quotationErrors, setQuotationErrors] = useState<
        { field: string; country: string; message: string; variant?: string }[]
    >([]);
    const [productName, setProductName] = useState('');
    const [productStatus, setProductStatus] = useState<number>();
    const [currentProduct, setCurrentProduct] = useState<IProduct>();
    const [expiredIn, setexpiredIn] = useState<string>();

    useEffect(() => {
        if (!(currentProduct && [3, 5].includes(currentProduct.status) && currentProduct.expirationDate)) return;
        setexpiredIn(getExpiredDate(currentProduct.expirationDate)?.expiredIn || '');

        const interval = setInterval(() => {
            const expired = getExpiredDate(currentProduct.expirationDate)?.expiredIn;
            setexpiredIn(expired || '');
            // eslint-disable-next-line no-unused-expressions
            !expired && clearInterval(interval);
        }, 60000);

        return () => {
            clearInterval(interval);
        };
    }, [currentProduct]);

    const groupQuotationByCountries = (quotationsData: IProduct['quotation']) => {
        // 1. get available coutries list
        const availableCountries = new Set<string>();
        quotationsData.forEach((quotation) => {
            quotation.options.forEach((opt) => {
                availableCountries.add(opt.country);
            });
        });
        // 2. iterate the quotations data and group it by country (key: country)
        // 2.1 init group
        const groupedQuotationsByCountry = new Map<
            string,
            {
                country: string;
                options: IProduct['quotation'][number]['options'];
                variants: IProduct['quotation'][number]['variants'];
            }
        >();
        availableCountries.forEach((country) => {
            groupedQuotationsByCountry.set(country, { country, options: [], variants: [] });
        });

        // 2.2 group quotations by country
        quotationsData.forEach((quotation) => {
            quotation.options.forEach((opt) => {
                const draft = groupedQuotationsByCountry.get(opt.country)!;
                draft.options.push(opt);
                if (quotation.variants.length > 0) {
                    draft.variants.push(quotation.variants[0]);
                }
                groupedQuotationsByCountry.set(opt.country, draft);
            });
        });
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const resultArr = [...groupedQuotationsByCountry].map(([_key, value]) => value);
        return resultArr;
    };

    const quotationDataFactory = (
        groupedQuotations: {
            country: string;
            options: IQuotation['options'];
            variants: IQuotation['variants'];
        }[],
    ): IQuotation[] => {
        const quotationsData = new Map<string, IQuotation>();

        groupedQuotations.forEach((quotation) => {
            const grpQuot = { ...quotation };
            // case we have variants
            if (grpQuot.variants.length > 0) {
                grpQuot.variants.forEach((variant, variantIdx) => {
                    const currentVariantQuotation = quotationsData.get((variant as IProduct)._id);
                    if (grpQuot.options[variantIdx].accepted !== undefined) {
                        grpQuot.options[variantIdx] = {
                            ...grpQuot.options[variantIdx],
                            isHidden: !grpQuot.options[variantIdx].accepted,
                        };
                    } else if (
                        grpQuot.options[variantIdx].accepted === undefined &&
                        grpQuot.options[variantIdx].isHidden !== undefined
                    ) {
                        delete grpQuot.options[variantIdx].isHidden;
                    }
                    if (currentVariantQuotation?.variants) {
                        quotationsData.set((variant as IProduct)._id, {
                            variants: [{ _id: (currentVariantQuotation?.variants?.[0] as IProduct)._id } as IProduct],
                            options: [...currentVariantQuotation.options, grpQuot.options[variantIdx]],
                        });
                    } else {
                        quotationsData.set((variant as IProduct)._id, {
                            variants: [{ _id: (variant as IProduct)._id } as IProduct],
                            options: [grpQuot.options[variantIdx]],
                        });
                    }
                });
            } else {
                // case where there is no variants
                grpQuot.options.forEach((option, optionIdx) => {
                    const currentCountryQuotation = quotationsData.get(option.country);
                    if (grpQuot.options[optionIdx].accepted !== undefined) {
                        grpQuot.options[optionIdx] = {
                            ...grpQuot.options[optionIdx],
                            isHidden: !grpQuot.options[optionIdx].accepted,
                        };
                    } else if (
                        grpQuot.options[optionIdx].accepted === undefined &&
                        grpQuot.options[optionIdx].isHidden !== undefined
                    ) {
                        delete grpQuot.options[optionIdx].isHidden;
                    }

                    if (currentCountryQuotation?.options) {
                        quotationsData.set(option.country, {
                            variants: [],
                            options: [...currentCountryQuotation.options, grpQuot.options[optionIdx]],
                        });
                    } else {
                        quotationsData.set(option.country, {
                            variants: [],
                            options: [grpQuot.options[optionIdx]],
                        });
                    }
                });
            }
        });

        const quotationsByVariantsArr = [...quotationsData].map((quotationMapItem) => quotationMapItem[1]);
        return quotationsByVariantsArr;
    };

    useEffect(() => {
        // Pending UI logic
        const toastLoader = toast.loading('Loading quotation data...');

        // Fetching product data
        getProduct(location.state.productId)
            .then((res) => {
                toast.success('Quotation loaded', { id: toastLoader });
                setIsStatusQuoted(
                    res.data.product.status === 3 ||
                        res.data.product.status === 41 ||
                        res.data.product.status === 42 ||
                        res.data.product.status === 69,
                );
                setProductStatus(res.data.product.status);
                setProductNotAvailabe(res.data.product.status === 99);
                setQuotationImgs(res.data.product.quotationImages);
                setProductName(res.data.product.name);
                setCurrentProduct(res.data.product);
                setAllImages([
                    ...res.data.product.quotationImages.map((url: string) => ({
                        name: url.substring(url.indexOf('.com/') + 5), // selects 'imgName' from 'https://domain.com/imgName'
                        url,
                    })),
                ]);
                if (res.data.product.video) {
                    setAllVideos([
                        ...res.data.product.video.map((url: string) => ({
                            name: url.substring(url.indexOf('.com/') + 5), // selects 'vidName' from 'https://domain.com/vidName'
                            url,
                        })),
                    ]);
                }
                setQuotationVideos(() => res.data.product.video || []);
                const groupedStocksByVariantName: any = {};

                if (navCache.quotation && Object.keys(navCache.quotation).length > 0) {
                    setQuotations(navCache.quotation);
                } else if (res.data.product?.quotation && res.data.product.quotation.length > 0) {
                    setQuotations(groupQuotationByCountries(res.data.product.quotation));
                } else if (res.data.product.variants.length < 1) {
                    // case no variants available
                    setQuotations(
                        groupQuotationByCountries(
                            res.data.product.stockConfigs!.map((stock) => {
                                return {
                                    variants: [],
                                    options: [
                                        {
                                            quantity: Number(stock.quantity),
                                            country: stock.country,
                                            unitPrice: 0,
                                            shippingLine: 'Vnlin',
                                            shippingDuration: '0',
                                            shippingLineFee: 0,
                                            totalShippingLineFee: 0,
                                            totalPrice: 0,
                                        },
                                    ],
                                };
                            }),
                        ),
                    );
                } else {
                    res.data.product.stockConfigs!.forEach((stock: any) => {
                        if (groupedStocksByVariantName[stock.variant]) {
                            groupedStocksByVariantName[stock.variant].push(stock);
                        } else {
                            groupedStocksByVariantName[stock.variant] = [stock];
                        }
                    });
                    const groupedStocksByvariantId: { [key: string]: any } = {};
                    Object.keys(groupedStocksByVariantName).forEach((variantName: any) => {
                        const idxOfId = res.data.product.variants.findIndex((el: any) => el.name === variantName);
                        const variantId: string = (res.data.product.variants as IProduct[])[idxOfId]._id;
                        groupedStocksByvariantId[variantId] = groupedStocksByVariantName[variantName];
                    });

                    const tempQuotations: IQuotation[] = [];
                    Object.values(groupedStocksByvariantId).forEach((stocks: any, i: number) => {
                        const row: any = {};
                        const index: number = res.data.product.variants.findIndex(
                            (el: any) => el._id === Object.keys(groupedStocksByvariantId)[i],
                        );
                        row.variants = [res.data.product.variants[index]];
                        row.options = [];
                        stocks.forEach((el: any) => {
                            row.options.push({
                                quantity: Number(el.quantity),
                                country: el.country,
                                unitPrice: 0,
                                shippingLine: 'Vnlin',
                                shippingLineFee: 0,
                                totalPrice: 0,
                            });
                        });
                        tempQuotations.push(row);
                    });
                    setQuotations(groupQuotationByCountries(tempQuotations));
                }
            })
            .catch((err) => {
                toast.success(`Sorry something went wrong! Please try again ${err}`, { id: toastLoader });
            });
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, quotationIdx: number, optIndex: number) => {
        const dupQuot = [...quotations];
        if (e.target.name === 'totalShippingLineFee') {
            const inputValue = e.target.value.replaceAll(',', '.');
            let totalQuantity = 0;
            dupQuot[quotationIdx].options.forEach((opt) => {
                totalQuantity += Number(opt.quantity);
            });
            const unitShippingPrice = Number(inputValue) / totalQuantity;
            dupQuot[quotationIdx].options = dupQuot[quotationIdx].options.map((opt) => {
                return {
                    ...opt,
                    shippingLineFee: unitShippingPrice * Number(opt.quantity),
                    totalShippingLineFee: Number(inputValue),
                };
            });
        } else if (e.target.name === 'unitPrice') {
            dupQuot[quotationIdx].options[optIndex].unitPrice = Number(e.target.value.replaceAll(',', '.'));
        } else if (e.target.name === 'shippingDuration') {
            dupQuot[quotationIdx].options = dupQuot[quotationIdx].options.map((opt) => {
                return {
                    ...opt,
                    shippingDuration: e.target.value,
                };
            });
        }
        setQuotations(dupQuot);
        setNavCache((prev) => ({ ...prev, quotation: dupQuot }));
    };

    const handleSubmitQuotation = () => {
        const toastId = toast.loading('Updating quotation...');
        setIsLoading(true);
        const quotationsGroupedByVariant = quotationDataFactory(quotations);
        const invalidFields: typeof quotationErrors = [];
        const validQuotations = quotationsGroupedByVariant.every((quotation) => {
            const validOptions = quotation.options.every((option) => {
                const validUnitPrice = !Number.isNaN(Number(option.unitPrice)) && Number(option.unitPrice) >= 0;
                const validShippingLineFee =
                    !Number.isNaN(Number(option.shippingLineFee)) && Number(option.shippingLineFee) >= 0;
                // get invalid unit price data
                if (!validUnitPrice) {
                    invalidFields.push({
                        field: 'unit price',
                        country: option.country,
                        variant: (quotation?.variants[0] as IProduct)?.name,
                        message: 'must be a valid non 0 integer',
                    });
                }
                // get invalid shipping fee data
                if (!validShippingLineFee) {
                    invalidFields.push({
                        field: 'shipping fee',
                        country: option.country,
                        variant: (quotation.variants[0] as IProduct)?.name,
                        message: 'must be a valid integer',
                    });
                }
                return validUnitPrice && validShippingLineFee;
            });
            return validOptions;
        });
        setQuotationErrors(invalidFields);

        if (!validQuotations) {
            toast.error('Quotation not submitted! Invalid data found. Check the quotations fields and try again.');
            setIsLoading(false);
            return;
        }

        // Pending UI logic

        const quotationData: {
            quotation: {
                options: {
                    totalPrice: number;
                    country: string;
                    quantity: number;
                    shippingLine: string;
                    shippingLineFee: number;
                    totalShippingLineFee: number;
                    shippingDuration: string;
                    unitPrice: number;
                    isHidden?: boolean | undefined;
                    accepted?: boolean | undefined;
                }[];
                variants: { _id: string }[];
            }[];
            status: number;
            video?: string[];
            quotationImages?: string[];
        } = {
            quotation: quotationsGroupedByVariant.map((el) => ({
                options: el.options.map((option) => {
                    const opt = { ...option };
                    // @ts-ignore the `_id` field is added by the db(mongo)
                    // because the schema was not defined for the quotation field
                    if (opt?._id) {
                        // @ts-ignore the `_id` field is added by the db(mongo)
                        // because the schema was not defined for the quotation field
                        delete opt._id;
                    }
                    return { ...opt, totalPrice: option.unitPrice * option.quantity };
                }),
                variants: el.variants.map((variant) => ({ _id: (variant as IProduct)._id })),
            })),
            status: 3,
        };

        const assetsFromData = new FormData();
        // new videos get appended as fields and existing vids are treated as urls
        const existingVidsUrls: string[] = [];
        allVideos.forEach((vidData) => {
            if (vidData.file) {
                assetsFromData.append(vidData.name, vidData.file);
            } else {
                existingVidsUrls.push(vidData.url);
            }
        });

        // new images get appended as fiels and existing imgs are treated as urls
        const existingImagesUrls: string[] = [];
        allImages.forEach((imageData) => {
            if (imageData.file) {
                assetsFromData.append(imageData.name, imageData.file);
            } else {
                existingImagesUrls.push(imageData.url);
            }
        });

        // Handler for upload progress
        const config = {
            onUploadProgress: (progressEvent: any) => {
                const progress = Math.round((progressEvent.loaded * 50) / progressEvent.total);
                setProgressCount(progress);
            },
            onDownloadProgress: (progressEvent: any) => {
                const progress = Math.round(50 + (progressEvent.loaded * 50) / progressEvent.total);

                setProgressCount(progress);
            },
        };

        toast.loading('Uploading quotation assets...', { id: toastId });
        // uploading quotation assets
        uploadMedia(assetsFromData, config)
            .then((res) => {
                const { uploderImages: uploaderImages, uplodervideos: uploadervideos } = res.data;
                if (res.data.uplodervideos) {
                    quotationData.video = uploadervideos;
                }
                if (res.data.uploderImages) {
                    quotationData.quotationImages = uploaderImages;
                }
                toast.success('Quotation assets uploaded. Saving quotation configurations...', { id: toastId });
            })
            .catch((err) => {
                toast.error(`Unable to upload quotation assets. ${err?.response?.data?.errors?.message || ''}`);
            })
            .finally(() => {
                // Updating product now
                updateQuotation(
                    {
                        id: location.state.productId,
                        updatedData: quotationData,
                    },
                    config,
                )
                    .then((res) => {
                        // 🏷️ remove cached values for `quotation`
                        setNavCache((prev) => {
                            const temp = { ...prev };
                            delete temp.quotation;
                            return temp;
                        });
                        setProductStatus(res.data.status);
                        // We close the notification to reset its content (it was opened with info type when update started)
                        toast.success('Product Quotation Added', { id: toastId });

                        // Get the previously created object urls
                        const objectURLs: string[] = [];
                        allImages.forEach((image) => {
                            if (image.file) {
                                objectURLs.push(image.url);
                            }
                        });
                        allVideos.forEach((vid) => {
                            if (vid.file) {
                                objectURLs.push(vid.url);
                            }
                        });

                        // Update images/videos in the view to use the remote urls from db if available
                        setQuotationImgs(res.data.quotationImages);
                        setAllImages([
                            ...res.data.quotationImages.map((url: string) => ({
                                name: url.substring(url.indexOf('.com/') + 5), // selects 'imgName' from 'https://domain.com/imgName'
                                url,
                            })),
                        ]);
                        if (res.data.video) {
                            setQuotationVideos(res.data.video);
                            setAllVideos([
                                ...res.data.video.map((videoURL: string) => ({
                                    name: videoURL.substring(videoURL.indexOf('.com/') + 5), // selects 'videoName' from 'https://domain.com/videoName'
                                    url: videoURL,
                                })),
                            ]);
                        }

                        // Release images/videos object urls created previously for optimal performance and memory usage management
                        objectURLs.forEach((url) => {
                            URL.revokeObjectURL(url);
                        });
                    })
                    .catch((err) => {
                        toast.error(`Sorry update failed! Error: ${err}`, { id: toastId });
                    })
                    .finally(() => {
                        setIsLoading(false);
                        setProgressCount(0);
                    });
            });
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            Array.from(e.target.files).forEach((file) => {
                if (file) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        const newImage: (typeof allImages)[number] = {
                            name: file.name,
                            url: URL.createObjectURL(file),
                            file,
                        };
                        /**
                         * ⚠️ images urls in the following states must be the same
                         * otherwise some functionalities as "delete image" will not work properly
                         *  */
                        setAllImages((imgs) => [...imgs, newImage]);
                        setQuotationImgs((urls) => [...urls, newImage.url]);
                    };
                    reader.readAsDataURL(file);
                }
            });
        }
    };

    const handleVideoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            Array.from(e.target.files).forEach((file) => {
                if (file) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        const newVideo = {
                            name: file.name,
                            url: URL.createObjectURL(file),
                            file,
                        };
                        /**
                         * ⚠️ videos urls in the following states must be the same
                         * otherwise some functionalities as "delete video" will not work properly
                         *  */
                        setAllVideos((vids) => [...vids, newVideo]);
                        setQuotationVideos((urls) => [...urls, newVideo.url]);
                    };
                    reader.readAsDataURL(file);
                }
            });
        }
    };

    const handleOpenDeleteImgModal = (state: boolean) => {
        setOpenDeleteImgModal(state);
    };

    const handleOpenDeleteVideoModal = (state: boolean) => {
        setOpenDeleteVideoModal(state);
    };

    const handleDeleteImage = () => {
        // Pending UI logic
        const toastLoader = toast.loading('Deleting image in progress...');
        setIsLoading(true);

        /**
         * 🏷️ Check if the image to delete is already updloaded or not
         *
         * Not uploaded images have a field called `file` containing the
         * image blob so already uploaded images does not have this field
         *  */
        const isNewImage = allImages.some((image) => {
            /**
             * What are we doing here?
             *
             * 1. The image in question have the same url field value as
             *    the selected image url (value of `selectedImgForDelete`)
             *
             * 2. This image should have a `file` field (we are casting its value to a boolean)
             */
            return image.url === selectedImgForDelete && !!image.file;
        });

        /**
         * 🏷️ Delete action
         *
         * If the image is not uploaded yet (`isNewImage === true`):
         *      we remove its data from the local states
         *      &
         *      we release its objectURL from the browser (performance & memory usage optimisation)
         * Else (`isNewImage === false`):
         *      we send a delete request to the backend.
         */
        if (isNewImage) {
            const updatedData: typeof allImages = [];
            allImages.forEach((img) => {
                if (img.url !== selectedImgForDelete) {
                    updatedData.push(img);
                } else {
                    URL.revokeObjectURL(img.url);
                }
            });
            setAllImages([...updatedData]);
            setQuotationImgs(() => [...updatedData.map((img) => img.url)]);
            // Action feedback
            toast.success('Image deleted successfully', { id: toastLoader });
            setIsLoading(false);
        } else {
            deleteQuotationImage({ productId: location.state.productId, imageLink: selectedImgForDelete })
                .then((res) => {
                    setAllImages([
                        ...res.data.map((url: string) => ({
                            name: url.substring(url.indexOf('.com/') + 5), // selects 'imgName' from 'https://domain.com/imgName'
                            url,
                        })),
                    ]);
                    setQuotationImgs([...res.data]);
                    toast.success('Image deleted successfully', { id: toastLoader });
                })
                .catch(() => {
                    toast.error('Sorry, something went wrong! Please try again.', { id: toastLoader });
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }

        setOpenDeleteImgModal(false);
    };

    const handleDeleteVideo = () => {
        // Pending UI logic
        const toastLoader = toast.loading('Deleting image in progress...');
        setIsLoading(true);

        /**
         * 🏷️ Check if the image to delete is already updloaded or not
         *
         * Not uploaded images have a field called `file` containing the
         * image blob so already uploaded images does not have this field
         *  */
        const isNewVideo = allVideos.some((video) => {
            /**
             * What are we doing here?
             *
             * 1. The image in question have the same url field value as
             *    the selected image url (value of `selectedImgForDelete`)
             *
             * 2. This image should have a `file` field (we are casting its value to a boolean)
             */
            return video.url === selectedVidForDelete && !!video.file;
        });

        /**
         * 🏷️ Delete action
         *
         * If the video is not uploaded yet (`isNewVideo === true`):
         *      we remove its data from the local states
         *      &
         *      we release its objectURL from the browser (performance & memory usage optimisation)
         * Else (`isNewVideo === false`):
         *      we send a delete request to the backend.
         */
        if (isNewVideo) {
            const updatedData: typeof allVideos = [];
            allVideos.forEach((video) => {
                if (video.url !== selectedVidForDelete) {
                    updatedData.push(video);
                } else {
                    URL.revokeObjectURL(video.url);
                }
            });
            setAllVideos([...updatedData]);
            setQuotationVideos(() => [...updatedData.map((video) => video.url)]);
            // Action feedback
            toast.success('Video deleted successfully', { id: toastLoader });
            setIsLoading(false);
        } else {
            deleteQuotationVideo({ productId: location.state.productId, videoLink: selectedVidForDelete })
                .then((res) => {
                    setAllVideos([
                        ...res.data.map((url: string) => ({
                            name: url.substring(url.indexOf('.com/') + 5), // selects 'vidName' from 'https://domain.com/vidName'
                            url,
                        })),
                    ]);
                    setQuotationVideos([...res.data]);
                    toast.success('Video deleted successfully', { id: toastLoader });
                })
                .catch((e) => {
                    // eslint-disable-next-line no-console
                    toast.error(`Sorry, something went wrong! Please try again. ${e}`);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }

        setOpenDeleteVideoModal(false);
    };

    const decideOnQuotation = (quotationIndex: number, optionIndex: number, accept: boolean) => {
        const quotationDraft = [...quotations];
        quotationDraft[quotationIndex].options[optionIndex] = {
            ...quotationDraft[quotationIndex].options[optionIndex],
            accepted: accept,
            isHidden: !accept,
        };
        setQuotations([...quotationDraft]);
        setNavCache((prev) => ({ ...prev, quotation: quotationDraft }));
    };

    const cancelQuotationDecision = (quotationIdx: number, optionIdx: number) => {
        const quotationDraft = [...quotations];
        delete quotationDraft[quotationIdx].options[optionIdx].accepted;
        delete quotationDraft[quotationIdx].options[optionIdx].isHidden;
        setQuotations([...quotationDraft]);
        setNavCache((prev) => ({ ...prev, quotation: quotationDraft }));
    };

    // get the total price for a signle country (items total price + shipping fee)
    const getCountryTotalPrice = (quotation: IQuotation) => {
        let totalItemsPrice = 0;
        if (quotation.options.every((opt) => !opt.accepted)) {
            quotation.options.forEach((opt) => {
                totalItemsPrice += Number(opt.unitPrice) * Number(opt.quantity);
            });
        } else {
            quotation.options.forEach((opt) => {
                if (opt.accepted || (opt.isHidden === undefined && opt.accepted === undefined)) {
                    totalItemsPrice += Number(opt.unitPrice) * Number(opt.quantity);
                }
            });
        }
        return totalItemsPrice + Number(quotation.options[0]?.totalShippingLineFee || 0);
    };

    const getOptionsTotalQuantity = (options: IProduct['quotation'][number]['options']) => {
        let total = 0;
        options.forEach((option) => {
            if (option.accepted !== false) {
                total += Number(option.quantity);
            }
        });
        return total;
    };

    return (
        <>
            {/* Delete quotation image confirmation modal */}
            <Modal
                open={openDeleteImgModal}
                setOpen={setOpenDeleteImgModal}
                body={
                    <Box
                        sx={{
                            padding: '30px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minWidth: '500px',
                        }}
                    >
                        <Typography sx={{ fontSize: 17, padding: '10px 20px' }}>Delete quotation image? </Typography>
                        <Typography variant="caption">Deleted images can&apos;t be restored!</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '10px',
                                margin: '20px',
                            }}
                        >
                            <Button variant="outlined" color="error" onClick={handleDeleteImage}>
                                Delete
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    handleOpenDeleteImgModal(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                }
            />
            {/* Delete quotation video confirmation modal */}
            <Modal
                open={openDeleteVideoModal}
                setOpen={setOpenDeleteVideoModal}
                body={
                    <Box
                        sx={{
                            padding: '30px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minWidth: '500px',
                        }}
                    >
                        <Typography sx={{ fontSize: 17, padding: '10px 20px' }}>Delete quotation video?</Typography>
                        <Typography variant="caption">Deleted videos can&apos;t be restored!</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '10px',
                                margin: '20px',
                            }}
                        >
                            <Button variant="outlined" color="error" onClick={handleDeleteVideo}>
                                Delete
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    handleOpenDeleteVideoModal(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                }
            />

            {navCache.quotation && (
                <p className="bg-red-500 text-gray-100 rounded-md py-1 px-2 font-bold my-1 w-[100%] text-center">
                    You have unsaved data! Confirm the update or your changes will be lost.
                </p>
            )}
            {productStatus === 69 && (
                <p className="bg-red-500 text-gray-100 rounded-md py-1 px-2 font-bold my-1 w-[100%] text-center">
                    Some quotations were refused! Update your quotations and resubmit.
                </p>
            )}
            {expiredIn && productStatus && [3, 5].includes(productStatus) && (
                <p className="flex justify-center items-center text-red-500 my-1 w-[100%] text-center">
                    {`${expiredIn} left to Expired.`}
                </p>
            )}

            <div className="grid grid-cols-12 dark:bg-[#0D1117]  border border-[#d1d5db] dark:border-[#505050] rounded-md px-2.5 py-2.5 text-center mb-3">
                <div className="col-span-2 ">
                    {quotations[0] && quotations[0].variants.length > 0 ? 'Variants' : 'Product'}
                </div>

                <div>Quantity</div>
                <div>Unit price</div>
                <div>Shipping line</div>
                <div> Shipping fee</div>
                <div>Country</div>
                <div className="col-span-1">Shipping duration estimate</div>
                <div>Unit total price</div>
                <div>Total price</div>
                <div> Action </div>
                <div>Status</div>
            </div>
            <div>
                {quotations.map((quotation, quotationIdx: number) => {
                    return (
                        <div
                            // eslint-disable-next-line react/no-array-index-key
                            key={quotationIdx}
                            className="border border-[#d1d5db] dark:border-[#505050]  rounded-md px-2.5 py-2.5 text-center bg-white dark:bg-[#161B22] "
                        >
                            <div className="flex flex-col gap-2">
                                {quotation.options.map((option, optIndex) => (
                                    <>
                                        <div className="grid grid-cols-12 gap-2 ">
                                            <div className=" rounded-lg border border-[#d1d5db] dark:border-[#505050] col-span-2  flex justify-center items-center bg-white dark:bg-[#0D1117]">
                                                {quotation.variants.length > 0 ? (
                                                    <div>{(quotation.variants[optIndex] as IProduct).name}</div>
                                                ) : (
                                                    <div>{productName ?? '-'}</div>
                                                )}
                                            </div>
                                            <div className=" flex justify-center items-center">{option.quantity}</div>
                                            <div className="flex justify-center items-center">
                                                <TextInput
                                                    placeholder="unit price"
                                                    // sizing="sm"
                                                    defaultValue={
                                                        navCache.quotation &&
                                                        navCache.quotation[quotationIdx].options[optIndex].unitPrice
                                                            ? navCache.quotation[quotationIdx].options[optIndex]
                                                                  .unitPrice
                                                            : option.unitPrice
                                                    }
                                                    name="unitPrice"
                                                    onChange={(e) => {
                                                        handleInputChange(e, quotationIdx, optIndex);
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        backgroundColor: 'borderColor',
                                                        padding: '10.8px',
                                                        marginLeft: '-5px',
                                                        borderTopRightRadius: '7px',
                                                        borderBottomRightRadius: '7px',
                                                    }}
                                                >
                                                    USD
                                                </Typography>
                                            </div>
                                            <div className="text-black flex justify-center items-center ">
                                                {optIndex === Math.round(quotation.options.length / 2) - 1 ? (
                                                    <TextInput
                                                        placeholder="shipping line"
                                                        id={`selectId${optIndex}`}
                                                        name="shippingLine"
                                                        value={option.shippingLine}
                                                        disabled
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            <div className="text-black  flex justify-center items-center ">
                                                {optIndex === Math.round(quotation.options.length / 2) - 1 ? (
                                                    <div className="flex justify-center items-center">
                                                        <TextInput
                                                            placeholder="Total shipping fee"
                                                            // value={totalShippingFee[quotationIdx]}
                                                            defaultValue={option.totalShippingLineFee}
                                                            name="totalShippingLineFee"
                                                            onChange={(e) => {
                                                                handleInputChange(e, quotationIdx, optIndex);
                                                            }}
                                                        />
                                                        <Typography
                                                            sx={{
                                                                backgroundColor: 'borderColor',
                                                                padding: '10.8px',
                                                                marginLeft: '-5px',
                                                                borderTopRightRadius: '7px',
                                                                borderBottomRightRadius: '7px',
                                                            }}
                                                        >
                                                            USD
                                                        </Typography>
                                                    </div>
                                                ) : (
                                                    <div />
                                                )}
                                            </div>
                                            <div className="flex justify-center items-center ">
                                                {optIndex === Math.round(quotation.options.length / 2) - 1 ? (
                                                    <TextInput
                                                        id={`selectId${optIndex}`}
                                                        placeholder="Country"
                                                        name="country"
                                                        value={option.country}
                                                        disabled
                                                    />
                                                ) : (
                                                    <div />
                                                )}
                                            </div>
                                            <div className="flex col-span-1 justify-center items-center ">
                                                {optIndex === Math.round(quotation.options.length / 2) - 1 ? (
                                                    <div className="flex justify-center items-center">
                                                        <TextInput
                                                            placeholder="Shipping duration"
                                                            // value={totalShippingFee[quotationIdx]}
                                                            defaultValue={option.shippingDuration}
                                                            name="shippingDuration"
                                                            onChange={(e) => {
                                                                handleInputChange(e, quotationIdx, optIndex);
                                                            }}
                                                        />
                                                        <Typography
                                                            sx={{
                                                                backgroundColor: 'borderColor',
                                                                padding: '10.8px',
                                                                marginLeft: '-5px',
                                                                borderTopRightRadius: '7px',
                                                                borderBottomRightRadius: '7px',
                                                            }}
                                                        >
                                                            days
                                                        </Typography>
                                                    </div>
                                                ) : (
                                                    <div />
                                                )}
                                            </div>
                                            <div className="flex col-span-1 justify-center items-center ">
                                                {option.unitPrice &&
                                                option.totalShippingLineFee &&
                                                option.quantity &&
                                                !Number.isNaN(
                                                    Number(option.unitPrice) +
                                                        Number(option.totalShippingLineFee) /
                                                            getOptionsTotalQuantity(quotation.options),
                                                ) &&
                                                getOptionsTotalQuantity(quotation.options) !== 0
                                                    ? currencyConverter(
                                                          Number(option.unitPrice) +
                                                              Number(option.totalShippingLineFee) /
                                                                  getOptionsTotalQuantity(quotation.options),
                                                          'USD',
                                                      )
                                                    : ' - '}
                                            </div>
                                            <div className="flex justify-center items-center">
                                                {optIndex === Math.round(quotation.options.length / 2) - 1 ? (
                                                    <>
                                                        <div className="border border-[#d1d5db] dark:border-[#505050] px-3 py-2.5 dark:bg-[#0D1117] rounded-lg">
                                                            {!Number.isNaN(getCountryTotalPrice(quotation)) && (
                                                                <Typography>
                                                                    {getCountryTotalPrice(quotation).toLocaleString(
                                                                        'en-US',
                                                                        {
                                                                            style: 'currency',
                                                                            currency: 'USD',
                                                                        },
                                                                    )}
                                                                </Typography>
                                                            )}
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div />
                                                )}
                                            </div>
                                            <div className="flex justify-center items-center">
                                                {isStatusQuoted ? (
                                                    Object.keys(option).includes('accepted') &&
                                                    option.accepted === true ? (
                                                        <Button
                                                            sx={{ padding: '5px 10px' }}
                                                            title="Cancel accept"
                                                            variant="outlined"
                                                            color="error"
                                                            onClick={() => {
                                                                cancelQuotationDecision(quotationIdx, optIndex);
                                                            }}
                                                        >
                                                            Cancel accept
                                                        </Button>
                                                    ) : Object.keys(option).includes('accepted') &&
                                                      option.accepted === false ? (
                                                        <Button
                                                            sx={{ padding: '5px 10px' }}
                                                            title="Cancel refuse"
                                                            variant="outlined"
                                                            color="error"
                                                            onClick={() => {
                                                                cancelQuotationDecision(quotationIdx, optIndex);
                                                            }}
                                                        >
                                                            Cancel refuse
                                                        </Button>
                                                    ) : (
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                gap: '5px',
                                                            }}
                                                        >
                                                            {/* Accept quotation button */}

                                                            <Tooltip title="Accept Quotation">
                                                                <DoneIcon
                                                                    className="text-green-400 cursor-pointer"
                                                                    onClick={() => {
                                                                        decideOnQuotation(quotationIdx, optIndex, true);
                                                                    }}
                                                                />
                                                            </Tooltip>

                                                            {/* Refuse quotation button */}
                                                            <Tooltip title="Refuse Quotation">
                                                                <CloseIcon
                                                                    className="text-red-500 cursor-pointer"
                                                                    onClick={() => {
                                                                        decideOnQuotation(
                                                                            quotationIdx,
                                                                            optIndex,
                                                                            false,
                                                                        );
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        </Box>
                                                    )
                                                ) : (
                                                    '-'
                                                )}
                                            </div>
                                            <div className="text-black flex justify-center items-center ">
                                                <Tooltip
                                                    title={
                                                        option.accepted
                                                            ? 'Accepted'
                                                            : Object.keys(quotation.options[0]).includes('accepted') &&
                                                                option.accepted === false
                                                              ? 'Refused'
                                                              : 'Pending'
                                                    }
                                                >
                                                    <div
                                                        style={{
                                                            borderRadius: '50%',
                                                            width: 12,
                                                            height: 12,
                                                            background: `${
                                                                option.accepted
                                                                    ? '#93c66e'
                                                                    : option.accepted === false
                                                                      ? '#ff7777'
                                                                      : '#578aff'
                                                            }`,
                                                        }}
                                                    />
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '10px',
                                }}
                            >
                                {quotationErrors.map((error) => {
                                    if (error.country === quotation.country) {
                                        return (
                                            <Alert
                                                // style={{ margin: '10px 0px', width: '40vw' }}
                                                color="failure"
                                                icon={HiInformationCircle}
                                            >
                                                <span>
                                                    <span className="font-medium">Error in </span>
                                                    {`${error.variant ? `variant '${error.variant}', ` : ''}country '${
                                                        error.country
                                                    }': field '${error.field}' ${error.message}`}
                                                </span>
                                            </Alert>
                                        );
                                    }
                                    return null;
                                })}
                            </Box>
                        </div>
                    );
                })}
            </div>

            {/* <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
                {quotationImgs.map((imgLink) => {
                    return (
                        <Box sx={{ position: 'relative' }} key={imgLink}>
                            {imgLink.endsWith('.jpg') || imgLink.endsWith('.png') || imgLink.endsWith('.jpeg') ? (
                                <>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        sx={{
                                            position: 'absolute',
                                            top: 2,
                                            right: 2,
                                            zIndex: 1,
                                            padding: '5px',
                                            borderRadius: 100,
                                            minWidth: 'fit-content',
                                        }}
                                        onClick={() => {
                                            setSelectedImgForDelete(imgLink);
                                            handleOpenDeleteImgModal(true);
                                        }}
                                    >
                                        <DeleteForeverIcon sx={{ fontSize: '21px' }} />
                                    </Button>
                                    <Zoom>
                                        <img
                                            src={imgLink}
                                            alt="quotation"
                                            style={{
                                                objectFit: 'cover',
                                                borderRadius: 8,
                                                overflow: 'hidden',
                                                width: 250,
                                                height: 250,
                                            }}
                                        />
                                    </Zoom>
                                </>
                            ) : imgLink.endsWith('.pdf') ? (
                                <>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        sx={{
                                            position: 'absolute',
                                            top: 2,
                                            right: 2,
                                            zIndex: 1,
                                            padding: '5px',
                                            borderRadius: 100,
                                            minWidth: 'fit-content',
                                        }}
                                        onClick={() => {
                                            setSelectedImgForDelete(imgLink);
                                            handleOpenDeleteImgModal(true);
                                        }}
                                    >
                                        <DeleteForeverIcon sx={{ fontSize: '21px' }} />
                                    </Button>

                                    <img
                                        src={imgLink}
                                        alt="quotation"
                                        style={{
                                            objectFit: 'cover',
                                            borderRadius: 8,
                                            overflow: 'hidden',
                                            width: 250,
                                            height: 250,
                                        }}
                                    />
                                </>
                            ) : (
                                'aze'
                            )}
                        </Box>
                    );
                })}
            </Box> */}

            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2, mt: 2 }}>
                {allImages.map((imgLink) => {
                    return (
                        <Box sx={{ position: 'relative' }} key={imgLink.url}>
                            {imgLink.name.endsWith('.jpg') ||
                            imgLink.name.endsWith('.png') ||
                            imgLink.name.endsWith('.jpeg') ||
                            imgLink.name.endsWith('.webp') ||
                            imgLink.name.endsWith('.svg') ||
                            imgLink.name.endsWith('.svg%2Bxml') ? (
                                <>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        sx={{
                                            position: 'absolute',
                                            top: 2,
                                            right: 2,
                                            zIndex: 1,
                                            padding: '5px',
                                            borderRadius: 100,
                                            minWidth: 'fit-content',
                                        }}
                                        onClick={() => {
                                            setSelectedImgForDelete(imgLink.url);
                                            handleOpenDeleteImgModal(true);
                                        }}
                                    >
                                        <DeleteForeverIcon sx={{ fontSize: '21px' }} />
                                    </Button>
                                    <Zoom>
                                        <img
                                            src={imgLink.url}
                                            alt="quotation"
                                            style={{
                                                objectFit: 'cover',
                                                borderRadius: 8,
                                                overflow: 'hidden',
                                                width: 250,
                                                height: 250,
                                            }}
                                        />
                                    </Zoom>
                                </>
                            ) : imgLink.name.endsWith('.pdf') ? (
                                <>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        sx={{
                                            position: 'absolute',
                                            top: -5,
                                            right: 1,
                                            zIndex: 1,
                                            padding: '5px',
                                            borderRadius: 100,
                                            minWidth: 'fit-content',
                                        }}
                                        onClick={() => {
                                            setSelectedImgForDelete(imgLink.url);
                                            handleOpenDeleteImgModal(true);
                                        }}
                                    >
                                        <DeleteForeverIcon sx={{ fontSize: '21px' }} />
                                    </Button>
                                    <div className="relative">
                                        <img
                                            src={FilePdf}
                                            alt="quotation"
                                            title="Click to view PDF"
                                            className="cursor-pointer "
                                            style={{
                                                objectFit: 'cover',
                                                borderRadius: 8,
                                                overflow: 'hidden',
                                                width: 100,
                                                height: 100,
                                            }}
                                            onClick={() => openPdfInNewTab(imgLink.url, productName)}
                                        />
                                        <IoSearch
                                            onClick={() => openPdfInNewTab(imgLink.url, productName)}
                                            title="Click to view PDF"
                                            className="absolute top-1 cursor-pointer left-4 h-5 w-5 text-red-500 hover:bg-red-200 hover:ring-2 ring-red-200 "
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        sx={{
                                            position: 'absolute',
                                            top: 2,
                                            right: 2,
                                            zIndex: 1,
                                            padding: '5px',
                                            borderRadius: 100,
                                            minWidth: 'fit-content',
                                        }}
                                        onClick={() => {
                                            setSelectedImgForDelete(imgLink.url);
                                            handleOpenDeleteImgModal(true);
                                        }}
                                    >
                                        <DeleteForeverIcon sx={{ fontSize: '21px' }} />
                                    </Button>

                                    <img
                                        src={FileOther}
                                        alt="quotation"
                                        style={{
                                            objectFit: 'cover',
                                            borderRadius: 8,
                                            overflow: 'hidden',
                                            width: 250,
                                            height: 250,
                                        }}
                                    />
                                </>
                            )}
                        </Box>
                    );
                })}
            </Box>
            {quotationImgs && quotationImgs.length > 0 && (
                <div className="mt-1 flex justify-end">
                    <Button onClick={() => downloadFilesAsBase64(quotationImgs, productName)}>
                        Download all Files
                    </Button>
                </div>
            )}

            <Box sx={{ margin: '20px 0px' }}>
                <Box>
                    <ProductImageUpload
                        name="photo"
                        onChange={(e) => {
                            // handleUploadProductImage(e);
                            handleImageChange(e);
                        }}
                    />
                </Box>
                {quotationVideos && (
                    <Box
                        sx={{
                            margin: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            gap: '10px',
                        }}
                    >
                        {quotationVideos.map((videoLink) => {
                            let videoSrc = videoLink;
                            if (
                                !videoSrc.toLowerCase().startsWith('https://') &&
                                !videoSrc.toLowerCase().startsWith('http://') &&
                                !videoSrc.toLowerCase().startsWith('blob')
                            ) {
                                videoSrc = `https://${videoLink}`;
                            }
                            return (
                                <Box sx={{ position: 'relative' }} key={videoLink}>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        sx={{
                                            position: 'absolute',
                                            top: 2,
                                            right: 2,
                                            zIndex: 1,
                                            padding: '5px',
                                            borderRadius: 100,
                                            minWidth: 'fit-content',
                                        }}
                                        onClick={() => {
                                            setSelectedVidForDelete(videoLink);
                                            handleOpenDeleteVideoModal(true);
                                        }}
                                    >
                                        <DeleteForeverIcon sx={{ fontSize: '21px' }} />
                                    </Button>
                                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                                    <video
                                        src={videoSrc}
                                        controls
                                        style={{
                                            width: '349px',
                                            aspectRatio: '16/9',
                                            objectFit: 'cover',
                                            borderRadius: '7px',
                                            overflow: 'hidden',
                                        }}
                                    />
                                </Box>
                            );
                        })}
                    </Box>
                )}
                {quotationVideos && quotationVideos.length > 0 && (
                    <div className="mt-1 flex justify-end">
                        <Button onClick={() => downloadFilesAsBase64(quotationVideos, productName)}>
                            Download all Videos
                        </Button>
                    </div>
                )}
                <VideoUpload
                    multiple
                    name="video"
                    onChange={(e) => {
                        handleVideoChange(e);
                    }}
                />
            </Box>

            {/* Pending UI */}

            {isLoading && <Progress progress={progressCount} size="sm" className="mb-2" />}

            {quotations &&
                quotations.length > 0 &&
                productStatus &&
                productStatus &&
                [1, 2, 69, 3, 42].includes(productStatus) && (
                    <div className="flex justify-end ">
                        <CustomBtn
                            variant="primary"
                            disabled={isLoading || productNotAvailabe}
                            onClick={handleSubmitQuotation}
                            isProcessing={isLoading}
                            className="!bg-main !hover:bg-main-hover !focus:bg-main-focus"
                        >
                            Submit Quotation
                        </CustomBtn>
                    </div>
                )}
        </>
    );
};

export default LogistioBulkQuotation;
