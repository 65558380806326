import { Badge, Modal } from 'flowbite-react';
import { ICallAgent } from 'pages/callAgentList/callAgent.types';
import React, { useState } from 'react';
import { MdOutlineSupportAgent } from 'react-icons/md';
import { IOrder } from 'services/admin/order';
import { IStore } from 'services/admin/store';
import { v4 as uuidv4 } from 'uuid';

export const validActionStatus = [1, 2, 3, 4, 55, 6, 7, 8];
export const actionReason = (param: number) => {
    switch (param) {
        case 1:
            return 'Canceled: Change of mind';
        case 2:
            return 'Canceled: Bought already';
        case 3:
            return 'Canceled: Did not order';
        case 4:
            return 'Canceled: Price not accepted';
        case 6:
            return 'Canceled: Wrong phone number';
        case 55:
            return 'Other';
        case 7:
            return 'Unreachable: No answer';
        case 8:
            return 'Unreachable: Phone off';
        default:
            return 'No action';
    }
};

const CallCenterComment: React.FC<{ data: IOrder }> = ({ data }) => {
    const [openModal, setOpenModal] = useState(false);

    const agentCallFeedback = () => {
        if (data.callCenterFeedback && data.callCenterFeedback.length) {
            return data.callCenterFeedback.map((agentFeedBack) => {
                return <div key={uuidv4()}>{agentFeedBack}</div>;
            });
        }
        return <p>No feedback from call agent </p>;
    };

    return (
        <>
            <Modal dismissible show={openModal} popup onClose={() => setOpenModal(false)}>
                <Modal.Header>Call Center Feedback</Modal.Header>
                <Modal.Body className="!p-1 overflow-y-auto ">
                    <div className=" p-2">
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <tbody>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Order ref
                                    </th>
                                    <td className="px-6 py-4">
                                        <Badge className="w-fit">{data.orderRef || '--'}</Badge>
                                    </td>
                                </tr>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Logistio code
                                    </th>
                                    <td className="px-6 py-4">
                                        <Badge className="w-fit">{data.lgstTrackingCode || '--'}</Badge>
                                    </td>
                                </tr>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Store
                                    </th>
                                    <td className="px-6 py-4">{(data.store as IStore).storeName}</td>
                                </tr>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Assigned Call agent
                                    </th>
                                    <td className="px-6 py-4">
                                        <Badge>
                                            {(data?.callAgent as ICallAgent)?.firstName &&
                                            (data?.callAgent as ICallAgent)?.lastName
                                                ? `${(data?.callAgent as ICallAgent)?.firstName} ${(
                                                      data?.callAgent as ICallAgent
                                                  )?.lastName}`
                                                : 'No assigned call agent'}
                                        </Badge>
                                    </td>
                                </tr>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Calls Attempts
                                    </th>
                                    <td className="px-6 py-4">
                                        <Badge>{data.callCenterAttempt ?? '0'}</Badge>
                                    </td>
                                </tr>
                                <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Order status action reason
                                    </th>
                                    <td className="px-6 py-4">
                                        {data.actionReason ? (
                                            <Badge
                                                color={
                                                    validActionStatus.includes(data.actionReason) ? 'failure' : 'info'
                                                }
                                            >
                                                {actionReason(data.actionReason)}
                                            </Badge>
                                        ) : (
                                            '--'
                                        )}
                                    </td>
                                </tr>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Center Agent feedback
                                    </th>
                                    <td className="px-6 py-4">{agentCallFeedback()}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
            </Modal>
            <MdOutlineSupportAgent className="w-5 h-5 cursor-pointer" onClick={() => setOpenModal(true)} />
        </>
    );
};

export default CallCenterComment;
