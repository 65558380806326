/* eslint-disable react/style-prop-object */
/* eslint-disable no-underscore-dangle */
import { Modal, Tabs } from 'flowbite-react';
import React, { useState } from 'react';
import { FaUserEdit } from 'react-icons/fa';
import StatusAccount from 'components/status/statusAccount';
import { IadminActions } from '../admin.types';
import AdminAccountManagment from '../updateAdmin/adminAccountManagement';
import AdminPersonalInfo from '../updateAdmin/adminPersonalInfo';

const AdminActions = (props: IadminActions) => {
    const [ModalEditAdmin, setModalEditAdmin] = useState(false);

    const EditAdmin = () => {
        setModalEditAdmin(true);
    };
    const EditAdminClose = () => {
        setModalEditAdmin(false);
    };

    const { admin, setRows, currentPage, currentFilter } = props;
    return (
        <>
            <div>
                <FaUserEdit className="h-5 w-5 cursor-pointer" onClick={EditAdmin} />
            </div>
            <Modal dismissible show={ModalEditAdmin} popup onClose={EditAdminClose}>
                <Modal.Header>
                    <StatusAccount type="others" label={admin.status} />
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        aria-label="Tabs with icons"
                        style="underline"
                        theme={{
                            tablist: {
                                tabitem: {
                                    base: 'flex items-center justify-center p-4 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 ',
                                },
                            },
                        }}
                    >
                        <Tabs.Item title="Account Management">
                            <AdminAccountManagment
                                status={admin.status}
                                id={admin._id}
                                setRows={setRows}
                                currentPage={currentPage}
                                currentFilter={currentFilter}
                            />
                        </Tabs.Item>
                        <Tabs.Item title="Personal Information">
                            <AdminPersonalInfo
                                admin={admin}
                                setRows={setRows}
                                currentPage={currentPage}
                                currentFilter={currentFilter}
                            />
                        </Tabs.Item>
                    </Tabs>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default AdminActions;
