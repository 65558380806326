import { ResellerProduct } from 'pages/reseller/reseller.types';
import { IPaginationData } from 'utils/Interfaces/types';
import axios from 'utils/axios';
import { getBearerToken } from 'utils/helpers/auth';

export const addResellerProduct = async (
    data: any,
    progressConfig?: {
        onUploadProgress: (ProgressEvent: any) => void;
        onDownloadProgress: (ProgressEvent: any) => void;
    },
) => {
    const config: {
        headers: {
            Authorization: string;
        };
        onUploadProgress?: (progressEvent: any) => void;
        onDownloadProgress?: (progressEvent: any) => void;
    } = getBearerToken();

    if (progressConfig?.onUploadProgress) {
        config.onUploadProgress = progressConfig.onUploadProgress;
    }
    if (progressConfig?.onDownloadProgress) {
        config.onDownloadProgress = progressConfig.onDownloadProgress;
    }
    const newResellerProduct = await axios.post(`/reseller/products`, data, getBearerToken());
    return newResellerProduct;
};
export const newDraftResellerProduct = async (
    data: any,
    progressConfig?: {
        onUploadProgress: (ProgressEvent: any) => void;
        onDownloadProgress: (ProgressEvent: any) => void;
    },
) => {
    const config: {
        headers: {
            Authorization: string;
        };
        onUploadProgress?: (progressEvent: any) => void;
        onDownloadProgress?: (progressEvent: any) => void;
    } = getBearerToken();

    if (progressConfig?.onUploadProgress) {
        config.onUploadProgress = progressConfig.onUploadProgress;
    }
    if (progressConfig?.onDownloadProgress) {
        config.onDownloadProgress = progressConfig.onDownloadProgress;
    }
    const newResellerProduct = await axios.post(`/reseller/products/draft`, data, getBearerToken());
    return newResellerProduct;
};
export const resellerCategories = [
    'Home Decor',
    'Pet Supplies',
    'Health Sports',
    'Fashion & Beauty',
    'Tools & Maintenance',
    'Toys Kids Education',
    'Outdoor Cars',
];
export const uploadMedia = async (
    data: any,
    progressConfig?: {
        onUploadProgress: (ProgressEvent: any) => void;
        onDownloadProgress: (ProgressEvent: any) => void;
    },
) => {
    const config: {
        headers: {
            Authorization: string;
        };
        onUploadProgress?: (progressEvent: any) => void;
        onDownloadProgress?: (progressEvent: any) => void;
    } = getBearerToken();

    if (progressConfig?.onUploadProgress) {
        config.onUploadProgress = progressConfig.onUploadProgress;
    }
    if (progressConfig?.onDownloadProgress) {
        config.onDownloadProgress = progressConfig.onDownloadProgress;
    }
    const newResellerProduct = await axios.post<{
        uploderImages?: string[];
        uplodervideos?: string[];
        uploaderFiles?: string[];
    }>(`/common/uploader`, data, getBearerToken());
    return newResellerProduct;
};
interface IResellerProductData {
    resellerProducts: IPaginationData<ResellerProduct>;
    avg?: Record<string, Record<string, { daily: number; weekly: number }>>;
}
export const getProducts = async (pagination: any) => {
    const config = getBearerToken();
    const response = await axios.post<IResellerProductData>(`/reseller/products/filter`, pagination, config);

    return response;
};
export const updateResellerProduct = async (
    data: any,
    productId: string,
    progressConfig?: {
        onUploadProgress: (ProgressEvent: any) => void;
        onDownloadProgress: (ProgressEvent: any) => void;
    },
) => {
    const config: {
        headers: {
            Authorization: string;
        };
        onUploadProgress?: (progressEvent: any) => void;
        onDownloadProgress?: (progressEvent: any) => void;
    } = getBearerToken();

    if (progressConfig?.onUploadProgress) {
        config.onUploadProgress = progressConfig.onUploadProgress;
    }
    if (progressConfig?.onDownloadProgress) {
        config.onDownloadProgress = progressConfig.onDownloadProgress;
    }

    const response = await axios.put(`/reseller/products/${productId}`, data, config);
    if (response.statusText === 'OK' && response.data.productHistoryId) {
        // socket.emit('productStatusUpdate', {
        //     // eslint-disable-next-line no-underscore-dangle
        //     user: getCurrentUser()._id,
        //     productHistoryId: response.data.productHistoryId,
        // });
    }
    // 🏷️ Return an axios response object w/out the product history id
    return { ...response, data: response.data.updatedProduct };
};
export const confirmResellerProduct = async (
    data: any,
    productId: string,
    progressConfig?: {
        onUploadProgress: (ProgressEvent: any) => void;
        onDownloadProgress: (ProgressEvent: any) => void;
    },
) => {
    const config: {
        headers: {
            Authorization: string;
        };
        onUploadProgress?: (progressEvent: any) => void;
        onDownloadProgress?: (progressEvent: any) => void;
    } = getBearerToken();

    if (progressConfig?.onUploadProgress) {
        config.onUploadProgress = progressConfig.onUploadProgress;
    }
    if (progressConfig?.onDownloadProgress) {
        config.onDownloadProgress = progressConfig.onDownloadProgress;
    }

    const response = await axios.put(`/reseller/products/confirm/${productId}`, data, config);
    if (response.statusText === 'OK' && response.data.productHistoryId) {
        // socket.emit('productStatusUpdate', {
        //     // eslint-disable-next-line no-underscore-dangle
        //     user: getCurrentUser()._id,
        //     productHistoryId: response.data.productHistoryId,
        // });
    }
    // 🏷️ Return an axios response object w/out the product history id
    return { ...response, data: response.data.updatedProduct };
};
export const getProduct = async (productId: string) => {
    const config = getBearerToken();
    const response = await axios.get<ResellerProduct>(`/reseller/products/${productId}`, config);

    return response;
};
export const deleteMedia = async (data: { productId: string; link: string; type: 'images' | 'files' | 'videos' }) => {
    const config = getBearerToken();
    const response = await axios.put<string[]>(
        `/reseller/products/media/${data.productId}`,
        { link: data.link, type: data.type },
        config,
    );
    return response;
};
export const deleteProduct = async (productId: string) => {
    const config = getBearerToken();
    const response = await axios.delete<string>(`/reseller/products/${productId}`, config);
    return response;
};
