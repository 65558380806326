/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { getCustomers } from 'services/admin/customer';
import UseDocumentTitle from 'utils/dynamicTitle/dynamicTitle';
import { Modal, Select, TextInput } from 'flowbite-react';
import { AiOutlineSearch } from 'react-icons/ai';
import { HiUserAdd } from 'react-icons/hi';
import CustomBtn from 'components/customButton/customBtn';
import useDebouncedValue from 'utils/hooks/useDebouncedValue';
import toast from 'react-hot-toast';
import { columns, statusDisplay } from './customerTableActions/customersElements';
import AddCustomerModal from './addCustomerModal/addCustomerModal';
import DataTable from '../../components/tables/tables';
import { ICustomer } from './customer.types';

interface Ipagination {
    page: number;
    limit: number;
}

interface Ifilter {
    status?: number;
    fullName?: string;
    email?: string;
}
interface Imeta {
    totalDocs: number;
    limit: number;
    totalPages: number;
    page: number;
    pagingCounter: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    prevPage: number | null;
    nextPage: number | null;
}
const Customers = () => {
    UseDocumentTitle('Customers');
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [costumers, setCustomers] = useState<Array<ICustomer>>([]);
    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 20,
    });
    const [filter, setFilter] = useState<Ifilter>({});
    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });
    // const [customer, setCustomer] = useState('');
    // useEffect(() => {
    //     if (customer) getCustomers({ limit: 1000, email: customer }).then((res) => setCustomers(res.data.docs));
    //     else getCustomers({ limit: 1000 }).then((res) => setCustomers(res.data.docs));
    // }, [customer]);

    const handlePaginationChange = (value: { [key: string]: any }) => {
        setPagination((prevPagination) => {
            const data = { ...prevPagination, ...value };
            if (value.limit) {
                data.page = 1;
            }
            return data;
        });
        setMeta((prev) => ({ ...prev, page: -1 }));
    };

    function handleChange(event: any) {
        setIsLoading(true);

        if (event.target.value !== '') {
            setFilter({ ...filter, ...{ [event.target.name]: event.target.value } });
        } else {
            setFilter({ ...filter, ...{ [event.target.name]: undefined } });
        }
        setPagination({ ...pagination, page: 1 });
        setMeta({ ...meta, page: -1 });
    }

    const debouncedPagination = useDebouncedValue(pagination, 500);
    const debouncedFilter = useDebouncedValue(filter, 500);
    useEffect(() => {
        const feedbackToast = toast.loading('Loading data...');
        setIsLoading(true);
        const queryOptions = { ...debouncedPagination, ...debouncedFilter };
        getCustomers(queryOptions)
            .then((result) => {
                toast.dismiss(feedbackToast);
                const updatedDocs = result.data.docs.map(
                    (customer: { accountManager: any[]; accountManagerNames: string }) => {
                        const accountManagers = customer.accountManager || [];
                        const managerNames = accountManagers
                            .map((manager: { firstName: string; lastName?: string }) => {
                                const lastNameInitial = manager.lastName ? manager.lastName.charAt(0) : '';
                                return `${manager.firstName}${lastNameInitial}`;
                            })
                            .join(', ');
                        return { ...customer, accountManagerNames: managerNames };
                    },
                );
                setCustomers(updatedDocs);
                setIsLoading(false);
                setMeta({
                    hasNextPage: result.data.hasNextPage,
                    hasPrevPage: result.data.hasPrevPage,
                    limit: result.data.limit,
                    nextPage: result.data.nextPage,
                    page: result.data.page,
                    pagingCounter: result.data.pagingCounter,
                    prevPage: result.data.prevPage,
                    totalDocs: result.data.totalDocs,
                    totalPages: result.data.totalPages,
                });
            })
            .catch((err) => {
                setIsLoading(false);
                toast.error((err as any)?.response?.data?.errors?.message || 'Unable to load data.', {
                    id: feedbackToast,
                });
            });
    }, [debouncedFilter, debouncedPagination]);

    return (
        <>
            <Modal dismissible show={open} popup onClose={() => setOpen(false)}>
                <Modal.Header>Add New Seller</Modal.Header>
                <Modal.Body className="!p-0">
                    <AddCustomerModal setOpen={setOpen} setCustomers={setCustomers} />
                </Modal.Body>
            </Modal>

            <div>
                <section className="dark:bg-gray-900 flex items-center my-2">
                    <div className=" relative w-full">
                        <div className="relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
                            <div className="flex flex-col items-center  p-2 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
                                <label htmlFor="table-search-firstName" className="sr-only">
                                    Search
                                </label>
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg
                                            className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                            aria-hidden="true"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                    <input
                                        type="text"
                                        id="table-search-firstName"
                                        className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-60 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Enter name to search"
                                        name="fullName"
                                        onChange={handleChange}
                                    />
                                </div>
                                <TextInput
                                    icon={AiOutlineSearch}
                                    id="email4"
                                    placeholder="Search by email"
                                    required
                                    type="email"
                                    name="email"
                                    onChange={(e) => handleChange(e)}
                                />

                                <div id="select">
                                    <Select
                                        id="operationType"
                                        required
                                        name="status"
                                        value={filter.status?.toString()}
                                        onChange={(e) => handleChange(e)}
                                    >
                                        <option value="">All Status</option>
                                        <option value="1">Pending</option>
                                        <option value="2">Approved</option>
                                        <option value="3">disabled</option>
                                    </Select>
                                </div>

                                <CustomBtn
                                    className=" md:!ml-auto"
                                    variant="primary"
                                    onClick={() => {
                                        setOpen(true);
                                    }}
                                >
                                    <HiUserAdd className="mr-2 h-5 w-5" />
                                    Add a Seller
                                </CustomBtn>
                            </div>
                        </div>
                    </div>
                </section>
                <DataTable
                    rows={costumers}
                    columns={columns}
                    icons={statusDisplay}
                    setRows={setCustomers}
                    pageState={{
                        isLoading,
                        total: meta.totalDocs,
                        pageSize: pagination.limit,
                        page: pagination.page,
                        count: meta.totalPages,
                    }}
                    setPageState={handlePaginationChange}
                    filterState={filter}
                />
            </div>
        </>
    );
};

export default Customers;
