/* eslint-disable jsx-a11y/label-has-associated-control */
import CustomBtn from 'components/customButton/customBtn';
import CustomError from 'components/customError/CustomError';
import { Avatar, Spinner } from 'flowbite-react';
import { Form, Formik } from 'formik';
import { AllUserProps } from 'pages/profileSetting/profileSettings.types';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { getAccountManager, updateAccountManager } from 'services/accountManager/accountManagerProfile';
import { profilePicture } from 'utils/validations/accountInformationValidation';

const ProfilePicture: React.FC<{
    id: string;
    profImg: any;
    setAccountManager: React.Dispatch<React.SetStateAction<AllUserProps | undefined>>;
}> = ({ profImg, id, setAccountManager }) => {
    const [verifImage, setVerifImage] = useState<any>([]);
    const [urlImage, setUrlImage] = useState('');
    const [imgLoading, setImgLoading] = useState(false);

    const profilePic = (values: any) => {
        setImgLoading(true);
        updateAccountManager(id, values)
            .then((res) => {
                setUrlImage(res.data.accountManager.profileImage);
                localStorage.setItem(
                    'user',
                    JSON.stringify({
                        firstName: res.data.accountManager.firstName,
                        lastName: res.data.accountManager.lastName,
                        email: res.data.accountManager.email,
                        phone: res.data.accountManager.phone,
                        profileImage: res.data.accountManager.profileImage,
                    }),
                );
                getAccountManager(id).then((result) => {
                    setAccountManager(result.data);
                });
                toast.success('Personal Information updated successfully');

                setImgLoading(false);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message);
            });
    };

    return (
        <>
            <Formik
                initialValues={{
                    profileImage: '',
                }}
                onSubmit={(values, onSubmitProps) => {
                    const formData = new FormData();

                    if (verifImage && verifImage.file) {
                        formData.append(verifImage.name, verifImage.file, verifImage.targetKey);
                    }

                    profilePic(formData);

                    onSubmitProps.setSubmitting(false);
                    // onSubmitProps.resetForm();
                }}
                validationSchema={profilePicture}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit} placeholder="">
                        <div className="cursor-pointer ">
                            <input
                                className="cursor-pointer "
                                type="file"
                                id="upload"
                                hidden
                                accept="image/png, image/svg, image/jpg, image/jpeg, image/*"
                                name="profileImage"
                                onChange={(e) => {
                                    const file = e.target.files instanceof FileList && e.target.files[0];
                                    if (file) {
                                        formik.setFieldValue('profileImage', file);
                                        const reader = new FileReader();
                                        setUrlImage(URL.createObjectURL(file));
                                        // eslint-disable-next-line @typescript-eslint/no-shadow
                                        reader.onload = () => {
                                            setVerifImage({
                                                file,
                                                name: file.name,
                                                url: URL.createObjectURL(file),
                                                targetKey: 'profileImage',
                                            });
                                        };
                                        reader.readAsDataURL(file);
                                    }
                                }}
                            />
                            <label htmlFor="upload" className="cursor-pointer ">
                                {profImg && (
                                    <Avatar
                                        // rounded
                                        // placeholderInitials="aze aze"
                                        img={urlImage && urlImage.length > 0 ? urlImage : profImg}
                                        // img={urlImage}
                                        size="xl"
                                        // bordered
                                        color="light"
                                    />
                                )}
                            </label>
                        </div>
                        <CustomError name="profileImage" />
                        <CustomBtn
                            className="w-full mt-2"
                            variant="success"
                            outline
                            type="submit"
                            disabled={imgLoading || formik.isSubmitting || urlImage.length === 0}
                        >
                            {imgLoading ? (
                                <>
                                    <Spinner aria-label="uploading" className="mr-1" /> Uploading
                                </>
                            ) : (
                                'Upload Photo'
                            )}
                        </CustomBtn>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ProfilePicture;
