import React from 'react';

const Widget: React.FC<{
    statPourcentage?: number;
    inputValue: number | string;
    title: string;
    loading?: boolean;
    icon: React.ReactNode;
    bgcolor?: string;
    percText?: string;
    isPerc?: boolean;
    footer?: boolean;
}> = ({
    statPourcentage,
    inputValue,
    title,
    loading,
    icon,
    bgcolor = 'white',
    percText = 'out of total orders',
    isPerc,
    footer,
}) => {
    const formatValue = (val: number = 0, isperc: boolean = false) => (!isperc || val % 2 === 0 ? val : val.toFixed(2));
    return (
        <>
            {loading ? (
                <div className=" min-w-0 bg-white dark:bg-gray-700 sm:rounded-lg overflow-hidden py-2 flex flex-col gap-2 shadow-sm border border-gray-100 dark:border-gray-700 animate-pulse">
                    <div className="grid grid-cols-3">
                        <div className=" flex justify-center items-center ">
                            <div className=" bg-gray-300 rounded-full dark:bg-indigo-200  h-16 w-16 " />
                        </div>
                        <div className="col-span-2 flex flex-col justify-center items-left">
                            <div className=" flex justify-start">
                                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-indigo-200 w-20 mb-4" />
                            </div>
                            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-indigo-200 w-36 " />
                        </div>
                    </div>
                    <div>
                        <div className="flex justify-center">
                            <div className="h-2.5 bg-gray-400 rounded-full dark:bg-indigo-200 w-48" />
                        </div>
                    </div>
                </div>
            ) : (
                <div className=" bg-white  rounded-2xl shadow-lg dark:bg-gray-800 hover:shadow-lg hover:scale-105  pb-0">
                    <div className="w-full flex gap-4 items-center m-4">
                        <div
                            className={`w-[87px] h-[87px] bg-${bgcolor}-200 flex justify-center items-center rounded-xl`}
                        >
                            {icon}
                        </div>
                        <div className="flex-1 grow w-full">
                            <div className=" font-poppin font-medium  text-base text-[#A3AED0]">{title}</div>

                            <div className="flex justify-between items-center w-full">
                                <div className="w-36 h-8 ext-[#2B3674] dark:text-[#6874F5] text-2xl font-semibold font-poppin">
                                    {formatValue(Number(inputValue))} {isPerc && !!inputValue ? '%' : ''}
                                </div>

                                {/* <div className=" text-blue-700 text-sm font-medium font-poppins leading-tight">
                                    {!statPourcentage && statPourcentage !== 0
                                        ? '∞'
                                        : `${statPourcentage.toFixed(2)}  %`}
                                </div> */}
                            </div>

                            {/* <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700">
                                <div
                                    className="bg-blue-600 h-1.5 rounded-full"
                                    style={{
                                        width: `${statPourcentage <= 100 ? statPourcentage.toFixed(2) : 100}%`,
                                    }}
                                />
                            </div> */}
                        </div>
                    </div>
                    {footer && (
                        <div className="w-full h-[35px] flex px-4 items-center bg-gray-300 rounded-b-[10px] dark:text-gray-700">
                            <span className="bg-purple-200 text-purple-600 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-purple-900 dark:text-purple-300">
                                {formatValue(statPourcentage, true)}%
                            </span>
                            <span>{percText}</span>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default Widget;
