/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import { Box } from '@mui/material';
import AutoComplete from 'components/autocomplete/autoComplete';
import DataTable from 'components/tables/tables';
import { TextInput } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { AiOutlineSearch } from 'react-icons/ai';
import { MdOutlineShoppingCart } from 'react-icons/md';
import { getCustomers } from 'services/admin/customer';
import { Ipagination } from 'services/admin/product';
import { getStocks, getStocksProducts, IStock } from 'services/admin/stock';
import UseDocumentTitle from 'utils/dynamicTitle/dynamicTitle';
import { vnlinWarehouses } from 'utils/helpers/warehouses';
import useDebouncedValue from 'utils/hooks/useDebouncedValue';
import { IProduct } from 'utils/Interfaces/products.interface';
import { columns } from './StockListElements';

interface Imeta {
    totalDocs: number;
    limit: number;
    totalPages: number;
    page: number;
    pagingCounter: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    prevPage: number | null;
    nextPage: number | null;
}
const Stock = () => {
    UseDocumentTitle('Stock');
    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });
    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 20,
    });
    const [filter, setFilter] = useState<{
        customer?: string;
        product?: string;
        warehouse?: string;
        sku?: string;
    }>({});
    const [customers, setCustomers] = useState([]);
    const [customer] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [logistioProducts, setLogistioProducts] = useState<IProduct[]>([]);
    const [logistioProduct] = useState('');
    const [logistioProductId, setLogistioProductId] = useState('');
    const [warehouseIdFilter, setWarehouseIdFilter] = useState('');
    const [skuFilter, setSkuFilter] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // const [deliveryQuantitiesPerVariant, setDeliveryQuantitiesPerVariant] = useState<any>();
    // const [productDeliveryTotalQuantity, setProductDeliveryTotalQuantity] = useState<any>();
    const [stockedProducts, setStockedProducts] = useState<IStock[]>([]);
    // const [stockedQuantity, setStockedQuantity] = useState(0);
    const handlePaginationChange = (value: Partial<Ipagination>) => {
        const data: Partial<Ipagination> = { ...value };

        // reset page number on limit change
        if (value.limit) {
            data.page = 1;
        }
        setPagination((prev) => ({ ...prev, ...data }));
    };

    useEffect(() => {
        if (customer)
            getCustomers({
                limit: 1000,
                firstName: customer.split(' ')[0],
                lastName: customer.split(' ')[1] ?? null,
            }).then((res) => {
                setCustomers(res.data.docs);
            });
        else
            getCustomers({ limit: 1000 }).then((res) => {
                setCustomers(res.data.docs);
            });
    }, [customer]);

    useEffect(() => {
        getStocksProducts().then((res) => {
            setLogistioProducts(res.data);
        });
    }, [logistioProduct, customerId]);

    useEffect(() => {
        let shouldUpdate = false;
        const draft = { ...filter };
        if ((filter?.customer || '') !== customerId) {
            draft.customer = customerId;
            shouldUpdate = true;
        }
        if ((filter?.product || '') !== logistioProductId) {
            draft.product = logistioProductId;
            shouldUpdate = true;
        }
        if ((filter?.warehouse || '') !== warehouseIdFilter) {
            draft.warehouse = warehouseIdFilter;
            shouldUpdate = true;
        }
        if ((filter?.sku || '') !== skuFilter) {
            draft.sku = skuFilter.trim() || undefined;
            shouldUpdate = true;
        }

        if (shouldUpdate) {
            setFilter({ ...draft });
            setPagination({ ...pagination, page: 1 });
        }
    }, [customerId, logistioProductId, warehouseIdFilter, skuFilter]);
    /**
     * A closure used to revalidate stocks listing data after update
     * refetches the stocks and maintains the current filter and pagination data
     */
    // const revalidateStocks = () => {
    //     const stockFilters: {
    //         page: number;
    //         limit: number;
    //         customer?: string;
    //         product?: string;
    //         warehouse?: string;
    //         sku?: string;
    //     } = {
    //         page: pagination.page,
    //         limit: pagination.limit,
    //     };

    //     if (logistioProductId) {
    //         stockFilters.product = logistioProductId;
    //         stockFilters.page = 1;
    //     }

    //     if (customerId) {
    //         stockFilters.customer = customerId;
    //         stockFilters.page = 1;
    //     }

    //     if (warehouseIdFilter) {
    //         stockFilters.warehouse = warehouseIdFilter;
    //         stockFilters.page = 1;
    //     }

    //     if (skuFilter) {
    //         stockFilters.sku = skuFilter;
    //         stockFilters.page = 1;
    //     }
    //     getStocks({ ...stockFilters })
    //         .then((res) => {
    //             setStockedProducts(res.data.docs);
    //             setTotalPages(res.data.totalPages);
    //             setPagination((prev) => ({
    //                 ...prev,
    //                 page: res.data.page,
    //                 limit: res.data.limit,
    //             }));
    //         })
    //         .catch(() => {
    //             toast.error('Something went wrong');
    //         });
    // };

    const debouncedPagination = useDebouncedValue(pagination, 500);
    const debouncedFilter = useDebouncedValue(filter, 500);
    useEffect(() => {
        const toastId = toast.loading('Loading stocks data...');
        setIsLoading(true);
        getStocks({ ...debouncedPagination, ...debouncedFilter })
            .then((res) => {
                toast.dismiss(toastId);
                setStockedProducts(res.data.docs);

                setMeta({
                    hasNextPage: res.data?.hasNextPage,
                    hasPrevPage: res.data?.hasPrevPage,
                    limit: res.data?.limit,
                    nextPage: res.data?.nextPage,
                    page: res.data?.page,
                    pagingCounter: res.data?.pagingCounter,
                    prevPage: res.data?.prevPage,
                    totalDocs: res.data?.totalDocs,
                    totalPages: res.data?.totalPages,
                });
                setPagination((prev) => ({
                    ...prev,
                    page: res.data.page,
                    limit: res.data.limit,
                }));
            })
            .catch(() => {
                toast.error('Please enter the SKU number for the product', { id: toastId });
            })
            .finally(() => setIsLoading(false));
    }, [debouncedPagination.page, debouncedPagination.limit, debouncedFilter]);

    return (
        <Box className="bg-white dark:bg-gray-900 min-h-[calc(100vh-80px)] px-8 p-5">
            <div className="flex ">
                <MdOutlineShoppingCart size={32} /> <span className="font-medium text-2xl">Stock list</span>
            </div>
            <section className="dark:bg-gray-900 flex items-center my-2">
                <div className=" relative w-full flex p-3 justify-between">
                    <div className="flex justify-start gap-3 ">
                        {/* Warehouses filter */}

                        <AutoComplete
                            key="id"
                            styles={{ height: '42px', maxWidth: 250 }}
                            placeholder="Warehouses"
                            options={[{ id: undefined, name: 'All' }, ...Object.values(vnlinWarehouses)]}
                            getOptionLabel={(option: any) => option.name}
                            // eslint-disable-next-line no-underscore-dangle
                            onChange={(option: any) => {
                                // setCustomer(option.email as string);
                                setWarehouseIdFilter(option.id);
                            }}
                        />
                        <AutoComplete
                            key="id"
                            styles={{ height: '42px', maxWidth: 250 }}
                            placeholder="Sellers"
                            options={[{ _id: undefined, firstName: 'All', lastName: 'Customers' }, ...customers]}
                            onChange={(newValue: any) => {
                                setCustomerId(newValue._id);
                            }}
                            getOptionLabel={(option: any) =>
                                option?.lastName && option?.firstName
                                    ? `${option.firstName} ${option.lastName}`
                                    : option?.email
                            }
                        />
                        <AutoComplete
                            key="id"
                            styles={{ height: '42px', maxWidth: 250 }}
                            placeholder="Products"
                            options={[{ _id: undefined, name: 'All' }, ...logistioProducts]}
                            onChange={(newValue: any) => {
                                setLogistioProductId(newValue._id);
                            }}
                            getOptionLabel={(option: any) => option.name}
                        />
                    </div>
                    {/* SKU filter  NEED CHANGING as data changed */}
                    <TextInput
                        icon={AiOutlineSearch}
                        id="skuSearch"
                        placeholder="Search by SKU"
                        name="sku"
                        onChange={(e) => {
                            setSkuFilter(e.target.value);
                        }}
                    />
                </div>
            </section>
            <div className="p-3">
                <DataTable
                    rows={stockedProducts}
                    columns={columns}
                    setRows={setStockedProducts}
                    pageState={{
                        isLoading,
                        total: meta.totalDocs,
                        pageSize: pagination.limit,
                        page: pagination.page,
                        count: meta.totalPages,
                    }}
                    setPageState={handlePaginationChange}
                    filterState={filter}
                />
            </div>
        </Box>
    );
};

export default Stock;
