import * as Yup from 'yup';

export const updateResellerStockSchema = (initialQuantity: number) =>
    Yup.object().shape({
        boxes: Yup.array()
            .of(
                Yup.object().shape({
                    // ctns: Yup.string().typeError('Field must be a number').required('Required'),
                    productQuantity: Yup.number()
                        .integer('Must be an integer')
                        .min(1, 'Minimum value is 1')
                        .typeError('Field must be a number')
                        .required('Required'),
                    height: Yup.number()
                        .min(0.01, 'Minimum value is 0.01')
                        .typeError('Field must be a number')
                        .required('Required'),
                    weight: Yup.number()
                        .min(0.01, 'Minimum value is 0.01')
                        .typeError('Field must be a number')
                        .required('Required'),
                    width: Yup.number()
                        .min(0.01, 'Minimum value is 0.01')
                        .typeError('Field must be a number')
                        .required('Required'),
                    length: Yup.number()
                        .min(0.01, 'Minimum value is 0.01')
                        .typeError('Field must be a number')
                        .required('Required'),
                    // boxNumber: Yup.number()
                    //     .integer('Must be an integer')
                    //     .min(1, 'Minimum value is 1')
                    //     .typeError('Field must be a number')
                    //     .required('Required'),
                }),
            )
            .test({
                message: `Total quantity in diffrent boxes should be equal to initial quantity`,
                test: (rows) => {
                    const total =
                        rows?.reduce(
                            (accumulator, currentValue) => accumulator + (currentValue?.productQuantity || 0),
                            0,
                        ) || 0;

                    return total === Number(initialQuantity);
                },
            })
            .required('Required'),
        // noofPieces: Yup.number()
        //     .integer('Must be an integer')
        //     .min(1, 'Minimum value is 1')
        //     .typeError('Field must be a number')
        //     .required('Required'),

        is_solely: Yup.string().required(),
        battery: Yup.number().required(),
        plateform: Yup.string().required('Required').typeError('Field must be a string'),
        transportation: Yup.string().required('Required'),
        logo: Yup.number().required('Required'),
        documents: Yup.string(),
    });
export const refillResellerStockSchema = Yup.object().shape({
    boxes: Yup.array()
        .of(
            Yup.object().shape({
                // ctns: Yup.string().typeError('Field must be a number').required('Required'),
                productQuantity: Yup.number()
                    .integer('Must be an integer')
                    .min(1, 'Minimum value is 1')
                    .typeError('Field must be a number')
                    .required('Required'),
                height: Yup.number()
                    .min(0.01, 'Minimum value is 0.01')
                    .typeError('Field must be a number')
                    .required('Required'),
                weight: Yup.number()
                    .min(0.01, 'Minimum value is 0.01')
                    .typeError('Field must be a number')
                    .required('Required'),
                width: Yup.number()
                    .min(0.01, 'Minimum value is 0.01')
                    .typeError('Field must be a number')
                    .required('Required'),
                length: Yup.number()
                    .min(0.01, 'Minimum value is 0.01')
                    .typeError('Field must be a number')
                    .required('Required'),
                // boxNumber: Yup.number()
                //     .integer('Must be an integer')
                //     .min(1, 'Minimum value is 1')
                //     .typeError('Field must be a number')
                //     .required('Required'),
            }),
        )
        .test({
            message: `Total quantity in diffrent boxes should be greater than zero`,
            test: (rows) => {
                const total =
                    rows?.reduce(
                        (accumulator, currentValue) => accumulator + (currentValue?.productQuantity || 0),
                        0,
                    ) || 0;

                return total > 0;
            },
        })
        .required('Required'),
    // noofPieces: Yup.number()
    //     .integer('Must be an integer')
    //     .min(1, 'Minimum value is 1')
    //     .typeError('Field must be a number')
    //     .required('Required'),

    is_solely: Yup.string().required(),
    battery: Yup.number().required(),
    plateform: Yup.string().required('Required').typeError('Field must be a string'),
    transportation: Yup.string().required('Required'),
    logo: Yup.number().required('Required'),
    documents: Yup.string(),
});
