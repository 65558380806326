import React from 'react';
import { openPdfInNewTab } from 'utils/helpers/productFilesDownloader';
import Zoom from 'react-medium-image-zoom';
import FilePdf from '../../assets/pdf.png';

const ImageProofVerification: React.FC<{
    verifImage: any;
}> = ({ verifImage }) => {
    if (
        verifImage?.verificationImage &&
        (verifImage.verificationImage.endsWith('.jpg') ||
            verifImage.verificationImage.endsWith('.png') ||
            verifImage.verificationImage.endsWith('.jpeg') ||
            verifImage.verificationImage.endsWith('.webp') ||
            verifImage.verificationImage.endsWith('.svg') ||
            verifImage?.verificationImage.endsWith('.svg%2Bxml'))
    ) {
        return (
            <Zoom>
                <img
                    src={verifImage.verificationImage}
                    alt="productImg"
                    style={{
                        objectFit: 'cover',
                        borderRadius: 8,
                        overflow: 'hidden',
                        width: 250,
                        height: 250,
                    }}
                />
            </Zoom>
        );
    }
    if (verifImage?.verificationImage && verifImage?.verificationImage.endsWith('.pdf')) {
        return (
            <button
                type="button"
                style={{
                    all: 'unset',
                }}
                onClick={() => openPdfInNewTab(verifImage.verificationImage, verifImage.accountName)}
            >
                <img
                    src={FilePdf}
                    alt={verifImage.accountName}
                    style={{
                        cursor: 'pointer',
                        objectFit: 'cover',
                        borderRadius: 8,
                        overflow: 'hidden',
                        width: 100,
                        height: 100,
                    }}
                />
            </button>
        );
    }
    return null;
};

export default ImageProofVerification;
