import React, { useState } from 'react';
import { AiOutlineAppstoreAdd } from 'react-icons/ai';
import CustomBtn from 'components/customButton/customBtn';

import { EditConfig } from '../pricingConfiguration.types';
import AddConfiguration from './addConfiguration';
import CurrencyRateList from './currencyRateList';

const CurrencyRate: React.FC<EditConfig> = ({ pricing, configId, setConfigPricing }) => {
    const [open, setOpen] = useState<boolean>(false);
    return (
        <>
            <AddConfiguration
                open={open}
                setOpen={setOpen}
                configPricing={pricing}
                setConfigPricing={setConfigPricing}
            />
            <div className="flex justify-end gap-2 p-2">
                <CustomBtn variant="primary" onClick={() => setOpen(true)} pill>
                    <AiOutlineAppstoreAdd className="mr-2 h-5 w-5" />
                    New
                </CustomBtn>
            </div>
            <div>
                {pricing.currencyRate && pricing.currencyRate.length > 0 && (
                    <CurrencyRateList
                        pricing={pricing}
                        configId={configId}
                        configLength={pricing?.currencyRate?.length}
                        setConfigPricing={setConfigPricing}
                    />
                )}
            </div>
        </>
    );
};
export default CurrencyRate;
