import CustomBtn from 'components/customButton/customBtn';
import React from 'react';
import { CustomCounterProps } from './Counter.types';

const CounterCustom: React.FC<CustomCounterProps> = ({ count, setCount }) => {
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setCount((prev) => (Number.isNaN(Number(e.target.value)) ? prev : Number(e.target.value)));
    };
    return (
        <>
            <div className=" p-1 rounded-lg flex items-center border border-gray-400 ">
                <CustomBtn variant="primary" onClick={() => setCount((prev) => prev - 1)} disabled={count <= 0}>
                    -
                </CustomBtn>
                <input
                    onChange={handleChange}
                    value={count}
                    type="text"
                    id="count"
                    className="block text-sm text-gray-900 border border-transparent rounded-lg  focus:ring-transparent focus:border-transparent dark:bg-gray-700 dark:border-transparent dark:placeholder-gray-400 dark:text-white w-20 text-center"
                    required
                />
                <CustomBtn variant="primary" onClick={() => setCount((prev) => prev + 1)}>
                    +
                </CustomBtn>
            </div>
        </>
    );
};

export default CounterCustom;
