import axios from 'utils/axios';

import { getBearerToken } from 'utils/helpers/auth';

export interface Ipagination {
    page: number;
    limit: number;
}

export interface IdateRange {
    startDate: string;
    endDate: string;
}

export interface Ifilter {
    wallet?: string;
    status?: number;
    operationType?: string;
    createdAt?: string;
    dateRange?: IdateRange;
}
export interface IWalletOperation {
    _id?: string;
    wallet: string;
    operationType: string;
    accountName: string;
    amount: number;
    status: number;
    verificationImage: string;
    store: string;
    prePaid: boolean;
    orderCount: number;
    handleFees: number;
    shippingFees: number;
    operationCurrency: string;
}
export interface IAmount {
    amount: number;
    customer: string;
    _id: string;
}

export type PaginationAndFiltres = Ipagination & Ifilter;

export const getCustomerWallet = async (data: any) => {
    const config = getBearerToken();
    const response = await axios.post(`/admin/walletOperation/filter`, data, config);
    return response;
};

export const updateCustomerWalletAmount = async (id: string, data: any) => {
    const config = getBearerToken();
    const response = await axios.put(`/admin/walletOperation/${id}`, data, config);
    return response;
};

export const getWalletAmount = async (id: string) => {
    const config = getBearerToken();
    const response = await axios.get<IAmount>(`/admin/wallet/${id}`, config);
    return response;
};

export const chargeWallet = async (data: any) => {
    const config = getBearerToken();
    const wal = await axios.post(`/admin/walletOperation`, data, config);
    return wal;
};
export const getWalletsList = async (data: any) => {
    const config = getBearerToken();
    const response = await axios.post(`/admin/wallet/filter`, data, config);
    return response;
};
