/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/style-prop-object */
import Avatar from 'assets/avatar.png';
import { IStausInterface, TableColDef } from 'components/tables/tables.types';
import { Badge } from 'flowbite-react';
import React from 'react';
import { PiCaretUpDownBold } from 'react-icons/pi';
import { getTotalPrice, getTotalQuantity } from 'utils/helpers/productsHelpers/getVariantInfo';
import { StatusBadge } from 'utils/helpers/statusBadge';
import { IProduct } from 'utils/Interfaces/products.interface';
import { IStatus } from './product.types';

export const columns: TableColDef[] = [
    {
        field: 'createdAt',
        headerName: 'Created at',
        fieldType: 'date',
        dateFormat: 'DD/MM/YYYY',
    },
    {
        field: 'customer',
        headerName: 'Seller name',
        fieldType: 'combo',
        child: ['firstName', 'lastName'],
    },
    {
        field: 'design',
        fieldType: 'imageArray',
        headerName: 'product Name',
        withName: true,
    },

    {
        field: 'quantity',
        headerName: 'Total Qty',
        fieldType: 'render',
        classField: 'w-[100px]',
        classHeader: 'text-center',
        renderCell: (params) => {
            const Total = getTotalQuantity(params);
            return (
                <>
                    <div className="flex items-center  justify-around">{Total}</div>
                </>
            );
        },
    },
    {
        field: 'updatedAt',
        headerName: 'Updated at',
        fieldType: 'date',
        dateFormat: 'DD/MM/YYYY',
    },
    {
        field: 'status',
        headerName: 'Status',
        fieldType: 'statusOptions',
    },
    {
        field: 'Action',
        headerName: 'Actions',
        actionType: 'Product',
    },
];

export const ToTalPriceCol = {
    field: 'price',
    headerName: 'Total Price($)',
    fieldType: 'render',
    classField: 'w-[100px]',
    classHeader: 'text-center',
    renderCell: (params: IProduct) => {
        const Total = getTotalPrice(params.quotation);
        return (
            <>
                <div className="flex items-center  justify-around">{Total}</div>
            </>
        );
    },
};

export const statusList = [
    { id: 1, label: 'Pending', type: 'Processing' },
    { id: 2, label: 'Under review', type: 'Processing' },
    { id: 3, label: 'Quoted', type: 'Processing' },
    { id: 69, label: 'Decision submitted', type: 'Processing' },
    { id: 5, label: 'Payment pending', type: 'Processing' },
    { id: 43, label: 'Payment verification', type: 'Processing' },
    { id: 30, label: 'Payment declined', type: 'Rejected' },
    { id: 99, label: 'Product unavailable', type: 'Rejected' },
    { id: 42, label: 'Quote rejected', type: 'Rejected' },
    { id: 6, label: 'Paid', type: 'Paid' },
    { id: 100, label: 'Expired', type: 'Rejected' },
];
export const statusList2: IStatus[] = [
    { id: 1, label: 'Pending' },
    { id: 2, label: 'Under review' },
    { id: 3, label: 'Quoted' },
    { id: 41, label: 'Reviewing quotations' },
    // { id: 42, label: 'Reviewing quotations' }, // 🏷️ logic is done in the backend if user select reviewing quotation in product filter it will return both status [41,42]
    { id: 5, label: 'Waiting for payment' },
    { id: 43, label: 'Processing payment' },
    { id: 6, label: 'Paid' },
    { id: 30, label: 'Payment declined' },
    // { id: 7, label: 'Preparing' }, // 🏷️ DEPRECATED
    { id: 8, label: 'Preparing stock' },
    // { id: 9, label: 'Waiting for pickup' },
    // { id: 10, label: 'Picked up' },
    // { id: 11, label: 'Pending delivery' },
    // { id: 12, label: 'Out for delivery' },
    // { id: 13, label: 'Delivered' },
    // { id: 14, label: 'Delivery failed' },
    // { id: 20, label: 'Partially matched' },
    // { id: 21, label: 'Being shipped to warehouse' },
    { id: 99, label: 'Product not available' },
];

export const statusDisplay: { [keys: string]: IStausInterface } = {
    '1': {
        statusIcon: (
            <Badge color="warning" size="sm">
                Pending
            </Badge>
        ),
        statusLabel: 'Pending',
    },
    '2': {
        statusIcon: (
            <Badge color="info" size="sm">
                Under review
            </Badge>
        ),
        statusLabel: 'Under review',
    },
    '3': {
        statusIcon: (
            <Badge color="purple" size="sm">
                Quoted
            </Badge>
        ),
        statusLabel: 'Quoted',
    },
    '41': {
        statusIcon: (
            <Badge color="success" size="sm">
                Customer reviewing quotations
            </Badge>
        ),
        statusLabel: 'Customer reviewing quotations',
    },
    '42': {
        statusIcon: (
            <Badge color="failure" size="sm">
                Customer reviewing quotations
            </Badge>
        ),
        statusLabel: 'Customer reviewing quotations',
    },
    '43': {
        statusIcon: (
            <Badge color="info" size="sm">
                Payment being proccessed
            </Badge>
        ),
        statusLabel: 'Payment being proccessed',
    },
    '69': {
        statusIcon: (
            <Badge color="failure" size="sm">
                Decision submitted
            </Badge>
        ),
        statusLabel: 'Decision submitted',
    },
    '5': {
        statusIcon: (
            <Badge color="info" size="sm">
                Waiting for payment
            </Badge>
        ),
        statusLabel: 'Waiting for payment',
    },
    '6': {
        statusIcon: (
            <Badge color="success" size="sm">
                Paid
            </Badge>
        ),
        statusLabel: 'Paid',
    },
    '7': {
        statusIcon: (
            <Badge color="info" size="sm">
                Preparing
            </Badge>
        ),
        statusLabel: 'Preparing',
    },
    '8': {
        statusIcon: (
            <Badge color="success" size="sm">
                Preparing stock
            </Badge>
        ),
        statusLabel: 'Preparing stock',
    },
    '30': {
        statusIcon: (
            <Badge color="failure" size="sm">
                Payment declined
            </Badge>
        ),
        statusLabel: 'Payment declined',
    },
    '9': {
        statusIcon: (
            <Badge color="success" size="sm">
                Waiting for pickup
            </Badge>
        ),
        statusLabel: 'Waiting for pickup',
    },
    '10': {
        statusIcon: (
            <Badge color="success" size="sm">
                Picked up
            </Badge>
        ),
        statusLabel: 'Picked up',
    },
    '11': {
        statusIcon: (
            <Badge color="warning" size="sm">
                Pending delivery
            </Badge>
        ),
        statusLabel: 'Pending delivery',
    },
    '12': {
        statusIcon: (
            <Badge color="success" size="sm">
                Out for delivery
            </Badge>
        ),
        statusLabel: 'Out for delivery',
    },
    '13': {
        statusIcon: (
            <Badge color="success" size="sm">
                Delivered
            </Badge>
        ),
        statusLabel: 'Delivered',
    },
    '14': {
        statusIcon: (
            <Badge color="failure" size="sm">
                Delivery failed
            </Badge>
        ),
        statusLabel: 'Delivery failed',
    },
    '20': {
        statusIcon: (
            <Badge color="success" size="sm">
                Partially matched
            </Badge>
        ),
        statusLabel: 'Partially matched',
    },
    '21': {
        statusIcon: (
            <Badge color="success" size="sm">
                Being shipped to warehouse
            </Badge>
        ),
        statusLabel: 'Being shipped to warehouse',
    },
    '99': {
        statusIcon: (
            <Badge color="failure" size="sm">
                Product not available
            </Badge>
        ),
        statusLabel: 'Product not available',
    },
};
export const statusDisplayColor: { [keys: string]: IStausInterface } = {
    '1': {
        statusIcon: <StatusBadge Icon={PiCaretUpDownBold} name="Pending" style="blueTag" />,
        statusLabel: 'Pending',
        statusClass: 'blueTag',
    },
    '2': {
        statusIcon: <StatusBadge Icon={PiCaretUpDownBold} name="Under review" style="blueTag" />,
        statusLabel: 'Under review',
        statusClass: 'blueTag',
    },
    '3': {
        statusIcon: <StatusBadge Icon={PiCaretUpDownBold} name="Quoted" style="greenTag" />,
        statusLabel: 'Quoted',
        statusClass: 'greenTag',
    },
    '41': {
        statusIcon: (
            <Badge color="success" size="sm">
                Reviewing quotations
            </Badge>
        ),
        statusLabel: 'Customer reviewing quotations',
    },
    '42': {
        statusIcon: <StatusBadge Icon={PiCaretUpDownBold} name="Quote rejected" style="redTag" />,
        statusLabel: 'Quote rejected',
        statusClass: 'redTag',
    },
    '69': {
        statusIcon: <StatusBadge Icon={PiCaretUpDownBold} name="Decision submitted" style="blueTag" />,
        statusLabel: 'Decision submitted',
        statusClass: 'blueTag',
    },
    '43': {
        statusIcon: <StatusBadge Icon={PiCaretUpDownBold} name="Payment verification" style="blueTag" />,
        statusLabel: 'Payment verification',
        statusClass: 'blueTag',
    },
    '5': {
        // eslint-disable-next-line react/style-prop-object
        statusIcon: <StatusBadge Icon={PiCaretUpDownBold} name="Payment pending" style="yellowTag" />,
        statusLabel: 'Waiting for payment',
        statusClass: 'yellowTag',
    },
    '6': {
        statusIcon: <StatusBadge Icon={PiCaretUpDownBold} name="Paid" style="greenTag" />,
        statusLabel: 'Paid',
        statusClass: 'greenTag',
    },
    '8': {
        statusIcon: <StatusBadge Icon={PiCaretUpDownBold} name="Preparing stock" style="greenTag" />,
        statusLabel: 'Preparing stock',
        statusClass: 'greenTag',
    },
    '30': {
        statusIcon: <StatusBadge Icon={PiCaretUpDownBold} name="Payment declined" style="redTag" />,
        statusLabel: 'Payment declined',
        statusClass: 'redTag',
    },
    '9': {
        statusIcon: (
            <Badge color="success" size="sm">
                Waiting for pickup
            </Badge>
        ),
        statusLabel: 'Waiting for pickup',
        statusClass: 'blueTag',
    },
    '10': {
        statusIcon: (
            <Badge color="success" size="sm">
                Picked up
            </Badge>
        ),
        statusLabel: 'Picked up',
    },
    '11': {
        statusIcon: (
            <Badge color="warning" size="sm">
                Pending delivery
            </Badge>
        ),
        statusLabel: 'Pending delivery',
    },
    '12': {
        statusIcon: (
            <Badge color="success" size="sm">
                Out for delivery
            </Badge>
        ),
        statusLabel: 'Out for delivery',
    },
    '13': {
        statusIcon: (
            <Badge color="success" size="sm">
                Delivered
            </Badge>
        ),
        statusLabel: 'Delivered',
    },
    '14': {
        statusIcon: (
            <Badge color="failure" size="sm">
                Delivery failed
            </Badge>
        ),
        statusLabel: 'Delivery failed',
    },
    '20': {
        statusIcon: (
            <Badge color="success" size="sm">
                Partially matched
            </Badge>
        ),
        statusLabel: 'Partially matched',
    },
    '21': {
        statusIcon: (
            <Badge color="success" size="sm">
                Being shipped to warehouse
            </Badge>
        ),
        statusLabel: 'Being shipped to warehouse',
    },
    '99': {
        statusIcon: <StatusBadge Icon={PiCaretUpDownBold} name="Product unavailable" style="redTag" />,
        statusLabel: 'Product not available',
        statusClass: 'redTag',
    },
    '100': {
        statusIcon: <StatusBadge Icon={PiCaretUpDownBold} name="Expired" style="redTag" />,
        statusLabel: 'Expired',
        statusClass: 'redTag',
    },
};
export const typeDisplay = {
    1: 'Dropshipping',
    2: 'Bulk',
    3: 'Logistio Bulk',
};

export const ManagerCol = {
    field: 'managedBy',
    headerName: 'Managed by',
    fieldType: 'render',
    renderCell: (params: any) => {
        const manager = params.managedBy;
        if (!manager) return <></>;

        return (
            <div className="flex gap-2">
                {manager?.profileImage ? (
                    // eslint-disable-next-line jsx-a11y/alt-text
                    <img src={manager?.profileImage} className="w-10 h-10 !rounded-full" />
                ) : (
                    <img src={Avatar} className="w-10 h-10 !rounded-full" />
                )}
                <div className="flex items-center gap-1">{`${manager.firstName || ''} ${manager.lastName || ''}`}</div>
            </div>
        );
    },
};
