/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';

import { Select, Tabs } from 'flowbite-react';
import toast from 'react-hot-toast';
import { FaUserFriends } from 'react-icons/fa';
import { FaBox } from 'react-icons/fa6';
import { getCallAgentAdmin } from 'services/callAgentAdmin/callAgentAdminProfile';
import { getLeads } from 'services/callAgentAdmin/dashboard';
import { getCurrentUser } from 'utils/helpers/auth';
import moment from 'moment';
import { selectTheme } from '../dashboard.styles';
import { IData } from '../dashboard.types';
import { calculatePercent } from '../utils';
import { IFilterCCADashboard } from './dashboard.types';
import CCADashboardLeads from './leadsDashboard';
import ReportLeads from './reportleads';

const CallCenterAdminDashboard: React.FC = () => {
    const defaultRange = {
        endDate: moment.tz(new Date(), 'Asia/Shanghai').set('hour', 23).set('minute', 59).set('second', 59).format(),
        startDate: moment
            .tz(new Date(), 'Asia/Shanghai')
            .set('day', 1)
            .set('hour', 0)
            .set('minute', 0)
            .set('second', 0)
            .format(),
    };
    const initVal = {
        newCount: { total: 0, percent: 0 },
        confirmedCount: { total: 0, percent: 0 },
        canceledCount: { total: 0, percent: 0 },
        wrongNumberCount: { total: 0, percent: 0 },
        expiredCount: { total: 0, percent: 0 },
        unreachableCount: { total: 0, percent: 0 },
        pendingAnswersCount: { total: 0, percent: 0 },
        otherCount: { total: 0, percent: 0 },
        ordersAssignedCount: { total: 0, percent: 0 },
        answeredCount: { total: 0, percent: 0 },
        orderCalledCount: { total: 0, percent: 0 },
        deliveryCount: { total: 0, percent: 0 },
        scheduledCallCount: { total: 0, percent: 0 },
    };
    const [dataToday, setData] = useState<IData>(initVal);
    const [dataPeriod, setPeriod] = useState<IData>(initVal);
    const [loading, setloading] = useState(false);
    const [callcenterName, setcallcenterName] = useState<string>('');
    const [filter, setFilter] = useState<IFilterCCADashboard>({ dateRange: defaultRange });
    // const [filterAgents, setFilterAgents] = useState<IFilterCCADashboardAgents>({
    //     dateRange: defaultRange,
    //     agent: '',
    // });
    const [callAgentAdmin, setCallAgentAdmin] = useState<any>();

    const currentId = getCurrentUser()._id;

    useEffect(() => {
        if (!currentId || !callAgentAdmin) return;
        /* SET ALL ORDERS ASSIGNED */
        setloading(true);
        getLeads({
            ...filter,
            callCenter:
                callAgentAdmin?.callCenter.length === 1 ? [callAgentAdmin?.callCenter[0]._id] : filter.callCenter,
        })
            // eslint-disable-next-line @typescript-eslint/no-shadow
            .then(({ data }: any) => {
                if (data?.errors) {
                    toast.error('Unable to load data.');
                    return;
                }
                const period = Object.keys(data.period).reduce((acc: any, cur: string) => {
                    const percent =
                        calculatePercent(
                            data.period[cur],
                            cur === 'deliveryCount' ? data.period.confirmedCount : data.period.ordersAssignedCount,
                        ) || 0;
                    acc[cur] = {
                        total: data.period[cur],
                        ...(!['ordersAssignedCount'].includes(cur) ? { percent } : {}),
                    };
                    return acc;
                }, {});
                const today = Object.keys(data.today).reduce((acc: any, cur: string) => {
                    const percent = calculatePercent(data.today[cur], data.today.orderCalledCount);
                    acc[cur] = {
                        total: data.today[cur],
                        ...(!['newCount', 'orderCalledCount'].includes(cur) ? { percent } : {}),
                    };
                    return acc;
                }, {});
                setData(today);
                setPeriod(period);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message || 'Unable to load data.');
            })
            .finally(() => setloading(false));
        //   }
    }, [filter, callAgentAdmin?.callCenter]);

    useEffect(() => {
        getCallAgentAdmin(currentId).then((res) => {
            setCallAgentAdmin(res.data);
        });
    }, []);

    return (
        <div>
            <div className="flex justify-between mb-4">
                <h2>Dashboard</h2>
                {callAgentAdmin?.callCenter && callAgentAdmin?.callCenter?.length > 1 && (
                    <div id="select" className="w-52">
                        <Select
                            theme={selectTheme}
                            color="general"
                            id="callCenterFilter"
                            required
                            onChange={(e) => {
                                if (e.target.value !== '') {
                                    const filterLeads = { ...filter, callCenter: [e.target.value] };
                                    if (filterLeads.agentStatus) {
                                        delete filterLeads.agentStatus;
                                    }
                                    setFilter({ ...filterLeads });
                                    const name = callAgentAdmin?.callCenter.find((el: any) => el._id === e.target.value)
                                        .name;
                                    setcallcenterName(name);
                                    // setFilterAgents({ ...filter, callCenter: [e.target.value] });
                                } else {
                                    setFilter({ ...filter, callCenter: undefined });
                                    setcallcenterName('');

                                    // setFilterAgents({ ...filter, callCenter: undefined });
                                }
                            }}
                        >
                            <option value="">All Call Centers</option>
                            {callAgentAdmin?.callCenter?.map((cc: any) => (
                                <option key={cc._id} value={cc._id}>
                                    {cc.name}
                                </option>
                            ))}
                        </Select>
                    </div>
                )}
            </div>
            <div>
                <Tabs
                    aria-label="Tabs with icons"
                    // eslint-disable-next-line react/style-prop-object
                    style="underline"
                    theme={{
                        tablist: {
                            tabitem: {
                                base: 'flex items-center justify-center px-4 h-14 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 ',
                            },
                        },
                    }}
                >
                    <Tabs.Item
                        title={
                            <div className="flex items-center gap-2">
                                <FaBox className="w-5 h-5 " />

                                <div>Activity</div>
                            </div>
                        }
                    >
                        <CCADashboardLeads loading={loading} data={dataToday} filter={filter} setFilter={setFilter} />
                    </Tabs.Item>
                    {/* <Tabs.Item
                        title={
                            <div className="flex items-center gap-2">
                                <FaUserFriends className="w-5 h-5 " />
                                <div>Agents</div>
                            </div>
                        }
                    >
                        <div className="w-full">
                            <CCADashboardAgents filter={filterAgents} />
                        </div>
                    </Tabs.Item> */}
                    <Tabs.Item
                        title={
                            <div className="flex items-center gap-2">
                                <FaUserFriends className="w-5 h-5 " />
                                <div>Report</div>
                            </div>
                        }
                    >
                        <div className="w-full">
                            <ReportLeads
                                loading={loading}
                                data={dataPeriod}
                                filter={filter}
                                setFilter={setFilter}
                                callAgentAdmin={callAgentAdmin}
                                callcenterName={callcenterName}
                            />
                        </div>
                    </Tabs.Item>
                </Tabs>
            </div>
        </div>
    );
};

export default CallCenterAdminDashboard;
