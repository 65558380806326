export const accordTitle = {
    flush: {
        off: 'hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:hover:bg-gray-800 dark:focus:ring-gray-800 ',
        on: 'bg-red dark:bg-transparent',
    },
    open: {
        off: 'text-gray-900  dark:bg-gray-800 dark:text-white hover:bg-indigo-100',
        on: 'text-gray-900 bg-indigo-200 dark:bg-gray-800 dark:text-white',
    },
    heading: 'text-gray-600 text-sm',
};
export const accordContent = { base: 'border-2 border-gray-950 shadow-lg p-2 rounded-b-2xl' };
