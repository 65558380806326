import * as Yup from 'yup';

const errorType = 'Field must be a number';
const errorRequired = 'Required Field';

export const AddWalletCurrencyConfigValidation = Yup.object().shape({
    currency: Yup.string().required(errorRequired),
    currencyValue: Yup.number().typeError(errorType).required(errorRequired),
});

export const UpdateWalletCurrencyConfig = Yup.object().shape({
    currency: Yup.string().optional(),
    currencyValue: Yup.number().typeError(errorType).optional(),
});
