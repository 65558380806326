import React from 'react';
import { Tabs } from 'flowbite-react';
import { PricingConfigurationProps } from './pricingConfiguration.types';
import PricingCodNoCC from './codNoCC/countriesPricing';
import PricingPrepaidNoCC from './prepaidNoCC/countriesPricing';
import PricingCodWithCC from './codWithCC/countriesPricing';
import CurrencyRate from './currencyRate/currencyRate';

const PricingConfiguration: React.FC<PricingConfigurationProps> = (pricingProps) => {
    const { pricing } = pricingProps;
    const { configId } = pricingProps;
    const { setConfigPricing } = pricingProps;
    return (
        <Tabs
            aria-label="Pills"
            // eslint-disable-next-line react/style-prop-object
            style="pills"
            theme={{
                base: 'flex flex-col gap-2',
                tablist: {
                    styles: {
                        pills: 'flex-wrap font-medium text-sm text-gray-500 dark:text-gray-400 space-x-3 p-2',
                    },
                    tabitem: {
                        base: 'flex items-center justify-center px-4 py-2 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 ',
                        styles: {
                            pills: {
                                base: '',
                                active: {
                                    on: 'rounded-3xl bg-[#6874F5] text-white',
                                    off: 'rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white border rounded-3xl dark:border-gray-700',
                                },
                            },
                        },
                    },
                },
            }}
        >
            <Tabs.Item title="COD with Call center">
                <PricingCodWithCC pricing={pricing} configId={configId} setConfigPricing={setConfigPricing} />
            </Tabs.Item>
            <Tabs.Item title="COD No Call center">
                <PricingCodNoCC pricing={pricing} configId={configId} setConfigPricing={setConfigPricing} />
            </Tabs.Item>
            <Tabs.Item title="Prepaid No Call center">
                <PricingPrepaidNoCC pricing={pricing} configId={configId} setConfigPricing={setConfigPricing} />
            </Tabs.Item>
            <Tabs.Item title="Currency Rate">
                <CurrencyRate
                    pricing={pricing}
                    configId={configId}
                    configLength={pricing?.currencyRate?.length}
                    setConfigPricing={setConfigPricing}
                />
            </Tabs.Item>
        </Tabs>
    );
};

export default PricingConfiguration;
