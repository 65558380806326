/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
import axios from 'utils/axios';
import { getBearerToken } from 'utils/helpers/auth';
import ExcelJS from 'exceljs';
import Package from 'assets/package.png';
import { IPaginationData } from 'utils/Interfaces/types';
import { IPurchases } from 'pages/purchases/purchases.types';
import { getVariantQuantity } from 'utils/helpers/productsHelpers/getVariantInfo';
import { IProduct } from 'utils/Interfaces/products.interface';

export interface IxlsxPurchases {
    product: string;
    customer: string;
    variants: string;
    quantity: string;
    image?: any;
}
export const getPurchasesList = async (filter: any) => {
    const purchases = await axios.post<{ response: IPaginationData<IPurchases> }>(
        `/purchases/filter`,
        filter,
        getBearerToken(),
    );
    return purchases;
};

export const getPurchases = async (id: any) => {
    const purchases = await axios.get(`/purchases/${id}`, getBearerToken());
    return purchases;
};

export const updatePurchases = async (id: any, data: any) => {
    const invoiceUpdate = await axios.put(`/purchases/update-one/${id}`, data, getBearerToken());
    return invoiceUpdate;
};
export const updateManyPurchases = async (data: any) => {
    const purchasesUpdate = await axios.put<{
        message: string;
        /** failed purchaseId[] */
        errors: { purchaseId: string; error: string }[];
        /** succeeded purchaseId[] */
        success: string[];
    }>(`/purchases/update-many`, data, getBearerToken());
    return purchasesUpdate;
};

export const getPendingPurchases = async () => {
    const res = await axios.get<number>('/purchases/countPendingPurchases', getBearerToken());
    return res;
};

const toDataURL = (url: string): Promise<any> => {
    const promise = new Promise((resolve) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
            const reader = new FileReader();
            reader.readAsDataURL(xhr.response);
            reader.onloadend = () => {
                resolve({ base64Url: reader.result });
            };
        };
        xhr.open('GET', url);
        // xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
        xhr.responseType = 'blob';
        xhr.send();
    });

    return promise;
};
export const exportExcelFile = async (data: any) => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('My Sheet');
    sheet.getRow(1).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
    };

    sheet.getRow(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ebf1f2' },
    };

    sheet.getRow(1).font = {
        name: 'Times New Roman',
        size: 14,
    };
    sheet.getRow(1).height = 30;
    sheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
    sheet.columns = [
        {
            header: 'Design',
            key: 'design',
            width: 20,
        },
        { header: 'Product', key: 'product', width: 32 },
        {
            header: 'Variants',
            key: 'variants',
            width: 20,
        },
        {
            header: 'Quantity',
            key: 'quantity',
            width: 15,
        },
        {
            header: 'Seller',
            key: 'customer',
            width: 20,
        },
    ];
    let startRow = 1;
    let prevStartRow = 1;
    // eslint-disable-next-line no-restricted-syntax
    for await (const purch of data) {
        if (purch?.variants?.length > 0) {
            let nbrVariant = 0;
            (purch.variants as IProduct[]).forEach((item) => {
                const variantQuantity = getVariantQuantity(item);
                if (variantQuantity) {
                    sheet.addRow({
                        customer: `${purch?.customer?.firstName} ${purch?.customer?.lastName || '--'}`,
                        product: `${purch?.product?.name || '--'}`,
                        variants: `${item?.name || '--'}`,
                        quantity: Number(variantQuantity),
                    });
                    nbrVariant += 1;
                }
            });

            sheet.mergeCells(startRow + 1, 1, startRow + nbrVariant, 1);
            sheet.mergeCells(startRow + 1, 2, startRow + nbrVariant, 2);
            sheet.mergeCells(startRow + 1, 5, startRow + nbrVariant, 5);
            startRow += nbrVariant;
        } else {
            sheet.addRow({
                customer: `${purch?.customer?.firstName} ${purch?.customer?.lastName || '--'}`,
                product: `${purch?.product?.name || '--'}`,
                variants: `${'--'}`,
                quantity: purch?.product?.quantity ? Number(purch?.product?.quantity) : 0,
            });
            startRow += 1;
        }
        const isThere = purch?.product?.design?.filter(
            (prodImg: any) =>
                prodImg.endsWith('.jpg') ||
                prodImg.endsWith('.png') ||
                prodImg.endsWith('.jpeg') ||
                prodImg.endsWith('.webp'),
        );
        if (isThere[0]) {
            const result = await toDataURL(isThere[0]);
            const imageLink = isThere[0];
            const splitted = imageLink?.split('.');
            const extName = splitted[splitted.length - 1];
            const imageId2 = workbook.addImage({
                base64: result.base64Url,
                extension: extName,
            });
            sheet.addImage(imageId2, {
                tl: { col: 0, row: prevStartRow },
                ext: { width: 60, height: 60 },
            });
        } else {
            let extension: 'jpeg' | 'png' | 'gif' = 'png';
            const lowerCase = Package.toLowerCase();
            if (lowerCase.indexOf('png') !== -1) extension = 'png';
            else if (lowerCase.indexOf('jpg') !== -1 || lowerCase.indexOf('jpeg') !== -1) extension = 'jpeg';
            const imageId2 = workbook.addImage({
                base64: Package,
                extension,
            });
            sheet.addImage(imageId2, {
                tl: { col: 0, row: prevStartRow },
                ext: { width: 60, height: 60 },
            });
        }
        prevStartRow = startRow;
    }
    const rowsLength = sheet.rowCount;
    for (let i = 1; i < rowsLength; i++) {
        sheet.getRow(i + 1).height = 60;
        sheet.getRow(i + 1).alignment = { vertical: 'middle', horizontal: 'center' };
        sheet.getRow(i + 1).font = {
            name: 'Poppins Black',
            family: 4,
            size: 10,
        };
    }
    workbook.xlsx.writeBuffer().then((dataBlob) => {
        const blob = new Blob([dataBlob], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = 'Logistio-Batch-Purchases.xlsx';
        anchor.click();
        window.URL.revokeObjectURL(url);
    });
};
