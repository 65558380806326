export const messagesPageStyle = {
    height: '85vh',
    borderTop: '1px solid',
    borderColor: 'secondaryBackground',
};

export const messagesContainerStyle = { borderRight: '1px solid', borderColor: 'secondaryBackground' };

export const MessageBoxStyle = {
    borderColor: 'secondaryBackground',
    height: '88vh',
    overflow: 'hidden',
    '&:hover': {
        overflowY: 'scroll',
    },
};

export const MessageTextsContainer = {
    padding: '10px',
    height: '67vh',
    overflow: 'hidden',
    '&:hover': {
        overflowY: 'scroll',
    },
};

export const MessageTexts = { margin: '10px' };
export const LoadPreviousMessagesWrapper = { display: 'flex', justifyContent: 'center', padding: '5px' };

export const LoadPreviousMessages = {
    backgroundColor: 'secondaryBackground',
    padding: '10px',
    borderRadius: '10px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};
