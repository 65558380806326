/* eslint-disable react/jsx-fragments */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import CustomError from 'components/customError/CustomError';
import toast from 'react-hot-toast';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Label, TextInput } from 'flowbite-react';
import CustomBtn from 'components/customButton/customBtn';
import { HiMail } from 'react-icons/hi';
import { changeUserInfos } from 'utils/validations/personalInformationValidation';
import { getAllCallAgents, updateCallAgent } from 'services/callAgentAdmin/callAgentsManagment';
import PhoneInput from 'react-phone-number-input';
import AutoComplete from 'components/autocomplete/autoComplete';
import { getCallAgentAdmin } from 'services/callAgentAdmin/callAgentAdminProfile';
import { getCurrentUser } from 'utils/helpers/auth';
import { ICallCenterAdmin } from 'pages/callCenterAdminList/callCenterAdmin.types';
import { ICallAgentActions } from '../callAgent.types';
import CallAgentPassword from './callAgentPassword';

export interface IdateRange {
    startDate: string | null | Date;
    endDate: string | null | Date;
}
const CallAgentPersonalInfo: React.FC<ICallAgentActions> = ({ callAgent, setRows, currentPage, currentFilter }) => {
    const [profileSaverLoader, setProfileLoader] = useState(false);
    const [callAdmin, setCallAdmin] = useState<ICallCenterAdmin | null>(null);

    const personalInfo = (values: any) => {
        setProfileLoader(true);
        updateCallAgent(callAgent._id, values)
            .then((res) => {
                toast.success(res.data.message);
                setProfileLoader(false);
            })
            .then(() => {
                getAllCallAgents({ page: currentPage, ...currentFilter }).then((res) => {
                    setRows(res.data.docs);
                });
            })
            .catch((err) => {
                toast.error(err.response.data.errors.message);
                setProfileLoader(false);
            });
    };
    useEffect(() => {
        const currentId = getCurrentUser()._id;
        getCallAgentAdmin(currentId).then((res) => {
            setCallAdmin(res.data);
        });
    }, []);
    // const hoursArray = new Array(24).fill(0);
    // const minutesArray = new Array(60).fill(0);
    // const [rangevalue, setRangeValue] = useState<IdateRange>({
    //     startDate: null,
    //     endDate: null,
    // });
    // const handleRangeChange = (newValue: any) => {
    //     if (newValue) {
    //         setRangeValue(newValue);
    //     }
    // };
    return (
        <>
            <Formik
                initialValues={{
                    firstName: callAgent.firstName,
                    lastName: callAgent.lastName,
                    phone: callAgent.phone,
                    email: callAgent.email,
                    callCenter: [(callAgent.callCenter as any)?.[0]?._id],
                }}
                onSubmit={(values) => {
                    personalInfo({
                        ...values,
                        callCenter:
                            (callAdmin?.callCenter as any)?.length === 1
                                ? [(callAdmin?.callCenter as any)?.[0]?._id]
                                : values.callCenter,
                    });
                }}
                validationSchema={changeUserInfos}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit} placeholder="">
                        <div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="username4"
                                        className="dark:text-white"
                                        color={formik.errors.firstName ? 'failure' : ''}
                                        value="First name"
                                    />
                                </div>
                                <TextInput
                                    {...formik.getFieldProps('firstName')}
                                    name="firstName"
                                    color={formik.errors.firstName ? 'failure' : 'gray'}
                                    type="text"
                                    helperText={
                                        <React.Fragment>
                                            <CustomError name="firstName" component="div" />
                                        </React.Fragment>
                                    }
                                />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="username4"
                                        className="dark:text-white"
                                        color={formik.errors.lastName ? 'failure' : ''}
                                        value="Last Name"
                                    />
                                </div>
                                <TextInput
                                    {...formik.getFieldProps('lastName')}
                                    name="lastName"
                                    color={formik.errors.lastName ? 'failure' : 'gray'}
                                    type="text"
                                    helperText={
                                        <React.Fragment>
                                            <CustomError name="lastName" component="div" />
                                        </React.Fragment>
                                    }
                                />
                            </div>
                        </div>
                        {(callAdmin?.callCenter as any)?.length > 1 ? (
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="callCenter"
                                        className="dark:text-white"
                                        value="Assign to Call Center"
                                    />
                                </div>

                                <AutoComplete
                                    key="callCenterInput"
                                    {...formik.getFieldProps('callCenter')}
                                    name="callCenter"
                                    placeholder="Choose a Call Center"
                                    defaultValue={callAgent?.callCenter[0]?.name || undefined}
                                    options={(callAdmin?.callCenter as any) || []}
                                    getOptionLabel={(option: any) => option.name}
                                    onChange={(option) => {
                                        formik.setFieldValue('callCenter', [option._id]);
                                    }}
                                />
                            </div>
                        ) : null}
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="username4"
                                    className="dark:text-white"
                                    color={formik.errors.phone ? 'failure' : ''}
                                    value="Phone Number"
                                />
                            </div>
                            {/* <TextInput
                                {...formik.getFieldProps('phone')}
                                name="phone"
                                type="text"
                                color={formik.errors.phone ? 'failure' : 'gray'}
                                helperText={
                                    <React.Fragment>
                                        <CustomError name="phone" component="div" />
                                    </React.Fragment>
                                }
                            /> */}
                            <PhoneInput
                                id="phoneNumber"
                                name="phone"
                                value={formik.values.phone}
                                onChange={(value) => formik.setFieldValue('phone', value)}
                                onBlur={formik.handleBlur('phone')}
                                placeholder="Enter a phone number"
                                className="mb-1"
                            />
                            <CustomError name="phone" component="div" />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="username4"
                                    className="dark:text-white"
                                    color={formik.errors.email ? 'failure' : ''}
                                    value="Email"
                                />
                            </div>
                            <TextInput
                                {...formik.getFieldProps('email')}
                                name="email"
                                color={formik.errors.email ? 'failure' : 'gray'}
                                icon={HiMail}
                                type="email"
                                helperText={
                                    <React.Fragment>
                                        <CustomError name="email" component="div" />
                                    </React.Fragment>
                                }
                            />
                        </div>
                        <div className="flex justify-end">
                            <CustomBtn
                                type="submit"
                                variant="primary"
                                disabled={profileSaverLoader}
                                isProcessing={profileSaverLoader}
                            >
                                Save Changes
                            </CustomBtn>
                        </div>
                    </Form>
                )}
            </Formik>
            <CallAgentPassword id={callAgent._id} />
        </>
    );
};

export default CallAgentPersonalInfo;
