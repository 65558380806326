import React from 'react';
import { toast, Toaster, ToastBar } from 'react-hot-toast';
import { AiOutlineClose } from 'react-icons/ai';

/**
 * To call the Notification Toaster
 * @HowToUse :
 * you only need to import toast from react-hot-toster in children tsx files and activate the toaster example: toast.success('')...
 * @InCaseOfUse :
 * @Success : toast.success("your message here")
 * @failure : toast.error("your message here")
 * @Promise : in case of a promise toast.promise(configuration here)
 * @Custom we can Customize the toast with toast.custom((t) => (JSX HERE))
 * for further infos check the documentation https://react-hot-toast.com
 */

const NotificationToast = () => {
    return (
        <>
            <Toaster
                position="top-right"
                gutter={0}
                toastOptions={{
                    duration: 5000,
                    className: ' m-1 dark:bg-gray-800',
                }}
            >
                {(t) => (
                    <ToastBar toast={t}>
                        {({ icon, message }) => (
                            <div
                                id="toast-success"
                                className="flex items-center w-full max-w-xs p-3  text-gray-500  dark:text-gray-400"
                            >
                                <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg">
                                    {icon}
                                </div>
                                <div className="ml-0 text-sm font-normal">{message}</div>
                                {t.type !== 'loading' && (
                                    <button
                                        onClick={() => toast.dismiss(t.id)}
                                        type="button"
                                        className="ml-auto -mx-1.5  bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1 hover:bg-gray-100   dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                                    >
                                        <AiOutlineClose className="h-4 w-4" />
                                    </button>
                                )}
                            </div>
                        )}
                    </ToastBar>
                )}
            </Toaster>
        </>
    );
};

export default NotificationToast;
