/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prettier/prettier */
import * as React from 'react';
import { Select, Label, TextInput, Button, Alert } from 'flowbite-react';
import { Form, Formik, useField, useFormikContext } from 'formik';
import CustomError from 'components/customError/CustomError';
import { countries } from 'utils/countryList';
import { FaCheckCircle, FaSave } from 'react-icons/fa';
import { CallCenterUpdateCoordinateSchema } from 'utils/validations/ordersValidations';
import { HiPencilAlt } from 'react-icons/hi';
import { cities } from 'utils/vnlinCities';
import { v4 as uuidv4 } from 'uuid';
import { IOrder } from 'services/admin/order';
import { MdReportGmailerrorred } from 'react-icons/md';
import { toast } from 'react-hot-toast';

interface IformikValues {
    firstName: string;
    lastName: string;
    address1: string;
    address2: string;
    country: string;
    city: string;
    zip: string;
    updateType: 2;
}

const SelectField = (props: any) => {
    const { name } = props;
    const getCitiesContent = (country: string) => {
        const content = [];

        if (cities && cities[country as keyof typeof cities]) {
            for (const cityName of cities[country as keyof typeof cities]) {
                content.push(
                    <option value={cityName} key={uuidv4()}>
                        {cityName}
                    </option>,
                );
            }
        } else {
            toast.error(`Country ${country} is not supported.`);
        }
        return content;
    };
    const {
        values: { country },
        setFieldValue,
    } = useFormikContext<IformikValues>();
    const [field] = useField(props);
    const city = field.value;
    React.useEffect(() => {
        setFieldValue(
            name,
            cities[country as keyof typeof cities] &&
                cities[country as keyof typeof cities].findIndex((el) => el === city) !== -1
                ? city
                : '',
        );
    }, [country, setFieldValue, name]);

    return (
        <div>
            <div className="mb-2 block">
                <Label htmlFor="cities" value="Select city" />
            </div>
            <Select id="cities" {...props} {...field}>
                <option value="">Select city from the list</option>
                {getCitiesContent(country)}
            </Select>
        </div>
    );
};

export const OrderCordinateAdmin: React.FC<{
    data: IOrder;
    editCordinate: boolean;
    setEditCordinate: React.Dispatch<React.SetStateAction<boolean>>;
    updateOrderInformations: React.Dispatch<React.SetStateAction<any>>;
    saveCordinateLoader: boolean;
}> = ({ data, editCordinate, setEditCordinate, updateOrderInformations, saveCordinateLoader }) => {
    const confirmDisplay = !(Number(data.status) === 99);
    return (
        <div className="p-4 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 items-center justify-between">
            <div className="flex items-center space-x-4">
                <div className="min-w-0 flex-1">
                    <p className="text-base font-bold leading-none text-gray-900 dark:text-white">Delivery Address</p>
                </div>

                <div className="inline-flex items-center text-base text-gray-900 dark:text-white gap-2">
                    {!editCordinate && (
                        <Button
                            pill
                            disabled={confirmDisplay}
                            size="xs"
                            onClick={() => {
                                updateOrderInformations(data);
                            }}
                            color="success"
                        >
                            Confirm
                            <FaCheckCircle className="ml-2 h-5 w-5" />
                        </Button>
                    )}
                    {!editCordinate && (
                        <Button
                            pill
                            size="xs"
                            onClick={() => {
                                setEditCordinate(true);
                            }}
                        >
                            <HiPencilAlt className="mr-2 h-5 w-5" />
                            Edit
                        </Button>
                    )}
                </div>
            </div>
            {editCordinate ? (
                <Formik
                    initialValues={{
                        firstName: data.firstName,
                        lastName: data.lastName,
                        address1: data.address1,
                        address2: data.address2,
                        country: data.country,
                        city: data.city,
                        zip: data.zip,
                        updateType: 2,
                    }}
                    onSubmit={(values, onSubmitProps) => {
                        if (countries.some((c) => c.label === data.country)) {
                            let { currency } = data;
                            countries.forEach((c) => {
                                if (c.label === values.country) {
                                    currency = c.currency;
                                }
                            });
                            updateOrderInformations({ ...values, currency });
                        } else {
                            updateOrderInformations(values);
                        }
                        onSubmitProps.setSubmitting(false);
                    }}
                    validationSchema={CallCenterUpdateCoordinateSchema}
                >
                    {(formik) => (
                        <Form onSubmit={formik.handleSubmit} placeholder="">
                            <div className="py-2">
                                <div className="grid md:grid-cols-2 md:gap-2">
                                    <div className="relative w-full mb-6 group">
                                        <div className="mb-2 block">
                                            <Label htmlFor="base" value="First name" />
                                        </div>
                                        <TextInput
                                            id="firstname"
                                            type="text"
                                            sizing="md"
                                            {...formik.getFieldProps('firstName')}
                                        />
                                    </div>
                                    <div>
                                        <div className="mb-2 block">
                                            <Label htmlFor="base" value="Last name" />
                                        </div>
                                        <TextInput
                                            id="lastname"
                                            type="text"
                                            sizing="md"
                                            {...formik.getFieldProps('lastName')}
                                        />
                                    </div>
                                </div>
                                <div className="grid md:grid-cols-2 md:gap-2">
                                    <div className="relative w-full mb-6 group">
                                        <div className="mb-2 block">
                                            <Label htmlFor="base" value="Adress 1" />
                                        </div>
                                        <TextInput
                                            id="adress1"
                                            type="text"
                                            sizing="md"
                                            {...formik.getFieldProps('address1')}
                                        />
                                    </div>
                                    <div>
                                        <div className="mb-2 block">
                                            <Label htmlFor="base" value="Adress 2" />
                                        </div>
                                        <TextInput
                                            id="adress2"
                                            type="text"
                                            sizing="md"
                                            {...formik.getFieldProps('address2')}
                                        />
                                    </div>
                                </div>
                                <div className="grid md:grid-cols-2 md:gap-2">
                                    <div className="relative w-full mb-6 group">
                                        <div className="mb-2 block">
                                            <Label htmlFor="country" value="Country" />
                                        </div>
                                        <Select
                                            id="countries"
                                            required
                                            {...formik.getFieldProps('country')}
                                            name="country"
                                            sizing="md"
                                            helperText={
                                                // eslint-disable-next-line react/jsx-fragments
                                                <React.Fragment>
                                                    <CustomError name="country" component="div" />
                                                </React.Fragment>
                                            }
                                        >
                                            {data.country &&
                                            countries.every((country) => country.label !== data.country) ? (
                                                <option selected disabled>
                                                    {data.country}
                                                </option>
                                            ) : (
                                                <option disabled>Select country</option>
                                            )}
                                            {countries.map((country) => (
                                                <option
                                                    key={country.code}
                                                    value={country.label}
                                                    selected={country.label === data.country}
                                                >
                                                    {country.label}
                                                </option>
                                            ))}
                                        </Select>
                                    </div>
                                    <SelectField name="city" />
                                    <div className="relative w-full mb-6 group">
                                        <div className="mb-2 block">
                                            <Label htmlFor="base" value="Zip" />
                                        </div>
                                        <TextInput
                                            id="company"
                                            type="text"
                                            sizing="md"
                                            {...formik.getFieldProps('zip')}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-wrap gap-4 justify-between py-2">
                                    <div>
                                        <Button
                                            pill
                                            color="failure"
                                            onClick={() => {
                                                setEditCordinate(false);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                            pill
                                            type="submit"
                                            disabled={
                                                !formik.values.city || !formik.values.country || saveCordinateLoader
                                            }
                                            isProcessing={saveCordinateLoader}
                                        >
                                            <FaSave className="mr-2 h-5 w-5" />
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            ) : (
                <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                    <li className="py-3 sm:py-4">
                        <div className="flex items-center space-x-4">
                            <div className="min-w-0 flex-1">
                                <p className="truncate font-medium text-gray-900 dark:text-white">Full Name:</p>
                            </div>
                            <div className="inline-flex items-center font-semibold text-gray-900 dark:text-white">
                                {data.firstName ?? '--'} {data.lastName ?? '--'}
                            </div>
                        </div>
                    </li>
                    <li className="py-3 sm:py-4">
                        <div className="flex items-center space-x-4">
                            <div className="min-w-0 flex-1">
                                <p className="truncate font-medium text-gray-900 dark:text-white">Address 1:</p>
                            </div>
                            <div className="inline-flex items-center font-semibold text-gray-900 dark:text-white">
                                {data.address1 ?? '--'}
                            </div>
                        </div>
                    </li>
                    <li className="py-3 sm:py-4">
                        <div className="flex items-center space-x-4">
                            <div className="min-w-0 flex-1">
                                <p className="truncate font-medium text-gray-900 dark:text-white">Address 2:</p>
                            </div>
                            <div className="inline-flex font-semibold text-gray-900 dark:text-white">
                                {data.address1 ?? '--'}
                            </div>
                        </div>
                    </li>
                    <li className="py-3 sm:py-4">
                        <div className="flex items-center space-x-4">
                            <div className="min-w-0 flex-1">
                                <p className="truncate font-medium text-gray-900 dark:text-white">Country:</p>
                            </div>
                            <div className="inline-flex font-semibold text-gray-900 dark:text-white">
                                {data.country ?? '--'}
                            </div>
                        </div>
                    </li>
                    <li className="pt-3 pb-0 sm:pt-4">
                        <div className="flex items-center space-x-4">
                            <div className="min-w-0 flex-1">
                                <p className="truncate font-medium text-gray-900 dark:text-white">City :</p>
                            </div>
                            <div className="inline-flex font-semibold text-gray-900 dark:text-white">
                                {data.city ?? '--'}
                            </div>
                        </div>
                    </li>
                    <li className="pt-3 pb-0 sm:pt-4">
                        <div className="flex items-center space-x-4">
                            <div className="min-w-0 flex-1">
                                <p className="truncate font-medium text-gray-900 dark:text-white">Zip :</p>
                            </div>
                            <div className="inline-flex font-semibold text-gray-900 dark:text-white">
                                {data.zip ?? '--'}
                            </div>
                        </div>
                    </li>
                </ul>
            )}
            {!data.city && (
                <div className="w-fit mt-2">
                    <Alert color="failure" icon={MdReportGmailerrorred} rounded className="p-2">
                        <span className="text-xs">
                            Please verify the city with the client and update it before any action
                        </span>
                    </Alert>
                </div>
            )}
        </div>
    );
};
