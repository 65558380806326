import React from 'react';
import { ErrorMessage } from 'formik';
import { ErrorProps } from './CustomError.types';

const FormError: React.FC<ErrorProps> = ({ name }) => {
    return (
        <>
            <ErrorMessage
                name={name}
                render={(msg) => (
                    <span>
                        {typeof msg === 'string' ? (
                            <span className="text-xs text-red-600 font-semibold">{msg}</span>
                        ) : null}
                    </span>
                )}
            />
        </>
    );
};

export default FormError;
