import * as Yup from 'yup';

const errorType = 'Field must be a number';
const errorRequired = 'Required Field';

export const BankConfigUSDlocal = Yup.object().shape({
    accountHolder: Yup.string().required(errorRequired),
    accountType: Yup.string().required(errorRequired),
    routingNumber: Yup.number().typeError(errorType).required(errorRequired),
    accountNumber: Yup.number().typeError(errorType).required(errorRequired),
    accountCurrency: Yup.string().required(errorRequired),
    paymentType: Yup.string().required(errorRequired),
    description: Yup.string().optional(),
});

export const BankConfigUSDInternational = Yup.object().shape({
    accountHolder: Yup.string().required(errorRequired),
    swiftBic: Yup.string().required(errorRequired),
    routingNumber: Yup.number().typeError(errorType).required(errorRequired),
    accountNumber: Yup.number().typeError(errorType).required(errorRequired),
    accountCurrency: Yup.string().required(errorRequired),
    paymentType: Yup.string().required(errorRequired),
    description: Yup.string().optional(),
});

export const BankConfigEur = Yup.object().shape({
    accountHolder: Yup.string().required(errorRequired),
    swiftBic: Yup.string().required(errorRequired),
    iban: Yup.string().required(errorRequired),
    accountCurrency: Yup.string().required(errorRequired),
    paymentType: Yup.string().required(errorRequired),
    accountNumber: Yup.number().typeError(errorType).required(errorRequired),
    description: Yup.string().optional(),
});

export const UpdateBankConfig = Yup.object().shape({
    email: Yup.string().email('Email is invalid').optional(),
    type: Yup.string().typeError(errorType).optional(),
    accountHolder: Yup.string().typeError(errorType).optional(),
    accountType: Yup.string().typeError(errorType).optional(),
    routingNumber: Yup.number().typeError(errorType).optional(),
    accountNumber: Yup.number().typeError(errorType).optional(),
    currency: Yup.string().typeError(errorType).optional(),
});

export const UpdateFinanceService = Yup.object().shape({
    email: Yup.string().email('Email is invalid').optional(),
    servicetype: Yup.string().typeError(errorType).optional(),
});

export const FinancialService = Yup.object().shape({
    email: Yup.string().email('Email is invalid').required(),
    serviceType: Yup.string().typeError(errorType).required(errorRequired),
});
