export const MessageFileWrapper = (received: boolean) => {
    return {
        display: 'flex',
        justifyContent: received ? 'flex-start' : 'flex-end',
        alignItems: 'flex-start',
        gap: '5px',
    };
};

export const fileWrapper = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
};

export const fileImage = (received: boolean) => {
    return {
        color: received ? 'secondaryBackground' : 'primary.main',
        width: '50px',
        height: '50px',
    };
};
