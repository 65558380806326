/* eslint-disable react/jsx-fragments */
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { changePasswordSchema } from 'utils/validations/accountInformationValidation';
import CustomError from 'components/customError/CustomError';
import CustomBtn from 'components/customButton/customBtn';
import toast from 'react-hot-toast';
import PasswordInput from 'components/inputField/passwordInput';
import { UserId } from 'pages/profileSetting/profileSettings.types';
import { updateAccountManager } from 'services/accountManager/accountManagerProfile';

const PasswordInfo: React.FC<UserId> = ({ id }) => {
    const [profilesettingLoader, setProfileSettingLoader] = useState(false);

    const updatePassword = (values: any) => {
        setProfileSettingLoader(true);
        updateAccountManager(id, values)
            .then(() => {
                toast.success('Password updated successully');
                setProfileSettingLoader(false);
            })
            .catch((err) => {
                toast.error(err.response.data.errors.message);
                setProfileSettingLoader(false);
            });
    };

    return (
        <>
            <Formik
                initialValues={{
                    password: '',
                }}
                onSubmit={(values, onSubmitProp) => {
                    updatePassword(values);
                    onSubmitProp.setSubmitting(false);
                    onSubmitProp.resetForm();
                }}
                validationSchema={changePasswordSchema}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit} placeholder="">
                        <PasswordInput
                            {...formik.getFieldProps('password')}
                            name="password"
                            colorLabel={formik.errors.password ? 'failure' : ''}
                            helperText={<CustomError name="password" component="div" />}
                            label="New Password"
                        />

                        <div className="flex justify-end">
                            <CustomBtn
                                type="submit"
                                variant="primary"
                                disabled={profilesettingLoader}
                                isProcessing={profilesettingLoader}
                            >
                                Save Changes
                            </CustomBtn>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default PasswordInfo;
