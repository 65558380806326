/* eslint-disable react/style-prop-object */
/* eslint-disable no-underscore-dangle */
import { Modal, Tabs } from 'flowbite-react';
import React, { useState } from 'react';
import { FaUserEdit } from 'react-icons/fa';
import StatusAccount from 'components/status/statusAccount';
import { ICallAgentActions } from '../callAgent.types';
import CallAgentAccountManagment from '../updateCallAgent/callAgentAccountManagment';
import CallAgentPersonalInfo from '../updateCallAgent/callAgentPersonalInfo';
import CallAgentWorkingTime from '../updateCallAgent/callAgentWorkingTime';

const CallAgentActions = (props: ICallAgentActions) => {
    const [ModalEditCallAgent, setModalEditCallAgent] = useState(false);

    const EditCallAgent = () => {
        setModalEditCallAgent(true);
    };
    const EditCallAgentClose = () => {
        setModalEditCallAgent(false);
    };

    const { callAgent, setRows, currentPage, currentFilter } = props;
    return (
        <>
            <div>
                <FaUserEdit className="h-5 w-5 cursor-pointer" onClick={EditCallAgent} />
            </div>
            <Modal dismissible show={ModalEditCallAgent} popup onClose={EditCallAgentClose}>
                <Modal.Header>
                    <StatusAccount type="others" label={callAgent.status} />
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        aria-label="Tabs with icons"
                        style="underline"
                        theme={{
                            tablist: {
                                tabitem: {
                                    base: 'flex items-center justify-center p-4 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 ',
                                },
                            },
                        }}
                    >
                        <Tabs.Item title="Account Management">
                            <CallAgentAccountManagment
                                status={callAgent.status}
                                id={callAgent._id}
                                setRows={setRows}
                                currentPage={currentPage}
                                currentFilter={currentFilter}
                            />
                        </Tabs.Item>
                        <Tabs.Item title="Personal Information">
                            <CallAgentPersonalInfo
                                callAgent={callAgent}
                                setRows={setRows}
                                currentPage={currentPage}
                                currentFilter={currentFilter}
                            />
                        </Tabs.Item>
                        <Tabs.Item title="Working Time">
                            <CallAgentWorkingTime
                                callAgent={callAgent}
                                setRows={setRows}
                                currentPage={currentPage}
                                currentFilter={currentFilter}
                            />
                        </Tabs.Item>
                    </Tabs>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default CallAgentActions;
