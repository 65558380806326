import React from 'react';
import { Badge } from 'flowbite-react';
import { vnlinWarehouses } from 'utils/helpers/warehouses';
import { IResellerStock } from 'services/admin/resellerStock';
import { TableColDef, IStausInterface } from '../../../../components/tables/tables.types';

export interface IStockActions {
    stock: IResellerStock;
    setRows: React.Dispatch<React.SetStateAction<IResellerStock[]>>;
    currentPage?: number;
    currentFilter?: Record<string, any>;
}
export const columns: TableColDef[] = [
    {
        field: 'resellerProduct',
        headerName: 'Product',
        fieldType: 'combo',
        child: ['name'],
        classField: 'whitespace-nowrap font-medium text-gray-900 dark:text-white',
    },
    {
        field: 'country',
        headerName: 'country',
    },
    {
        field: 'warehouse',
        headerName: 'warehouse',
        fieldType: 'render',
        renderCell: (params) => (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <p>{vnlinWarehouses[params.warehouse as '64' | '90' | '91' | '99'].name}</p>
            </div>
        ),
    },
    {
        field: 'resellerProduct',
        headerName: 'SKU',
        fieldType: 'combo',
        child: ['sku'],
        classField: 'whitespace-nowrap font-medium text-gray-900 dark:text-white',
    },
    {
        field: 'status',
        headerName: 'Status',
        fieldType: 'status',
    },
    {
        field: 'quantity',
        headerName: 'Quantity',
        classField: 'whitespace-nowrap font-medium text-gray-900 dark:text-white',
    },
    {
        field: 'availableQuantity',
        headerName: 'Available Quantity',
    },

    {
        field: 'outsideQuantity',
        headerName: 'Outside Quantity',
    },
    {
        field: 'deliveredQuantity',
        headerName: 'Delivered Quantity',
    },

    {
        field: 'Action',
        actionType: 'ResellerStock',
        headerName: 'Actions',
    },
];
export const statusDisplay: { [keys: string]: IStausInterface } = {
    1: {
        statusIcon: (
            <Badge color="warning" size="sm" className=" break-all">
                Preparing stock
            </Badge>
        ),
        statusLabel: 'Preparing stock',
    },
    2: {
        statusIcon: (
            <Badge color="info" size="sm" className=" break-all">
                Ready for pickup
            </Badge>
        ),
        statusLabel: 'Ready for pickup',
    },
    3: {
        statusIcon: (
            <Badge color="purple" size="sm" className=" break-all">
                Stock being shipped
            </Badge>
        ),
        statusLabel: 'Stock being shipped',
    },
    30: {
        statusIcon: (
            <Badge color="purple" size="sm" className="break-all">
                Stock received by carrier{' '}
            </Badge>
        ),
        statusLabel: 'Stock received by carrier ',
    },
    31: {
        statusIcon: (
            <Badge color="purple" size="sm" className="break-all">
                Sent to GuangZhou Airport{' '}
            </Badge>
        ),
        statusLabel: 'Sent to GuangZhou Airport ',
    },
    32: {
        statusIcon: (
            <Badge color="purple" size="sm" className="break-all">
                Instation Scan GZ{' '}
            </Badge>
        ),
        statusLabel: 'Instation Scan GZ ',
    },
    33: {
        statusIcon: (
            <Badge color="purple" size="sm" className="break-all">
                Arrived in loading warehouse{' '}
            </Badge>
        ),
        statusLabel: 'Arrived in loading warehouse ',
    },
    34: {
        statusIcon: (
            <Badge color="purple" size="sm" className="break-all">
                Sent To HongKong{' '}
            </Badge>
        ),
        statusLabel: 'Sent To HongKong ',
    },
    35: {
        statusIcon: (
            <Badge color="purple" size="sm" className="break-all">
                Instation Scan HK{' '}
            </Badge>
        ),
        statusLabel: 'Instation Scan HK ',
    },
    36: {
        statusIcon: (
            <Badge color="purple" size="sm" className="break-all">
                Departed on Flight{' '}
            </Badge>
        ),
        statusLabel: 'Departed on Flight ',
    },
    37: {
        statusIcon: (
            <Badge color="purple" size="sm" className="break-all">
                Arrived destination Airport{' '}
            </Badge>
        ),
        statusLabel: 'Arrived destination Airport ',
    },
    38: {
        statusIcon: (
            <Badge color="purple" size="sm" className="break-all">
                Customs clearance start{' '}
            </Badge>
        ),
        statusLabel: 'Customs clearance start ',
    },
    39: {
        statusIcon: (
            <Badge color="purple" size="sm" className="break-all">
                Customs Released_Import{' '}
            </Badge>
        ),
        statusLabel: 'Customs Released_Import ',
    },
    40: {
        statusIcon: (
            <Badge color="purple" size="sm" className="break-all">
                Arrive to headquarters warehouse{' '}
            </Badge>
        ),
        statusLabel: 'Arrive to headquarters warehouse ',
    },

    4: {
        statusIcon: (
            <Badge color="success" size="sm" className=" break-all">
                Stock ready
            </Badge>
        ),
        statusLabel: 'Stock ready',
    },
};
export const stockStatusDisplay: { [keys: string]: IStausInterface } = {
    1: {
        statusIcon: (
            <Badge color="failure" size="sm" className=" break-all">
                Not Available
            </Badge>
        ),
        statusLabel: 'Not Available',
    },
    2: {
        statusIcon: (
            <Badge color="success" size="sm" className=" break-all">
                Available
            </Badge>
        ),
        statusLabel: 'Available',
    },
};
