/* eslint-disable no-underscore-dangle */
import { Dropdown } from 'flowbite-react';
import React, { useState } from 'react';
import { IStockShipment, updateStockShipment } from 'services/admin/stockShipment';
import { getStockStatus } from 'utils/helpers/status';
import toast from 'react-hot-toast';
import { PickUpDrawer } from 'components/pickUpDrawer/pickUpDrawer';
import { StatusBadge } from 'utils/helpers/statusBadge';
import { PiCaretUpDownBold } from 'react-icons/pi';
import { stockShipmentsStatus } from './utils';

const ShipmentStatus: React.FC<{
    shipmentData: IStockShipment;
    refetchShipments: () => void;
}> = ({ shipmentData, refetchShipments }) => {
    const [openModal, setOpenModal] = useState<boolean>(false);

    const handleStatusClick = (newStatus: string) => {
        if (newStatus === '2') {
            setOpenModal(true);
        } else {
            updateStockShipment({
                // eslint-disable-next-line no-underscore-dangle
                id: shipmentData._id,
                newStatus: Number(newStatus),
            })
                .then(() => {
                    toast.success('Shipment updated successfuly');
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.errors?.message);
                })
                .finally(() => {
                    refetchShipments();
                });
        }
    };

    return (
        <>
            <Dropdown
                inline
                arrowIcon={false}
                label={
                    <StatusBadge
                        color={
                            stockShipmentsStatus.find((elt) => elt.value === shipmentData?.status?.toString())?.color
                        }
                        style={
                            stockShipmentsStatus.find((elt) => elt.value === shipmentData?.status?.toString())?.color
                        }
                        Icon={PiCaretUpDownBold}
                        name={getStockStatus(shipmentData?.status as number)}
                    >
                        {getStockStatus(shipmentData?.status as number)}
                    </StatusBadge>
                }
            >
                {stockShipmentsStatus.map((action) => {
                    return (
                        <Dropdown.Item
                            disabled={action.value === shipmentData?.status?.toString()}
                            onClick={() => handleStatusClick(action.value)}
                            key={action.label}
                            className="gap-2"
                        >
                            {action.label}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown>

            {openModal && (
                <PickUpDrawer
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    key={shipmentData?._id}
                    shipmentsData={[shipmentData]}
                    refetchShipments={refetchShipments}
                />
            )}
        </>
    );
};

export default ShipmentStatus;
