import axios from 'utils/axios';
import { getBearerToken } from 'utils/helpers/auth';

export const getCallAgent = async (id: string) => {
    const callAgent = await axios.get(`/callagents/${id}`, getBearerToken());
    return callAgent;
};

export const updateCallAgent = async (id: any, data: any) => {
    const callAgentUpdate = await axios.put(`/callagents/${id}`, data, getBearerToken());
    return callAgentUpdate;
};
