import axios from 'utils/axios';
import { getBearerToken } from 'utils/helpers/auth';

export const getCallAgentAdmin = async (id: string) => {
    const callAgentAdmin = await axios.get(`/callcenteradmins/callagentsadmin/${id}`, getBearerToken());
    return callAgentAdmin;
};

export const updateCallAgentAdmin = async (id: any, data: any) => {
    const callAgentAdminUpdate = await axios.put(`/callcenteradmins/callagentsadmin/${id}`, data, getBearerToken());
    return callAgentAdminUpdate;
};
