/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box, Divider } from '@mui/material';
import MessageFile from 'components/messageFile/MessageFile';
import MessageInput from 'components/messageInput/MessageInput';
import MessageText from 'components/messageText/MessageText';
import React, { useEffect, useState } from 'react';
import { getCurrentUser } from 'utils/helpers/auth';
import { socket } from 'utils/socketIO';
import { LoadPreviousMessages, LoadPreviousMessagesWrapper, MessageTexts } from './chat.styles';

const SupportChat = ({ customerId, chatId }: { customerId: string; chatId: string }) => {
    const [currentRef, setCurrentRef] = useState('last');
    const [convMessages, setConvMessages] = useState<any>([]);
    const myRef = React.useRef<any>();
    const [limit, setLimit] = useState(0);
    const [totalMessages, setTotalMessages] = useState(0);
    useEffect(() => {
        myRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [convMessages]);
    useEffect(() => {
        // Creates a WebSocket connection
        socket.emit(
            'joinSupportChat',
            JSON.stringify({
                user: getCurrentUser()._id,
                recieverId: customerId,
            }),
        );

        socket.on('paginate-support-messages', (data: any) => {
            if (data.totalDocs) setTotalMessages(data.totalDocs);
            if (data.limit) setLimit(data.limit);
            if (data.docs) {
                setConvMessages(data.docs);
            }
            setCurrentRef('last');
        });

        socket.on('new-support-message', (data: any) => {
            if (data.docs && (data.docs as any[]).every((doc) => doc.chat === chatId)) {
                setConvMessages((conv: any) => [...data.docs, ...conv]);
                if (getCurrentUser()._id !== JSON.parse(data).docs[0].to) {
                    socket.emit('newChatMessage', {
                        user: getCurrentUser()._id,
                        about: 23, // new support message
                        customerId,
                        messageId: data.docs[0]._id,
                    });
                }
            }

            setCurrentRef('last');
        });

        return () => {
            socket.off('paginate-support-messages');
            socket.off('new-support-message');
        };
    }, [customerId]);

    const [fileName, setFileName] = useState('');
    const [fileSize, setFileSize] = useState(0);

    const [progressCount, setProgressCount] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [displayuploadingFile, setDisplayUploadingFile] = useState(false);

    useEffect(() => {
        setUploading(progressCount < 100 && progressCount > 0);
    }, [progressCount, displayuploadingFile]);

    return (
        <div className="h-[90vh] bg-background-secondary relative  ">
            <div className="h-[calc(90vh-(104px+0px-50px))] bg-background-secondary  overflow-hidden hover:overflow-y-scroll">
                {limit < totalMessages && (
                    <Box sx={LoadPreviousMessagesWrapper}>
                        <Box
                            onClick={() => {
                                socket.emit(
                                    'paginateSupportMessages',
                                    JSON.stringify({
                                        limit: limit + 20,
                                        recieverId: customerId,
                                        user: getCurrentUser()._id,
                                    }),
                                );
                                setLimit((lmt) => lmt + 20);
                                setCurrentRef('first');
                            }}
                            sx={LoadPreviousMessages}
                        >
                            <ExpandLessIcon /> Click to load previous messages
                        </Box>
                    </Box>
                )}

                {currentRef === 'first' && <Box ref={myRef} />}
                {convMessages &&
                    convMessages
                        .slice(0)
                        .reverse()
                        .map((message: any) => (
                            <Box sx={MessageTexts}>
                                {message.message ? (
                                    <MessageText
                                        entity={message.from}
                                        message={message.message}
                                        received={message.onModelFrom === 'Customer'}
                                        key={message.id}
                                        timestamp={message.createdAt}
                                    />
                                ) : message.file ? (
                                    <MessageFile
                                        entity={message.from}
                                        file={message.file}
                                        received={message.onModelFrom === 'Customer'}
                                        timestamp={message.createdAt}
                                        fileName={message.fileName}
                                        fileSize={message.fileSize}
                                    />
                                ) : null}
                            </Box>
                        ))}
                {currentRef === 'last' && <Box ref={myRef} />}
                {uploading && displayuploadingFile && (
                    <Box sx={MessageTexts}>
                        <MessageFile
                            file="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7wNPpW45HWrITlj89fzAHTMQvxzolD3mx_-Swrw2tvQ&s"
                            received={false}
                            fileName={fileName}
                            fileSize={fileSize}
                            uploading={uploading}
                            progressCount={progressCount}
                        />
                    </Box>
                )}
            </div>
            <Divider />
            <MessageInput
                socketRef={socket}
                customerId={customerId}
                action="newSupportMessage"
                setProgressCount={setProgressCount}
                setFileName={setFileName}
                setFileSize={setFileSize}
                setDisplayUploadingFile={setDisplayUploadingFile}
            />
        </div>
    );
};

export default SupportChat;
