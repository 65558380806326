// export const inputBox = {
//     fontSize: '12px',
//     padding: '5px 8px 4px 8px',
//     borderRadius: '8px',
//     border: '0.5px solid #666',
//     cursor: 'pointer',
// };

export const inputBox = {
    // fontSize: '12px',
    // padding: '5px 8px 4px 8px',
    // borderRadius: '8px',
    // border: '0.5px solid #666',
    cursor: 'pointer',
    color: 'notifcationRingColor',
};

export const calendarWrap = {
    display: 'inline-block',
    position: 'relative',
};

export const calendarElement = {
    backgroundColor: 'red',
};
