export const messagesPageStyle = {
    backgroundColor: 'secondaryBackground',
    height: '90vh',
    // borderTop: '1px solid',
    // borderColor: '#DBDBDC',
};

export const messagesContainerStyle = { borderRight: '1px solid', borderColor: 'secondaryBackground' };

export const MessageBoxStyle = {
    height: '88vh',
    overflow: 'hidden',
    '&:hover': {
        overflowY: 'scroll',
    },
};

export const MessageTextsContainer = (haveHeader: boolean) => {
    const height = haveHeader ? '69vh' : '80vh';
    return {
        // backgroundColor: 'red',
        padding: '10px',
        height,
        overflow: 'hidden',
        '&:hover': {
            overflowY: 'scroll',
        },
    };
};

export const MessageTexts = { margin: '10px' };
export const LoadPreviousMessagesWrapper = {
    display: 'flex',
    justifyContent: 'center',
    padding: '5px',
    height: '50px',
};

export const LoadPreviousMessages = {
    backgroundColor: 'primaryBackground',
    padding: '10px',
    borderRadius: '10px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};
