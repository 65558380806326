import { type AxiosResponse } from 'axios';
import axios from 'utils/axios';
import { IPaginationData } from 'utils/Interfaces/types';
import { getBearerToken } from '../../utils/helpers/auth';

interface IdateRange {
    startDate: string;
    endDate: string;
}

interface IOptions {
    status?: number;
    createdAt?: object;
    type?: string;
    dateRange?: IdateRange;
    source?: string;
    customer?: string;
    page?: number;
    limit?: number;
}
export interface IOrderDetails {
    orederRef: string;
    orderId: string;
    Orderfees?: number;
    orderFees: number;
    shippingFees: number;
    handleFees: number;
}
export type PaymentType = 'invoice' | 'product' | 'order' | 'restock';
export type PaymentSource = 'wallet' | 'bank' | 'walletBank' | 'other';
export interface IPayments {
    // payment: {
    _id: string;
    type: PaymentType;
    amount: number;
    source: PaymentSource;
    accountName: string;
    status: number;
    verificationImage?: string;
    product: any;
    walletOperation: any;
    walletAmount?: number;
    bankAmount?: number;
    operationCurrency?: string;
    ordersDetails: IOrderDetails[];
    //  };
}

export interface IPayment {
    payment: IPayments;
}

interface IData {
    status: number;
}
export const paymetSources = [
    { value: 'wallet', label: 'Wallet Operation' },
    { value: 'bank', label: 'Bank Transaction' },
    { value: 'walletBank', label: 'Wallet Operation and Bank Transaction' },
];
export const paymentTypes = [
    { value: 'product', label: 'Product Sourcing' },
    { value: 'restock', label: 'Restock' },
    { value: 'invoice', label: 'Invoice Payment' },
    { value: 'order', label: 'Order Fees' },
    { value: 'resellerProduct', label: 'Reseller Product Fees' },
    { value: 'resellerProduct', label: 'Reseller Product Fees' },
];
export interface IPaginatedPayment extends IPaginationData<IPayments> {}

export const getpayments = async (options: IOptions) => {
    const config = getBearerToken();
    const response = await axios.post<IPaginatedPayment, AxiosResponse<{ response: IPaginatedPayment }>, IOptions>(
        `/admin/payment/filter`,
        options,
        config,
    );
    return response;
};

export const getpayment = async (id: string) => {
    const config = getBearerToken();
    const response = await axios.get<IPayment>(`/admin/payment/${id}`, config);
    return response;
};

export const updateBankPayment = async (id: string, data: IData) => {
    const config = getBearerToken();
    const response = await axios.put(`/admin/payment/${id}`, data, config);
    return response;
};
