import axios from 'utils/axios';
import { getBearerToken } from 'utils/helpers/auth';

export const getAccountManager = async (id: string) => {
    const accountManager = await axios.get(`/accountmanagers/${id}`, getBearerToken());
    return accountManager;
};

export const updateAccountManager = async (id: any, data: any) => {
    const accountManagerUpdate = await axios.put(`/accountmanagers/${id}`, data, getBearerToken());
    return accountManagerUpdate;
};
