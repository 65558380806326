/* eslint-disable react/jsx-fragments */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import CustomError from 'components/customError/CustomError';
import toast from 'react-hot-toast';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Label, TextInput } from 'flowbite-react';
import CustomBtn from 'components/customButton/customBtn';
import { HiMail } from 'react-icons/hi';
import { changeUserInfos } from 'utils/validations/personalInformationValidation';
import { getAllCallAgents, updateCallAgent } from 'services/admin/callAgent';
import PhoneInput from 'react-phone-number-input';
import AutoComplete from 'components/autocomplete/autoComplete';
import { getAllCallCenters } from 'services/admin/callCenter';
import { ICallCenter } from 'pages/callCenterList/callCenter.types';
import { ICallAgentActions } from '../callAgent.types';
import CallAgentPassword from './callAgentPassword';

export interface IdateRange {
    startDate: string | null | Date;
    endDate: string | null | Date;
}
const CallAgentPersonalInfo: React.FC<ICallAgentActions> = ({ callAgent, setRows, currentPage, currentFilter }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [callCenters, setCallCentersState] = useState<Array<ICallCenter>>([]);

    const personalInfo = (values: any) => {
        setIsLoading(true);
        updateCallAgent(callAgent._id, values)
            .then((res) => {
                toast.success(res.data.message);
                setIsLoading(false);
            })
            .then(() => {
                getAllCallAgents({ page: currentPage, ...currentFilter }).then((res) => {
                    setRows(res.data.docs);
                });
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message);
                setIsLoading(false);
            });
    };
    useEffect(() => {
        getAllCallCenters({ limit: 1000, status: 1 }).then((res) => {
            setCallCentersState(res.data.docs);
        });
    }, []);
    return (
        <>
            <Formik
                initialValues={{
                    firstName: callAgent.firstName,
                    lastName: callAgent.lastName,
                    phone: callAgent.phone,
                    email: callAgent.email,
                    callCenter: callAgent?.callCenter,
                }}
                onSubmit={(values) => {
                    personalInfo({ ...values });
                }}
                validationSchema={changeUserInfos}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit} placeholder="">
                        <div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="username4"
                                        className="dark:text-white"
                                        color={formik.errors.firstName ? 'failure' : ''}
                                        value="First name"
                                    />
                                </div>
                                <TextInput
                                    {...formik.getFieldProps('firstName')}
                                    name="firstName"
                                    color={formik.errors.firstName ? 'failure' : 'gray'}
                                    type="text"
                                    helperText={
                                        <React.Fragment>
                                            <CustomError name="firstName" component="div" />
                                        </React.Fragment>
                                    }
                                />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="username4"
                                        className="dark:text-white"
                                        color={formik.errors.lastName ? 'failure' : ''}
                                        value="Last Name"
                                    />
                                </div>
                                <TextInput
                                    {...formik.getFieldProps('lastName')}
                                    name="lastName"
                                    color={formik.errors.lastName ? 'failure' : 'gray'}
                                    type="text"
                                    helperText={
                                        <React.Fragment>
                                            <CustomError name="lastName" component="div" />
                                        </React.Fragment>
                                    }
                                />
                            </div>
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="username4"
                                    className="dark:text-white"
                                    color={formik.errors.phone ? 'failure' : ''}
                                    value="Phone Number"
                                />
                            </div>

                            <PhoneInput
                                id="phoneNumber"
                                name="phone"
                                value={String(formik.values.phone)}
                                onChange={(value) => formik.setFieldValue('phone', value)}
                                onBlur={formik.handleBlur('phone')}
                                placeholder="Enter a phone number"
                                className="mb-1"
                            />
                            <CustomError name="phone" component="div" />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="username4"
                                    className="dark:text-white"
                                    color={formik.errors.email ? 'failure' : ''}
                                    value="Email"
                                />
                            </div>
                            <TextInput
                                {...formik.getFieldProps('email')}
                                name="email"
                                color={formik.errors.email ? 'failure' : 'gray'}
                                icon={HiMail}
                                type="email"
                                helperText={
                                    <React.Fragment>
                                        <CustomError name="email" component="div" />
                                    </React.Fragment>
                                }
                            />
                        </div>
                        {callAgent.status !== 2 && (
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="username4"
                                        className="dark:text-white"
                                        color={formik.errors.email ? 'failure' : ''}
                                        value="Assign to a Call Center"
                                    />
                                </div>
                                <AutoComplete
                                    key="callCenterInput"
                                    {...formik.getFieldProps('callCenter')}
                                    name="callCenter"
                                    placeholder="Choose a Call Center"
                                    options={callCenters}
                                    defaultValue={callAgent?.callCenter[0]?.name}
                                    getOptionLabel={(option: any) => option?.name}
                                    onChange={(option) => {
                                        formik.setFieldValue('callCenter', [option.id]);
                                    }}
                                />
                            </div>
                        )}
                        <div className="flex justify-end">
                            <CustomBtn type="submit" variant="primary" isProcessing={isLoading} disabled={isLoading}>
                                Save Changes
                            </CustomBtn>
                        </div>
                    </Form>
                )}
            </Formik>
            <CallAgentPassword id={callAgent._id} />
        </>
    );
};

export default CallAgentPersonalInfo;
