/* eslint-disable no-underscore-dangle */
import toast from 'react-hot-toast';
import React, { useState } from 'react';
import CustomBtn from 'components/customButton/customBtn';
import PaymentsStatus from 'components/status/paymentsStatus';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import ImageProofVerification from 'components/ImagePoofVerification/ImageproodVerification';
import { currencyConverter } from 'utils/currencyConverter';
import { getpayments, updateBankPayment } from 'services/admin/payments';

import { Button, Table } from 'flowbite-react';
import { downloadSingleFileAsBase64 } from 'utils/helpers/productFilesDownloader';
import { IPaymentProps } from './payments.types';

const BankPayment: React.FC<IPaymentProps> = ({ paymentData, setRows, currentPage, currentFilter }) => {
    const [isLoading, setIsLoading] = useState(false);

    const getPaymentAfterUpdate = () => {
        getpayments({ page: currentPage, ...currentFilter }).then((res) => {
            const data = res.data.response;
            setRows(data.docs);
        });
    };
    /**
     * @param status
     * [
     * 2: accepted
     * 3: refused
     * ]
     * */
    const decideOnPayment = (status: 2 | 3) => {
        const feedbackToast = toast.loading('Updating payment...');
        setIsLoading(true);
        updateBankPayment(paymentData._id, {
            status,
        })
            .then(() => {
                setIsLoading(false);
                getPaymentAfterUpdate();
                if (status === 3) {
                    toast.success(
                        `Amount of  $${paymentData.amount} is rejected for account ${paymentData.accountName} `,
                        {
                            id: feedbackToast,
                        },
                    );
                } else if (status === 2) {
                    toast.success(`Amount of $${paymentData.amount} accepted for account ${paymentData.accountName} `, {
                        id: feedbackToast,
                    });
                }
            })
            .catch((err) => {
                setIsLoading(false);
                toast.error(err?.response?.data?.errors?.message || 'Something went wrong', { id: feedbackToast });
            });
    };

    const countFileTypes = (fileType: string) => {
        const fileExtension = fileType.split('.').pop()?.toLowerCase() || 'unknown';
        const groupedExtension =
            fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'jpg' ? 'Images' : fileExtension;
        return groupedExtension;
    };

    return (
        <>
            <div className="container mx-auto mb-2">
                <div className="grid grid-cols-2 gap-4 justify-items-center">
                    <div className="col-span-1 grid grid-cols-2 gap-2 items-center">
                        <div>Account Name:</div>
                        <div>{paymentData.accountName}</div>
                        <div>Status: </div>
                        <div>
                            <PaymentsStatus status={paymentData.status} />
                        </div>
                    </div>
                    <div className="col-span-1 grid grid-rows-2 gap-2 items-center">
                        <div>
                            <div className="col-span-1 grid grid-cols-2 gap-2 items-center">
                                <div>Declared Amount: </div>
                                <div>
                                    {currencyConverter(
                                        paymentData.amount,
                                        paymentData.operationCurrency ? paymentData.operationCurrency : 'USD',
                                    )}
                                </div>
                            </div>
                        </div>
                        <div>
                            {paymentData.source === 'walletBank' && paymentData.type === 'product' && (
                                <div>
                                    <div>
                                        <div>
                                            <div className="col-span-1 grid grid-cols-2 gap-2 items-center">
                                                <div>Wallet Amount: </div>
                                                <div>{currencyConverter(paymentData.walletAmount, 'USD')}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <div className="col-span-1 grid grid-cols-2 gap-2 items-center">
                                                <div>Bank Amount: </div>
                                                <div>
                                                    {currencyConverter(
                                                        paymentData.bankAmount,
                                                        paymentData.operationCurrency
                                                            ? paymentData.operationCurrency
                                                            : 'USD',
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {' '}
                {(paymentData.source === 'other' ||
                    paymentData.source === 'bank' ||
                    paymentData.source === 'walletBank') &&
                    (paymentData.type === 'product' || paymentData.type === 'restock') &&
                    paymentData.status === 1 && (
                        <div className="container mx-auto mb-1">
                            <div className="flex gap-3 justify-center">
                                <CustomBtn
                                    size="sm"
                                    variant="failure"
                                    disabled={isLoading}
                                    onClick={() => {
                                        decideOnPayment(3);
                                    }}
                                    isProcessing={isLoading}
                                >
                                    Reject
                                    <AiOutlineClose className="ml-1 w-5 h-5" />
                                </CustomBtn>

                                <CustomBtn
                                    size="sm"
                                    variant="success"
                                    disabled={isLoading}
                                    onClick={() => {
                                        decideOnPayment(2);
                                    }}
                                    isProcessing={isLoading}
                                >
                                    Confirm
                                    <AiOutlineCheck className="ml-1 w-5 h-5" />
                                </CustomBtn>
                            </div>
                        </div>
                    )}
            </div>
            <div className="mb-1">
                {paymentData && paymentData.source === 'wallet' ? (
                    <div className=" bg-blue-300 text-black flex justify-center rounded-xl">
                        <p>Paid with wallet, no Proof needed</p>
                    </div>
                ) : (
                    <div>
                        {countFileTypes(paymentData.verificationImage as string) === 'Images' ? (
                            ''
                        ) : (
                            <div className=" bg-green-300  text-black flex justify-center">
                                This proof is {countFileTypes(paymentData.verificationImage as string)}
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className="mb-1">
                {paymentData && paymentData.type === 'order' && (
                    <div className="text-black justify-center w-full max-h-48">
                        <div className="overflow-x-auto overflow-y-auto max-h-48">
                            <Table>
                                <Table.Head>
                                    <Table.HeadCell>Order Ref</Table.HeadCell>
                                    <Table.HeadCell>Fees</Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y">
                                    {paymentData?.ordersDetails?.map((orderInfo) => {
                                        return (
                                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                                <Table.Cell>{orderInfo.orederRef}</Table.Cell>
                                                <Table.Cell>
                                                    {orderInfo?.Orderfees?.toFixed(2) ||
                                                        orderInfo?.orderFees?.toFixed(2)}
                                                    $
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                )}
            </div>
            <div className="flex justify-end mb-1">
                <Button
                    disabled={paymentData.source === 'wallet'}
                    onClick={() =>
                        downloadSingleFileAsBase64(paymentData.verificationImage as string, paymentData.accountName)
                    }
                >
                    {paymentData.source === 'wallet' ? 'No proof to download' : 'Download Proof'}
                </Button>
            </div>

            <div className="flex justify-center items-center mt-2">
                <ImageProofVerification verifImage={paymentData} />
            </div>
        </>
    );
};

export default BankPayment;
