/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box, Divider } from '@mui/material';
import MessageFile from 'components/messageFile/MessageFile';
import MessageHeader from 'components/messageHeader/MessageHeader';
import MessageInput from 'components/messageInput/MessageInput';
import MessageText from 'components/messageText/MessageText';
import React, { useEffect, useState } from 'react';
import { getProductWithSkus } from 'services/admin/product';
import { getCurrentUser } from 'utils/helpers/auth';
import { getDesignImages } from 'utils/helpers/productsHelpers/filterImages';
import { socket } from 'utils/socketIO';
import { IProduct } from 'utils/Interfaces/products.interface';
import Package from '../../assets/package.png';
import { LoadPreviousMessages, LoadPreviousMessagesWrapper, MessageTexts } from './chat.styles';

const ProductChat = ({ productId, customerId, chatId }: { productId: string; customerId: string; chatId: string }) => {
    const [currentRef, setCurrentRef] = useState('last');
    const [convMessages, setConvMessages] = useState<any>([]);
    const myRef = React.useRef<any>();
    const [limit, setLimit] = useState(0);
    const [productInfo, setProductInfo] = useState<{ [key: string]: any }>({});
    const [totalMessages, setTotalMessages] = useState(0);
    useEffect(() => {
        myRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [convMessages]);
    useEffect(() => {
        // Creates a WebSocket connection

        socket.emit(
            'joinProductChat',
            JSON.stringify({
                product: productId,
                user: getCurrentUser()._id,
                customerId,
            }),
        );

        socket.on('paginate-product-messages', (data: any) => {
            if (JSON.parse(data).totalDocs) setTotalMessages(JSON.parse(data).totalDocs);
            if (JSON.parse(data).limit) setLimit(JSON.parse(data).limit);
            // setConvMessages(JSON.parse(data).docs);
            if (JSON.parse(data).docs) {
                setConvMessages(JSON.parse(data).docs);
            }
            setCurrentRef('last');
        });

        socket.on('new-product-message', (data: any) => {
            if (JSON.parse(data).docs && (JSON.parse(data).docs as any[]).every((doc) => doc.chat === chatId)) {
                setConvMessages((conv: any) => [...JSON.parse(data).docs, ...conv]);
                if (getCurrentUser()._id !== JSON.parse(data).docs[0].to) {
                    socket.emit('newChatMessage', {
                        user: getCurrentUser()._id,
                        about: 21, // new product message
                        customerId,
                        messageId: JSON.parse(data).docs[0]._id,
                    });
                }
            }

            setCurrentRef('last');
        });

        getProductWithSkus(productId).then((res) => {
            let skus = '';

            if (res.data.globalSKU && !res.data.variants?.length) {
                skus = `${res.data.globalSKU}`;
            } else if (res.data.variants.length > 0) {
                res.data.variants.forEach((variant) => {
                    if ((variant as IProduct).globalSKU) {
                        skus += `[${(variant as IProduct).name}: ${(variant as IProduct).globalSKU}] `;
                    }
                });
            }
            setProductInfo({
                image: getDesignImages(res.data) && res.data.design?.length ? getDesignImages(res.data) : Package,
                name: res.data.name,
                sku: skus,
                type: res.data.type,
                status: res.data.status,
                customer: res.data.customer,
                id: res.data._id,
                productInfo: res.data,
            });
        });
        return () => {
            socket.off('paginate-product-messages');
            socket.off('new-product-message');
        };
    }, [productId, customerId]);

    const [fileName, setFileName] = useState('');
    const [fileSize, setFileSize] = useState(0);

    const [progressCount, setProgressCount] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [displayuploadingFile, setDisplayUploadingFile] = useState(false);

    useEffect(() => {
        setUploading(progressCount < 100 && progressCount > 0);
    }, [progressCount, displayuploadingFile]);

    return (
        <div className="h-[90vh] bg-background-secondary relative  ">
            {productInfo && (
                <MessageHeader
                    productImage={productInfo.image}
                    productTitle={productInfo.name}
                    sku={productInfo.sku}
                    status={productInfo.status}
                    type={productInfo.type}
                    productId={productInfo.id}
                    customerId={productInfo.customer}
                    productInfo={productInfo}
                />
            )}
            <Divider />
            <div className="h-[calc(90vh-(174px+0px-50px))] bg-background-secondary  overflow-hidden hover:overflow-y-scroll">
                {limit < totalMessages && (
                    <Box sx={LoadPreviousMessagesWrapper}>
                        <Box
                            onClick={() => {
                                socket.emit(
                                    'paginateProductMessages',
                                    JSON.stringify({
                                        limit: limit + 20,
                                        product: productId,
                                        user: getCurrentUser()._id,
                                    }),
                                );
                                setLimit((lmt) => lmt + 20);
                                setCurrentRef('first');
                            }}
                            sx={LoadPreviousMessages}
                        >
                            <ExpandLessIcon /> Click to load previous messages
                        </Box>
                    </Box>
                )}
                {currentRef === 'first' && <Box ref={myRef} />}
                {convMessages &&
                    convMessages
                        .slice(0)
                        .reverse()
                        .map((message: any) => (
                            <Box sx={MessageTexts}>
                                {message.message ? (
                                    <MessageText
                                        entity={message.from}
                                        message={message.message}
                                        received={message.onModelFrom === 'Customer'}
                                        key={message.id}
                                        timestamp={message.createdAt}
                                    />
                                ) : message.file ? (
                                    <MessageFile
                                        entity={message.from}
                                        file={message.file}
                                        received={message.onModelFrom === 'Customer'}
                                        timestamp={message.createdAt}
                                        fileName={message.fileName}
                                        fileSize={message.fileSize}
                                    />
                                ) : null}
                            </Box>
                        ))}
                {currentRef === 'last' && <Box ref={myRef} />}
                {uploading && displayuploadingFile && (
                    <Box sx={MessageTexts}>
                        <MessageFile
                            file="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7wNPpW45HWrITlj89fzAHTMQvxzolD3mx_-Swrw2tvQ&s"
                            received={false}
                            fileName={fileName}
                            fileSize={fileSize}
                            uploading={uploading}
                            progressCount={progressCount}
                        />
                    </Box>
                )}
            </div>
            <Divider className="mb-0" />
            <div className="">
                {/* this is a plus when there is time i'll keep working on it  */}
                {/* <div className="flex gap-2 h-32 pt-2 pl-3 bg-background-secondary z-50 absolute bottom-[45px] border-t border-x rounded-t-md border-gray-700 w-full">
                    <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7wNPpW45HWrITlj89fzAHTMQvxzolD3mx_-Swrw2tvQ&s"
                        alt="Quotation Files"
                        className="cursor-pointer  "
                        style={{
                            objectFit: 'cover',
                            borderRadius: 8,
                            overflow: 'hidden',
                            width: 90,
                            height: 90,
                        }}
                    />
                    <div className="bg-gray-100 dark:bg-gray-900 rounded-2xl flex gap-3 px-2 py-2 h-[80%] items-center w-60">
                        <div className=" flex-shrink-0">
                            <img
                                src={FileFile}
                                alt="Quotation Files"
                                className="cursor-pointer "
                                style={{
                                    objectFit: 'cover',
                                    borderRadius: 8,
                                    overflow: 'hidden',
                                    width: 30,
                                    height: 30,
                                }}
                            />
                        </div>
                        <div>
                            <div className="break-words break-all text-black dark:text-white text-base font-normal ">
                                File name
                            </div>

                            <div className="text-sm font-normal font-mono">zert</div>
                        </div>
                    </div>
                </div> */}
                <div className="absolute bottom-0 w-full">
                    <MessageInput
                        socketRef={socket}
                        productId={productId}
                        action="newProductMessage"
                        setProgressCount={setProgressCount}
                        setFileName={setFileName}
                        setFileSize={setFileSize}
                        setDisplayUploadingFile={setDisplayUploadingFile}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProductChat;
