/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-fragments */
import FlagIcon from 'components/flags/flagIcon';
import CustomError from 'components/customError/CustomError';
import { Label, Modal, Select, TextInput, Textarea } from 'flowbite-react';
import { Form, Formik } from 'formik';
import { IFinanceService, ISimpleBank, addCountryConfiguration, getConfiguration } from 'services/admin/configuration';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import CustomBtn from 'components/customButton/customBtn';
import {
    BankConfigEur,
    BankConfigUSDInternational,
    BankConfigUSDlocal,
    FinancialService,
} from 'utils/validations/bankInformationsValidation';
import { logistioCurrencies } from 'utils/countryList';
import { AddBankConfig } from './bankConfiguration.types';

export const getCurrenciesContent = () => {
    const content = [];
    for (const item of logistioCurrencies) {
        content.push(
            <option key={item.code} value={item.code}>
                <FlagIcon flag={item?.code} /> {item?.label}
            </option>,
        );
    }
    return content;
};
const AddBankConfiguration: React.FC<AddBankConfig> = ({ open, configBank, setOpen, setConfigBank }) => {
    const [bankLoader, setBankLoader] = useState(false);
    const [addSwitcher, setAddSwitcher] = useState(0);
    const [accountCurrency, setAccountCurrency] = useState('');
    const [paymentType, setPaymentType] = useState('');

    const addBankConfiguration = (values: ISimpleBank) => {
        setBankLoader(true);
        let newValues;
        const toastLoader = toast.loading('Loading...');
        const simpleBankValues = configBank;
        if (configBank.simpleBank) {
            newValues = [...configBank?.simpleBank];
            newValues.push(values);
        } else {
            newValues = [values];
        }
        simpleBankValues.simpleBank = newValues;
        addCountryConfiguration({ bankInformations: simpleBankValues })
            .then((res) => {
                toast.success(res.data.message, { id: toastLoader });
                setBankLoader(false);
                getConfiguration().then((result) => {
                    setConfigBank(result?.data?.bankInformations);
                });
            })
            .catch((err) => {
                toast.error(err.response.data.errors.message, { id: toastLoader });
                setBankLoader(false);
            });
    };

    const addFinanceService = (values: IFinanceService) => {
        setBankLoader(true);
        let newValues;
        const toastLoader = toast.loading('Loading...');
        const financeServiceValues = configBank;
        if (configBank.financeService) {
            newValues = [...configBank?.financeService];
            newValues.push(values);
        } else {
            newValues = [values];
        }
        financeServiceValues.financeService = newValues;
        addCountryConfiguration({ bankInformations: financeServiceValues })
            .then((res) => {
                toast.success(res.data.message, { id: toastLoader });
                setBankLoader(false);
                getConfiguration().then((result) => {
                    setConfigBank(result?.data?.bankInformations);
                });
            })
            .catch((err) => {
                toast.error(err.response.data.errors.message, { id: toastLoader });
                setBankLoader(false);
            });
    };

    return (
        <>
            <Modal
                dismissible
                show={open}
                popup
                onClose={() => {
                    setOpen(false);
                    setAddSwitcher(0);
                    setAccountCurrency('');
                    setPaymentType('');
                }}
            >
                <Modal.Header>Bank Informations</Modal.Header>
                <Modal.Body className=" !p-0">
                    <div className="py-2 px-6 flex justify-center items-center">
                        <div>
                            <Select
                                onChange={(e) => {
                                    setAddSwitcher(Number(e.target.value));
                                }}
                            >
                                <option>Choose</option>
                                <option value={1}>Add Bank Information</option>
                                <option value={2}>Add Financial Service</option>
                            </Select>
                        </div>
                    </div>
                    {addSwitcher === 1 && (
                        <>
                            <div className="flex py-2 px-6 flex-col justify-center items-center ">
                                {/* <div className="mb-2 block">
                                    <Label className="dark:text-white" value="Account Currency" />
                                </div> */}

                                <Select
                                    id="currency"
                                    onChange={(e) => {
                                        setAccountCurrency(e.target.value);
                                    }}
                                >
                                    <option key="select" value="">
                                        Select currency
                                    </option>
                                    {getCurrenciesContent()}
                                </Select>
                            </div>
                            {accountCurrency === 'USD' && (
                                <>
                                    <div className="flex py-2 px-6 flex-col justify-center items-center">
                                        <Select
                                            onChange={(e) => {
                                                setPaymentType(e.target.value);
                                            }}
                                        >
                                            <option>Choose payment type</option>
                                            <option value="local">Local</option>
                                            <option value="international">International</option>
                                        </Select>
                                    </div>

                                    {paymentType === 'local' && (
                                        <Formik
                                            initialValues={{
                                                accountCurrency,
                                                paymentType,
                                                accountNumber: '',
                                                accountHolder: '',
                                                accountType: 'Checking',
                                                routingNumber: '',
                                                // description: '',
                                            }}
                                            onSubmit={(values, onSubmitProps) => {
                                                addBankConfiguration(values);
                                                setOpen(false);
                                                setAddSwitcher(0);
                                                setAccountCurrency('');
                                                setPaymentType('');
                                                onSubmitProps.setSubmitting(false);
                                                onSubmitProps.resetForm();
                                            }}
                                            validationSchema={BankConfigUSDlocal}
                                        >
                                            {(formik) => (
                                                <Form onSubmit={formik.handleSubmit} placeholder="">
                                                    <div className="py-2 px-6 flex flex-col gap-2 overflow-auto max-h-[37rem]">
                                                        <div className="grid gap-6 mb-6 md:grid-cols-2">
                                                            <div>
                                                                <div className="mb-2 block">
                                                                    <Label
                                                                        htmlFor="price3"
                                                                        className="dark:text-white"
                                                                        color={
                                                                            formik.touched.accountHolder &&
                                                                            formik.errors.accountHolder
                                                                                ? 'failure'
                                                                                : ''
                                                                        }
                                                                        value="Account Holder"
                                                                    />
                                                                </div>
                                                                <TextInput
                                                                    {...formik.getFieldProps('accountHolder')}
                                                                    name="accountHolder"
                                                                    color={
                                                                        formik.touched.accountHolder &&
                                                                        formik.errors.accountHolder
                                                                            ? 'failure'
                                                                            : 'gray'
                                                                    }
                                                                    type="text"
                                                                    helperText={
                                                                        <React.Fragment>
                                                                            <CustomError
                                                                                name="accountHolder"
                                                                                component="div"
                                                                            />
                                                                        </React.Fragment>
                                                                    }
                                                                />
                                                            </div>
                                                            <div>
                                                                <div>
                                                                    <div className="mb-2 block">
                                                                        <Label
                                                                            className="dark:text-white"
                                                                            value="Account Number"
                                                                        />
                                                                    </div>
                                                                    <TextInput
                                                                        {...formik.getFieldProps('accountNumber')}
                                                                        name="accountNumber"
                                                                        type="text"
                                                                        helperText={
                                                                            <React.Fragment>
                                                                                <CustomError
                                                                                    name="accountNumber"
                                                                                    component="div"
                                                                                />
                                                                            </React.Fragment>
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <div>
                                                                    <div className="mb-2 block">
                                                                        <Label
                                                                            className="dark:text-white"
                                                                            value="ACH Routing Number"
                                                                        />
                                                                    </div>
                                                                    <TextInput
                                                                        {...formik.getFieldProps('routingNumber')}
                                                                        name="routingNumber"
                                                                        type="text"
                                                                        helperText={
                                                                            <React.Fragment>
                                                                                <CustomError
                                                                                    name="routingNumber"
                                                                                    component="div"
                                                                                />
                                                                            </React.Fragment>
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <div>
                                                                    <div className="mb-2 block">
                                                                        <Label
                                                                            className="dark:text-white"
                                                                            value="Description"
                                                                        />
                                                                    </div>
                                                                    <Textarea
                                                                        {...formik.getFieldProps('description')}
                                                                        name="description"
                                                                        id="comment"
                                                                        placeholder="Leave a description..."
                                                                        helperText={
                                                                            <React.Fragment>
                                                                                <CustomError
                                                                                    name="description"
                                                                                    component="div"
                                                                                />
                                                                            </React.Fragment>
                                                                        }
                                                                        rows={4}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex gap-2 justify-between py-2 px-2 items-center rounded-b-lg  bg-background-secondary">
                                                        <CustomBtn
                                                            variant="failure"
                                                            outline
                                                            pill
                                                            type="reset"
                                                            onClick={() => {
                                                                setOpen(false);
                                                                formik.resetForm();
                                                                setAddSwitcher(0);
                                                            }}
                                                        >
                                                            Cancel
                                                        </CustomBtn>
                                                        <CustomBtn
                                                            pill
                                                            variant="primary"
                                                            type="submit"
                                                            disabled={
                                                                !formik.isValid ||
                                                                !formik.dirty ||
                                                                formik.isSubmitting ||
                                                                bankLoader
                                                            }
                                                            isProcessing={bankLoader}
                                                        >
                                                            Add
                                                        </CustomBtn>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    )}
                                    {paymentType === 'international' && (
                                        <Formik
                                            initialValues={{
                                                accountCurrency,
                                                paymentType,
                                                accountNumber: '',
                                                accountHolder: '',
                                                swiftBic: '',
                                                routingNumber: '',
                                                // description: '',
                                            }}
                                            onSubmit={(values, onSubmitProps) => {
                                                addBankConfiguration(values);
                                                setOpen(false);
                                                setAddSwitcher(0);
                                                setAccountCurrency('');
                                                setPaymentType('');
                                                onSubmitProps.setSubmitting(false);
                                                onSubmitProps.resetForm();
                                            }}
                                            validationSchema={BankConfigUSDInternational}
                                        >
                                            {(formik) => (
                                                <Form onSubmit={formik.handleSubmit} placeholder="">
                                                    <div className="py-2 px-6 flex flex-col gap-2 overflow-auto max-h-[37rem]">
                                                        <div className="grid gap-6 mb-6 md:grid-cols-2">
                                                            <div>
                                                                <div className="mb-2 block">
                                                                    <Label
                                                                        htmlFor="price3"
                                                                        className="dark:text-white"
                                                                        color={
                                                                            formik.touched.accountHolder &&
                                                                            formik.errors.accountHolder
                                                                                ? 'failure'
                                                                                : ''
                                                                        }
                                                                        value="Account Holder"
                                                                    />
                                                                </div>
                                                                <TextInput
                                                                    {...formik.getFieldProps('accountHolder')}
                                                                    name="accountHolder"
                                                                    color={
                                                                        formik.touched.accountHolder &&
                                                                        formik.errors.accountHolder
                                                                            ? 'failure'
                                                                            : 'gray'
                                                                    }
                                                                    type="text"
                                                                    helperText={
                                                                        <React.Fragment>
                                                                            <CustomError
                                                                                name="accountHolder"
                                                                                component="div"
                                                                            />
                                                                        </React.Fragment>
                                                                    }
                                                                />
                                                            </div>
                                                            <div>
                                                                <div>
                                                                    <div className="mb-2 block">
                                                                        <Label
                                                                            className="dark:text-white"
                                                                            value="Account Number"
                                                                        />
                                                                    </div>
                                                                    <TextInput
                                                                        {...formik.getFieldProps('accountNumber')}
                                                                        name="accountNumber"
                                                                        type="text"
                                                                        helperText={
                                                                            <React.Fragment>
                                                                                <CustomError
                                                                                    name="accountNumber"
                                                                                    component="div"
                                                                                />
                                                                            </React.Fragment>
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <div className="mb-2 block">
                                                                    <Label
                                                                        htmlFor="price3"
                                                                        className="dark:text-white"
                                                                        color={
                                                                            formik.touched.swiftBic &&
                                                                            formik.errors.swiftBic
                                                                                ? 'failure'
                                                                                : ''
                                                                        }
                                                                        value="SWIFT / BIC"
                                                                    />
                                                                </div>
                                                                <TextInput
                                                                    {...formik.getFieldProps('swiftBic')}
                                                                    name="swiftBic"
                                                                    color={
                                                                        formik.touched.swiftBic &&
                                                                        formik.errors.swiftBic
                                                                            ? 'failure'
                                                                            : 'gray'
                                                                    }
                                                                    type="text"
                                                                    helperText={
                                                                        <React.Fragment>
                                                                            <CustomError
                                                                                name="swiftBic"
                                                                                component="div"
                                                                            />
                                                                        </React.Fragment>
                                                                    }
                                                                />
                                                            </div>

                                                            <div>
                                                                <div>
                                                                    <div className="mb-2 block">
                                                                        <Label
                                                                            className="dark:text-white"
                                                                            value="Routing Number"
                                                                        />
                                                                    </div>
                                                                    <TextInput
                                                                        {...formik.getFieldProps('routingNumber')}
                                                                        name="routingNumber"
                                                                        type="text"
                                                                        helperText={
                                                                            <React.Fragment>
                                                                                <CustomError
                                                                                    name="routingNumber"
                                                                                    component="div"
                                                                                />
                                                                            </React.Fragment>
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <div>
                                                                    <div className="mb-2 block">
                                                                        <Label
                                                                            className="dark:text-white"
                                                                            value="Description"
                                                                        />
                                                                    </div>
                                                                    <Textarea
                                                                        {...formik.getFieldProps('description')}
                                                                        name="description"
                                                                        id="comment"
                                                                        placeholder="Leave a description..."
                                                                        helperText={
                                                                            <React.Fragment>
                                                                                <CustomError
                                                                                    name="description"
                                                                                    component="div"
                                                                                />
                                                                            </React.Fragment>
                                                                        }
                                                                        rows={4}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex gap-2 justify-between py-2 px-2 items-center rounded-b-lg  bg-background-secondary">
                                                        <CustomBtn
                                                            variant="failure"
                                                            outline
                                                            pill
                                                            type="reset"
                                                            onClick={() => {
                                                                setOpen(false);
                                                                formik.resetForm();
                                                                setAddSwitcher(0);
                                                            }}
                                                        >
                                                            Cancel
                                                        </CustomBtn>
                                                        <CustomBtn
                                                            pill
                                                            variant="primary"
                                                            type="submit"
                                                            disabled={
                                                                !formik.isValid ||
                                                                !formik.dirty ||
                                                                formik.isSubmitting ||
                                                                bankLoader
                                                            }
                                                            isProcessing={bankLoader}
                                                        >
                                                            Add
                                                        </CustomBtn>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    )}
                                </>
                            )}
                            {accountCurrency === 'EUR' && (
                                <>
                                    <Formik
                                        initialValues={{
                                            accountCurrency,
                                            paymentType: '',
                                            iban: '',
                                            accountHolder: '',
                                            swiftBic: '',
                                            accountNumber: '',
                                            // description: '',
                                        }}
                                        onSubmit={(values, onSubmitProps) => {
                                            addBankConfiguration(values);
                                            setOpen(false);
                                            setAddSwitcher(0);
                                            setAccountCurrency('');
                                            setPaymentType('');
                                            onSubmitProps.setSubmitting(false);
                                            onSubmitProps.resetForm();
                                        }}
                                        validationSchema={BankConfigEur}
                                    >
                                        {(formik) => (
                                            <Form onSubmit={formik.handleSubmit} placeholder="">
                                                <div className="py-2 px-6 flex flex-col gap-2 overflow-auto max-h-[37rem]">
                                                    <div className="grid gap-6 mb-6 md:grid-cols-2">
                                                        <div>
                                                            <div className="mb-2 block">
                                                                <Label
                                                                    htmlFor="price3"
                                                                    className="dark:text-white"
                                                                    color={
                                                                        formik.touched.paymentType &&
                                                                        formik.errors.paymentType
                                                                            ? 'failure'
                                                                            : ''
                                                                    }
                                                                    value="Payment Type"
                                                                />
                                                            </div>

                                                            <Select
                                                                // onChange={(e) => {
                                                                //     setPaymentType(e.target.value);
                                                                // }}
                                                                {...formik.getFieldProps('paymentType')}
                                                            >
                                                                <option>Choose payment type</option>
                                                                <option value="local">Local</option>
                                                                <option value="international">International</option>
                                                            </Select>
                                                        </div>
                                                        <div>
                                                            <div className="mb-2 block">
                                                                <Label
                                                                    htmlFor="price3"
                                                                    className="dark:text-white"
                                                                    color={
                                                                        formik.touched.accountHolder &&
                                                                        formik.errors.accountHolder
                                                                            ? 'failure'
                                                                            : ''
                                                                    }
                                                                    value="Account Holder"
                                                                />
                                                            </div>
                                                            <TextInput
                                                                {...formik.getFieldProps('accountHolder')}
                                                                name="accountHolder"
                                                                color={
                                                                    formik.touched.accountHolder &&
                                                                    formik.errors.accountHolder
                                                                        ? 'failure'
                                                                        : 'gray'
                                                                }
                                                                type="text"
                                                                helperText={
                                                                    <React.Fragment>
                                                                        <CustomError
                                                                            name="accountHolder"
                                                                            component="div"
                                                                        />
                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <div>
                                                                <div className="mb-2 block">
                                                                    <Label
                                                                        className="dark:text-white"
                                                                        value="Account Number"
                                                                    />
                                                                </div>
                                                                <TextInput
                                                                    {...formik.getFieldProps('accountNumber')}
                                                                    name="accountNumber"
                                                                    type="text"
                                                                    helperText={
                                                                        <React.Fragment>
                                                                            <CustomError
                                                                                name="accountNumber"
                                                                                component="div"
                                                                            />
                                                                        </React.Fragment>
                                                                    }
                                                                />
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div>
                                                                <div className="mb-2 block">
                                                                    <Label className="dark:text-white" value="IBAN" />
                                                                </div>
                                                                <TextInput
                                                                    {...formik.getFieldProps('iban')}
                                                                    name="iban"
                                                                    type="text"
                                                                    helperText={
                                                                        <React.Fragment>
                                                                            <CustomError name="iban" component="div" />
                                                                        </React.Fragment>
                                                                    }
                                                                />
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div>
                                                                <div className="mb-2 block">
                                                                    <Label
                                                                        className="dark:text-white"
                                                                        value="SWIFT / BIC"
                                                                    />
                                                                </div>
                                                                <TextInput
                                                                    {...formik.getFieldProps('swiftBic')}
                                                                    name="swiftBic"
                                                                    type="text"
                                                                    helperText={
                                                                        <React.Fragment>
                                                                            <CustomError
                                                                                name="swiftBic"
                                                                                component="div"
                                                                            />
                                                                        </React.Fragment>
                                                                    }
                                                                />
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div>
                                                                <div className="mb-2 block">
                                                                    <Label
                                                                        className="dark:text-white"
                                                                        value="Description"
                                                                    />
                                                                </div>
                                                                <Textarea
                                                                    {...formik.getFieldProps('description')}
                                                                    name="description"
                                                                    id="comment"
                                                                    placeholder="Leave a description..."
                                                                    helperText={
                                                                        <React.Fragment>
                                                                            <CustomError
                                                                                name="description"
                                                                                component="div"
                                                                            />
                                                                        </React.Fragment>
                                                                    }
                                                                    rows={4}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex gap-2 justify-between py-2 px-2 items-center rounded-b-lg  bg-background-secondary">
                                                    <CustomBtn
                                                        variant="failure"
                                                        outline
                                                        pill
                                                        type="reset"
                                                        onClick={() => {
                                                            setOpen(false);
                                                            formik.resetForm();
                                                            setAddSwitcher(0);
                                                        }}
                                                    >
                                                        Cancel
                                                    </CustomBtn>
                                                    <CustomBtn
                                                        pill
                                                        variant="primary"
                                                        type="submit"
                                                        disabled={
                                                            !formik.isValid ||
                                                            !formik.dirty ||
                                                            formik.isSubmitting ||
                                                            bankLoader
                                                        }
                                                        isProcessing={bankLoader}
                                                    >
                                                        Add
                                                    </CustomBtn>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </>
                            )}
                        </>
                    )}
                    {addSwitcher === 2 && (
                        <Formik
                            initialValues={{
                                email: '',
                                serviceType: 'Payoneer',
                            }}
                            onSubmit={(values, onSubmitProps) => {
                                addFinanceService(values);
                                setOpen(false);
                                setAddSwitcher(0);
                                setAccountCurrency('');
                                setPaymentType('');
                                onSubmitProps.setSubmitting(false);
                                onSubmitProps.resetForm();
                            }}
                            validationSchema={FinancialService}
                        >
                            {(formik) => (
                                <Form onSubmit={formik.handleSubmit} placeholder="">
                                    <div className="py-2 px-6 flex flex-col gap-2 overflow-auto max-h-[37rem]">
                                        <div className="grid gap-6 mb-6 md:grid-cols-2">
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label
                                                        className="dark:text-white"
                                                        color={
                                                            formik.touched.email && formik.errors.email ? 'failure' : ''
                                                        }
                                                        value="Email"
                                                    />
                                                </div>
                                                <TextInput
                                                    {...formik.getFieldProps('email')}
                                                    name="email"
                                                    color={
                                                        formik.touched.email && formik.errors.email ? 'failure' : 'gray'
                                                    }
                                                    type="text"
                                                    helperText={
                                                        <React.Fragment>
                                                            <CustomError name="email" component="div" />
                                                        </React.Fragment>
                                                    }
                                                />
                                            </div>

                                            <div>
                                                <div className="mb-2 block">
                                                    <Label className="dark:text-white" value="Service Type" />
                                                </div>
                                                <Select id="serviceType" {...formik.getFieldProps('serviceType')}>
                                                    <option value="Payoneer">Payoneer</option>
                                                    <option value="PayPal">PayPal</option>
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex gap-2 justify-between py-2 px-2 items-center rounded-b-lg  bg-background-secondary">
                                        <CustomBtn
                                            variant="failure"
                                            outline
                                            pill
                                            type="reset"
                                            onClick={() => {
                                                setOpen(false);
                                                formik.resetForm();
                                                setAddSwitcher(0);
                                            }}
                                        >
                                            Cancel
                                        </CustomBtn>
                                        <CustomBtn
                                            pill
                                            variant="primary"
                                            type="submit"
                                            disabled={
                                                !formik.isValid || !formik.dirty || formik.isSubmitting || bankLoader
                                            }
                                            isProcessing={bankLoader}
                                        >
                                            Add
                                        </CustomBtn>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddBankConfiguration;
