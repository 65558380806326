/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef, useEffect } from 'react';
import { GoArrowDown, GoArrowUp } from 'react-icons/go';

const CustomSelect = ({ optionList, selectedOptions, onChange, placeholder }: any) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const dropdownRef = useRef(null);

    const handleClickOutside = (event: Event) => {
        if (dropdownRef.current && !(dropdownRef.current as any).contains(event.target)) {
            setDropdownOpen(false);
            setIsEditing(false);
            setSearchQuery('');
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const filteredOptions = optionList?.filter(
        (option: any) =>
            `${option.firstName} ${option.lastName}`.toLowerCase().includes(searchQuery.toLowerCase()) ||
            option?.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            option?.label?.toLowerCase().includes(searchQuery.toLowerCase()),
    );

    return (
        <div
            className="relative inline-block w-64 h-full rounded-lg text-gray-700 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 "
            ref={dropdownRef}
        >
            <div
                className="relative h-full rounded-lg "
                onClick={() => {
                    setDropdownOpen(true);
                    if (!isEditing) setIsEditing(true);
                }}
            >
                {isEditing ? (
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="bg-gray-200 px-4 py-2 border border-gray-300 rounded w-full text-gray-700 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 "
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus
                    />
                ) : (
                    <button
                        className="bg-gray-200 px-4 py-2 border border-gray-300 rounded-lg w-full h-full flex items-center justify-between text-gray-700 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 "
                        type="button"
                        onClick={() => {
                            setDropdownOpen(!dropdownOpen);
                            setIsEditing(true);
                        }}
                    >
                        {selectedOptions?.length
                            ? `${selectedOptions?.filter((elt: string) => elt !== 'select_all')
                                  ?.length} selected options`
                            : placeholder}

                        {dropdownOpen ? <GoArrowUp /> : <GoArrowDown />}
                    </button>
                )}
                {dropdownOpen && (
                    <div className="absolute mt-2 w-full bg-white border border-gray-300 rounded shadow-lg z-10 max-h-60 overflow-y-auto overflow-x-hidden	">
                        <label className="flex items-center px-4 py-2 text-gray-700 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 ">
                            <input
                                type="checkbox"
                                value="select_all"
                                checked={
                                    selectedOptions?.includes('select_all') ||
                                    selectedOptions?.filter((elt: string) => elt !== 'select_all')?.length ===
                                        filteredOptions?.length
                                }
                                onChange={onChange}
                                className="form-checkbox rounded focus:ring-2 border border-gray-300 dark:border-gray-600 dark:bg-gray-700 bg-gray-100 focus:ring-cyan-600 dark:ring-offset-gray-800 dark:focus:ring-cyan-600 text-cyan-600"
                            />
                            <span className="ml-2">All Options</span>
                        </label>
                        {filteredOptions?.map((option: any) => (
                            <label
                                key={option._id}
                                className="flex items-center px-4 py-2 text-gray-700 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 "
                            >
                                <input
                                    type="checkbox"
                                    value={option?._id || option?.label}
                                    checked={
                                        selectedOptions?.includes('select_all') ||
                                        selectedOptions?.includes(option?._id) ||
                                        selectedOptions?.includes(option?.label)
                                    }
                                    onChange={onChange}
                                    className="rounded focus:ring-2 border border-gray-300 dark:border-gray-600 dark:bg-gray-700 bg-gray-100 focus:ring-cyan-600 dark:ring-offset-gray-800 dark:focus:ring-cyan-600 text-cyan-600"
                                />
                                <span className="ml-2 text-gray-700 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 ">
                                    {(option?.firstName &&
                                        option?.lastName &&
                                        `${option?.firstName} ${option?.lastName}`) ||
                                        option?.email ||
                                        option?.label}
                                </span>
                            </label>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CustomSelect;
