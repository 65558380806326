import React from 'react';

const useDebouncedValue: <T>(value: T, timer: number) => T = (value, timer) => {
    const [debouncedValue, setDebouncedValue] = React.useState(value);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            setDebouncedValue(value);
        }, timer);

        return () => {
            clearTimeout(timeout);
        };
    }, [value, timer]);

    return debouncedValue;
};

export default useDebouncedValue;
