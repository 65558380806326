/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { ColorModeContext } from 'utils/contexts/theme';
import { BsBrightnessHighFill, BsMoonFill } from 'react-icons/bs';

const ThemeSwitch = () => {
    const colorMode = React.useContext(ColorModeContext);

    return (
        <>
            <div
                className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full flex items-center justify-center w-10 h-10"
                onClick={() => colorMode.colorMode()}
            >
                {colorMode.mode === true ? (
                    <BsBrightnessHighFill className="dark:text-white w-5 h-5 " />
                ) : (
                    <BsMoonFill className="w-4 h-4 text-gray-600" />
                )}
            </div>
        </>
    );
};

export default ThemeSwitch;
