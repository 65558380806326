/* eslint-disable no-unused-vars */
import React from 'react';
import { IStausInterface, TableColDef } from 'components/tables/tables.types';
import { Badge } from 'flowbite-react';

export const columns: TableColDef[] = [
    // {
    //     field: 'Picture',
    //     headerName: 'Portrait',
    //     width: 100,
    //     renderCell: (params: GridRenderCellParams<string>) => (
    //         <div>
    //             <UserAvatar image={params.value} label={params.row.id} isCollapsed />
    //         </div>
    //     ),
    // },
    {
        field: 'profileImage',
        headerName: 'Picture',
        fieldType: 'profileImage',
    },
    {
        field: 'firstName',
        headerName: 'First Name',
    },
    {
        field: 'lastName',
        headerName: 'Last Name',
    },
    {
        field: 'email',
        headerName: 'E-Mail',
    },

    {
        field: 'callCenter',
        headerName: 'Call Center',
        fieldType: 'render',
        renderCell: (params) => (
            <div>{params.callCenter && params.callCenter.length > 0 ? params.callCenter[0].name : '-'}</div>
        ),
    },
    {
        field: 'phone',
        headerName: 'Mobile Number',
    },
    {
        field: 'ongoingOrders',
        headerName: 'Assigned Orders',
        fieldType: 'render',
        renderCell: (params) => (
            <div>
                <p>{params.ongoingOrders ? params.ongoingOrders : '0'}</p>
            </div>
        ),
    },
    {
        field: 'status',
        headerName: 'Status',
        fieldType: 'status',
    },
    {
        field: 'Action',
        actionType: 'Call Agent',
        headerName: 'Options',
    },
];
export const statusDisplay: { [keys: string]: IStausInterface } = {
    1: {
        statusIcon: (
            <Badge color="success" size="sm">
                Active
            </Badge>
        ),
        statusLabel: 'Active',
    },
    2: {
        statusIcon: (
            <Badge color="failure" size="sm">
                Disabled
            </Badge>
        ),
        statusLabel: 'Disabled',
    },
};
