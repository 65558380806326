import { IOrder } from 'services/admin/order';
import axios from 'utils/axios';
import { socket } from 'utils/socketIO';
import { getBearerToken, getCurrentUser } from '../../utils/helpers/auth';

// export const updateOrder = async (data: any) => {
//     const orderUpdate = await axios.put(`api/orders/callcenter/${data.id}`, data, getBearerToken());
//     return orderUpdate;
// };

export const updateOrder = async (data: { id: string } & Partial<IOrder>) => {
    const orderUpdate = await axios.put(`/orders/update/${data.id}`, data, getBearerToken());
    if (orderUpdate.data.orderHistoryId) {
        socket.emit('orderStatusUpdate', {
            // eslint-disable-next-line no-underscore-dangle
            user: getCurrentUser()._id,
            orderHistoryId: orderUpdate.data.orderHistoryId,
        });
    }

    return orderUpdate;
};

export const pendingQuestion = async (data: { orderId: string; content: string }) => {
    const inquiry = await axios.post(`/orders/inquiry`, data, getBearerToken());
    return inquiry;
};

export const getQuestionHistory = async (orderId: string) => {
    const questionsHistory = await axios.get<IOrder['inquiriesConversation']>(
        `/orders/inquiry/${orderId}`,
        getBearerToken(),
    );
    return questionsHistory;
};
