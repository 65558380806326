/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { Checkbox } from 'flowbite-react';
import { MenuItem, Select } from '@mui/material';
import InputField from 'components/inputField/InputField';
import { IErrors, IProductAttributes } from './pickUpDrawer';

export const Transportation: React.FC<{
    productAttributes: IProductAttributes;
    setProductAttributes: any;
    numberOfBoxes: number;
    platform: string;
    transportation: string;
    setPlatform: any;
    setTransportation: any;
    errors: IErrors[];
    setDocuments: any;
    documents: string;
}> = ({
    setProductAttributes,
    productAttributes,
    numberOfBoxes,
    platform,
    transportation,
    setPlatform,
    setTransportation,
    errors,
    documents,
    setDocuments,
}) => {
    const [withDeclaration, setWithDeclaration] = useState<boolean>(false);
    const handleCheckBox = (value: boolean, productId: string, field: string) => {
        setProductAttributes((prevAttributes: IProductAttributes) => ({
            ...prevAttributes,
            [productId]: {
                ...prevAttributes[productId],
                [field]: value,
            },
        }));
    };

    return (
        <div className="py-2 px-4 h-full w-full flex flex-col overflow-hidden ">
            <div className="py-2">
                <table className="w-full  text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400  ">
                    <thead className="text-xs rounded-lg  bg-gray-200 dark:bg-gray-700  font-normal text-gray-600  dark:text-gray-400 ">
                        <tr>
                            <th scope="col" className="p-2">
                                Product
                            </th>
                            <th scope="col" className="py-2">
                                Contain Battery
                            </th>

                            <th scope="col" className="py-2">
                                Branding
                            </th>

                            <th scope="col" className="py-2">
                                Solely
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {Object.entries(productAttributes)?.map(([productId, product]) => (
                            <tr
                                className=" border-b   dark:border-gray-700 font-bold  bg-white dark:bg-gray-800 "
                                key={productId}
                            >
                                <td className="p-2">{product?.name}</td>
                                <td className=" py-2">
                                    <Checkbox
                                        key="battery"
                                        checked={product?.battery}
                                        onChange={(event) => {
                                            handleCheckBox(event?.target.checked, productId, 'battery');
                                        }}
                                    />
                                </td>
                                <td className=" py-2">
                                    <Checkbox
                                        key="branding"
                                        checked={product?.branding}
                                        onChange={(event) => {
                                            handleCheckBox(event?.target.checked, productId, 'branding');
                                        }}
                                    />
                                </td>
                                <td className=" py-2">
                                    <Checkbox
                                        key="solely"
                                        checked={product?.solely}
                                        onChange={(event) => {
                                            handleCheckBox(event?.target.checked, productId, 'solely');
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="py-2 flex w-full items-center gap-10">
                <div>
                    <p className="font-normal text-gray-700 dark:text-gray-400"> Customs declaration </p>
                </div>
                <div>
                    <Checkbox
                        key="declaration"
                        checked={withDeclaration}
                        onChange={(event) => {
                            setWithDeclaration(event?.target.checked);
                            if (!event?.target.checked) {
                                setDocuments('');
                            }
                        }}
                    />
                </div>
            </div>

            <div className="my-2 flex flex-col  overflow-y-auto">
                {withDeclaration && (
                    <div className="py-2 flex w-96 items-center gap-10">
                        <div>
                            <p className="font-normal text-gray-700 dark:text-gray-400">Documents </p>
                        </div>
                        <div>
                            <InputField
                                style={{
                                    width: '250px',
                                }}
                                placeholder="documents"
                                value={documents}
                                onChange={(e) => {
                                    setDocuments(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                )}

                <div className="py-2 flex w-full items-center gap-10">
                    <div>
                        <p className="font-normal text-gray-700 dark:text-gray-400">Number of boxes </p>
                    </div>
                    <div>
                        <p className="font-bold text-gray-700 dark:text-gray-400">{numberOfBoxes}</p>
                    </div>
                </div>

                <div className="py-2 flex w-full items-center gap-10">
                    <div>
                        <p className="font-normal text-gray-700 dark:text-gray-400">Transportation </p>
                    </div>
                    <div>
                        <Select
                            sx={{
                                margin: '10px 0px',
                                borderRadius: '8px',
                                height: '40px',
                                width: '250px',
                                backgroundColor: 'inputBackground',
                            }}
                            value={transportation}
                            id="transportation-select"
                            onChange={(e) => setTransportation(e.target.value)}
                        >
                            {[
                                { label: 'By Air', value: 'A' },
                                { label: 'B2B E-commerce', value: 'E' },
                                { label: 'By Sea', value: 'S' },
                            ].map(({ label, value }) => (
                                <MenuItem selected={transportation === value} value={value} key={value}>
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors?.some((error: IErrors) => error.element === 'transportation') && (
                            <div
                                className="bg-red-100 text-red-800 border border-red-300 rounded p-2 "
                                style={{ width: '250px' }}
                            >
                                {errors?.find((error: IErrors) => error.element === 'transportation')?.message}
                            </div>
                        )}
                    </div>
                </div>

                <div className="py-2 flex w-full items-center gap-10">
                    <div>
                        <p className="font-normal text-gray-700 dark:text-gray-400">Platform</p>
                    </div>
                    <div>
                        <Select
                            sx={{
                                margin: '10px 0px',
                                borderRadius: '8px',
                                height: '40px',
                                width: '250px',
                                backgroundColor: 'inputBackground',
                            }}
                            id="platform-select"
                            value={platform}
                            onChange={(e) => setPlatform(e.target.value)}
                        >
                            {[
                                { label: 'Aliexpress', value: '速卖通' },
                                { label: 'Amazon FLEX', value: '亚马逊FLEX' },
                                { label: 'NOON FLEX', value: 'NOON FLEX' },
                                { label: 'E-commerce', value: '独立站' },
                                { label: 'Other', value: '其他' },
                            ].map(({ label, value }) => (
                                <MenuItem selected={platform === value} value={value} key={value}>
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors?.some((error: IErrors) => error.element === 'platform') && (
                            <div
                                className="bg-red-100 text-red-800 border border-red-300 rounded p-2 "
                                style={{ width: '200px' }}
                            >
                                {errors?.find((error: IErrors) => error.element === 'platform')?.message}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
