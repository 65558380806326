/* eslint-disable no-underscore-dangle */
import AccountRoleBadge from 'components/status/accountRoleBadge';
import { Accordion } from 'flowbite-react';
import { AllUserProps } from 'pages/profileSetting/profileSettings.types';
import React, { useEffect, useState } from 'react';
import { getAccountManager } from 'services/accountManager/accountManagerProfile';

import { getCurrentUser } from 'utils/helpers/auth';
import ProfilePicture from './profilePicture';
import AccountInformation from './accountManagerUpdates/AccountInformation';
import PersonalInformation from './accountManagerUpdates/PersonalInformation';
import PasswordInfo from './accountManagerUpdates/passwordInfo';

const AcProfile = () => {
    const [accountManager, setAccountManager] = useState<AllUserProps>();

    const currentId = getCurrentUser()._id;

    useEffect(() => {
        getAccountManager(currentId).then((res) => {
            setAccountManager(res.data);
        });
    }, []);

    return (
        <>
            <div className="flex justify-end items-center mb-3">
                <p className="mr-2">Current Role:</p>
                <AccountRoleBadge role={getCurrentUser().role} />
            </div>
            <div className="grid grid-flow-col">
                <div className="flex justify-center items-center ">
                    <ProfilePicture
                        id={currentId}
                        profImg={
                            accountManager?.profileImage ??
                            'https://image.similarpng.com/very-thumbnail/2021/08/Instagram-photo-frame-template-and-social-media-notification-icon-design-template-on-transparent-background-PNG.png'
                        }
                        setAccountManager={setAccountManager}
                    />
                </div>
                <div className="col-span-5">
                    <Accordion collapseAll alwaysOpen>
                        <Accordion.Panel>
                            <Accordion.Title>Personal Info</Accordion.Title>
                            <Accordion.Content>
                                {accountManager && (
                                    <PersonalInformation
                                        firstName={accountManager?.firstName}
                                        lastName={accountManager?.lastName}
                                        phone={accountManager?.phone}
                                        id={currentId}
                                    />
                                )}
                            </Accordion.Content>
                        </Accordion.Panel>
                        <Accordion.Panel>
                            <Accordion.Title>Email</Accordion.Title>
                            <Accordion.Content>
                                {accountManager && <AccountInformation id={currentId} email={accountManager.email} />}
                            </Accordion.Content>
                        </Accordion.Panel>
                        <Accordion.Panel>
                            <Accordion.Title>Password</Accordion.Title>
                            <Accordion.Content>
                                <PasswordInfo id={currentId} />
                            </Accordion.Content>
                        </Accordion.Panel>
                    </Accordion>
                </div>
            </div>
        </>
    );
};

export default AcProfile;
