import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const layoutTitle = {
    color: 'textColor.tertiary',
    fontWeight: 700,
    fontSize: '18px',
    marginBottom: '20px',
};

export const Container = styled(Box)(({ theme }) => ({
    background: theme.palette.primaryBackground,
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    alignContent: 'space-between',
}));

export const titleBoxStyle = {
    padding: '10px 20px',
};
export const selectTheme = {
    field: {
        select: {
            base: 'border-gray-300 w-full dark:border-slate-600',
            colors: {
                general: 'bg-custom-background',
            },
        },
    },
};
