import axios from 'utils/axios';
import { getBearerToken } from 'utils/helpers/auth';

export const addCallCenter = async (data: any) => {
    const newCallAgent = await axios.post(`/callcenter`, data, getBearerToken());
    return newCallAgent;
};

export const getAllCallCenters = async (filter: any) => {
    const callAgent = await axios.post(`/callcenter/filter`, filter, getBearerToken());
    return callAgent;
};

// export const getCallAgent = async (id: any) => {
//     const callAgent = await axios.get(`/callcenter/${id}`, getBearerToken());
//     return callAgent;
// };

export const updateCallCenter = async (id: any, data: any) => {
    const callAgentUpdate = await axios.put(`/callcenter/${id}`, data, getBearerToken());
    return callAgentUpdate;
};
