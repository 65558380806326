/* eslint-disable react/jsx-fragments */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { changeEmailSchema } from 'utils/validations/accountInformationValidation';
import CustomError from 'components/customError/CustomError';
import toast from 'react-hot-toast';
import CustomBtn from 'components/customButton/customBtn';
import { Label, TextInput } from 'flowbite-react';
import { HiMail } from 'react-icons/hi';
import { UserEmailProps } from 'pages/profileSetting/profileSettings.types';
import { updateCallAgent } from 'services/callAgent/callAgentProfile';

const UpdateEmail: React.FC<UserEmailProps> = ({ id, email }) => {
    const [profilesettingLoader, setProfileSettingLoader] = useState(false);

    const updateEmail = (values: any) => {
        setProfileSettingLoader(true);
        updateCallAgent(id, values)
            .then((res) => {
                localStorage.setItem(
                    'user',
                    JSON.stringify({
                        firstName: res.data.callAgent.firstName,
                        lastName: res.data.callAgent.lastName,
                        email: res.data.callAgent.email,
                        phone: res.data.callAgent.phone,
                        profileImage: res.data.callAgent.profileImage,
                    }),
                );
                toast.success('Email Updated Successfully');
                setProfileSettingLoader(false);
            })
            .catch((err) => {
                toast.error(err.response.data.errors.message);
                setProfileSettingLoader(false);
            });
    };

    return (
        <>
            <Formik
                initialValues={{
                    email,
                }}
                onSubmit={(values) => {
                    updateEmail(values);
                }}
                validationSchema={changeEmailSchema}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit} placeholder="">
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="username4"
                                    className="dark:text-white"
                                    color={formik.errors.email ? 'failure' : ''}
                                    value="Email"
                                />
                            </div>
                            <TextInput
                                {...formik.getFieldProps('email')}
                                name="email"
                                color={formik.errors.email ? 'failure' : 'gray'}
                                icon={HiMail}
                                type="email"
                                helperText={
                                    <React.Fragment>
                                        <CustomError name="email" component="div" />
                                    </React.Fragment>
                                }
                            />
                        </div>
                        <div className="flex justify-end">
                            <CustomBtn
                                type="submit"
                                variant="primary"
                                disabled={profilesettingLoader}
                                isProcessing={profilesettingLoader}
                            >
                                Save Changes
                            </CustomBtn>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UpdateEmail;
