/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
import { Avatar, Progress, Spinner } from 'flowbite-react';
import React from 'react';
import { IoSearch } from 'react-icons/io5';
import Zoom from 'react-medium-image-zoom';
import FileFile from '../../assets/file.png';
import Package from '../../assets/package.png';
import FilePdf from '../../assets/pdf.png';
import { downloadSingleFileAsBase64, openPdfInNewTab } from './productFilesDownloader';

export const countFileTypes = (files: string[]): Record<string, number> => {
    const fileTypeCounts: Record<string, number> = {};

    files.forEach((file) => {
        const fileExtension = file.split('.').pop()?.toLowerCase() || 'unknown';
        const groupedExtension =
            fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'webp'
                ? 'Images'
                : fileExtension === 'svg%2bxml' ||
                    fileExtension === 'SVG%2BXML' ||
                    fileExtension === 'SVG' ||
                    fileExtension === 'svg'
                  ? 'SVG'
                  : fileExtension;

        fileTypeCounts[groupedExtension] = (fileTypeCounts[groupedExtension] || 0) + 1;
    });

    return fileTypeCounts;
};

export const isThereImage = (imageArray: string[]) => {
    const isThere = imageArray.filter(
        (prodImg) =>
            prodImg.endsWith('.jpg') ||
            prodImg.endsWith('.webp') ||
            prodImg.endsWith('.png') ||
            prodImg.endsWith('.jpeg'),
    );
    return isThere;
};

export const imageDisplay = (
    imageArray: string[],
    onlyOneImage?: boolean,
    width?: number,
    height?: number,
    avatar?: boolean,
    border?: boolean,
) => {
    const isThere = (imageArray || []).filter(
        (prodImg) =>
            prodImg.endsWith('.jpg') ||
            prodImg.endsWith('.png') ||
            prodImg.endsWith('.jpeg') ||
            prodImg.endsWith('.webp') ||
            prodImg.endsWith('.svg%2Bxml'),
    );
    if (onlyOneImage && isThere && isThere.length > 0) {
        return (
            <Zoom>
                <img
                    key={isThere[0]}
                    src={isThere[0]}
                    alt="quotation"
                    style={{
                        objectFit: 'contain',
                        borderRadius: 8,
                        overflow: 'hidden',
                        width: width ?? 50,
                        height: height ?? 50,
                    }}
                />
            </Zoom>
        );
    }

    if (avatar && isThere && isThere.length > 0) {
        return <Avatar alt="product image" img={isThere[0]} size="lg" bordered />;
    }

    if (avatar && isThere && isThere.length === 0) {
        return <Avatar alt="product image" img={Package} size="lg" bordered />;
    }

    if (isThere && isThere.length > 0) {
        return isThere.map((prodImg) => (
            <Zoom>
                <img
                    key={prodImg}
                    src={prodImg}
                    alt="quotation"
                    className={`${border ? 'border' : ''}`}
                    style={{
                        objectFit: 'contain',
                        borderRadius: 8,
                        overflow: 'hidden',
                        width: width ?? 270,
                        height: height ?? 270,
                    }}
                />
            </Zoom>
        ));
    }
    return (
        <Zoom>
            <img
                src={Package}
                alt="quotation"
                style={{
                    objectFit: 'contain',
                    borderRadius: 8,
                    overflow: 'hidden',
                    width: width ?? 270,
                    height: height ?? 270,
                }}
            />
        </Zoom>
    );
};

export const filesDisplay = (imgLink: string, pdfTitle: string, width?: number, height?: number) => {
    const isItImage =
        imgLink.endsWith('.jpg') ||
        imgLink.endsWith('.png') ||
        imgLink.endsWith('.jpeg') ||
        imgLink.endsWith('.webp') ||
        imgLink.endsWith('.svg') ||
        imgLink.endsWith('.svg%2Bxml');

    const isItPdf = imgLink.endsWith('.pdf');

    if (isItImage) {
        return (
            <Zoom>
                <img
                    src={imgLink}
                    alt="Quotation Images"
                    style={{
                        objectFit: 'cover',
                        borderRadius: 8,
                        overflow: 'hidden',
                        width: width ?? 100,
                        height: height ?? 100,
                    }}
                />
            </Zoom>
        );
    }

    if (isItPdf) {
        return (
            <div className="relative" title="Click to view PDF">
                <img
                    title="Click to view PDF"
                    src={FilePdf}
                    alt="PDF File"
                    className="cursor-pointer "
                    style={{
                        objectFit: 'cover',
                        borderRadius: 8,
                        overflow: 'hidden',
                        width: width ?? 100,
                        height: height ?? 100,
                    }}
                    onClick={() => openPdfInNewTab(imgLink, pdfTitle)}
                />

                <IoSearch
                    onClick={() => openPdfInNewTab(imgLink, pdfTitle)}
                    className="absolute left-4 cursor-pointer top-1 h-5 w-5  text-red-500  hover:bg-red-200 hover:ring-2 ring-red-200 "
                />
            </div>
        );
    }

    return (
        <img
            src={FileFile}
            alt="Quotation Files"
            style={{
                objectFit: 'cover',
                borderRadius: 8,
                overflow: 'hidden',
                width: width ?? 100,
                height: height ?? 100,
            }}
        />
    );
};

const formatFileSize = (fileSizeInBytes: number): string => {
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    let unitIndex = 0;

    while (fileSizeInBytes >= 1024 && unitIndex < units.length - 1) {
        // eslint-disable-next-line no-param-reassign
        fileSizeInBytes /= 1024;
        // eslint-disable-next-line no-plusplus
        unitIndex++;
    }

    return `${fileSizeInBytes.toFixed(2)} ${units[unitIndex]}`;
};
export const chatFilesDisplay = (
    fileLink: string,
    fileTitle: string,
    fileSize?: number,
    width?: number,
    height?: number,
) => {
    const isItImage =
        fileLink.endsWith('.jpg') ||
        fileLink.endsWith('.png') ||
        fileLink.endsWith('.jpeg') ||
        fileLink.endsWith('.webp') ||
        fileLink.endsWith('.svg') ||
        fileLink.endsWith('.svg%2Bxml');

    const isItPdf = fileLink.endsWith('.pdf');

    const isItVideo = fileLink.endsWith('.mp4') || fileLink.endsWith('.webm') || fileLink.endsWith('.ogg');

    if (isItImage) {
        return (
            <Zoom>
                <img
                    src={fileLink}
                    alt="Quotation Images"
                    style={{
                        objectFit: 'cover',
                        borderRadius: 8,
                        overflow: 'hidden',
                        width: width ?? 100,
                        height: height ?? 100,
                    }}
                />
            </Zoom>
        );
    }

    if (isItVideo) {
        return (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video
                key={fileLink}
                controls
                style={{
                    width: '349px',
                    aspectRatio: '16/9',
                    objectFit: 'cover',
                    borderRadius: '7px',
                    overflow: 'hidden',
                }}
                src={fileLink.startsWith('https://') ? fileLink : `https://${fileLink}`}
            />
        );
    }

    if (isItPdf) {
        return (
            <button
                className="appearance-none text-left"
                type="button"
                onClick={() => downloadSingleFileAsBase64(fileLink, fileTitle)}
            >
                <div className="bg-gray-100 dark:bg-gray-900 rounded-2xl flex gap-3 px-2 py-3 items-center w-60">
                    <div className=" flex-shrink-0">
                        <img
                            title="Click to view PDF"
                            src={FilePdf}
                            alt="PDF File"
                            className="cursor-pointer "
                            style={{
                                objectFit: 'cover',
                                borderRadius: 8,
                                overflow: 'hidden',
                                width: 30,
                                height: 30,
                            }}
                            onClick={() => downloadSingleFileAsBase64(fileLink, fileTitle)}
                        />
                    </div>
                    <div>
                        <div className="break-all break-words text-black dark:text-white text-base font-normal ">
                            {fileTitle.length > 50 ? `${fileTitle.substring(0, 50)}...` : fileTitle}
                        </div>
                        {fileSize && <div className="text-sm font-normal font-mono">{formatFileSize(fileSize)}</div>}
                    </div>
                </div>
            </button>
        );
    }

    return (
        <button
            className="appearance-none text-left"
            type="button"
            onClick={() => {
                downloadSingleFileAsBase64(fileLink, fileTitle);
            }}
        >
            <div className="bg-gray-100 dark:bg-gray-900 rounded-2xl flex gap-3 px-2 py-3 items-center w-60">
                <div className=" flex-shrink-0">
                    <img
                        src={FileFile}
                        alt="Quotation Files"
                        className="cursor-pointer "
                        style={{
                            objectFit: 'cover',
                            borderRadius: 8,
                            overflow: 'hidden',
                            width: width ?? 30,
                            height: height ?? 30,
                        }}
                        onClick={() => {
                            downloadSingleFileAsBase64(fileLink, fileTitle);
                        }}
                    />
                </div>
                <div>
                    <div className="break-words break-all text-black dark:text-white text-base font-normal ">
                        {fileTitle.length > 50 ? `${fileTitle.substring(0, 50)}...` : fileTitle}
                    </div>
                    {fileSize && <div className="text-sm font-normal font-mono">{formatFileSize(fileSize)}</div>}
                </div>
            </div>
        </button>
    );
};

export const uploadingFileDisplay = (fileTitle: string, fileSize: number, progressCount: number) => {
    const isItImage =
        fileTitle.endsWith('.jpg') ||
        fileTitle.endsWith('.png') ||
        fileTitle.endsWith('.jpeg') ||
        fileTitle.endsWith('.webp') ||
        fileTitle.endsWith('.svg') ||
        fileTitle.endsWith('.svg%2Bxml');

    const isItPdf = fileTitle.endsWith('.pdf');

    const isItVideo = fileTitle.endsWith('.mp4') || fileTitle.endsWith('.webm') || fileTitle.endsWith('.ogg');

    if (isItImage) {
        return (
            <div className="relative">
                <Spinner aria-label="image loading" className="absolute right-2 top-2" />
                <div
                    style={{
                        borderRadius: 8,
                        overflow: 'hidden',
                        width: 100,
                        height: 100,
                    }}
                    className="bg-gray-600"
                />
                <div className="flex justify-end">Sending...</div>
            </div>
        );
    }

    if (isItVideo) {
        return (
            <>
                <div>
                    <video
                        key={fileTitle}
                        controls
                        style={{
                            width: '349px',
                            aspectRatio: '16/9',
                            objectFit: 'cover',
                            borderRadius: '7px',
                            overflow: 'hidden',
                        }}
                    />
                    <div className="flex justify-end">Sending...</div>
                </div>
            </>
        );
    }

    if (isItPdf) {
        return (
            <div className="bg-gray-100 dark:bg-gray-900 rounded-2xl flex gap-3 px-2 py-3 items-center w-60">
                <div className=" flex-shrink-0">
                    <img
                        title="Click to view PDF"
                        src={FilePdf}
                        alt="PDF File"
                        className="cursor-pointer "
                        style={{
                            objectFit: 'cover',
                            borderRadius: 8,
                            overflow: 'hidden',
                            width: 30,
                            height: 30,
                        }}
                    />
                </div>
                <div className="w-full">
                    <div className="break-all break-words text-black dark:text-white text-base font-normal ">
                        {fileTitle.length > 50 ? `${fileTitle.substring(0, 50)}...` : fileTitle}
                    </div>
                    {fileSize && <div className="text-sm font-normal font-mono">{formatFileSize(fileSize)}</div>}

                    <Progress progress={progressCount as number} size="sm" className="mb-2" />
                </div>
            </div>
        );
    }

    return (
        <button className="appearance-none text-left" type="button" onClick={() => {}}>
            <div className="bg-gray-100 dark:bg-gray-900 rounded-2xl flex gap-3 px-2 py-3 items-center w-60">
                <div className=" flex-shrink-0">
                    <img
                        src={FileFile}
                        alt="Quotation Files"
                        className="cursor-pointer "
                        style={{
                            objectFit: 'cover',
                            borderRadius: 8,
                            overflow: 'hidden',
                            width: 30,
                            height: 30,
                        }}
                        onClick={() => {}}
                    />
                </div>
                <div className="w-full">
                    <div className="break-words break-all text-black dark:text-white text-base font-normal ">
                        {fileTitle.length > 50 ? `${fileTitle.substring(0, 50)}...` : fileTitle}
                    </div>
                    {fileSize && <div className="text-sm font-normal font-mono">{formatFileSize(fileSize)}</div>}
                    <Progress progress={progressCount as number} size="sm" className="mb-2" />
                </div>
            </div>
        </button>
    );
};

const imageFinder = () => {
    return <></>;
};

export default imageFinder;
