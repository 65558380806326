/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { isAuthenticated } from './auth';

const ProtectedRoute = () => {
    const { pathname } = useLocation();
    const isAuth = isAuthenticated();

    return isAuth ? <Outlet /> : <Navigate to="/auth/login" state={{ from: pathname }} replace />;
};

export default ProtectedRoute;
