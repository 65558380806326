/* eslint-disable no-underscore-dangle */
import AccountRoleBadge from 'components/status/accountRoleBadge';
import { Accordion } from 'flowbite-react';
import { AllUserProps } from 'pages/profileSetting/profileSettings.types';
import React, { useEffect, useState } from 'react';
import { getCurrentUser } from 'utils/helpers/auth';
import { getCallAgentAdmin } from 'services/callAgentAdmin/callAgentAdminProfile';
import UpdatePersonalInformation from './updateProfile/updatePersonalInformation';
import UpdateEmail from './updateProfile/updateEmail';
import UpdatePassword from './updateProfile/updatePassword';
import ProfilePicture from './profilePic';

const CallAgentAdminProfile = () => {
    const [callAgentAdmin, setCallAgentAdmin] = useState<AllUserProps>();

    const currentId = getCurrentUser()._id;

    useEffect(() => {
        getCallAgentAdmin(currentId).then((res) => {
            setCallAgentAdmin(res.data);
        });
    }, []);
    return (
        <>
            <div className="flex justify-end items-center mb-3">
                <p className="mr-2">Current Role:</p>
                <AccountRoleBadge role={getCurrentUser().role} />
            </div>
            <div className="grid grid-flow-col">
                <div className="flex justify-center items-center ">
                    <ProfilePicture
                        id={currentId}
                        profImg={
                            callAgentAdmin?.profileImage ??
                            'https://image.similarpng.com/very-thumbnail/2021/08/Instagram-photo-frame-template-and-social-media-notification-icon-design-template-on-transparent-background-PNG.png'
                        }
                        setCallAgentAdmin={setCallAgentAdmin}
                    />
                </div>
                <div className="col-span-5">
                    <Accordion collapseAll alwaysOpen>
                        <Accordion.Panel>
                            <Accordion.Title>Personal Info</Accordion.Title>
                            <Accordion.Content>
                                {callAgentAdmin && (
                                    <UpdatePersonalInformation
                                        firstName={callAgentAdmin?.firstName}
                                        lastName={callAgentAdmin?.lastName}
                                        phone={callAgentAdmin?.phone}
                                        id={currentId}
                                    />
                                )}
                            </Accordion.Content>
                        </Accordion.Panel>
                        <Accordion.Panel>
                            <Accordion.Title>Email</Accordion.Title>
                            <Accordion.Content>
                                {callAgentAdmin && <UpdateEmail id={currentId} email={callAgentAdmin?.email} />}
                            </Accordion.Content>
                        </Accordion.Panel>
                        <Accordion.Panel>
                            <Accordion.Title>Password</Accordion.Title>
                            <Accordion.Content>
                                <UpdatePassword id={currentId} />
                            </Accordion.Content>
                        </Accordion.Panel>
                    </Accordion>
                </div>
            </div>
        </>
    );
};

export default CallAgentAdminProfile;
