/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { forwardRef, useEffect, useState } from 'react';
// import MessageContainer from 'components/messageContainer/MessageContainer';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box, Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import MessageFile from 'components/messageFile/MessageFile';
import MessageHeader from 'components/messageHeader/MessageHeader';
import MessageInput from 'components/messageInput/MessageInput';
import MessageText from 'components/messageText/MessageText';
import { useLocation } from 'react-router-dom';
import { getProduct } from 'services/admin/product';
import { getCurrentUser } from 'utils/helpers/auth';
import { socket } from 'utils/socketIO';
import { IProduct } from 'utils/Interfaces/products.interface';
import ModalLoader from 'utils/helpers/themeStyle/modalLoader';
import {
    LoadPreviousMessages,
    LoadPreviousMessagesWrapper,
    MessageTexts,
    MessageTextsContainer,
    // MessageBoxStyle,
    // messagesContainerStyle,
    messagesPageStyle,
} from './Messages.styles';

const Messages = forwardRef(() => {
    const location: any = useLocation();
    const [currentRef, setCurrentRef] = useState('last');
    const [convMessages, setConvMessages] = useState<any>([]);
    const myRef = React.useRef<any>();
    const [limit, setLimit] = useState(0);
    const [productInfo, setProductInfo] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [totalMessages, setTotalMessages] = useState(0);
    useEffect(() => {
        myRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [convMessages]);
    useEffect(() => {
        // Creates a WebSocket connection
        setIsLoading(true);
        socket.emit(
            'joinProductChat',
            JSON.stringify({
                product: location.state.productId,
                user: getCurrentUser()._id,
                customerId: location.state.customerId,
            }),
        );

        socket.on('paginate-product-messages', (data: any) => {
            if (JSON.parse(data).totalDocs) setTotalMessages(JSON.parse(data).totalDocs);
            // setConvMessages(JSON.parse(data).docs);
            if (JSON.parse(data).docs) {
                if (JSON.parse(data).docs.length === 1) {
                    setConvMessages((conv: any) => [...JSON.parse(data).docs, ...conv]);
                } else setConvMessages(JSON.parse(data).docs);
            }

            setCurrentRef('last');
        });

        socket.on('new-product-message', (data: any) => {
            if (JSON.parse(data).docs) {
                if (JSON.parse(data).docs.length === 1) {
                    setConvMessages((conv: any) => [...JSON.parse(data).docs, ...conv]);
                } else setConvMessages(JSON.parse(data).docs);
                if (getCurrentUser()._id !== JSON.parse(data).docs[0].to) {
                    socket.emit('newChatMessage', {
                        user: getCurrentUser()._id,
                        about: 21, // new product message
                        customerId: location.state.customerId,
                        messageId: JSON.parse(data).docs[0]._id,
                    });
                }
            }

            setCurrentRef('last');
        });

        getProduct(location.state.productId).then((res) => {
            let skus = '';
            if (res.data.product.globalSKU && !res.data.product.variants?.length) {
                skus = `${res.data.product.globalSKU}`;
            } else if (res.data.product.variants.length > 0) {
                res.data.product.variants.forEach((variant) => {
                    if ((variant as IProduct).globalSKU) {
                        skus += `[${(variant as IProduct).name}: ${(variant as IProduct).globalSKU}] `;
                    }
                });
            }
            setProductInfo({
                image: res.data.product.design.length > 0 ? res.data.product.design[0] : '',
                name: res.data.product.name,
                sku: skus,
                status: res.data.product.status,
                type: res.data.product.type,
                productInfo: res.data.product,
            });
        });
        return () => {
            socket.off('paginate-product-messages');
            setIsLoading(false);
        };
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_progressCount, setProgressCount] = useState(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_fileName, setFileName] = useState('');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_fileSize, setFileSize] = useState(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_displayuploadingFile, setDisplayUploadingFile] = useState(false);

    if (isLoading && !productInfo) {
        return <ModalLoader />;
    }

    return (
        <Grid container sx={messagesPageStyle}>
            <Grid item xs={12} md={12}>
                {productInfo && (
                    <MessageHeader
                        productImage={productInfo.image}
                        productTitle={productInfo.name}
                        sku={productInfo.sku}
                        status={productInfo.status}
                        type={productInfo.type}
                        productInfo={productInfo}
                    />
                )}
                <Divider />
                {limit < totalMessages && (
                    <Box sx={LoadPreviousMessagesWrapper}>
                        <Box
                            onClick={() => {
                                socket.emit(
                                    'paginateProductMessages',
                                    JSON.stringify({
                                        limit: limit + 20,
                                        product: location.state.productId,
                                        user: getCurrentUser()._id,
                                    }),
                                );
                                setLimit((lmt) => lmt + 20);
                                setCurrentRef('first');
                            }}
                            sx={LoadPreviousMessages}
                        >
                            <ExpandLessIcon /> Click to load previous messages
                        </Box>
                    </Box>
                )}

                <Box sx={MessageTextsContainer}>
                    {currentRef === 'first' && <Box ref={myRef} />}
                    {convMessages &&
                        convMessages
                            ?.slice(0)
                            ?.reverse()
                            ?.filter(
                                (value: any, index: number, self: any) =>
                                    index ===
                                    self.findIndex(
                                        (t: any) => t.createdAt === value.createdAt && t.message === value.message,
                                    ),
                            )
                            ?.map((message: any) => (
                                <Box sx={MessageTexts}>
                                    {message.message ? (
                                        <MessageText
                                            message={message.message}
                                            received={message.onModelFrom === 'Customer'}
                                            entity={message.from}
                                            timestamp={message.createdAt}
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={message.id}
                                        />
                                    ) : message.file ? (
                                        <MessageFile
                                            file={message.file}
                                            received={message.onModelFrom === 'Customer'}
                                            fileName={message.fileName}
                                            fileSize={message.fileSize}
                                            timestamp={message.createdAt}
                                        />
                                    ) : null}
                                </Box>
                            ))}
                    {currentRef === 'last' && <Box ref={myRef} />}
                </Box>
                <Divider />
                <MessageInput
                    socketRef={socket}
                    setProgressCount={setProgressCount}
                    setFileName={setFileName}
                    setFileSize={setFileSize}
                    setDisplayUploadingFile={setDisplayUploadingFile}
                />
            </Grid>
        </Grid>
    );
});

export default Messages;
