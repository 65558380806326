/* eslint-disable react/jsx-fragments */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import CustomError from 'components/customError/CustomError';
import { updateAdmin } from 'services/admin/admin';
import { changePersonalInfos } from 'utils/validations/personalInformationValidation';
import CustomBtn from 'components/customButton/customBtn';
import PhoneInput from 'react-phone-number-input';
import toast from 'react-hot-toast';
import { Label, TextInput } from 'flowbite-react';
import { UserProps } from '../profileSettings.types';
import '../../../utils/helpers/phoneInput.css';

const PersonalInformation: React.FC<UserProps> = ({ firstName, lastName, phone, id }) => {
    const [profilesettingLoader, setProfileSettingLoader] = useState(false);

    const personalInfo = (values: any) => {
        setProfileSettingLoader(true);
        updateAdmin(id, values)
            .then((res) => {
                localStorage.setItem(
                    'user',
                    JSON.stringify({
                        firstName: res.data.admin.firstName,
                        lastName: res.data.admin.lastName,
                        email: res.data.admin.email,
                        phone: res.data.admin.phone,
                        profileImage: res.data.admin.profileImage,
                    }),
                );
                toast.success('Personal Information updated successfully');
                setProfileSettingLoader(false);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message);
                setProfileSettingLoader(false);
            });
    };
    return (
        <>
            <Formik
                initialValues={{
                    firstName,
                    lastName,
                    phone,
                }}
                onSubmit={(values) => {
                    personalInfo(values);
                }}
                validationSchema={changePersonalInfos}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit} placeholder="">
                        <div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="username4"
                                        className="dark:text-white"
                                        color={formik.errors.firstName ? 'failure' : ''}
                                        value="First name"
                                    />
                                </div>
                                <TextInput
                                    {...formik.getFieldProps('firstName')}
                                    name="firstName"
                                    color={formik.errors.firstName ? 'failure' : 'gray'}
                                    type="text"
                                    helperText={
                                        <React.Fragment>
                                            <CustomError name="firstName" component="div" />
                                        </React.Fragment>
                                    }
                                />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="username4"
                                        className="dark:text-white"
                                        color={formik.errors.lastName ? 'failure' : ''}
                                        value="Last Name"
                                    />
                                </div>
                                <TextInput
                                    {...formik.getFieldProps('lastName')}
                                    name="lastName"
                                    color={formik.errors.lastName ? 'failure' : 'gray'}
                                    type="text"
                                    helperText={
                                        <React.Fragment>
                                            <CustomError name="lastName" component="div" />
                                        </React.Fragment>
                                    }
                                />
                            </div>
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="username4"
                                    className="dark:text-white"
                                    color={formik.errors.phone ? 'failure' : ''}
                                    value="Phone Number"
                                />
                            </div>

                            <PhoneInput
                                id="phoneNumber"
                                name="phone"
                                value={formik.values.phone}
                                onChange={(value) => formik.setFieldValue('phone', value)}
                                onBlur={formik.handleBlur('phone')}
                                placeholder="Enter phone number"
                                className="mb-1"
                            />
                            <CustomError name="phone" component="div" />
                        </div>
                        <div className="flex justify-end">
                            <CustomBtn
                                type="submit"
                                variant="primary"
                                disabled={profilesettingLoader}
                                isProcessing={profilesettingLoader}
                            >
                                Save Changes
                            </CustomBtn>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default PersonalInformation;
