import CustomBtn from 'components/customButton/customBtn';
import React, { useState } from 'react';
import { AiOutlineAppstoreAdd } from 'react-icons/ai';
import NewWalletCurrencyModal from './newWalletCurrencyModal';
import WalletCurrenciesAccordion from './walletCurrenciesAccordion';
import { WalletCurrency } from './walletCurrency.types';

const WalletCurrencyConfiguration: React.FC<WalletCurrency> = ({
    walletCurrencyConfig,
    configId,
    setWalletCurrencyConfig,
}) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <div>
                <NewWalletCurrencyModal
                    open={open}
                    setOpen={setOpen}
                    walletCurrencyConfig={walletCurrencyConfig}
                    setWalletCurrencyConfig={setWalletCurrencyConfig}
                />
            </div>
            <div className="flex justify-end gap-2 p-2">
                <CustomBtn variant="primary" onClick={() => setOpen(true)} pill>
                    <AiOutlineAppstoreAdd className="mr-2 h-5 w-5" />
                    New
                </CustomBtn>
            </div>

            <div>
                <WalletCurrenciesAccordion
                    accords={walletCurrencyConfig}
                    configId={configId}
                    setWalletCurrencyConfig={setWalletCurrencyConfig}
                />
            </div>
        </>
    );
};

export default WalletCurrencyConfiguration;
