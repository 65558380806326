import axios from 'utils/axios';
import { getBearerToken } from 'utils/helpers/auth';

export const uploadMedia = async (
    data: any,
    progressConfig?: {
        onUploadProgress: (ProgressEvent: any) => void;
        onDownloadProgress: (ProgressEvent: any) => void;
    },
) => {
    const config: {
        headers: {
            Authorization: string;
        };
        onUploadProgress?: (progressEvent: any) => void;
        onDownloadProgress?: (progressEvent: any) => void;
    } = getBearerToken();

    if (progressConfig?.onUploadProgress) {
        config.onUploadProgress = progressConfig.onUploadProgress;
    }
    if (progressConfig?.onDownloadProgress) {
        config.onDownloadProgress = progressConfig.onDownloadProgress;
    }
    const newResellerProduct = await axios.post<{
        uploderImages?: string[];
        uplodervideos?: string[];
        uploaderFiles?: string[];
    }>(`/common/uploader`, data, getBearerToken());
    return newResellerProduct;
};
