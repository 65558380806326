import * as Yup from 'yup';

export const CallCenterFeedbackSchema = Yup.object().shape({
    callCenterFeedback: Yup.string().optional(),
    // actionReason: Yup.string().min(1, 'Field required').required('Field required'),
    status: Yup.string().min(1, 'Field required').required(),
    actionReason: Yup.string().when('status', {
        is: (val: string) => val === '2' || val === '3' || val === '43',
        then: (schema) => schema.min(1, 'Field required').required('Field required'),
    }),
    totalPrice: Yup.number().when('status', {
        is: (val: string) => val === '4',
        then: (schema) => schema.min(1, 'Field required').required('Field required'),
    }),
    schedualCallDate: Yup.string().when('status', {
        is: (val: string) => val === '43',
        then: (schema) => schema.required('Please set a call date'),
    }),

    callHours: Yup.string().when('status', {
        is: (val: string) => val === '43',
        then: (schema) => schema.required('Please set the call hour'),
    }),
    callMinutes: Yup.string().when('status', {
        is: (val: string) => val === '43',
        then: (schema) => schema.required('Please set the call minutes'),
    }),
    timeZone: Yup.string().when('status', {
        is: (val: string) => val === '43',
        then: (schema) => schema.required('Please set the time zone'),
    }),

    content: Yup.string().when('status', {
        is: (val: string) => val === '41',
        then: (schema) => schema.required('Please provide your question'),
    }),

    updateType: Yup.string().required(),
});

export const AccountManagerInquiryResponseSchema = Yup.object().shape({
    content: Yup.string().required('Please provide your answer'),
});

export const CallCenterUpdateInfoSchema = Yup.object().shape({
    company: Yup.string().optional(),
    phone: Yup.number().optional().typeError(''),
    province: Yup.string().optional(),
    updateType: Yup.string().required(),
});
export const CallCenterUpdateCoordinateSchema = Yup.object().shape({
    firstName: Yup.string().optional(),
    lastName: Yup.string().optional(),
    address1: Yup.string().optional(),
    address2: Yup.string().optional(),
    country: Yup.string().optional(),
    city: Yup.string().optional(),
    zip: Yup.string().optional(),
    updateType: Yup.string().required(),
});

export const UpdateOrderProduct = Yup.object().shape({
    items: Yup.array().of(
        Yup.object().shape({
            quantity: Yup.number().typeError('Field must be a number').required('Quantity required'),
            product: Yup.mixed().required('Choose product'),
            totalQuantity: Yup.number()
                .min(1, 'Min quantity is 1')
                .typeError('Field must be a number')
                .required('Quantity required'),
        }),
    ),
});
