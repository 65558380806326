/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { getCustomers } from 'services/admin/customer';
import moment from 'moment';
import { adminDashboardType } from 'services/admin/dashboard';
import CustomSelect from 'components/customSelect/customSelect';
import { Select } from 'flowbite-react';
import { countries } from 'utils/countryList';
import Datepicker from 'react-tailwindcss-datepicker';
import SellerPerformance from './adminDashboard/sellers';
import { IdateRange, IfilterDashboard } from './dashboard.types';

interface ICustomer {
    totalNumber?: number;
    customersList?: [];
}

const accountManagerDashboard = () => {
    const [customers, setCustomers] = useState<ICustomer>({ totalNumber: 0, customersList: [] });
    const types = Object.values(adminDashboardType);
    const startDate = moment
        .tz('Asia/Shanghai')
        .startOf('month')
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
        .format();
    const endDate = moment
        .tz('Asia/Shanghai')
        .endOf('month')
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 59)
        .format();
    const [filter, setFilter] = useState<IfilterDashboard>({
        dateRange: { startDate, endDate },
        type: adminDashboardType.codWithCC,
    });
    const [dateRange, setRangeValue] = useState<IdateRange | null>({
        startDate: moment.tz(startDate, 'Asia/Shanghai').format('YYYY-MM-DD'),
        endDate: moment.tz(endDate, 'Asia/Shanghai').format('YYYY-MM-DD'),
    });

    useEffect(() => {
        getCustomers({ limit: 1000, status: 2 }).then((res) =>
            setCustomers({ totalNumber: res?.data?.docs?.length, customersList: res?.data?.docs }),
        );
    }, []);

    const handleAddCustomers = (e: any) => {
        if (e?.target.value !== '') {
            if (filter?.customers?.includes(e.target.value)) {
                setFilter({
                    ...filter,
                    customers:
                        e.target.value === 'select_all'
                            ? []
                            : filter?.customers?.filter(
                                  (elt: string) => elt !== e.target.value && elt !== 'select_all',
                              ),
                });
            } else {
                setFilter({
                    ...filter,
                    customers:
                        e.target.value === 'select_all'
                            ? ['select_all', ...(customers?.customersList as [])?.map((elt: any) => elt?._id)]
                            : [
                                  ...(filter?.customers?.filter((elt: string) => elt !== 'select_all') || []),
                                  e.target.value,
                              ],
                });
            }
        } else {
            setFilter({ ...filter, customers: [] });
        }
    };

    const handleRangeFilterChange = (newValue: any) => {
        let newRange: typeof newValue | undefined;
        if (newValue && newValue.startDate && newValue.endDate) {
            const startD = moment.tz(newValue.startDate, 'Asia/Shanghai');
            const endD = moment.tz(newValue.endDate, 'Asia/Shanghai');
            endD.set('hour', 23);
            endD.set('minute', 59);
            endD.set('second', 59);
            startD.set('hour', 0);
            startD.set('minute', 0);
            startD.set('second', 0);
            newRange = {
                startDate: startD.format(),
                endDate: endD.format(),
            };
            setRangeValue({
                startDate: startD.format('YYYY-MM-DD'),
                endDate: endD.format('YYYY-MM-DD'),
            });
        } else setRangeValue(newValue);
        setFilter((oldFilter) => ({ ...oldFilter, dateRange: newRange }));
    };

    return (
        <>
            <div className="m-4">
                <h3>Dashboard</h3>
            </div>
            <div className="flex items-center w-full ">
                <div className="flex justify-between  w-full  ">
                    <div className="flex items-center w-full p-1">
                        <div className="p-1">
                            <CustomSelect
                                optionList={customers?.customersList}
                                selectedOptions={filter?.customers}
                                onChange={handleAddCustomers}
                                placeholder="Select the seller (s)"
                            />
                        </div>
                        <div id="select" className="p-1">
                            <Select
                                id="operationType"
                                required
                                onChange={(e) => {
                                    if (e.target.value !== '') {
                                        setFilter({ ...filter, country: e.target.value });
                                    } else {
                                        setFilter({ ...filter, country: undefined });
                                    }
                                }}
                            >
                                <option value="">All Countries</option>
                                {countries.map((country: any) => {
                                    return (
                                        <option key={country.label} value={country.label}>
                                            {country.label}
                                        </option>
                                    );
                                })}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="flex items-center w-full justify-end ">
                    <div id="select" className="p-1">
                        <Select
                            id="operationType"
                            required
                            onChange={(e) => {
                                if (e.target.value !== '') {
                                    setFilter({ ...filter, type: e.target.value });
                                }
                            }}
                        >
                            {types.map((type: string) => {
                                return (
                                    <option key={type} value={type}>
                                        {type}
                                    </option>
                                );
                            })}
                        </Select>
                    </div>
                    <div id="datePicker" className="block">
                        <Datepicker
                            inputId="orderDate"
                            value={dateRange}
                            containerClassName="relative z-100"
                            showShortcuts
                            useRange
                            onChange={handleRangeFilterChange}
                        />
                    </div>
                </div>
            </div>
            <SellerPerformance filter={filter} activeSeller={customers?.totalNumber} />
        </>
    );
};

export default accountManagerDashboard;
