/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import UseDocumentTitle from 'utils/dynamicTitle/dynamicTitle';
import AutoComplete from 'components/autocomplete/autoComplete';
import { getpayments, IPayments, paymentTypes, paymetSources } from 'services/admin/payments';
import { Select } from 'flowbite-react';
import toast from 'react-hot-toast';
import { getCustomers } from 'services/admin/customer';
import useDebouncedValue from 'utils/hooks/useDebouncedValue';
import DataTable from '../../components/tables/tables';
import { columns, statusDisplay } from './paymentsTableActions/paymentsElements';

interface Ipagination {
    page: number;
    limit: number;
}

interface Imeta {
    totalDocs: number;
    limit: number;
    totalPages: number;
    page: number;
    pagingCounter: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    prevPage: number | null;
    nextPage: number | null;
}

interface IdateRange {
    startDate: string;
    endDate: string;
}

interface Ifilter {
    status?: number;
    createdAt?: object;
    type?: string;
    dateRange?: IdateRange;
    source?: string;
    customer?: string;
}

const Payments = () => {
    UseDocumentTitle('Payments');

    const [paymentsList, setPaymentsList] = useState<Array<IPayments>>([]);

    // const [paymentData, setPaymentData] = useState<IPayment | {}>({});

    const [customers, setCustomers] = useState([]);

    const [customer] = useState('');
    const [customerId, setCustomerId] = useState('');

    useEffect(() => {
        if (customer) getCustomers({ limit: 1000, email: customer }).then((res) => setCustomers(res.data.docs));
        else getCustomers({ limit: 1000 }).then((res) => setCustomers(res.data.docs));
    }, [customer]);

    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 20,
    });
    const [filter, setFilter] = useState<Ifilter>({});
    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });
    const [isLoading, setIsLoading] = useState(false);

    const handleCustomerFilterChange = (newFilter: Ifilter) => {
        if (newFilter.customer === undefined || newFilter.customer === '') {
            setFilter((oldFilter) => {
                const temp = oldFilter;
                delete temp.customer;
                return { ...temp };
            });
        } else {
            setFilter({ ...filter, ...newFilter });
        }
        setMeta({ ...meta, page: -1 });
    };
    const debouncedPagination = useDebouncedValue(pagination, 500);
    const debouncedFilter = useDebouncedValue(filter, 500);
    // fetch stores on component mount & pagination or filter change
    useEffect(() => {
        /**
         * apply pagination & filter if filter options exists
         * else ignore filter options and only use pagination
         */
        const queryOptions = Object.values(debouncedFilter).length
            ? customerId !== ''
                ? { ...debouncedPagination, ...debouncedFilter }
                : { ...debouncedPagination, ...debouncedFilter }
            : customerId !== ''
              ? { ...debouncedPagination, customer: customerId }
              : { ...debouncedPagination };

        /**
         * check if we are requesting an active page (already fetched and is displayed)
         */
        // const fetchedAndActive = meta.page === pagination.page;
        /**
         * ignore request if page is available
         */
        /**
         * handle customer id filter change doesn't block request
         */
        // if (!fetchedAndActive) {
        setIsLoading(true);
        getpayments(queryOptions)
            .then((res) => {
                const data = res.data.response;
                setPaymentsList(data.docs);
                setMeta({
                    hasNextPage: data.hasNextPage,
                    hasPrevPage: data.hasPrevPage,
                    limit: data.limit,
                    nextPage: data.nextPage,
                    page: data.page,
                    pagingCounter: data.pagingCounter,
                    prevPage: data.prevPage,
                    totalDocs: data.totalDocs,
                    totalPages: data.totalPages,
                });
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                toast.error(err.response.data.errors.message);
            });
        //  }
    }, [debouncedFilter, debouncedPagination, customerId]);

    const handlePaginationChange = (value: { [key: string]: any }) => {
        setPagination((prevPagination) => {
            const data = { ...prevPagination, ...value };
            if (value.limit) {
                data.page = 1;
            }
            return data;
        });
        setMeta((prev) => ({ ...prev, page: -1 }));
    };

    return (
        <>
            <h5> All Payments</h5>
            <div className="flex gap-2 items-center  mb-2">
                <div className="w-72">
                    <AutoComplete
                        key="id"
                        placeholder="Sellers"
                        options={customers as []}
                        getOptionLabel={(option: any) =>
                            option?.lastName && option?.firstName
                                ? `${option.firstName} ${option.lastName}`
                                : option?.email
                        }
                        // eslint-disable-next-line no-underscore-dangle
                        onChange={(option) => {
                            handleCustomerFilterChange({ customer: option._id as string });
                            setCustomerId(option._id as string);
                        }}
                    />
                </div>
                <div id="select">
                    <Select
                        id="operationType"
                        required
                        value={filter.status?.toString()}
                        onChange={(e) => {
                            if (e.target.value) {
                                setFilter((prevFilter) => ({ ...prevFilter, status: Number(e.target.value) }));
                            } else if (!e.target.value && filter.status) {
                                const filterDraft = { ...filter };
                                delete filterDraft.status;
                                setFilter({ ...filterDraft });
                            }
                            setMeta({ ...meta, page: -1 });
                        }}
                    >
                        <option value="">All Status</option>
                        <option value="1">Pending</option>
                        <option value="2">Confirmed</option>
                        <option value="3">Rejected</option>
                    </Select>
                </div>
                <div id="sourcePayment">
                    <Select
                        id="sourcePaymentSelect"
                        required
                        value={filter.source?.toString()}
                        onChange={(e) => {
                            if (e.target.value) {
                                setFilter((prevFilter) => ({ ...prevFilter, source: e.target.value }));
                            } else if (!e.target.value && filter.source) {
                                const filterDraft = { ...filter };
                                delete filterDraft.source;
                                setFilter({ ...filterDraft });
                            }
                        }}
                    >
                        <option value="">All Sources</option>
                        {paymetSources.map((sourceType) => {
                            return <option value={sourceType.value}>{sourceType.label}</option>;
                        })}
                    </Select>
                </div>
                <div id="typePayment">
                    <Select
                        id="typePayment"
                        required
                        value={filter.type?.toString()}
                        onChange={(e) => {
                            if (e.target.value) {
                                setFilter((prevFilter) => ({ ...prevFilter, type: e.target.value }));
                            } else if (!e.target.value && filter.type) {
                                const filterDraft = { ...filter };
                                delete filterDraft.type;
                                setFilter({ ...filterDraft });
                            }
                        }}
                    >
                        <option value="">All Types</option>
                        {paymentTypes.map((type) => {
                            return <option value={type.value}>{type.label}</option>;
                        })}
                    </Select>
                </div>
            </div>

            <DataTable
                rows={paymentsList}
                columns={columns}
                icons={statusDisplay}
                setRows={setPaymentsList}
                pageState={{
                    isLoading,
                    total: meta.totalDocs,
                    pageSize: pagination.limit,
                    page: pagination.page,
                    count: meta.totalPages,
                }}
                setPageState={handlePaginationChange}
                filterState={filter}
            />
        </>
    );
};

export default Payments;
