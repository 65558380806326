import axios from 'utils/axios';
import { getBearerToken } from 'utils/helpers/auth';

export const getSourcingAgent = async (id: string) => {
    const sourcingAgent = await axios.get(`/productproviders/${id}`, getBearerToken());
    return sourcingAgent;
};

export const updateSourcingAgent = async (id: any, data: any) => {
    const sourcingAgentUpdate = await axios.put(`/productproviders/${id}`, data, getBearerToken());
    return sourcingAgentUpdate;
};
