/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-lone-blocks */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-fragments */
import CustomError from 'components/customError/CustomError';
import { Badge, Label, Select, TextInput } from 'flowbite-react';
import { Form, Formik } from 'formik';
import React from 'react';
import toast from 'react-hot-toast';
import { UpdateCreditsInformationsForCogs } from 'utils/validations/CreditsInformationsValidation';
import CustomBtn from 'components/customButton/customBtn';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { addCredit, getCredits } from 'services/accountManager/credits';
import { ICreditCogs } from './credits.types';

const AddCreditCog: React.FC<ICreditCogs> = ({ productList, customer, setRows }) => {
    const newCredit = (values: any) => {
        addCredit({ ...values, status: 1, customer, creditType: 'Cogs' })
            .then(() => {
                toast.success('Credit application added successfully');
            })
            .then(() => {
                getCredits({}).then((res) => setRows(res.data.docs));
            })
            .catch((err) => {
                toast.error(err.response.data.errors.message);
            });
    };
    return (
        <>
            <div id="selectCreditType">
                <Formik
                    initialValues={{
                        creditAmount: '',
                        cogsUnitPrice: '',
                        customer: '',
                        product: '',
                    }}
                    onSubmit={(values1, onSubmitProps) => {
                        newCredit(values1);
                        onSubmitProps.setSubmitting(false);
                    }}
                    validationSchema={UpdateCreditsInformationsForCogs}
                >
                    {(formik1) => (
                        <Form onSubmit={formik1.handleSubmit} placeholder="">
                            <div className="flex flex-col">
                                {productList.length > 0 ? (
                                    <div>
                                        <div key="zerUnitPrice" className="py-2">
                                            <div className="mb-2 block">
                                                <Label
                                                    htmlFor="cogsUnitPrice"
                                                    className="dark:text-white"
                                                    value="product"
                                                />
                                            </div>
                                            <Select
                                                id="products"
                                                required
                                                {...formik1.getFieldProps(`product`)}
                                                name="product"
                                            >
                                                <option key="null val" value="">
                                                    Select product
                                                </option>
                                                {productList.map((product: any) => {
                                                    return (
                                                        <option key={product.id} value={product.id}>
                                                            {product?.name}
                                                        </option>
                                                    );
                                                })}
                                            </Select>
                                        </div>
                                        <div className="grid grid-cols-2 gap-2">
                                            <div key="zer">
                                                <div className="mb-2 block">
                                                    <Label
                                                        htmlFor="creditAmount"
                                                        className="dark:text-white"
                                                        value="Credit for Cogs"
                                                    />
                                                </div>
                                                <TextInput
                                                    {...formik1.getFieldProps(`creditAmount`)}
                                                    color={
                                                        formik1.touched.creditAmount && formik1.errors.creditAmount
                                                            ? 'failure'
                                                            : 'gray'
                                                    }
                                                    type="text"
                                                    name="creditAmount"
                                                    helperText={
                                                        <React.Fragment>
                                                            <CustomError name="creditAmount" component="div" />
                                                        </React.Fragment>
                                                    }
                                                />
                                            </div>
                                            <div key="zerUnitPrice">
                                                <div className="mb-2 block">
                                                    <Label
                                                        htmlFor="cogsUnitPrice"
                                                        className="dark:text-white"
                                                        value="Cogs unit price"
                                                    />
                                                </div>
                                                <TextInput
                                                    {...formik1.getFieldProps(`cogsUnitPrice`)}
                                                    name="cogsUnitPrice"
                                                    type="text"
                                                    color={
                                                        formik1.errors.cogsUnitPrice && formik1.touched.cogsUnitPrice
                                                            ? 'failure'
                                                            : 'gray'
                                                    }
                                                    helperText={
                                                        <span>
                                                            <CustomError name="cogsUnitPrice" component="div" />
                                                        </span>
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <Badge color="info" size="sm" icon={AiOutlineInfoCircle}>
                                        Cutomer Dont have products yet
                                    </Badge>
                                )}
                            </div>
                            <div className="flex justify-end items-center rounded-b-lg mt-3">
                                <CustomBtn
                                    pill
                                    variant="primary"
                                    type="submit"
                                    disabled={!formik1.isValid || !formik1.dirty || formik1.isSubmitting}
                                >
                                    add
                                </CustomBtn>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default AddCreditCog;
