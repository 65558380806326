import { Badge } from 'flowbite-react';
import React from 'react';
import { RoleBadgeColor } from './Status.styles';

const AccountRoleBadge: React.FC<{
    role?: string;
}> = ({ role }) => {
    return (
        <>
            <Badge color={RoleBadgeColor('admin').color} size="sm">
                {role}
            </Badge>
        </>
    );
};

export default AccountRoleBadge;
