import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import { Button, Divider } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { messageFileUpload } from 'services/admin/product';
import { getCurrentUser } from 'utils/helpers/auth';
import {
    ButtonStyling,
    CustomFileInput,
    CustomLabelInput,
    dividerStyle,
    iconStyle,
    inputStyle,
} from './MessageInput.styles';
import { InputFieldProps } from './MessageInput.types';

const MessageInput: React.FC<InputFieldProps> = ({
    socketRef,
    action,
    productId,
    orderId,
    customerId,
    setProgressCount,
    setFileSize,
    setFileName,
    setDisplayUploadingFile,
}) => {
    const location = useLocation();
    const locationData = location.state as any;

    const socketData = {};
    if (productId) {
        Object.assign(socketData, { product: productId });
    } else if (orderId) {
        Object.assign(socketData, { order: orderId });
    } else if (customerId) {
        Object.assign(socketData, { recieverId: customerId });
    } else if (locationData.productId) {
        Object.assign(socketData, { product: locationData.productId });
    } else {
        // eslint-disable-next-line no-underscore-dangle
        Object.assign(socketData, { order: locationData.data._id });
    }

    const [message, setMessage] = useState('');
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setMessage(e.target.value);
    };
    const sendMessage = (e: any) => {
        e.preventDefault();
        if (message.trim()) {
            socketRef.emit(
                action || 'newProductMessage',
                JSON.stringify({
                    // eslint-disable-next-line no-underscore-dangle
                    user: getCurrentUser()._id,
                    message: message.trim(),
                    ...socketData,
                }),
            );
        }
        setMessage('');
    };

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const myfile = e.target.files instanceof FileList && e.target.files[0];
        if (myfile) {
            setDisplayUploadingFile(true);
            const reader = new FileReader();
            const formData = new FormData();
            formData.append('photo', myfile);
            setFileName(myfile.name);
            setFileSize(myfile.size);
            const config = {
                onUploadProgress: (progressEvent: any) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgressCount(progress);
                },
                onDownloadProgress: (progressEvent: any) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgressCount(progress);
                },
            };
            messageFileUpload(formData, config)
                .then((res) => {
                    return socketRef.emit(
                        action || 'newProductMessage',
                        JSON.stringify({
                            // eslint-disable-next-line no-underscore-dangle
                            user: getCurrentUser()._id,
                            file: res.data.file,
                            fileName: myfile.name,
                            fileSize: myfile.size,
                            ...socketData,
                        }),
                    );
                })
                .then(() => {
                    setDisplayUploadingFile(false);
                    setProgressCount(0);
                });
            // eslint-disable-next-line @typescript-eslint/no-shadow
            reader.onload = () => {};
            reader.readAsDataURL(myfile);
        }
    };
    return (
        <div className="h-[54px] pb-4">
            <OutlinedInput
                id="outlined-adornment-amount"
                value={message}
                onChange={(e) => handleChange(e)}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        sendMessage(e);
                    }
                }}
                startAdornment={
                    <InputAdornment position="start">
                        <Button sx={ButtonStyling}>
                            <CustomFileInput
                                type="file"
                                id="file"
                                accept="*"
                                multiple={false}
                                onChange={handleFileUpload}
                            />
                            <CustomLabelInput htmlFor="file">
                                {' '}
                                <AttachFileIcon sx={iconStyle} />
                            </CustomLabelInput>
                        </Button>

                        <Divider orientation="vertical" sx={dividerStyle} />
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="end">
                        <SendIcon onClick={sendMessage} sx={iconStyle} />
                    </InputAdornment>
                }
                placeholder="Type your message here…"
                sx={inputStyle}
            />
        </div>
    );
};

export default MessageInput;
