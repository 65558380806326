import { Button, Label, TextInput } from 'flowbite-react';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { IConfig } from 'services/admin/configuration';
import { updateCallCenterConfig } from 'services/callCenter/callCenterService';

const OrderConfiguration: React.FC<{ orderConfig: IConfig['order']; callCenterId: IConfig['_id'] }> = ({
    orderConfig,
    callCenterId,
}) => {
    const [assignUnreachableAfterHours, setAssignUnreachableAfterHours] = useState(
        `${orderConfig?.assignUnreachableAfterHours || ''}`,
    );
    const [orderMaxUnreachable, setOrderMaxUnreachable] = useState(`${orderConfig?.orderMaxUnreachable || ''}`);
    const [errors, setErrors] = useState<[string, string]>(['', '']);
    const [orderConfigLoader, setOrderConfigLoader] = useState(false);

    const handleValuesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === 'assignUnreachableAfterHours') {
            if (Number.isNaN(Number(e.target.value))) {
                setErrors((prev) => {
                    const draft = [...prev];
                    draft[0] = 'value must be a valid number';
                    return [draft[0], draft[1]];
                });
            } else if (e.target.value.includes(',') || e.target.value.includes('.')) {
                setErrors((prev) => {
                    const draft = [...prev];
                    draft[0] = 'value must be an integer';
                    return [draft[0], draft[1]];
                });
            } else if (Number(e.target.value) < 1) {
                setErrors((prev) => {
                    const draft = [...prev];
                    draft[0] = 'min value is 1';
                    return [draft[0], draft[1]];
                });
            } else {
                if (errors[0] !== '') {
                    setErrors((prev) => {
                        const draft = [...prev];
                        draft[0] = '';
                        return [draft[0], draft[1]];
                    });
                }
                setAssignUnreachableAfterHours(e.target.value);
            }
        } else if (e.target.name === 'orderMaxUnreachable') {
            if (Number.isNaN(Number(e.target.value))) {
                setErrors((prev) => {
                    const draft = [...prev];
                    draft[1] = 'value must be a valid number';
                    return [draft[0], draft[1]];
                });
            } else if (e.target.value.includes(',') || e.target.value.includes('.')) {
                setErrors((prev) => {
                    const draft = [...prev];
                    draft[1] = 'value must be an integer';
                    return [draft[0], draft[1]];
                });
            } else if (Number(e.target.value) < 1) {
                setErrors((prev) => {
                    const draft = [...prev];
                    draft[1] = 'min value is 1';
                    return [draft[0], draft[1]];
                });
            } else {
                if (errors[1] !== '') {
                    setErrors((prev) => {
                        const draft = [...prev];
                        draft[1] = '';
                        return [draft[0], draft[1]];
                    });
                }
                setOrderMaxUnreachable(e.target.value);
            }
        }
    };

    const handleCancel = () => {
        setAssignUnreachableAfterHours(`${orderConfig?.assignUnreachableAfterHours || ''}`);
        setOrderMaxUnreachable(`${orderConfig?.orderMaxUnreachable || ''}`);
    };

    const handleSave = () => {
        setOrderConfigLoader(true);
        const toastId = toast.loading('Updating orders assignment configuration...');
        updateCallCenterConfig(callCenterId, {
            ordersConfig: {
                assignUnreachableAfterHours: Number(assignUnreachableAfterHours),
                orderMaxUnreachable: Number(orderMaxUnreachable),
            },
        })
            .then(() => {
                toast.success('Configuration updated successfully', { id: toastId });
                setOrderConfigLoader(false);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message || 'Something went wrong', { id: toastId });
                setOrderConfigLoader(false);
            });
    };
    return (
        <div className=" p-4 mb-2 gap-4 w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <h1 className="mb-5 text-lg font-bold leading-none text-gray-900 dark:text-white">
                Unreachable / Expired Orders
            </h1>
            <div className="mb-4">
                <div className="mb-2 block">
                    <Label htmlFor="assignUnreachableAfterHours" value="Auto reassign unreachable orders after" />
                </div>
                <div className="flex items-center dark:bg-gray-700 bg-gray-100 w-fit rounded-md">
                    <TextInput
                        name="assignUnreachableAfterHours"
                        type="text"
                        sizing="md"
                        value={assignUnreachableAfterHours}
                        className="max-w-[50px]"
                        onChange={handleValuesChange}
                    />
                    <p className="px-2 font-bold">hours</p>
                </div>
                <p className="opacity-50 text-xs p-2">
                    *Automatically reassign <span className="font-bold">unreachable</span> orders after the specified
                    period
                </p>
                {errors[0] !== '' && <p className="text-red-700">{errors[0]}</p>}
            </div>

            <div className="mb-4">
                <div className="mb-2 block">
                    <Label htmlFor="orderMaxUnreachable" value="Order attempts before expiration" />
                </div>
                <div className="flex items-center dark:bg-gray-700 bg-gray-100 w-fit rounded-md">
                    <TextInput
                        name="orderMaxUnreachable"
                        type="text"
                        sizing="md"
                        className="max-w-[50px]"
                        value={orderMaxUnreachable}
                        onChange={handleValuesChange}
                    />
                    <p className="px-2 font-bold">attempts</p>
                </div>
                <p className="opacity-50 text-xs p-2">
                    *Update the <span className="font-bold">unreachable</span> orders status to{' '}
                    <span className="font-bold">expired</span> after the specified attempts
                </p>
                {errors[1] !== '' && <p className="text-red-700">{errors[1]}</p>}
            </div>
            {(orderConfig.orderMaxUnreachable !== Number(orderMaxUnreachable) ||
                orderConfig.assignUnreachableAfterHours !== Number(assignUnreachableAfterHours)) &&
            errors.every((error) => error === '') ? (
                <div className="flex justify-end gap-2">
                    <Button
                        color="failure"
                        onClick={() => {
                            handleCancel();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            handleSave();
                        }}
                        disabled={orderConfigLoader}
                        isProcessing={orderConfigLoader}
                    >
                        Save
                    </Button>
                </div>
            ) : null}
        </div>
    );
};

export default OrderConfiguration;
