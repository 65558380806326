/* eslint-disable no-underscore-dangle */
import { Button } from '@mui/material';
import React from 'react';
import toast from 'react-hot-toast';
import { BiCopy } from 'react-icons/bi';
import { getVariantQuantity } from 'utils/helpers/productsHelpers/getVariantInfo';
import { IProduct } from 'utils/Interfaces/products.interface';

function Tablesku({ product }: { product: IProduct | null }) {
    const copySKUToClipboard = (sku: string) => {
        navigator.clipboard.writeText(sku);
        toast.success(`SKU: ${sku} copied to clipboard`);
    };

    return (
        <div className="overflow-x-auto min-w-[500px] overflow-y-auto">
            <div className="flex items-center  justify-center flex-col">
                {product && (
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 relative rounded-xl">
                        <thead className="text-xs text-gray-700 uppercase bg-[#EAEDF7] rounded-xl dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3  ">
                                    {product?.variants?.length > 0 ? 'Variant' : 'Product'}
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    SKU{' '}
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {product?.variants &&
                                product?.variants?.length > 0 &&
                                (product.variants as IProduct[]).map((variant) => {
                                    if (!getVariantQuantity(variant)) return; // to check
                                    // eslint-disable-next-line no-underscore-dangle, consistent-return
                                    return (
                                        <tr className=" border-b dark:bg-gray-800 dark:border-gray-700">
                                            <td className="px-6 py-4 flex gap-2">{variant.name}</td>
                                            <td className="px-6 py-4">
                                                {variant.globalSKU}
                                                <Button
                                                    title="Copy sku to clipboard"
                                                    onClick={() => {
                                                        copySKUToClipboard(variant?.globalSKU ?? '--');
                                                    }}
                                                >
                                                    <BiCopy fontSize="20px" />
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            {product?.quantity && product.quantity > 0 ? (
                                <tr className=" border-b dark:bg-gray-800 dark:border-gray-700">
                                    <td className="px-6 py-4 flex gap-2">{product.name}</td>
                                    <td className="px-6 py-4">
                                        {product.globalSKU}
                                        <Button
                                            title="Copy sku to clipboard"
                                            onClick={() => {
                                                copySKUToClipboard(product?.globalSKU ?? '--');
                                            }}
                                        >
                                            <BiCopy fontSize="20px" />
                                        </Button>
                                    </td>
                                </tr>
                            ) : null}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
}

export default Tablesku;
