import axios from 'utils/axios';
import { getBearerToken } from 'utils/helpers/auth';

export const addCallCenterAdmin = async (data: any) => {
    const newCallAgent = await axios.post(`/callcenteradmins`, data, getBearerToken());
    return newCallAgent;
};

export const getAllCallCenterAdmins = async (filter: any) => {
    const callAgent = await axios.post(`/callcenteradmins/filter`, filter, getBearerToken());
    return callAgent;
};

export const getCallAgent = async (id: any) => {
    const callAgent = await axios.get(`/callcenteradmins/${id}`, getBearerToken());
    return callAgent;
};

export const updateCallCenterAdmin = async (id: any, data: any) => {
    const callAgentUpdate = await axios.put(`/callcenteradmins/${id}`, data, getBearerToken());
    return callAgentUpdate;
};
