/* eslint-disable react/jsx-fragments */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import toast from 'react-hot-toast';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Checkbox, Label } from 'flowbite-react';
import CustomBtn from 'components/customButton/customBtn';
import { getAllCallAgents, updateCallAgent } from 'services/callAgentAdmin/callAgentsManagment';
import AutoComplete from 'components/autocomplete/autoComplete';
import moment from 'moment';
import { convertWorkingHoursToAgentTz, convertWorkingHoursToUTC } from 'utils/helpers/callAgent';
import { ICallAgentActions } from '../callAgent.types';

export interface IdateRange {
    startDate: string | null | Date;
    endDate: string | null | Date;
}
const CallAgentWorkingTime: React.FC<ICallAgentActions> = ({ callAgent, setRows, currentPage, currentFilter }) => {
    const [profileSaverLoader, setProfileLoader] = useState(false);
    const [workingTime, setWorkingTime] = useState<{ [key: string]: boolean }[]>([]);
    const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const personalInfo = (values: any) => {
        setProfileLoader(true);
        updateCallAgent(callAgent._id, values)
            .then((res) => {
                toast.success(res.data.message);
                setProfileLoader(false);
            })
            .then(() => {
                getAllCallAgents({ page: currentPage, ...currentFilter }).then((res) => {
                    setRows(res.data.docs);
                });
            })
            .catch((err) => {
                toast.error(err.response.data.errors.message);
                setProfileLoader(false);
            });
    };

    useEffect(() => {
        if (callAgent.workingTime) {
            const tzWorkingTime = convertWorkingHoursToAgentTz(callAgent.workingTime, callAgent.timeZone);
            setWorkingTime(tzWorkingTime);
        } else {
            const tempWorkingTime = [];
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < 7; i++) {
                tempWorkingTime.push({
                    '00-01': false,
                    '01-02': false,
                    '02-03': false,
                    '03-04': false,
                    '04-05': false,
                    '05-06': false,
                    '06-07': false,
                    '07-08': false,
                    '08-09': false,
                    '09-10': false,
                    '10-11': false,
                    '11-12': false,
                    '12-13': false,
                    '13-14': false,
                    '14-15': false,
                    '15-16': false,
                    '16-17': false,
                    '17-18': false,
                    '18-19': false,
                    '19-20': false,
                    '20-21': false,
                    '21-22': false,
                    '22-23': false,
                    '23-00': false,
                });
            }
            setWorkingTime([...tempWorkingTime]);
        }
    }, []);
    const handleHourChange = (day: number, hour: string, value: boolean) => {
        const updatedList = [...workingTime];
        updatedList[day][hour] = value;
        setWorkingTime(updatedList);
    };
    return (
        <>
            <Formik
                initialValues={{
                    timeZone: callAgent?.timeZone || '',
                }}
                onSubmit={(values) => {
                    const UTCWorkingHours = convertWorkingHoursToUTC(workingTime, values.timeZone);
                    personalInfo({ ...values, workingTime: UTCWorkingHours });
                }}
                // validationSchema={changeUserInfos}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit} placeholder="">
                        <div className="w-72 py-2">
                            <Label htmlFor="timezone">Timezone</Label>
                            <AutoComplete
                                key="id"
                                {...formik.getFieldProps('timeZone')}
                                name="timeZone"
                                placeholder="Timezone"
                                defaultValue={callAgent.timeZone}
                                options={moment.tz.names() as any}
                                getOptionLabel={(option: any) => `${option}`}
                                // eslint-disable-next-line no-underscore-dangle
                                onChange={(option) => {
                                    formik.setFieldValue('timeZone', option);
                                }}
                            />
                        </div>
                        {workingTime.map((dd, idx: number) => {
                            return (
                                <>
                                    <div className="mb-2 mt-3 flex justify-between items-center px-3 py-2 rounded-md text-s font-medium bg-gray-50 dark:bg-gray-600">
                                        <p>{weekDays[idx]}</p>
                                    </div>
                                    <div className="grid grid-cols-8 mb-2">
                                        {Object.keys(dd).map((key: string) => (
                                            <div className="flex items-center gap-2">
                                                <Checkbox
                                                    value={key + idx}
                                                    checked={workingTime[idx][key] || false}
                                                    onChange={(event) => {
                                                        handleHourChange(idx, key, event?.target.checked);
                                                    }}
                                                />
                                                <Label>{key}</Label>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            );
                        })}

                        <div className="mt-6 flex justify-end gap-2">
                            <CustomBtn
                                type="button"
                                // onClick={}
                                variant="secondary"
                                disabled={profileSaverLoader}
                                isProcessing={profileSaverLoader}
                            >
                                Cancel
                            </CustomBtn>
                            <CustomBtn
                                type="submit"
                                variant="primary"
                                isProcessing={profileSaverLoader}
                                disabled={profileSaverLoader}
                            >
                                Save
                            </CustomBtn>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default CallAgentWorkingTime;
