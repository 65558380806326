import { Badge, Card, Modal } from 'flowbite-react';
import Zoom from 'react-medium-image-zoom';
import React, { useState } from 'react';
import { HiOutlineArchiveBox } from 'react-icons/hi2';
import { IOrder } from 'services/admin/order';
import { IProduct } from 'utils/Interfaces/products.interface';
import Package from '../../../../assets/package.png';

const OrderProductInfo: React.FC<{ data: IOrder }> = ({ data }) => {
    const [openModal, setOpenModal] = useState(false);

    const isThereAnImage = (imageArr: string[]) => {
        const isThere = imageArr?.filter(
            (prodImg) =>
                prodImg.endsWith('.jpg') ||
                prodImg.endsWith('.png') ||
                prodImg.endsWith('.jpeg') ||
                prodImg.endsWith('.webp') ||
                prodImg.endsWith('.svg%2Bxml'),
        );
        if (isThere && isThere.length > 0) {
            return (
                <Zoom>
                    <img
                        src={isThere[0]}
                        alt="quotation"
                        style={{
                            objectFit: 'contain',
                            borderRadius: 8,
                            overflow: 'hidden',
                            width: 120,
                            height: 90,
                        }}
                    />
                </Zoom>
            );
        }
        return (
            <Zoom>
                <img
                    src={Package}
                    alt="quotation"
                    style={{
                        objectFit: 'contain',
                        borderRadius: 8,
                        overflow: 'hidden',
                        width: 80,
                        height: 70,
                    }}
                />
            </Zoom>
        );
    };

    return (
        <>
            <Modal dismissible show={openModal} size="5xl" popup onClose={() => setOpenModal(false)}>
                <Modal.Header>Product Details</Modal.Header>
                <Modal.Body className="!p-1 overflow-y-auto ">
                    <div className="  p-2 " key="productDetailsHolder">
                        <div>
                            <Card
                                theme={{
                                    root: {
                                        children: 'p-2',
                                    },
                                }}
                                className="mb-1"
                            >
                                <div className="grid grid-cols-12 text-center">
                                    <div className="col-span-1">
                                        <div className="sr-only">Image</div>
                                    </div>
                                    <div className="col-span-4">
                                        <p>Product Name</p>
                                    </div>
                                    <div className="col-span-3">
                                        <p>Product Spec</p>
                                    </div>
                                    <div className="col-span-3">
                                        <p>Sku</p>
                                    </div>
                                    <div>
                                        <p>Quantity</p>
                                    </div>
                                </div>
                            </Card>
                            {data?.items &&
                                data.items.length > 0 &&
                                data.items.map((item: any) => {
                                    const itemProduct = item?.product as IProduct;
                                    let productName = '';
                                    let variant = '';
                                    if (itemProduct.itemKind === 'variant') {
                                        productName = itemProduct?.parentProduct?.name;
                                        variant = itemProduct?.name;
                                    } else {
                                        productName = itemProduct?.name;
                                        variant = '-';
                                    }
                                    return (
                                        <>
                                            {(item.quantity || 0) > 0 && (
                                                <Card
                                                    key={itemProduct?.globalSKU}
                                                    theme={{
                                                        root: {
                                                            children: 'p-2',
                                                        },
                                                    }}
                                                    className="mb-1 relative"
                                                >
                                                    {itemProduct?.isReseller && (
                                                        <Badge
                                                            color="secondary"
                                                            className="inline-block absolute top-1 left-1 transform -rotate-12 z-10"
                                                            style={{ backgroundColor: '#F2EAFA', color: '#7828C8' }}
                                                        >
                                                            RESELLER
                                                        </Badge>
                                                    )}
                                                    <div className="grid grid-cols-12 text-center items-center">
                                                        <div className="">{isThereAnImage(itemProduct?.design)}</div>
                                                        <div className="col-span-4">
                                                            <p>{productName}</p>
                                                        </div>
                                                        <div className="col-span-3">
                                                            <p>{variant}</p>
                                                        </div>
                                                        <div className="col-span-3">
                                                            <p>{itemProduct?.globalSKU}</p>
                                                        </div>
                                                        <div>
                                                            <p>{item.quantity}</p>
                                                        </div>
                                                    </div>
                                                </Card>
                                            )}
                                            {(item.upsellQuantity || 0) > 0 && (
                                                <Card
                                                    key={itemProduct?.globalSKU}
                                                    theme={{
                                                        root: {
                                                            children: 'p-2',
                                                        },
                                                    }}
                                                    className="mb-1 bg-gray-100 relative"
                                                >
                                                    {itemProduct?.isReseller && (
                                                        <Badge
                                                            color="secondary"
                                                            className="inline-block absolute top-1 left-1 transform -rotate-12 z-10"
                                                            style={{ backgroundColor: '#F2EAFA', color: '#7828C8' }}
                                                        >
                                                            RESELLER
                                                        </Badge>
                                                    )}
                                                    <Badge
                                                        color="success"
                                                        className="inline-block absolute top-3 right-1 transform rotate-12"
                                                    >
                                                        UPSELL
                                                    </Badge>

                                                    <div className="grid grid-cols-12 text-center items-center">
                                                        <div>{isThereAnImage(itemProduct?.design)}</div>
                                                        <div className="col-span-4">
                                                            <p>{productName}</p>
                                                        </div>
                                                        <div className="col-span-3">
                                                            <p>{variant}</p>
                                                        </div>
                                                        <div className="col-span-3">
                                                            <p>{itemProduct?.globalSKU}</p>
                                                        </div>
                                                        <div>
                                                            <p>{item.upsellQuantity}</p>
                                                        </div>
                                                    </div>
                                                </Card>
                                            )}
                                        </>
                                    );
                                })}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <HiOutlineArchiveBox className="w-5 h-5 cursor-pointer" onClick={() => setOpenModal(true)} />
        </>
    );
};

export default OrderProductInfo;
