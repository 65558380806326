/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';

import AutoComplete from 'components/autocomplete/autoComplete';
import CustomBtn from 'components/customButton/customBtn';
import { Dropdown, Select } from 'flowbite-react';
import { ICustomer } from 'pages/customers/customer.types';
import Datepicker from 'react-tailwindcss-datepicker';
import { getCustomers } from 'services/admin/customer';
// import useDebouncedValue from 'utils/hooks/useDebouncedValue';
import moment from 'moment-timezone';
import toast from 'react-hot-toast';
import { BsFillFileEarmarkRuledFill } from 'react-icons/bs';
import { HiPencil } from 'react-icons/hi';
import { exportExcelFile, getPurchasesList, updateManyPurchases } from 'services/admin/purchases';
import DataTable from '../../components/tables/tables';
import { IPurchases, Ifilter, Ipagination } from './purchases.types';
import { columns, statusDisplay } from './purchasesListElements';

const purchasesList = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [purchases, setPurchases] = useState<Array<IPurchases>>([]);
    const [customers, setCustomers] = useState<ICustomer[]>([]);
    const [customer] = useState('');
    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 20,
    });
    const [Rangevalue, setRangeValue] = useState(null);
    const [filter, setFilter] = useState<Ifilter>({});

    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });
    const [purchasesSelected, setPurchasesSelected] = useState<IPurchases[]>([]);

    interface Imeta {
        totalDocs: number;
        limit: number;
        totalPages: number;
        page: number;
        pagingCounter: number;
        hasPrevPage: boolean;
        hasNextPage: boolean;
        prevPage: number | null;
        nextPage: number | null;
    }

    const handlePaginationChange = (value: { [key: string]: any }) => {
        setPagination((prevPagination) => {
            const data = { ...prevPagination, ...value };
            if (value.limit) {
                data.page = 1;
            }
            return data;
        });
        setMeta((prev) => ({ ...prev, page: -1 }));
    };

    const handleRangeFilterChange = (newValue: any) => {
        let newRange: typeof newValue | undefined;
        if (newValue && newValue.startDate && newValue.endDate) {
            const startD = moment.tz(newValue.startDate, 'Asia/Shanghai');
            const endD = moment.tz(newValue.endDate, 'Asia/Shanghai');
            endD.set('hour', 23);
            endD.set('minute', 59);
            endD.set('second', 59);
            newRange = {
                startDate: startD.format(),
                endDate: endD.format(),
            };
        }
        setRangeValue(newValue || null);
        setFilter({ ...filter, ...{ dateRange: newRange } });
        setMeta({ ...meta, page: -1 });
        setPagination((prev) => ({ ...prev, page: 1 }));
    };
    useEffect(() => {
        if (customer) getCustomers({ limit: 1000, email: customer }).then((res) => setCustomers(res.data.docs));
        else
            getCustomers({ limit: 1000 })
                .then((res) => setCustomers(res.data.docs))
                .catch((err) => {
                    toast.error(err?.response?.data?.errors?.message);
                });
    }, [customer]);
    const exportBatchPurchases = () => {
        exportExcelFile(purchasesSelected);
    };
    const updatePurchasesStatus = (status: number) => {
        const feedbackToast = toast.loading('Updating purchases...');
        const purchasesIds = purchasesSelected.map((purch) => purch?._id);
        updateManyPurchases({ purchases: purchasesIds, status })
            .then((res) => {
                const { data } = res;
                toast.dismiss(feedbackToast);
                if (data.errors && data.errors.length) {
                    data.errors.forEach((err) => {
                        toast.error(err.error || 'Unable to update purchase');
                    });
                } else {
                    toast.success('Purchases updated successfully', { id: feedbackToast });
                    const queryOptions = { ...filter, ...pagination };
                    getPurchasesList(queryOptions).then((result) => {
                        setPurchases(result?.data?.response?.docs);
                        setIsLoading(false);
                        setMeta({
                            hasNextPage: result.data.response?.hasNextPage,
                            hasPrevPage: result.data.response?.hasPrevPage,
                            limit: result.data.response?.limit,
                            nextPage: result.data.response?.nextPage,
                            page: result.data.response?.page,
                            pagingCounter: result.data.response?.pagingCounter,
                            prevPage: result.data.response?.prevPage,
                            totalDocs: result.data.response?.totalDocs,
                            totalPages: result.data.response?.totalPages,
                        });
                    });
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message, { id: feedbackToast });
            });
    };
    // const debouncedPagination = useDebouncedValue(pagination, 500);
    // const debouncedFilter = useDebouncedValue(filter, 500);
    useEffect(() => {
        const feedbackToast = toast.loading('Loading data...');
        setIsLoading(true);
        // const queryOptions = { ...debouncedPagination, ...debouncedFilter };
        const queryOptions = { ...pagination, ...filter };
        getPurchasesList(queryOptions)
            .then((result) => {
                toast.dismiss(feedbackToast);
                setPurchases(result?.data?.response?.docs);
                setIsLoading(false);
                setMeta({
                    hasNextPage: result.data.response?.hasNextPage,
                    hasPrevPage: result.data.response?.hasPrevPage,
                    limit: result.data.response?.limit,
                    nextPage: result.data.response?.nextPage,
                    page: result.data.response?.page,
                    pagingCounter: result.data.response?.pagingCounter,
                    prevPage: result.data.response?.prevPage,
                    totalDocs: result.data.response?.totalDocs,
                    totalPages: result.data.response?.totalPages,
                });
            })
            .catch((err) => {
                setIsLoading(false);
                toast.error((err as any)?.response?.data?.errors?.message || 'Unable to load data.', {
                    id: feedbackToast,
                });
            });
    }, [pagination, filter]);

    return (
        <>
            <h3>Purchases</h3>
            <section className="dark:bg-gray-900 flex items-center my-2">
                <div className=" relative w-full">
                    <div className="relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
                        <div className="flex flex-col items-center p-2 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
                            <div className="w-48">
                                <AutoComplete
                                    key="id"
                                    placeholder="Sellers"
                                    options={customers as []}
                                    getOptionLabel={(option: any) =>
                                        option?.lastName && option?.firstName
                                            ? `${option.firstName} ${option.lastName}`
                                            : option?.email
                                    }
                                    // eslint-disable-next-line no-underscore-dangle
                                    onChange={(option) => {
                                        setFilter({ ...filter, ...{ customer: option._id as string, page: -1 } });
                                        setPagination((prev) => ({ ...prev, page: 1 }));
                                        setMeta({ ...meta, page: -1 });
                                    }}
                                />
                            </div>
                            <div id="select">
                                <Select
                                    id="operationType"
                                    required
                                    value={filter.status?.toString()}
                                    onChange={(e) => {
                                        setFilter({
                                            ...filter,
                                            ...{
                                                status: e.target.value ? Number(e.target.value) : undefined,
                                                page: -1,
                                            },
                                        });
                                        setPagination((prev) => ({ ...prev, page: 1 }));
                                        setMeta({ ...meta, page: -1 });
                                    }}
                                >
                                    <option value="">All Status</option>
                                    <option value="1">Waiting for treatement</option>
                                    <option value="2">Purchased</option>
                                    <option value="3">Cancelled</option>
                                </Select>
                            </div>
                            <div className="mb-2 block">
                                <Datepicker
                                    inputId="deliveryDate"
                                    value={Rangevalue}
                                    containerClassName="relative z-100"
                                    // inputClassName="absolute"
                                    // toggleClassName="absolute
                                    useRange
                                    onChange={handleRangeFilterChange}
                                />
                            </div>
                            <div>
                                <CustomBtn
                                    variant="primary"
                                    onClick={() => {
                                        exportBatchPurchases();
                                    }}
                                    disabled={!purchasesSelected.length}
                                >
                                    <BsFillFileEarmarkRuledFill className="mr-2 h-5 w-5" />
                                    Export purchases
                                    {purchasesSelected.length > 0 ? (
                                        <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-600 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">
                                            {purchasesSelected.length}
                                        </div>
                                    ) : null}
                                </CustomBtn>
                            </div>
                            <div>
                                <Dropdown label="Update purchases" disabled={!purchasesSelected.length}>
                                    <Dropdown.Item
                                        icon={HiPencil}
                                        onClick={() => updatePurchasesStatus(2)}
                                        key="Set to Purchased"
                                    >
                                        Set to Purchased
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        icon={HiPencil}
                                        onClick={() => updatePurchasesStatus(3)}
                                        key="Set to Cancelled"
                                    >
                                        Set to Canceled
                                    </Dropdown.Item>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <DataTable
                rows={purchases}
                columns={columns}
                icons={statusDisplay}
                setRows={setPurchases}
                pageState={{
                    isLoading,
                    total: meta.totalDocs,
                    pageSize: pagination.limit,
                    page: pagination.page,
                    count: meta.totalPages,
                }}
                setPageState={handlePaginationChange}
                selectable
                setRowsSelected={setPurchasesSelected}
                filterState={filter}
            />
        </>
    );
};

export default purchasesList;
