/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prettier/prettier */
import * as React from 'react';
import { IOrder } from 'services/admin/order';
import { IStore } from 'services/admin/store';
import { ordersStatus } from 'utils/helpers/status';

export const OrderDetail: React.FC<{ data: IOrder }> = ({ data }) => {
    return (
        <div className="p-4 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 items-center justify-between">
            <div className="flex items-center space-x-4">
                <div className="min-w-0 flex-1">
                    <h1 className="text-base font-bold leading-none text-gray-900 dark:text-white">Order details</h1>
                </div>
            </div>
            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                <li className="py-3 sm:py-4">
                    <div className="flex items-center space-x-4">
                        <div className="min-w-0 flex-1">
                            <p className="truncate font-medium text-gray-900 dark:text-white">Status:</p>
                        </div>
                        <div className="inline-flex items-center font-semibold text-gray-900 dark:text-white">
                            {ordersStatus(data.status)}
                        </div>
                    </div>
                </li>
                <li className="py-3 sm:py-4">
                    <div className="flex items-center space-x-4">
                        <div className="min-w-0 flex-1">
                            <p className="truncate font-medium text-gray-900 dark:text-white">Shipment Line Status:</p>
                        </div>
                        <div className="inline-flex items-center font-semibold text-gray-900 dark:text-white">
                            {data.shipmentLineStatus}
                        </div>
                    </div>
                </li>
                {!data.prePaid && data.cod && (
                    <>
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="min-w-0 flex-1">
                                    <p className="truncate font-medium text-gray-900 dark:text-white">Total Price:</p>
                                </div>
                                <div className="inline-flex items-center font-semibold text-gray-900 dark:text-white">
                                    {data.totalPrice}
                                </div>
                            </div>
                        </li>
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="min-w-0 flex-1">
                                    <p className="truncate text-sm text-gray-900 dark:text-white">Currency:</p>
                                </div>
                                <div className="inline-flex items-center font-semibold text-gray-900 dark:text-white">
                                    {data.currency}
                                </div>
                            </div>
                        </li>
                    </>
                )}
                {data.withCC !== false && (
                    <li className="pt-3 pb-0 sm:pt-4">
                        <div className="flex items-center space-x-4">
                            <div className="min-w-0 flex-1">
                                <p className="truncate font-medium text-gray-900 dark:text-white">
                                    Call Center Attempts :
                                </p>
                            </div>
                            <div className="inline-flex items-center font-semibold text-gray-900 dark:text-white">
                                {data.callCenterAttempt}
                            </div>
                        </div>
                    </li>
                )}
                <li className="py-3 sm:py-4">
                    <div className="flex items-center space-x-4">
                        <div className="min-w-0 flex-1">
                            <p className="truncate font-medium text-gray-900 dark:text-white">Offer:</p>
                        </div>
                    </div>
                    <div className="text-gray-900 dark:text-white">{(data?.store as IStore)?.offer || '--'}</div>
                </li>
            </ul>
        </div>
    );
};
