import { Label, TextInput } from 'flowbite-react';
import React from 'react';
import toast from 'react-hot-toast';
import { IConfig } from 'services/admin/configuration';

const getMinutesFromCronExpression = (cronExpression?: string) => {
    if (!cronExpression) {
        return 15;
    }
    // cronExpression schema is "*/minutes * * * *"
    const cronIndexes = cronExpression.split(' ');
    // minutesIndex schema is "*/minutes"
    const minutesIndex = cronIndexes[0];

    const minutesStepValue = minutesIndex.substring(2);
    const isValidMinutes = !(
        Number(minutesStepValue) < 1 ||
        Number(minutesStepValue) > 59 ||
        Number.isNaN(Number(minutesStepValue)) ||
        `${minutesStepValue}`.includes(',') ||
        `${minutesStepValue}`.includes('.') ||
        `${minutesStepValue}`.length < 1
    );
    if (!isValidMinutes) {
        toast.error(`Invalid minutes value found! Defaulting to 15`);
        return 15;
    }
    return Number(minutesStepValue);
};

const JobsConfiguration: React.FC<{
    jobsConfig: IConfig['jobs'];
}> = ({ jobsConfig }) => {
    const getNextRun = (minutes: number): string => {
        const now = new Date();
        const currentMinutes = now.getMinutes();
        const remainder = currentMinutes % minutes;
        const minutesToAdd = remainder === 0 ? minutes : minutes - remainder;
        const nextRunInUnix = now.setSeconds(0) + minutesToAdd * 60 * 1000;
        return new Date(nextRunInUnix).toLocaleString();
    };

    return (
        <div className=" p-4 mb-2 gap-4 w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <h1 className="mb-5 text-lg font-bold leading-none text-gray-900 dark:text-white">
                Automated orders assignment
            </h1>
            <div className="mb-4">
                <div className="mb-2 block">
                    <Label htmlFor="minutesStep" value="Auto assign orders" />
                </div>
                <div className="flex items-center dark:bg-gray-700 bg-gray-100 w-fit rounded-md">
                    <p className="px-2 font-bold">run every</p>
                    <TextInput
                        name="minutesStep"
                        type="text"
                        sizing="md"
                        value={getMinutesFromCronExpression(jobsConfig?.orderAssignSchedule || '')}
                        className="max-w-[50px]"
                        disabled
                    />
                    <p className="px-2 font-bold">minutes</p>
                </div>
                <p className="opacity-50 text-xs p-2">
                    Next run:{' '}
                    <span className="font-bold">
                        {getNextRun(getMinutesFromCronExpression(jobsConfig?.orderAssignSchedule || ''))}
                    </span>
                </p>
                <p className="opacity-50 text-xs p-2">
                    *Update the <span className="font-bold">Automated orders assigniment cycle</span> to automatically
                    run on every specified period
                </p>
            </div>

            <div className="mb-4">
                <div className="mb-2 block">
                    <Label htmlFor="maxOngoingOrdersByAgent" value="Max ongoing orders by agent" />
                </div>
                <div className="flex items-center dark:bg-gray-700 bg-gray-100 w-fit rounded-md">
                    <TextInput
                        name="maxOngoingOrdersByAgent"
                        type="text"
                        sizing="md"
                        value={jobsConfig?.maxOngoingOrdersByAgent || 0}
                        className="max-w-[50px]"
                        disabled
                    />
                    <p className="px-2 font-bold">orders</p>
                </div>
                <p className="opacity-50 text-xs p-2">
                    *Maximum <span className="font-bold">auto assigned</span> orders{' '}
                    <span className="font-bold">by every agent</span>
                </p>
            </div>
        </div>
    );
};

export default JobsConfiguration;
