import React from 'react';
import { Alert } from 'flowbite-react';
import { HiInformationCircle } from 'react-icons/hi';
import { ErrorMessage } from 'formik';
import { ErrorProps } from './CustomError.types';

const CustomError: React.FC<ErrorProps> = ({ name }) => {
    return (
        <>
            <ErrorMessage
                name={name}
                render={(msg) => (
                    <Alert color="failure" icon={HiInformationCircle}>
                        <span>
                            <span className="font-medium">Error: </span> {msg}
                        </span>
                    </Alert>
                )}
            />
        </>
    );
};

export default CustomError;
