/* eslint-disable no-nested-ternary */
import React from 'react';
import { Badge } from 'flowbite-react';
import { StatusAccountProps } from './Status.types';
import { ColorCustomerAccountStatus, ColorAccountStatus } from './Status.styles';

const StatusAccount: React.FC<StatusAccountProps> = ({ label, type }) => {
    return (
        <>
            {type === 'customer' ? (
                <div className="flex flex-wrap gap-2">
                    <Badge
                        color={ColorCustomerAccountStatus(label).color}
                        icon={ColorCustomerAccountStatus(label).icon}
                    >
                        {label === 3 ? 'Disabled ' : label === 2 ? 'Approved ' : 'Pending '}
                    </Badge>
                </div>
            ) : (
                <div className="flex flex-wrap gap-2">
                    <Badge color={ColorAccountStatus(label).color} icon={ColorAccountStatus(label).icon}>
                        {label === 2 ? 'Disabled ' : 'Active '}
                    </Badge>
                </div>
            )}
        </>
    );
};

export default StatusAccount;
