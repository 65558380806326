export const calculatePercent = (value: number, of: number) => {
    let percent = NaN;
    if (of > 0) {
        if (value === 0) {
            percent = 0;
        }
        percent = (value / of) * 100;
    }
    if (value === 0 && of === 0) {
        percent = 0;
    }

    return percent;
};

export const chartsType = {
    donut: 'donut',
    pieByDays: 'pieByDays',
    pieByCountry: 'pieByCountry',
} as const;

export type IchartsType = (typeof chartsType)[keyof typeof chartsType];

const getColorForStatus = (status: string) => {
    switch (status) {
        case 'Confirmed':
            return '#03C03C';

        case 'Canceled':
            return '#FF0000';

        case 'Expired':
            return '#1F51FF';

        case 'Wrong number':
            return '#FFA500';

        case 'Other':
            return '#0071c5';

        default:
            return '';
    }
};

export const getChartsData = (dashboardData: any, chartType: IchartsType, isDarkMode?: boolean) => {
    switch (chartType) {
        case chartsType.donut:
            return {
                options: {
                    labels: dashboardData?.ordersStatusCount?.map((elt: any) => elt?.status),
                    plotOptions: {
                        pie: {
                            donut: {
                                size: '70%',
                            },
                        },
                    },
                    legend: {
                        position: 'bottom',
                    },

                    colors: dashboardData?.ordersStatusCount?.map((elt: any) => getColorForStatus(elt?.status)),
                },

                series: dashboardData?.ordersStatusCount?.map((elt: any) => elt?.count),
            };

        case chartsType.pieByDays: {
            return {
                options: {
                    chart: {
                        id: 'basic-bar-days',
                    },
                    xaxis: {
                        categories: dashboardData?.ordersByWeekDays?.map((elt: any) => elt?.dayOfWeek),
                        labels: {
                            style: {
                                colors: isDarkMode ? '#FFFFFF' : 'black',
                                fontSize: '12px',
                            },
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '30%',
                            color: 'blue',
                        },
                    },

                    stroke: {
                        show: true,
                        width: 1.5,
                        colors: ['#fff'],
                    },
                    tooltip: {
                        shared: true,
                        intersect: false,
                    },
                },
                series: [
                    {
                        name: 'Confirmed Orders',
                        data: dashboardData?.ordersByWeekDays?.map((elt: any) => elt?.count),
                        color: '#03C03C',
                    },
                ],
            };
        }

        case chartsType.pieByCountry: {
            return {
                options: {
                    chart: {
                        id: 'basic-bar-country',
                    },
                    xaxis: {
                        categories: dashboardData?.ordersByCountry?.[0]?.countries?.map((elt: any) => elt?.country),
                        labels: {
                            style: {
                                colors: isDarkMode ? '#FFFFFF' : 'black',
                                fontSize: '12px',
                            },
                        },
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '30%',
                        },
                    },

                    stroke: {
                        show: true,
                        width: 1.5,
                        colors: ['#fff'],
                    },
                    tooltip: {
                        shared: true,
                        intersect: false,
                    },
                },
                series: [
                    {
                        name: 'Confirmed',
                        data: dashboardData?.ordersByCountry?.[0]?.countries?.map((elt: any) => elt.confirmedCount),
                        color: '#03C03C',
                    },
                    {
                        name: 'Delivered',
                        data: dashboardData?.ordersByCountry?.[0]?.countries?.map((elt: any) => elt.deliveredCount),
                        color: '#6495ED',
                    },
                ],
            };
        }

        default:
            return { options: {}, series: [] };
    }
};
