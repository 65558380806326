/* eslint-disable no-underscore-dangle */
import TextError from 'components/customError/customErrorText';
import FormError from 'components/customError/formError';
import { Badge, Button, Label, Radio, Select, TextInput } from 'flowbite-react';
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import {
    IStockPackingProps,
    IUpdateStock,
    ResellerProduct,
    STOCK_PLATEFORM,
    STOCK_TRANSPORTATION,
} from 'pages/reseller/reseller.types';
import React from 'react';
import toast from 'react-hot-toast';
import { FaPlus } from 'react-icons/fa';
import { getStocks, updateDeliveryStock } from 'services/admin/resellerStock';
import { updateResellerStockSchema } from 'utils/validations/resellerStockValidations';

const StockPacking: React.FC<IStockPackingProps> = (props) => {
    const { stock, setRows, currentPage, currentFilter, setModal } = props;
    const updateStock = (stockData: IUpdateStock) => {
        // const toastId = toast.loading('updating stock Informations...', dura);
        const updatePromise = updateDeliveryStock({
            id: stock._id,
            updatedData: {
                status: 2,
                productId: (stock.resellerProduct as ResellerProduct)?._id,
                ...stockData,
            },
        })
            .then(() => {
                setModal(false);
            })
            .catch(() => {})
            .finally(() => {
                setModal(false);
                getStocks({ ...currentFilter, page: currentPage })
                    .then((result) => {
                        setRows(result?.data?.docs);
                    })
                    .catch((err) => {
                        toast.error((err as any)?.response?.data?.errors?.message || 'Unable to load data.');
                    });
            });
        toast.promise(updatePromise, {
            loading: 'Updating stock Informations...',
            success: 'Reseller stock updated successfully',
            error: (err) => err?.response?.data?.errors,
        });
    };

    const initialForm: IUpdateStock = {
        boxes: [
            {
                ctns: 1,
                productQuantity: 0,
                height: 0,
                weight: 0,
                width: 0,
                length: 0,
                boxNumber: 1,
            },
        ],
        is_solely: 'NO',
        noofPieces: 0,
        battery: 0,
        logo: 0,
        transportation: 'A',
        plateform: '速卖通',
        customsDeclaration: 'NO',
        documents: '',
    };
    return (
        <div>
            <Badge color="gray" className="p-2 mb-2">
                Total quantity {stock?.quantity}
            </Badge>
            <Formik
                initialValues={initialForm}
                onSubmit={(values) => {
                    const stockBoxing: IUpdateStock = {
                        battery: Number(values.battery) as 0 | 1,
                        is_solely: values.is_solely,
                        noofPieces: values.noofPieces,
                        boxes: values.boxes,
                        transportation: values.transportation,
                        plateform: values.plateform,
                        documents: values.documents,
                        logo: Number(values.logo) as 0 | 1,
                    };
                    stockBoxing.noofPieces = values?.boxes?.length;
                    updateStock(stockBoxing);
                }}
                validationSchema={updateResellerStockSchema(stock?.quantity)}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit} placeholder="">
                        <FieldArray
                            name="boxes"
                            render={(arrayHelpers) => (
                                <div>
                                    {formik.values.boxes.map((_, index) => {
                                        return (
                                            <div className="border border-gray-900 rounded-lg content-center  p-2 mb-2 gap-2">
                                                <h4>Box {index + 1}</h4>
                                                <div className="grid grid-cols-2 gap-2 ">
                                                    <div>
                                                        <div className="mb-1 flex">
                                                            <Label>quantity</Label>
                                                            <span className="text-red-600 "> *</span>
                                                        </div>
                                                        <TextInput
                                                            {...formik.getFieldProps(`boxes.${index}.productQuantity`)}
                                                            name={`boxes[${index}].productQuantity`}
                                                            type="text"
                                                            placeholder="productQuantity"
                                                            helperText={
                                                                <span>
                                                                    <TextError
                                                                        name={`boxes[${index}].productQuantity`}
                                                                        component="div"
                                                                    />
                                                                </span>
                                                            }
                                                        />
                                                    </div>
                                                    <div>
                                                        <div className="mb-1 flex">
                                                            <Label>height (cm)</Label>
                                                            <span className="text-red-600 "> *</span>
                                                        </div>
                                                        <TextInput
                                                            {...formik.getFieldProps(`boxes.${index}.height`)}
                                                            name={`boxes[${index}].height`}
                                                            type="text"
                                                            helperText={
                                                                <span>
                                                                    <TextError
                                                                        name={`boxes[${index}].height`}
                                                                        component="div"
                                                                    />
                                                                </span>
                                                            }
                                                        />
                                                    </div>
                                                    <div>
                                                        <div className="mb-1 flex">
                                                            <Label>width (cm)</Label>
                                                            <span className="text-red-600 "> *</span>
                                                        </div>
                                                        <TextInput
                                                            {...formik.getFieldProps(`boxes.${index}.width`)}
                                                            name={`boxes[${index}].width`}
                                                            type="text"
                                                            helperText={
                                                                <span>
                                                                    <TextError
                                                                        name={`boxes[${index}].width`}
                                                                        component="div"
                                                                    />
                                                                </span>
                                                            }
                                                        />
                                                    </div>
                                                    <div>
                                                        <div className="mb-1 flex">
                                                            <Label>weight (Kg)</Label>
                                                            <span className="text-red-600 "> *</span>
                                                        </div>
                                                        <TextInput
                                                            {...formik.getFieldProps(`boxes.${index}.weight`)}
                                                            name={`boxes[${index}].weight`}
                                                            type="text"
                                                            helperText={
                                                                <span>
                                                                    <TextError
                                                                        name={`boxes[${index}].weight`}
                                                                        component="div"
                                                                    />
                                                                </span>
                                                            }
                                                        />
                                                    </div>
                                                    <div>
                                                        <div className="mb-1 flex">
                                                            <Label>length (cm)</Label>
                                                            <span className="text-red-600 "> *</span>
                                                        </div>
                                                        <TextInput
                                                            {...formik.getFieldProps(`boxes.${index}.length`)}
                                                            name={`boxes[${index}].length`}
                                                            type="text"
                                                        />
                                                        <FormError name={`boxes[${index}].length`} component="div" />
                                                    </div>

                                                    <Button
                                                        color="gray"
                                                        size="md"
                                                        className="mt-6"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        Remove
                                                    </Button>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <button
                                        onClick={() =>
                                            arrayHelpers.push({
                                                ctns: 1,
                                                productQuantity: 0,
                                                height: 0,
                                                weight: 0,
                                                width: 0,
                                                leng: 0,
                                                boxNumber: formik?.values?.boxes?.length + 1,
                                            })
                                        }
                                        type="button"
                                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    >
                                        <FaPlus className="w-4 h-4" />
                                    </button>
                                </div>
                            )}
                        />
                        <>
                            <ErrorMessage
                                name="boxes"
                                render={(msg) => (
                                    <>
                                        {typeof msg === 'string' ? (
                                            <span>
                                                <span className="text-xs text-red-600 font-semibold">*{msg} </span>
                                            </span>
                                        ) : null}
                                    </>
                                )}
                            />
                        </>
                        <div className="grid grid-cols-2 gap-4">
                            <div className="mt-2">
                                <Label>
                                    Plateform <span className="text-xs text-red-600 font-semibold">*</span>
                                </Label>
                                <Select
                                    sizing="sm"
                                    id="plateformId"
                                    {...formik.getFieldProps(`plateform`)}
                                    name="plateform"
                                    helperText={
                                        <span>
                                            <TextError name="plateform" component="div" />
                                        </span>
                                    }
                                >
                                    {STOCK_PLATEFORM.map((plateform) => (
                                        <option key={plateform.value} value={plateform.value}>
                                            {plateform.label}
                                        </option>
                                    ))}
                                </Select>
                                <span>
                                    <TextError name="plateform" component="div" />
                                </span>
                            </div>
                            <div className="mt-2">
                                <Label>
                                    Transportation <span className="text-xs text-red-600 font-semibold">*</span>
                                </Label>

                                <Select
                                    sizing="sm"
                                    id="transportationId"
                                    {...formik.getFieldProps(`transportation`)}
                                    name="transportation"
                                    helperText={
                                        <span>
                                            <TextError name="transportation" component="div" />
                                        </span>
                                    }
                                >
                                    {STOCK_TRANSPORTATION.map((transportation) => (
                                        <option key={transportation.value} value={transportation.value}>
                                            {transportation.label}
                                        </option>
                                    ))}
                                </Select>
                                <span>
                                    <TextError name="transportation" component="div" />
                                </span>
                            </div>
                        </div>
                        <div className="flex items-center gap-2">
                            <Label className="p-2">
                                does this product contain a battery?
                                <span className="text-red-600 "> *</span>
                            </Label>
                            <fieldset className="flex max-w-md  gap-4 p-2">
                                <div className="flex items-center gap-2">
                                    <Radio
                                        id="battery_yes"
                                        {...formik.getFieldProps(`battery`)}
                                        name="battery"
                                        value={1}
                                    />
                                    <Label htmlFor="battery_yes">Yes</Label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Radio
                                        id="battery_no"
                                        {...formik.getFieldProps(`battery`)}
                                        name="battery"
                                        defaultChecked
                                        value={0}
                                    />
                                    <Label htmlFor="battery_no">No</Label>
                                </div>
                            </fieldset>
                        </div>
                        <div className="flex items-center gap-2">
                            <Label className="p-2">
                                LOGO/BRAND
                                <span className="text-red-600 "> *</span>
                            </Label>
                            <fieldset className="flex max-w-md  gap-4 p-2">
                                <div className="flex items-center gap-2">
                                    <Radio id="logo_yes" {...formik.getFieldProps(`logo`)} name="logo" value={1} />
                                    <Label htmlFor="logo_yes">Yes</Label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Radio
                                        id="logo_no"
                                        {...formik.getFieldProps(`logo`)}
                                        name="logo"
                                        defaultChecked
                                        value={0}
                                    />
                                    <Label htmlFor="logo_no">No</Label>
                                </div>
                            </fieldset>
                        </div>
                        <div className="flex items-center gap-2">
                            <Label className="p-2">
                                is solely?
                                <span className="text-red-600 "> *</span>
                            </Label>
                            <fieldset className="flex max-w-md  gap-4 p-2">
                                <div className="flex items-center gap-2">
                                    <Radio
                                        id="is_solely_yes"
                                        {...formik.getFieldProps(`is_solely`)}
                                        name="is_solely"
                                        value="YES"
                                    />
                                    <Label htmlFor="is_solely_yes">Yes</Label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Radio
                                        id="is_solely_no"
                                        {...formik.getFieldProps(`is_solely`)}
                                        name="is_solely"
                                        defaultChecked
                                        value="NO"
                                    />
                                    <Label htmlFor="is_solely_no">No</Label>
                                </div>
                            </fieldset>
                        </div>
                        <div className="flex items-center gap-2">
                            <Label className="p-2">
                                Customs declaration
                                <span className="text-red-600 "> *</span>
                            </Label>
                            <fieldset className="flex max-w-md  gap-4 p-2">
                                <div className="flex items-center gap-2">
                                    <Radio
                                        id="customsDeclaration_yes"
                                        {...formik.getFieldProps(`customsDeclaration`)}
                                        name="customsDeclaration"
                                        value="YES"
                                    />
                                    <Label htmlFor="customsDeclaration_yes">Yes</Label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Radio
                                        id="customsDeclaration_no"
                                        {...formik.getFieldProps(`customsDeclaration`)}
                                        name="customsDeclaration"
                                        defaultChecked
                                        value="NO"
                                    />
                                    <Label htmlFor="customsDeclaration_no">No</Label>
                                </div>
                            </fieldset>
                        </div>
                        {formik.values.customsDeclaration === 'YES' ? (
                            <div>
                                <div className="mb-1 flex">
                                    <Label>Documents</Label>
                                    <span className="text-red-600 "> *</span>
                                </div>
                                <TextInput
                                    {...formik.getFieldProps(`documents`)}
                                    name="documents"
                                    type="text"
                                    helperText={
                                        <span>
                                            <TextError name="documents" component="div" />
                                        </span>
                                    }
                                />
                            </div>
                        ) : null}
                        <div className="flex gap-2 justify-end py-2 px-2 items-center rounded-b-lg ">
                            <div className="w-2/6">
                                <button
                                    onClick={() => {
                                        formik.resetForm();
                                        setModal(false);
                                    }}
                                    type="submit"
                                    className="text-gray-800 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 rounded-full  focus:outline-none focus:ring-blue-300 font-medium  text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
                                >
                                    {formik.isSubmitting && (
                                        <svg
                                            aria-hidden="true"
                                            role="status"
                                            className="inline w-5 h-5 me-1 text-white animate-spin"
                                            viewBox="0 0 100 101"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#5e6070"
                                            />
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    )}
                                    <span>Cancel</span>
                                </button>
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                >
                                    {formik.isSubmitting && (
                                        <svg
                                            aria-hidden="true"
                                            role="status"
                                            className="inline w-5 h-5 me-1 text-white animate-spin"
                                            viewBox="0 0 100 101"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#5e6070"
                                            />
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    )}
                                    <span>Confirm</span>
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
export default StockPacking;
