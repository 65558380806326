import { contryCode } from 'utils/countryList';
import * as Yup from 'yup';
import 'yup-phone-lite';

// eslint-disable-next-line no-useless-escape

export const addSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Email is required'),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().optional(),
    phone: Yup.string()
        .phone(contryCode as [], 'Invalid number')
        .optional(),
});

export const addSchemaCallAgent = Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Email is required'),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().optional(),
    timeZone: Yup.string().required('Providing time zone is required'),
    phone: Yup.string()
        .phone(contryCode as [], 'Invalid number')
        .optional(),
    // callCenter: Yup.string().required('Assign is required'),
});
