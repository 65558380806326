export const isAuthenticated = () => {
    const token = window.localStorage.getItem('token');
    return !!token;
};

export const getBearerToken = () => ({
    headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
});

export const getCurrentUser = () => {
    const token = localStorage.getItem('token');
    if (token) {
        const userData = JSON.parse(window.atob(token.split('.')[1]));
        return userData;
    }
    return false;
};

export const getUser = () => {
    const user = localStorage.getItem('user');
    if (user) {
        const decodedUser = JSON.parse(localStorage.getItem('user')!);
        return decodedUser;
    }
    return false;
};
