import axios from 'utils/axios';
import { getBearerToken } from 'utils/helpers/auth';

export const addAccountManager = async (data: any) => {
    const newAccountManager = await axios.post(`/admin/accountmanagers`, data, getBearerToken());
    return newAccountManager;
};

export const getAllAccountManagers = async (filter: any) => {
    const accountManagers = await axios.post(`/admin/accountmanagers/filter`, filter, getBearerToken());
    return accountManagers;
};

export const getAccountManager = async (id: any) => {
    const accountManager = await axios.get(`/admin/accountmanagers/${id}`, getBearerToken());
    return accountManager;
};

export const getAccountManagers = async (ids: any) => {
    const accountManagers = await axios.post(`/admin/accountmanagers/assigned`, ids, getBearerToken());
    return accountManagers;
};

export const updateAccountManager = async (id: any, data: any) => {
    const accountManagerUpdate = await axios.put(`/admin/accountmanagers/${id}`, data, getBearerToken());
    return accountManagerUpdate;
};
