/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import CustomBtn from 'components/customButton/customBtn';
import { AiOutlineAppstoreAdd } from 'react-icons/ai';
import { getConfiguration, updateConfigItem } from 'services/admin/configuration';
import { Button, Label, Modal, Select, TextInput } from 'flowbite-react';
import { HiOutlineExclamationCircle, HiPencilAlt } from 'react-icons/hi';
import { Form, Formik } from 'formik';
import { UpdateFinanceService } from 'utils/validations/bankInformationsValidation';
import { FaSave } from 'react-icons/fa';
import CustomError from 'components/customError/CustomError';
import toast from 'react-hot-toast';
import FlagIcon from 'components/flags/flagIcon';
import { MdDeleteForever } from 'react-icons/md';
import AddBankConfiguration from './addBankConfiguration';
import { BankInformationsProps } from './bankConfiguration.types';
import UpdateBanklInfos from './updateBanklInfos';

const BankConfiguration: React.FC<BankInformationsProps> = ({ bankConfig, configId, setBankConfig }) => {
    const [open, setOpen] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openDeleteFinanceServiceModal, setOpenDeleteFinanceServiceModal] = useState(false);
    const [editItem, setEditItem] = useState<any[]>([]);
    const [editFinanceServiceItem, setEditFinanceServiceItem] = useState<any[]>([]);
    const [deleteIndex, setDeleteIndex] = useState(0);

    useEffect(() => {
        setEditItem(() => {
            return bankConfig?.simpleBank?.map(() => {
                return false;
            });
        });
    }, [bankConfig]);

    useEffect(() => {
        setEditFinanceServiceItem(() => {
            return bankConfig?.financeService?.map(() => {
                return false;
            });
        });
    }, [bankConfig]);

    const [updateLoader, setUpdateLoader] = useState(false);
    const updateBankInformations = (values: any, index: number) => {
        setUpdateLoader(true);
        const newValues = bankConfig;
        newValues.simpleBank[index] = values;
        updateConfigItem(configId, { bankInformations: newValues })
            .then((res) => {
                toast.success(res?.data?.message);
                setUpdateLoader(false);
                getConfiguration().then((result) => {
                    setBankConfig(result.data?.bankInformations);
                });
            })
            .catch((err) => {
                toast.error(err.response.data.errors.message);
                setUpdateLoader(false);
            });
    };

    const updateFinancalService = (values: any, index: number) => {
        setUpdateLoader(true);
        const newValues = bankConfig;
        newValues.financeService[index] = values;
        updateConfigItem(configId, { bankInformations: newValues })
            .then((res) => {
                toast.success(res?.data?.message);
                setUpdateLoader(false);
                getConfiguration().then((result) => {
                    setBankConfig(result.data?.bankInformations);
                });
            })
            .catch((err) => {
                toast.error(err.response.data.errors.message);
                setUpdateLoader(false);
            });
    };

    const deletePricing = (index: number) => {
        const toastLoader = toast.loading('Loading...');
        const newValues = bankConfig;
        newValues?.simpleBank.splice(index, 1);
        updateConfigItem(configId, { bankInformations: newValues })
            .then(() => {
                toast.success('Bank Configuration deleted', { id: toastLoader });
                setOpenDeleteModal(false);
                getConfiguration().then((result) => {
                    setBankConfig(result.data?.bankInformations);
                });
            })
            .catch((err) => toast.error(err?.response?.data?.errors?.message, { id: toastLoader }));
        setOpen(false);
    };

    const deleteFinanceService = (index: number) => {
        const toastLoader = toast.loading('Loading...');
        const newValues = bankConfig;
        newValues?.financeService.splice(index, 1);
        updateConfigItem(configId, { bankInformations: newValues })
            .then(() => {
                toast.success('Bank Configuration deleted', { id: toastLoader });
                setOpenDeleteFinanceServiceModal(false);
                getConfiguration().then((result) => {
                    setBankConfig(result.data?.bankInformations);
                });
            })
            .catch((err) => toast.error(err?.response?.data?.errors?.message, { id: toastLoader }));
        setOpen(false);
    };

    return (
        <>
            <AddBankConfiguration open={open} setOpen={setOpen} configBank={bankConfig} setConfigBank={setBankConfig} />
            <div className="flex justify-end gap-2 p-2">
                <CustomBtn variant="primary" onClick={() => setOpen(true)} pill>
                    <AiOutlineAppstoreAdd className="mr-2 h-5 w-5" />
                    New
                </CustomBtn>
            </div>
            <Modal
                show={openDeleteFinanceServiceModal}
                size="md"
                popup
                onClose={() => setOpenDeleteFinanceServiceModal(false)}
            >
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                        <p className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            Are you sure you want to delete this configuration ?
                        </p>
                        <div className="flex justify-center gap-4">
                            <CustomBtn variant="tertiary" onClick={() => setOpenDeleteFinanceServiceModal(false)}>
                                No, cancel
                            </CustomBtn>
                            <CustomBtn
                                variant="failure"
                                onClick={() => {
                                    deleteFinanceService(deleteIndex);
                                }}
                            >
                                Yes, am sure
                            </CustomBtn>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={openDeleteModal} size="md" popup onClose={() => setOpenDeleteModal(false)}>
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                        <p className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            Are you sure you want to delete this configuration ?
                        </p>
                        <div className="flex justify-center gap-4">
                            <CustomBtn variant="tertiary" onClick={() => setOpenDeleteModal(false)}>
                                No, cancel
                            </CustomBtn>
                            <CustomBtn
                                variant="failure"
                                onClick={() => {
                                    deletePricing(deleteIndex);
                                }}
                            >
                                Yes, am sure
                            </CustomBtn>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {bankConfig?.simpleBank?.map((config, key: number) => (
                <div
                    className=" p-4 mb-2 gap-4 w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
                    // eslint-disable-next-line react/no-array-index-key
                    key={`bankConfig${key}`}
                >
                    <div className="flex items-center space-x-4">
                        <div className="min-w-0 flex-1">
                            <div className="flex gap-2">
                                <h1 className="text-lg font-bold leading-none text-gray-900 dark:text-white">
                                    Coordinates
                                </h1>
                                <FlagIcon flag={config?.accountCurrency as string} />
                            </div>
                        </div>
                        <div className="inline-flex items-center p-2 text-base font-semibold text-gray-900 dark:text-white">
                            {!editItem[key] && (
                                <Button
                                    pill
                                    size="xs"
                                    onClick={() => {
                                        // setEdit(true);
                                        const nextEditItem = editItem.map((c, i) => {
                                            if (i === key) {
                                                return true;
                                            }
                                            return c;
                                        });
                                        setEditItem(nextEditItem);
                                    }}
                                >
                                    <HiPencilAlt className="mr-2 h-5 w-5" />
                                    Edit
                                </Button>
                            )}
                        </div>
                    </div>

                    {editItem[key] ? (
                        <UpdateBanklInfos
                            config={config}
                            updateBankInformations={updateBankInformations}
                            bankConfig={bankConfig}
                            editItem={editItem}
                            setEditItem={setEditItem}
                            setOpenDeleteModal={setOpenDeleteModal}
                            setDeleteIndex={setDeleteIndex}
                        />
                    ) : (
                        <div className="grid grid-cols-2 gap-4 ">
                            <div className="bg-white p-2 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                <div className="items-center justify-between mb-4">
                                    <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                                        <li className="py-3 sm:py-4">
                                            <div className="flex items-center space-x-4">
                                                <div className="min-w-0 flex-1">
                                                    <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                        Account Holder
                                                    </p>
                                                </div>
                                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                    {config?.accountHolder || '--'}
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-3 sm:py-4">
                                            <div className="flex items-center space-x-4">
                                                <div className="min-w-0 flex-1">
                                                    <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                        Payment Type:
                                                    </p>
                                                </div>
                                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                    {config?.paymentType || '--'}
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-3 sm:py-4">
                                            <div className="flex items-center space-x-4">
                                                <div className="min-w-0 flex-1">
                                                    <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                        Account Number:
                                                    </p>
                                                </div>
                                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                    {config?.accountNumber || '--'}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="bg-white p-2 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                <div className="items-center justify-between mb-4">
                                    <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                                        <li className="py-3 sm:py-4">
                                            <div className="flex items-center space-x-4">
                                                <div className="min-w-0 flex-1">
                                                    <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                        {config?.accountCurrency === 'USD' &&
                                                        config?.paymentType === 'local'
                                                            ? 'Account Type'
                                                            : 'SWIFT / BIC'}
                                                    </p>
                                                </div>
                                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                    {config?.accountCurrency === 'USD' &&
                                                    config?.paymentType === 'local'
                                                        ? config?.accountType
                                                        : config?.swiftBic}
                                                </div>
                                            </div>
                                        </li>
                                        <li className="pt-3 pb-0 sm:pt-4">
                                            <div className="flex items-center space-x-4">
                                                <div className="min-w-0 flex-1">
                                                    <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                        {config?.accountCurrency === 'USD' &&
                                                            config?.paymentType === 'local' &&
                                                            'ACH routing number'}

                                                        {config?.accountCurrency === 'USD' &&
                                                            config?.paymentType === 'international' &&
                                                            'Routing number'}

                                                        {config?.accountCurrency === 'EUR' && 'IBAN'}
                                                    </p>
                                                </div>
                                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                    {config?.accountCurrency === 'USD' &&
                                                        config?.paymentType === 'local' &&
                                                        config?.routingNumber}

                                                    {config?.accountCurrency === 'USD' &&
                                                        config?.paymentType === 'international' &&
                                                        config?.routingNumber}

                                                    {config?.accountCurrency === 'EUR' && config?.iban}
                                                </div>
                                            </div>
                                        </li>
                                        {/* <li className="pt-3 pb-0 sm:pt-4">
                                            <div className="flex items-center space-x-4">
                                                <div className="min-w-0 flex-1">
                                                    <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                        Account Number :
                                                    </p>
                                                </div>
                                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                    {config?.accountNumber || 'xxxx xxxx xxxx xxxx'}
                                                </div>
                                            </div>
                                        </li> */}
                                        <li className="pt-3 pb-0 sm:pt-4">
                                            <div className="flex items-center space-x-4">
                                                <div className="min-w-0 flex-1">
                                                    <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                        Description :
                                                    </p>
                                                </div>
                                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                    {config?.description || 'No description provided'}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ))}

            <div className="grid grid-cols-2 gap-1">
                {bankConfig?.financeService?.map((config, key: number) => (
                    <div
                        className=" p-4 mb-2 gap-4  bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
                        // eslint-disable-next-line react/no-array-index-key
                        key={`bankConfig${key}`}
                    >
                        <div className="flex items-center space-x-4">
                            <div className="min-w-0 flex-1">
                                <div className="flex gap-2">
                                    <h1 className="text-lg font-bold leading-none text-gray-900 dark:text-white">
                                        Coordinates
                                    </h1>
                                    {config.serviceType}
                                </div>
                            </div>
                            <div className="inline-flex items-center p-2 text-base font-semibold text-gray-900 dark:text-white">
                                {!editFinanceServiceItem[key] && (
                                    <Button
                                        pill
                                        size="xs"
                                        onClick={() => {
                                            // setEdit(true);
                                            const nextEditFinanceServiceItem = editFinanceServiceItem.map((c, i) => {
                                                if (i === key) {
                                                    return true;
                                                }
                                                return c;
                                            });
                                            setEditFinanceServiceItem(nextEditFinanceServiceItem);
                                        }}
                                    >
                                        <HiPencilAlt className="mr-2 h-5 w-5" />
                                        Edit
                                    </Button>
                                )}
                            </div>
                        </div>

                        {editFinanceServiceItem[key] ? (
                            <Formik
                                initialValues={{
                                    email: config?.email,
                                    serviceType: config?.serviceType,
                                }}
                                onSubmit={(values, onSubmitProps) => {
                                    updateFinancalService(values, bankConfig.financeService.indexOf(config));
                                    onSubmitProps.setSubmitting(false);
                                }}
                                validationSchema={UpdateFinanceService}
                            >
                                {(formik) => (
                                    <Form onSubmit={formik.handleSubmit} placeholder="">
                                        <div className="grid grid-cols-1 gap-4 ">
                                            <div className="bg-white p-2 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                                <div className="relative w-full mb-6 group">
                                                    <div className="mb-2 block">
                                                        <Label htmlFor="email" value="Email" />
                                                    </div>
                                                    <TextInput
                                                        id="email"
                                                        type="text"
                                                        sizing="md"
                                                        {...formik.getFieldProps('email')}
                                                        helperText={
                                                            <>
                                                                <CustomError name="email" component="div" />
                                                            </>
                                                        }
                                                    />
                                                </div>

                                                <div>
                                                    <div className="mb-2 block">
                                                        <Label className="dark:text-white" value="Service Type" />
                                                    </div>
                                                    <Select id="serviceType" {...formik.getFieldProps('serviceType')}>
                                                        <option key="select" value="">
                                                            Select service
                                                        </option>
                                                        <option>PayPal</option>
                                                        <option>Payoneer</option>
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap gap-4 justify-between py-2">
                                            <div>
                                                <Button
                                                    pill
                                                    color="failure"
                                                    onClick={() => {
                                                        const nextEditFinanceServiceItem = editFinanceServiceItem.map(
                                                            (c, i) => {
                                                                if (i === key) {
                                                                    return false;
                                                                }
                                                                return c;
                                                            },
                                                        );
                                                        setEditFinanceServiceItem(nextEditFinanceServiceItem);
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                            <div className="flex gap-2">
                                                <Button
                                                    pill
                                                    color="failure"
                                                    onClick={() => {
                                                        setOpenDeleteFinanceServiceModal(true);
                                                        setDeleteIndex(key);
                                                    }}
                                                >
                                                    <MdDeleteForever className="mr-2 h-5 w-5" />
                                                    Delete
                                                </Button>

                                                <Button
                                                    pill
                                                    type="submit"
                                                    disabled={updateLoader}
                                                    isProcessing={updateLoader}
                                                >
                                                    <FaSave className="mr-2 h-5 w-5" />
                                                    Save
                                                </Button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        ) : (
                            <div className="grid grid-cols-1 gap-4 ">
                                <div className="bg-white p-2 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                    <div className="items-center justify-between mb-4">
                                        <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                                            <li className="py-3 sm:py-4">
                                                <div className="flex items-center space-x-4">
                                                    <div className="min-w-0 flex-1">
                                                        <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                            Email:
                                                        </p>
                                                    </div>
                                                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                        {config?.email || '--'}
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="py-3 sm:py-4">
                                                <div className="flex items-center space-x-4">
                                                    <div className="min-w-0 flex-1">
                                                        <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                            Type:
                                                        </p>
                                                    </div>
                                                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                        {config?.serviceType || '--'}
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </>
    );
};

export default BankConfiguration;
