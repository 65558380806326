/* eslint-disable react/style-prop-object */
/* eslint-disable no-underscore-dangle */
import { Modal, Tabs } from 'flowbite-react';
import React, { useState } from 'react';
import { FaUserEdit } from 'react-icons/fa';
import StatusAccount from 'components/status/statusAccount';
import { IAccountManagerActions } from '../accountManager.types';
import AccountManagerPersonalInfo from '../updateAccountManager/accountManagerPersonalInfo';
import AManagerAccountManagment from '../updateAccountManager/aCAccountManagment';

const AccountManagerActions = (props: IAccountManagerActions) => {
    const [ModalEditAccountManager, setModalEditAccountManager] = useState(false);

    const EditAccountManager = () => {
        setModalEditAccountManager(true);
    };
    const EditAccountManagerClose = () => {
        setModalEditAccountManager(false);
    };

    const { accountManager, setRows, currentPage, currentFilter } = props;
    return (
        <>
            <div>
                <FaUserEdit className="h-5 w-5 cursor-pointer" onClick={EditAccountManager} />
            </div>
            <Modal dismissible show={ModalEditAccountManager} popup onClose={EditAccountManagerClose}>
                <Modal.Header>
                    <StatusAccount type="others" label={accountManager.status} />
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        aria-label="Tabs with icons"
                        style="underline"
                        theme={{
                            tablist: {
                                tabitem: {
                                    base: 'flex items-center justify-center p-4 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 ',
                                },
                            },
                        }}
                    >
                        <Tabs.Item title="Account Management">
                            <AManagerAccountManagment
                                status={accountManager.status}
                                id={accountManager._id}
                                setRows={setRows}
                                currentPage={currentPage}
                                currentFilter={currentFilter}
                            />
                        </Tabs.Item>
                        <Tabs.Item title="Personal Information">
                            <AccountManagerPersonalInfo
                                accountManager={accountManager}
                                setRows={setRows}
                                currentPage={currentPage}
                                currentFilter={currentFilter}
                            />
                        </Tabs.Item>
                    </Tabs>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default AccountManagerActions;
