/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/system';
import darkTheme from 'theme/darkTheme';
import customTheme from 'theme';

type DarkMode = {
    mode: boolean;
    colorMode: () => void;
};

type ThemeContextChildrenProps = {
    children: React.ReactNode;
};

export const ColorModeContext = createContext<DarkMode>({
    mode: false,
    colorMode: () => {},
});

const ThemeMode = ({ children }: ThemeContextChildrenProps) => {
    const storedDarkMode = localStorage.getItem('DARK_MODE');

    const modeState = () => {
        if (storedDarkMode === 'true') {
            return true;
        }

        return false;
    };
    const [mode, setMode] = useState(modeState);

    const colorMode = () => {
        const m = !mode;
        setMode(!mode);
        if (m) {
            document.documentElement.classList.remove('light');
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
            document.documentElement.classList.add('light');
        }
    };

    useEffect(() => {
        const darkM = localStorage.getItem('DARK_MODE');
        if (darkM === 'true') {
            document.documentElement.classList.remove('light');
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
            document.documentElement.classList.add('light');
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('DARK_MODE', JSON.stringify(mode));
    }, [mode]);

    return (
        <ColorModeContext.Provider value={{ colorMode, mode }}>
            <ThemeProvider theme={mode === true ? darkTheme : customTheme}>{children}</ThemeProvider>
        </ColorModeContext.Provider>
    );
};

export default ThemeMode;
