/* eslint-disable react/style-prop-object */
/* eslint-disable no-underscore-dangle */
import Tablesku from 'components/listSkus/TableSku';
import { Dropdown, Modal } from 'flowbite-react';
import { IProductActions } from 'pages/productList/product.types';
import React from 'react';
import toast from 'react-hot-toast';
import { HiChat, HiDotsVertical, HiPencil } from 'react-icons/hi';
import { IoIosBarcode } from 'react-icons/io';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCurrentUser } from 'utils/helpers/auth';
import { IProduct } from 'utils/Interfaces/products.interface';

const ProductActions = (props: IProductActions) => {
    const [openModalSkus, setOpenModalSkus] = React.useState(false);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { product } = props;

    const handleShowSkus = () => {
        if (
            (product.variants.length === 0 && !product.globalSKU) ||
            (product.variants as IProduct[]).some((variant) => !variant.globalSKU)
        ) {
            toast.error('Sorry, something went wrong. Please try again!');
        } else {
            setOpenModalSkus(true);
        }
    };
    const navigateEditProduct = () => {
        const user = getCurrentUser();
        let navigation = 'admin';
        if (user.role === 'accountManager') {
            navigation = 'accountManager';
        }
        if (user.role === 'productProvider') {
            navigation = 'productProvider';
        }
        navigate(`/${navigation}/product-settings`, {
            state: {
                tabId: 0,
                productId: product._id,
                type: product.type,
                customerId: product.customer,
                productName: product.name,
                isRestock: pathname.includes('restock'),
            },
        });
    };
    const navigateChatProduct = () => {
        const user = getCurrentUser();
        let navigation = 'admin';
        if (user.role === 'accountManager') {
            navigation = 'accountManager';
        }
        if (user.role === 'productProvider') {
            navigation = 'productProvider';
        }
        navigate(`/${navigation}/product-settings`, {
            state: {
                tabId: 2,
                productId: product._id,
                type: product.type,
                customerId: product.customer,
                productName: product.name,
            },
        });
    };
    const navigationList = [
        {
            label: 'Product details',
            action: navigateEditProduct,
            iconAcion: HiPencil,
        },
        {
            label: 'Preview SKU',
            action: () => handleShowSkus(),
            iconAcion: IoIosBarcode,
        },
        {
            label: 'Chat',
            action: navigateChatProduct,
            iconAcion: HiChat,
        },
    ];

    return (
        <>
            <Modal
                dismissible
                show={openModalSkus}
                popup
                onClose={() => setOpenModalSkus(false)}
                theme={{
                    content: {
                        base: 'w-fit',
                        inner: 'relative rounded-lg bg-white shadow dark:bg-gray-700 flex flex-col max-h-[90vh] w-fit',
                    },
                }}
            >
                {' '}
                <Modal.Header className="p-4 pb-0">Product Skus - {product?.name || '--'}</Modal.Header>
                <Modal.Body className=" flex !p-4 ">
                    <Tablesku product={product} />
                </Modal.Body>
            </Modal>

            <Dropdown inline arrowIcon={false} label={<HiDotsVertical />}>
                {navigationList.map((navigation) => {
                    return (
                        <Dropdown.Item icon={navigation.iconAcion} onClick={navigation.action} key={navigation.label}>
                            {navigation.label}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown>
        </>
    );
};
export default ProductActions;
