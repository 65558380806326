/* eslint-disable no-unused-vars */
import { useRef, useEffect } from 'react';

function UseDocumentTitle(name: any, prevailOnUnmount = false) {
    const defaultTitle = useRef('Logistio ERP');

    useEffect(() => {
        document.title = `${name} | Logistio ERP`;
    }, [name]);

    useEffect(
        () => () => {
            if (!prevailOnUnmount) {
                document.title = defaultTitle.current;
            }
        },
        [],
    );
}
export default UseDocumentTitle;
