import { contryCode } from 'utils/countryList';
import * as Yup from 'yup';
import 'yup-phone-lite';

// eslint-disable-next-line no-useless-escape
// const phoneRegExp = /^((\+[1-9]{1,4}[ \-])|(\([0-9]{2,3}\)[ \-])|([0-9]{2,4})[ \-])?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;

export const changePersonalInfos = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().optional(),
    phone: Yup.string()
        .phone(contryCode as [], 'Invalid number')
        .required('Phone number is required'),
});

export const changeCustomerInfos = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().optional(),
    phone: Yup.string()
        .phone(contryCode as [], 'Invalid number')
        .optional(),
    email: Yup.string().email('New Email is invalid').required('New Email is required'),
});

export const changeUserInfos = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().optional(),
    phone: Yup.string()
        .phone(contryCode as [], 'Invalid number')
        .optional(),
    email: Yup.string().email('New Email is invalid').required('New Email is required'),
});
