import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getCurrentUser, isAuthenticated } from 'utils/helpers/auth';

const PublicRoute = () => {
    const isAuth = isAuthenticated();

    return isAuth ? <Navigate to={`${getCurrentUser().role}`} /> : <Outlet />;
};

export default PublicRoute;
