import { Accordion, Button, Modal, Pagination, Select } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { getCustomers } from 'services/admin/customer';
import { ICustomer } from 'pages/customers/customer.types';
import AutoComplete from 'components/autocomplete/autoComplete';

import { FaClipboardList } from 'react-icons/fa';
import { ICredit, getCredits } from 'services/accountManager/credits';
import useDebouncedValue from 'utils/hooks/useDebouncedValue';
import toast from 'react-hot-toast';
import Datepicker from 'react-tailwindcss-datepicker';
import CreditHistory from 'pages/CreditsManagement/creditOperationHistory';
import moment from 'moment-timezone';
import AddCredit from './addCredit';
import { statusDisplay } from './creditElements';
import { IdateRange } from './credits.types';

interface Ipagination {
    page: number;
    limit: number;
}

interface Ifilter {
    status?: number;
    customer?: string;
    type?: number;
    creditType?: string;
    dateRange?: { startDate?: string | number; endDate?: string | number };
}
interface Imeta {
    totalDocs: number;
    limit: number;
    totalPages: number;
    page: number;
    pagingCounter: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    prevPage: number | null;
    nextPage: number | null;
}
const Credits = () => {
    const [ModalAddCredit, setModalAddCredit] = useState(false);
    const [customers, setCustomers] = useState<ICustomer[]>([]);

    const EditCustomerClose = () => {
        setModalAddCredit(false);
    };
    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });
    const [filter, setFilter] = useState<Ifilter>({});
    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 20,
    });
    const [creditList, setCreditList] = useState<ICredit[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_isLoading, setIsLoading] = useState(false);
    const [rangevalue, setRangeValue] = useState<IdateRange>({
        startDate: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 2).toLocaleString(),
        endDate: new Date().toLocaleString(),
    });
    const [currentPage, setCurrentPage] = useState(pagination.page);

    const handlePageLimit = (limit: number) => {
        setPagination({ ...pagination, limit });
    };
    const onPageChange = (newPage: number) => {
        setCurrentPage(newPage);
        setPagination({ ...pagination, page: newPage });
    };
    function handleChangeFilterCustomer(filterCustomer: string) {
        if (filterCustomer !== '') {
            setFilter({ ...filter, ...{ customer: filterCustomer } });
        } else {
            setFilter({ ...filter, ...{ customer: undefined } });
        }
        setPagination({ ...pagination, page: 1 });
        setMeta({ ...meta, page: -1 });
    }

    const handleRangeFilterChange = (newValue: any) => {
        if (newValue) {
            const startD = moment.tz(newValue.startDate, 'Asia/Shanghai');
            const endD = moment.tz(newValue.endDate, 'Asia/Shanghai');
            endD.set('hour', 23);
            endD.set('minute', 59);
            endD.set('second', 59);
            const newRange = {
                startDate: startD.format(),
                endDate: endD.format(),
            };
            setRangeValue(newRange);
            setFilter({ ...filter, ...{ dateRange: newValue, page: 1 } });
            setMeta({ ...meta, page: -1 });
        }
    };
    function handleChange(event: any) {
        setIsLoading(true);
        if (event.target.value !== '') {
            setFilter({ ...filter, ...{ [event.target.name]: event.target.value, page: 1 } });
        } else {
            setFilter({ ...filter, ...{ [event.target.name]: undefined, page: 1 } });
        }
        // setPagination({ ...pagination, page: 1 });
        setMeta({ ...meta, page: -1 });
    }
    useEffect(() => {
        getCustomers({ limit: 1000 }).then((res) => setCustomers(res.data.docs));
    }, []);
    const debouncedPagination = useDebouncedValue(pagination, 500);
    const debouncedFilter = useDebouncedValue(filter, 500);
    useEffect(() => {
        setIsLoading(true);
        const queryOptions = { ...debouncedPagination, ...debouncedFilter };
        getCredits(queryOptions)
            .then((result: any) => {
                setCreditList(result?.data?.docs);
                setMeta({
                    hasNextPage: result.data?.hasNextPage,
                    hasPrevPage: result.data?.hasPrevPage,
                    limit: result.data?.limit,
                    nextPage: result.data?.nextPage,
                    page: result.data?.page,
                    pagingCounter: result.data?.pagingCounter,
                    prevPage: result.data?.prevPage,
                    totalDocs: result.data?.totalDocs,
                    totalPages: result.data?.totalPages,
                });
                setIsLoading(false);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message ?? 'Sorry something went wrong');
            });
    }, [debouncedPagination, debouncedFilter]);
    return (
        <>
            <Modal dismissible show={ModalAddCredit} popup onClose={EditCustomerClose}>
                <Modal.Header>Apply for a credit</Modal.Header>
                <Modal.Body>
                    <AddCredit customers={customers} setRows={setCreditList} />
                </Modal.Body>
            </Modal>
            <div className="h-96">
                <section className="dark:bg-gray-900 flex items-center my-2">
                    <div className=" relative w-full">
                        <div className="relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
                            <div className="flex items-center p-2 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
                                <div className="w-72">
                                    <AutoComplete
                                        key="id"
                                        placeholder="Sellers"
                                        options={customers as []}
                                        getOptionLabel={(option: any) =>
                                            option?.lastName && option?.firstName
                                                ? `${option.firstName} ${option.lastName}`
                                                : option?.email
                                        }
                                        // eslint-disable-next-line no-underscore-dangle
                                        onChange={(option) => handleChangeFilterCustomer(option._id as string)}
                                    />
                                </div>
                                <div id="selectType">
                                    <Select
                                        id="creditType"
                                        name="creditType"
                                        required
                                        onChange={(e) => handleChange(e)}
                                    >
                                        <option value="" key="none">
                                            All
                                        </option>
                                        <option value="Ads" key="ads">
                                            credit for Ads
                                        </option>
                                        <option value="Cogs" key="cogs">
                                            credit for Cogs
                                        </option>
                                    </Select>
                                </div>
                                <div id="selectStatus">
                                    <Select id="status" required name="status" onChange={(e) => handleChange(e)}>
                                        <option value="" key="0">
                                            All Status
                                        </option>
                                        <option value="1" key="1">
                                            Pending
                                        </option>
                                        <option value="2" key="2">
                                            Confirmed
                                        </option>
                                        <option value="3" key="3">
                                            paid
                                        </option>
                                        <option value="4" key="4">
                                            Rejected
                                        </option>
                                    </Select>
                                </div>
                                <Datepicker
                                    inputId="deliveryDate"
                                    value={rangevalue}
                                    containerClassName="relative z-100"
                                    useRange
                                    separator="-"
                                    onChange={handleRangeFilterChange}
                                />
                                <Button
                                    // variant="primary"
                                    pill
                                    onClick={() => {
                                        setModalAddCredit(true);
                                    }}
                                >
                                    <FaClipboardList className="mr-2 h-5 w-5" />
                                    Apply for a credit
                                </Button>
                            </div>
                        </div>
                    </div>
                </section>
                <div>
                    <div className="flex bg-white shadow-md dark:bg-gray-800 sm:rounded-lg w-full mb-2 py-4 pr-2">
                        <div className="flex pl-5">
                            <div className="grid grid-cols-7 justify-start gap-4">
                                <div className="w-48 text-sm">Seller</div>
                                <div className="w-32 text-sm">Credit Type</div>
                                <div className="w-32 text-sm">Credit Amount</div>
                                <div className="w-32 text-sm">Status</div>
                                <div className="w-32 text-sm">Ads percent</div>
                                <div className="w-32 text-sm">Product</div>
                                <div className="text-sm">Cogs Unit Price</div>
                            </div>
                        </div>
                        <div className="text-sm w-16" />
                        <div className="text-sm w-16">Actions</div>
                    </div>
                    {/* {stockedProducts &&
                        stockedProducts.length > 0 &&
                        stockedProducts.map((productStock) => {
                            return <StockTable stockData={productStock} />;
                        })} */}
                    {creditList.map((credit) => {
                        return (
                            <div className="flex bg-white shadow-md dark:bg-gray-800 sm:rounded-lg w-full mb-2 pr-2">
                                <div>
                                    <Accordion collapseAll alwaysOpen flush>
                                        <Accordion.Panel>
                                            <div className="w-full">
                                                <Accordion.Title>
                                                    <div className="grid grid-cols-7 justify-start gap-4 w-full">
                                                        <div className="w-48 text-sm">
                                                            {`${credit?.customer?.firstName} ${credit?.customer?.lastName}`}
                                                        </div>
                                                        <div className="w-32 text-sm">{`${credit?.creditType}`}</div>
                                                        <div className="w-32 text-sm">{`${credit?.creditAmount}`}</div>
                                                        <div className="w-32 text-sm">
                                                            <div>{statusDisplay[credit?.status]?.statusIcon}</div>
                                                        </div>
                                                        <div className="w-32 text-sm">
                                                            {`${credit?.adsPercent || '#'}`}
                                                        </div>
                                                        <div className="w-32 text-sm">{`${
                                                            credit?.product?.name || '#'
                                                        }`}</div>
                                                        <div className="text-sm">{`${
                                                            credit?.cogsUnitPrice || '#'
                                                        }`}</div>
                                                    </div>
                                                </Accordion.Title>
                                                <Accordion.Content>
                                                    <CreditHistory credit={credit} />
                                                </Accordion.Content>
                                            </div>
                                        </Accordion.Panel>
                                    </Accordion>
                                </div>
                                <div className="w-16 py-6">Actions</div>
                            </div>
                        );
                    })}
                </div>
                <Pagination
                    currentPage={currentPage}
                    onPageChange={onPageChange}
                    showIcons
                    totalPages={meta.totalPages ?? 1}
                    theme={{
                        pages: {
                            selector: {
                                active: 'dark:!bg-gray-700 dark:!text-white',
                            },
                        },
                    }}
                />
                <div className="flex items-center justify-end gap-3 my-2">
                    <div className="mt-2">
                        <p>Count :</p>
                    </div>
                    <div className="mt-2">
                        <Select
                            sizing="md"
                            id="pageLimit"
                            onChange={(e) => {
                                handlePageLimit(Number(e.target.value));
                            }}
                        >
                            <option key={10} value={10}>
                                10
                            </option>
                            <option key={20} value={20}>
                                20
                            </option>
                            <option key={50} value={50}>
                                50
                            </option>
                            <option key={100} value={100}>
                                100
                            </option>
                            <option key={500} value={500}>
                                500
                            </option>
                        </Select>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Credits;
