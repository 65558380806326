import axios from 'utils/axios';
import { getBearerToken } from 'utils/helpers/auth';

export const addCallAgent = async (data: any) => {
    const newCallAgent = await axios.post(`/callAgentAdmin/callagents`, data, getBearerToken());
    return newCallAgent;
};

export const getAllCallAgents = async (filter: any) => {
    const callAgent = await axios.post(`/callAgentAdmin/callagents/filter`, filter, getBearerToken());
    return callAgent;
};

export const getCallAgent = async (id: any) => {
    const callAgent = await axios.get(`/callAgentAdmin/callagents/${id}`, getBearerToken());
    return callAgent;
};

export const updateCallAgent = async (id: any, data: any) => {
    const callAgentUpdate = await axios.put(`/callAgentAdmin/callagents/${id}`, data, getBearerToken());
    return callAgentUpdate;
};
