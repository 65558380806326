import { getConfiguration } from 'services/admin/configuration';
import { countries } from './countryList';

export const currencyConverter = (price: any, currency: string) => {
    let a = 'en-US';
    let b = 'USD';

    if (currency && (currency === 'EUR' || currency === '€' || currency === 'EURO')) {
        a = 'en-DE';
        b = 'EUR';
    }

    const currencyStyle = Intl.NumberFormat(a, {
        style: 'currency',
        currency: b,
        useGrouping: true,
    });

    return currencyStyle.format(price);
};

// convert currency t USD
export async function getLoadCurrencyConverter() {
    const configuration = (await getConfiguration()).data;
    const localCurrencyConverter = (from: string, amount: number) => {
        const country = countries.find((element) => element.currency === from);
        const currencyValue = configuration?.pricing?.codWithCC.find((el) => el.country === country?.label);
        const conversionValue = Number(amount) / Number(currencyValue);
        // if(configura)
        return conversionValue;
    };
    return localCurrencyConverter;
}

// export async function localCurrencyConverter(from: string, amount: number): Promise<number> {
//     const configuration = (await getConfiguration()).data;
//     const country = countries.find((element) => element.currency === from);
//     const currencyValue = configuration?.pricing.find((el) => el.country === country?.label);
//     const conversionValue = Number(amount) / Number(currencyValue);
//     // if(configura)
//     return conversionValue;
// }
