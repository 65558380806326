/* eslint-disable no-nested-ternary */

export const ButtonStyle = (sizeType: string, styleType: string) => {
    return {
        backgroundColor:
            styleType === 'primary'
                ? 'primary.main'
                : styleType === 'secondary'
                  ? 'secondary.main'
                  : 'secondaryBackground',
        color:
            styleType === 'primary'
                ? 'primaryBackground'
                : styleType === 'secondary'
                  ? 'textColor.quaternary'
                  : 'textColor.secondary',
        width: sizeType === 'small' ? '10px' : sizeType === 'medium' ? '200px' : '100%',

        '&:hover': {
            backgroundColor:
                styleType === 'primary'
                    ? 'primary.dark'
                    : styleType === 'secondary'
                      ? 'secondary.dark'
                      : 'tertiaryBackground',
            color:
                styleType === 'primary'
                    ? 'primaryBackground'
                    : styleType === 'secondary'
                      ? 'textColor.quaternary'
                      : 'textColor.secondary',
        },
        paddingRight: '20px',
        paddingLeft: '20px',
    };
};

export const Qsz = (param: string) => {
    switch (param) {
        case 'primary':
            return '!bg-primary text-white hover:!bg-primary-dark';
        case 'secondary':
            return ' !bg-transparent border-2 border-primary text-primary hover:!bg-primary-dark hover:text-white';
        case 'tertiary':
            return '!bg-transparent underline text-gray-500 hover:!bg-tertiaryBgBtn';
        default:
            return '';
    }
};

export const Qxz = (param: string) => {
    switch (param) {
        case 'secondary':
            return ' !bg-transparent border-2 !border-primary !text-primary hover:!bg-primary-dark hover:!text-white';
        case 'warning':
            return '!bg-transparent border-2 !border-amber-500 !text-amber-500 hover:!bg-amber-500 hover:!text-white';
        case 'failure':
            return '!bg-transparent border-2 !border-red-500 !text-red-500 hover:!bg-red-500 hover:!text-white';
        case 'success':
            return '!bg-transparent border-2 !border-green-500 !text-green-500 hover:!bg-green-500 hover:!text-white';
        default:
            return '';
    }
};

export const Qzz = (param: string) => {
    switch (param) {
        case 'secondary':
            return ' !text-primary';
        case 'warning':
            return '!text-amber-500 ';
        case 'failure':
            return '!text-red-500  ';
        case 'success':
            return '!text-green-500 ';
        default:
            return '';
    }
};
