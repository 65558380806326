/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import ReactApexCharts from 'react-apexcharts';
import { adminDahboardCallCenter } from 'services/admin/dashboard';
import Loader from 'components/tables/loader';
import { ApexOptions } from 'apexcharts';
import { ColorModeContext } from 'utils/contexts/theme';
import { calculatePercent, chartsType, getChartsData } from '../utils';
import { dashboardProps } from '../dashboard.types';

const CallCenterReport: React.FC<dashboardProps> = ({ filter }) => {
    const [dashboardData, setDashboardData] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const isDarkMode = useContext(ColorModeContext);

    useEffect(() => {
        if (filter?.callCenter || filter?.countries?.length || filter?.dateRange) {
            setIsLoading(true);
            adminDahboardCallCenter({
                ...filter,
                countries: filter?.countries?.filter((elt: any) => elt !== 'select_all'),
            })
                .then((res) => {
                    setDashboardData(res.data);
                    setIsLoading(false);
                })
                .catch(() => {
                    toast.error('Something went wrong');
                });
        }
    }, [filter]);

    return (
        <>
            <div className="w-full p-4">
                {!isLoading ? (
                    <div className="flex flex-col w-full p-2 gap-4">
                        <div className="flex gap-4">
                            <div className="w-full">
                                <section className="shadow-sx bg-white dark:bg-gray-800  w-full p-1">
                                    <div className="p-2 text-m font-normal text-gray-700 dark:text-gray-500">
                                        <h5> Call center status</h5>
                                    </div>
                                    <div className="p-2 text-m font-normal text-gray-500 dark:text-gray-300">
                                        Total orders
                                    </div>
                                    <div className="px-2 text-l font-bold text-gray-700 dark:text-gray-300">
                                        {dashboardData?.totalOrdersCount || 0}
                                    </div>
                                    <div className="w-full" id="donutChart">
                                        <ReactApexCharts
                                            key="donutChart"
                                            options={
                                                getChartsData(dashboardData, chartsType.donut)?.options as ApexOptions
                                            }
                                            series={getChartsData(dashboardData, chartsType.donut)?.series}
                                            type="donut"
                                            height={329}
                                        />
                                    </div>
                                </section>
                            </div>
                            <div className="w-full">
                                <section className=" shadow-sx bg-white dark:bg-gray-800  w-full p-1">
                                    <div className="p-2 text-m font-normal text-gray-700 dark:text-gray-500">
                                        <h5>Track your confirmation rate</h5>
                                    </div>
                                    <div className="p-2 text-m font-normal text-gray-500 dark:text-gray-300">
                                        Average confirmation rate
                                    </div>
                                    <div className="px-2 text-l font-bold text-gray-700 dark:text-gray-300">
                                        {(
                                            calculatePercent(
                                                dashboardData?.ordersStatusCount?.find(
                                                    (elt: any) => elt?.status === 'Confirmed',
                                                )?.count,
                                                dashboardData?.totalOrdersCount,
                                            ) || 0
                                        ).toFixed(2)}
                                        %
                                    </div>
                                    <div className="w-full" id="barChartDays">
                                        <ReactApexCharts
                                            options={
                                                getChartsData(dashboardData, chartsType.pieByDays, isDarkMode.mode)
                                                    ?.options as ApexOptions
                                            }
                                            series={getChartsData(dashboardData, chartsType.pieByDays)?.series}
                                            type="bar"
                                            height={280}
                                        />
                                    </div>
                                </section>
                            </div>
                        </div>

                        <div className="flex w-full p-1">
                            <section className=" shadow-sx bg-white dark:bg-gray-800  w-full p-1">
                                <div className="p-2 text-m font-normal text-gray-700 dark:text-gray-500">
                                    <h5>Orders status</h5>
                                </div>
                                <div className="p-2 text-m font-normal text-gray-500 dark:text-gray-300">
                                    Total orders
                                </div>
                                <div className="px-2 text-l font-bold text-gray-700 dark:text-gray-300">
                                    {dashboardData?.totalOrdersCount || 0}
                                </div>

                                <div className="w-full" id="barChartCountries">
                                    <ReactApexCharts
                                        options={
                                            getChartsData(dashboardData, chartsType.pieByCountry, isDarkMode.mode)
                                                ?.options as ApexOptions
                                        }
                                        series={getChartsData(dashboardData, chartsType.pieByCountry)?.series}
                                        type="bar"
                                        height={300}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                ) : (
                    <Loader />
                )}
            </div>
        </>
    );
};

export default CallCenterReport;
