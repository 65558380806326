/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Sidebar } from 'flowbite-react';
import routes from 'routes';
import toast from 'react-hot-toast';
import { getPendingInvoicesCount } from 'services/admin/invoice';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getPendingPurchases } from 'services/admin/purchases';
import { ReactComponent as Lgst } from '../../assets/LogistioLogo.svg';
import { ReactComponent as LgstSmall } from '../../assets/LogistioLogocollapsed.svg';
import { getPendingAnswerOrdersCount } from '../../services/admin/order';

const AdminSideBar: React.FC<{ collapsed: boolean }> = ({ collapsed }) => {
    const { pathname } = useLocation();
    const [pendingAnswerCount, setPendingAnswerCount] = useState(0);
    const [pendingInvoicesCount, setPendingInvoicesCount] = useState(0);
    const [pendingPurchasesCount, setPendingPurchasesCount] = useState(0);
    const [openCollapse, setOpenCollapse] = useState<number | null>(null);
    const fetchData = async () => {
        try {
            const [pendingAnswerRes, pendingInvoicesRes, pendingPurchases] = await Promise.all([
                getPendingAnswerOrdersCount(),
                getPendingInvoicesCount(),
                getPendingPurchases(),
            ]);

            if (pendingAnswerRes.data !== pendingAnswerCount) {
                setPendingAnswerCount(pendingAnswerRes.data);
            }

            if (pendingInvoicesRes.data !== pendingInvoicesCount) {
                setPendingInvoicesCount(pendingInvoicesRes.data);
            }
            if (pendingPurchases.data !== pendingPurchasesCount) {
                setPendingPurchasesCount(pendingPurchases.data);
            }
        } catch (err: any) {
            toast.error(err?.response?.data?.errors?.message || 'Error fetching data');
        }
    };
    useEffect(() => {
        fetchData();
        const interval = setInterval(fetchData, 1000 * 60);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const handleCollapseToggle = (index: number) => {
        setOpenCollapse(openCollapse === index ? null : index);
    };

    return (
        <>
            <Sidebar
                aria-label="Sidebar with multi-level dropdown example"
                collapsed={collapsed}
                theme={{
                    root: {
                        // base: '!bg-red-400 ',
                        collapsed: {
                            off: '!w-56',
                            on: '!w-16',
                        },
                    },
                }}
            >
                <Link to="/admin/">
                    <div className="flex justify-center items-center mb-2">
                        {!collapsed ? (
                            <Lgst fill="var(--logoColor)" stroke="var(--logoColor)" height={45} />
                        ) : (
                            <LgstSmall fill="var(--logoColor)" stroke="var(--logoColor)" height={35} />
                        )}
                    </div>
                </Link>
                <Sidebar.Items>
                    <Sidebar.ItemGroup>
                        {routes
                            .filter((prop) => prop.type === 'adminFeatures' || prop.type === 'adminParameters')
                            .map((prop, index: number) => {
                                const path = `${prop.layout}/${prop?.path}`;

                                if (prop.children) {
                                    return (
                                        <div key={index}>
                                            <div
                                                onClick={() => handleCollapseToggle(index)}
                                                role="button"
                                                tabIndex={0}
                                                key={index}
                                                className={`flex items-center text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 py-1 ${
                                                    collapsed ? 'justify-center' : ''
                                                }`}
                                            >
                                                {openCollapse === index && !collapsed && (
                                                    <div className="h-3 w-0.5 dark:bg-gray-200 bg-gray-400 mr-2" />
                                                )}
                                                <div className="flex p-2">
                                                    <prop.icon
                                                        className={`w-6 h-6 text-gray-500 transition duration-75  group-hover:text-gray-900 dark:group-hover:text-white ${
                                                            path === pathname ? 'dark:text-white' : 'dark:text-gray-400'
                                                        }`}
                                                    />
                                                    {!collapsed && <p className="ml-3">{prop.name}</p>}
                                                </div>
                                                {!collapsed && (
                                                    <div className="ml-auto">
                                                        {openCollapse === index ? (
                                                            <KeyboardArrowUpIcon />
                                                        ) : (
                                                            <KeyboardArrowDownIcon />
                                                        )}
                                                    </div>
                                                )}
                                            </div>

                                            {!collapsed && openCollapse === index && prop.children && (
                                                <div className="flex flex-col py-1">
                                                    {prop.children.map((child, childIndex) => (
                                                        <Link key={childIndex} to={`${child.layout}/${child.path}`}>
                                                            <div
                                                                className={`flex items-center text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 py-3 px-2 ${
                                                                    `${child.layout}/${child.path}` === pathname
                                                                        ? 'dark:bg-gray-700 bg-gray-100'
                                                                        : ''
                                                                } ${collapsed ? 'justify-center' : ''}`}
                                                            >
                                                                <p className="ml-3 px-3">{child.name}</p>
                                                            </div>
                                                        </Link>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    );
                                }
                                return (
                                    <Link key={index} to={`${prop.layout}/${prop?.path}`}>
                                        <div
                                            key={index}
                                            className={`flex items-center text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 ${
                                                path === pathname ? 'dark:bg-gray-700 bg-gray-100' : ''
                                            } py-1 ${collapsed ? 'justify-center' : ''}`}
                                        >
                                            {path === pathname && !collapsed && (
                                                <div className="h-3 w-0.5 dark:bg-gray-200 bg-gray-400 mr-2" />
                                            )}
                                            <div className="flex p-2">
                                                {/* @ts-expect-error */}
                                                <prop.icon
                                                    className={`w-6 h-6 text-gray-500 transition duration-75  group-hover:text-gray-900 dark:group-hover:text-white ${
                                                        path === pathname ? 'dark:text-white' : 'dark:text-gray-400'
                                                    }`}
                                                />
                                                {!collapsed && <p className="ml-3">{prop.name}</p>}
                                            </div>
                                            {prop.path.toLowerCase() === 'orders' && pendingAnswerCount > 0 && (
                                                <div className="ml-auto">
                                                    <p
                                                        className="w-5 h-5 bg-red-600 text-white text-xs flex items-center justify-center rounded-full"
                                                        title={`You have ${
                                                            pendingAnswerCount || 0
                                                        } unseen pending answers`}
                                                    >
                                                        {pendingAnswerCount}
                                                    </p>
                                                </div>
                                            )}
                                            {prop.path.toLowerCase() === 'purchases' && pendingPurchasesCount > 0 && (
                                                <div className="ml-auto">
                                                    <p
                                                        className="w-5 h-5 bg-red-600 text-white text-xs flex items-center justify-center rounded-full"
                                                        title={`You have ${
                                                            pendingPurchasesCount || 0
                                                        } unseen Waiting for treatement purchases`}
                                                    >
                                                        {pendingPurchasesCount}
                                                    </p>
                                                </div>
                                            )}

                                            {prop.path.toLowerCase() === 'invoices' && pendingInvoicesCount > 0 && (
                                                <div className="ml-auto">
                                                    <p
                                                        className=" bg-red-600 text-white text-xs flex items-center justify-center rounded-full w-8 h-6"
                                                        title={`You have ${
                                                            pendingInvoicesCount || 0
                                                        } unseen pending invoices`}
                                                    >
                                                        {pendingInvoicesCount}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </Link>
                                );
                            })}
                    </Sidebar.ItemGroup>
                </Sidebar.Items>
            </Sidebar>
        </>
    );
};

export default AdminSideBar;
