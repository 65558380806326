/* eslint-disable react/jsx-fragments */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import CustomError from 'components/customError/CustomError';
import toast from 'react-hot-toast';
import { Form, Formik } from 'formik';
import PhoneInput from 'react-phone-number-input';
import React, { useState } from 'react';
import { Label, TextInput } from 'flowbite-react';
import CustomBtn from 'components/customButton/customBtn';
import { changeUserInfos } from 'utils/validations/personalInformationValidation';
import { getAllProductProviders, updateProductProviders } from 'services/admin/productProviders';
import { HiMail } from 'react-icons/hi';
import { ISourcingAgentActions } from '../productProvider.types';
import SourcingAgentPassword from './sourcingAgentPassword';
import '../../../utils/helpers/phoneInput.css';

const SourcingAgentPersonalInfo: React.FC<ISourcingAgentActions> = ({ sourcingAgent, setRows, currentPage }) => {
    const [isLoading, setIsLoading] = useState(false);

    const personalInfo = (values: any) => {
        setIsLoading(true);
        updateProductProviders(sourcingAgent._id, values)
            .then((res) => {
                toast.success(res.data.message);
                setIsLoading(false);
            })
            .then(() => {
                getAllProductProviders({ page: currentPage }).then((res) => {
                    setRows(res.data.docs);
                });
            })
            .catch((err) => {
                setIsLoading(false);
                toast.error(err.response.data.errors.message);
            });
    };

    return (
        <>
            <Formik
                initialValues={{
                    firstName: sourcingAgent.firstName,
                    lastName: sourcingAgent.lastName,
                    phone: sourcingAgent.phone,
                    email: sourcingAgent.email,
                }}
                onSubmit={(values) => {
                    personalInfo(values);
                }}
                validationSchema={changeUserInfos}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit} placeholder="">
                        <div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="username4"
                                        className="dark:text-white"
                                        color={formik.errors.firstName ? 'failure' : ''}
                                        value="First name"
                                    />
                                </div>
                                <TextInput
                                    {...formik.getFieldProps('firstName')}
                                    name="firstName"
                                    color={formik.errors.firstName ? 'failure' : 'gray'}
                                    type="text"
                                    helperText={
                                        <React.Fragment>
                                            <CustomError name="firstName" component="div" />
                                        </React.Fragment>
                                    }
                                />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="username4"
                                        className="dark:text-white"
                                        color={formik.errors.lastName ? 'failure' : ''}
                                        value="Last Name"
                                    />
                                </div>
                                <TextInput
                                    {...formik.getFieldProps('lastName')}
                                    name="lastName"
                                    color={formik.errors.lastName ? 'failure' : 'gray'}
                                    type="text"
                                    helperText={
                                        <React.Fragment>
                                            <CustomError name="lastName" component="div" />
                                        </React.Fragment>
                                    }
                                />
                            </div>
                        </div>

                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="username4"
                                    className="dark:text-white"
                                    color={formik.errors.phone ? 'failure' : ''}
                                    value="Phone Number"
                                />
                            </div>

                            <PhoneInput
                                id="phoneNumber"
                                name="phone"
                                value={String(formik.values.phone)}
                                onChange={(value) => formik.setFieldValue('phone', value)}
                                onBlur={formik.handleBlur('phone')}
                                placeholder="Enter phone number"
                                className="mb-1"
                            />
                            <CustomError name="phone" component="div" />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="username4"
                                    className="dark:text-white"
                                    color={formik.errors.email ? 'failure' : ''}
                                    value="Email"
                                />
                            </div>
                            <TextInput
                                {...formik.getFieldProps('email')}
                                name="email"
                                color={formik.errors.email ? 'failure' : 'gray'}
                                icon={HiMail}
                                type="email"
                                helperText={
                                    <React.Fragment>
                                        <CustomError name="email" component="div" />
                                    </React.Fragment>
                                }
                            />
                        </div>

                        <div className="flex justify-end">
                            <CustomBtn type="submit" variant="primary" isProcessing={isLoading} disabled={isLoading}>
                                Save Changes
                            </CustomBtn>
                        </div>
                    </Form>
                )}
            </Formik>
            <SourcingAgentPassword id={sourcingAgent._id} />
        </>
    );
};

export default SourcingAgentPersonalInfo;
