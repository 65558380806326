import toast from 'react-hot-toast';

function getFileExtension(url: any) {
    const parts = url.split('.');
    return parts[parts.length - 1];
}

export function downloadFilesAsBase64(fileUrls: string[], productName: string) {
    const fetchPromises: Promise<any>[] = [];
    const links: HTMLAnchorElement[] = [];

    fileUrls.forEach((url: string, index: number) => {
        const filename = `${productName}_file_${index + 1}`;

        const fetchPromise = fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                return new Promise<string>((resolve) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        resolve(reader.result as string);
                    };
                    reader.readAsDataURL(blob);
                });
            })
            .then((base64Data: string) => {
                const link = document.createElement('a');

                link.setAttribute('download', `${filename}.${getFileExtension(url)}`);

                link.setAttribute('href', base64Data);
                links.push(link);
            })
            .catch(() => {
                toast.error(`Error fetching file from ${url}`);
            });

        fetchPromises.push(fetchPromise);
    });

    Promise.all(fetchPromises).then(() => {
        links.forEach((link) => {
            link.click();
        });
    });
}

export function downloadSingleFileAsBase64(fileUrl: string, productName: string) {
    const fetchPromises: Promise<any>[] = [];
    const links: HTMLAnchorElement[] = [];

    const filename = `${productName}`;

    const fetchPromise = fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
            return new Promise<string>((resolve) => {
                const reader = new FileReader();
                reader.onload = () => {
                    resolve(reader.result as string);
                };
                reader.readAsDataURL(blob);
            });
        })
        .then((base64Data: string) => {
            const link = document.createElement('a');

            link.setAttribute('download', `${filename}`);

            link.setAttribute('href', base64Data);
            links.push(link);
        })
        .catch(() => {
            toast.error(`Error fetching file from ${fileUrl}`);
        });

    fetchPromises.push(fetchPromise);

    Promise.all(fetchPromises).then(() => {
        links.forEach((link) => {
            link.click();
        });
    });
}

export function openPdfInNewTab(fileUrl: string, productName: string) {
    const feedbackToast = toast.loading('loading file...');
    const fetchPromise = fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
            return new Promise<string>((resolve) => {
                const reader = new FileReader();
                reader.onload = () => {
                    resolve(reader.result as string);
                };
                reader.readAsDataURL(blob);
            });
        })
        .then((base64Data: string) => {
            // Creating a new HTML document with title and iframe
            const newDocument = document.implementation.createHTMLDocument();
            // naming the PDF file while opening it
            newDocument.title = `${productName} PDF`;
            newDocument.body.innerHTML = `<iframe width='100%' height='100%' frameBorder="0" style={{margin: 0; padding: 0; border: none;}} src='${base64Data}'></iframe>`;

            // Open the new document in a new window
            const newTab = window.open();
            if (newTab) {
                toast.success('File loaded successfully', { id: feedbackToast });
                newTab.document.write(newDocument.documentElement.outerHTML);
            } else {
                toast.error('Could not open new tab.', { id: feedbackToast });
            }
        })
        .catch(() => {
            toast.error(`Error fetching file from ${fileUrl}`, { id: feedbackToast });
        });

    return fetchPromise;
}
