export const sentMessage = {
    backgroundColor: 'primary.main',
    borderRadius: '22px 0 22px 22px',
    objectFit: 'fit-content',
    maxWidth: '300px',
    padding: '10px',
    color: 'white',
    wordWrap: 'break-word;',
};

export const receivedMessage = {
    backgroundColor: 'grayBackground',
    borderRadius: '0 22px 22px 22px',
    bjectFit: 'fit-content',
    maxWidth: '300px',
    padding: '10px',
    // color: 'white',
    wordWrap: 'break-word;',
};

export const MessageBox = (received: boolean) => {
    return {
        display: 'flex',
        gap: '5px',
        alignItems: 'flex-start',
        justifyContent: received ? 'flex-start' : 'flex-end',
    };
};
