import React from 'react';
import { Badge } from 'flowbite-react';
import { TableColDef, IStausInterface } from '../../../components/tables/tables.types';
import { Iinvoice } from '../invoice.types';
import PrintInvoice from './invoicePrint';
import VisaulisePrepaidInvoice from './visauliseInvoice';

export const columns: TableColDef[] = [
    {
        field: 'customer',
        headerName: 'Seller',
        classField: 'whitespace-nowrap font-medium text-gray-900 dark:text-white',
        fieldType: 'combo',
        child: ['firstName', 'lastName'],
    },
    {
        field: 'store',
        headerName: 'Store',
        fieldType: 'combo',
        child: ['storeName'],
    },
    {
        field: 'createdAt',
        headerName: 'Date',
        fieldType: 'date',
    },
    {
        field: 'generationTiming',
        headerName: 'Timing',
    },
    {
        field: 'status',
        headerName: 'Status',
        fieldType: 'status',
    },
    {
        field: 'Visaulise',
        headerName: 'View',
        fieldType: 'render',
        renderCell: (params: Iinvoice) => (
            <>
                <VisaulisePrepaidInvoice invoice={params} />
            </>
        ),
    },
    {
        field: 'invoiceLink',
        headerName: 'Print',
        fieldType: 'render',
        renderCell: (params: Iinvoice) => (
            <>
                <PrintInvoice invoice={params} />
            </>
        ),
    },
];
export const statusDisplay: { [keys: string]: IStausInterface } = {
    1: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Treating
            </Badge>
        ),
        statusLabel: 'Treating',
    },
    2: {
        statusIcon: (
            <Badge color="success" size="sm">
                Paid
            </Badge>
        ),
        statusLabel: 'Paid',
    },
};
