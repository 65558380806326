export const variantsTableHeadWrapper = {
    display: 'grid',
    gridGap: '10px',
    gridTemplateColumns: '1fr 3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    alignItems: 'center',
    border: '1px solid',
    borderRadius: '4px',
    borderColor: 'borderColor',
    margin: '20px 0px',
    padding: '20px 15px',
    color: 'textColor.secondary',
    fontWeight: '600',
    backgroundColor: 'tertiaryBackground',
};

export const variantsTableRows = {
    display: 'grid',
    gridGap: '10px',
    gridTemplateColumns: '1fr 3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    alignItems: 'center',
    border: '1px solid',
    borderRadius: '4px',
    borderColor: 'borderColor',
    margin: '10px 0px',
    padding: '0px 15px',
    color: 'textColor.secondary',
};

export const optionStyle = {
    fontWeight: '600',
};

export const variantKeyMode = {
    fontWeight: '500',
};

export const variantDeleteIcon = {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
};
