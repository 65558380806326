import { Badge, Tooltip } from 'flowbite-react';
import React from 'react';
import { getTotalQuantity } from 'utils/helpers/productsHelpers/getVariantInfo';
import { IStausInterface, TableColDef } from '../../components/tables/tables.types';

export const columns: TableColDef[] = [
    {
        field: 'createdAt',
        headerName: 'Created at',
        fieldType: 'date',
        dateFormat: 'DD/MM/YYYY',
    },
    {
        field: 'product',
        headerName: 'product Name',
        fieldType: 'combo',
        child: ['name'],
    },
    {
        field: 'status',
        headerName: 'Status',
        fieldType: 'status',
    },
    {
        field: 'options',
        headerName: 'Variant',
        fieldType: 'render',
        renderCell: (params) => {
            const variants =
                Object.keys(params?.product?.options || {}).filter(
                    (key: string) => params.product.options[key]?.length,
                ) || [];
            if (!variants.length) return <></>;
            return (
                <>
                    <div className="flex items-center gap-1">
                        <>
                            {variants.length > 2 ? (
                                <>
                                    <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
                                        {variants[0]}
                                    </span>

                                    <Tooltip content={variants.slice(1).join(' - ')}>
                                        <span className="bg-gray-100 cursor-pointer text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
                                            +{variants.length - 1}
                                        </span>
                                    </Tooltip>
                                </>
                            ) : (
                                <>
                                    {variants.map((el: string) => (
                                        <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
                                            {el}
                                        </span>
                                    ))}
                                </>
                            )}
                        </>
                    </div>
                </>
            );
        },
    },
    {
        field: 'quantity',
        headerName: 'Total Qty',
        fieldType: 'render',
        classField: 'w-[100px]',
        classHeader: 'text-center',
        renderCell: (params) => {
            const Total = getTotalQuantity(params?.product);
            return (
                <>
                    <div className="flex items-center  justify-around">{Total}</div>
                </>
            );
        },
    },
    {
        field: 'customer',
        headerName: 'Seller',
        fieldType: 'combo',
        child: ['firstName', 'lastName'],
    },
    {
        field: 'Action',
        actionType: 'Purchases',
        headerName: 'Update',
    },
];
export const statusDisplay: { [keys: string]: IStausInterface } = {
    1: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Waiting for treatement
            </Badge>
        ),
        statusLabel: 'Waiting for treatement',
    },
    2: {
        statusIcon: (
            <Badge color="success" size="sm">
                Purchased
            </Badge>
        ),
        statusLabel: 'Purchased',
    },
    3: {
        statusIcon: (
            <Badge color="red" size="sm">
                Canceled
            </Badge>
        ),
        statusLabel: 'Canceled',
    },
};
