import { IProduct } from 'utils/Interfaces/products.interface';

export const getDesignImages = (product: IProduct) => {
    const designImages = product.design.filter(
        (prodImg: any) =>
            prodImg.endsWith('.jpg') ||
            prodImg.endsWith('.png') ||
            prodImg.endsWith('.jpeg') ||
            prodImg.endsWith('.webp'),
    );
    return designImages;
};
