import React from 'react';
import { IStausInterface } from 'components/tables/tables.types';
import { Badge } from 'flowbite-react';

export const statusDisplay: { [keys: string]: IStausInterface } = {
    1: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Pending
            </Badge>
        ),
        statusLabel: 'Pending',
    },
    2: {
        statusIcon: (
            <Badge color="success" size="sm">
                Confirmed
            </Badge>
        ),
        statusLabel: 'Confirmed',
    },
    3: {
        statusIcon: (
            <Badge color="info" size="sm">
                Paid
            </Badge>
        ),
        statusLabel: 'Paid',
    },
    4: {
        statusIcon: (
            <Badge color="failure" size="sm">
                Rejected
            </Badge>
        ),
        statusLabel: 'Rejected',
    },
};
