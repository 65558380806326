import React from 'react';
import { currencies } from 'utils/countryList';
import { ICustomerBankInformations } from '../customer.types';

const CustomerBankInformation: React.FC<{ bankInformation: ICustomerBankInformations }> = ({ bankInformation }) => {
    const {
        accountHolder,
        accountNumber,
        accountType,
        achRoutingNumber,
        bankingType,
        city,
        state,
        country,
        email,
        paymentService,
        postCode,
        receiverType,
        recepientAdress,
        swiftBicCode,
        transferMethod,
        ibanRoutingNumber,
        targetCurrency,
    } = bankInformation;
    const bankingMethodLabels = { BankAccount: 'Bank account', FinancialService: 'Financial service' };

    const fullTargetCurrency = currencies.filter((curr) => curr.code === targetCurrency);
    return (
        <>
            {bankingType ? (
                <>
                    {' '}
                    <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="min-w-0 flex-1">
                                    <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                        Selected method
                                    </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                    {bankingMethodLabels[bankingType] || '--'}
                                </div>
                            </div>
                        </li>
                    </ul>
                    {bankingType === 'BankAccount' && (
                        <>
                            <div className="grid grid-cols-2 gap-4 ">
                                <div className="bg-white p-2 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                    <div className="items-center justify-between mb-4">
                                        <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                                            <li className="py-3 sm:py-4">
                                                <div className="flex items-center space-x-4">
                                                    <div className="min-w-0 flex-1">
                                                        <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                            Transfer type
                                                        </p>
                                                    </div>
                                                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                        {transferMethod || '--'}
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="py-3 sm:py-4">
                                                <div className="flex items-center space-x-4">
                                                    <div className="min-w-0 flex-1">
                                                        <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                            Receiver type
                                                        </p>
                                                    </div>
                                                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                        {receiverType || '--'}
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="py-3 sm:py-4">
                                                <div className="flex items-center space-x-4">
                                                    <div className="min-w-0 flex-1">
                                                        <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                            Account holder
                                                        </p>
                                                    </div>
                                                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                        {accountHolder || '--'}
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="py-3 sm:py-4">
                                                <div className="flex items-center space-x-4">
                                                    <div className="min-w-0 flex-1">
                                                        <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                            Target currency
                                                        </p>
                                                    </div>
                                                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                        {`${fullTargetCurrency[0]?.name || '--'} (${
                                                            fullTargetCurrency[0]?.code || '--'
                                                        })`}
                                                    </div>
                                                </div>
                                            </li>

                                            {transferMethod === 'ACH' && (
                                                <>
                                                    <li className="py-3 sm:py-4">
                                                        <div className="flex items-center space-x-4">
                                                            <div className="min-w-0 flex-1">
                                                                <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                                    Account Number/IBAN:
                                                                </p>
                                                            </div>
                                                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                                {accountNumber || '--'}
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="py-3 sm:py-4">
                                                        <div className="flex items-center space-x-4">
                                                            <div className="min-w-0 flex-1">
                                                                <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                                    ACH routing number:
                                                                </p>
                                                            </div>
                                                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                                {achRoutingNumber || '--'}
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="py-3 sm:py-4">
                                                        <div className="flex items-center space-x-4">
                                                            <div className="min-w-0 flex-1">
                                                                <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                                    Account type:
                                                                </p>
                                                            </div>
                                                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                                {accountType || '--'}
                                                            </div>
                                                        </div>
                                                    </li>
                                                </>
                                            )}
                                            {transferMethod === 'SWIFT' && (
                                                <>
                                                    <li className="py-3 sm:py-4">
                                                        <div className="flex-col items-center">
                                                            <div className="min-w-0 ">
                                                                <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                                    Account Number/IBAN:
                                                                </p>
                                                            </div>
                                                            <div className="text-base font-semibold text-gray-900 dark:text-white">
                                                                {ibanRoutingNumber || '--'}
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="py-3 sm:py-4">
                                                        <div className="flex items-center space-x-4">
                                                            <div className="min-w-0 flex-1">
                                                                <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                                    SWIFT BIC code:
                                                                </p>
                                                            </div>
                                                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                                {swiftBicCode || '--'}
                                                            </div>
                                                        </div>
                                                    </li>
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                                <div className="bg-white p-2 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                    <div className="items-center justify-between mb-4">
                                        <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                                            <li className="pt-3 pb-4 sm:pt-4">
                                                <div className="flex items-center space-x-4">
                                                    <div className="min-w-0 flex-1">
                                                        <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                            Country :
                                                        </p>
                                                    </div>
                                                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                        {country || '--'}
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="pt-3 pb-4 sm:pt-4">
                                                <div className="flex items-center space-x-4">
                                                    <div className="min-w-0 flex-1">
                                                        <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                            City :
                                                        </p>
                                                    </div>
                                                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                        {city || '--'}
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="pt-3 pb-4 sm:pt-4">
                                                <div className="flex items-center space-x-4">
                                                    <div className="min-w-0 flex-1">
                                                        <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                            State :
                                                        </p>
                                                    </div>
                                                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                        {state || '--'}
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="pt-3 pb-4 sm:pt-4">
                                                <div className="flex items-center space-x-4">
                                                    <div className="min-w-0 flex-1">
                                                        <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                            Adress:
                                                        </p>
                                                    </div>
                                                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                        {recepientAdress || '--'}
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="pt-3 pb-0 sm:pt-4">
                                                <div className="flex items-center space-x-4">
                                                    <div className="min-w-0 flex-1">
                                                        <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                            Post code :
                                                        </p>
                                                    </div>
                                                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                        {postCode || '--'}
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {bankingType === 'FinancialService' && (
                        <>
                            <div className="bg-white p-2 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                <div className="items-center justify-between mb-4">
                                    <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                                        <li className="py-3 sm:py-4">
                                            <div className="flex items-center space-x-4">
                                                <div className="min-w-0 flex-1">
                                                    <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                        Service:
                                                    </p>
                                                </div>
                                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                    {paymentService || '--'}
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-3 sm:py-4">
                                            <div className="flex items-center space-x-4">
                                                <div className="min-w-0 flex-1">
                                                    <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                        Email:
                                                    </p>
                                                </div>
                                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                    {email || '--'}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </>
                    )}
                </>
            ) : (
                <>No configuration</>
            )}
        </>
    );
};

export default CustomerBankInformation;
