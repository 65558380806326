/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import AddAdminModal from 'pages/adminsList/addAdminModal/addAdminModal';
import { getAll } from 'services/admin/admin';
import CustomBtn from 'components/customButton/customBtn';
import UseDocumentTitle from 'utils/dynamicTitle/dynamicTitle';
import { HiUserAdd } from 'react-icons/hi';
import { AiOutlineSearch } from 'react-icons/ai';
import { Modal, Select, TextInput } from 'flowbite-react';
import useDebouncedValue from 'utils/hooks/useDebouncedValue';
import toast from 'react-hot-toast';
import DataTable from '../../components/tables/tables';
import { Iadmin } from './admin.types';
import { columns, statusDisplay } from './adminTableActions/adminsListElements';

interface Ipagination {
    page: number;
    limit: number;
}
interface Ifilter {
    status?: number;
    fullName?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
}
const adminsList = () => {
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [admins, setAdmins] = useState<Array<Iadmin>>([]);
    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 20,
    });
    const [filter, setFilter] = useState<Ifilter>({});
    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });
    interface Imeta {
        totalDocs: number;
        limit: number;
        totalPages: number;
        page: number;
        pagingCounter: number;
        hasPrevPage: boolean;
        hasNextPage: boolean;
        prevPage: number | null;
        nextPage: number | null;
    }

    const handlePaginationChange = (value: { [key: string]: any }) => {
        setPagination((prevPagination) => {
            const data = { ...prevPagination, ...value };
            if (value.limit) {
                data.page = 1;
            }
            return data;
        });
        setMeta((prev) => ({ ...prev, page: -1 }));
    };

    function handleChangeFilter(event: any) {
        if (event.target.value !== '') {
            setFilter({ ...filter, ...{ [event.target.name]: event.target.value } });
        } else {
            setFilter({ ...filter, ...{ [event.target.name]: undefined } });
        }
        setPagination((prevPagination) => ({ ...prevPagination, page: 1 }));
        setMeta({ ...meta, page: -1 });
    }

    const debouncedPagination = useDebouncedValue(pagination, 500);
    const debouncedFilter = useDebouncedValue(filter, 500);
    useEffect(() => {
        const feedbackToast = toast.loading('Loading admins list...');
        setIsLoading(true);
        const queryOptions = { ...debouncedPagination, ...debouncedFilter };
        getAll(queryOptions)
            .then((result) => {
                toast.success('Admins list loaded successfully', { id: feedbackToast });
                setAdmins(result.data.docs);
                setMeta({
                    hasNextPage: result.data.hasNextPage,
                    hasPrevPage: result.data.hasPrevPage,
                    limit: result.data.limit,
                    nextPage: result.data.nextPage,
                    page: result.data.page,
                    pagingCounter: result.data.pagingCounter,
                    prevPage: result.data.prevPage,
                    totalDocs: result.data.totalDocs,
                    totalPages: result.data.totalPages,
                });
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                toast.error((err as any)?.response?.data?.errors?.message || 'Unable to load data.', {
                    id: feedbackToast,
                });
            });
    }, [debouncedFilter, debouncedPagination]);

    UseDocumentTitle('Admins');

    return (
        <>
            <h3>Admins List</h3>

            <div className="flex justify-between mb-2 items-center">
                <div className="flex gap-2">
                    <TextInput
                        icon={AiOutlineSearch}
                        id="customerName"
                        placeholder="Search by admin"
                        name="fullName"
                        onChange={(e) => {
                            handleChangeFilter(e);
                        }}
                    />
                    <div id="select">
                        <Select
                            id="statusType"
                            name="status"
                            required
                            value={filter.status?.toString()}
                            onChange={(e) => {
                                handleChangeFilter(e);
                            }}
                        >
                            <option value="">All Status</option>
                            <option value="1">Active</option>
                            <option value="2">Disabled</option>
                        </Select>
                    </div>
                </div>
                <CustomBtn
                    variant="primary"
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    <HiUserAdd className="mr-2 h-5 w-5" />
                    Add Admin
                </CustomBtn>
                <Modal dismissible show={open} popup onClose={() => setOpen(false)}>
                    <Modal.Header>Add New Admin</Modal.Header>
                    <Modal.Body className="!p-0">
                        <AddAdminModal setOpen={setOpen} setAdmins={setAdmins} />
                    </Modal.Body>
                </Modal>
            </div>
            <DataTable
                rows={admins}
                columns={columns}
                icons={statusDisplay}
                setRows={setAdmins}
                pageState={{
                    isLoading,
                    total: meta.totalDocs,
                    pageSize: pagination.limit,
                    page: pagination.page,
                    count: meta.totalPages,
                }}
                filterState={filter}
                setPageState={handlePaginationChange}
            />
        </>
    );
};

export default adminsList;
