/* eslint-disable react/style-prop-object */
/* eslint-disable no-underscore-dangle */
import { Modal, Tabs } from 'flowbite-react';
import React, { useState } from 'react';
import { FaUserEdit } from 'react-icons/fa';
import StatusAccount from 'components/status/statusAccount';
import { ICallCenterActions } from '../callCenter.types';
import CallCenterManagment from '../updateCallCenter/callCenterManagment';
import CallCenterInfo from '../updateCallCenter/callCenterInfo';

const CallCenterActions = (props: ICallCenterActions) => {
    const [ModalEditCC, setModalEditCC] = useState(false);

    const EditCallCenter = () => {
        setModalEditCC(true);
    };
    const EditCCClose = () => {
        setModalEditCC(false);
    };

    const { callCenter, setRows, currentPage, currentFilter } = props;
    return (
        <>
            <div>
                <FaUserEdit className="h-5 w-5 cursor-pointer" onClick={EditCallCenter} />
            </div>
            <Modal dismissible show={ModalEditCC} popup onClose={EditCCClose}>
                <Modal.Header>
                    <StatusAccount type="others" label={callCenter.status} />
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        aria-label="Tabs with icons"
                        style="underline"
                        theme={{
                            tablist: {
                                tabitem: {
                                    base: 'flex items-center justify-center p-4 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 ',
                                },
                            },
                        }}
                    >
                        <Tabs.Item title="Account Management">
                            <CallCenterManagment
                                status={callCenter.status}
                                id={callCenter._id}
                                setRows={setRows}
                                currentPage={currentPage}
                                currentFilter={currentFilter}
                            />
                        </Tabs.Item>
                        <Tabs.Item title="Call Center Information">
                            <CallCenterInfo
                                callCenter={callCenter}
                                setRows={setRows}
                                currentPage={currentPage}
                                currentFilter={currentFilter}
                            />
                        </Tabs.Item>
                    </Tabs>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default CallCenterActions;
