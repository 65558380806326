/* eslint-disable react/jsx-fragments */
import CustomBtn from 'components/customButton/customBtn';
import CustomError from 'components/customError/CustomError';
import PasswordInput from 'components/inputField/passwordInput';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { updateCallCenterAdmin } from 'services/admin/callCenterAdmin';
import { changePasswordSchema } from 'utils/validations/accountInformationValidation';

const CallCenterAdminPassword: React.FC<{ id: string }> = ({ id }) => {
    const [isLoading, setIsLoading] = useState(false);

    const updatePassword = (values: any) => {
        setIsLoading(true);
        updateCallCenterAdmin(id, values)
            .then(() => {
                toast.success('Password updated');
                setIsLoading(false);
            })
            .catch((err) => {
                toast.error(err.response.data.errors.message);
                setIsLoading(false);
            });
    };

    return (
        <>
            <Formik
                initialValues={{
                    password: '',
                }}
                onSubmit={(values, onSubmitProps) => {
                    updatePassword(values);
                    onSubmitProps.setSubmitting(false);
                    onSubmitProps.resetForm();
                }}
                validationSchema={changePasswordSchema}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit} placeholder="">
                        <div>
                            <PasswordInput
                                {...formik.getFieldProps('password')}
                                name="password"
                                colorLabel={formik.errors.password ? 'failure' : ''}
                                helperText={<CustomError name="password" component="div" />}
                                label="New Password"
                            />
                            <div className="flex justify-end">
                                <CustomBtn
                                    type="submit"
                                    variant="primary"
                                    isProcessing={isLoading}
                                    disabled={isLoading}
                                >
                                    Save New Password
                                </CustomBtn>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default CallCenterAdminPassword;
