import { ResellerProduct } from 'pages/reseller/reseller.types';
import { IPaginationData } from 'utils/Interfaces/types';
import axios from 'utils/axios';
import { getBearerToken } from 'utils/helpers/auth';

export type CommunicationMethod = 'email' | 'whatsapp';
export interface IResellerStock {
    _id: string;
    resellerProduct: string | ResellerProduct;
    quantity: number;
    lgQuantity: number;
    availableQuantity: number;
    packagedQuantity?: number;
    outsideQuantity: number;
    deliveredQuantity: number;
    country: string;
    warehouse: string;
    trackingCode?: string;
    /* STOCK STATUS:
     ** 1 : Preparing stock : Stock created successfully on our database and we preparing it.
     ** 2 : Stock ready for pickup : The stock is packed from our side and ready for pickup.
     ** 3 : Stock being shipped : Vnlin took the stock and started shipping it.
     ** 4 : Stock ready : Vnlin shipped the stock to the required warehouse.
     */
    status: number;
    boxes?: {
        boxNumber: number;
        quantity: number;
    }[];
    quantityLimit?: number;
    notificationType?: [] | [CommunicationMethod] | [CommunicationMethod, CommunicationMethod];
}
export interface ResellerStockShipment {
    resellerProduct: string | ResellerProduct;
    resellerStock: string | IResellerStock;
    quantity: number;
    country: string;
    warehouse: string;
    trackingCode: string;
    /* STOCK STATUS:
     ** 1 : Preparing stock : Stock created successfully on our database and we preparing it.
     ** 2 : Stock ready for pickup : The stock is packed from our side and ready for pickup.
     ** 3 : Stock being shipped : Vnlin took the stock and started shipping it.
     ** 4 : Stock ready : Vnlin shipped the stock to the required warehouse.
     ** 5 : Out of stock : Available stock quantity is 0.
     */
    status: number;
    boxes?: {
        boxNumber: number;
        quantity: number;
    }[];
    createdAt: string;
}
export const getStocks = async (pagination: any) => {
    const config = getBearerToken();
    const response = await axios.post<IPaginationData<IResellerStock>>(`/reseller/stocks/filter`, pagination, config);

    return response;
};
export const updateDeliveryStock = async (data: any) => {
    const config = getBearerToken();
    const response = await axios.put(`/reseller/stocks/update/${data.id}`, data.updatedData, config);
    return response;
};
export const refillResellerStock = async (data: any) => {
    const config = getBearerToken();
    const response = await axios.post(`/reseller/stocks/refill`, data, config);
    return response;
};
export const getStockShipments = async (pagination: any) => {
    const config = getBearerToken();
    const response = await axios.post<IPaginationData<ResellerStockShipment>>(
        `/reseller/stocks/filter/shipments`,
        pagination,
        config,
    );

    return response;
};
