import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import { InputFieldProps } from './InputField.types';
import { LabelStyling, StyledTextField } from './InputField.styles';

interface State {
    password: string;
    showPassword: boolean;
}
const InputField: React.FC<InputFieldProps> = ({
    className,
    label,
    onBlur,
    InputType,
    placeholder,
    value,
    name,
    onChange,
    rows,
    // inputStyle,
    disabled,
    style,
}) => {
    const [values, setValues] = React.useState<State>({
        password: '',
        showPassword: InputType !== 'password',
    });
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    return (
        <Box sx={style}>
            <Typography variant="body2" sx={LabelStyling}>
                {label}
            </Typography>
            {InputType === 'textArea' ? (
                <TextField
                    sx={{ width: '100%' }}
                    id="outlined-multiline-static"
                    multiline
                    rows={rows}
                    disabled={disabled}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                />
            ) : (
                <OutlinedInput
                    // id="outlined-adornment-password"
                    sx={StyledTextField(disabled as boolean)}
                    value={value}
                    name={name}
                    type={values.showPassword ? 'text' : 'password'}
                    onChange={onChange}
                    onBlur={onBlur}
                    endAdornment={
                        // eslint-disable-next-line no-nested-ternary
                        InputType === 'password' ? (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ) : InputType === 'search' ? (
                            <InputAdornment position="end">
                                <IconButton aria-label="search" onMouseDown={handleMouseDownPassword} edge="end">
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ) : null
                    }
                    placeholder={placeholder}
                    className={className}
                    disabled={disabled}
                />
            )}
        </Box>
    );
};

export default InputField;
