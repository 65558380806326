/* eslint-disable no-underscore-dangle */
import { Form, Formik } from 'formik';
import CustomBtn from 'components/customButton/customBtn';
import MultiSelect from 'components/multiSelect/autoComplete';
import { toast } from 'react-hot-toast';
import React, { useState } from 'react';
import { getCustomers, updateCustomer } from 'services/admin/customer';
import { getAccountManagers } from 'services/admin/accountManager';
import { AssignAMProps } from './customer.types';

const AssignAccountManager: React.FC<AssignAMProps> = ({
    customer,
    accountManagers,
    setAssignedAccountManager,
    setRows,
    currentPage,
}) => {
    const [accountManagerId, setAccountManagerId] = useState([]);
    const customerId = customer._id;

    const updateProfile = (values: any) => {
        const updatedValues = {
            ...values,
            accountManager: values.accountManager.map((manager: any) => manager._id),
        };

        updateCustomer(customerId, updatedValues)
            .then(() =>
                getCustomers({ page: currentPage }).then((res) => {
                    const updatedDocs = res.data.docs.map(
                        // eslint-disable-next-line @typescript-eslint/no-shadow
                        (customer: { accountManager: any[]; accountManagerNames: string }) => {
                            const acctManagers = customer.accountManager || [];
                            const managerNames = acctManagers
                                .map((manager: { firstName: string; lastName?: string }) => {
                                    const lastNameInitial = manager.lastName ? manager.lastName.charAt(0) : '';
                                    return `${manager.firstName}${lastNameInitial}`;
                                })
                                .join(', ');
                            return { ...customer, accountManagerNames: managerNames };
                        },
                    );
                    setRows(updatedDocs);
                }),
            )
            .then(() => {
                toast.success('Manager Assigned Successfully');
                getAccountManagers(accountManagerId).then((res) => setAssignedAccountManager(res.data));
            })
            .catch(() => {
                toast.error('Something went wrong');
            });
    };

    return (
        <>
            <Formik
                initialValues={{
                    accountManager: customer.accountManager || [],
                }}
                onSubmit={(values, onSubmitProps) => {
                    updateProfile(values);
                    onSubmitProps.setSubmitting(false);
                    onSubmitProps.resetForm();
                }}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit} placeholder="">
                        <div>
                            <div className="p-1">
                                <div className="border-t my-2" />

                                <MultiSelect
                                    key="id"
                                    name="accountManager"
                                    placeholder="Assign new Account Manager"
                                    options={accountManagers}
                                    defaultValue={customer.accountManager ? customer.accountManager : []}
                                    getOptionLabel={(option: any) => `${option.firstName} ${option.lastName}`}
                                    onChange={(newValue: any) => {
                                        formik.setFieldValue('accountManager', newValue);
                                        setAccountManagerId(newValue.map((item: { _id: string }) => item._id));
                                    }}
                                    value={formik.values.accountManager}
                                />
                                <div className="mt-2 flex justify-end">
                                    <CustomBtn type="submit" variant="primary" className="w-32">
                                        Save
                                    </CustomBtn>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AssignAccountManager;
