/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Accordion, Card } from 'flowbite-react';
import { getCurrentUser } from 'utils/helpers/auth';
import { getCallAgentAdmin } from 'services/callAgentAdmin/callAgentAdminProfile';
import toast from 'react-hot-toast';
import Loader from 'components/tables/loader';
import { accordContent, accordTitle } from 'pages/configurationAdmin/configuration.styles';
import JobsConfiguration from './automationConfiguration/jobsConfiguration';
import OrderConfiguration from './automationConfiguration/orderConfiguration';
import { ICallCenterAdminConfig } from '../callCenterAdmin.types';

const ConfigurationCallCenterAdmin = () => {
    const [callCenters, setCallCenters] = useState<ICallCenterAdminConfig>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const user = getCurrentUser();
        setIsLoading(true);
        getCallAgentAdmin(user._id)
            .then((res) => {
                setCallCenters(res.data);
                setIsLoading(false);
            })
            .catch((e) => {
                setIsLoading(false);
                toast.error(e.response.data.errors.message);
            });
    }, []);

    return (
        <>
            <div className=" p-4 mb-2 gap-4 w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <div className="my-5">
                    <p className="dark:text-[#6874F5] text-2xl font-semibold font-poppin">Call Center Configuration</p>
                </div>
                {isLoading ? (
                    <Loader />
                ) : (
                    <Card
                        theme={{
                            root: {
                                base: 'flex rounded-lg border border-gray-200 bg-white  dark:border-gray-700 dark:bg-transparent',
                                children: 'flex h-full flex-col justify-center p-2 ',
                            },
                        }}
                    >
                        {callCenters?.callCenter?.length ? (
                            <Accordion collapseAll alwaysOpen flush>
                                {callCenters?.callCenter?.map((callCenter: any) =>
                                    callCenters?.callCenter?.length > 1 ? (
                                        <Accordion.Panel key={callCenter?._id}>
                                            <Accordion.Title as="h4" theme={accordTitle}>
                                                {callCenter?.name}
                                            </Accordion.Title>
                                            <Accordion.Content theme={accordContent}>
                                                <div className="my-2 mx-4 ">
                                                    <JobsConfiguration
                                                        key={`jobs_${callCenter?._id}`}
                                                        callCenterId={callCenter?._id}
                                                        jobsConfig={callCenter?.jobsConfig}
                                                    />
                                                    <OrderConfiguration
                                                        key={`order_${callCenter?._id}`}
                                                        callCenterId={callCenter?._id}
                                                        orderConfig={callCenter?.ordersConfig}
                                                    />
                                                </div>
                                            </Accordion.Content>
                                        </Accordion.Panel>
                                    ) : (
                                        <>
                                            <JobsConfiguration
                                                key={`jobs_${callCenter?._id}`}
                                                callCenterId={callCenter?._id}
                                                jobsConfig={callCenter?.jobsConfig}
                                            />
                                            <OrderConfiguration
                                                key={`order_${callCenter?._id}`}
                                                callCenterId={callCenter?._id}
                                                orderConfig={callCenter?.ordersConfig}
                                            />
                                        </>
                                    ),
                                )}
                            </Accordion>
                        ) : null}
                    </Card>
                )}
            </div>
        </>
    );
};

export default ConfigurationCallCenterAdmin;
