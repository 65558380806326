import moment from 'moment';

/**
 * Converts a given date and time to Coordinated Universal Time (UTC) based on the provided timezone.
 * @param {Object} options - The options object.
 * @param {string} options.date - The date in the format "YYYY-MM-DD".
 * @param {string} options.hour - The hour in 24-hour format (e.g., "00" to "23").
 * @param {string} options.minute - The minute (e.g., "00" to "59").
 * @param {string} options.tz - The timezone in the format "Continent/City" (e.g., "America/New_York").
 * @returns {string} The converted date and time in UTC format (ISO 8601 string).
 */
export function convertDateTimeToUTC({
    date,
    hour,
    minute,
    tz,
}: {
    date: `${number}-${number}-${number}`;
    hour: `${number}`;
    minute: `${number}`;
    tz: `${string}/${string}`;
}): string {
    const dateTime = moment.tz(`${date} ${hour}:${minute}`, tz);
    return dateTime.utc().format();
}
