/* eslint-disable no-underscore-dangle */
import CustomError from 'components/customError/CustomError';
import toast from 'react-hot-toast';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import '../../../utils/helpers/phoneInput.css';
import { Label, TextInput } from 'flowbite-react';
import CustomBtn from 'components/customButton/customBtn';
import { HiMail } from 'react-icons/hi';
import { changeUserInfos } from 'utils/validations/personalInformationValidation';
import { getAllCallCenterAdmins, updateCallCenterAdmin } from 'services/admin/callCenterAdmin';
import MultiSelect from 'components/multiSelect/autoComplete';
import { getAllCallCenters } from 'services/admin/callCenter';
import { ICallCenter } from 'pages/callCenterList/callCenter.types';
import { ICallCenterAdminActions } from '../callCenterAdmin.types';
import CallCenterAdminPassword from './callCenterAdminPassword';

const CallCenterAdminPersonalInfo: React.FC<ICallCenterAdminActions> = ({
    callCenterAdmin,
    setRows,
    currentPage,
    currentFilter,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [callCenters, setCallCenters] = useState<Array<ICallCenter>>([]);

    const personalInfo = (values: any) => {
        setIsLoading(true);
        updateCallCenterAdmin(callCenterAdmin._id, {
            ...values,
            callCenter: values.callCenter.map((center: ICallCenter) => center._id),
        })
            .then((res) => {
                toast.success(res.data.message);
                setIsLoading(false);
            })
            .then(() => {
                getAllCallCenterAdmins({ page: currentPage, ...currentFilter }).then((res) => {
                    setRows(res.data.docs);
                });
            })
            .catch((err) => {
                setIsLoading(false);
                toast.error(err.response.data.errors.message);
            });
    };

    useEffect(() => {
        getAllCallCenters({ limit: 1000, status: 1 }).then((res) => {
            setCallCenters(res.data.docs);
        });
    }, []);

    return (
        <>
            <Formik
                initialValues={{
                    firstName: callCenterAdmin.firstName,
                    lastName: callCenterAdmin.lastName,
                    phone: callCenterAdmin.phone,
                    email: callCenterAdmin.email,
                    callCenter: callCenterAdmin.callCenter || [],
                }}
                onSubmit={(values) => {
                    personalInfo(values);
                }}
                validationSchema={changeUserInfos}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit} placeholder="">
                        <div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="firstName"
                                        className="dark:text-white"
                                        color={formik.errors.firstName ? 'failure' : ''}
                                        value="First name"
                                    />
                                </div>
                                <TextInput
                                    {...formik.getFieldProps('firstName')}
                                    name="firstName"
                                    color={formik.errors.firstName ? 'failure' : 'gray'}
                                    type="text"
                                    helperText={
                                        <>
                                            <CustomError name="firstName" component="div" />
                                        </>
                                    }
                                />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="lastName"
                                        className="dark:text-white"
                                        color={formik.errors.lastName ? 'failure' : ''}
                                        value="Last Name"
                                    />
                                </div>
                                <TextInput
                                    {...formik.getFieldProps('lastName')}
                                    name="lastName"
                                    color={formik.errors.lastName ? 'failure' : 'gray'}
                                    type="text"
                                    helperText={
                                        <>
                                            <CustomError name="lastName" component="div" />
                                        </>
                                    }
                                />
                            </div>
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="phone"
                                    className="dark:text-white"
                                    color={formik.errors.phone ? 'failure' : ''}
                                    value="Phone Number"
                                />
                            </div>
                            <PhoneInput
                                id="phoneNumber"
                                name="phone"
                                value={String(formik.values.phone)}
                                onChange={(value) => formik.setFieldValue('phone', value)}
                                onBlur={formik.handleBlur('phone')}
                                placeholder="Enter phone number"
                                className="mb-1"
                            />
                            <CustomError name="phone" component="div" />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="email"
                                    className="dark:text-white"
                                    color={formik.errors.email ? 'failure' : ''}
                                    value="Email"
                                />
                            </div>
                            <TextInput
                                {...formik.getFieldProps('email')}
                                name="email"
                                color={formik.errors.email ? 'failure' : 'gray'}
                                icon={HiMail}
                                type="email"
                                helperText={
                                    <>
                                        <CustomError name="email" component="div" />
                                    </>
                                }
                            />
                        </div>
                        {callCenterAdmin.status !== 2 && (
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="callCenter"
                                        className="dark:text-white"
                                        value="Assign Admin to a Call Center"
                                    />
                                </div>
                                <MultiSelect
                                    key="id"
                                    name="callCenter"
                                    placeholder="Assign to a Call Center"
                                    options={callCenters}
                                    defaultValue={callCenterAdmin.callCenter as any}
                                    getOptionLabel={(option: any) => `${option.name}`}
                                    onChange={(newValue: any) => {
                                        formik.setFieldValue('callCenter', newValue);
                                    }}
                                    value={formik.values.callCenter}
                                />
                            </div>
                        )}
                        <div className="flex justify-end">
                            <CustomBtn type="submit" variant="primary" isProcessing={isLoading} disabled={isLoading}>
                                Save Changes
                            </CustomBtn>
                        </div>
                    </Form>
                )}
            </Formik>
            <CallCenterAdminPassword id={callCenterAdmin._id} />
        </>
    );
};

export default CallCenterAdminPersonalInfo;
