import * as Yup from 'yup';

export const addResellerSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    chineseName: Yup.string().required('Required'),
    weight: Yup.number().typeError('Field must be a number').required('Required'),
    width: Yup.number().typeError('Field must be a number').required('Required'),
    height: Yup.number().typeError('Field must be a number').required('Required'),
    length: Yup.number().typeError('Field must be a number').required('Required'),
    quantity: Yup.number()
        .integer('Must be an integer')
        .min(1, 'Minimum value is 1')
        .typeError('Field must be a number')
        .required('Required'),
    declarationAmount: Yup.number()
        // .min(0.001, 'Minimum value is 0.001')
        .typeError('Field must be a number')
        .required('Required'),
    // adCopies: Yup.array().of(Yup.string().required('Required')).min(1, 'Add at least one copy').required('Required'),
    resellerQuotation: Yup.array()

        .of(
            Yup.object().shape({
                country: Yup.string().typeError('Field must be a number').required('Required'),
                quantity: Yup.number()
                    .integer('Must be an integer')
                    .min(1, 'Minimum value is 1')
                    .typeError('Field must be a number')
                    .required('Required'),
                unitPurchasePrice: Yup.number()
                    // .min(0.01, 'Minimum value is 0.01')
                    .typeError('Field must be a number')
                    .required('Required'),
                sellingPrice: Yup.number()
                    // .min(0.01, 'Minimum value is 0.01')
                    .typeError('Field must be a number')
                    .required('Required'),
                totalShippingFee: Yup.number()
                    // .min(0.01, 'Minimum value is 0.01')
                    .typeError('Field must be a number')
                    .required('Required'),
                recommendedPrice: Yup.number()
                    // .min(0.01, 'Minimum value is 0.01')
                    .optional()
                    .typeError('Field must be a number'),
            }),
        )

        .test({
            message: `Total quantity in diffrent warehouses should be equal to initial quantity`,
            test: (rows, context) => {
                const total =
                    rows?.reduce((accumulator, currentValue) => accumulator + (currentValue?.quantity || 0), 0) || 0;

                return total === Number(context?.parent?.quantity);
            },
        })
        .required('Required'),
    categories: Yup.array().of(Yup.string()).min(1, 'Add at least one category').required('Required'),
    quantityLimit: Yup.number()
        .integer('Must be an integer')
        .min(1, 'Minimum value is 1')
        .typeError('Field must be a number')
        .required('Required'),
});
export const draftResellerSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
});
