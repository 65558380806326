import React, { useState, useEffect } from 'react';
import { Button, Label, TextInput } from 'flowbite-react';
import { IChat } from 'services/callAgent/chat';
import { TFilter } from './Conversations';
import Modal from '../../components/modal/Modal';

const getModalTitle = (chatType: IChat['type']) => {
    switch (chatType) {
        case 1:
            return 'Products chat filters';
        case 2:
            return 'Orders chat filters';
        case 3:
            return 'Support chat filters';
        default:
            return 'Chat filters';
    }
};

const FilterModal = ({
    filter,
    handleFilterUpdate,
    chatType,
    resetFilter,
}: {
    filter: TFilter;
    handleFilterUpdate: (data: Partial<TFilter>) => void;
    resetFilter: () => void;
    chatType: IChat['type'];
}) => {
    const [openModal, setOpenModal] = useState(false);
    const [tempFilters, setTempFilters] = useState<Partial<TFilter>>(filter);

    useEffect(() => {
        setTempFilters(filter);
    }, [filter]);

    const filterFactory = (data: Partial<TFilter>) => {
        setTempFilters((prev) => ({ ...prev, ...data }));
    };

    const modalTitle = getModalTitle(chatType);

    return (
        <>
            <Button
                onClick={() => {
                    setOpenModal(true);
                }}
                color="gray"
                className="w-full mt-1"
            >
                {/* <tab.icon /> */}
                <p className="pl-2 text-base">Filters</p>
            </Button>
            <Modal
                open={openModal}
                fullWidth
                setOpen={setOpenModal}
                body={
                    <div className="space-y-6 p-4">
                        <h3>{modalTitle}</h3>
                        {chatType === 1 && (
                            <>
                                <div>
                                    <div className="mb-2 block">
                                        <Label htmlFor="product" value="Product name" />
                                    </div>
                                    <TextInput
                                        id="product"
                                        value={tempFilters.product ?? ''}
                                        onChange={(e) => {
                                            filterFactory({ product: e.target.value });
                                        }}
                                    />
                                </div>
                                <div>
                                    <div className="mb-2 block">
                                        <Label htmlFor="globalSKU" value="Sku" />
                                    </div>
                                    <TextInput
                                        id="globalSKU"
                                        value={tempFilters.globalSKU ?? ''}
                                        onChange={(e) => {
                                            filterFactory({ globalSKU: e.target.value });
                                        }}
                                    />
                                </div>
                            </>
                        )}

                        {chatType === 2 && (
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="orderRef" value="Order ref." />
                                </div>
                                <TextInput
                                    id="orderRef"
                                    value={tempFilters.orderRef ?? ''}
                                    onChange={(e) => {
                                        filterFactory({ orderRef: e.target.value });
                                    }}
                                />
                            </div>
                        )}

                        {chatType === 3 && (
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="customer" value="Customer name" />
                                </div>
                                <TextInput
                                    id="customer"
                                    value={tempFilters.customer ?? ''}
                                    onChange={(e) => {
                                        filterFactory({ customer: e.target.value });
                                    }}
                                />
                            </div>
                        )}
                        <div className="flex items-center justify-center gap-2">
                            <Button
                                onClick={() => {
                                    handleFilterUpdate(tempFilters);
                                    setOpenModal(false);
                                }}
                            >
                                Apply
                            </Button>
                            <Button
                                color="gray"
                                onClick={() => {
                                    setTempFilters({});
                                    resetFilter();
                                    setOpenModal(false);
                                }}
                            >
                                Reset
                            </Button>
                        </div>
                    </div>
                }
            />
        </>
    );
};

export default FilterModal;
