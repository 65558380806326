/* eslint-disable no-underscore-dangle */
/* eslint-disable react/style-prop-object */
import { Dropdown, Modal, Tabs } from 'flowbite-react';
import { HiOutlineUserAdd, HiPencil, HiDotsVertical } from 'react-icons/hi';
import { GiWallet } from 'react-icons/gi';
import CustomerPersonalInfo from 'pages/customers/updateCustomer/customerPersonalInfo';
import StatusAccount from 'components/status/statusAccount';
import CustomerAccountManagment from 'pages/customers/updateCustomer/customerAccountManagment';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import AssignAccountManager from 'pages/customers/AssignAccountManager';
import { ICustomerActions } from 'pages/customers/customer.types';
import { getProducts } from 'services/admin/product';
import { getAccountManagers, getAllAccountManagers } from 'services/admin/accountManager';
import { IAccountManager } from 'pages/accountManagerList/accountManager.types';
import { getCurrentUser } from 'utils/helpers/auth';
import CustomerBankInformation from './customerBankInformation';

const CustomerActions: React.FC<ICustomerActions> = (props: ICustomerActions) => {
    const [ModalAssignManager, setModalAssignManager] = useState(false);
    const [ModalEditCustomer, setModalEditCustomer] = useState(false);
    const navigate = useNavigate();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_productList, setProductList] = useState<any>([]);
    const [accountManagers, setAccountManagers] = useState([]);
    const [assignedAccountManager, setAssignedAccountManager] = useState<IAccountManager>();

    const { customer, setRows, currentPage, currentFilter } = props;

    const EditCustomer = () => {
        setModalEditCustomer(true);
        getProducts({ customer: customer._id }).then((res) => setProductList(res.data.docs));
    };
    const EditCustomerClose = () => {
        setModalEditCustomer(false);
    };
    const assigneManager = () => {
        let ids: string[] = [];
        if (customer.accountManager && Array.isArray(customer.accountManager)) {
            ids = customer.accountManager.map((manager: any) => manager._id);
        }
        setModalAssignManager(true);
        getAccountManagers(ids).then((res) => setAssignedAccountManager(res.data));
        getAllAccountManagers({ limit: 1000 }).then((res) => setAccountManagers(res.data.docs));
    };
    const closeAssigneManager = () => {
        setModalAssignManager(false);
    };
    const user = getCurrentUser();
    let navigation = 'admin';
    if (user.role === 'accountManager') {
        navigation = 'accountManager';
    }
    const navigateWallet = async () => {
        navigate(`/${navigation}/customer/${customer._id}/${customer.wallet}`, {
            state: {
                walletId: customer.wallet,
                customerName: `${customer.firstName} ${customer.lastName}`,
                customerId: customer._id,
            },
        });
    };
    const actionsListAdmin = [
        {
            label: 'Edit',
            action: EditCustomer,
            iconAcion: HiPencil,
            actionStatus: true,
        },
        {
            label: 'Assign Manager',
            action: assigneManager,
            iconAcion: HiOutlineUserAdd,
            actionStatus: true,
        },
        {
            label: 'Wallet',
            action: navigateWallet,
            iconAcion: GiWallet,
            actionStatus: !(customer.status === 1),
        },
    ];
    const actionsListAccountManager = [
        {
            label: 'Edit',
            action: EditCustomer,
            iconAcion: HiPencil,
            actionStatus: true,
        },
        {
            label: 'Wallet',
            action: navigateWallet,
            iconAcion: GiWallet,
            actionStatus: true,
        },
    ];

    return (
        <>
            <Modal dismissible show={ModalEditCustomer} size="3xl" popup onClose={EditCustomerClose}>
                <Modal.Header>
                    <StatusAccount type="customer" label={customer.status} />
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        aria-label="Tabs with icons"
                        style="underline"
                        theme={{
                            tablist: {
                                tabitem: {
                                    base: 'flex items-center justify-center p-4 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 ',
                                },
                            },
                        }}
                    >
                        <Tabs.Item title="Account Management">
                            <CustomerAccountManagment
                                status={customer.status}
                                id={customer._id}
                                productsLimit={customer.productsLimit}
                                invoiceTiming={customer.invoiceGenTiming}
                                setRows={setRows}
                                currentPage={currentPage}
                                currentFilter={currentFilter}
                            />
                        </Tabs.Item>
                        <Tabs.Item title="Personal Information">
                            <CustomerPersonalInfo
                                customer={customer}
                                setRows={setRows}
                                currentPage={currentPage}
                                currentFilter={currentFilter}
                            />
                        </Tabs.Item>
                        {/* <Tabs.Item title="Credits Information">
                            <CustomerCreditsInformations customer={customer} productList={productList} />
                        </Tabs.Item> */}
                        <Tabs.Item title="Bank Information">
                            <CustomerBankInformation bankInformation={customer?.bankAccountInformation ?? {}} />
                        </Tabs.Item>
                    </Tabs>
                </Modal.Body>
            </Modal>

            <Modal dismissible show={ModalAssignManager} popup onClose={closeAssigneManager}>
                <Modal.Header>Select Manager</Modal.Header>
                <Modal.Body>
                    <AssignAccountManager
                        customer={customer}
                        accountManagers={accountManagers}
                        assignedAccountManager={assignedAccountManager as IAccountManager}
                        setAssignedAccountManager={setAssignedAccountManager}
                        setRows={setRows}
                        currentPage={currentPage as number}
                    />
                </Modal.Body>
            </Modal>
            <Dropdown inline arrowIcon={false} label={<HiDotsVertical />}>
                {user.role === 'accountManager'
                    ? actionsListAccountManager.map((action) => {
                          if (action.actionStatus) {
                              return (
                                  <Dropdown.Item icon={action.iconAcion} onClick={action.action} key={action.label}>
                                      {action.label}
                                  </Dropdown.Item>
                              );
                          }
                          return null;
                      })
                    : actionsListAdmin.map((action) => {
                          if (action.actionStatus) {
                              return (
                                  <Dropdown.Item
                                      icon={action.iconAcion}
                                      onClick={action.action}
                                      key={action.label}
                                      // disabled
                                  >
                                      {action.label}
                                  </Dropdown.Item>
                              );
                          }
                          return null;
                      })}
            </Dropdown>
        </>
    );
};
export default CustomerActions;
