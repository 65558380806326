/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-fragments */
import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import toast from 'react-hot-toast';
import { addSchemaCallAgent } from 'utils/validations/addMemberValidation';
import CustomError from 'components/customError/CustomError';
import { addCallAgent, getAllCallAgents } from 'services/admin/callAgent';
import PhoneInput from 'react-phone-number-input';
import '../../../utils/helpers/phoneInput.css';
import CustomBtn from 'components/customButton/customBtn';
import { Checkbox, Label, TextInput } from 'flowbite-react';
import { HiMail } from 'react-icons/hi';
import AutoComplete from 'components/autocomplete/autoComplete';
import { convertWorkingHoursToUTC } from 'utils/helpers/callAgent';
import moment from 'moment';
import { getAllCallCenters } from 'services/admin/callCenter';
import { ICallCenter } from 'pages/callCenterList/callCenter.types';
import { NotifProps } from '../callAgent.types';

export interface IdateRange {
    startDate: string | null | Date;
    endDate: string | null | Date;
}

const AddCAgent: React.FC<NotifProps> = ({ setOpen, setCallAgents }) => {
    const [isLoading, setIsLoading] = useState(false);

    const close = () => {
        setOpen(false);
    };
    const dayHoursArray = [
        '00-01',
        '01-02',
        '02-03',
        '03-04',
        '04-05',
        '05-06',
        '06-07',
        '07-08',
        '08-09',
        '09-10',
        '10-11',
        '11-12',
        '12-13',
        '13-14',
        '14-15',
        '15-16',
        '16-17',
        '17-18',
        '18-19',
        '19-20',
        '20-21',
        '21-22',
        '22-23',
        '23-00',
    ];
    const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const [workingTime, setWorkingTime] = useState<{ [key: string]: boolean }[]>([]);
    const [callCenter, setCallCenters] = useState<Array<ICallCenter>>([]);
    useEffect(() => {
        const tempWorkingTime = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < 7; i++) {
            tempWorkingTime.push({
                '00-01': false,
                '01-02': false,
                '02-03': false,
                '03-04': false,
                '04-05': false,
                '05-06': false,
                '06-07': false,
                '07-08': false,
                '08-09': false,
                '09-10': false,
                '10-11': false,
                '11-12': false,
                '12-13': false,
                '13-14': false,
                '14-15': false,
                '15-16': false,
                '16-17': false,
                '17-18': false,
                '18-19': false,
                '19-20': false,
                '20-21': false,
                '21-22': false,
                '22-23': false,
                '23-00': false,
            });
        }
        setWorkingTime([...tempWorkingTime]);
        getAllCallCenters({ limit: 1000, status: 1 }).then((res) => {
            setCallCenters(res.data.docs);
        });
    }, []);

    const newCallAgent = (values: any) => {
        setIsLoading(true);
        addCallAgent(values)
            .then((res) => {
                toast.success(res.data.message);
                close();
                setIsLoading(false);
            })
            .then(() => getAllCallAgents({}).then((res) => setCallAgents(res.data.docs)))
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message);
                setIsLoading(false);
            });
    };
    const handleHourChange = (day: number, hour: string, value: any) => {
        const wtDraft = [...workingTime];
        wtDraft[day][hour] = value;
        setWorkingTime([...wtDraft]);
    };
    return (
        <>
            <Formik
                initialValues={{
                    email: '',
                    firstName: '',
                    timeZone: '',
                    callCenter: [],
                }}
                onSubmit={(values, onSubmitProps) => {
                    const UTCWorkingTime = convertWorkingHoursToUTC(workingTime, values.timeZone);
                    newCallAgent({
                        ...values,
                        workingTime: UTCWorkingTime,
                    });
                    onSubmitProps.setSubmitting(false);
                    onSubmitProps.resetForm();
                }}
                validationSchema={addSchemaCallAgent}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit} placeholder="">
                        <div className="overflow-auto h-[36rem] p-4">
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="username4"
                                        className="dark:text-white"
                                        color={formik.errors.email ? 'failure' : ''}
                                        value="Email"
                                    />
                                </div>
                                <TextInput
                                    {...formik.getFieldProps('email')}
                                    name="email"
                                    placeholder="jhondoe@email.com"
                                    color={formik.errors.email ? 'failure' : 'gray'}
                                    icon={HiMail}
                                    type="email"
                                    helperText={
                                        <React.Fragment>
                                            <CustomError name="email" component="div" />
                                        </React.Fragment>
                                    }
                                />
                            </div>
                            <div className="grid grid-cols-2 gap-1">
                                <div>
                                    <div className="mb-2 block">
                                        <Label
                                            htmlFor="username4"
                                            className="dark:text-white"
                                            color={formik.errors.firstName ? 'failure' : ''}
                                            value="First Name"
                                        />
                                    </div>
                                    <TextInput
                                        {...formik.getFieldProps('firstName')}
                                        name="firstName"
                                        color={formik.errors.firstName ? 'failure' : 'gray'}
                                        type="text"
                                        helperText={
                                            <React.Fragment>
                                                <CustomError name="firstName" component="div" />
                                            </React.Fragment>
                                        }
                                    />
                                </div>
                                <div>
                                    <div className="mb-2 block">
                                        <Label
                                            htmlFor="username4"
                                            className="dark:text-white"
                                            // color={formik.errors.lastName ? 'failure' : ''}
                                            value="Last Name"
                                        />
                                    </div>
                                    <TextInput
                                        {...formik.getFieldProps('lastName')}
                                        name="lastName"
                                        // color={formik.errors.lastName ? 'failure' : 'gray'}
                                        type="text"
                                        helperText={
                                            <React.Fragment>
                                                <CustomError name="lastName" component="div" />
                                            </React.Fragment>
                                        }
                                    />
                                </div>
                                <div>
                                    <div className="mb-2 block">
                                        <Label
                                            htmlFor="username4"
                                            className="dark:text-white"
                                            value="Assign to a Call Center"
                                        />
                                    </div>

                                    <AutoComplete
                                        key="callCenterInput"
                                        {...formik.getFieldProps('callCenter')}
                                        name="callCenter"
                                        placeholder="Choose a Call Center"
                                        options={callCenter}
                                        getOptionLabel={(option: any) => option.name}
                                        onChange={(option) => {
                                            formik.setFieldValue('callCenter', [option.id]);
                                        }}
                                    />
                                </div>
                                <div>
                                    <div className="mb-2 block">
                                        <Label htmlFor="username4" className="dark:text-white" value="Phone Number" />
                                    </div>

                                    <PhoneInput
                                        id="phoneNumber"
                                        name="phone"
                                        // value={String(formik.values.phone)}
                                        onChange={(value) => formik.setFieldValue('phone', value)}
                                        onBlur={formik.handleBlur('phone')}
                                        placeholder="Enter a phone number"
                                        className="mb-1"
                                    />
                                    <CustomError name="phone" component="div" />
                                </div>
                                <div className="w-72">
                                    <div className="mb-2 block">
                                        <Label
                                            htmlFor="timeZoneInput"
                                            className="dark:text-white"
                                            color={formik.errors.email ? 'failure' : ''}
                                            value="Time zone"
                                        />
                                    </div>
                                    <AutoComplete
                                        key="timeZoneInput"
                                        {...formik.getFieldProps('timeZone')}
                                        name="timeZone"
                                        placeholder="Select a time zone"
                                        options={moment.tz.names() as any}
                                        getOptionLabel={(option: any) => option}
                                        onChange={(option) => {
                                            formik.setFieldValue('timeZone', option);
                                        }}
                                    />
                                    <CustomError name="timeZone" component="div" />
                                </div>
                            </div>
                            <div>
                                {weekDays.map((dd, idx: number) => {
                                    return (
                                        <>
                                            <div className="mb-2 mt-3 flex justify-start items-center px-3 py-2 rounded-md text-s font-medium bg-gray-50 dark:bg-gray-600">
                                                <p>{dd}</p>
                                            </div>
                                            <div className="grid grid-cols-8 mb-2">
                                                {dayHoursArray.map((hh) => {
                                                    return (
                                                        <div className="flex items-center gap-2">
                                                            <Checkbox
                                                                onChange={(event) => {
                                                                    handleHourChange(idx, hh, event?.target.checked);
                                                                }}
                                                            />
                                                            <Label>{hh}</Label>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                            <div className="flex gap-2 justify-between py-2 px-2 items-center rounded-b-lg  bg-background-secondary">
                                <CustomBtn
                                    variant="failure"
                                    outline
                                    onClick={() => {
                                        formik.resetForm();
                                        close();
                                    }}
                                >
                                    Cancel
                                </CustomBtn>
                                <CustomBtn
                                    variant="primary"
                                    type="submit"
                                    isProcessing={isLoading}
                                    disabled={isLoading}
                                >
                                    Save
                                </CustomBtn>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddCAgent;
